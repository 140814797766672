// Used for generalNav:
import { ReactComponent as Setting } from "src/assets/images/nav/setting.svg";
import { ReactComponent as SettingGradient } from "src/assets/images/nav/setting-gradient.svg";
// Used for restrictedNav:
import { ReactComponent as Organization } from "src/assets/images/nav/organization.svg";
// Used for teacherNav:
import { ReactComponent as GroupUser } from "src/assets/images/nav/group-user.svg";
import { ReactComponent as GroupUserActive } from "src/assets/images/nav/group-user-active.svg";
import { ReactComponent as Folder } from "src/assets/images/nav/folder.svg";
import { ReactComponent as FolderActive } from "src/assets/images/nav/folder-active.svg";
import { ReactComponent as QuestionCloud } from "src/assets/images/nav/question-cloud.svg";
import { ReactComponent as QuestionCloudActive } from "src/assets/images/nav/question-cloud-active.svg";
import { ReactComponent as HandWriting } from "src/assets/images/nav/hand-writing.svg";
import { ReactComponent as HandWritingActive } from "src/assets/images/nav/hand-writing-active.svg";
import { ReactComponent as Report } from "src/assets/images/nav/report-gradient.svg";
import { ReactComponent as FileManager } from "src/assets/images/nav/file-manager.svg";
import { ReactComponent as FileManagerActive } from "src/assets/images/nav/file-manager-active.svg";
import { ReactComponent as TempFolder } from "src/assets/images/nav/temp-folder.svg";
// Used for studentNav:
import { ReactComponent as Test } from "src/assets/images/nav/test.svg";
import { ReactComponent as TestGradient } from "src/assets/images/nav/test-gradient.svg";
import { ReactComponent as Classes } from "src/assets/images/nav/classes.svg";
import { ReactComponent as ClassesGradient } from "src/assets/images/nav/classes-gradient.svg";
import { ReactComponent as MyQuestions } from "src/assets/images/CauHoi.svg";
import { ReactComponent as MyExam } from "src/assets/images/DeThi.svg";
import { ReactComponent as MyResources } from "src/assets/images/TaiNguyenCuaToi.svg";

//icon admin dashboard
import { ReactComponent as DashBoard } from "src/assets/images/nav/Dashboard.svg";
import { ReactComponent as ManageBranch } from "src/assets/images/nav/QLChiNhanh.svg";
import { ReactComponent as ManageData } from "src/assets/images/nav/QLDungLuong.svg";
import { ReactComponent as ManageTeacher } from "src/assets/images/nav/QLGV.svg";
import { ReactComponent as ManageStudent } from "src/assets/images/nav/QLHS.svg";
import { ReactComponent as ManageClass } from "src/assets/images/nav/QLLop.svg";
import { ReactComponent as ManageEmployee } from "src/assets/images/nav/QLNV.svg";
import { ReactComponent as Permissions } from "src/assets/images/nav/phanquyen.svg";
import { ReactComponent as QLDL } from "src/assets/images/nav/QLDL.svg";
import { ReactComponent as DLTC } from "src/assets/images/nav/dungluongtochuc.svg";
import { ReactComponent as Ranking } from "src/assets/images/nav/ranking-grey.svg";
import { CompetitionIcon } from "src/utils/drawer";
import { HomeFilled } from "@ant-design/icons";

export const generalNav = [
    {
        name: "profile",
        routeName: "profile",
        icon: SettingGradient,
        iconResponsive: Setting,
        active: ["/profile"],
        isAllowedDemoAccount: false,
        allowRole: ["student"],
    },
    {
        name: "setting",
        routeName: "setting",
        icon: SettingGradient,
        iconResponsive: Setting,
        active: ["/setting"],
        isAllowedDemoAccount: false,
        allowRole: ["teacher"],
    },
];

export const restrictedNav = [
    {
        name: "organization",
        routeName: "organization",
        icon: Organization,
        iconResponsive: Organization,
        active: ["/organization"],
    },
];

export const teacherNav = [
    {
        name: "home",
        routeName: "home",
        icon: HomeFilled2,
        activeIcon: HomeFilled2,
        source: "general",
        active: ["/home"],
    },
    {
        name: "admin_dashboard",
        routeName: "admin_dashboard",
        icon: DashBoard,
        activeIcon: DashBoard,
        source: "general",
        active: [
            "/manage-class",
            "/manage-teacher",
            "/manage-student",
            "/manage-employee",
            "/manage-capacity",
            "/manage-branch",
            "/manage-role",
            "/organization",
            "/manage-ranking",
            "/manage-competitions",
        ],
        is_organization: true,
        subnav: [
            {
                key: "manage_student",
                name: "manage_student",
                routeName: "manage_student",
                source: "general",
                active: ["/manage-student"],
                icon: ManageStudent,
            },
            {
                key: "manage_teacher",
                name: "manage_teacher",
                routeName: "manage_teacher",
                source: "general",
                active: ["/manage-teacher"],
                icon: ManageTeacher,
            },
            {
                key: "manage_employee",
                name: "manage_employee",
                routeName: "manage_employee",
                source: "general",
                active: ["/manage-employee"],
                icon: ManageEmployee,
            },
            {
                key: "manage_class",
                name: "manage_class",
                routeName: "manage_class",
                source: "general",
                active: ["/manage-class"],
                icon: ManageClass,
            },
            {
                key: "manage_branch",
                name: "manage_branch",
                routeName: "manage_branch",
                source: "general",
                active: ["/manage-branch"],
                icon: ManageBranch,
            },
            {
                key: "manage_competitions",
                name: "manage_competitions",
                routeName: "manage_competitions",
                source: "general",
                active: ["/manage-competitions"],
                icon: CompetitionIcon,
            },
            {
                key: "manage_ranking",
                name: "manage_ranking",
                routeName: "manage_ranking",
                source: "general",
                active: ["/manage-ranking"],
                icon: Ranking,
            },

            {
                key: "manage_role",
                name: "manage_role",
                routeName: "manage_role",
                source: "general",
                active: ["/manage-role"],
                icon: Permissions,
            },
            {
                key: "manage_capacity",
                name: "manage_capacity",
                routeName: "manage_capacity",
                source: "general",
                active: ["/manage-capacity"],
                icon: DLTC,
            },
            {
                key: "organization_detail",
                name: "organization_detail",
                routeName: "organization_detail",
                source: "general",
                active: ["/organization"],
                icon: Organization,
            },
        ],
    },
    {
        name: "class",
        routeName: "class",
        icon: GroupUser,
        activeIcon: GroupUserActive,
        source: "general",
        active: ["/class"],
        // hide_with_owner: true,
    },

    {
        name: "my_resources",
        routeName: "my_resources",
        icon: MyResources,
        activeIcon: MyResources,
        source: "general",
        active: ["/my-resources/question", "/my-resources/exam"],
        subnav: [
            {
                key: 1,
                name: "my_resources_question",
                routeName: "my_resources_question",
                source: "general",
                active: ["/my-resources/question"],
                icon: MyQuestions,
            },
            {
                key: 2,
                name: "my_resources_exam",
                routeName: "my_resources_exam",
                source: "general",
                active: ["/my-resources/exam"],
                icon: MyExam,
            },
        ],
    },
    {
        name: "question_bank",
        routeName: "question_bank",
        icon: Folder,
        activeIcon: FolderActive,
        source: "general",
        active: ["/question"],
    },
    {
        name: "exam_bank",
        routeName: "exam_bank",
        icon: QuestionCloud,
        activeIcon: QuestionCloudActive,
        source: "general",
        active: ["/exam"],
    },
    {
        name: "assignment",
        routeName: "assignment",
        icon: HandWriting,
        activeIcon: HandWritingActive,
        source: "general",
        active: ["/assignment"],
    },
    {
        name: "entry_test",
        routeName: "entry_test",
        icon: GroupUser,
        activeIcon: GroupUserActive,
        source: "general",
        active: ["/entry-tests"],
        // hide_with_owner: true,
    },
    {
        name: "learning_progress",
        routeName: "learning-progress-report",
        icon: Report,
        iconResponsive: Report,
        active: ["/learning-progress-report"],
    },

    {
        name: "competitions",
        routeName: "competitions",
        icon: CompetitionIcon,
        activeIcon: CompetitionIcon,
        source: "general",
        active: ["/competitions"],
    },

    {
        name: "ranking",
        routeName: "ranking",
        icon: Ranking,
        activeIcon: Ranking,
        source: "general",
        active: ["/ranking"],
    },

    {
        name: "my_storage",
        routeName: "my_storage",
        icon: FileManager,
        activeIcon: FileManagerActive,
        source: "general",
        active: ["/my-storage"],
    },

    // {
    //     name: "setting",
    //     routeName: "setting",
    //     icon: SettingGradient,
    //     iconResponsive: Setting,
    //     active: ["/setting"],
    //     isAllowedDemoAccount: false,
    // },

    // {
    //     name: "branch",
    //     routeName: "branch",
    //     icon: GroupUser,
    //     activeIcon: GroupUserActive,
    //     source: "general",
    //     active: ["/branch"],
    // },
];

export const studentNav = [
    {
        id: 1,
        name: "assignments",
        routeName: "home",
        icon: HandWriting,
        iconResponsive: HandWritingActive,
        active: ["/home"],
    },
    {
        id: 2,
        name: "scores",
        routeName: "scores",
        icon: TestGradient,
        iconResponsive: Test,
        active: ["/scores"],
    },
    {
        id: 3,
        name: "classes",
        routeName: "classes",
        icon: ClassesGradient,
        iconResponsive: Classes,
        active: ["/classes"],
    },
    {
        id: 4,
        name: "personal_leaning_progress",
        routeName: "personal-report",
        icon: Report,
        iconResponsive: Report,
        active: ["/personal-report"],
    },
    {
        id: 5,
        name: "competitions",
        routeName: "competitions",
        icon: CompetitionIcon,
        iconResponsive: CompetitionIcon,
        active: ["/competitions"],
    },
];

function HomeFilled2() {
    return (
        <div style={{ color: "#ACC1CA", width: "100%", height: "100%" }}>
            <HomeFilled>
                <linearGradient
                    id="paint0_linear_936_9190"
                    x1="17.5084"
                    y1="35.0189"
                    x2="17.5084"
                    y2="-0.00979211"
                    gradientUnits="userSpaceOnUse"
                />
            </HomeFilled>
        </div>
    );
}
