import React, { useEffect, useState } from "react";
import Clock from "src/modules/components/Clock";
import useMediaQuery from "src/hooks/useMediaQuery";
import "./TestLayout_Header.scss";
import ExitIcon from "src/assets/images/exit.svg";
import { useTranslation } from "react-i18next";
import Button from "src/modules/components/Button";
import { Tooltip, notification } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { gotoQuestionOrderTempTime, handleSubmitRecord, removeTest, setIsShowConfirmModal } from "src/reducers/test";
import { useDispatch, useSelector } from "react-redux";
import { removeRecord } from "src/reducers/answer";
import PopConfirm from "src/modules/components/Modal/PopConfirm";

const Header = ({ title, icon }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const { isShowConfirmModal, listRecord, isSubmittingTest } = useSelector((state) => state.test);
    const currMedia = useMediaQuery(["(min-width: 1023px)"]);
    const [recordInfo, setRecordInfo] = useState({});
    const dispatch = useDispatch();
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const competition_id = searchParams.get("competition_id");
    // const currMedia = useMediaQuery(["(max-width: 1024px)"]);

    const handleExit = () => {
        if (competition_id) {
            navigate(`${generate("rounds")}?competition_id=${competition_id}`);
        } else {
            navigate(generate("home"));
        }
    };

    const onChangeQuestionOrder = (order) => {
        dispatch(
            gotoQuestionOrderTempTime({
                order,
                recordId: recordInfo?.record_id,
                questionId: recordInfo.questions[order - 1].id,
            })
        );
    };

    const handleSubmit = async () => {
        // Trước khi kết thúc bài thi phải lưu giá trị của câu hiện đang làm.
        const res = await dispatch(
            handleSubmitRecord({
                recordId: recordInfo?.record_id,
            })
        );

        const { status, message } = res?.payload;

        if (status) {
            notification.success({
                message: t("test.submit_record_success"),
            });

            dispatch(
                removeRecord({
                    recordId: recordInfo?.record_id,
                })
            );

            dispatch(removeTest());
        } else {
            notification.error({
                message,
            });
        }

        dispatch(setIsShowConfirmModal(false));

        setTimeout(() => {
            // navigate(generate("home"));
            handleExit();
        }, 200);
    };

    useEffect(() => {
        if (params.id) {
            setRecordInfo(listRecord[params.id]);
        }
    }, [listRecord, params]);

    return (
        <>
            <div className="TestHeader pdf">
                <div className="title_wrapper">
                    <div className="title">{title}</div>
                </div>
                <div className="TestHeader_Clock">
                    <Clock width={140} strokeColor={"fff"} trailColor="3190FB" theme="eloctronicClock" />
                </div>
                <div className="actions">
                    <Tooltip title={t("test.exit")}>
                        <Button icon={ExitIcon} onClick={handleExit}></Button>
                    </Tooltip>
                    <Tooltip title={t("test.submit")}>
                        <Button
                            onClick={() => {
                                dispatch(setIsShowConfirmModal(true));
                            }}
                            icon={<SendOutlined />}
                        ></Button>
                    </Tooltip>
                </div>
            </div>

            <PopConfirm
                type="submit-test"
                visible={isShowConfirmModal}
                onOk={handleSubmit}
                onCancel={() => dispatch(setIsShowConfirmModal(false))}
                okText={t("test.submit")}
                cancelText={t("test.cancel")}
                isLoading={isSubmittingTest}
            >
                <p className="modal-title" style={{ margin: 0 }}>
                    {t("test.confirm_submit")}
                </p>
            </PopConfirm>
        </>
    );
};

export default React.memo(Header);
