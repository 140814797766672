import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionPanel from "src/modules/components/QuestionPanel";
import QuestionContent from "src/modules/components/QuestionContent";

function QuestionDetailPreview({ formQuestionDataPreview, isScoring }) {
    const { t } = useTranslation();

    const [isShowResult, setIsShowResult] = useState(false);

    const qDataPreview = formQuestionDataPreview;
    const [qDataAnswered, setQDataAnswered] = useState(undefined);

    const handleToggleShowResult = () => {
        setIsShowResult(!isShowResult);
    };

    const handleAnswer = (newValue) => {
        if (!newValue) {
            return;
        }
        // Tạm thời câu highlight bắn answer khác sau sẽ cập nhật lại
        if (newValue.passage_highlighted) {
            setQDataAnswered({
                answered: {
                    answered: newValue.answered,
                    passage_highlighted: newValue.passage_highlighted,
                },
            });
        } else {
            setQDataAnswered({
                answered: newValue?.answered,
            });
        }
    };

    return (
        <>
            {!isScoring && (
                <button className="question-show-answer" onClick={handleToggleShowResult}>
                    {isShowResult ? t("q_detail.hide_answer") : t("q_detail.show_answer")}
                </button>
            )}

            {!isShowResult ? (
                <QuestionPanel
                    questionInfo={{
                        ...qDataPreview,
                        ...qDataAnswered,
                    }}
                    onChange={handleAnswer}
                    showQuestionNumber={false}
                    showBottomNav={false}
                ></QuestionPanel>
            ) : (
                <QuestionContent
                    type="preview"
                    questionInfo={{
                        ...qDataPreview,
                        ...qDataAnswered,
                    }}
                    showResults={true}
                    // Question display type:
                    displayType="question-panel"
                />
            )}
        </>
    );
}

export default QuestionDetailPreview;
