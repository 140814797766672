import { CaretDownOutlined, EyeOutlined, QuestionOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Col, Dropdown, Input, Menu, Modal, notification, Row, Space, Spin, Tag, Tooltip } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import Button from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import FilterMenu from "./components/FilterMenu";
import { ReactComponent as FilterIcon } from "src/assets/images/filter-icon.svg";
import Header from "./components/Header";

import { findCompetition, listCompetitions, listRanking } from "src/api/containers/Competition";
import { findAssignment } from "src/api/containers/assignment";
import { findClass } from "src/api/containers/class";
import { useSearchParams } from "react-router-dom";
import "./Ranking.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAssignmentResult } from "src/utils/helpers";
import ViewScoreStudent from "../ViewScoreStudent";
import { setCurrentPage } from "src/reducers/general";
import HelpModal from "src/modules/components/Help/HelpModal";

const sortFilterKeys = [
    { name: "all", value: "all" },
    { name: "ascending", value: "asc" },
    { name: "descending", value: "desc" },
];

const resultFilterKeys = [
    { name: "all", value: "all" },
    { name: "passed", value: "passed" },
    { name: "failed", value: "failed" },
];

function Ranking() {
    const { t } = useTranslation();
    const searchInput = useRef(null);
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const assignment_id_searchParams = searchParams.get("assignment_id");
    const competition_id_searchParams = searchParams.get("competition_id");

    const currentPage = useRef(1);

    // API CALL:
    // User and scope:
    const { user, accountType } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }

    const [values, setValues] = useValues({
        exam: {},
        listRanking: [],
        assignment: {},
        classData: {},
        rankingLoading: false,
        pagination: {},
        headerLoading: false,
        isShowModalViewScore: false,
        currentRecordId: "",
    });

    const [filterSettings, setFilterSettings] = useValues({
        isFilterMenuVisible: assignment_id_searchParams ? false : true,
        customFilterParams: [],
        formData: {},
        assignment: {},
        tableFilters: {
            score: "all",
            time: "all",
            rank: "asc",
        },
        result: "",
        student_name: "",
    });
    const [isShowHelpModal, setIsShowHelpModal] = useState(false);
    const currentPageForHelp = useSelector((state) => state.general.page);

    const handleSearchName = (name, confirm) => {
        confirm();
        const exam_id = filterSettings.formData?.assignment_id;
        const newFiltersArr = objToArray(filterSettings.tableFilters);
        if (exam_id) {
            getListRanking(exam_id, { result: "", orderBy: newFiltersArr, student_name: name });
        }
        setFilterSettings({ student_name: name });
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: (prop) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = prop;
            return (
                <div
                    style={{
                        padding: "2",
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    className="table-search"
                >
                    <Input
                        ref={searchInput}
                        placeholder={t("shared.find")}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchName(selectedKeys, confirm)}
                        suffix={
                            <span
                                style={{
                                    cursor: "pointer",
                                    color: "#000",
                                    padding: "3px 8px",
                                    backgroundColor: "rgba(0,0,0,0.05)",
                                    borderRadius: 5,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSearchName(selectedKeys, confirm);
                                }}
                            >
                                <SearchOutlined style={{ color: "#000" }} />
                            </span>
                        }
                        bordered
                        style={{ border: "none", padding: "2 0 2 8" }}
                    />
                </div>
            );
        },
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : "#000",
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const objToArray = (obj) => {
        let filtersArr = Object.entries(obj);

        let newFiltersArr = filtersArr?.filter((item, i) => {
            return ["asc", "desc"].includes(item?.[1]);
        });

        return newFiltersArr.map((item) => item.join(":"));
    };

    const handleTableFilters = (key, value) => {
        const exam_id = filterSettings.formData?.assignment_id;
        const filters = { ...filterSettings.tableFilters };
        const newFilters = {};
        filters[key] = value;

        for (const key in filters) {
            if (filters.hasOwnProperty(key) && ["asc", "desc"].includes(filters[key])) {
                newFilters[key] = filters[key];
            }
        }

        const newFiltersArr = objToArray(newFilters);
        if (exam_id) {
            getListRanking(exam_id, {
                orderBy: newFiltersArr,
                student_name: filterSettings.student_name,
                result: key === "result" ? (value !== "all" ? value : values.result || "") : "",
            });
        }

        setFilterSettings({
            tableFilters: {
                ...filterSettings.tableFilters,
                [key]: value,
            },
            result: key === "result" ? (value !== "all" ? value : values.result || "") : "",
        });
    };

    const isRoundEnded = useMemo(() => {
        const curentDate = new Date();

        return moment(curentDate).isAfter(filterSettings.assignment?.end_date);
    }, filterSettings.assignment?.id);

    function handleHelpClick() {
        setIsShowHelpModal((pre) => !pre);
    }
    function handleChangeVisible(val) {
        setIsShowHelpModal(val);
    }

    const renderTiltle = (name, title, filterKeys) => {
        return {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName={name}
                            cateName="shared"
                            filterKeys={filterKeys}
                            selectedFilterKey={filterSettings.tableFilters?.[name]}
                            onChangeFilterKey={(val) => {
                                setFilterSettings({
                                    tableFilters: { ...filterSettings.tableFilters, [name]: val },
                                });
                                handleTableFilters(name, val);
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="status-wrapper">
                        <span>{t(`ranking.${title}`)}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
        };
    };

    const rankingColumns = [
        {
            title: t("profile.full_name"),
            dataIndex: "name",
            key: "name",
            align: "center",
            width: "20%",
            render: (item, record) => {
                return record?.name;
            },
            ...getColumnSearchProps(),
        },
        {
            ...renderTiltle("score", "score", sortFilterKeys),
            dataIndex: "score",
            key: "score",
            align: "center",
            width: 150,
            filterIcon: <CaretDownOutlined style={{ color: "#000" }} />,
            render: (item, record) => {
                return record?.record?.score;
            },
        },
        {
            ...renderTiltle("time", "time", sortFilterKeys),
            dataIndex: "time",
            key: "time",
            align: "center",
            width: 150,
            filterIcon: <CaretDownOutlined style={{ color: "#000" }} />,
            filteredValue: filterSettings.tableFilters?.time || null,
            render: (item, record) => {
                const duration = moment.duration(record?.record?.time, "seconds");
                const formattedTime = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
                return formattedTime;
                // return record?.record?.time;
            },
        },
        {
            dataIndex: "rank",
            key: "rank",
            align: "center",
            width: "20%",
            filterIcon: <CaretDownOutlined style={{ color: "#000" }} />,
            ...renderTiltle("rank", "rank", sortFilterKeys),
            render: (item, record) => {
                return record?.record?.rank;
            },
        },
        {
            // title: t("shared.result"),
            width: "20%",
            ...renderTiltle("result", "result", resultFilterKeys),
            dataIndex: "result",
            key: "result",
            align: "center",
            filterIcon: isRoundEnded && <CaretDownOutlined style={{ color: "#000" }} />,
            render: (item, record) => {
                if (!isRoundEnded) return "";
                return getAssignmentResult({ record: record?.record });

                // if (!record?.record) {
                //     return `${t("ranking.failed")} (${t("ranking.donot")})`;
                // }
                // if (record?.record?.result === "not_submitted") {
                //     return `${t("ranking.failed")} (${t("ranking.not_submitted")})`;
                // }

                // return textResult?.[record?.record?.result]
                //     ? textResult?.[record?.record?.result]
                //     : record?.record?.result;
            },
        },
    ];

    if (isRoundEnded) {
        rankingColumns.push({
            // title: t("shared.result"),
            width: "20%",
            key: "actions",
            align: "center",
            render: (item, record) => {
                if (!isRoundEnded || !record?.record || !record?.record?._id) return "";
                else {
                    return (
                        <span
                            className="table-action"
                            style={{ justifyContent: "center", cursor: "pointer" }}
                            onClick={() => {
                                setValues({
                                    isShowModalViewScore: true,
                                    currentRecordId: record?.record?._id,
                                });
                            }}
                        >
                            <Tooltip title={t("question_bank.view")}>
                                <EyeOutlined />
                            </Tooltip>
                        </span>
                    );
                }
            },
        });
    }

    const getListRanking = (exam_id, params) => {
        setValues({
            rankingLoading: true,
        });
        listRanking(exam_id, params).then(({ data, status, message, pagination }) => {
            if (status) {
                setValues({ listRanking: data, rankingLoading: false, pagination: pagination });
            } else {
                // console.log(message);
            }
        });
    };

    const getClass = (id) => {
        setValues({
            headerLoading: true,
        });
        findClass(id, { relations: ["categories"] }).then((res) => {
            if (res?.status) {
                setValues({
                    classData: res.data,
                });
            } else {
                // navigate(generate("not-found"));
            }
            setValues({ loading: false, headerLoading: false });
        });
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();

        let newFilterParams = [];
        if (paramName == "assignment_id") {
            newFilterParams = filterSettings.customFilterParams.filter((item) => {
                return !(paramName === item.name);
            });
        }

        // if (paramName == "competition_id") {
        //     setFilterSettings({
        //         customFilterParams: newFilterParams,
        //         competition: {},
        //         formData: {
        //             assignment_id: filterSettings?.formData?.assignment_id,
        //         },
        //     });
        //     return;
        // }

        if (paramName == "assignment_id" || paramName == "competition_id") {
            setFilterSettings({
                customFilterParams: newFilterParams,
                assignment: {},
                formData: {},
                tableFilters: {
                    rank: "asc",
                    time: "all",
                    score: "all",
                },
                student_name: "",
            });
            setValues({
                exam: {},
                listRanking: [],
                classData: {},
            });
        }
    };

    useEffect(() => {
        const exam_id = filterSettings.formData?.assignment_id;
        if (exam_id !== values.exam?._id) {
            getListRanking(exam_id, { result: "", orderBy: "rank:asc" });
        }
    }, [filterSettings.formData?.assignment_id]);

    useEffect(() => {
        const newFilter = {
            assignment: {},
            competition: {},
            formData: {
                assignment_id: assignment_id_searchParams,
                competition_id: competition_id_searchParams,
            },
            customFilterParams: [],
        };
        if (assignment_id_searchParams) {
            findAssignment(assignment_id_searchParams, { competition_id: competition_id_searchParams }).then(
                ({ status, data, message }) => {
                    if (status && data?.class?.id) {
                        getClass(data.class.id);

                        newFilter.assignment = data;
                        newFilter.customFilterParams = [
                            ...newFilter.customFilterParams,
                            {
                                name: "assignment_id",
                                value: assignment_id_searchParams,
                                labelName: t("ranking.round2"),
                                labelValue: data?.name,
                            },
                        ];

                        setFilterSettings({
                            ...newFilter,
                        });
                    }
                }
            );
        }
        if (competition_id_searchParams) {
            findCompetition(competition_id_searchParams).then(({ data, status }) => {
                if (status) {
                    newFilter.competition = data;
                    newFilter.customFilterParams = [
                        ...newFilter.customFilterParams,
                        {
                            name: "competition_id",
                            value: competition_id_searchParams,
                            labelName: t("nav.competitions"),
                            labelValue: data?.name,
                        },
                    ];
                    setFilterSettings({
                        ...newFilter,
                    });
                }
            });
        }
    }, [assignment_id_searchParams, competition_id_searchParams]);

    useEffect(() => {
        dispatch(setCurrentPage("ranking"));
    }, []);

    return (
        <div className="ranking">
            <Row gutter={[40, 40]}>
                <Col span={24}>
                    <Header
                        loading={values.headerLoading}
                        classData={values.classData}
                        assignment={filterSettings.assignment}
                        assignment_id={assignment_id_searchParams}
                    />
                </Col>
                <Col span={24} align="end">
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <Space wrap={true} style={{ justifyContent: "flex-end" }}>
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.customFilterParams?.length > 0 &&
                                        filterSettings.customFilterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    className="app-tag"
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                >
                                                    {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                        fKey.labelValue || ""
                                                    }`}
                                                </Tag>
                                            );
                                        })}
                                </Space>
                            </div>
                            <div className="PersonalLearningProgressFilter_wrapper">
                                <div className="PersonalLearningProgressFilter_inner">
                                    <div className="filterButton">
                                        <Space>
                                            <Dropdown
                                                visible={filterSettings.isFilterMenuVisible}
                                                overlay={
                                                    <FilterMenu
                                                        isTeacher={true}
                                                        filterParams={filterSettings.customFilterParams}
                                                        handleCloseFilterMenu={(
                                                            newFilterParams,
                                                            formData,
                                                            assignment
                                                        ) => {
                                                            setFilterSettings({
                                                                isFilterMenuVisible: false,
                                                                customFilterParams: newFilterParams,
                                                                formData: {
                                                                    assignment_id:
                                                                        formData?.assignment_id?.value ||
                                                                        formData?.assignment_id,
                                                                    competition_id:
                                                                        formData?.competition_id?.value ||
                                                                        formData?.competition_id,
                                                                },
                                                                assignment: assignment,
                                                                filterSettings: {
                                                                    rank: "asc",
                                                                    score: "all",
                                                                    time: "all",
                                                                },
                                                            });
                                                            getClass(assignment?.class?.id);
                                                        }}
                                                        initAssignment={filterSettings.assignment}
                                                        fParams={fParams}
                                                    />
                                                }
                                                overlayClassName="progress-filter"
                                                trigger={["click"]}
                                                placement="bottomRight"
                                                onVisibleChange={(val) =>
                                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                                }
                                            >
                                                <Button
                                                    type="primary"
                                                    title={t("report.filter")}
                                                    icon={<FilterIcon />}
                                                    className="tags_button"
                                                />
                                            </Dropdown>
                                            <div className="help">
                                                <span className="act-button-wrapper">
                                                    <Tooltip title={t("header.help")} placement="bottomRight">
                                                        <span
                                                            className={`act-button question`}
                                                            onClick={handleHelpClick}
                                                        >
                                                            <QuestionOutlined />
                                                        </span>
                                                    </Tooltip>

                                                    <HelpModal
                                                        visible={isShowHelpModal}
                                                        page={currentPageForHelp}
                                                        onChangeVisible={handleChangeVisible}
                                                    />
                                                </span>
                                            </div>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </Space>
                    </div>
                </Col>
                {!filterSettings.assignment?.id ? (
                    <Col span={24}>
                        <Alert
                            description={t("ranking.choose_asignment_to_view_ranking")}
                            type="warning"
                            style={{ marginTop: 20 }}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <div className="exam-list-table ranking-table">
                            <Table
                                className="app-table"
                                columns={rankingColumns}
                                dataSource={values.listRanking}
                                // scroll={{ x: "auto" }}
                                loading={values.rankingLoading}
                                scroll={{ x: "100%" }}
                                pagination={{
                                    position: ["bottomCenter"],
                                    ...values.pagination,
                                    showSizeChanger: false,
                                    onChange: (page) => {
                                        const newFiltersArr = objToArray(filterSettings.tableFilters);
                                        const assignment_id = filterSettings.formData?.assignment_id;

                                        if (page !== currentPage.current) {
                                            currentPage.current = page;
                                            getListRanking(assignment_id, {
                                                result: "",
                                                orderBy: newFiltersArr,
                                                student_name: filterSettings.student_name,
                                                page: page,
                                            });
                                        }
                                    },
                                }}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={values.isShowModalViewScore}
                onCancel={() => setValues({ isShowModalViewScore: false })}
                width={"80%"}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose
            >
                <ViewScoreStudent
                    markingRecord={values.record} //lay kq bai ktra
                    // role={isTeacher ? "teacher" : "student"}
                    record_id={values.currentRecordId}
                    isMarking={false}
                    closeModal={() => {
                        setValues({ isShowModalViewScore: false });
                    }}
                    isModalBox={true}
                />
            </Modal>
        </div>
    );
}

const TableColumnFilterMenu = (props) => {
    const {
        cateName = "",
        cateNameNotFrom = -1,
        columnName = "",
        filterKeys = [],
        selectedFilterKey = -1,
        onChangeFilterKey = () => {},
    } = props;

    const { t } = useTranslation();

    return (
        <ul className="table-column-filter-menu">
            {filterKeys.map((keyItem, keyIndex) => {
                return (
                    <li
                        key={`${columnName}-${keyIndex}`}
                        className={selectedFilterKey === keyItem.value ? "active" : ""}
                        onClick={() => onChangeFilterKey(keyItem.value)}
                    >
                        {cateNameNotFrom === -1 || keyIndex < cateNameNotFrom ? (
                            <>{t(`${cateName}.${keyItem.name}`)}</>
                        ) : (
                            <>{t(`${keyItem.name}`)}</>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default Ranking;
