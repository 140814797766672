import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { Col, Input, message, notification, Row, Select, Spin, Tag } from "antd";
import { useTranslation } from "react-i18next";
import Header from "src/modules/components/Header";
import PaymentMethods from "./components/PaymentMethods";
import PaymentOrderPanel from "./components/PaymentOrderPanel";
import { default as CustomButton } from "src/modules/components/Button";
import { applyPromoCode, fetchSubscriptionPlans, findSubscriptionPlan, purchasePlan } from "src/api/containers/plan";
import "./Payment.scss";
import { useSelector } from "react-redux";
import { useValues } from "src/hooks";

const Payment = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // "plan", "purchase", true||false.
    const [data, setData] = useValues({
        plan: undefined,
        selectedPlanPriceId: undefined,
        selectedPaymentMethod: undefined,
        payment_promo_info: {},
    });
    const [amount, setAmount] = useState({
        total: undefined,
        final: undefined,
    });
    const [inputPromoCode, setInputPromoCode] = useState(undefined);

    const { generate } = useRouting();
    const navigate = useNavigate();
    const currOrder = JSON.parse(sessionStorage.getItem("currentOrder")) || { planId: undefined };

    const paramsForOrgOrTeam = useSelector((state) => state.auth?.user?.paramsForOrgOrTeam);

    // Get all order items:
    const getOrderItems = () => {
        const items = [];
        // Plan:
        if (data.plan?.prices?.length) {
            // Get the selected plan's price:
            for (let i = 0; i < data.plan.prices.length; i++) {
                if (data.plan.prices[i].id === data.selectedPlanPriceId) {
                    let priceCurr = data.plan.prices[i].price || 0;
                    let priceUsual = priceCurr;
                    if (data.plan.prices[i].discount) {
                        priceUsual = priceCurr;
                        priceCurr = priceCurr - (priceCurr * data.plan.prices[i].discount) / 100;
                    }
                    items.push({
                        name: data.plan.name,
                        priceCurrent: priceCurr,
                        priceUsual: priceUsual,
                    });
                    break;
                }
            }
        }

        return items;
    };

    // Calculate total amount of all order items:
    const getTotalAmount = () => {
        let amount = 0;
        if (data.plan?.prices?.length) {
            // Get the selected plan's price:
            for (let i = 0; i < data.plan.prices.length; i++) {
                if (data.plan.prices[i].id === data.selectedPlanPriceId) {
                    // Initial price:
                    amount += data.plan.prices[i].price || 0;
                    // Discount:
                    if (data.plan.prices[i].discount) {
                        amount = amount - (amount * data.plan.prices[i].discount) / 100;
                    }
                    break;
                }
            }
        }
        return amount;
    };

    function handleApplyPromocodes(codes) {
        if (data.selectedPlanPriceId && codes?.length) {
            setLoading("add-promo");
            applyPromoCode({
                plan_price_id: data.selectedPlanPriceId,
                promotion_codes: codes,
            })
                .then((res) => {
                    if (res.status && res.data) {
                        setInputPromoCode(undefined);

                        setData({
                            payment_promo_info: res.data,
                        });

                        if (res.data?.message) {
                            notification.warning({
                                message: res.data?.message,
                            });
                        }
                    } else {
                        notification.error({
                            message: res.message || t("payment.err_can_not_apply_promocode"),
                        });
                    }
                    setLoading(false);
                })
                .catch((item) => {
                    setLoading(false);
                });
        } else {
            notification.error({
                message: t("payment.please_input_promocode"),
            });
        }
    }

    const handleAddPromoCode = (code) => {
        const promo_codes = (data.payment_promo_info?.promotions_info || [])?.map((item) => item?.code);
        if (code && !promo_codes?.includes(code)) {
            promo_codes.push(code);
            handleApplyPromocodes(promo_codes);
        }
    };

    const handleRemovePromoCode = (promoCode) => {
        const promotions_info = data.payment_promo_info?.promotions_info;

        if (Array.isArray(promotions_info) && promotions_info?.length > 1) {
            const promotions = promotions_info?.filter((item) => item?.code !== promoCode)?.map((item2) => item2?.code);

            if (promotions?.length) {
                handleApplyPromocodes(promotions);
            }
        } else {
            setData({ payment_promo_info: {} });
            return;
        }
    };

    const handlePurchasePlan = () => {
        // submit

        if (!data.selectedPaymentMethod) {
            notification.error({
                message: `${t("payment.please_select_a_payment_method")}!`,
            });
            return;
        }
        setLoading("purchase");
        const paymentInfo = {
            plan_price_id: data.selectedPlanPriceId,
            payment_method: data.selectedPaymentMethod?.type,
            promotion_codes: data.payment_promo_info?.promotions_info?.map((item) => item.code),
        };

        if (currOrder.is_extend && paramsForOrgOrTeam?.organization_id) {
            paymentInfo.organization_id = paramsForOrgOrTeam.organization_id;
        }
        purchasePlan(paymentInfo).then((res) => {
            if (res.status && res.data) {
                if (res.data.url) {
                    sessionStorage.removeItem("currentOrder");
                    setLoading("redirect");
                    window.location.href = res.data.url;
                    return;
                } else if (res.data.plan && res.data.bill) {
                    sessionStorage.removeItem("currentOrder");
                    notification.success({
                        message: res.message || t("payment.sucess_make_payment_transaction"),
                    });
                    navigate("/");
                }
            } else {
                notification.error({
                    message: res.message || t("payment.err_can_not_purchase"),
                });
            }
            setLoading(false);
        });
    };

    const handleChangePlanPrice = (res) => {
        setData({
            selectedPlanPriceId: res,
            payment_promo_info: {},
        });
    };

    useEffect(() => {
        if (currOrder && currOrder.planId) {
            setLoading("plan");
            findSubscriptionPlan(currOrder.planId).then((res) => {
                if (res.status && res.data) {
                    setData({
                        plan: res.data,
                        selectedPlanPriceId: currOrder.planPriceId,
                    });
                }
                setLoading(false);
            });
        } else {
            if (loading !== "redirect") {
                navigate(generate("not-found"));
            }
        }
    }, [currOrder.planId]);

    useEffect(() => {
        const total = getTotalAmount();
        let final = total;

        if (data.payment_promo_info?.money_minus >= 0 && data.payment_promo_info?.amount >= 0) {
            final = data.payment_promo_info?.amount;
        }

        if (final < 0) {
            final = 0;
        }
        setAmount({
            ...amount,
            total: total,
            final: final,
        });
    }, [data]);

    if (!currOrder) {
        return null;
    }

    return (
        <div className="payment-wrapper">
            <div className="payment-header">
                <Header backEnable={false} backTitle={t("payment.payment")} showHelp={false} actions={[]} />
            </div>

            <Row gutter={[30, 30]}>
                <Col xs={24} sm={24} lg={16}>
                    <PaymentMethods
                        onChangePaymentMethod={(val) => {
                            setData({
                                selectedPaymentMethod: val,
                            });
                        }}
                    />
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <div className="promotion-section">
                        <div className="section section-title">{t("payment.select_plan_price")}</div>

                        <Select
                            className="app-select select-plan-price-wrapper"
                            placeholder={t("payment.select_plan_price")}
                            onChange={(value) => handleChangePlanPrice(value)}
                            value={data?.selectedPlanPriceId}
                            optionFilterProp="children"
                            showSearch
                            // allowClear
                        >
                            {data?.plan?.prices?.map((item, index) => (
                                <Select.Option key={`class-${index}`} value={item.id}>
                                    {item.months} {t("price.month")}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <PaymentOrderPanel
                        isLoading={loading === "plan"}
                        disableActions={loading === "purchase"}
                        orderItems={getOrderItems()}
                        amountCurrent={amount.final}
                        amountUsual={amount.total !== amount.final ? amount.total : undefined}
                        showActions={false}
                        promotions={data?.payment_promo_info?.promotions_info}
                    />

                    <div className="payment-actions">
                        <div className="promotion-section">
                            <div className="section section-title">{t("payment.promotion")}</div>
                            {data.payment_promo_info?.promotions_info?.length ? (
                                <div className="section section-applied-codes">
                                    {data.payment_promo_info?.promotions_info?.map((promo, i) => {
                                        return (
                                            <Tag
                                                key={`promo-${promo.code}`}
                                                closable
                                                onClose={() => handleRemovePromoCode(promo.code)}
                                                className="app-tag"
                                                title={promo.code}
                                            >
                                                {promo.code}
                                            </Tag>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className="input-promotion-wrapper">
                                <Input
                                    className="app-input"
                                    placeholder={t("payment.input_promotion_code_or_not")}
                                    value={inputPromoCode}
                                    onChange={(e) => setInputPromoCode(e.target.value)}
                                />
                                <CustomButton
                                    type="ghost"
                                    title={t("shared.apply")}
                                    onClick={() => handleAddPromoCode(inputPromoCode)}
                                    disabled={loading === "add-promo" || !inputPromoCode}
                                    className="btn-apply-promotion"
                                ></CustomButton>
                            </div>
                        </div>
                        <CustomButton
                            type="primary"
                            icon={
                                loading == "purchase" ? <Spin spinning={true} style={{ display: "flex" }} /> : undefined
                            }
                            title={loading === "redirect" ? t("shared.redirecting") : t("payment.purchase")}
                            onClick={handlePurchasePlan}
                            disabled={["plan", "purchase", "redirect"].includes(loading) || !data.selectedPaymentMethod}
                            className="btn-purchase"
                        ></CustomButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Payment;
