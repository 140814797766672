import moment from "moment";
import api from "..";

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The list of students who attended the assignment & answer info for each of them.
 */
export const getStudentDataListForAssignment = (assignmentId) => {
    const filters = [];
    const params = {
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/list_student_for_assignment/${assignmentId}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @param {object} fetchParams { recordIds: array of strings }
 * @returns The list of answers of assignment & all student's answers.
 */
export const getAnswerDataListForAssignment = (assignmentId, fetchParams = {}) => {
    const record_ids = fetchParams?.recordIds || [];
    const filters = [];
    const params = {
        ...(record_ids.length > 0 ? { record_ids: record_ids.join(",") } : {}),
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/list_report_record/${assignmentId}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The statistic overview of all students of the class who attended the assignment.
 */
export const getAssignmentOverview = (assignmentId) => {
    const filters = [];
    const params = {
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/student_submit/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The list of assignment's question records: record ids & question numbers.
 */
export const getAssignmentQuestionRecordList = (assignmentId) => {
    const filters = [];
    const params = {
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/list_question_assignment/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The list of assignment's question records for each question.
 */
export const getAssignmentQuestionRecordDetailList = (assignmentId, fetchParams = {}) => {
    const detail_ids = fetchParams?.recordIds || [];
    const filters = [];
    const params = {
        ...(detail_ids.length > 0 ? { detail_id: detail_ids.join(",") } : {}),
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/list_question_record/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @returns The list record of student
 */
export const getPersonalProgress = (fetchParams = {}) => {
    if (fetchParams.student_id) {
        const filters = {
            ...(fetchParams.class_id && { class_ids: fetchParams.class_id }),
            ...(fetchParams.subject_id && { category_id: fetchParams.subject_id }),
            ...(fetchParams.dateTo && { end_date: moment(fetchParams.dateTo).format("YYYY-MM-DD") }),
            ...(fetchParams.dateFrom && { begin_date: moment(fetchParams.dateFrom).format("YYYY-MM-DD") }),
        };
        const params = {
            ...filters,
        };

        return api
            .get(`/statistic/personal_progress/${fetchParams.student_id}`, params)
            .then(({ status, data, message }) => ({ status, data, message }))
            .catch((message) => ({ status: false, message }));
    } else {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (student_id)!", data: [] });
        });
    }
};

/**
 *
 * @returns The list statistic record of student
 */
export const getGeneralProgress = (fetchParams = {}) => {
    if (fetchParams.class_id) {
        const params = {
            assignment_id: fetchParams.assignment_id,
            ...(fetchParams.class_id !== "all" && { class_ids: fetchParams.class_id }),
            ...(fetchParams.subject_id && { category_id: fetchParams.subject_id }),
            ...(fetchParams.dateFrom && { begin_date: moment(fetchParams.dateFrom).format("YYYY-MM-DD") }),
            ...(fetchParams.dateTo && { end_date: moment(fetchParams.dateTo).format("YYYY-MM-DD") }),
        };

        return api
            .get(`/statistic/general_progress`, params)
            .then(({ status, data, message }) => ({ status, data, message }))
            .catch((message) => ({ status: false, message }));
    } else {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (class_id)!", data: [] });
        });
    }
};

/**
 *
 * @param1 {string} organization_id if user in organization
 * @param2 {string} user_id case admin view user
 */
export const getDashboadData = (params = {}) => {
    return api
        .get(`/statistic/dashboard`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};
