import api from "..";

const classFields = {
    basicInfo: [
        "name",
        "code",
        "begin_date",
        "end_date",
        "owner",
        "teachers",
        "user_avatars",
        "total_student",
        "is_official",
    ],
    scopeInfo: ["branch_id", "organization_id", "team_id"],
    unused: ["course_ids", "course_names"],
};

/**
 * (VERIFIED) Filter keys and values.
 * {
 *   key: {
 *     value1: label1,
 *     value2: label2,
 *     value3: label3,
 *   }
 * }
 */
export const classFilters = {
    is_active: {
        undefined: "all",
        1: "active",
        0: "inactive",
    },
};

/**
 * (VERIFIED) Get class list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchClasses = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`); // Class owner.
    fetchParams.owner_id && filters.push(`owner_id:${fetchParams.owner_id}`); // Class owner.
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.is_active !== undefined && filters.push(`is_active:${fetchParams.is_active}`);
    fetchParams.code && filters.push(`code:${fetchParams.code}`);
    fetchParams.branch_id && filters.push(`branch_id:${fetchParams.branch_id}`);
    fetchParams.branch_ids && filters.push(`branch_ids:${fetchParams.branch_ids};class_in_organization:1`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.class_in_organization && filters.push(`class_in_organization:${fetchParams.class_in_organization}`);
    (fetchParams.is_official || fetchParams.is_official === 0) &&
        filters.push(`is_official:${fetchParams.is_official}`);

    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _class_fields: `${[...classFields.basicInfo, ...classFields.scopeInfo]}`,
        // Other fields:
        _relations: `${fetchParams?.relations}`,
        ...(fetchParams?.relations?.includes("categories") > 0 ? { _category_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("file") > 0 ? { _file_fields: `${["src"]}` } : {}),
        ...(fetchParams?.relations?.includes("branch") > 0 ? { _branch_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("organization") > 0 ? { _organization_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("team") > 0 ? { _organization_fields: `${["name"]}` } : {}),
        // Pagination:
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/class", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                ...(!fetchParams.noPagination && {
                    pagination: {
                        showSizeChanger: false,
                        current: meta.pagination.page,
                        pageSize: meta.pagination.perPage,
                        total: meta.pagination.total,
                        lastPage: meta.pagination.lastPage,
                    },
                }),
            };
        })
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

/**
 * (VERIFIED) Get class list for student.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchClassesForStudent = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.owner_id && filters.push(`owner_id:${fetchParams.owner_id}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _class_fields: `${[...classFields.basicInfo, ...classFields.scopeInfo]}`,
        // Other fields:
        _relations: `${fetchParams?.relations}`,
        ...(fetchParams?.relations?.includes("categories") > 0 ? { _category_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("file") > 0 ? { _file_fields: `${["src"]}` } : {}),
        ...(fetchParams?.relations?.includes("branch") > 0 ? { _branch_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("organization") > 0 ? { _organization_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("team") > 0 ? { _organization_fields: `${["name"]}` } : {}),
        // Pagination:
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/class", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                ...(!fetchParams.noPagination && {
                    pagination: {
                        showSizeChanger: false,
                        current: meta.pagination.page,
                        pageSize: meta.pagination.perPage,
                        total: meta.pagination.total,
                        lastPage: meta.pagination.lastPage,
                    },
                }),
            };
        })
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchStudentsOfClass = (classId) => {
    const filters = [];
    filters.push(`role:student`);
    classId && filters.push(`class_id:${classId}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _class_user_fields: `${["class_id", "user_id"]}`,
        _relations: `${["user", "class"]}`,
        _user_fields: `${["role", "name", "phone", "gender", "avatar"]}`,
    };
    return api
        .get(`/class_user`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

/**
 * (VERIFIED) Get class list for student.
 * @param {string} classId Class id.
 * @param {string} categoryId Category id.
 * @param {string} scopeId Scope id.
 * @returns Object.
 */
export const fetchClassesSubjectsAssignments = (classId, categoryId, scopeId) => {
    // Filter:
    // const filters = [];
    // classId && filters.push(`class_id:${classId}`);
    // categoryId && filters.push(`category_id:${categoryId}`);
    // scopeId && filters.push(`organization_id:${scopeId}`);
    // Fetch params:
    const params = {
        organization_id: scopeId,
        class_id: classId,
        category_id: categoryId,
    };
    // Fetch:
    return api
        .get(`/assignment/list_selection`, params)
        .then(({ classes, categories, assignments }) => ({ classes, categories, assignments, status: true }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

/**
 * (VERIFIED) Get class detail.
 * @param {string} id Class id.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const findClass = (id, fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.class_in_organization && filters.push(`class_in_organization:${fetchParams.class_in_organization}`);
    // Fetch params:
    const params = {
        _filter: filters.join(";") || undefined,
        _class_fields: `${[...classFields.basicInfo, ...classFields.scopeInfo]}`,
        // Other fields:
        _relations: `${fetchParams?.relations}`,
        ...(fetchParams?.relations?.includes("categories") > 0 ? { _category_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("file") > 0 ? { _file_fields: `${["src"]}` } : {}),
        ...(fetchParams?.relations?.includes("branch") > 0 ? { _branch_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("organization") > 0 ? { _organization_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("team") > 0 ? { _organization_fields: `${["name"]}` } : {}),
    };
    // Fetch:
    return api
        .get(`/class/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Update class.
 * @param {string} id Class id.
 * @param {Object} data Class data.
 * @returns Object.
 */
export const updateClass = (id, data) => {
    return api
        .put(`/class/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Create class.
 * @param {Object} data Class data.
 * @returns Object.
 */
export const createClass = (data) => {
    return api
        .post("/class", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Delete a class.
 * @param {string} id Class id.
 * @param {string} scopeId Class scope id.
 * @returns Object.
 */
export const deleteClass = (id, fetchParams) => {
    if (!id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }
    // Fetch params:
    let url = `/class/${id}`;
    let queryCount = 0;
    if (fetchParams?.organization_id) {
        url += `${queryCount === 0 ? "?" : "&"}organization_id=${fetchParams.organization_id}`;
        queryCount++;
    }
    if (fetchParams?.class_in_organization) {
        url += `${queryCount === 0 ? "?" : "&"}class_in_organization=${fetchParams.class_in_organization}`;
        queryCount++;
    }
    // Fetch:
    return api
        .delete(url)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Delete a class user.
 * @param {string} id Class id.
 * @param {string} uid User id.
 * @param {string} scopeId Scope id.
 * @returns Object.
 */
export const deleteClassUser = (id, uid, scopeId) => {
    if (!id || !uid) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }
    const data = {
        class_id: id,
        user_id: uid,
        ...(scopeId ? { organization_id: scopeId } : {}),
    };
    return api
        .post(`/class/remove_user`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// User - student:
export const addStudent = (id, data) => {
    return api
        .put(`/class/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteStudent = (data) => {
    return api
        .post(`/class/remove_user`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// (CLASS INVITATION) User - teacher:
export const inviteTeacherToClass = (data) => {
    if (!data.email || !data.class_id) {
        return new Promise((resolve, reject) => {
            reject({ status: false });
        }).catch(() => ({ status: false }));
    }

    return api
        .post("/class/mail_invite_teacher", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

export const addTeacherToClass = (classId) => {
    return api
        .put(`/class/${classId}/add_class_user`)
        .then(({ status, success }) => ({ status, message: success }))
        .catch(({ message }) => ({ status: false, message }));
};

// (CLASS INVITATION) User - student:
export const addStudentToClass = (classId) => {
    return api
        .put(`/class/${classId}/add_class_user_student`)
        .then(({ status, success }) => ({ status, message: success }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * Add users to a class.
 * @param {Object} data Users.
 * @returns Object.
 */
export const addUsersToClass = (data) => {
    const { class_id, users } = data;

    if (!class_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (class_id)!" });
        });
    }
    if (!users?.length) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (users)!" });
        });
    }

    return api
        .put(`/class/${class_id}/add_users_to_class`, data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const mergeClass = (class_id, class_ids) => {
    return api
        .post(`/class/merge_class`, { class_id, class_ids })
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * Get Excel user list sample file.
 * @returns Blob.
 */
export const downloadSampleUserListFile = () => {
    // Fetch params:
    const headers = {
        "Access-Control-Allow-Origin": "*",
    };
    const otherAxiosRequestConfig = {
        responseType: "blob",
    };
    // Fetch:
    return api
        .get("/file/import_class_user.xlsx", {}, headers, otherAxiosRequestConfig)
        .then((data) => data)
        .catch(() => ({ status: false }));
};

/**
 * Upload & import class's user list (Excel).
 * @param {string} data Class id.
 * @param {Object} data Data.
 * @returns Object.
 */
export const importUserListToClass = (class_id, data) => {
    if (!class_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (class_id)!" });
        });
    }
    if (!data) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information (data)!" });
        });
    }

    return api
        .post(`class/${class_id}/import_user`, data)
        .then(({ status, message, success }) => ({ status, message: message || success }))
        .catch(() => ({ status: false }));
};
