import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import FileViewer from "../FileViewer";
import "./FileViewerModal.scss";

function FileViewerModal(props) {
    const {
        type = "",
        className = "",
        headerTitle = "",
        fileData = {},
        visible = false,
        onOk = () => {},
        onCancel = () => {},
        ...rest
    } = props;

    const { t } = useTranslation();

    const handleOk = () => {
        if (onOk && onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        if (onCancel && onCancel instanceof Function) {
            onCancel();
        }
    };

    if (type === "like-google-drive") {
        document.body.style.overflow = visible === true ? "hidden" : "visible";

        const handleDownloadFile = (e, url, name) => {
            e.preventDefault();
            /**
             * Solution 1 (NOT GOOD):
             */
            // const link = document.createElement("a");
            // link.download = name;
            // link.href = uri;
            // link.target = "_blank";
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            /**
             * Solution 2 (SOLVED):
             */
            fetch(url)
                .then((res) => res.blob())
                .then((blobObj) => {
                    const blob = window.URL.createObjectURL(blobObj);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = blob;
                    a.download = url.replace(/^.*[\\/]/, "");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(blob);
                })
                .catch(() => alert("Can not download the file!"));
        };

        return (
            <div className={`file-viewer-modal view-file-ggdrive${className ? " " + className : ""}`}>
                <div className={`modal-view-file${visible ? " visible" : ""}`}>
                    <div className="modal-wrapper">
                        <div className="modal-overlay" onClick={handleCancel}></div>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="header-left-section">
                                    <span className="action-button" onClick={handleCancel}>
                                        <ArrowLeftOutlined />
                                    </span>
                                    <span className="view-file-name">
                                        {`${fileData?.name ? " " + fileData?.name.toLowerCase() : ""}`}
                                    </span>
                                </div>
                                <div className="header-right-section">
                                    <span
                                        className="action-button"
                                        onClick={(e) => {
                                            handleDownloadFile(e, fileData?.src || "", fileData?.name || "unknown");
                                        }}
                                    >
                                        <DownloadOutlined />
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body" onClick={handleCancel}>
                                <FileViewer fileSrc={fileData.src} fileType={fileData.type} fileName={fileData.name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Modal
            className={`file-viewer-modal view-file-default${className ? " " + className : ""}`}
            centered
            title={
                <div>
                    <div>{headerTitle}</div>
                    <u>{`${fileData?.name ? " " + fileData?.name : ""}`}</u>
                </div>
            }
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width="calc(100vw - 60px)"
            height="calc(100vh - 60px)"
            style={{
                maxWidth: "1400px",
                maxHeight: "1400px",
                margin: "30px 0",
            }}
        >
            {fileData?.src ? (
                <iframe
                    src={fileData?.src || "#"}
                    title={fileData?.name || "unknown_file"}
                    style={{ width: "100%", height: "100%", border: "2px solid #aaa" }}
                ></iframe>
            ) : (
                <div>{t("my_storage.view_file_error")}</div>
            )}
        </Modal>
    );
}

export default FileViewerModal;
