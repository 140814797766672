import { useRef, useState } from "react";
import { t } from "i18next";
import InputEditor from "src/modules/components/InputEditor";
import QuestionContentDetail from "../components/ContentDetail";
import "./WritingShort.scss";
import clsx from "clsx";

function WritingShort({
    isReadonly = false,
    question,
    defaultAnswer /** @param {string} defaultAnswer. Student's answer. */,
    onChange = () => {},
    exam_types,
}) {
    const timeoutIdChange = useRef(null);

    const [inputAnswer, setInputAnswer] = useState(defaultAnswer?.[0]);

    const handleChangeAnswered = (str) => {
        setInputAnswer(str);
        // Limit onChange calls:
        clearTimeout(timeoutIdChange.current);
        timeoutIdChange.current = setTimeout(() => {
            if (onChange instanceof Function) {
                onChange({
                    answered: [str],
                });
            }
        });
    };

    return (
        <div className={clsx("q-writing", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            <div className="q-content-title">
                <QuestionContentDetail isReadonly={isReadonly} value={{ question }} />
            </div>

            <div className="q-content-section">
                <div className="view-writing">
                    <InputEditor
                        className="app-input"
                        value={inputAnswer || ""}
                        onChange={handleChangeAnswered}
                        placeholder={t("q.write_answer_here")}
                        disabled={isReadonly}
                    />
                </div>
            </div>
        </div>
    );
}

export default WritingShort;
