import React from "react";
import { Link } from "react-router-dom";
import { Button, ConfigProvider, Table as AntTable, Tooltip } from "antd";
import { default as CustomButton } from "src/modules/components/Button";
import CustomizeRenderEmpty from "../CustomizeRenderEmpty";

const Table = (props) => {
    const {
        loading = false,
        columns = [],
        actions = [],
        dataSource = [],
        pagination = {},
        rowKey = "id",
        nodata_title,
        actionAlign = "center",
    } = props;

    if (actions && actions.length > 0) {
        columns.push({
            key: "action",
            width: 250,
            render: (text, record) => (
                <span className="table-action" style={{ justifyContent: actionAlign }}>
                    {actions.map((action, index) => {
                        const {
                            title,
                            icon,
                            link,
                            onClick,
                            width,
                            tooltip = "",
                            handleHidden = () => {},
                            ...rest
                        } = action;

                        if (handleHidden?.(record)) return;

                        if (link) {
                            return (
                                <Link to={link} key={index} className="table-action__icon">
                                    {icon && typeof icon === "string" && (
                                        <img src={icon} width={width || "25"} className="btn__content-image" />
                                    )}
                                    {icon && React.isValidElement(icon) && <>{icon}</>}
                                </Link>
                            );
                        }
                        if (title) {
                            return (
                                <CustomButton
                                    key={index}
                                    type="ghost"
                                    title={title}
                                    onClick={() => onClick(record)}
                                    className="table-action__btn"
                                    {...rest}
                                />
                            );
                        }
                        return (
                            <Tooltip key={index} placement="bottom" title={tooltip}>
                                <span onClick={() => onClick(record)} className="table-action__icon">
                                    {icon && typeof icon === "string" && (
                                        <img src={icon} width={width || "25"} className="btn__content-image" />
                                    )}
                                    {icon && React.isValidElement(icon) && <>{icon}</>}
                                </span>
                            </Tooltip>
                        );
                    })}
                </span>
            ),
        });
    }

    return (
        <ConfigProvider renderEmpty={() => <CustomizeRenderEmpty nodata_title={nodata_title} />}>
            <AntTable
                className="app-table"
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={pagination}
                rowKey={rowKey}
                {...props}
            />
        </ConfigProvider>
    );
};

export default Table;
