import { useSelector } from "react-redux";
import ExamBank from "../../ExamBank";

const MyExams = () => {
    const { user } = useSelector((state) => state.auth);

    const fixedFetchParams = {
        user_id: user.id,
    };

    return <ExamBank fixedFetchParams={fixedFetchParams} excludedFilterFields={["user_id"]} />;
};

export default MyExams;
