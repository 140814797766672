import React, { useContext, useEffect, useMemo, useRef } from "react";
import PDFQuestionItem from "../PDFQuestionItem";
import { Affix, Collapse, Divider, Form, Input, Modal, notification } from "antd";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Questionlist.scss";
import Button from "src/modules/components/Button";
import { Button as AntdButton } from "antd";
import { useValues } from "src/hooks";
import QuestionContentDetail from "src/modules/components/QuestionPanel/components/ContentDetail";
import { useTranslation } from "react-i18next";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import QuestionDetail from "src/modules/containers/QuestionDetail";
import { useSelector } from "react-redux";
import { findQuestion } from "src/api/containers/question";
import QuestionContent from "src/modules/components/QuestionContent";
import QuestionSection from "../QuestionSection";
import { v4 as uuidv4 } from "uuid";
import PopupFileManager from "src/modules/components/FileManager/popup";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";

function getDetailFields(detail = {}) {
    const keysArray = ["is_publish", "is_public", "_id"];
    const resultObject = Object.keys(detail).reduce((acc, key) => {
        if (!keysArray.includes(key)) {
            acc[key] = detail[key];
        }
        return acc;
    }, {});

    return resultObject;
}

function QuestionList({ form, isModalQuestionType = false, showModalQuestionType }) {
    const { t } = useTranslation();
    const {
        currentThumbnail,
        defaultListQuestion,
        setListQuestion,
        listThumbnails,
        setListThumbnails,
        currentTab,
        setCurrentThumbnail,
    } = useContext(ExamPDFContext);
    const { lastedQuestionChange } = useSelector((state) => state.exam);

    const handleSelectfileRef = useRef();
    const fmController = PopupFileManager.useController();

    const listQuestion = useMemo(() => {
        const question = listThumbnails.find((item) => item.file_id === currentThumbnail?.file_id)?.["questions"] || [];
        // return question?.map((item, idx) => {
        //     return { ...item, page: idx + 1 };
        // });

        return question;
    }, [currentThumbnail, listThumbnails]);

    const [values, setValues] = useValues({
        questionList: defaultListQuestion || [],
        isModalUpdateting: false,
        selectedQuestion: {},
        max_score: 0,
        isShowResult: false,
    });

    const questionsWithSection = useMemo(() => {
        const currentListSection = [...(currentThumbnail?.sections || [])];
        let result = [];
        if (currentListSection?.length) {
            result = currentListSection.filter((item) => !item?.after_question_id);

            listQuestion.forEach((q) => {
                result = [...result, q];

                currentListSection.forEach((s) => {
                    if (s.after_question_id === q.id) {
                        const newS = { ...s };
                        newS.isSection = true;

                        result?.push(newS);
                    }
                });
            });
            return result;
        } else {
            return listQuestion;
        }
    }, [listQuestion, currentThumbnail?.sections]);

    function checkIsHaveQuestion(qid, question = []) {
        return !!question.find((q) => q.id == qid);
    }

    useEffect(() => {
        if (lastedQuestionChange.question_id) {
            findQuestion(lastedQuestionChange.question_id, "", {}, ["tags", "category", "files"]).then((res) => {
                if (res.status) {
                    if (
                        lastedQuestionChange.type === "create" &&
                        !checkIsHaveQuestion(lastedQuestionChange.question_id, listQuestion)
                    ) {
                        const newData = { ...res.data, ...getDetailFields(res.data?.detail) };

                        const newListThumbnail = listThumbnails.map((item) => {
                            if (item.file_id === currentThumbnail.file_id) {
                                if (!item.questions) {
                                    item.questions = [];
                                }
                                item.questions = [...item.questions, newData];
                            }

                            return item;
                        });

                        setListThumbnails(newListThumbnail);
                    } else if (
                        lastedQuestionChange.type === "update" &&
                        checkIsHaveQuestion(lastedQuestionChange.question_id, listQuestion)
                    ) {
                        const index = listQuestion.findIndex((q) => q.id === lastedQuestionChange.question_id);
                        setValues({ questionList: ([...listQuestion][index] = res.data) });
                        const newList = [...listQuestion];
                        newList[index] = res.data;
                        setListQuestion(newList);
                    }
                }
            });
        }
    }, [lastedQuestionChange]);

    function handleRemoveQuestion(qId) {
        const newData = listQuestion.filter((item) => item.id !== qId);
        const newThumbsnails = [...listThumbnails];
        let item = newThumbsnails.find((item) => item.file_id === currentThumbnail.file_id);
        if (item.questions) {
            item.questions = newData;
        }
        setListThumbnails(newThumbsnails);
        setValues({ questionList: newData });
    }

    useEffect(() => {
        const max_score = listQuestion.reduce((acc, cur) => acc + cur?.score, 0);
        form.setFieldsValue({
            max_score: max_score,
        });
        setValues({ max_score: max_score });
    }, [listQuestion]);

    useEffect(() => {
        if (listThumbnails?.length && !currentThumbnail) {
            setCurrentThumbnail(listThumbnails?.[0]);
        }
    }, [listThumbnails]);

    function handleChangeSections(sectionData) {
        let newListSection = [];

        if (sectionData?.key) {
            // case update
            const newSection = structuredClone(sectionData);

            newListSection = currentThumbnail?.sections?.map((item) => {
                if (item.key === sectionData.key) {
                    return newSection;
                }
                return item;
            });
        } else {
            //case thêm mới
            let after_question_id = null;
            if (listQuestion?.length) {
                after_question_id = listQuestion[listQuestion?.length - 1]?.id;
            }
            const newSection = {
                key: uuidv4(),
                name: "",
                file_id: null,
                after_question_id: after_question_id,
                isSection: true,
            };
            newListSection = [...(currentThumbnail?.sections || []), newSection];
        }

        const newListThumbsnail = listThumbnails.map((item) => {
            if (item.file_id === currentThumbnail.file_id) {
                const newItem = structuredClone(item);
                newItem.sections = newListSection;

                setCurrentThumbnail(newItem);
                return newItem;
            }
            return item;
        });

        setListThumbnails?.(newListThumbsnail);
    }

    return (
        <>
            <div className="pdf-question-list">
                {questionsWithSection.map((item, idx) => {
                    if (item?.isSection) {
                        return (
                            <QuestionSection
                                key={`${item.key}_${idx}`}
                                data={item}
                                onFinishChange={handleChangeSections}
                                openFileManager={() => fmController.call("open")}
                                onSelectedFileRef={handleSelectfileRef}
                                mode={currentTab.tabKey}
                            />
                        );
                    }
                    return (
                        <PDFQuestionItem
                            question={item}
                            key={`${item.key}_${idx}`}
                            qIndex={item.order}
                            setData={setValues}
                            handleRemoveQuestion={handleRemoveQuestion}
                            isShowResult={values.isShowResult}
                        />
                    );
                })}

                {currentTab?.tabKey == "detail" ? (
                    <>
                        <Divider>
                            <AntdButton shape="round" onClick={() => handleChangeSections()}>
                                Thêm section
                            </AntdButton>
                        </Divider>
                        <ModalSelectQuestionType
                            type="exam_bank"
                            exam_types={"pdf"}
                            visible={isModalQuestionType}
                            onCancel={() => {
                                showModalQuestionType?.(false);
                            }}
                            exam_file_id={currentThumbnail.file_id}
                        ></ModalSelectQuestionType>

                        <Modal
                            visible={values.isModalUpdateting}
                            centered
                            title={""}
                            closable={false}
                            footer={null}
                            onCancel={() => setValues({ isModalUpdateting: false })}
                            width={"80%"}
                            bodyStyle={{
                                overflowY: "auto",
                                maxHeight: "calc(100vh - 100px)",
                                backgroundColor: "#F0F6FB",
                            }}
                            destroyOnClose={true}
                        >
                            <QuestionDetail
                                type="exam_bank"
                                exam_types="pdf"
                                // questionId={values.selectedQuestion.id || values.selectedQuestion._id}
                                editingQuestion={values.selectedQuestion}
                                exam_file_id={currentThumbnail.file_id}
                                typeOfQuestion={values.selectedQuestion.type}
                                closeModal={() => setValues({ isModalUpdateting: false, selectedQuestion: {} })}
                                refetch={() => {}}
                            ></QuestionDetail>
                        </Modal>

                        <PopupFileManager
                            controller={fmController}
                            isMultiple={false}
                            onSelectFile={(selectedFile) => {
                                if (selectedFile?.id && selectedFile?.src) {
                                    if (validateFileBeforeUpload(selectedFile.src).type === "audio") {
                                        handleSelectfileRef.current?.({
                                            file_id: selectedFile.id,
                                            src: selectedFile.src,
                                        });
                                        fmController.call("close");
                                    } else {
                                        const str = fileManagerConfigs.audioAcceptedExtensionList.join(", ");
                                        notification.error({
                                            message: (
                                                <>
                                                    <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                                    <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                                </>
                                            ),
                                        });
                                    }
                                }
                            }}
                        />
                    </>
                ) : null}
            </div>

            {/* {currentTab?.tabKey === "detail" ? (
                <div className="pdf-question-list_top">
                    <Button
                        title="Thêm câu hỏi"
                        icon={<PlusCircleOutlined />}
                        onClick={() => setValues({ isModalQuestionType: true })}
                    />
                    <span>
                        <strong style={{ fontSize: 16 }}> Tổng điểm:</strong>
                        {` ${values.max_score}`}
                        <Form.Item name="max_score" style={{ display: "none" }}>
                            <Input disabled className="app-input" style={{ display: "none" }} />
                        </Form.Item>
                    </span>
                </div>
            ) : null} */}
            {currentTab?.tabKey === "preview" ? (
                <div className="pdf-question-list_top">
                    <Button
                        title={values.isShowResult ? "Ẩn đáp án" : "Hiện đáp án"}
                        // icon={<PlusCircleOutlined />}
                        onClick={() => setValues({ isShowResult: !values.isShowResult })}
                    />
                </div>
            ) : null}
        </>
    );
}

export default QuestionList;
