import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Dropdown, Empty, Row, Space, Tooltip } from "antd";
import {
    FieldTimeOutlined,
    UserOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    FireOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import { default as CustomButton } from "src/modules/components/Button";
import { useRouting } from "src/utils/router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import Pencil from "src/assets/images/pencil.svg";
import { getAssignmentResult } from "src/utils/helpers";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css/pagination";

import SwiperButton from "../SwiperButton";
import RoundStatus from "../RoundStatus";
import { useValues } from "src/hooks";
import "./Rounds.scss";

const slidePerPage = {
    320: {
        slidesPerView: 1,
        spaceBetween: 20,
    },
    576: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    992: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 3,
        spaceBetween: 30,
    },
    1920: {
        slidesPerView: 5,
        spaceBetween: 30,
    },
};
const RoundsStudent = (props) => {
    const {
        handleRefetchAsignment = () => {},
        competition = {},
        listAssignments = [], //list vòng thi
    } = props;

    const formatDate = useSelector((state) => state.general.formatDate);
    const { generate } = useRouting();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // const [swiperRef, setSwiperRef] = useState(null);
    const swiperRef = useRef(null);

    const [values, setValues] = useValues({
        isBeginning: true,
        isEnd: false,
    });

    const renderRecordStatus = ({ status }) => {
        switch (status) {
            case "donot":
                return <div className="test_status test_status-danger">{t("assignment.donot")}</div>;
            case "submited":
                return <div className="test_status test_status-success">{t("assignment.submited")}</div>;
            case "marked":
                return <div className="test_status test_status-info">{t("assignment.marked")}</div>;
            case "doing":
                return <div className="test_status test_status-warn">{t("assignment.doing")}</div>;
            case "expired":
                return <div className="test_status test_status-danger">{t("assignment.expired")}</div>;
            case "not_start":
                return <div className="test_status test_status-disable">{t("assignment.not_start")}</div>;
        }
    };

    const handleDoHomeWork = (assignmentId) => {
        navigate(`${generate("test", { id: assignmentId })}?is_competition=${competition?.id}`);
    };

    const handleClickReview = (recordId) => {
        const competition_id = searchParams.get("competition_id");
        navigate(generate("score", { id: recordId }) + `?prev=rounds&competition_id=${competition_id}`);
    };

    const getRoundStatus = (curentDate, beginDate, endDate) => {
        if (moment(curentDate).isBefore(beginDate)) {
            return "upcoming";
        }
        if (moment(curentDate).isAfter(endDate)) {
            return "finished";
        }
        if (moment(curentDate).isAfter(beginDate) && moment(curentDate).isBefore(endDate)) {
            return "happening";
        }
        return "";
    };

    const renderButtonDoHomeWork = (arg1, assignmentId, isPreviousDone, roundStatus) => {
        const { status, id } = arg1;

        if (!isPreviousDone && roundStatus !== "finished") {
            return (
                <Tooltip type="info" title={t("ranking.must_complete_previous_round")}>
                    {/* // để cái này({" "}) để show đc tooltip, do nút bị disable nên nếu ko có sẽ ko show tooltip đc */}{" "}
                    <CustomButton isDisabled={true} title={t("assignment.do_homework")} icon={Pencil} type="primary" />
                </Tooltip>
            );
        }

        if (roundStatus === "upcoming" || status === "not_start") {
            return <CustomButton isDisabled={true} title={t("assignment.do_homework")} icon={Pencil} type="primary" />;
        }
        if (roundStatus === "happening") {
            if (status === "donot") {
                return (
                    <CustomButton
                        onClick={() => handleDoHomeWork(assignmentId)}
                        title={t("assignment.do_homework")}
                        icon={Pencil}
                        type="primary"
                    />
                );
            } else if (status === "doing") {
                return (
                    <CustomButton
                        onClick={() => handleDoHomeWork(assignmentId)}
                        title={t("assignment.continue_doing")}
                        icon={Pencil}
                        type="primary"
                    />
                );
            } else if (status === "submited" || status === "marked") {
                return <CustomButton isDisabled={true} title={t("statistical.submitted")} type="primary" />;
            }
        }
        if (roundStatus === "finished") {
            //đã làm bài nhưng chưa submit
            // if ((status === "doing" || status === "expired") && id) {
            //     return (
            //         <CustomButton
            //             onClick={() => handleDoHomeWork(assignmentId)}
            //             title={t("test.submit")}
            //             icon={Pencil}
            //             type="primary"
            //         />
            //     );
            // } else
            if (status === "marked" || status === "passed" || status === "not_ranked") {
                return (
                    <CustomButton
                        onClick={() => handleClickReview(id)}
                        title={t("assignment.review")}
                        // icon={Pencil}
                        type="primary"
                    />
                );
            } else {
                return (
                    <CustomButton isDisabled={true} title={t("assignment.do_homework")} icon={Pencil} type="primary" />
                );
            }
        }
    };

    const renderTestResult = (record, roundStatus) => {
        const { id, result, status } = record || {};
        if (roundStatus === "finished") {
            return getAssignmentResult({ record });
        } else {
            return t("ranking.not_result");
        }
    };

    const handleChangeSlide = (type) => {
        if (type === "prev") {
            swiperRef.current?.slidePrev();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        } else if (type === "next") {
            swiperRef.current?.slideNext();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        }
    };

    return (
        <div className="class-list rounds rounds-student">
            <Row>
                <Col span={12}>
                    <div className="rounds-header">
                        <div className="rounds-header-title">
                            <h2>{competition?.name}</h2>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="rounds-header-actions">
                        <div className="rounds-scroll-btn">
                            <Button
                                disabled={values.isBeginning}
                                onClick={() => handleChangeSlide("prev")}
                                icon={<ArrowLeftOutlined />}
                            ></Button>
                            <Button
                                onClick={() => handleChangeSlide("next")}
                                icon={<ArrowRightOutlined />}
                                disabled={values.isEnd}
                            ></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Swiper
                        // centeredSlides={true}
                        autoplay={true}
                        spaceBetween={30}
                        centeredSlidesBounds={true}
                        breakpoints={slidePerPage}
                        pagination={{
                            type: "fraction",
                        }}
                        pag
                        className="mySwiper"
                    >
                        <SwiperButton swiperRef={swiperRef} style={{ display: "none" }} />
                        {listAssignments.map((item, index) => {
                            // const isAllowActions = item?.ranking_type
                            //     ? compareTwoTime(new Date(), item?.end_date)
                            //     : true;
                            let isPreviousDone;
                            const idx = index - 1;
                            if (idx < 0) isPreviousDone = true;
                            else isPreviousDone = listAssignments?.[idx]?.record?.result === "passed";
                            return (
                                <Col key={index} xs={24} md={12} lg={8} xxl={6}>
                                    <SwiperSlide key={index}>
                                        <div className="class-item-wrapper" style={{ minWidth: 200 }}>
                                            <div
                                                className="class-item"
                                                // onClick={() => navigate({ pathname: generate("ranking", { id: item.id }) })}
                                            >
                                                <div className="class-item-header-top">
                                                    <span className="round-index">{`${t("ranking.round")} ${
                                                        item?.order
                                                    }`}</span>
                                                    <RoundStatus
                                                        status={getRoundStatus(
                                                            new Date(),
                                                            item?.begin_date,
                                                            item?.end_date
                                                        )}
                                                    />
                                                </div>

                                                <p className="class-item-header">{item?.name}</p>
                                                {/* <div className="class-item-student">
                                            {item.user_avatars?.avatars?.length ? (
                                                <Avatar.Group maxCount={6}>
                                                    {item.user_avatars.avatars.map((user, index) => (
                                                        <Avatar src={user?.src || NoAvatar} key={index} />
                                                    ))}
                                                    {item.user_avatars.user_avatars_left ? (
                                                        <Avatar>+{item.user_avatars.user_avatars_left}</Avatar>
                                                    ) : null}
                                                </Avatar.Group>
                                            ) : null}
                                        </div>
                                        <p>
                                            <QrcodeOutlined className="class-item-icon" />
                                            {`${t("class.code")}: ${item.code ? item.code : ""}`}
                                        </p> */}

                                                <p>
                                                    <FieldTimeOutlined className="class-item-icon" />
                                                    {`${t("assignment.start_from")}: ${
                                                        item.begin_date
                                                            ? moment(item.begin_date).format("DD/MM/YYYY HH:MM")
                                                            : ""
                                                    }`}
                                                </p>
                                                <p>
                                                    <FieldTimeOutlined className="class-item-icon" />
                                                    {`${t("assignment.deadline")}: ${
                                                        item.end_date
                                                            ? moment(item.end_date).format("DD/MM/YYYY HH:MM")
                                                            : ""
                                                    }`}
                                                </p>
                                                <p>
                                                    <UserOutlined className="class-item-icon" />
                                                    {`${t("shared.owner")}: ${item?.user?.name || ""}`}
                                                </p>
                                                <p>
                                                    <FireOutlined className="class-item-icon" />
                                                    {t("assignment.status")}:{renderRecordStatus(item?.record)}
                                                </p>
                                                <p>
                                                    <FileDoneOutlined className="class-item-icon" />
                                                    {`${t("assignment.result")}: `}
                                                    {renderTestResult(
                                                        item?.record,
                                                        getRoundStatus(new Date(), item?.begin_date, item?.end_date)
                                                    )}
                                                </p>

                                                {/* <CustomButton
                                            title={t("report.view_detail")}
                                            icon={<ReviewIcon />}
                                            type="primary"
                                            isDisabled={false}
                                            onClick={() => {
                                                const a = generate("ranking", { assignment_id: item?.id });
                                                navigate(
                                                    `${a}?assignment_id=${item?.id}&competition_id=${competition?.id}`
                                                );
                                            }}
                                        /> */}
                                                <Space wrap="wrap">
                                                    {renderButtonDoHomeWork(
                                                        item?.record,
                                                        item?.id,
                                                        isPreviousDone,
                                                        getRoundStatus(new Date(), item?.begin_date, item?.end_date)
                                                    )}
                                                </Space>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Col>
                            );
                        })}
                    </Swiper>
                </Col>
            </Row>

            {listAssignments.length <= 0 && <Empty description={t("ranking.no_rounds")} />}
        </div>
    );
};

export default RoundsStudent;
