import React, { useMemo } from "react";
import { Col, Form, Radio, Row } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUserScopeByScopeId, getUserScopeSelected } from "src/api/helpers/userScope";
import "./SettingPublishingStatus.scss";

function SettingPublishingStatus({
    questionScopeId /** @param {string} questionScopeId - Organization id, team id or undefined. */,
}) {
    const { t } = useTranslation();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // Question scope:
    const { scopeId, scopeKey, scopeName } = useMemo(() => {
        if (questionScopeId) {
            return { scopeId: questionScopeId, ...getUserScopeByScopeId(user, questionScopeId) };
        } else {
            return { scopeId: fParamsOrgMember?.organization_id, ...getUserScopeSelected(user) };
        }
    }, [user, questionScopeId]);

    return (
        <Row className="question-formitems set-publishing-status">
            <Col>
                <Form.Item
                    name="is_public"
                    label={t("question.publishing_status")}
                    rules={[
                        {
                            required: true,
                            message: (
                                <>
                                    <WarningOutlined /> {t("question.required_publishing_status")}
                                </>
                            ),
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio className="app-radio" value={false}>
                            {t("shared.private")}
                        </Radio>
                        <Radio className="app-radio" value={true}>
                            {t("shared.public")}
                        </Radio>
                        {scopeId && scopeKey ? (
                            <Radio className="app-radio" value={scopeId}>
                                {t(`shared.local_of_${scopeKey}`)}
                                <span className="status-field-desc">({scopeName})</span>
                            </Radio>
                        ) : null}
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    );
}

export default SettingPublishingStatus;
