import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";
import "./ExamResult.scss";
import clsx from "clsx";

function ExamResult(props) {
    const {
        strokeColor = "#F0F7FF",
        trailColor = "rgba(255, 255, 255, 0.3)",
        width = 320,
        score = 0,
        totalScore = 0,
        countQuestions = 0,
        countCorrectAnswers = 0,
        isMobile = false,
        size = "normal",
        className,
    } = props;

    const { t } = useTranslation();

    const [percent, setPercent] = useState(0);

    useEffect(() => {
        if (totalScore > 0) {
            setPercent((score / totalScore) * 100);
        }
    }, [score, totalScore]);

    const getFontSizeByLengthScore = (score) => {
        const length = String(score).length;
        const fontSizePerLength = 4;
        const result = 79 - length * fontSizePerLength;
        return result <= 18 ? 18 : result;
    };

    return (
        <div className={clsx("exam-result", { mobile: isMobile, [size]: 1, [className]: !!className })}>
            <Progress
                className="progress-bar"
                type="dashboard"
                status="normal"
                gapPosition="bottom"
                gapDegree={155}
                width={width}
                percent={percent}
                strokeColor={strokeColor}
                trailColor={trailColor}
                format={(percent, successPercent) => {
                    return (
                        <span className="result-wrapper">
                            <span className="score-wrapper">
                                <span
                                    className="score-value"
                                    style={{ fontSize: getFontSizeByLengthScore(score.toFixed(2)) }}
                                >
                                    {score.toFixed(2) || 0}
                                </span>{" "}
                                <span className="score-text">{t("shared.score")}</span>
                            </span>
                            <span className="result-totalScore">
                                {t("exam_result.count_correct_answers")}:{" "}
                                <span>
                                    {countCorrectAnswers || 0}/{countQuestions || 0}
                                </span>
                            </span>
                        </span>
                    );
                }}
            />
        </div>
    );
}

export default ExamResult;
