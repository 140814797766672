import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import useValues from "src/hooks/useValues";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";
import { fetchAssignments } from "src/api/containers/assignment";
import { listCompetitions } from "src/api/containers/Competition";
import { useFetch } from "src/hooks";

const FilterMenu = ({
    fParams = {}, // params about user, organization,...
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleCloseFilterMenu = () => {},
    initAssignment = {},
    initCompetition = {},
}) => {
    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        assignments: [],
        competition: initCompetition,
        assignment: initAssignment,
        isFetchingCates: false,
        isLoadingAssignment: false,
    });
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.

    const [competitionLoading, competitionData, competitionPagination, competitionFetch, competitionRefetch] = useFetch(
        { page: 1, slug: "", ...fParams },
        listCompetitions
    );

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        // Save new applied filter values:
        const newFilterParams = [];
        formData.assignment_id &&
            newFilterParams.push({
                name: "assignment_id",
                value: formData.assignment_id,
                labelName: t("ranking.round2"),
                labelValue: values.assignment?.name,
            });
        formData.competition_id &&
            newFilterParams.push({
                name: "competition_id",
                value: formData.competition_id,
                labelName: t("nav.competitions"),
                labelValue: values.competition?.name,
            });
        formData.ranking_type &&
            newFilterParams.push({
                name: "ranking_type",
                value: formData.ranking_type,
                labelName: t("ranking.ranking_type"),
                labelValue: t("ranking.ranking_type"),
            });

        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams, formData, values.assignment);
    };

    const handleFetchAssignments = (params) => {
        setValues({ isLoadingAssignment: true });
        fetchAssignments(params)
            .then(({ status, data }) => {
                if (status) {
                    setValues({ assignments: data });
                }
            })
            .catch(({ message }) => {
                // notification.error({ message: message });
            })
            .finally(() => {
                setValues({ isLoadingAssignment: false });
            });
    };

    const selectAssignment = (id) => {
        if (id) {
            const assignment = values.assignments.find((item) => item.id === id);
            setValues({ assignment: assignment });
        }
    };

    const selectCompetition = (id) => {
        if (id) {
            const competition = competitionData?.find((item) => item.id === id);
            setValues({ competition: competition });
            handleFetchAssignments({ is_ranking: 1, noPagination: 1, competition_id: id });
        } else {
            setValues({ competition: {}, assignment: {}, assignments: [] });
            form.setFieldsValue({
                assignment_id: undefined,
            });
        }
    };

    useEffect(() => {
        competitionFetch({}, true);
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = {
                    value: filterParams[i].value,
                    label: filterParams[i]?.labelValue,
                };
            }

            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            setCurrFilterParams(filterParams);
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid form-horizontal" layout="horizontal">
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Form.Item name="competition_id" label={t("nav.competitions")}>
                        <Select
                            className="app-select"
                            placeholder={t("ranking.select_competition")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            onChange={(id) => selectCompetition(id)}
                            loading={values.isLoadingAssignment}
                            // onSearch={(kw) => handleSearchAssignment(kw)}
                            notFoundContent={
                                values.isLoadingAssignment ? (
                                    <span style={{ display: "flex" }}>
                                        <Spin style={{ marginRight: "10px", minHeight: 50 }} />{" "}
                                        {`${t("shared.loading")}...`}
                                    </span>
                                ) : undefined
                            }
                        >
                            {competitionData?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <br />

                    <Form.Item name="assignment_id" label={t("ranking.round2")}>
                        <Select
                            className="app-select"
                            placeholder={t("ranking.select_round")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            onChange={(id) => selectAssignment(id)}
                            loading={values.isLoadingAssignment}
                            // onSearch={(kw) => handleSearchAssignment(kw)}
                            notFoundContent={
                                values.isLoadingAssignment ? (
                                    <span style={{ display: "flex" }}>
                                        <Spin style={{ marginRight: "10px", minHeight: 50 }} />{" "}
                                        {`${t("shared.loading")}...`}
                                    </span>
                                ) : undefined
                            }
                        >
                            {values.assignments.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {/* <Col span={24}>
                    <Form.Item name="ranking_type" label={t("ranking.ranking_type")}>
                        <Select
                            className="app-select"
                            placeholder={t("ranking.ranking_type")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                        >
                            <Select.Option key={"point"} value={"point"}>
                                {t("ranking.top")}
                            </Select.Option>
                            <Select.Option key={"top"} value={"top"}>
                                {t("ranking.point")}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col> */}
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
