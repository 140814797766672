import { Col, Form, InputNumber, notification, Row, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import Editor from "src/modules/components/Editor";
import Answer from "./components/Answer";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import configEditor from "src/utils/configEditor";
import parser from "src/utils/parser";
import "./DetailDragDrop.scss";

const DetailDragDrop = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    const handleAddAnswer = (listName) => {
        const listAnswer = form.getFieldValue("correct_answer");
        listAnswer[listName].push("");

        form.setFieldsValue({
            correct_answer: listAnswer,
        });
    };

    const handleDeleteAnswer = (listName, index) => {
        const listAnswer = form.getFieldValue("correct_answer");
        listAnswer[listName]?.splice(index, 1);

        form.setFieldsValue({
            correct_answer: listAnswer,
        });
    };

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();

            const __answer1 = _formData.correct_answer.answer1.map((aswr) => parser.compactMathField(aswr));

            const __answer2 = _formData.correct_answer.answer2.map((aswr) => parser.compactMathField(aswr));

            const __correct_answer = {
                answer1: __answer1,
                answer2: __answer2,
            };
            // Data:
            const _questionData = {
                type: currQType,
                answer1: __answer1,
                answer2: __answer2,
            };

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: __correct_answer,
                correct_answer: __correct_answer,
                // Special fields:
                type: _questionData.type,
            };
            // Start action:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            const validateActions = ["go-preview", "save-child", "save"];
            if (validateActions.includes(formAction)) {
                const { answer1, answer2 } = form.getFieldValue("correct_answer");

                if (answer1.length < 2 || answer2 < 2) {
                    return notification.warning({
                        message: t("message.require_less_than_answers"),
                    });
                }

                const isHaveEmptyAnswer1 = answer1.some((aswr) => !aswr);
                const isHaveEmptyAnswer2 = answer2.some((aswr) => !aswr);
                if (isHaveEmptyAnswer1 || isHaveEmptyAnswer2) {
                    return notification.warning({
                        message: t("message.warning_answer_empty"),
                    });
                }

                const answer1WithoutDuplicate = new Set(answer1);
                const answer2WithoutDuplicate = new Set(answer2);

                if (
                    answer1WithoutDuplicate.size !== answer1.length ||
                    answer2WithoutDuplicate.size !== answer2.length
                ) {
                    return notification.warning({
                        message: t("message.warning_duplicate_answer"),
                    });
                }
            }
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                category_id: qData?.category?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                question: qData?.detail?.question || "",
                                score: qData?.detail?.score,
                                // Special fields: "answer1", "answer2".
                                correct_answer: {
                                    answer1: qData?.detail.answer1,
                                    answer2: qData?.detail.answer2,
                                },
                            };
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    form.setFieldsValue({
                        ...(!Object.keys(form.getFieldValue("correct_answer") || {})?.length && {
                            correct_answer: {
                                answer1: ["", ""],
                                answer2: ["", ""],
                            },
                        }),
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;
                            const questionFormData = {
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "answer1", "answer2".
                                correct_answer: {
                                    answer1: qData?.answer1,
                                    answer2: qData?.answer2,
                                },
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }

                        form.setFieldsValue({
                            ...(!Object.keys(form.getFieldValue("correct_answer") || {})?.length && {
                                correct_answer: {
                                    answer1: ["", ""],
                                    answer2: ["", ""],
                                },
                            }),
                        });
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và category list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion.current}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-formitems">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">
                    {t("question.answer")}
                    <CustomTooltip
                        type="info"
                        placement="right"
                        title={t("question.message_shuffle")}
                        style={{ marginLeft: "8px" }}
                    />
                </Typography>
                <div className="question-answer-list">
                    <Form.Item name="correct_answer" className="list_dragdrop">
                        <Form.List name={["correct_answer", "answer1"]}>
                            {(fields) => (
                                <div className="dragdrop_col">
                                    <div className="dragdrop_col-header">
                                        <div className="question-answer-actions">
                                            <span className="action-add" onClick={() => handleAddAnswer("answer1")}>
                                                <span>{t("question_dragdrop.add_answer")}</span>
                                                <button type="button">+</button>
                                            </span>
                                        </div>
                                    </div>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            {...field}
                                            name={field.name}
                                            className="answer-input"
                                            key={field.key}
                                        >
                                            <Answer
                                                listName="answer1"
                                                labelAlign="left"
                                                deleteAlign="left"
                                                label={`(${index + 1})`}
                                                index={index}
                                                onDeleteAnswer={handleDeleteAnswer}
                                            />
                                        </Form.Item>
                                    ))}
                                </div>
                            )}
                        </Form.List>
                        <Form.List name={["correct_answer", "answer2"]}>
                            {(fields) => (
                                <div className="dragdrop_col">
                                    <div className="dragdrop_col-header">
                                        <div className="question-answer-actions">
                                            <span className="action-add" onClick={() => handleAddAnswer("answer2")}>
                                                <span>{t("question_dragdrop.add_answer")}</span>
                                                <button type="button">+</button>
                                            </span>
                                        </div>
                                    </div>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            {...field}
                                            name={field.name}
                                            className="answer-input"
                                            key={field.key}
                                        >
                                            <Answer
                                                listName="answer2"
                                                label={String.fromCharCode(65 + index)}
                                                index={index}
                                                labelAlign="right"
                                                deleteAlign="right"
                                                onDeleteAnswer={handleDeleteAnswer}
                                            />
                                        </Form.Item>
                                    ))}
                                </div>
                            )}
                        </Form.List>
                    </Form.Item>
                </div>
            </Row>
        </>
    );
};

export default DetailDragDrop;
