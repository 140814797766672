import { Dropdown, Layout, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ExitIcon from "src/assets/images/exit.svg";
import DropdownIcon from "src/assets/images/icon-dropdown.svg";
import NoAvatar from "src/assets/images/no-avatar.png";
import PencilIcon from "src/assets/images/pencil.svg";
import Button from "src/modules/components/Button";
import Clock from "../../../../components/Clock";
import NumberQuestion from "./NumberQuestion";
import "./TestLayout_Sidebar.scss";

import { t } from "i18next";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAuth } from "src/modules/auth";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { removeRecord } from "src/reducers/answer";
import { gotoQuestionOrderTempTime, handleSubmitRecord, removeTest, setIsShowConfirmModal } from "src/reducers/test";
import { convertQuestionState } from "src/utils/helpers";
import { useRouting } from "src/utils/router";
import StudentFeatureDropDown from "./StudentFeatureDropDown";

const Sidebar = (props) => {
    const dispatch = useDispatch();
    const { listTest = [], listOrderAnswered = [] } = props;
    const { listAnswers } = useSelector((state) => state.answer);
    const { isReadonly } = useSelector((state) => state.clock);
    const recorData = useSelector((state) => state.test);
    const { isShowConfirmModal, listRecord, isSubmittingTest } = recorData || {};
    const currMedia = useMediaQuery(["(min-width: 1023px)"]);
    const [recordInfo, setRecordInfo] = useState({});
    const { generate } = useRouting();
    const navigate = useNavigate();
    const params = useParams();
    const { user } = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const competition_id = searchParams.get("competition_id");

    useEffect(() => {
        console.log(recorData);
    }, [recorData]);

    const handleExit = () => {
        if (competition_id) {
            navigate(`${generate("rounds")}?competition_id=${competition_id}`);
        } else {
            navigate(generate("home"));
        }
    };

    const onChangeQuestionOrder = (order) => {
        dispatch(
            gotoQuestionOrderTempTime({
                order,
                recordId: recordInfo?.record_id,
                questionId: recordInfo.questions[order - 1].id,
            })
        );
    };

    const handleSubmit = async () => {
        // Trước khi kết thúc bài thi phải lưu giá trị của câu hiện đang làm.
        const res = await dispatch(
            handleSubmitRecord({
                recordId: recordInfo?.record_id,
            })
        );

        const { status, message } = res?.payload;

        if (status) {
            notification.success({
                message: t("test.submit_record_success"),
            });

            dispatch(
                removeRecord({
                    recordId: recordInfo?.record_id,
                })
            );

            dispatch(removeTest());
        } else {
            notification.error({
                message,
            });
        }

        dispatch(setIsShowConfirmModal(false));

        setTimeout(() => {
            // navigate(generate("home"));
            handleExit();
        }, 200);
    };

    useEffect(() => {
        if (params.id) {
            setRecordInfo(listRecord[params.id]);
        }
    }, [listRecord, params]);

    return (
        <>
            <Layout.Sider
                className="test_sidebar scroll_primary"
                theme="light"
                width={352}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="SiderHeader">
                    {/* <div className="SiderHeader_notification">
                        <Dropdown placement="bottomRight" overlay={<Menu listTest={listTest} />} trigger={["click"]}>
                            <img
                                src={listTest?.length ? HaveNotificationIcon : NotificationIcon}
                                width="24px"
                                height="30px"
                            />
                        </Dropdown>
                    </div> */}
                    <Dropdown overlay={<StudentFeatureDropDown />} trigger={["click"]}>
                        <div className="SiderHeader_Account">
                            <div className="SiderHeader_Account_avatarWrapper">
                                <img className="SiderHeader_Account_avatar" src={user.avatar || NoAvatar} />
                            </div>
                            <div className="SiderHeader_Account_info">
                                <div className="SiderHeader_Account_name">{user.name}</div>
                                <div className="SiderHeader_Account_email">{user.username}</div>
                            </div>
                            <div className="SiderHeader_Account_arrowWrapper">
                                <img src={DropdownIcon} />
                            </div>
                        </div>
                    </Dropdown>
                </div>

                {params.id && (
                    <>
                        {currMedia.breakpointValue === 1023 && <Clock isTitle={true} isLoading={false} />}
                        {isReadonly && <div className="test_timeoutTest">{t("test.timeout_test")}</div>}
                        <NumberQuestion
                            amount={recordInfo?.questions?.length}
                            listOrderAnswered={convertQuestionState(
                                recordInfo?.questions,
                                listAnswers[recordInfo?.record_id]
                            )}
                            onChangeQuestionOrder={onChangeQuestionOrder}
                        />
                        <div className="Submit_Test">
                            <Button type="grey" icon={ExitIcon} onClick={handleExit} title={t("test.exit")}></Button>
                            <Button
                                type="pink"
                                onClick={() => {
                                    dispatch(setIsShowConfirmModal(true));
                                }}
                                icon={PencilIcon}
                                title={t("test.submit")}
                            ></Button>
                        </div>
                    </>
                )}
            </Layout.Sider>

            <PopConfirm
                type="submit-test"
                visible={isShowConfirmModal}
                onOk={handleSubmit}
                onCancel={() => dispatch(setIsShowConfirmModal(false))}
                okText={t("test.submit")}
                cancelText={t("test.cancel")}
                isLoading={isSubmittingTest}
            >
                <p className="modal-title" style={{ margin: 0 }}>
                    {t("test.confirm_submit")}
                </p>
            </PopConfirm>
        </>
    );
};

export default Sidebar;
