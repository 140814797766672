import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import ShadeGrid from "src/modules/containers/QuestionDetail/containers/DetailShading/components/ShadeGrid";
import { useValues } from "src/hooks";
import { QuestionCircleOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import "./Shading.scss";

function Shading(props) {
    const {
        isReadonly = false,
        question = "", // tiêu đề câu hỏi
        onChange = () => {},
        qInfo,
        defaultAnswer,
    } = props;

    const { width, height, rowCount, columnCount, lock_cell, shaded, by_location, answered } = qInfo || {};

    const { t } = useTranslation();
    const firstLoadRef = useRef(true);

    const [shadeData, setShadeData] = useValues({
        answers: defaultAnswer || answered || [], // luu dap an chua user
    });

    const checkIndexIfItemExists = (i, j, arr) => {
        return Array.isArray(arr) && arr.findIndex((shade) => shade[0] === i && shade[1] === j);
    };

    const modifyArray = (i, j, arr) => {
        let newArr = structuredClone(arr); //vì clone thông thường sẽ bị lỗi
        const indx = checkIndexIfItemExists(i, j, newArr);

        if (indx !== -1) {
            newArr.splice(indx, 1); // remove item
            return newArr;
        } else return [...newArr, [i, j]];
    };

    const checkIsItemExists = (i, j, answers) => {
        return Array.isArray(answers) && answers.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;
    };

    const checkIsItemLocked = (i, j, shaded) => {
        const isLocked = Array.isArray(shaded) && shaded.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;
        return isLocked;
    };

    const removeDuplicateItems = (arr) => {
        let result = [];
        if (Array.isArray(arr)) {
            arr.forEach((item) => {
                if (checkIndexIfItemExists(item[0], item[1], result) === -1) {
                    result.push(item);
                }
            });
        }
        return result;
    };

    const handleClickShadeItem = (i, j, mode) => {
        const isNotLocked = !shadeData.lock_cell && !checkIsItemLocked(i, j, shadeData.shaded);

        if (isReadonly) {
            return;
        } else {
            //TH làm bài
            if (isNotLocked) {
                const ans = modifyArray(i, j, shadeData.answers);
                setShadeData({
                    answers: ans,
                });

                if (onChange && onChange instanceof Function) {
                    onChange({
                        answered: ans || [],
                    });
                }
            }
        }
    };

    const getListActive = (arr1, arr2) => {
        const newArr = [...structuredClone(arr1), ...structuredClone(arr2)];
        return removeDuplicateItems(newArr);
    };

    useEffect(() => {
        if (!isReadonly && !lock_cell && firstLoadRef.current) {
            setShadeData({
                answers: defaultAnswer || shaded,
            });
        }
        firstLoadRef.current = false;
    }, [shaded]);

    return (
        <div className="q-writing q-shading">
            <Space>
                <div className="q-title">{t("q.question")}</div>
                {!by_location && (
                    <Tooltip title={t("shade.unlockcell_note")}>
                        <QuestionCircleTwoTone style={{ fontSize: 20, cursor: "pointer" }} />
                    </Tooltip>
                )}
            </Space>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question || "",
                    }}
                />
            </div>

            <div className="content-shading student">
                <ShadeGrid
                    cellHeight={height}
                    cellWidth={width}
                    rowCount={rowCount}
                    colCount={columnCount}
                    mode={"doing"}
                    onCellClick={handleClickShadeItem}
                    lockedCells={lock_cell ? shaded : []}
                    // listActive={shadeData.answers?.length > 0 ? shadeData.answers : shaded}
                    listActive={(() => {
                        if (!lock_cell) {
                            return shadeData.answers?.length > 0 ? shadeData.answers : shaded;
                        } else {
                            return getListActive(shaded, shadeData.answers);
                        }
                    })()}
                />
            </div>
        </div>
    );
}

export default Shading;
