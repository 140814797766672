import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useValues from "src/hooks/useValues";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";

const FilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchData = () => {},
    handleCloseFilterMenu = () => {},
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.

    const handleSubmit = () => {
        const fetchParams = form.getFieldsValue();

        // Save new applied filter values:
        const newFilterParams = [];

        [0, 1, "0", "1"].includes(fetchParams.status) &&
            newFilterParams.push({
                name: "status",
                value: fetchParams.status,
                labelName: t("shared.status"),
                labelValue: fetchParams.status == 1 ? t(`setting.alive`) : t(`assignment.expired`),
            });

        [0, 1, "0", "1"].includes(fetchParams.is_team) &&
            newFilterParams.push({
                name: "is_team",
                value: fetchParams.is_team,
                labelName: t("shared.type"),
                labelValue: fetchParams.is_team ? t("organization.team") : t("organization.organization"),
            });

        setCurrFilterParams(newFilterParams);
        // Fetch assignment list by the corresponding filter values:
        handleFetchData({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                status: newFormData.status,
                is_team: newFormData.is_team,
            };

            setCurrFilterParams(filterParams);
            handleFetchData({ page: 1, ...fetchParams }, true);
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid form-horizontal" layout="horizontal">
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Form.Item name="status" label={t("shared.status")}>
                        <Select className="app-select" placeholder={t("branch.select_status")}>
                            <Select.Option value={1}>{t(`setting.alive`)}</Select.Option>
                            <Select.Option value={0}>{t(`assignment.expired`)}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="is_team" label={t("shared.type")}>
                        <Select className="app-select" placeholder={t("organization.select_type")}>
                            <Select.Option value={0}>{t(`organization.organization`)}</Select.Option>
                            <Select.Option value={1}>{t(`organization.team`)}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
