import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./FillBlanksInput.scss";
import clsx from "clsx";

function FillBlanksInput(props) {
    const { isReadonly = false, question, defaultAnswer, exam_types, onChange = () => {} } = props;

    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState(defaultAnswer || []);

    const handleChangeAnswer = (selectorIndex, inputValue) => {
        const newSelectedAnswer = [...selectedAnswer];
        newSelectedAnswer[selectorIndex] = inputValue;
        setSelectedAnswer(newSelectedAnswer);
        onChange({ answered: newSelectedAnswer });
    };

    return (
        <div className={clsx("q-fill-blanks-input", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            <div className={clsx("q-content-title", { exam_types })}>
                <QuestionContentDetail
                    blankVisibleType="input"
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                    selectedAnswer={selectedAnswer}
                    handleChangeAnswer={handleChangeAnswer}
                />
            </div>
        </div>
    );
}

export default FillBlanksInput;
