import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import { store } from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import Echo from "laravel-echo";
// import Pusher from 'pusher-js';

import sentry from "./utils/sentry";

sentry.init();
// window.Pusher = Pusher;

// console.log(window.Pusher, Pusher)

// window.Echo = new Echo({
//     broadcaster: "pusher",
//     key: "f2c1edd8b913ec7bf412",
//     cluster: "ap1",
//     client: new Pusher("f2c1edd8b913ec7bf412", {
//         broadcaster: "pusher",
//         key: "f2c1edd8b913ec7bf412",
//     }),
//     forceTLS: false,
//     encrypted: true,
//     wsHost: "http://api-dev.testonline.edu.vn/api/app",
//     wsPort: 6001,
//     disableStats: true,
//     authorizer: (channel, options) => {
//         return {
//             authorize: (socketId, callback) => {
//                 api.post("/broadcasting/auth", {
//                     socket_id: socketId,
//                     channel_name: channel.name,
//                 })
//                     .then((response) => {
//                         callback(false, response.data);
//                     })
//                     .catch((error) => {
//                         callback(true, error);
//                     });
//             },
//         };
//     },
// });

window.Pusher = require("pusher-js");

window.Echo = new Echo({
    broadcaster: "pusher",
    key: "0b417bb02641bd06e7d2",
    cluster: "ap1",
    forceTLS: false,
    encrypted: true,
    wsHost: "api-dev.testonline.edu.vn",
    wsPort: 6001,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    // authEndpoint: "http://127.0.0.1:8000/api/broadcasting/auth",
    // auth: {
    //     headers: {
    //         Authorization:
    //             "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYyOGM5ZTIyMWZlYzc3ZjBmYzkxOGZjMiIsInVzZXJuYW1lIjoidGVhY2hlcl90dWFuQGdtYWlsLmNvbSIsIm5hbWUiOiJUaFx1MWVhN3kgVHVcdTFlYTVuIn0.gFYuiXXa4Ml2XCd2lFMEY6gzQ3LfpLODpC6M_HAFayU",
    //         Accept: "application/json",
    //     },
    // },
    // authorizer: (channel, options) => {
    //     return {
    //         authorize: (socketId, callback) => {
    //             api.post("/broadcasting/auth", {
    //                 socket_id: socketId,
    //                 channel_name: channel.name,
    //             })
    //                 .then((response) => {
    //                     callback(false, response.data);
    //                 })
    //                 .catch((error) => {
    //                     callback(true, error);
    //                 });
    //         },
    //     };
    // },
});

document.title = "Test Online - App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Root />
        </I18nextProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
