import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { Dropdown, Select, Space, Spin, Tag } from "antd";
import Header from "../../../Class/components/Header";
import InputSearch from "src/modules/components/Input/InputSearch";
import FilterMenu from "../../../Class/components/FilterMenu";
import Button, { default as CustomButton } from "src/modules/components/Button";
import ClassItems from "../../components/ClassItems";
import { classFilters, fetchClasses } from "src/api/containers/class";
import { fetchBranches } from "src/api/containers/branch";
import { useFetch, useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import { permissionUser } from "src/utils/permission";
import FilterIcon from "src/assets/images/filter-icon.svg";
import ModalMergeClass from "src/modules/containers/Class/components/ModalMergeClass";
import { MergeCellsOutlined } from "@ant-design/icons";

const ListClassInScope = () => {
    const dispatch = useDispatch();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const mainfetchParams = {
        page: 1,
        is_active: 1,
        class_in_organization: 1,
        organization_id: user.paramsForOrgOrTeam?.organization_id,
        relations: ["branch", user?.currentOrg?.scopeOptionKey === "organization" ? "organization" : "team"],
    };
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { ...mainfetchParams, ...fParams },
        fetchClasses
    );

    // Permission:
    const { checkPermission, rolesAllow, pagesAllow } = permissionUser;

    // Others:
    const [values, setValues] = useValues({
        listPages: 0,
        listOfClasses: [],
        listOfBranches: [],
        allwFetchBranch: checkPermission(pagesAllow.branch, rolesAllow.list, user),
        isModalMergeClass: false,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchValue: "",
        isFilterMenuVisible: false,
        filterParams: [
            {
                name: "is_active",
                value: "1",
                labelName: t("shared.status"),
                labelValue: t(`class.${classFilters.is_active["1"]}`),
            },
            {
                name: "is_official",
                value: 1,
                labelName: t("class.class_type"),
                labelValue: t(`class.official_class`),
            },
        ],
    });

    const handleSearch = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchValue });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleFilterClassByStatus = (value) => {
        if (value === -1) {
            fetch({ page: 1, is_active: undefined });
        } else {
            fetch({ page: 1, is_active: value });
        }
    };

    const handleFilterBranchById = (value) => {
        fetch({ page: 1, branch_id: value });
    };

    const customizeBranchList = (branches) => {
        const renderActive = (active) => {
            return active ? "" : ` (${t("branch.inactive")})`;
        };
        let result = [];
        if (branches?.length > 0) {
            result = branches.map((item) => {
                const newName = item.name + renderActive(item.active);
                return { ...item, name: newName };
            });
        }
        return result;
    };

    useEffect(() => {
        dispatch(setCurrentPage("class"));
        fetch({}, true);
        if (values.allwFetchBranch) {
            fetchBranches({ noPagination: true, ...user.paramsForOrgOrTeam }).then((res) => {
                setValues({ listOfBranches: customizeBranchList(res.data) });
            });
        }
    }, []);

    useEffect(() => {
        if (dataSource.length) {
            if (pagination.current === 1) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: dataSource,
                });
            } else if (pagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: [...values.listOfClasses, ...dataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfClasses: [],
            });
        }
    }, [dataSource, pagination]);

    return (
        <div className="class">
            <Header />

            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("class.find_class")}
                            onChange={(e) => setFilterSettings({ ...filterSettings, inputSearchValue: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {filterSettings.filterParams?.length > 0
                                    ? filterSettings.filterParams.map((fKey, i) => {
                                          return (
                                              <Tag
                                                  className="app-tag"
                                                  key={`filter-key${i}`}
                                                  closable
                                                  onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                              >
                                                  {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                  {`${fKey.labelValue || ""}`}
                                              </Tag>
                                          );
                                      })
                                    : // <>{t("shared.option_filter")}</>
                                      null}
                            </Space>
                        </div>
                        <Dropdown
                            forceRender
                            visible={filterSettings.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={filterSettings.filterParams}
                                    willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    excludeFields={[
                                        "organization_id",
                                        !values.allwFetchBranch ? "branch_id" : undefined,
                                    ]}
                                    branchDataList={values.listOfBranches}
                                    onFetchDataList={fetch}
                                    onCloseFilterMenu={(newFilterParams) =>
                                        setFilterSettings({
                                            ...filterSettings,
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        })
                                    }
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => {
                                setFilterSettings({ ...filterSettings, isFilterMenuVisible: val });
                            }}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={FilterIcon}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions">{/* Nothing. */}</div>
                <span>
                    <Button
                        icon={<MergeCellsOutlined />}
                        title={t("class.merge_class")}
                        onClick={() => {
                            setValues({
                                isModalMergeClass: true,
                            });
                        }}
                    />
                </span>
            </div>

            <Spin spinning={loading}>
                {/* <div className="class-filter">
                    <div>
                        <span className="class-filter-title">{t("class.list_class")}</span>
                        <span className="class-filter-select">
                            <Select
                                className="app-select show-arrow has-rd"
                                placeholder={t("shared.choose_status")}
                                showArrow={false}
                                allowClear
                                defaultValue={1}
                                onChange={(value) => handleFilterClassByStatus(value)}
                            >
                                {classFilters.is_active.map((item, itemIndex) => {
                                    return (
                                        <Select.Option
                                            key={`ia${itemIndex}`}
                                            value={item.value === undefined ? -1 : item.value}
                                        >
                                            {t(`class.${item.label}`)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                    {values.allwFetchBranch && (
                        <div>
                            <span className="class-filter-title">{t("class.branch")}</span>
                            <span>
                                <Select
                                    className="app-select show-arrow has-rd branch-select"
                                    placeholder={t("class.select_branch")}
                                    showArrow={false}
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    onChange={(value) => handleFilterBranchById(value)}
                                >
                                    {values.listOfBranches.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </span>
                        </div>
                    )}
                </div> */}
                <div className="class-list">
                    <ClassItems
                        classList={values.listOfClasses}
                        pagination={pagination}
                        handleFetchClassList={fetch}
                        // handleRefetchClassList={refetch}
                        handleRefetchClassList={() => {
                            fetch({ page: 1 }, true);
                        }}
                    />
                </div>

                <ModalMergeClass
                    visible={values.isModalMergeClass}
                    onOk={() => {
                        fetch({}, true, { page: 1 });
                        setValues({ isModalMergeClass: false });
                    }}
                    onCancel={() => {
                        setValues({ isModalMergeClass: false });
                    }}
                    destroyOnClose
                ></ModalMergeClass>
            </Spin>
        </div>
    );
};

export default ListClassInScope;
