import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Rounds from "../../components/Rounds";
// import Header from "../../components/Header";
// import FilterMenu from "../../components/FilterMenu";
import { useFetch, useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import Header from "src/modules/components/Header";
import "./DetailCompetition.scss";
import { fetchAssignments } from "src/api/containers/assignment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { findCompetition } from "src/api/containers/Competition";
import { useRouting } from "src/utils/router";

const DetailCompetition = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const competition_id = searchParams.get("competition_id");
    const navigate = useNavigate();
    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { generate } = useRouting();
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [assignmentLoading, assignmentData, assignmentPagination, assignmentFetch, assignmentRefetch] = useFetch(
        { page: 1, competition_id: competition_id, orderBy: ["order:asc"], ...fParams },
        fetchAssignments
    );

    // Others:
    const [values, setValues] = useValues({
        listPages: 0,
        competition: {},
    });

    useEffect(() => {
        if (competition_id) {
            dispatch(setCurrentPage("competitions"));
            assignmentFetch({}, true);
            findCompetition(competition_id).then(({ status, data }) => {
                if (status) {
                    setValues({
                        competition: data,
                    });
                }
            });
        }
    }, [competition_id]);

    useEffect(() => {
        if (assignmentData.length) {
            if (assignmentPagination.current === 1) {
                setValues({
                    listPages: assignmentPagination.current,
                });
            } else if (assignmentPagination.current > values.listPages) {
                setValues({
                    listPages: assignmentPagination.current,
                });
            }
        } else {
            setValues({
                listPages: 0,
            });
        }
    }, [assignmentData, assignmentPagination]);

    return (
        <div className="class detail-competition">
            <Header backEnable={true} backFunc={() => navigate(generate("competitions"))} />

            <Spin spinning={assignmentLoading}>
                <Rounds
                    competition={values.competition}
                    pagination={assignmentPagination}
                    handleRefetchAsignment={() => {
                        assignmentRefetch({ page: 1 }, true);
                    }}
                    listAssignments={assignmentData}
                />
            </Spin>
        </div>
    );
};

export default DetailCompetition;
