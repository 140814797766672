import { Tabs } from "antd";
import ReactDOM from "react-dom/client";
import Tab1 from "./tabs/tab1";
import Tab2 from "./tabs/tab2";
import Tab3 from "./tabs/tab3";
import Tab4 from "./tabs/tab4";
import Tab5 from "./tabs/tab5";
import Tab6 from "./tabs/tab6";
import Tab7 from "./tabs/tab7";
import Tab8 from "./tabs/tab8";
import Tab9 from "./tabs/tab9";
import Tab10 from "./tabs/tab10";
import Context from "./Context";
import "./index.scss";

const tabs = [
    { icon: require("../icons/group/_group6134.svg").default, tab: Tab1 },
    { icon: require("../icons/group/_group6133.svg").default, tab: Tab2 },
    { icon: require("../icons/group/_group2479.svg").default, tab: Tab3 },
    { icon: require("../icons/group/_group6135.svg").default, tab: Tab4 },
    { icon: require("../icons/group/_group6136.svg").default, tab: Tab5 },
    { icon: require("../icons/group/_group6137.svg").default, tab: Tab6 },
    { icon: require("../icons/group/_group6138.svg").default, tab: Tab7 },
    { icon: require("../icons/group/_group6140.svg").default, tab: Tab8 },
    { icon: require("../icons/group/_group6141.svg").default, tab: Tab9 },
    { icon: require("../icons/group/_group6142.svg").default, tab: Tab10 },
];

export const Buttons = ({ quill, onClickOption }) => {
    return (
        <div className="mathchar-btn-list">
            <Context.Provider value={{ quill }}>
                <Tabs
                    defaultActiveKey="0"
                    children={tabs.map((tab, idx) => {
                        const Tab = tab.tab;
                        return (
                            <Tabs.TabPane
                                tab={<img src={tab.icon} />}
                                key={idx}
                                children={<Tab onClick={onClickOption} />}
                            />
                        );
                    })}
                />
            </Context.Provider>
        </div>
    );
};

export default function renderButtons(element, quill) {
    const root = ReactDOM.createRoot(element);
    root.render(<Buttons quill={quill} />);
}
