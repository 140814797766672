import { generateConfigForQuestion } from "./question";

export const generateConfigForQuestionHL = () => {
    return {
        ...generateConfigForQuestion(),

        // Plugins, toolbar, menubar:
        plugins:
            "paste" +
            " lists advlist" +
            // " image imagetools media file-manager" +
            " insertdatetime link anchor table charmap math codesample hr" +
            " searchreplace wordcount" +
            " directionality visualblocks visualchars" +
            " preview" +
            " nonbreaking pagebreak" +
            " quickbars help" +
            " highlight",
        toolbar:
            "undo redo" +
            " | searchreplace" +
            " | highlight" +
            " | bold italic underline strikethrough removeformat" +
            " | fontselect fontsizeselect formatselect lineheight" +
            " | alignleft aligncenter alignright alignjustify" +
            " | outdent indent" +
            " | bullist numlist" +
            " | subscript superscript" +
            " | forecolor backcolor" +
            // " | image media file-manager" +
            " | link table charmap math",

        // Settings:
        paste_as_text: true,
    };
};
