const liBackground = {
    correct: "#4fe3c1",
    incorrect: "#ff4d4f",
    active: "#445cfe",
    hover: "#445cfe",
    borderHover: "#002766",
    default: "#e6f7ff",
};
const generateLiBackground = (props) => {
    const { active, showAnswers, correct, locked, mode, isShowIncorrect, isAllCorrect } = props;
    const isOriginActive = active && !locked && correct;
    const isCheckRed = active && !locked && !correct;
    const isCheckLocked = (active && locked) || (!active && locked);
    const isShowGreen = showAnswers && correct && !locked;
    const isShowLocked = (showAnswers && correct && locked) || (showAnswers && !correct && active && locked);
    const isShowRed = showAnswers && !correct && active && !locked;
    const isSimplyActive = !showAnswers && active;

    if (!isAllCorrect && isShowIncorrect && (active || isOriginActive || isShowGreen || isSimplyActive)) {
        return liBackground.incorrect;
    }

    if (mode === "setAnswer" && active) {
        return liBackground.active;
    }

    if (isOriginActive || isShowGreen || isSimplyActive) {
        return liBackground.active;
    }

    if (isCheckLocked || isShowLocked || isSimplyActive) {
        return liBackground.active;
    }

    return liBackground.default;

    // return hoverBg ? liBackground.borderHover : liBackground.hover;
};

const generateBorderWidth = ({ active, checkAnswers, correct, locked, border }) => {
    if (border !== "full") {
        return 0;
    }

    if (active && checkAnswers) {
        return "1px";
    }

    const isCheckGreen = checkAnswers && active && !locked && correct;
    const isCheckRed = checkAnswers && active && !locked && !correct;

    return isCheckGreen || isCheckRed ? "2px" : "1px";
};

const convertStyle = (props) => {
    const {
        width = 1,
        active = false,
        height = 1,
        visibility,
        showAnswers,
        correct,
        locked = false,
        isReadonly,
        mode = "normal", // normal/ previewResult
        isAllCorrect = false,
        isShowIncorrect,
    } = props;

    const activeStyle = {
        backgroundColor: "#d8a2de",
        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: "#000",
    };

    const correctStyle = {
        backgroundColor: liBackground.correct,
        // borderWidth: 1,
    };

    const inCorrectStyle = {
        backgroundColor: liBackground.incorrect,
    };

    const result = {
        minWidth: width * 30,
        minHeight: height * 30,
        visibility: visibility,
        // backgroundColor: generateLiBackground({
        //     active,
        //     showAnswers,
        //     correct,
        //     locked,
        //     mode,
        //     isAllCorrect,
        //     isShowIncorrect,
        // }),
        backgroundColor: liBackground.default,
        cursor: locked ? "not-allowed" : "pointer",
        borderWidth: 1,
        // borderWidth: generateBorderWidth,
        borderStyle: "solid",
        borderColor: "#ccc",
        "&:hover": props.hover
            ? `
                 background: ${generateLiBackground(0.6, true)};
                  z-index: 11;
                  border: 3px solid ${liBackground.borderHover};
                `
            : "",
    };

    if (active) {
        Object.assign(result, activeStyle);
    }

    if (correct) {
        Object.assign(result, correctStyle);
    }

    if (isReadonly) {
        Object.assign(result, {
            pointerEvents: "none",
        });
    }

    if (!isAllCorrect && isShowIncorrect && active) {
        Object.assign(result, inCorrectStyle);
    }

    return result;
};

const Li2 = (props) => {
    // const { styleProps } = props;
    const { isReadonly, locked, onClick } = props;
    return (
        <li onClick={() => !locked && !isReadonly && onClick()} style={convertStyle(props)}>
            {/* {props.value.i + "-" + props.value.j} */}
        </li>
    );
};

export default Li2;
