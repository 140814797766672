import { forwardRef, useEffect, useImperativeHandle, useRef, useState, React, useContext } from "react";

//lazy load
import loadable from "@loadable/component";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import { Button, Dropdown, Menu, Modal } from "antd";
import { EditOutlined, LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import "./PDFDrawer.scss";
import { useResizeObserver, useValues } from "src/hooks";
import ZwibblerToolbars from "../Toolbars";
const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler/index.js"));
import deleteBtn from "src/assets/images/action-close.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { v4 as uuidv4 } from "uuid";
import { PDF_CONST } from "../../constant";

function safeZwibblerLoad(zwibbler, data) {
    // hàm load json data của zwibbler
    try {
        zwibbler.load(data);
    } catch (e) {
        try {
            // eslint-disable-next-line no-control-regex
            zwibbler.load(data.replace(/[^\x00-\x80]/g, ""));
        } catch (e2) {
            /* eslint-disable */
            console.log({ e2 });
        }
    }
}

const defaultScale = 1;

const PDFDrawer = ({ width, src, defaultAnswer, onChange, isReadonly = false, scale, mode = "detail" }) => {
    const [zwibbler, setZwibbler] = useState();
    const zwibblerEl = useRef(null);
    const modeRef = useRef(mode);
    modeRef.current = mode;

    const { targetRef, dimensions } = useResizeObserver();

    const {
        setDrawingTool,
        pathsData,
        loadData,
        setPathByFile,
        currentThumbnail,
        listThumbnails,
        toggleThumbnails,
        isShowThumbnails,
    } = useContext(ExamPDFContext);

    const [values, setValues] = useValues({
        scale: defaultScale,
    });

    const [ytModal, setYtModal] = useState({
        isShow: false,
        videoId: "",
        node: "",
    });

    // const leftColumnWidth = minimized ? 0 : 180
    // const rightColumnWidth = 300
    // const pdfWidth = windowWidth - rightColumnWidth - leftColumnWidth

    const zwibblerConfig = {
        // Needed to indicate to Zwibbler that this is the main element.
        zwibbler: "",
        "z-controller": "mycontroller",
        background: "transparent",
        // confine: "view",
    };

    const saveData = (newZwibbler) => {
        const saved = newZwibbler.save("zwibbler3");

        if (setPathByFile instanceof Function) {
            setPathByFile({ path: saved });
        }
        if (onChange instanceof Function) {
            onChange({
                answered: [saved],
            });

            pathsData.current = saved;
        }
    };

    useEffect(() => {
        function initZwibbler() {
            if (window.Zwibbler && zwibblerEl.current && !zwibbler) {
                // initialize Zwibbler
                const newZwibbler = window.Zwibbler.create(zwibblerEl.current, {
                    // defaultZoom: "page",
                    // pageView: "true",
                    // confine: "'view",
                    autoPickTool: false,
                    showToolbar: false,
                    showColourPanel: false,
                    showHints: false,
                    scrollbars: false,
                    readOnly: isReadonly,
                    setFocus: false, // Zwibbler will be unable to intercept any keyboard commands
                    allowZoom: false,
                    allowTextInShape: false, // Zwibbler to write text inside a closed shapes
                    // leaveTextToolOnBlur: true, // Zwibbler text tool data will be saved if user moves out of scratchpad
                });

                newZwibbler.addSelectionHandle(1.0, 0.0, 0, 0, deleteBtn, function (pageX, pageY) {
                    newZwibbler.deleteSelection();
                });

                newZwibbler.on("document-changed", () => {
                    if (newZwibbler.dirty()) {
                        saveData(newZwibbler);
                    }
                });

                newZwibbler.on("node-clicked", function (node, x, y) {
                    var id = newZwibbler.getNodeProperty(node, "_qId");

                    if (id) {
                        function scrollToElement(id) {
                            // Get the target element by its ID
                            var targetElement = document.getElementById(id);

                            // Check if the element exists
                            if (targetElement) {
                                // Scroll to the target element
                                targetElement.scrollIntoView({
                                    behavior: "smooth", // Use 'auto' or 'instant' for different scrolling behavior
                                    block: "start", // Use 'start', 'center', or 'end' to align the element within the viewport
                                });
                            }
                        }
                        scrollToElement(id);
                    }

                    const _ytCode = newZwibbler.getNodeProperty(node, "_ytCode");
                    if (modeRef.current === "preview") {
                        if (_ytCode) {
                            setYtModal((state) => ({ isShow: true, node: node, videoId: _ytCode }));
                        }
                    } else {
                        const oldLink = `https://www.youtube.com/watch?v=${_ytCode}`;

                        let newLink = prompt("Copy and paste the video embed code", oldLink);
                        const yId = getYoutubeId(newLink);

                        newZwibbler.setNodeProperty(node, "_ytCode", yId);
                    }
                });

                newZwibbler.on("paste", () => {
                    // preventing pasting of images currently
                    return false;
                });

                //setting
                newZwibbler.setConfig("confine", "view");
                setZwibbler(newZwibbler);
            }
        }

        //chạy interval để check zwibbler đã load xong chưa => mếu xong thì chạy hàm khởi tạo....
        let interval = setInterval(() => {
            if ("Zwibbler" in window) {
                initZwibbler();
                clearInterval(interval);
                // console.count("interval");
            }
        }, 100);
        return () => {
            setZwibbler(null);
        };
    }, []);

    useEffect(() => {
        if (zwibbler && defaultAnswer?.[0]) {
            safeZwibblerLoad(zwibbler, defaultAnswer[0]);
        }
        if (zwibbler && loadData?.current) {
            loadData.current = (data) => {
                safeZwibblerLoad(zwibbler, data);
            };
        }
    }, [zwibbler]);

    useEffect(() => {
        setDrawingTool?.(zwibbler);
    }, [zwibbler]);

    useEffect(() => {
        if (currentThumbnail.file_id && currentThumbnail.path && zwibbler) {
            safeZwibblerLoad(zwibbler, currentThumbnail.path);
        }
    }, [currentThumbnail]);

    useEffect(() => {
        // handle dimension change such as zoom in/out or window resize
        if (zwibbler) {
            zwibbler.resize();
            console.log("resize-------->");
        }
        // if (
        //     (isStudentAttempt || expressGrader) &&
        //     !readOnly &&
        //     zwibblerRef.current
        // ) {
        //     const zwibblerDomRect = zwibblerRef.current.getBoundingClientRect()
        //     setScratchpadRect({
        //         top: zwibblerDomRect.top,
        //         left: zwibblerDomRect.left,
        //         width: zwibblerDomRect.width,
        //         height: zwibblerDomRect.height,
        //     })
        // }
    }, [dimensions.width, dimensions.height]);

    function getWidthHeight(width) {
        const w = width || 600;
        // const w = window.document.body.offsetWidth - 760;
        const h = Math.round(width * 1.414 || 600 * 1.414);

        // return { width: w, height: h, minWidth: 600, minHeight: 600 * 1.414 };
        return { width: w, height: h, minWidth: 600 };
    }

    const width_height = getWidthHeight(width);

    console.log({ width_height });

    return (
        <div className="pdf-drawer-container">
            {/* <div className="canvas-tools">
                <ZwibblerToolbars ctx={zwibbler} />
            </div> */}
            <div className="pdf-container">
                <div className="pdf-wrapper">
                    <PerfectScrollbar>
                        <div className={`zwibbler-draw-container pdf`}>
                            {!isReadonly && <div className="toolbar-wrapper"></div>}
                            <div
                                className="zwibbler-container"
                                id="scrollable"
                                // style={{ width: width || 400, height: height || 400, maxHeight: "60vh" }}
                                // style={{ ...width_height, transform: `scale(${scale})` }}
                                style={{ ...width_height }}
                            >
                                <div className="custom-zwibbler" style={{ height: width_height.height }}>
                                    <div
                                        ref={targetRef}
                                        style={{ transform: `scale(${scale})` }}
                                        className="zwibbler-image-background"
                                    >
                                        {src && <img src={src} />}
                                    </div>

                                    <div
                                        ref={zwibblerEl}
                                        {...zwibblerConfig}
                                        id="zwibbler-canvas"
                                        className="zwibbler"
                                        style={{ transform: `scale(${scale})`, minWidth: 500 }}
                                    >
                                        <div z-canvas="" className="stretch" confine="view" />
                                    </div>
                                </div>
                            </div>
                            {/* //layzyload module */}
                            <ZwibblerModule></ZwibblerModule>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>

            <YoutubeVideoModal
                visible={ytModal?.isShow}
                videoId={ytModal?.videoId}
                onCancel={() => setYtModal((state) => ({ isShow: false, node: "", videoId: "" }))}
            />
        </div>
    );
};

function YoutubeVideoModal({ videoId, visible = false, onCancel = () => {} }) {
    return (
        <Modal
            className=""
            visible={visible}
            centered
            onCancel={onCancel}
            style={{ width: 640 }}
            destroyOnClose
            footer={null}
        >
            <div style={{ height: 300, display: "flex", alignItems: "center" }}>
                <iframe
                    width="460"
                    height="260"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder="0"
                    allowFullScreen
                    title="YouTube Video"
                ></iframe>
            </div>
        </Modal>
    );
}

export default PDFDrawer;
