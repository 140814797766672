import React from "react";
import IconRocket from "./IconRocket";
import IconSave from "./IconSave";
import IconEdit from "./IconEdit";
import IconDelete from "./IconDelete";
import IconModify from "./IconModify";
import IconPreview from "./IconPreview";
import IconTick from "./IconTick";
import IconCross from "./IconCross";
import IconNavPrev from "./IconNavPrev";
import IconNavNext from "./IconNavNext";

function Icon(props) {
    const { name, ...rest } = props;

    const renderIcon = () => {
        switch (name) {
            case "icon-save":
                return <IconSave {...rest} />;
            case "icon-edit":
                return <IconEdit {...rest} />;
            case "icon-delete":
                return <IconDelete {...rest} />;
            case "icon-rocket":
                return <IconRocket {...rest} />;
            case "icon-modify":
                return <IconModify {...rest} />;
            case "icon-preview":
                return <IconPreview {...rest} />;
            // <:
            case "icon-nav-prev":
                return <IconNavPrev {...rest} />;
            // >:
            case "icon-nav-next":
                return <IconNavNext {...rest} />;
            // ✓:
            case "icon-tick":
                return <IconTick {...rest} />;
            // x (thick):
            case "icon-cross-thick":
                return <IconCross {...rest} type="thick" />;
            // x (thin):
            case "icon-cross-thin":
                return <IconCross {...rest} type="thin" />;
            // Empty:
            default:
                return <></>;
        }
    };

    return <>{renderIcon()}</>;
}

export default Icon;
