import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notification, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import InputSearch from "src/modules/components/Input/InputSearch";
import Table from "src/modules/components/Table";
import ModalDetailRole from "../ModalDetailRole";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "src/modules/components/Button";
import { deleteRole, fetchRoles } from "src/api/containers/role";
import { permissionUser } from "src/utils/permission";
import Can from "src/modules/components/Can";

function RoleList() {
    const user = useSelector((state) => state.auth.user);
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const role = {
        _update: checkPermission(pagesAllow.role, rolesAllow.update, user),
        _delete: checkPermission(pagesAllow.role, rolesAllow.delete, user),
    };

    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, slug: "", noPagination: true, organization_id: user.paramsForOrgOrTeam?.organization_id },
        fetchRoles
    );

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        isModalConfirmDeleteVisible: false,
        isModalDetailRoleVisible: false,
        selectedRole: null,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchRole: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });
    const columns = [
        {
            title: t("manage_role.name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => {
                return record?.name || "_";
            },
        },
        {
            title: t("manage_role.description"),
            dataIndex: "description",
            key: "description",
            width: "auto",
            render: (text, record) => {
                return record?.description || "_";
            },
        },
    ];

    if (role._delete || role._update) {
        columns.push({
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="organization-user-list-actions">
                    <Can I={rolesAllow.update} page={pagesAllow.role}>
                        <Tooltip placement="top" title={t("shared.update")}>
                            <span onClick={() => handleShowModalEditRole(record)} className="action-btn">
                                <EditOutlined />
                            </span>
                        </Tooltip>
                    </Can>

                    <Can I={rolesAllow.delete} page={pagesAllow.role}>
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <span
                                onClick={() => handleShowModalConfirmDelete(record)}
                                className="action-btn organization-user-delbtn"
                            >
                                <DeleteOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                </div>
            ),
        });
    }

    const handleShowModalConfirmDelete = (selectedRole) => {
        setValues({ isModalConfirmDeleteVisible: true, selectedRole: selectedRole });
    };

    const handleShowModalEditRole = (selectedRole) => {
        setValues({ isModalDetailRoleVisible: true, selectedRole: selectedRole });
    };

    const handleDeleteSelectedRole = () => {
        const selectedRole = values.selectedRole;
        deleteRole(selectedRole.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isModalConfirmDeleteVisible: false, selectedRole: null });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleSearchRole = () => {
        if (filterSettings) {
            fetch({
                page: 1,
                slug: filterSettings.inputSearchRole,
            });
        }
    };

    // const handleRemoveFilterParam = (e, paramName) => {
    //     e.preventDefault();
    //     const newFilterParams = filterSettings.filterParams.filter((item) => {
    //         return !(paramName === item.name);
    //     });
    //     setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    // };

    useEffect(() => {
        fetch({ page: 1, slug: "" }, true);
    }, []);

    return (
        <div className="question-bank organization-user-list">
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("manage_role.find_role")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchRole: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchRole();
                                    }
                                }}
                                onClickIconSearch={handleSearchRole}
                            />
                        </div>

                        <div className="filter-toolbar-item filtermenu-wrapper">
                            {/* <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    className="app-tag"
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                >
                                                    {`${fKey.labelValue || ""}`}
                                                </Tag>
                                            );
                                        })
                                        : null}
                                </Space>
                            </div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={<></>}
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown> */}
                            <Can I={rolesAllow.create} page={pagesAllow.role}>
                                <CustomButton
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    title={t("manage_role.add_role")}
                                    onClick={() => handleShowModalEditRole(undefined)}
                                ></CustomButton>
                            </Can>
                        </div>
                    </div>
                    {/* <div className="filter-toolbar-actions">
                        <CustomButton
                            type="primary"
                            icon={<PlusOutlined />}
                            title={t("manage_role.add_role")}
                            onClick={() => handleShowModalEditRole(undefined)}
                        ></CustomButton>
                    </div> */}
                </div>
            </div>

            <div className="organization-user-list__table">
                <Table
                    className="app-table"
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            <ModalDetailRole
                organization={user.paramsForOrgOrTeam}
                visible={values.isModalDetailRoleVisible}
                selectedRole={values.selectedRole || undefined}
                onOk={() => {
                    refetch({}, true);
                    setValues({ isModalDetailRoleVisible: false });
                }}
                onCancel={() => setValues({ isModalDetailRoleVisible: false })}
            />

            <PopConfirm
                type="delete"
                visible={values.isModalConfirmDeleteVisible}
                onOk={handleDeleteSelectedRole}
                onCancel={() => setValues({ isModalConfirmDeleteVisible: false })}
            />
        </div>
    );
}

export default RoleList;
