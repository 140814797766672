import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Page404.scss";
import Img404 from "src/assets/images/http-status-codes/404.png";

function Page404(props) {
    const { t } = useTranslation();

    return (
        <div className="page-404-wrapper">
            <div className="page-404-container">
                <span className="page-404-img-wrapper">
                    <img src={Img404} />
                </span>
                <div className="page-404-content">
                    <p>OOPS!</p>
                    <div className="content-title">{t("html_status_code.page_404_title")}</div>
                    <div className="content-description">{t("html_status_code.page_404_msg")}</div>
                    <Link to="/" className="content-action-button">
                        {t("html_status_code.action_go_back_home")}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Page404;
