import clsx from "clsx";
import { useEffect, useState } from "react";
import { IMGClock, IMGFallenPapers, IMGLearning } from "src/utils/drawer";
import ResetPassword from "./components/ActiveUserOrganization/ResetPassword";
import ForgetPassword from "./components/ForgetPassword";
import LoginTabs from "./components/LoginTabs";
import ThirdPartyRole from "./components/ThirdPartyRole";
import Verify from "./components/Verify";
import "./Login.scss";

const LoginV2 = (props) => {
    const { register = false, forget = false, role = false, firstResetPW = false, username } = props;
    const [userData, setUserData] = useState({});
    const [hideHeader, setHideHeader] = useState("");

    const handleDataLogin = (username, password) => {
        setUserData({ username, password });
        setHideHeader(true);
    };

    useEffect(() => {
        setHideHeader(forget || role || firstResetPW);
    }, [window.location.pathname]);

    if (role) {
        return (
            <div className="login">
                <ThirdPartyRole />
            </div>
        );
    }

    return (
        <div className="login">
            <div className="login-layout-wrapper">
                <div className={clsx("login-top", hideHeader && "hide")}>
                    <IMGClock />
                </div>
                <div className={clsx("login-left", hideHeader && "mt-0")}>
                    <div className="body-wrapper">
                        {forget ? (
                            <ForgetPassword />
                        ) : userData?.username && userData?.password ? (
                            <Verify userData={userData} />
                        ) : firstResetPW ? (
                            <ResetPassword username={username} />
                        ) : (
                            <LoginTabs tab={register ? "1" : "2"} getData={handleDataLogin} />
                        )}
                    </div>
                </div>
                <div className="login-right">
                    <div className="decoration-wrapper">
                        <div className="decoration-top">
                            <IMGFallenPapers />
                        </div>
                        <div className="decoration-bottom">
                            <IMGLearning />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginV2;
