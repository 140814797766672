import React, { useMemo, useRef } from "react";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import QuestionContentDetail from "../ContentDetail";
import JSXGraphBoard from "src/modules/components/JSXGraphBoard";
import "./GraphNumberLine.scss";

function GraphNumberLine({
    // Question & answers:
    question = "" /** @param {string} question */,
    correct_answer /** @param {string[]} correct_answer */,
    answered /** @param {string[]} answered */,
    max_value /** @param {Number} max_value Max value */,
    min_value /** @param {Number} min_value Min value */,
    jump_value /** @param {Number} jump_value Jump value */,
}) {
    const refGraph1Id = useRef(`qcontent1-jsxgraph-container-${uuidv4()}`);
    const refGraph2Id = useRef(`qcontent2-jsxgraph-container-${uuidv4()}`);

    const [answerResult, answerExpected, answeredCounter] = useMemo(() => {
        let answerResult = [];
        let answerExpected = [];
        let answeredCounter = 0;
        // Helpers:
        const setUpAnswerDataItem = (answerDataItem, isCorrect) => {
            // Specify attrs:
            let labelClassName = isCorrect ? "is-correct" : "is-incorrect";
            // Apply attrs:
            if (answerDataItem.type === "numberline_label") {
                !answerDataItem.attrs && (answerDataItem.attrs = {});
                answerDataItem.attrs.fixed = true;
                answerDataItem.attrs.labelClassName = labelClassName;
            }
        };
        // Converting data:
        (answered || []).forEach((item, itemIndex) => {
            const answerDataItem = {
                type: item.type || "numberline_label",
                label: item.label,
                value: item.value,
            };
            // Check if the answer is correct:
            let isCorrect = false;
            const ans = item.value;
            const ansCorrect = correct_answer
                .map((itemC) => {
                    if (itemC.label === item.label) {
                        return itemC.value;
                    }
                    return false;
                })
                .filter((itemCVal) => {
                    return itemCVal !== false;
                });
            isCorrect = ansCorrect.includes(ans);
            if (typeof ans === "number") {
                answeredCounter++;
            }
            // Update answerDataItem's value and display:
            setUpAnswerDataItem(answerDataItem, isCorrect);
            // Update the list:
            answerResult.push(answerDataItem);
        });
        (correct_answer || []).forEach((item, itemIndex) => {
            const answerDataItem = {
                type: item.type || "numberline_label",
                label: item.label,
                value: item.value,
            };
            // Update answerDataItem's value and display:
            setUpAnswerDataItem(answerDataItem, true);
            // Update the list:
            answerExpected.push(answerDataItem);
        });
        // Data:
        return [answerResult, answerExpected, answeredCounter];
    }, [correct_answer, answered]);

    return (
        <div className="q-result q-graph-numberline">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-graph-numberline">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="numberline"
                        id={refGraph1Id.current}
                        width="100%"
                        height="350px"
                        boundingBox={[min_value, 10, max_value, -10]}
                        coordsStep={[jump_value, 0]}
                        value={answerResult}
                        isReadOnly={true}
                    />
                    {answeredCounter === 0 && (
                        <div className="graph-msg">
                            <div className="msg-wrapper">
                                <span className="msg">{t("message.answer_empty")}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="view-graph-numberline">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="numberline"
                        id={refGraph2Id.current}
                        width="100%"
                        height="350px"
                        boundingBox={[min_value, 10, max_value, -10]}
                        coordsStep={[jump_value, 0]}
                        value={answerExpected}
                        isReadOnly={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default GraphNumberLine;
