import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { CaretDownOutlined } from "@ant-design/icons";
import "./ColorPicker.scss";
import { Dropdown } from "antd";

function ColorPickerV2(props) {
    const {
        currColor = "unset",
        defaultSelectedColor = {
            hex: "transparent",
            rgb: {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            },
        },
        defaultColorCollections = [
            { color: "transparent", title: "No fill" },
            "#1fbfb8",
            "#039fbe",
            "#1978a5",
            "#26495c",
            "#ced89e",
            "#adcF9f",
            "#76ba99",
            "#05716c",
            "#f24c4c",
            "#f47c7c",
            "#d71b3b",
            "#b20238",
            "#ffd24c",
            "#d7d716",
            "#f3ca20",
            "#ec9b3b",
            "#f57e7e",
            "#e75874",
            "#e52165",
        ],
        onChangeColor = () => {},
        className = "",
        width,
        ...rest
    } = props;

    const [selectedColor, setSelectedColor] = useState(defaultSelectedColor);

    const handleChangeSelectedColor = (newColor, event) => {
        setSelectedColor(newColor);
        onChangeColor(newColor);
    };

    useEffect(() => {
        if (currColor !== "unset") {
            const curr = `${currColor?.rgb?.r},${currColor?.rgb?.g},${currColor?.rgb?.b},${currColor?.rgb?.a}`;
            const selected = `${selectedColor?.rgb?.r},${selectedColor?.rgb?.g},${selectedColor?.rgb?.b},${selectedColor?.rgb?.a}`;
            if (currColor.hex !== selectedColor.hex && curr !== selected) {
                setSelectedColor(currColor);
            }
        }
    }, [currColor]);

    return (
        <div className={`app-color-picker${className ? " " + className : ""}`} {...rest}>
            <Dropdown
                trigger={["click"]}
                overlay={
                    <>
                        <div className="color-picker-wrapper sketch-picker">
                            <SketchPicker
                                color={selectedColor.rgb}
                                onChange={handleChangeSelectedColor}
                                // onChangeComplete={handleChangeSelectedColor}
                                disableAlpha={false}
                                presetColors={defaultColorCollections}
                                width={250}
                            />
                        </div>
                    </>
                }
                placement="bottomLeft"
            >
                <div className="toggle-btn">
                    <div
                        className={`color-preview${selectedColor?.rgb?.a === 0 ? " no-color" : ""}`}
                        style={{
                            backgroundColor: `rgba(${selectedColor?.rgb?.r}, ${selectedColor?.rgb?.g}, ${selectedColor?.rgb?.b}, ${selectedColor?.rgb?.a})`,
                        }}
                    />
                    <div className="toggle-btn-icon">
                        <CaretDownOutlined />
                    </div>
                </div>
            </Dropdown>
        </div>
    );
}

function ColorPickerV1(props) {
    const {
        currColor = "unset",
        defaultSelectedColor = {
            hex: "transparent",
            rgb: {
                r: 0,
                g: 0,
                b: 0,
                a: 0,
            },
        },
        defaultColorCollections = [
            { color: "transparent", title: "No fill" },
            "#1fbfb8",
            "#039fbe",
            "#1978a5",
            "#26495c",
            "#ced89e",
            "#adcF9f",
            "#76ba99",
            "#05716c",
            "#f24c4c",
            "#f47c7c",
            "#d71b3b",
            "#b20238",
            "#ffd24c",
            "#d7d716",
            "#f3ca20",
            "#ec9b3b",
            "#f57e7e",
            "#e75874",
            "#e52165",
        ],
        onChangeColor = () => {},
        className = "",
        ...rest
    } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState(defaultSelectedColor);

    const handleClickToggleButton = () => {
        setIsVisible(!isVisible);
    };

    const handleCloseColorPicker = () => {
        setIsVisible(false);
    };

    const handleChangeSelectedColor = (newColor, event) => {
        setSelectedColor(newColor);
        onChangeColor(newColor);
    };

    useEffect(() => {
        if (currColor !== "unset") {
            const curr = `${currColor.rgb.r},${currColor.rgb.g},${currColor.rgb.b},${currColor.rgb.a}`;
            const selected = `${selectedColor.rgb.r},${selectedColor.rgb.g},${selectedColor.rgb.b},${selectedColor.rgb.a}`;
            if (currColor.hex !== selectedColor.hex && curr !== selected) {
                setSelectedColor(currColor);
            }
        }
    }, [currColor]);

    return (
        <div className={`app-color-picker${className ? " " + className : ""}`} {...rest}>
            <div className="toggle-btn" onClick={handleClickToggleButton}>
                <div
                    className={`color-preview${selectedColor.rgb.a === 0 ? " no-color" : ""}`}
                    style={{
                        backgroundColor: `rgba(${selectedColor.rgb.r}, ${selectedColor.rgb.g}, ${selectedColor.rgb.b}, ${selectedColor.rgb.a})`,
                    }}
                />
                <div className="toggle-btn-icon">
                    <CaretDownOutlined />
                </div>
            </div>

            {isVisible === true ? (
                <>
                    <div className="color-picker-overlay" onClick={handleCloseColorPicker} />
                    <div className="color-picker-wrapper sketch-picker">
                        <SketchPicker
                            color={selectedColor.rgb}
                            onChange={handleChangeSelectedColor}
                            // onChangeComplete={handleChangeSelectedColor}
                            disableAlpha={false}
                            presetColors={defaultColorCollections}
                            width={width || 250}
                        />
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default React.memo(ColorPickerV2);
