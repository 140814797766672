import React from "react";
import { useTranslation } from "react-i18next";
import { planTypes } from "src/api/containers/plan";
import { default as CustomButton } from "src/modules/components/Button";
import "./CardSubscriptionPlan.scss";
import { ReactComponent as SvgStarSingleHalf } from "src/assets/images/account/star-single-half.svg";
import { ReactComponent as SvgStarSingle } from "src/assets/images/account/star-single.svg";
import { ReactComponent as SvgStarTriple } from "src/assets/images/account/star-triple.svg";
import { ReactComponent as SvgTick } from "src/assets/images/account/tick.svg";
import discLabel from "src/assets/images/account/label-pinion.svg";

const subscriptionPlans = {
    [planTypes.free]: {
        icon: <SvgStarSingleHalf />,
        label: undefined,
    },
    [planTypes.premium]: {
        icon: <SvgStarTriple />,
        label: "subscription_plan_best_value",
    },
    [planTypes.organization]: {
        icon: <SvgStarSingle />,
        label: undefined,
    },
};

const CardSubscriptionPlan = ({
    className = "",
    type,
    title,
    priceCurrent,
    priceUsual,
    priceUnit,
    priceDiscount,
    actions = [],
    bodyContent,
    isLoadingSkeleton = false,
    ...rest
}) => {
    const { t } = useTranslation();

    // Generate ClassNames:
    const currClassNames = ["subscription-plan-card"];
    if (className) {
        currClassNames.push(className);
    }
    if (subscriptionPlans[type]?.className) {
        currClassNames.push(subscriptionPlans[type].className);
    }
    if (isLoadingSkeleton) {
        currClassNames.push("skeleton");
    }

    // Render label:
    const renderPriceUnsual = () => {
        if (priceUsual && !isNaN(priceUsual)) {
            return (
                <div className="price-usual">
                    <span className="price-value">{priceUsual.toLocaleString() || 0}</span>
                    <span className="price-unit">{priceUnit || "_"}</span>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderPriceCurrent = () => {
        if (!isNaN(priceCurrent)) {
            return (
                <div className="price-current">
                    <span className="price-value">{priceCurrent.toLocaleString()}</span>
                    <span className="price-unit">{priceUnit || "_"}</span>
                    {priceDiscount ? (
                        <span className="price-discount-label" style={{ backgroundImage: `url(${discLabel})` }}>
                            {`-${priceDiscount}%`}
                        </span>
                    ) : null}
                </div>
            );
        } else if (!!priceCurrent) {
            return (
                <div className="price-current">
                    <span className="price-value">{priceCurrent}</span>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderBodyContent = () => {
        if (!bodyContent) {
            return null;
        } else if (bodyContent.length) {
            return bodyContent.map((content, i) => {
                return (
                    <li key={`content-${i}`}>
                        <span>
                            <SvgTick />
                        </span>
                        <span>{content}</span>
                    </li>
                );
            });
        } else if (React.isValidElement(bodyContent)) {
            return bodyContent;
        } else {
            return null;
        }
    };

    return (
        <div className={currClassNames.join(" ")} {...rest}>
            <div className={`card-subsc-plan`}>
                <div className="card-wrapper">
                    <div className="card-content">
                        <div className="card-header">
                            {type ? <div className="header-icon">{subscriptionPlans[type]?.icon}</div> : null}
                            <div className="header-title">{title || "_"}</div>
                        </div>
                        <div className="card-price">
                            {renderPriceCurrent()}
                            {renderPriceUnsual()}
                        </div>
                        <div className="card-body">
                            <ul className="plan-benefit-list">
                                {isLoadingSkeleton
                                    ? Array(4)
                                          .fill()
                                          .map((_, i) => {
                                              return (
                                                  <li key={`loading-${i}`}>
                                                      <span>_</span>
                                                      <span>_</span>
                                                  </li>
                                              );
                                          })
                                    : renderBodyContent()}
                            </ul>
                        </div>
                    </div>
                    {actions?.length ? (
                        <div className="card-actions">
                            {actions.map((act, i) => {
                                return (
                                    <CustomButton
                                        key={`act-${i}`}
                                        type={act.type}
                                        linkTo={act.linkTo}
                                        title={act.title}
                                        onClick={act.onClick}
                                        className={act.className}
                                        {...act}
                                    ></CustomButton>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
                {subscriptionPlans[type]?.label ? (
                    <div className="card-label">{t(`account.${subscriptionPlans[type].label}`)}</div>
                ) : null}
            </div>
        </div>
    );
};

export default CardSubscriptionPlan;
