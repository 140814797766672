import moment from "moment";
import api from "..";

const testInputFields = [
    "name",
    "exam_question_id",
    "homework_time_minute",
    "begin_date",
    "end_date",
    "category_id",
    "count_record",
];
const testLevelInputFields = ["name", "test_input_id", "class_id", "score", "user_id"];

const listEntryTest = (fetchParams) => {
    const filters = [];

    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.begin_date && filters.push(`begin_date:${moment(fetchParams.begin_date).format("YYYY-MM-DD")}`);
    fetchParams.end_date && filters.push(`end_date:${moment(fetchParams.end_date).format("YYYY-MM-DD")}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _test_input_fields: `${testInputFields}`,
        _perPage: fetchParams?.perPage,
        _page: fetchParams?.page,
        _orderBy: fetchParams.orderBy ? fetchParams?.orderBy?.join?.(";") : "id:asc",
        _relations: `${["category", "exam"]}`,
        _category_fields: `${["name"]}`,
        _exam_fields: `${["name"]}`,
    };
    return api
        .get(`/test_input`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

const createEntryTest = (data) => {
    return api
        .post("/test_input", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

const updateEntryTest = (id, data) => {
    return api
        .put(`/test_input/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

const deleteEntryTest = (id) => {
    return api
        .delete(`/test_input/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

const listEntryTestLevel = (fetchParams) => {
    const filters = [];

    fetchParams.test_input_id && filters.push(`test_input_id:${fetchParams.test_input_id}`);
    fetchParams.begin_date && filters.push(`begin_date:${fetchParams.begin_date}`);
    fetchParams.end_date && filters.push(`end_date:${fetchParams.end_date}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _test_level_input_fields: `${testLevelInputFields}`,
        _perPage: fetchParams?.perPage,
        _page: fetchParams?.page,
        _relations: `${["user", "class"]}`,
        _user_fields: `${["name"]}`,
        _class_fields: `${["name"]}`,
        _orderBy: fetchParams.orderBy ? fetchParams?.orderBy?.join?.(";") : "asc",
    };
    return api
        .get(`/test_level_input`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

const createEntryTestLevel = (data) => {
    return api
        .post("/test_level_input", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

const updateEntryTestLevel = (id, data) => {
    return api
        .put(`/test_level_input/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

const deleteEntryTestLevel = (id) => {
    return api
        .delete(`/test_level_input/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export {
    listEntryTest,
    createEntryTest,
    updateEntryTest,
    deleteEntryTest,
    listEntryTestLevel,
    createEntryTestLevel,
    updateEntryTestLevel,
    deleteEntryTestLevel,
};
