import Home from "src/modules/containers/Home";
// Manage targets:
import TargetController from "src/modules/containers/TargetController";
// Teacher pages:
import AssignmentReport from "src/modules/containers/AssignmentReport";
import DetailClass from "src/modules/containers/Class/containers/DetailClass";
import ListClass from "src/modules/containers/Class/containers/ListClass";
import MyStorage from "src/modules/containers/MyStorage";
import Organization from "src/modules/containers/Organization";
import OrganizationDetail from "src/modules/containers/OrganizationDetail";
import Payment from "src/modules/containers/Payment";
import PaymentResultPage from "src/modules/containers/Payment/pages/PaymentResultPage";
// Question:
import QuestionBank from "src/modules/containers/QuestionBank";
import QuestionPreview from "src/modules/containers/QuestionPreview";
// Exam:
import DetailExam from "src/modules/containers/DetailExam";
import ExamBank from "src/modules/containers/ExamBank";
// Student pages:
import ClassesStudent from "src/modules/containers/ClassesStudent";
import HomeStudent from "src/modules/containers/HomeStudent";
import ScoresStudent from "src/modules/containers/ScoresStudent";
import Test from "src/modules/containers/Test";
import ViewScoreStudent from "src/modules/containers/ViewScoreStudent";
// HTML Status Code pages:
import Page404 from "src/modules/containers/PageForStatusCode/Page404";
// Others:
import Assignment from "src/modules/containers/Assignment";
import Branch from "src/modules/containers/Branch/containers/ListBranch";
import ComponentLibs from "src/modules/containers/ComponentLibs";
import GeneralLearningProgress from "src/modules/containers/GeneralLearningProgress";
import GeneralLayout from "src/modules/containers/Layout/GeneralLayout";
import LearningProgressReport from "src/modules/containers/LearningProgressReport";
import ListClassInScope from "src/modules/containers/ManageClass/containers/ListClassInScope";
import DetailClassInScope from "src/modules/containers/ManageClass/containers/DetailClassInScope";
import ManageEmployee from "src/modules/containers/ManageEmployee";
import ManageRole from "src/modules/containers/ManageRole";
import ManageStudent from "src/modules/containers/ManageStudent";
import ManageTeacher from "src/modules/containers/ManageTeacher";
import MyExams from "src/modules/containers/MyResources/MyExams";
import MyQuestion from "src/modules/containers/MyResources/MyQuestions";
import PersonalLearningProgress from "src/modules/containers/PersonalLearningProgress";
import Profile from "src/modules/containers/Profile";
import QuestionDetail from "src/modules/containers/QuestionDetail";
import DetailPassageQuestion from "src/modules/containers/QuestionDetail/containers/DetailPassageQuestion";
import Setting from "src/modules/containers/Setting";
import Ranking from "src/modules/containers/Ranking";
import Competitions from "src/modules/containers/Competitions";
import DetailCompetition from "src/modules/containers/Competitions/containers/DetailCompetition";
import CompetitionsStudent from "src/modules/containers/CompetitionsStudent";
import DetailCompetitionStudent from "src/modules/containers/CompetitionsStudent/containers/DetailCompetition";
import Dashboard from "src/modules/containers/Dashboard";
import EntryTest from "src/modules/containers/EntryTest";
import DetailEntryTest from "src/modules/containers/EntryTest/containers/DetailEntryTest";
import ExamSelect from "src/modules/containers/Exam/ExamSelect";
import ExamPDFCreate from "src/modules/containers/Exam/ExamPDFCreate";
import DetailExamPDF from "src/modules/containers/Exam/DetailExamPDF";
import TestPDF from "src/modules/containers/Exam/DetailExamPDF/components/TestPDF";

// (Routes for developers) React/HTML elements:
const componentLibs = [
    // {
    //     name: "component-libs",
    //     path: "/component-libs",
    //     element: ComponentLibs,
    //     role: ["teacher"],
    //     layout: GeneralLayout,
    // },
];

// Question details:
const questions = [
    {
        name: "add_passage",
        path: "/question/passage",
        element: DetailPassageQuestion,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "update_passage",
        path: "/question/passage/:id",
        element: DetailPassageQuestion,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "add_question",
        path: "/question/:questionKey",
        element: QuestionDetail,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "update_question",
        path: "/question/:questionKey/:id",
        element: QuestionDetail,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "preview_question",
        path: "/question/preview/:questionKey/:id",
        element: QuestionPreview,
        role: ["teacher"],
        layout: GeneralLayout,
    },
];

// Upgrade & payment:
const routesSubscription = [
    {
        name: "payment",
        path: "/payment",
        element: Payment,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "payment_result",
        path: "/payment/callback",
        element: PaymentResultPage,
        role: ["teacher"],
        // layout: GeneralLayout,
    },
];

// Scope management:
const routesOrganization = [
    {
        name: "organization_detail",
        path: "/organization",
        element: OrganizationDetail,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "organization_invite",
        path: "/organization-invitation/:invite_organization_id",
        element: TargetController,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },
    {
        name: "manage_capacity",
        path: "/manage-capacity",
        element: Organization,
        role: ["teacher"],
        layout: GeneralLayout,
    },
];

export default [
    ...componentLibs,

    /**
     * ROLE: TEACHER & EMPLOYEE.
     */
    ...questions,
    ...routesSubscription,
    ...routesOrganization,

    /**
     * ********************************************************************************
     * Teacher route:
     */
    {
        name: "home",
        path: "/home",
        element: Dashboard,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "my_storage",
        path: "/my-storage",
        element: MyStorage,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    // Branch
    {
        name: "manage_role",
        path: "/manage-role",
        element: ManageRole,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_branch",
        path: "/manage-branch",
        element: Branch,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    // Class page:
    {
        name: "class",
        path: "/class",
        element: ListClass,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "detail_class",
        path: "/class/:id",
        element: DetailClass,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "class_invite",
        path: "/class-invitation/:invite_class_id",
        element: TargetController,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },
    {
        name: "class_invite_student",
        path: "/class-invitation-student/:invite_student_class_id",
        element: TargetController,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },

    // Question page:
    {
        name: "question_bank",
        path: "/question",
        element: QuestionBank,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    // Assignment page:
    {
        name: "assignment",
        path: "/assignment",
        element: Assignment,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "assignment_invite",
        path: "/assignment/:assignment_id",
        element: TargetController,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },
    {
        name: "assignment_report",
        path: "/class/:classId/assignment/:assignmentId",
        element: AssignmentReport,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    // Exam page:
    {
        name: "exam_bank",
        path: "/exam",
        element: ExamBank,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "add_exam_bank",
        path: "/exam/create",
        element: DetailExam,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "update_exam_bank",
        path: "/exam/:id",
        element: DetailExam,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    {
        name: "my_resources_exam",
        path: "/my-resources/exam",
        element: MyExams,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "my_resources_question",
        path: "/my-resources/question",
        element: MyQuestion,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_class",
        path: "/manage-class",
        element: ListClassInScope,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_class_detail",
        path: "/manage-class/:id",
        element: DetailClassInScope,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_teacher",
        path: "/manage-teacher",
        element: ManageTeacher,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_student",
        path: "/manage-student",
        element: ManageStudent,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_employee",
        path: "/manage-employee",
        element: ManageEmployee,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    //ranking pages
    {
        name: "ranking",
        path: "/ranking",
        element: Ranking,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    {
        name: "competitions",
        path: "/competitions",
        element: Competitions,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "manage_ranking",
        path: "/manage-ranking",
        element: Ranking,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    {
        name: "manage_competitions",
        path: "/manage-competitions",
        element: Competitions,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "rounds",
        path: "/rounds",
        element: DetailCompetition,
        role: ["teacher"],
        layout: GeneralLayout,
    },

    /**
     * ********************************************************************************
     * Student route:
     */
    {
        name: "home",
        path: "/home",
        element: HomeStudent,
        role: ["student"],
        layout: GeneralLayout,
    },
    {
        name: "test",
        path: "/test/:id",
        role: ["student"],
        // element: Test,
        element: Test,
    },
    {
        name: "profile",
        path: "/profile",
        element: Profile,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },
    {
        name: "setting",
        path: "/setting",
        element: Setting,
        role: ["teacher", "student"],
        layout: GeneralLayout,
    },
    {
        name: "classes",
        path: "/classes",
        role: ["student"],
        element: ClassesStudent,
        layout: GeneralLayout,
    },
    {
        name: "scores",
        path: "/scores",
        element: ScoresStudent,
        role: ["student"],
        layout: GeneralLayout,
    },
    {
        name: "score",
        path: "/score/:id",
        element: ViewScoreStudent,
        role: ["student"],
        layout: GeneralLayout,
    },
    {
        name: "competitions",
        path: "/competitions",
        element: CompetitionsStudent,
        role: ["student"],
        layout: GeneralLayout,
    },
    {
        name: "rounds",
        path: "/rounds",
        element: DetailCompetitionStudent,
        role: ["student"],
        layout: GeneralLayout,
    },

    // {
    //     name: "score",
    //     path: "/score/:id",
    //     element: ViewScoreStudent,
    //     layout: GeneralLayout,
    // },
    {
        name: "not-found",
        path: "/not-found",
        role: ["teacher", "student"],
        element: Page404,
    },
    {
        name: "personal-report",
        path: "/personal-report",
        role: ["teacher", "student"],
        element: PersonalLearningProgress,
        layout: GeneralLayout,
    },
    {
        name: "general-report",
        path: "/general-report",
        role: ["teacher"],
        element: GeneralLearningProgress,
        layout: GeneralLayout,
    },
    {
        name: "learning-progress-report",
        path: "/learning-progress-report",
        role: ["teacher"],
        element: LearningProgressReport,
        layout: GeneralLayout,
    },
    {
        name: "entry_test",
        path: "/entry-tests",
        role: ["teacher"],
        element: EntryTest,
        layout: GeneralLayout,
    },
    {
        name: "entry_test_level",
        path: "/entry-tests/:id/level",
        role: ["teacher"],
        element: DetailEntryTest,
        layout: GeneralLayout,
    },
    {
        name: "exam_pdf_create",
        path: "/exam-pdf",
        role: ["teacher"],
        element: ExamPDFCreate,
        layout: GeneralLayout,
    },
    {
        name: "exam_select",
        path: "/exam/select",
        role: ["teacher"],
        element: ExamSelect,
        layout: GeneralLayout,
    },
    {
        name: "add_exam_pdf",
        path: "/exam-pdf/create",
        element: DetailExamPDF,
        role: ["teacher"],
        layout: GeneralLayout,
    },
    {
        name: "update_exam_pdf",
        path: "/exam-pdf/:id",
        element: DetailExamPDF,
        role: ["teacher"],
        layout: GeneralLayout,
    },
];
