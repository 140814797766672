// File is verified!
import api from "..";

const roleFields = ["name", "description", "permissions"];

// Get role list:
export const fetchRoles = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _role_fields: `${roleFields}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/role", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                    lastPage: meta.pagination.lastPage,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

// Find role:
export const findRole = (id) => {
    const params = {
        _role_fields: `${roleFields}`,
    };
    return api
        .get(`/role/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

// Create role:
export const createRole = (data) => {
    const { name, description, permissions } = data;

    if (!name || !description) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .post("/role", data)
        .then(({ status, id, message }) => ({ status, id, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Update role:
export const updateRole = (data, id) => {
    const { name, description, permissions } = data;

    if (!name || !description) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .put(`/role/${id}`, data)
        .then(({ status, id, message }) => ({ status, id, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Delete role:
export const deleteRole = (id) => {
    return api
        .delete(`/role/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};
