import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Input, Modal, notification, Spin } from "antd";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import { useValues } from "src/hooks";
import { permissionUser } from "src/utils/permission";
import { fetchPermissions } from "src/api/containers/permission";
import { createRole, findRole, updateRole } from "src/api/containers/role";
import actionClose from "src/assets/images/action-close.svg";
import "./ModalDetailRole.scss";

const ModalDetailRole = ({
    modalClassName = "",
    selectedRole = "",
    organization,
    onOk = () => {},
    onCancel = () => {},
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useValues({
        role: undefined,
        permissionList: [],
    });
    const [formData, setFormData] = useValues({
        permissionsRequired: {},
    });

    const { rolesAllow } = permissionUser;

    const handleOk = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        // Update form states:
        setFormData({ permissionsRequired: {} });
        // Update form:
        form.resetFields();
        // Event handlers:
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmitForm = async () => {
        const formData = {
            ...form.getFieldsValue(),
            organization_id: organization?.organization_id,
        };
        if (!formData.name || !formData.description) {
            return;
        }
        // Generate selected permissions to be submitted:
        if (Object.keys(formData.permissions || {})?.length) {
            const permissionsToSubmit = [];
            const permissionGroupKeyList = Object.keys(formData.permissions);
            for (let i = 0; i < permissionGroupKeyList.length; i++) {
                const key = permissionGroupKeyList[i];
                const val = formData.permissions[key];
                if (val) {
                    permissionsToSubmit.push({
                        [key]: val,
                    });
                }
            }
            if (permissionsToSubmit.length > 0) {
                formData.permissions = permissionsToSubmit;
            }
        }
        // Make api calls:
        if (selectedRole?.id) {
            updateRole(formData, selectedRole.id).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        } else {
            createRole(formData).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleChangePermissions = (groupInfo, permissionList) => {
        if (groupInfo?.key === "class" && permissionList instanceof Array) {
            if (permissionList.includes(rolesAllow.create) || permissionList.includes(rolesAllow.update)) {
                const formValues = form.getFieldsValue();
                // Set permissions of related fields:
                const newPermissionsTeacher = [...(formValues.permissions.teacher || [])];
                const newPermissionsBranch = [...(formValues.permissions.branch || [])];
                if (!newPermissionsTeacher.includes(rolesAllow.list)) {
                    newPermissionsTeacher.push(rolesAllow.list);
                }
                if (!newPermissionsBranch.includes(rolesAllow.list)) {
                    newPermissionsBranch.push(rolesAllow.list);
                }
                // Update form states:
                setFormData({
                    permissionsRequired: {
                        teacher: [rolesAllow.list],
                        branch: [rolesAllow.list],
                    },
                });
                // Update form:
                form.setFieldsValue({
                    ...formValues,
                    permissions: {
                        ...formValues.permissions,
                        teacher: newPermissionsTeacher,
                        branch: newPermissionsBranch,
                    },
                });
            } else {
                // Update form:
                setFormData({
                    permissionsRequired: {},
                });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchPermissions({ organization_id: organization?.organization_id }).then((res) => {
            setLoading(false);
            if (res.status) {
                setData({ permissionList: res.data });
            }
        });
    }, []);

    useEffect(() => {
        if (rest.visible && selectedRole?.id) {
            setLoading(true);
            findRole(selectedRole.id).then((res) => {
                setLoading(false);
                if (res.status) {
                    // Generate selected permissions to be shown:
                    const permissionsToShow = {};
                    const permissionsCurrent = res.data.permissions?.length ? res.data.permissions : [];
                    for (let i = 0; i < permissionsCurrent.length; i++) {
                        const pKeys = Object.keys(permissionsCurrent[i]);
                        for (let j = 0; j < pKeys.length; j++) {
                            permissionsToShow[pKeys[j]] = permissionsCurrent[i][pKeys[j]];
                        }
                    }
                    // Update states:
                    setData({ role: res.data });
                    // Update form states:
                    handleChangePermissions({ key: "class" }, permissionsToShow.class);
                    // Update form:
                    form.setFieldsValue({
                        name: res.data.name,
                        description: res.data.description,
                        permissions: permissionsToShow,
                    });
                }
            });
        }
    }, [rest.visible, selectedRole]);

    return (
        <Modal
            centered
            footer={null}
            title={selectedRole ? t("manage_role.update_role") : t("manage_role.add_role")}
            closeIcon={<img src={actionClose}></img>}
            onOk={handleOk}
            onCancel={handleCancel}
            className={`app-modal type-basic flexible-height modal-detail-role${
                modalClassName ? " " + modalClassName : ""
            }`}
            {...rest}
        >
            <Spin spinning={loading === true}>
                <Form form={form} onFinish={handleSubmitForm} className="body-layout">
                    <div className="form-item-group">
                        <Form.Item
                            name="name"
                            label={t("manage_role.name")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                        >
                            <Input className="app-input" placeholder={t("manage_role.name")} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label={t("manage_role.description")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                        >
                            <Input className="app-input" placeholder={t("manage_role.description")} />
                        </Form.Item>

                        <Form.Item label={t("manage_role.permissions")}>
                            {data.permissionList.map((p, index) => {
                                const groupInfo = p.group;
                                const groupOfPermissions = p.permission.length ? p.permission : [];
                                const groupOfOptions = [];
                                for (let i = 0; i < groupOfPermissions.length; i++) {
                                    groupOfOptions.push({
                                        label: groupOfPermissions[i].name,
                                        value: groupOfPermissions[i].key,
                                        disabled:
                                            formData.permissionsRequired[groupInfo.key] instanceof Array
                                                ? formData.permissionsRequired[groupInfo.key].includes(
                                                      groupOfPermissions[i].key
                                                  )
                                                : false,
                                    });
                                }
                                return (
                                    <Form.Item
                                        key={`group-permission-${index}`}
                                        name={["permissions", groupInfo.key]}
                                        label={groupInfo.name}
                                        className="form-item-group-permission"
                                    >
                                        <Checkbox.Group
                                            options={groupOfOptions}
                                            onChange={(permissionList) =>
                                                handleChangePermissions(groupInfo, permissionList)
                                            }
                                        />
                                    </Form.Item>
                                );
                            })}
                        </Form.Item>
                    </div>

                    <div className="btn-group">
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-cross-thick" />}
                            title={t("shared.cancel")}
                            onClick={handleCancel}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-tick" />}
                            title={t("shared.save")}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalDetailRole;
