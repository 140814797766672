import { useEffect, useRef, useState } from "react";
import { Input, message } from "antd";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as SvgFolder } from "src/modules/components/FileManager/assets/custom/folder.svg";

const Item = ({
    controller,
    folder,
    folderExcluded,
    flow,
    targetedFolder,
    // Methods:
    listFolders = () => {},
    removeFiles = () => {},
    updateFolder = () => {},
}) => {
    const { t } = useTranslation();

    const refInputFol = useRef("");
    const [collapse, setCollapse] = useState(false);
    const [children, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Values: "load-children" || "create-folder" || false.
    const [isHandling, setIsHandling] = useState(false); // Values: "create-folder" || false.
    const [isConfirming, setIsConfirming] = useState({
        target: undefined, // Value: inputfol || undefined.
        msg: undefined,
        callback: undefined,
    });

    const handleOpen = () => {
        setCollapse(true);
    };

    const handleClose = () => {
        setCollapse(false);
    };

    const handleUpdateFolder = (newData, hasPrevState = true) => {
        if (newData?.id) {
            if (hasPrevState === true) {
                setChildren((prevState) => {
                    const newChildren = [...prevState];
                    for (let i = 0; i < newChildren.length; i++) {
                        if (newChildren[i].id === newData.id) {
                            newChildren[i] = { ...newChildren[i], ...newData };
                            break;
                        }
                    }
                    return newChildren;
                });
            } else {
                const newChildren = [...children];
                for (let i = 0; i < newChildren.length; i++) {
                    if (newChildren[i].id === newData.id) {
                        newChildren[i] = { ...newChildren[i], ...newData };
                        break;
                    }
                }
                setChildren(newChildren);
            }
        }
    };

    const handleClickItemName = () => {
        controller.call("movetomodal.set_targeted_folder", folder);
    };

    const handleCancelConfirmingInputFol = () => {
        setIsHandling(false);
        setIsConfirming({
            target: undefined,
            msg: undefined,
            callback: undefined,
        });
    };

    const handleOkConfirmingInputFol = () => {
        isConfirming.callback?.();
    };

    const handleBlurInputFol = () => {
        const folderName = refInputFol.current?.input?.value;
        const parentInfo = folder;
        if (folderName && parentInfo) {
            const siblings = children;
            const handleDoIf = (stage, res, run) => {
                if (stage == "pending") {
                    setIsLoading("create-folder");
                } else if (stage === "fulfilled") {
                    // setIsHandling(false);
                    setIsLoading(false);
                    handleCancelConfirmingInputFol();
                    if (res) {
                        controller.call(`movetomodal_item.${folder.id}.add_child_list`, [res]);
                    }
                } else if (stage === "rejected") {
                    // setIsHandling(false);
                    setIsLoading(false);
                    handleCancelConfirmingInputFol();
                    if (res.message) {
                        alert(res.message);
                    }
                } else if (stage === "confirm") {
                    setIsConfirming({
                        target: "inputfol",
                        msg: res,
                        callback: run,
                    });
                }
            };
            controller.call("crud_folder.create_with_validate", folderName, parentInfo, handleDoIf, siblings);
        } else {
            setIsHandling(false);
        }
    };

    useEffect(() => {
        if (folder.id === null) {
            setTimeout(() => {
                handleOpen();
            });
        }

        controller.set(`movetomodal_item.${folder.id}.open`, () => {
            handleOpen();
        });
        controller.set(`movetomodal_item.${folder.id}.close`, () => {
            handleClose();
        });

        return () => {
            controller.remove(`movetomodal_item.${folder.id}.open`);
            controller.remove(`movetomodal_item.${folder.id}.close`);
        };
    }, []);

    useEffect(() => {
        controller.set(`movetomodal_item.${folder.id}.update`, (itemData) => {
            updateFolder(itemData);
        });
        controller.set(`movetomodal_item.${folder.id}.set_is_empty`, (boolState) => {
            /**
             * View README.md to understand this function:
             * src\modules\components\FileManager\README.md
             */
            updateFolder({ ...folder, state: boolState ? "empty" : "not-empty" });
        });

        return () => {
            controller.remove(`movetomodal_item.${folder.id}.update`);
            controller.remove(`movetomodal_item.${folder.id}.set_is_empty`);
        };
    }, [updateFolder]);

    useEffect(() => {
        controller.set(`movetomodal_item.${folder.id}.input_create_folder`, () => {
            setIsHandling("create-folder");
            handleOpen();
        });
        controller.set(`movetomodal_item.${folder.id}.add_child_list`, (childList) => {
            if (childList?.length) {
                setChildren([...childList, ...children]);
                controller.call(`movetomodal_item.${folder.id}.set_is_empty`, false);
            }
        });

        return () => {
            controller.remove(`movetomodal_item.${folder.id}.input_create_folder`);
            controller.remove(`movetomodal_item.${folder.id}.add_child_list`);
        };
    }, [children]);

    useEffect(() => {
        if (collapse) {
            const res = listFolders(folder.id);
            if (res instanceof Promise) {
                setIsLoading("load-children");
                res.then((folders) => {
                    setIsLoading(false);
                    if (folders instanceof Array) {
                        setChildren(folders);
                    }
                }).catch(({ message }) => {
                    setIsLoading(false);
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        }
    }, [collapse]);

    // Class names:
    const classNameInit = "fm-moveto-item";
    let classNameCurr = classNameInit;
    if (folderExcluded instanceof Array) {
        if (folderExcluded.includes(folder.id)) {
            classNameCurr += " disabled";
        }
    }
    if (folder.id === targetedFolder?.id) {
        classNameCurr += " active";
    }
    if (collapse) {
        classNameCurr += " has-scope-line";
    }

    // Rendering:
    const renderInputCreateFolder = () => {
        let classNameExtra = "";
        if (isConfirming.target === "inputfol") {
            classNameExtra = "focus-danger";
        }
        return (
            <li className={`${classNameInit} input-c-fol ${classNameExtra}`}>
                <span className="fm-moveto-item-label">
                    <span style={{ marginRight: "19px" }}></span>
                    <span className="fm-moveto-item-name">
                        <span className="item-icon">
                            <SvgFolder />
                        </span>
                        <span className="item-name">
                            {isLoading === "create-folder" ? (
                                <span>{refInputFol.current?.input?.value}</span>
                            ) : (
                                <Input
                                    ref={refInputFol}
                                    className="app-input fm-moveto-input"
                                    placeholder={t("file_manager.input_folder_name")}
                                    onBlur={handleBlurInputFol}
                                    autoFocus
                                />
                            )}
                        </span>
                    </span>
                </span>
                {isConfirming.target === "inputfol" && (
                    <span className="input-c-fol-confirm">
                        <span className="cfm-title">{isConfirming.msg}</span>
                        <span className="cfm-actions">
                            <CustomButton
                                type="ghost"
                                title={t("shared.cancel")}
                                onClick={handleCancelConfirmingInputFol}
                                disabled={isLoading === "create-folder"}
                            ></CustomButton>
                            <CustomButton
                                type="primary"
                                title={t("shared.ok")}
                                onClick={handleOkConfirmingInputFol}
                                disabled={isLoading === "create-folder"}
                            ></CustomButton>
                        </span>
                    </span>
                )}
            </li>
        );
    };

    if (collapse === false) {
        return (
            <li className={classNameCurr}>
                <span className="fm-moveto-item-label">
                    {folder.id && (!folder.state || folder.state === "empty") ? (
                        <span style={{ marginRight: "19px" }}></span>
                    ) : (
                        <span className="fm-moveto-item-collapse-target" onClick={handleOpen}>
                            <CaretRightOutlined />
                        </span>
                    )}
                    <span className="fm-moveto-item-name" onClick={handleClickItemName}>
                        <span className="item-icon">
                            <SvgFolder />
                        </span>
                        <span className="item-name">{folder.name}</span>
                    </span>
                </span>

                {isHandling === "create-folder" && <ul>{renderInputCreateFolder()}</ul>}
            </li>
        );
    }

    return (
        <li className={classNameCurr}>
            <span className="fm-moveto-item-label">
                {folder.id && (!folder.state || folder.state === "empty") ? (
                    <span style={{ marginRight: "19px" }}></span>
                ) : (
                    <span className="fm-moveto-item-collapse-target" onClick={handleClose}>
                        <CaretDownOutlined />
                    </span>
                )}
                <span className="fm-moveto-item-name" onClick={handleClickItemName}>
                    <span className="item-icon">
                        <SvgFolder />
                    </span>
                    <span className="item-name">{folder.name}</span>
                </span>
            </span>

            {isLoading === "load-children" ? (
                <ul>
                    <li className="fm-moveto-item skeleton-loading">
                        <span className="fm-moveto-item-label">
                            <span style={{ marginRight: "19px" }}></span>
                            <span className="fm-moveto-item-name"></span>
                        </span>
                    </li>
                </ul>
            ) : null}

            {isLoading !== "load-children" && children.length > 0 && (
                <ul>
                    {isHandling === "create-folder" && <>{renderInputCreateFolder()}</>}
                    {children.map((child) => (
                        <Item
                            key={child.id}
                            controller={controller}
                            folder={child}
                            folderExcluded={folderExcluded}
                            flow={[...flow, child]}
                            targetedFolder={targetedFolder}
                            listFolders={listFolders}
                            updateFolder={handleUpdateFolder}
                            // removeFiles={removeFiles}
                        />
                    ))}
                </ul>
            )}

            {!isLoading && children.length === 0 && (
                <>{isHandling === "create-folder" && <ul>{renderInputCreateFolder()}</ul>}</>
            )}
        </li>
    );
};

export default Item;
