import api from "..";

export const fetchAssignments = (fetchParams = {}, fields, isRelationShip = true) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.teacher_id && filters.push(`teacher_id:${fetchParams.teacher_id}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.is_ranking !== undefined && filters.push(`is_ranking:${fetchParams.is_ranking}`);
    fetchParams.competition_id !== undefined && filters.push(`competition_id:${fetchParams.competition_id}`);
    [0, 1, "0", "1"].includes(fetchParams.is_test) && filters.push(`is_test:${fetchParams.is_test}`);
    fetchParams.ignore_exam_id && filters.push(`ignore_exam_id:${fetchParams.ignore_exam_id}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _orderBy: fetchParams.orderBy ? fetchParams?.orderBy?.join?.(";") : undefined,
        _assignment_fields:
            fields ||
            `${[
                "name",
                "begin_date",
                "end_date",
                "exam",
                "count_record",
                "class",
                "record",
                "is_test",
                "homework_time_minute",
                "ranking_type",
                "ranking_value",
                "order",
            ]}`,
        ...(isRelationShip && { _relations: `${["category", "user"]}` }),
        ...(isRelationShip && { _category_fields: `${["name"]}`, _user_fields: `${["name"]}` }),
        // Pagination:
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };

    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchAssignmentsBank = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.teacher_id && filters.push(`teacher_id:${fetchParams.teacher_id}`);
    ["0", "1"].includes(fetchParams.is_test?.toString()) && filters.push(`is_test:${fetchParams.is_test}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.is_ranking && filters.push(`is_ranking:${fetchParams.is_ranking}`);
    fetchParams.ignore_exam_id && filters.push(`ignore_exam_id:${fetchParams.ignore_exam_id}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${[
            "name",
            "category",
            "begin_date",
            "end_date",
            "exam",
            "class",
            "homework_time_minute",
            "is_test",
            "ranking_type",
            "ranking_value",
            "previous_id",
            "class_category_ids",
            "count_submit_assignment",
        ]}`,
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,

            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchStudentAssignments = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    [0, 1].includes(fetchParams.is_test) && filters.push(`is_test:${fetchParams.is_test}`);
    fetchParams.status && filters.push(`status:${fetchParams.status}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${[
            "name",
            "begin_date",
            "end_date",
            "exam",
            "class",
            "record",
            "is_test",
            "ranking_type",
            "ranking_value",
            "homework_time_minute",
        ]}`,
        _relations: `${["category"]}`,
        _category_fields: `${["name"]}`,
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const findAssignment = (id, fetchParams = {}) => {
    const filters = [];
    fetchParams.competition_id && filters.push(`competition_id:${fetchParams.competition_id}`);

    const params = {
        _assignment_fields: `${[
            "name",
            "begin_date",
            "end_date",
            "exam",
            "class",
            "homework_time_minute",
            "previous_id",
        ]}`,
        _relations: `${[fetchParams?.relations?.length > 0 ? fetchParams.relations.map((item) => item) : undefined]}`,
        ...(fetchParams?.relations?.includes("category") > 0 ? { _category_fields: `${["name"]}` } : {}),
        _filter: filters.join(";") || undefined,
    };
    return api
        .get(`/assignment/${id}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createAssignment = (data) => {
    return api
        .post("/assignment", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const updateAssignment = (id, data) => {
    return api
        .put(`/assignment/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteAssignment = (id) => {
    return api
        .delete(`/assignment/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const addParticipantToAssignment = (assignmentId) => {
    return api
        .put(`/assignment/${assignmentId}/add_class_user`)
        .then(({ status, message, assignment_id }) => ({ status, message, assignment_id }))
        .catch(({ message }) => ({ status: false, message }));
};
