import React, { useEffect, useRef, useState } from "react";
import { Form, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { fetchCategories } from "src/api/containers/category";
import { fetchTags } from "src/api/containers/tag";
import useValues from "src/hooks/useValues";
import { default as CustomButton } from "src/modules/components/Button";
import { fetchClassesForStudent } from "src/api/containers/class";

const FilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetch = () => {},
    handleCloseFilterMenu = () => {},
}) => {
    const [form] = useForm();
    const [values, setValues] = useValues({
        categories: [],
        isFetchingCates: false,
        classes: [],
        isFetchingClasses: false,
    });
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            category_id: formData.category_id,
            class_id: formData.class_id,
            is_test: formData.is_test,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.category_id &&
            newFilterParams.push({
                name: "category_id",
                value: fetchParams.category_id,
                labelName: t("assignment.subject"),
                labelValue:
                    values.categories.filter((cate) => {
                        return cate.id === fetchParams.category_id;
                    })[0].name || "",
            });
        fetchParams.class_id &&
            newFilterParams.push({
                name: "class_id",
                value: fetchParams.class_id,
                labelName: t("assignment.class"),
                labelValue:
                    values.classes.filter((classItem) => {
                        return classItem.id === fetchParams.class_id;
                    })[0].name || "",
            });
        [0, 1, "0", "1"].includes(fetchParams.is_test) &&
            newFilterParams.push({
                name: "is_test",
                value: fetchParams.is_test,
                labelName: t("shared.type"),
                labelValue: fetchParams.is_test == 0 ? t("assignment.assignment") : t("assignment.exam"),
            });

        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleFetch({ page: 1, ...fetchParams }, true);
        handleCloseFilterMenu(newFilterParams);
    };

    useEffect(() => {
        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setValues({
                categories: res.data,
            });
        });

        fetchClassesForStudent({ slug: "", noPagination: true }).then((res) => {
            setValues({
                classes: res.data,
            });
        });
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);

            // Refetch data:
            const fetchParams = {
                category_id: newFormData.category_id,
                class_id: newFormData.class_id,
                is_test: newFormData.is_test,
                tag_id: newFormData.tag_id,
                type: newFormData.type,
                is_publish: newFormData.is_publish,
                is_public: newFormData.is_public,
            };
            setCurrFilterParams(filterParams);
            handleFetch({ page: 1, ...fetchParams }, true);
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu">
            <Form.Item name="category_id" label={t("assignment.subject")}>
                <Select
                    className="app-select"
                    placeholder={t("assignment.choose_subject")}
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    notFoundContent={
                        values.isFetchingCates === true ? (
                            <i>
                                <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                            </i>
                        ) : undefined
                    }
                >
                    {values.categories.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="class_id" label={t("assignment.class")}>
                <Select
                    className="app-select"
                    placeholder={t("assignment.choose_class")}
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    notFoundContent={
                        values.isFetchingClasses === true ? (
                            <i>
                                <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                            </i>
                        ) : undefined
                    }
                >
                    {values.classes.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="is_test" label={t("shared.type")}>
                <Select className="app-select" placeholder={t("assignment.select_type_assignment")}>
                    <Select.Option key={"assignment"} value={0}>
                        {t("assignment.assignment")}
                    </Select.Option>
                    <Select.Option key={"exam"} value={1}>
                        {t("assignment.exam")}
                    </Select.Option>
                </Select>
            </Form.Item>

            <div className="filter-menu-footer">
                <CustomButton
                    htmlType="submit"
                    type="grey"
                    title={t("shared.ok")}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
