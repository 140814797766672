import React, { useContext } from "react";
import Context from "./Context";

const Button = ({ icon, iconText, cmd, write, keystrokes, className, onClick }) => {
    const { quill } = useContext(Context);

    const click = (e) => {
        if (quill) {
            cmd && quill.cmd(cmd);
            write && quill.write(write);
            quill.focus();
            keystrokes?.forEach((key) => quill.keystroke(key));
        }
        const position = {
            x: e.clientX,
            y: e.clientY,
            target: e.target,
        };
        if (onClick instanceof Function) {
            onClick(write, keystrokes, position);
        }
    };

    return (
        <button
            type="button"
            onClick={click}
            className={`mathchar-btn${className ? " " + className : ""}`}
            children={icon ? <img src={icon} /> : iconText}
        />
    );
};

export default Button;
