import React, { useState } from "react";
import { Button, Col, Dropdown, Menu, notification, Popconfirm, Row, Space, Spin } from "antd";
import { UserOutlined, PlusCircleOutlined, EllipsisOutlined, StarOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { default as CustomButton } from "../Button";
import Icon from "src/modules/components/Icon";
import "./BranchItems.scss";
import ModalDetailBranch from "../ModalDetailBranch";
import { deleteBranch } from "src/api/containers/branch";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
import { useSelector } from "react-redux";

const BranchItems = (props) => {
    const {
        classList = [],
        pagination = {},
        handleFetchClassList = () => {},
        handleRefetchClassList = () => {},
    } = props;

    const user = useSelector((state) => state.auth.user);

    const [showModalDetailClass, setShowModalDetailClass] = useState({
        isShown: false,
        branchId: "",
        name: "",
        active: true,
    });
    const [showMenuBtnLoading, setShowMenuBtnLoading] = useState({
        isLoading: false,
        branchId: "",
    });

    const { pagesAllow, rolesAllow } = permissionUser;

    const handleDeleteClass = (branchId) => {
        if (branchId) {
            setShowMenuBtnLoading({ isLoading: true, classId: branchId });
            deleteBranch(branchId, {
                organization_id: user.paramsForOrgOrTeam?.organization_id,
            }).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload class list:
                    handleRefetchClassList();
                } else {
                    notification.error({
                        message: res.message || "",
                    });
                }
                setShowMenuBtnLoading({ isLoading: false, classId: "" });
            });
        }
    };

    const renderMenu = (branchId, name, active) => {
        return (
            <Menu className="class-item-menu">
                <Can I={rolesAllow.update} page={pagesAllow.branch}>
                    <Menu.Item
                        key="menu-item-2"
                        onClick={() => {
                            setShowModalDetailClass({
                                isShown: true,
                                branchId: branchId,
                                name: name,
                                active: active ? true : false,
                            });
                        }}
                    >
                        <Space className="class-item-menu-btn-wrapper">
                            <Icon name="icon-modify" />
                            {t("shared.update")}
                        </Space>
                    </Menu.Item>
                </Can>
                <Can I={rolesAllow.delete} page={pagesAllow.branch}>
                    <Popconfirm
                        title={t("shared.confirm_delete")}
                        onConfirm={() => {
                            handleDeleteClass(branchId);
                        }}
                        okText={t("shared.yes")}
                        cancelText={t("shared.no")}
                    >
                        <Menu.Item key="menu-item-1">
                            <Space className="class-item-menu-btn-wrapper">
                                <Icon name="icon-delete" />
                                {t("shared.delete")}
                            </Space>
                        </Menu.Item>
                    </Popconfirm>
                </Can>
            </Menu>
        );
    };

    return (
        <>
            <Row gutter={[30, 30]}>
                <Can I={rolesAllow.create} page={pagesAllow.branch}>
                    <Col xs={24} md={12} lg={8} xxl={6}>
                        <div className="class-item-add">
                            <CustomButton
                                title={t("branch.add_branch")}
                                icon={<PlusCircleOutlined />}
                                onClick={() =>
                                    setShowModalDetailClass({ isShown: true, branchId: "", name: "", active: true })
                                }
                            />
                        </div>
                    </Col>
                </Can>
                {classList.map((item, index) => (
                    <Col key={index} xs={24} md={12} lg={8} xxl={6}>
                        <div className="class-item-wrapper">
                            <Spin
                                spinning={
                                    showMenuBtnLoading.classId === item.id
                                        ? showMenuBtnLoading.isLoading || false
                                        : false
                                }
                            >
                                <div className="class-item">
                                    <p className="class-item-header">{item.name}</p>
                                    <p>
                                        <StarOutlined className="class-item-icon" />
                                        {`${t("branch.status")}: `}
                                        {item.active ? t("branch.active") : t("branch.inactive")}
                                    </p>
                                    <p>
                                        <UserOutlined className="class-item-icon" />
                                        {`${t("branch.creator")}: `}
                                        {item.creator.name}
                                    </p>
                                </div>
                            </Spin>
                            <Can I_OR={[rolesAllow.update, rolesAllow.delete]} page={pagesAllow.branch}>
                                <Dropdown
                                    overlay={renderMenu(item.id, item.name, item.active)}
                                    trigger={["click"]}
                                    placement="bottomRight"
                                >
                                    <Button className="class-item-dropdown-btn">
                                        <EllipsisOutlined />
                                    </Button>
                                </Dropdown>
                            </Can>
                        </div>
                    </Col>
                ))}
                {pagination.current < pagination.lastPage ? (
                    <Col xs={24} md={12} lg={8} xxl={6}>
                        <div
                            className="class-item-loadmore"
                            onClick={() => handleFetchClassList({ page: pagination.current + 1 })}
                        >
                            <CustomButton title={`${t("class.load_more_class")}...`} icon={null} />
                        </div>
                    </Col>
                ) : null}
            </Row>

            <ModalDetailBranch
                organization={user.paramsForOrgOrTeam}
                classId={showModalDetailClass.branchId}
                details={showModalDetailClass}
                visible={showModalDetailClass.isShown}
                onOk={() => {
                    handleRefetchClassList();
                }}
                onCancel={() => {
                    setShowModalDetailClass({ ...showModalDetailClass, isShown: false });
                }}
                destroyOnClose
            ></ModalDetailBranch>
        </>
    );
};

export default BranchItems;
