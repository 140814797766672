import { message, notification, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { findRecord, markStudentAssignment } from "src/api/containers/record";
import Button from "src/modules/components/Button";
import QuestionNumberList from "src/modules/components/NumberList/QuestionNumberList";
import ExamResult from "src/modules/components/ProgressBar/ExamResult";
import QuestionResultPanel from "src/modules/components/QuestionResultPanel";
import StudentFrame from "src/modules/components/StudentFrame";
import StudentHeader from "src/modules/components/StudentHeader";
import "./ViewScoreStudent.scss";
import { getAllSearchParams, objectToSearchParamsString } from "src/utils/helpers/urlQueryString";
import { useSelector } from "react-redux";
import { useRouting } from "src/utils/router";
import PDFTestResult from "../Exam/DetailExamPDF/components/PDFTestResult";
import { LogoutOutlined } from "@ant-design/icons";

const ViewScoreStudent = (props) => {
    const {
        record_id,
        markingRecord = null,
        role = "student",
        typeViewScore, // "preview" || "review"
        isMarking = false,
        isModalBox = false,
        closeModal = () => {},
    } = props;

    const { generate } = useRouting();
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [record, setRecord] = useState({
        data: null,
        order: -1,
    });

    const isExamPDF = useMemo(() => {
        return record?.data?.assignment?.exam?.type === "pdf";
    }, [record]);

    const [markedQuestions, setMarkedQuestions] = useState({});
    const [loading, setLoading] = useState(true);

    const { user } = useSelector((state) => state.auth);

    const markedScore = useMemo(() => {
        const pureScore = record?.data?.score;

        const markedTotalScore = Object.entries(markedQuestions).reduce((prevValue, [_, curValue]) => {
            // Case passage
            if (curValue.type === "passage") {
                const totalScoreInPassage = Object.keys(curValue).reduce((prevChildValue, curChildKey) => {
                    const curChildValue = curValue[curChildKey];
                    if (curChildKey === "note" || curChildKey === "type") return prevChildValue;
                    return prevChildValue + curChildValue.score - curChildValue.oldScore;
                }, 0);

                return (prevValue += totalScoreInPassage);
            } else {
                if (typeof curValue.score === "number") {
                    return (prevValue += curValue.score - (curValue.oldScore || 0));
                }

                return prevValue;
            }
        }, 0);

        return pureScore + markedTotalScore;
    }, [markedQuestions, record?.data?.score]);

    const handleSaveMarkingRecord = () => {
        const payloadQuestion = {};

        if (isExamPDF) {
            record.data.history?.forEach((p) => {
                p?.questions?.forEach((q) => {
                    payloadQuestion[q.id] = { score: q.score_teacher_submit ?? q.score_submit };
                });
            });
        } else {
            record.data.history.forEach((q) => {
                if (q.type === "passage") {
                    const newQuestionMarked = {};

                    q.paragraphs.forEach((p) => {
                        p?.question_details?.forEach((qChild) => {
                            newQuestionMarked[qChild.id] = {
                                score: (qChild.score_teacher_submit ?? qChild.score_submit) || 0,
                            };
                        });
                    });

                    payloadQuestion[q.id] = newQuestionMarked;
                } else {
                    payloadQuestion[q.id] = { score: q.score_teacher_submit ?? q.score_submit };
                }
            });
        }

        Object.entries(markedQuestions).forEach(([questionId, element]) => {
            // Case passage
            if (element.type === "passage") {
                const childScoreOfQuestions = {};

                Object.entries(element).forEach(([childId, value]) => {
                    if (childId === "note") return (childScoreOfQuestions[childId] = value);

                    if (childId === "type") return;

                    childScoreOfQuestions[childId] = {
                        score: value.score,
                    };
                });

                payloadQuestion[questionId] = {
                    ...payloadQuestion[questionId],
                    ...childScoreOfQuestions,
                };
            } else {
                payloadQuestion[questionId] = {
                    ...(payloadQuestion[questionId] || {}),
                    score: element.score,
                    ...(element.note && { note: element.note }),
                };
            }
        });

        setLoading(true);
        markStudentAssignment(markingRecord?.id, { questions: payloadQuestion }).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.mark_success") });
                closeModal();
            } else {
                notification.error({ message: message || t("message.mark_error") });
            }
            setLoading(false);
        });
    };

    const markingQuestion = (id, { score, oldScore, note, childId }) => {
        const newMarkedQuestions = { ...markedQuestions };

        if (childId) {
            const payload = {
                type: "passage",
                ...(newMarkedQuestions[id] || {}),
                [childId]: {
                    oldScore: 0,
                    score: 0,
                },
            };

            payload[childId].oldScore = oldScore || 0;
            payload[childId].score = score || 0;

            newMarkedQuestions[id] = payload;
        } else {
            newMarkedQuestions[id] = {
                ...(newMarkedQuestions[id] || {}),
            };

            newMarkedQuestions[id].oldScore = oldScore || 0;
            newMarkedQuestions[id].score = score || 0;

            note !== undefined && (newMarkedQuestions[id].note = note);
        }

        setMarkedQuestions(newMarkedQuestions);
    };

    useEffect(() => {
        setLoading(true);
        if (!markingRecord) {
            findRecord(id || record_id)
                .then((res) => {
                    if (res?.status) {
                        setRecord({
                            data: res?.data,
                            order: 1,
                        });
                        setLoading(false);
                    } else {
                        notification.error({
                            message: res?.message,
                        });
                    }
                })
                .catch(({ message }) => {
                    notification.error({
                        message: message,
                    });
                });
        } else {
            setRecord({
                order: 1,
                data: markingRecord,
            });
            setLoading(false);
        }
    }, [id]);

    const handleChooseQuestion = (question) => {
        setRecord({
            ...record,
            order: question,
        });
    };

    const handleStudentExit = () => {
        const previousPage = searchParams.get("prev");
        const params = getAllSearchParams(searchParams);
        delete params.prev;

        if (previousPage === "rounds") {
            if (params.course) {
                navigate(generate("rounds") + objectToSearchParamsString(params));
            }
        } else if (previousPage === "scores") {
            navigate(generate("scores"));
        } else {
            navigate(generate("home"));
        }
    };

    const renderActions = (isPDF) => {
        return (
            <>
                {isMarking && record?.data?.history?.length > 0 && (
                    <div className="viewScore_teacherSaveMark">
                        <Button
                            type={isPDF ? "ghost2" : "second"}
                            title={t("shared.save")}
                            onClick={handleSaveMarkingRecord}
                        />
                    </div>
                )}
                {user?.role === "student" && (
                    <div className="viewScore_teacherSaveMark">
                        <Button
                            icon={<LogoutOutlined />}
                            type={"ghost2"}
                            title={t("shared.exit")}
                            onClick={handleStudentExit}
                        />
                    </div>
                )}
            </>
        );
    };

    if (record?.data?.assignment?.exam?.type === "pdf") {
        return (
            <Spin spinning={loading}>
                {record?.data?.count_sentences && (
                    <PDFTestResult
                        role={role}
                        showResults={true}
                        record={record}
                        order={record?.order}
                        isMarking={isMarking}
                        markedQuestions={markedQuestions}
                        markingQuestion={markingQuestion}
                        markedScore={markedScore}
                        setRecord={setRecord}
                        typeViewScore="review"
                        headerActions={renderActions()}
                    />
                )}
            </Spin>
        );
    }

    return (
        <Spin spinning={loading}>
            <div className={isModalBox ? "viewScoreStudent_wrapper model" : "viewScoreStudent_wrapper"}>
                <div className="viewScoreStudent_container">
                    {!isModalBox && (
                        <div className="viewScoreStudent_header">
                            <StudentHeader title="Danh sách bài được giao" isHaveBanner={false}>
                                <div className="homeStudent_controlPanel">
                                    {/* Mobile exam result */}
                                    <ExamResult
                                        isMobile
                                        width={200}
                                        totalScore={record?.data?.assignment?.exam?.max_score}
                                        score={markedScore}
                                        countQuestions={record?.data?.count_sentences}
                                        countCorrectAnswers={record?.data?.count_correct_sentences}
                                    />
                                </div>
                            </StudentHeader>
                        </div>
                    )}

                    <StudentFrame
                        isSelectBoxMobile
                        title={t("exam_result.your_assignment")}
                        questionAmount={record?.data?.history?.length}
                        handleChooseQuestion={handleChooseQuestion}
                        order={record.order}
                        assignmentName={record?.data?.assignment?.exam?.name}
                        isShowBreadcrumb
                    >
                        {record?.data?.count_sentences && (
                            <QuestionResultPanel
                                role={role}
                                showResults={true}
                                record={record?.data}
                                order={record?.order}
                                isMarking={isMarking}
                                markedQuestions={markedQuestions}
                                markingQuestion={markingQuestion}
                                setRecord={setRecord}
                                typeViewScore="review"
                            />
                        )}
                    </StudentFrame>

                    {role === "teacher" && (
                        <div className="viewScoreStudent_container_teacherSaveMark">
                            <Button type="primary" title={t("shared.save")} onClick={handleSaveMarkingRecord} />
                        </div>
                    )}
                </div>

                <div className="viewScore_controlPanel">
                    <div className="viewScore_controlPanel_inner">
                        <div className="viewScore_examResult">
                            <ExamResult
                                width={320}
                                totalScore={record?.data?.assignment?.exam?.max_score}
                                score={markedScore}
                                countQuestions={record?.data?.count_sentences}
                                countCorrectAnswers={record?.data?.count_correct_sentences}
                            />
                        </div>
                        <div className="viewScore_questionNumberList">
                            <QuestionNumberList
                                numberOfQuestions={record?.data?.history?.length}
                                order={record.order}
                                onChangeSelectedQuestion={handleChooseQuestion}
                            />
                        </div>
                        {renderActions()}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ViewScoreStudent;
