import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, notification, Space, Tag, Tooltip } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FormOutlined,
    ShareAltOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useFetch, useValues } from "src/hooks";
import Header from "src/modules/components/Header";
import FilterMenu from "./components/CompetitionFilterMenu";
import InputSearch from "src/modules/components/Input/InputSearch";
import Button from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import FilterIcon from "src/assets/images/filter-icon.svg";
import Pencil from "src/assets/images/pencil.svg";
import { Link, useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { deleteCompetition, listCompetitions } from "src/api/containers/Competition";
import { fetchClasses } from "src/api/containers/class";
import "./EntryTestBank.scss";
import { permissionUser } from "src/utils/permission";
import Can from "src/modules/components/Can";
import ModalDetailEntryTest from "./components/ModalDetailEntryTest";
import { deleteEntryTest, listEntryTest } from "src/api/containers/entry_test";
import { fetchCategories } from "src/api/containers/category";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import ModalMarkAssignment from "../Class/components/ModalMarkAssignment";
import Icon from "src/modules/components/Icon";

const EntryTestBank = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { generate } = useRouting();

    // User and scope:
    const { user, accountType } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // Date format:
    const formatDateTime = "DD/MM/YYYY HH:mm";

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }

    // Api
    //organization_id: fParamsOrgMember.organization_id
    const [categoryLoading, categoryData, categoryPagination, fetchCategory, reFetchCategory] = useFetch(
        { noPagination: 1, relations: ["categories"] },
        fetchCategories
    );
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, slug: "", ...fParams },
        listEntryTest
    );

    const [isAddAssignmentModal, setIsAddAssignmentModal] = useState(false);
    const [editingEntryTest, setEditingEntryTest] = useState({});
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [values, setValues] = useValues({
        isInvModalVisible: false,
        editingEntryTest: {},
    });
    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;

    const [filterSettings, setFilterSettings] = useState({
        inputSearchAssignment: "",
        isFilterMenuVisible: false,
        filterParams: [
            ...(fParams.organization_id
                ? [
                      {
                          name: "organization_id",
                          value: fParams.organization_id,
                          labelValue: fParamsDetail.organization_name,
                      },
                  ]
                : []),
        ],
    });

    const { rolesAllow, pagesAllow, checkPermission } = permissionUser;

    const columns = [
        {
            title: t("entry_test.entry_test"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => record?.name,
        },

        {
            title: t("shared.category"),
            dataIndex: "category",
            key: "category",
            width: "auto",
            render: (text, record) => (
                // <Link target="_blank" to={"#"}>
                <span> {record?.category?.name}</span>
                // </Link>
            ),
        },

        {
            title: t("class.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.begin_date).format(formatDateTime)
                    : t("class.time_not_limited");
            },
        },
        {
            title: t("class.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date ? moment(record.end_date).format(formatDateTime) : t("class.time_not_limited");
            },
        },
        {
            title: t("entry_test.submitted_marked"),
            dataIndex: "count_record",
            key: "count_record",
            width: "auto",
            render: (text, record) => {
                return `${record?.count_record?.count_marked}/${record?.count_record?.count_submit}`;
            },
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="table-action question-bank-action" style={{ justifyContent: "center" }}>
                    <Tooltip placement="top" title={t("question_bank.view")}>
                        <span
                            type="ghost"
                            onClick={() => {
                                setValues({
                                    isModalViewRecord: true,
                                    assignment_id: record?.id,
                                    viewProgressAssignment: {
                                        id: record.id,
                                        name: record.name,
                                    },
                                    viewProgressClass: {
                                        id: record.class?.id,
                                        name: record.class?.name,
                                    },

                                    ...(record?.category
                                        ? {
                                              viewProgressSubject: {
                                                  id: record?.category?.id,
                                                  name: record?.category?.name,
                                              },
                                          }
                                        : {}),
                                });
                            }}
                        >
                            <EyeOutlined />
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.view_detail")}>
                        <span
                            className="table-action__icon"
                            onClick={() => {
                                const a = generate("entry_test_level", { id: record?.id });
                                navigate(`${a}`);
                            }}
                        >
                            <UnorderedListOutlined />
                        </span>
                    </Tooltip>

                    <Tooltip placement="top" title={t("assignment.invite_via_link")}>
                        <span
                            className="table-action__icon"
                            onClick={() => {
                                setValues({
                                    isInvModalVisible: true,
                                });
                                setSelectedItem(record);
                            }}
                        >
                            <ShareAltOutlined className="action-icon" />
                        </span>
                    </Tooltip>

                    {/* <Can I={rolesAllow.update} orLogic={() => !user?.currentOrg} page={pagesAllow.competition}> */}
                    <Tooltip placement="top" title={t("shared.update")}>
                        <span className="table-action__icon" onClick={() => handleUpdateAssignment(record)}>
                            <EditOutlined />
                        </span>
                    </Tooltip>
                    {/* </Can> */}
                    {/* <Can I={rolesAllow.delete} orLogic={() => !user?.currentOrg} page={pagesAllow.competition}> */}
                    <Tooltip placement="top" title={t("shared.delete")}>
                        <span
                            className="table-action__icon question-bank-delete"
                            onClick={() => handleShowConfirmModal(record)}
                        >
                            <DeleteOutlined />
                        </span>
                    </Tooltip>
                    {/* </Can> */}
                </div>
            ),
        },
    ];

    const getFilterParamValue = (fName, fValueNotFound = undefined) => {
        for (let i = 0; i < filterSettings.filterParams.length; i++) {
            if (filterSettings.filterParams[i].name === fName) {
                return filterSettings.filterParams[i].value;
            }
        }
        return fValueNotFound;
    };

    const handleUpdateAssignment = (item) => {
        setEditingEntryTest(item);
        setIsAddAssignmentModal(true);
    };

    const handleShowConfirmModal = (item) => {
        setSelectedItem(item);
        setIsConfirmModalVisible(true);
    };

    const handleSearchAssignment = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchAssignment });
        }
    };

    const handleDeleteTest = () => {
        const item = selectedItem;
        deleteEntryTest(item.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setIsConfirmModalVisible(false);
                setSelectedItem({});
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        dispatch(setCurrentPage("entry_test"));
        fetch({}, true);
        fetchCategory({}, true);
    }, []);

    useEffect(() => {
        //animation when clone a new question
        if (animate?.name == "assignment") {
            const rowAnimation = (id, action, timeout = 3000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };

            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    return (
        <>
            <div className="question-bank competitions">
                <Header backEnable={false} backTitle={t("entry_test.entry_test")} actions={[]} />

                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchAssignment: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchAssignment();
                                    }
                                }}
                                onClickIconSearch={handleSearchAssignment}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                          fKey.labelValue || ""
                                                      }`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                forceRender
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        categoryList={categoryData}
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetchEntryTest={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) => {
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val });
                                }}
                            >
                                <div className="filter-button">
                                    <Button type="primary" icon={FilterIcon} title={t("shared.option_filter")}></Button>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions">
                        {/* <Can I={rolesAllow.create} orLogic={() => !user?.currentOrg} page={pagesAllow.competition}> */}
                        <Button
                            type="primary"
                            icon={Pencil}
                            title={t("entry_test.create_test")}
                            onClick={() => {
                                setEditingEntryTest({});
                                setIsAddAssignmentModal(true);
                            }}
                        ></Button>
                        {/* </Can> */}
                    </div>
                </div>

                <div className="table competitions-table">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={loading}
                        pagination={{
                            showSizeChanger: false,
                            ...pagination,
                            position: ["bottomCenter"],
                            onChange: (page) => {
                                fetch({ page: page });
                            },
                        }}
                        scroll={{ x: 1500 }}
                        rowKey="id"
                    />
                </div>
            </div>

            {isAddAssignmentModal && (
                <ModalDetailEntryTest
                    isLoading={loading}
                    visible={isAddAssignmentModal}
                    editingEntryTest={editingEntryTest}
                    categoryList={categoryData}
                    onOk={() => {
                        refetch();
                        setIsAddAssignmentModal(false);
                        setEditingEntryTest({});
                    }}
                    onCancel={() => {
                        setIsAddAssignmentModal(false);
                        setEditingEntryTest({});
                    }}
                    classScopeId={fParamsOrgMember.organization_id}
                />
            )}

            <PopConfirm
                type="delete"
                visible={isConfirmModalVisible}
                onOk={handleDeleteTest}
                onCancel={() => setIsConfirmModalVisible(false)}
            />

            <ModalInviteLink
                targetId={`${window.location.origin}/assignment/` + selectedItem?.id}
                visible={values.isInvModalVisible}
                onOk={() => {
                    setValues({ isInvModalVisible: false });
                }}
                onCancel={() => {
                    setValues({ isInvModalVisible: false });
                }}
            />

            <ModalMarkAssignment
                className="class-detail-modal detail-marking"
                centered
                title={t("class.mark_assignment")}
                footer={null}
                visible={values.isModalViewRecord}
                onCancel={() => setValues({ isModalViewRecord: false })}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
                markingAssignmentId={values.assignment_id}
                // setIsPersonalLearningProgress={(status) => setValues({ isModalPersonalLearningProgress: status })}
                // setStudentInfo={(inf) => setValues({ studentInfo: inf })}
            />
        </>
    );
};

export default EntryTestBank;
