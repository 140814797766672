import { PDF_CONST } from "../../constant";
import videoIcon from "src/assets/images/pdf/video.png";
import { getYoutubeId } from "../../pdf_helpers";

class YoutubeTool {
    constructor(zwibblerContext, src) {
        // initialize any variables here here.
        this.ctx = zwibblerContext;
        this.url = PDF_CONST.LINK_ICON;
        this.width = "60px";
        this.height = "60px";
        // this.doneFn = doneFn || function (nodeid) {};
    }

    enter() {
        this.ctx.setCursor("pointer");
    }

    leave() {
        // Called by Zwibbler when a different tool is selected
        // for any reason. Clean up here.
        // Redraw is necessary in this tool only because we have drawn the
        // "ghost text" in the onMouseMove method and we should not leave it
        // on the screen.
        // this.ctx.redraw();
    }

    onMouseClick(x, y, e) {
        // The x, y coordinates are coordinates in the document, taking into
        // account zooming and scrolling. "e" is the DOM event that caused the
        // mouse click.
        // this.ctx.begin();
        // var node = this.ctx.createNode("ImageNode", {
        //     url: this.url,
        //     width: this.width,
        //     height: this.height,
        //     x: x,
        //     y: y,
        // });
        // this.ctx.translateNode(node, x, y);
        // this.ctx.commit();
        // this.ctx.usePickTool();
        // this.doneFn?.(node);

        let ytLink = prompt("Copy and paste the video embed code", "");
        console.log("ytLink:::", ytLink);
        const yId = getYoutubeId(ytLink);

        if (yId) {
            this.ctx.useStampTool(
                {
                    url: videoIcon,
                    lockSize: true,
                    width: 40,
                    _ytCode: yId,
                },
                true
            );
        }

        // this.ctx.zoomIn();
    }

    onMouseMove(x, y, e) {
        // this.ctx.redraw((canvas) => {
        //     canvas.fillStyle = "rgba(0,0,0,0.5)"; // 50% black
        //     canvas.font = this.fontSize + 'px "' + this.fontName + '"';
        //     y += this.fontSize;
        //     canvas.fillText(self.text, x, y);
        // });
    }
}

export { YoutubeTool };
