const fontList = {
    appGlobalFont: "Mulish=Mulish, sans-serif",
    editorDefaultFonts: {
        andale_mono: "Andale Mono=andale mono,times",
        arial: "Arial=arial,helvetica,sans-serif",
        arial_black: "Arial Black=arial black,avant garde",
        book_antiqua: "Book Antiqua=book antiqua,palatino",
        comic_sans_ms: "Comic Sans MS=comic sans ms,sans-serif",
        courier_new: "Courier New=courier new,courier",
        georgia: "Georgia=georgia,palatino",
        helvetica: "Helvetica=helvetica",
        impact: "Impact=impact,chicago",
        symbol: "Symbol=symbol",
        tahoma: "Tahoma=tahoma,arial,helvetica,sans-serif",
        terminal: "Terminal=terminal,monaco",
        times_new_roman: "Times New Roman=times new roman,times",
        trebuchet_ms: "Trebuchet MS=trebuchet ms,geneva",
        verdana: "Verdana=verdana,geneva",
        webdings: "Webdings=webdings",
        wingdings: "Wingdings=wingdings,zapf dingbats",
    },
    editorAdditionalFonts: {
        open_sans: "Open Sans=Open Sans, sans-serif",
    },
};

/**
 * Used for editor config: fontsize_formats.
 */
export const fontSizeList = "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt";

/**
 * Used for editor config: lineheight_formats.
 */
export const lineHeightList = "1 1.1 1.2 1.3 1.4 1.5 2";

/**
 * Used for editor config: font_formats.
 * Get font list.
 * @returns List of font family names.
 */
export const getFontList = () => {
    const fontPrimary = fontList.appGlobalFont;
    const fonts = { ...fontList.editorDefaultFonts, ...fontList.editorAdditionalFonts };
    const fontsInOrder = Object.keys(fonts)
        .sort()
        .map((item) => {
            return fonts[item];
        });
    const result = [fontPrimary, ...fontsInOrder].join("; ");
    return result;
};
