import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //save id question/assignment/exam recently add,update,or delete
    animation: {
        name: "",
        add: "",
        update: "",
        delete: "",
        clone: "",
    },
};

export const animationSlice = createSlice({
    name: "animation",
    initialState,
    reducers: {
        animationId: (state, action) => {
            if (action.payload) {
                state.animation = action.payload;
            }
        },
    },
});

export const { animationId } = animationSlice.actions;

export default animationSlice.reducer;
