import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchCategories } from "src/api/containers/category";
import useValues from "src/hooks/useValues";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";
import moment from "moment";

const CompetitionFilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchEntryTest = () => {},
    handleCloseFilterMenu = () => {},
    classData = [],
    categoryList = [],
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        categories: [],
        optionListSubject: [],
        isFetchingCates: false,
        currFilterParams: filterParamsInitial?.length ? filterParamsInitial : [],
    });

    const timeoutIdGetCates = useRef(null);

    // Api
    // const [classLoading, classData, classPagination, fetchClass, reFetchClass] = useFetch(
    //     { noPagination: 1, relations: ["categories"] },
    //     fetchClasses
    // );

    const handleSubmit = (fetchParams) => {
        const newFilterParams = [];
        if (fetchParams.begin_date) {
            newFilterParams.push({
                name: "begin_date",
                value: fetchParams.begin_date,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.begin_date).format("DD-MM-YYYY"),
            });
        }

        if (fetchParams.end_date) {
            newFilterParams.push({
                name: "end_date",
                value: fetchParams.end_date,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.end_date).format("DD-MM-YYYY"),
            });
        }

        fetchParams.category_id &&
            newFilterParams.push({
                name: "category_id",
                value: fetchParams.category_id,
                labelName: t("shared.category"),
                labelValue:
                    categoryList?.filter((cate) => {
                        return cate.id === fetchParams.category_id;
                    })[0]?.name || "",
            });
        fetchParams.organization_id !== undefined &&
            newFilterParams.push({
                name: "organization_id",
                value: fetchParams.organization_id,
                labelValue: scopeName,
            });
        setValues({
            currFilterParams: newFilterParams,
        });
        // Fetch assignment list by the corresponding filter values:
        handleFetchEntryTest({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    const handleSearchCategories = (keyword) => {
        clearTimeout(timeoutIdGetCates.current);
        if (typeof keyword === "string") {
            setValues({ categories: [], isFetchingCates: true });
            timeoutIdGetCates.current = setTimeout(() => {
                fetchCategories({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ categories: res.data, isFetchingCates: false });
                    }
                });
            }, 500);
        }
    };

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (values.currFilterParams.length) {
                for (let i = 0; i < values.currFilterParams.length; i++) {
                    newFormData[values.currFilterParams[i].name] = values.currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== values.currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                category_id: newFormData.category_id,
                organization_id: newFormData.organization_id,
                begin_date: newFormData.begin_date,
                end_date: newFormData.end_date,
            };
            setValues({
                currFilterParams: filterParams,
            });
            handleFetchEntryTest({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <div className="filter-competitions">
            <Form
                form={form}
                className="form form-full-label filter-menu layout-grid "
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Row gutter={[10, 0]}>
                    {fParamsOrgMember?.organization_id && (
                        <Col span={12}>
                            <Form.Item name="organization_id" label={t("shared.scope")}>
                                <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                                    <Select.Option value={fParamsOrgMember.organization_id}>
                                        {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={fParamsOrgMember?.organization_id ? 12 : 24}>
                        <Form.Item name="category_id" label={t("shared.category")} labelCol={24}>
                            <Select
                                className="app-select"
                                placeholder={t("shared.choose_category")}
                                optionFilterProp="children"
                                showSearch
                                allowClear
                                // onSearch={(keyword) => {
                                //     handleSearchCategories(keyword);
                                // }}
                                // notFoundContent={
                                //     values.isFetchingCates === true ? (
                                //         <i>
                                //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                //         </i>
                                //     ) : (
                                //         undefined
                                //     )
                                // }
                            >
                                {categoryList?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[10, 0]}>
                    <Col span={12}>
                        <Form.Item name="begin_date" label={t("report.dateFrom")}>
                            <DatePicker
                                placeholder={t("report.please_select_date")}
                                className=" ant-picker filter-person_input"
                                // suffixIcon={<img src={CalanderIcon} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="end_date" label={t("report.dateTo")}>
                            <DatePicker
                                placeholder={t("report.please_select_date")}
                                className="filter-person_input"
                                // suffixIcon={<img src={CalanderIcon} />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="filter-menu-footer">
                    <CustomButton
                        type="grey"
                        title={t("shared.cancel")}
                        icon={<Icon name="icon-cross-thick" />}
                        onClick={() => handleCloseFilterMenu(false)}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        title={t("shared.apply")}
                        icon={<Icon name="icon-tick" />}
                        // onClick={handleSubmit}
                    ></CustomButton>
                </div>
            </Form>
        </div>
    );
};

export default CompetitionFilterMenu;
