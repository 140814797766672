import { Alert, Dropdown, Spin, Tag } from "antd";
import html2canvas from "html2canvas";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPersonalProgress } from "src/api/containers/statistic";
import { ReactComponent as FilterIcon } from "src/assets/images/filter-icon.svg";
import { useFetch } from "src/hooks";
import { useAuth } from "src/modules/auth";
import Button from "src/modules/components/Button";
import ClassificationChart from "src/modules/components/Chart/ClassificationChart";
import Header from "src/modules/components/Header";
import PercentColumnPlot from "src/modules/components/PercentColumnPlot";
import UserBoard from "src/modules/components/UserBoard";
import { setCurrentPage } from "src/reducers/general";
import { exportPersonalLearningToPdfText } from "src/utils/helpers";
import FilterMenu from "./components/FilterMenu";
import ReportTable from "./components/ReportTable";
import "./ReportStudent.scss";

const PersonalLearningProgress = () => {
    const navigate = useNavigate();
    const slider = useRef(null);

    const { user } = useAuth();

    const [
        loadingPersonalProgress,
        dataPersonalProgress,
        paginationPersonalProgress,
        fetchPersonalProgress,
        refetchPersonalProgress,
    ] = useFetch({ page: 1, slug: "" }, getPersonalProgress);

    const [filterSettings, setFilterSettings] = useState(() => {
        const filterParams = [];
        let isFilterMenuVisible = true;

        if (user.role === "student") {
            filterParams.push({
                labelName: t("student"),
                labelValue: "A Student",
                name: "student_id",
                value: user.id,
            });
            isFilterMenuVisible = false;
        }

        return {
            isFilterMenuVisible,
            filterParams,
        };
    });

    const [isResized, setIsResized] = useState(true);
    const [isMobile, setIsMobile] = useState(null);
    const timer = useRef();

    const handleResize = (e) => {
        const width = window.innerWidth;

        if (width <= 500) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

        clearInterval(timer.current);
        setIsResized(false);

        timer.current = setTimeout(() => {
            setIsResized(true);
        }, 500);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("learning_progress"));
        const width = window.innerWidth;

        if (width <= 500) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleExportPersonalLearningToPdf = async () => {
        const logoOrganization = document.createElement("div");
        logoOrganization.classList.add("pdf__logo");

        // Tính năng hiện logo của organization khi export pdf (chưa xong)
        const base64img = "";
        // base64 img
        logoOrganization.innerHTML = `<img src="${base64img}" /> <span></span>`;
        document.body.appendChild(logoOrganization);

        const canvas = await html2canvas(logoOrganization);
        const logo = canvas.toDataURL();

        exportPersonalLearningToPdfText(
            dataPersonalProgress,
            dataPersonalProgress?.name,
            logo,
            dataPersonalProgress?.arr_name_class
        );

        logoOrganization.remove();
    };

    const handleGoBack = (e) => {
        if (e) {
            e.preventDefault();
        }

        navigate(-1);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="reportStudent_wrapper">
            <div className="reportStudent_header">
                <Header backTitle={t("report.personal_learning_progress")} className="" backFunc={handleGoBack} />
            </div>
            <div className="reportStudent_box">
                <div className="reportStudent_filter">
                    <div className="tags">
                        {filterSettings.filterParams?.length > 0 &&
                            filterSettings.filterParams.map((fKey, i) => {
                                // Remove tag student when student check personal learning report
                                if (user.role === "student" && fKey.name == "student_id") {
                                    return;
                                }
                                return (
                                    <Tag
                                        className="tag_item_inner"
                                        key={`filter-key${i}`}
                                        closable
                                        onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                    >
                                        {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`}
                                    </Tag>
                                );
                            })}
                    </div>
                    <div className="reportStudent_filter_inner">
                        <div className="filterButton">
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                forceRender
                                overlay={
                                    <FilterMenu
                                        isStudent={user.role === "student"}
                                        filterParams={filterSettings.filterParams}
                                        handleFetch={fetchPersonalProgress}
                                        handleCloseFilterMenu={(newFilterParams) => {
                                            if (user.role === "student") {
                                                newFilterParams.push({
                                                    labelName: "Học sinh",
                                                    labelValue: "A Student",
                                                    name: "student_id",
                                                    value: user.id,
                                                });
                                            }
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            });
                                        }}
                                    />
                                }
                                overlayClassName="progress-filter"
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <Button
                                    type="primary"
                                    title={t("report.filter")}
                                    icon={<FilterIcon />}
                                    className="tags_button"
                                />
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {loadingPersonalProgress && <Spin tip={`${t("shared.loading")}...`}></Spin>}
                {!loadingPersonalProgress && (
                    <>
                        {dataPersonalProgress?._id ? (
                            <>
                                <div className="reportStudent_item">
                                    <UserBoard
                                        fullName={dataPersonalProgress?.name || dataPersonalProgress?.username}
                                        nameOfClasses={dataPersonalProgress?.arr_name_class}
                                        avatar={dataPersonalProgress?.avatar}
                                        exportPdf={handleExportPersonalLearningToPdf}
                                    />
                                    {isResized && (
                                        <PercentColumnPlot
                                            label={t("report.course_completion_score")}
                                            data={dataPersonalProgress?.chart_for_assignment_category}
                                        />
                                    )}

                                    {!isResized && (
                                        <div style={{ width: "100%", padding: "10px", height: "400px" }}>
                                            <div
                                                style={{
                                                    backgroundColor: "#fff",
                                                    borderRadius: "4px",
                                                    height: "100%",
                                                    padding: "20px",
                                                }}
                                            >
                                                <Spin spinning={true}></Spin>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="reportStudent_item big">
                                    <div className="reportStudent_chartPie_wrapper">
                                        <div className="reportStudent_chartPie">
                                            <div className="reportStudent_title">{t("report.rate_tests_taken")}</div>
                                            {isResized && (
                                                <ClassificationChart
                                                    isMobile={isMobile}
                                                    data={dataPersonalProgress?.chart_for_assignment}
                                                ></ClassificationChart>
                                            )}
                                            {!isResized && <Spin spinning={true}></Spin>}
                                        </div>
                                    </div>
                                    <div className="reportStudent_table_wrapper">
                                        <div id="pdf__tableWithPagination" className="reportStudent_table_inner">
                                            {isResized && (
                                                <ReportTable
                                                    isPagination={true}
                                                    data={dataPersonalProgress?.records_without_history}
                                                />
                                            )}

                                            {!isResized && <Spin spinning={true}></Spin>}
                                        </div>
                                        <div
                                            id="pdf__tableWithoutPagination"
                                            style={{ display: "none" }}
                                            className="reportStudent_table_inner"
                                        >
                                            {isResized && (
                                                <ReportTable
                                                    isPagination={false}
                                                    data={dataPersonalProgress?.records_without_history}
                                                />
                                            )}

                                            {!isResized && <Spin spinning={true}></Spin>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Alert
                                description={t("report.message_student_have_not_selected")}
                                type="warning"
                                style={{ marginTop: 20 }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PersonalLearningProgress;
