import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, Tooltip } from "antd";
import React, { useRef } from "react";
import { t } from "i18next";
import "./HelpCarousuel.scss";
import EmptyImg from "src/assets/images/Empty_1.png";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";

const HelpCarousel = (props) => {
    const { data = [], visible, isReOpenModal } = props;
    const caroRef = useRef(null);
    const [carousuel, setCarousel] = useValues({ currentSlide: 0, nextSlide: 1 });
    const onChangeSlide = (currentSlide) => {
        setCarousel({ currentSlide: currentSlide, nextSlide: currentSlide + 1 });
    };
    if (visible && isReOpenModal && caroRef && caroRef?.current) {
        caroRef.current?.goTo(0);
    }
    return (
        <>
            <Carousel className="help-carousuel" afterChange={(slide) => onChangeSlide(slide)} ref={caroRef}>
                {data && data?.length > 0 ? (
                    data.map((item) => {
                        return (
                            <div className="help-carousuel-body" key={item._id}>
                                {/* <div className="help-carousuel-title">help title</div> */}
                                <div className="help-carousuel-body-inner">
                                    <div className="help-carousuel-desc">
                                        <div dangerouslySetInnerHTML={{ __html: item?.description || "" }}></div>
                                    </div>
                                    <div className="help-carousuel-img">
                                        <img src={item?.image?.src} />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="no-data">
                        <img src={EmptyImg} />
                        <div>{t("help.nodata")}</div>
                    </div>
                )}
            </Carousel>
            <div className="navigate-button">
                {carousuel.currentSlide > 0 && (
                    <Tooltip title={t("help.pre")}>
                        <CustomButton
                            title={<LeftOutlined />}
                            onClick={() => caroRef.current.prev()}
                            className="navigate-icon prev"
                        ></CustomButton>
                    </Tooltip>
                )}
                {carousuel.nextSlide < data.length && (
                    <Tooltip title={t("help.next")}>
                        <CustomButton
                            title={<RightOutlined />}
                            onClick={() => caroRef.current.next()}
                            className="navigate-icon next"
                        ></CustomButton>
                    </Tooltip>
                )}
            </div>
        </>
    );
};

export default HelpCarousel;
