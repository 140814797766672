import React from "react";
import Clock from "src/modules/components/Clock";
import useMediaQuery from "src/hooks/useMediaQuery";
import "./TestLayout_Header.scss";

const Header = (props) => {
    const { title, icon } = props;
    const currMedia = useMediaQuery(["(max-width: 1024px)"]);

    return (
        <div className="TestHeader">
            <div className="title_wrapper">
                <div className="title">{title}</div>
            </div>
            <div>
                <div className="icon">
                    <img src={icon} width="90px" alt="icon" />
                </div>

                <div className="TestHeader_Clock">
                    {currMedia.breakpointValue === 1024 && (
                        <Clock width={140} strokeColor={"fff"} trailColor="3190FB" theme="eloctronicClock" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Header);
