import { useEffect, useState } from "react";
import Item from "./Item";
import "./Sidebar.scss";

const Sidebar = ({ controller, listFolders = () => {}, removeFiles = () => {} }) => {
    const [activeFolder, setActiveFolder] = useState(null);

    useEffect(() => {
        controller.set("sidebar.set_active_folder", (folder) => {
            setActiveFolder(folder);
        });
        return () => {
            controller.remove("sidebar.set_active_folder");
        };
    }, []);

    useEffect(() => {
        controller.set("sidebar.get_active_folder", () => {
            return activeFolder;
        });

        if (activeFolder && activeFolder.name !== "Root") {
            controller.call("toolbar.add_action", "delete");
        } else {
            controller.call("toolbar.remove_action", "delete");
        }

        return () => {
            controller.remove("sidebar.get_active_folder");
        };
    }, [activeFolder]);

    return (
        <div className="file-manager-sidebar">
            <div className="file-manager-sidebar-container">
                <ul>
                    <Item
                        activeFolder={activeFolder}
                        controller={controller}
                        listFolders={listFolders}
                        removeFiles={removeFiles}
                        folder={{ id: null, name: "Root" }}
                        flow={[{ id: null, name: "Root" }]}
                        path="/"
                    />
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
