import DragDrop from "src/modules/components/QuestionPanel/DragDrop";
import DragDropGroup from "src/modules/components/QuestionPanel/DragDropGroup";
import FillBlanks from "src/modules/components/QuestionPanel/FillBlanks";
import FillBlanksInput from "src/modules/components/QuestionPanel/FillBlanksInput";
import MultipleChoice from "src/modules/components/QuestionPanel/MultipleChoice";
import MultipleChoiceBoolean from "src/modules/components/QuestionPanel/MultipleChoiceBoolean";
import MultipleChoiceTable from "src/modules/components/QuestionPanel/MultipleChoiceTable";
import Sort from "src/modules/components/QuestionPanel/Sort";
import Writing from "src/modules/components/QuestionPanel/Writing";
import WritingShort from "src/modules/components/QuestionPanel/WritingShort";
import MultipleChoiceSentenceQuiz from "src/modules/components/QuestionPanel/MultipleChoiceSentenceQuiz";
import { Button, Collapse, Modal, Popconfirm, Space } from "antd";
import { useTranslation } from "react-i18next";
import { PlusCircleTwoTone } from "@ant-design/icons";
import Icon from "src/modules/components/Icon";
import { useContext, useState } from "react";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import QuestionContent from "src/modules/components/QuestionContent";
import FillBlanksDragDrop from "src/modules/components/QuestionPanel/FillBlanksDragDrop";
import MathTextDropdown from "src/modules/components/QuestionPanel/MathTextDropdown";
import Matrix from "src/modules/components/QuestionPanel/Matrix";
import "./PDFQuestionItem.scss";
import { addAnswer, addAnswerPreview } from "src/reducers/answer";
import { useDispatch } from "react-redux";

function PDFQuestionItem({
    mode,
    recordId,
    question,
    qIndex,
    isShowResult,
    setData = () => {},
    handleRemoveQuestion = () => {},
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { drawingTool: ctx, currentTab } = useContext(ExamPDFContext);
    const [qDataAnswered, setQDataAnswered] = useState({});

    const handleAnswer = (newValue) => {
        if (!newValue) {
            return;
        }
        // Tạm thời câu highlight bắn answer khác sau sẽ cập nhật lại
        if (newValue.passage_highlighted) {
            setQDataAnswered({
                answered: {
                    answered: newValue.answered,
                    passage_highlighted: newValue.passage_highlighted,
                },
            });
        } else {
            setQDataAnswered({
                answered: newValue?.answered,
            });
        }
    };

    const handleChange = (value) => {
        console.log(1, value);
        // if (onChange && onChange instanceof Function) {
        //     onChange(value);
        // }

        const result = {
            answered: value.answered,
            ...(value.type && { type: value.type }),

            // Tạm thời để đây vì câu highlight cần submit thêm cái passage sau sẽ phải đổi
            ...(value.passage_highlighted && { passage_highlighted: value.passage_highlighted }),
        };

        if (mode === "preview") {
            // this mode name is wrong! should be "review"
            dispatch(
                addAnswerPreview({
                    value: { [question.id]: result },
                })
            );
        }

        if (mode === "test") {
            console.log(2);
            dispatch(
                addAnswer({
                    value: { [question.id]: result },
                    record_id: recordId,
                })
            );
        }
    };

    console.log(mode);

    const renderQuestionByType = (q) => {
        const defaultProps = {
            question: q.question,
            answer: q.answer,
            qIndex: qIndex,
            exam_types: "pdf",
            onChange: mode === "test" ? handleChange : handleAnswer,
        };

        switch (q.type) {
            case "writing":
                return <Writing wordLimit={q.word_limit} {...defaultProps} />;
            case "writing_short":
                return <WritingShort {...defaultProps} />;
            case "fill_in_the_blank":
                return <FillBlanks {...defaultProps} />;
            case "fill_in_the_blank_text":
                return <FillBlanksInput {...defaultProps} />;
            case "fill_in_the_blank_drag_drop":
                return <FillBlanksDragDrop {...defaultProps} />;
            case "convert_measurement":
                return <MathTextDropdown {...defaultProps} />;
            case "fill_in_the_blank_latex":
            case "matrix":
                return <Matrix {...defaultProps} template_latex={q.template_latex} />;

            case "multiple_choice":
                return <MultipleChoice {...defaultProps} />;
            case "multiple_choice_sentence_quiz":
                return <MultipleChoiceSentenceQuiz {...defaultProps} />;
            case "multiple_choice_boolean":
                return <MultipleChoiceBoolean {...defaultProps} />;
            case "multiple_choice_table":
                return (
                    <MultipleChoiceTable {...defaultProps} answer1={q.answer1} answer2={q.answer2} match={q.match} />
                );
            case "drag_drop":
                return (
                    <DragDrop
                        {...defaultProps}
                        answer={{
                            answer1: q.answer1,
                            answer2: q.answer2,
                        }}
                    />
                );
            case "drag_drop_group":
                return <DragDropGroup {...defaultProps} answers={q.answer1} groups={q.answer2} />;

            case "drag_drop_group_order_by":
                return <DragDropGroup {...defaultProps} answers={q.answer1} groups={q.answer2} />;
            case "sort":
                return <Sort {...defaultProps} />;

            default:
                return <></>;
        }
    };

    function createBtn({ text = "text", id, x = 100, y = 100 }) {
        ctx.begin();
        var node = ctx.createNode("TextNode", {
            text: text,
            fontSize: 22,
            fontName: "Arial",
            // background: "#4fe3c1",
            textFillStyle: "#fff",
            lockEditMode: true,
            lockSize: true,
            lockText: true,
            lockRotation: true,
            fontWeight: 700,
            padding: 2,
            zIndex: 10,
            "border-width": 2,
            "border-color": "#555",
            "border-radius": 4,
            _qId: id || "#",
            background: "rgb(47, 65, 81)",
            bold: true,
        });
        ctx.translateNode(node, x, y);
        // console.log(node.get("text"));
        ctx.commit();
    }

    function handleDelete(id) {
        //delete question
    }

    return (
        <div className="pdf-question-item" id={question.id}>
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
                <Space>
                    <Button style={{ fontWeight: 700 }}>{qIndex}.</Button>
                    {currentTab?.tabKey === "detail" ? (
                        <Button
                            icon={<PlusCircleTwoTone />}
                            onClick={() => {
                                createBtn({ text: `${qIndex}.`, id: question.id });
                            }}
                        >
                            Thêm vào trang
                        </Button>
                    ) : null}
                </Space>
                <span>{`${t("shared.score")}: ${question.score}`}</span>
            </Space>

            <div className="pdf-question-item_content">
                {[currentTab?.tabKey, mode].includes("preview") && isShowResult ? (
                    <QuestionContent
                        key={`${question.id}_${qIndex}`}
                        type="review"
                        showResults={isShowResult}
                        // Question display type:
                        displayType="question-panel"
                        questionInfo={{
                            ...question,
                            ...qDataAnswered,
                        }}
                        isShowScore={false}
                    />
                ) : (
                    renderQuestionByType({
                        ...question,
                        ...qDataAnswered,
                    })
                )}
            </div>

            {currentTab?.tabKey == "detail" ? (
                <span className="action-list">
                    <Space size={"middle"} style={{ justifyContent: "flex-end", width: "100%" }}>
                        <span>
                            <Popconfirm
                                title={`${t("shared.msg_confirm_delete")}?`}
                                onConfirm={() => {
                                    handleRemoveQuestion?.(question.id);
                                }}
                                okText={t("shared.yes")}
                                cancelText={t("shared.no")}
                            >
                                <span className="action-button">
                                    <Icon name="icon-delete" />
                                </span>
                            </Popconfirm>
                        </span>
                        <span
                            className="action-button"
                            onClick={() => {
                                setData?.({ isModalUpdateting: true, selectedQuestion: question });
                            }}
                        >
                            <Icon name="icon-edit" />
                        </span>
                    </Space>
                </span>
            ) : null}
        </div>
    );
}

export default PDFQuestionItem;
