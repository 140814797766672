import { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import configEditor from "src/utils/configEditor";
import Editor from "../Editor";
import "./TextareaEditor.scss";

const TextareaEditor = ({
    className,
    disabled,
    placeholder,
    value,
    // Suffix:
    suffix,
    isSuffix = false,
    // Editor:
    wordCountMax,
    onCountWord,
    // Event handlers:
    onChange,
}) => {
    const editorElemRef = useRef(null);
    const editorConfigTextArea = useRef(
        configEditor.textArea({
            wordCountMax,
            onCountWord,
        })
    );

    return (
        <div
            ref={editorElemRef}
            className={clsx("textarea-editor", className, disabled && "textarea-editor--disabled")}
            onMouseDown={(e) => {
                if (disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            <div className="textarea-editor__editor">
                <Editor
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    config={editorConfigTextArea.current}
                />
            </div>
            {isSuffix && <div className="textarea-editor__suffix">{suffix}</div>}
        </div>
    );
};

TextareaEditor.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    // Suffix:
    suffix: PropTypes.string,
    isSuffix: PropTypes.bool,
    // Editor:
    wordCountMax: PropTypes.number,
    onCountWord: PropTypes.func,
    // Event handlers:
    onChange: PropTypes.func,
};

TextareaEditor.defaultProps = {
    isSuffix: false,
};

export default TextareaEditor;
