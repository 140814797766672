import React from "react";
import "./Button.scss";

const Button = (props) => {
    const { icon = null, title = "", onClick = () => {} } = props;

    return (
        <button className="class-btn" onClick={onClick}>
            {icon && icon}
            {title}
        </button>
    );
};

const OutlineButton = (props) => {
    const { icon = null, title = "", onClick = () => {} } = props;

    return (
        <button className="class-btn outline-btn" onClick={onClick}>
            {icon && icon}
            {title}
        </button>
    );
};

export default Button;
export { OutlineButton };
