import MathQuill from "mathquill-node";
import React, { useLayoutEffect, useRef } from "react";
import parser from "src/utils/parser";

const MQ = MathQuill.getInterface(2);
const LatexDangerously = ({ innerHTML, ...rest }) => {
    const dangerouslyLatex = useRef(null);

    useLayoutEffect(() => {
        dangerouslyLatex.current.querySelectorAll("math-static").forEach((mathItem) => {
            MQ.StaticMath(mathItem);
        });
    }, [innerHTML]);

    return (
        <span
            dangerouslySetInnerHTML={{ __html: parser.parseMathField(innerHTML) }}
            ref={dangerouslyLatex}
            {...rest}
        ></span>
    );
};

export default React.memo(LatexDangerously);
