import Button from "../Button";
import { number1, number2, number3, _2735, _2475, sqrt, _999, _944 } from "../../icons/tab1";
import { number4, number5, number6, _2737, dot, fraction, _941, _944_2 } from "../../icons/tab1";
import { number7, number8, number9, _2734, _2736, _942, _4065, _gt } from "../../icons/tab1";
import { number0, decimal, equal, _2788, _3341, _943, _6199, lt } from "../../icons/tab1";
import { _3528, Path3437, _2715, _2480, Path3436, Path3367, _1043, _9295 } from "../../icons/tab1";

const Tab1 = ({ onClick }) => {
    let datas = [
        // [
        { icon: number1, text: "1" },
        { icon: number2, text: "2" },
        { icon: number3, text: "3" },
        { icon: _2735, text: "+" },
        { icon: _2475, text: "\\pm" },
        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
        { icon: _999, text: "\\sqrt[]{}", keys: ["Left", "Left"] },
        { icon: _944, text: "\\left[\\right]", keys: ["Left"] },
        // ],
        // [
        { icon: number4, text: "4" },
        { icon: number5, text: "5" },
        { icon: number6, text: "6" },
        { icon: _2737, text: "-" },
        { icon: dot, text: "\\cdot" },
        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
        { icon: _941, text: "\\frac{}{}", keys: ["Left", "Left", "Left"] },
        { icon: _944_2, text: "\\left(\\right)", keys: ["Left"] },
        // ],
        // [
        { icon: number7, text: "7" },
        { icon: number8, text: "8" },
        { icon: number9, text: "9" },
        { icon: _2734, text: "*" },
        { icon: _2736, text: "\\times" },
        { icon: _942, text: "^{}", keys: ["Left"] },
        { icon: _4065, text: "\\le" },
        { icon: _gt, text: ">" },
        // ],
        // [
        { icon: number0, text: "0" },
        { icon: decimal, text: "." },
        { icon: equal, text: "=" },
        { icon: _2788, text: "\\div" },
        { icon: _3341, text: "\\frac{}{}", keys: ["Left", "Left"] },
        { icon: _943, text: "_{}", keys: ["Left", "Left"] },
        { icon: _6199, text: "\\ge" },
        { icon: lt, text: "<" },
        // ],
        // [
        { icon: _3528, text: "\\%" },
        { icon: Path3437, text: "\\pi" },
        { icon: _2715, text: "i" },
        { icon: _2480, text: "e^{}", keys: ["Left"] },
        { icon: Path3436, text: "\\theta" },
        { icon: Path3367, text: "\\infty" },
        { icon: _1043, text: "\\sqrt[3]{}" },
        { icon: _9295, text: "\\left(f\\circ g\\right)\\left(x\\right)" },
        // ],
    ];
    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab1;
