import api from "..";

export const fetchQuestionType = (fetchParams) => {
    const filters = [];
    fetchParams.exam_types && filters.push(`exam_types:${fetchParams.exam_types}`);
    // Fetch params:
    const params = {
        _filter: filters.join(";") || undefined,
        _noPagination: 1,
        _question_type_fields: `${[
            "name",
            "type",
            "status_point",
            "status_question",
            "parent_id",
            "icon_src",
            "gif_src",
            "description",
            "is_new",
            "color",
            "exam_types",
        ]}`,
        _orderBy: "_id:asc",
        _relations: `${["childs"]}`,
    };
    // Fetch:
    return api
        .get(`/question_type`, params)
        .then(({ status, data, message }) => ({
            status,
            data,
            message,
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
