import React, { useEffect, useRef, useState } from "react";
import styles from "./QuestionSection.module.scss";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useValues } from "src/hooks";
import { Button, Divider, Drawer, Space, Tooltip, notification } from "antd";
import { useTranslation } from "react-i18next";
import Audio from "src/modules/components/Audio";

/**
 *
 * @param {  String } mode - 'detail' | 'preview'
 * @param {  Object } data
 * @param {  String } audioIconType - 'icon' | 'bar'
 *
 * @returns
 */
function QuestionSection({
    mode = "detail",
    data,
    onFinishChange,
    onSelectedFileRef,
    openFileManager,
    audioIconType = "icon",
}) {
    const inputRef = useRef();
    const { t } = useTranslation();

    const audioRef = useRef(null);

    const [values, setValues] = useValues({
        isEditing: false,
        sectionData: data,
    });

    function handleInputBlur() {
        setValues({ isEditing: false });
        handleFinishChange();
    }

    useEffect(() => {
        setValues({ sectionData: { ...data, file_id: "65bcdf30640b4e5ebf014616" } });
    }, [data]);

    useEffect(() => {
        if (mode === "detail") {
            if (values.isEditing && values.sectionData?.name) {
                inputRef.current?.focus?.();
                inputRef.current.value = values.sectionData?.name;
            } else if (!values.sectionData?.name) {
                inputRef.current?.focus?.();
                setValues({ isEditing: true });
            }
        }
    }, [values.isEditing]);

    useEffect(() => {
        if (onSelectedFileRef) {
            onSelectedFileRef.current = (data = {}) => {
                const newData = { ...values.sectionData, ...data };
                setValues({ sectionData: newData });
                onFinishChange?.(newData);
            };
        }
    });

    function handleFinishChange() {
        const data = values.sectionData;
        onFinishChange?.(data);

        if (!data.name) {
            notification.warning({ message: "Vui lòng nhập nội dung cho section" });
        }
    }

    function handleRemoveAudio() {
        const data = values.sectionData;
        (data.file_id = null), delete data.src, setValues({ sectionData: data });
        onFinishChange?.(data);
    }

    function handleInputChange(e) {
        setValues({ sectionData: { ...values.sectionData, name: e.target.value } });
    }

    if (mode === "detail") {
        return (
            <div className={`${styles.container} edit`}>
                <div className={styles.wrapper}>
                    <div className={styles.inputTitle}>
                        {values.isEditing ? (
                            <input ref={inputRef} onBlur={handleInputBlur} onChange={handleInputChange} />
                        ) : (
                            <div>
                                <p className={styles.textTitle}>{data?.name}</p>
                            </div>
                        )}
                    </div>
                    <span className={styles.actions}>
                        <span className={styles.actionIcon} onClick={openFileManager}>
                            <PaperClipOutlined />
                        </span>
                        {values.isEditing ? (
                            <span className={styles.actionIcon} onClick={handleFinishChange}>
                                <CheckOutlined />
                            </span>
                        ) : (
                            <span className={styles.actionIcon} onClick={() => setValues({ isEditing: true })}>
                                <EditOutlined />
                            </span>
                        )}

                        <span className={styles.actionIcon}>
                            <DeleteOutlined />
                        </span>
                    </span>
                </div>
                {data?.file_id && data?.src && (
                    <div className="flex flex-center">
                        <Audio src={data.src} />
                        <span style={{ marginLeft: 20, cursor: "pointer" }}>
                            <Tooltip title={t("shared.delete")}>
                                <CloseOutlined color="#ddd" onClick={handleRemoveAudio} />
                            </Tooltip>
                        </span>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div>
                <p className={styles.textTitle}>{data?.name}</p>
                {data?.file_id && data?.src && (
                    <div className="flex flex-center">
                        <Audio src={data.src} type="111" />
                    </div>
                )}
            </div>
        );
    }
}

export default QuestionSection;
