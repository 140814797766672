import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { toggleHelpModalVisible } from "src/reducers/general";
import IconChevronLeft from "src/assets/images/icon-chevron-left.svg";
import { t } from "i18next";
import "./Header.scss";

const Header = ({
    className = "",
    backEnable = true,
    backTitle = t("header.go_back"),
    backFunc = () => {},
    actions = [],
    showHelp = true,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClickHelp = () => {
        dispatch(toggleHelpModalVisible(true));
    };

    const generateActionList = () => {
        if (actions && actions?.length > 0) {
            return actions.map((item, i) => {
                if (item.link) {
                    return (
                        <div key={`action${i}`} className="action-item">
                            <Link className="action-link" to={item.link}>
                                {item.icon ? (
                                    <span className="action-icon">
                                        <img src={item.icon} alt=""></img>
                                    </span>
                                ) : null}
                                <span className="action-title">{item.title || ""}</span>
                            </Link>
                        </div>
                    );
                } else {
                    return (
                        <div key={`action${i}`} className="action-item">
                            <div
                                className={`action-button${item.type ? " " + item.type : ""}${
                                    item.isDisabled ? " disabled" : ""
                                }`}
                                onClick={() => {
                                    item.onClick && !item.isDisabled ? item.onClick() : null;
                                }}
                            >
                                {item.icon && typeof item.icon === "string" ? (
                                    <span className="action-icon">
                                        <img src={item.icon} alt=""></img>
                                    </span>
                                ) : (
                                    <>{React.isValidElement(item.icon) ? <>{item.icon}</> : <>no</>}</>
                                )}
                                <span className="action-title">{item.title || ""}</span>
                            </div>
                        </div>
                    );
                }
            });
        } else {
            return null;
        }
    };

    return (
        <div className={`page-header-wrapper${className ? " " + className : ""}`}>
            <div className="page-header">
                <div className="header-left-section">
                    {backEnable ? (
                        <div className="btn-wrapper" onClick={backFunc}>
                            <div className="btn-go-back">
                                <span className="go-back-icon">
                                    <img src={IconChevronLeft} alt=""></img>
                                </span>
                                <span className="go-back-title">{backTitle ? backTitle : t("header.go_back")}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="btn-wrapper">
                            <div className="btn-app-title">{backTitle}</div>
                        </div>
                    )}
                </div>
                <div className="header-mid-section">
                    <div className="action-list-wrapper">
                        <div className="action-list">{generateActionList()}</div>
                        {showHelp ? (
                            <div className="btn-icon-only">
                                <span className="act-button-wrapper">
                                    <span className="act-button" onClick={handleClickHelp}>
                                        <Tooltip title={t("header.help")} placement="bottomRight">
                                            <QuestionCircleFilled />
                                        </Tooltip>
                                    </span>
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
