import api from "..";

export const fetchRecordByAssignmentId = (fetchData) => {
    const params = {
        _record_fields: `${[""]}`,
    };

    return api
        .post(`/record/start_time`, fetchData, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const findRecord = (id) => {
    const params = {
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "test_input_id",
        ]}`,
        _relations: `${["assignment"]}`,
        _assignment_fields: `${["exam", "name", "type"]}`,
    };

    return api
        .get(`/record/${id}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
/**
 *
 * @param {string} assignmentId Assignment
 * @param {string} userId The student who take the assignment.
 * @returns Record data
 */
export const findRecordBy = (assignmentId, userId) => {
    const filters = [];
    assignmentId && filters.push(`assignment_id:${assignmentId}`);
    userId && filters.push(`user_id:${userId}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "max_score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "is_student_submit",
            "is_teacher_submit",
            "test_input_id",
        ]}`,
        _relations: `${["assignment", "user", "reports"]}`,
        _assignment_fields: `${["exam", "type"]}`,
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, message }) => {
            return { status, data, message };
        })
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchRecordsOfStudent = (fetchParams) => {
    const filters = [];

    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    [0, 1, "0", "1"].includes(fetchParams.is_test) && filters.push(`is_test:${fetchParams.is_test}`);
    // fetchParams.status && filters.push(`status:${fetchParams.status}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "test_input_id",
        ]}`,
        _relations: `${["assignment"]}`,
        _assignment_fields: `${["name", "class", "category", "is_test", "type"]}`,
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const submitRecord = (fetchData) => {
    const { id, data } = fetchData;

    return api
        .put(`/record/${id}/submit`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const fetchStudentListByAssignmentID = (fetchParams) => {
    const filters = [];
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _record_fields: `${[
            "score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "count_submit_exercise",
            "test_input_id",
        ]}`,
        _relations: `${["user", "assignment"]}`,
        _user_fields: fetchParams.userFields ? fetchParams.userFields : `${["name"]}`,
        _assignment_fields: `${["exam"]}`,
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, message, meta }) => ({
            status,
            data,
            message,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const markStudentAssignment = (id, formData) => {
    return api
        .put(`/record/${id}/submit`, formData)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message: message }));
};

// export const findCategory = (id) => {
//     const params = {
//         _category_fields: `${["name"]}`,
//     };
//     return api
//         .get(`/category/${id}`, params)
//         .then(({ status, data }) => ({ status, data }))
//         .catch(() => ({ status: false }));
// };

// export const updateCategory = (id, data) => {
//     return api
//         .put(`/category/${id}`, data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// export const createCategory = (data) => {
//     return api
//         .post("/category", data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// export const deleteCategory = (id) => {
//     return api
//         .delete(`/category/${id}`)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };
