import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./FillBlanksDragDrop.scss";

function FillBlanksDragDrop(props) {
    const { isReadonly = false, question, answer, defaultAnswer, onChange = () => {}, showAnswerList = true } = props;

    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState(defaultAnswer || []);

    const handleChangeAnswer = (answered) => {
        onChange({
            answered: answered,
        });
    };

    return (
        <div className="q-fill-blanks-drag-drop">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    blankVisibleType="drag_drop"
                    isReadonly={isReadonly}
                    value={{
                        question,
                        answerList: answer,
                    }}
                    selectedAnswer={selectedAnswer}
                    handleChangeDragDrop={handleChangeAnswer}
                />
            </div>
        </div>
    );
}

export default FillBlanksDragDrop;
