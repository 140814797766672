import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { Tabs } from "antd";
import Header from "src/modules/components/Header";
import Overview from "./containers/Overview";
import Question from "./containers/Question";
import Student from "./containers/Student";
import BackTopButton from "src/modules/components/BackTop";
import { findAssignment } from "src/api/containers/assignment";
import { getStudentDataListForAssignment } from "src/api/containers/statistic";
import { useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import "./AssignmentReport.scss";

const AssignmentReport = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const tabs = {
        overview: "1",
        student: "2",
        question: "3",
    };
    const [currTab, setCurrTab] = useState({
        tabKey: "",
        tabProps: {},
    });
    const [values, setValues] = useValues({
        loading: false,
        assignmentData: {},
        optionListStudent: [],
    });

    const handleGoBack = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleSwitchTab = (tabKey, tabProps) => {
        setCurrTab({
            tabKey: tabKey,
            tabProps: tabProps,
        });
    };

    useEffect(() => {
        dispatch(setCurrentPage("assignment_teacher"));
        if (params.classId && params.assignmentId) {
            setValues({ ...values, loading: true });
            Promise.all([
                findAssignment(params.assignmentId),
                getStudentDataListForAssignment(params.assignmentId),
            ]).then((res) => {
                if (res[0].status === true) {
                    setValues({
                        ...values,
                        loading: false,
                        assignmentData: res[0].data || {},
                        optionListStudent: res[1].data || [],
                    });
                }
            });
        }
    }, []);

    return (
        <div className="assignment-report-wrapper">
            <Header backTitle={values.assignmentData.name || ""} backFunc={(e) => handleGoBack(e)} />

            <div className="report-tabs-wrapper">
                <Tabs
                    type="card"
                    defaultActiveKey="1"
                    centered
                    activeKey={currTab.tabKey ? currTab.tabKey : undefined}
                    onChange={(tabKey) => setCurrTab({ tabKey: tabKey, tabProps: {} })}
                >
                    <Tabs.TabPane tab={<span>{t("statistical.overview")}</span>} key={tabs.overview}>
                        <Overview
                            assignmentData={values.assignmentData}
                            optionListStudent={values.optionListStudent}
                            handleSwitchTab={handleSwitchTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span>{t("statistical.student")}</span>} key={tabs.student}>
                        <Student
                            assignmentData={values.assignmentData}
                            optionListStudent={values.optionListStudent}
                            studentId={currTab.tabKey === "2" ? currTab.tabProps?.studentId : undefined}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span>{t("statistical.question")}</span>} key={tabs.question}>
                        <Question assignmentData={values.assignmentData} />
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <BackTopButton />
        </div>
    );
};

export default AssignmentReport;
