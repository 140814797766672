import React from "react";
import PDFDrawer from "./PDFDrawer";
import Thumbnails from "../Thumbnails";
import { useResizeObserver, useValues } from "src/hooks";
import "./PDFPreviewer.scss";
import PageAdjustTools from "./PageAdjustTools";
import ZwibblerToolbars from "../Toolbars";
import { v4 as uuidv4 } from "uuid";
import { PDF_CONST } from "../../constant";

function PDFPreviewer({
    previewRef,
    currentThumbnail,
    listThumbnails,
    onSelectThumbnails,
    onChangeListThumbnails,
    canvasContext,
    mode, // 'detail' | 'preview'
}) {
    const [values, setValues] = useValues({
        isShowThumbnails: true,
        scale: 1,
        currentThumbnail: currentThumbnail,
    });
    const { targetRef, dimensions } = useResizeObserver();

    function toggleThumbnails() {
        setValues({ isShowThumbnails: !values.isShowThumbnails });
    }

    function handleAddBlankPage() {
        const page = {
            file_id: uuidv4(),
            path: PDF_CONST.DEFAULT_PATHS,
            src: "",
            page: listThumbnails.length + 1,
        };

        const listData = [...(listThumbnails || [])];

        onChangeListThumbnails?.([...listData, page]);
    }

    function handleRemovePage() {
        const file_id = currentThumbnail?.file_id;
        if (!file_id) return;
        const newList = listThumbnails
            .filter((item) => item.file_id !== file_id)
            .map((p, idx) => {
                return { ...p, page: idx + 1 };
            });

        onChangeListThumbnails?.(newList);
    }

    function handleScale(type) {
        const s = 0.05;
        let scale = values.scale;
        if (type === "incre" && scale < 2) {
            scale = scale + s;
        } else if (scale > 0.5) {
            scale = scale - s;
        }
        setValues({ scale: scale });
    }

    function handleSelectThumbnail(data) {
        setValues({ currentThumbnail: data });
        if (onSelectThumbnails instanceof Function) {
            onSelectThumbnails(data);
        }
    }
    function handleChangeListThumbnail(data) {
        if (onChangeListThumbnails instanceof Function) {
            onChangeListThumbnails(data);
        }
    }

    return (
        <div className="pdf-previewer" ref={targetRef}>
            {mode !== "preview" ? (
                <div className="canvas-toolss">
                    <ZwibblerToolbars ctx={canvasContext} />
                </div>
            ) : null}

            <div
                className="content"
                style={{ width: values.isShowThumbnails ? dimensions.width - 160 : dimensions.width - 20 }}
            >
                <Thumbnails
                    currentThumbnail={values.currentThumbnail}
                    setCurrentThumbnail={handleSelectThumbnail}
                    isShowThumbnails={values.isShowThumbnails}
                    toggleThumbnails={toggleThumbnails}
                    listThumbnails={listThumbnails}
                    setListThumbnails={handleChangeListThumbnail}
                />

                <div style={{ position: "relative" }}>
                    <div className="pdf-previewer_adjust-tools">
                        <PageAdjustTools
                            toggleThumbnails={toggleThumbnails}
                            handleAddBlankPage={handleAddBlankPage}
                            handleRemovePage={handleRemovePage}
                            handleScale={handleScale}
                            isShowThumbnails={values.isShowThumbnails}
                        />
                    </div>
                    <PDFDrawer
                        width={values.isShowThumbnails ? dimensions.width - 160 : dimensions.width - 20}
                        height={previewRef?.current?.offsetHeight || 800}
                        src={values.currentThumbnail?.src}
                        scale={values.scale}
                        mode={mode}
                    />
                </div>
            </div>
        </div>
    );
}

export default PDFPreviewer;
