import { useTranslation } from "react-i18next";
import "./LoadingScreen.scss";

const LoadingScreen = () => {
    const { t } = useTranslation();

    return (
        <div className="loading-screen">
            <div className="loading-screen-body">{t("shared.loading")}...</div>
        </div>
    );
};

export default LoadingScreen;
