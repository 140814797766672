import { Select, Spin } from "antd";
import { t } from "i18next";
import "./ListBranch.scss";
import BranchItems from "../../components/BranchItems";
import { useFetch, useValues } from "src/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchFilters, fetchBranches } from "src/api/containers/branch";
import Header from "src/modules/components/Header";

const Branch = () => {
    const user = useSelector((state) => state.auth.user);

    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, filter: "active:1", organization_id: user.paramsForOrgOrTeam?.organization_id },
        fetchBranches
    );
    const [values, setValues] = useValues({
        listPages: 0,
        listOfClasses: [],
    });

    useEffect(() => {
        fetch({}, true);
    }, []);

    const handleFilterClass = (value) => {
        if (value === "all") {
            fetch({ page: 1, filter: undefined });
        } else {
            fetch({ page: 1, filter: `active:${value}` });
        }
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (dataSource.length) {
            if (pagination.current === 1) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: dataSource,
                });
            } else if (pagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: [...values.listOfClasses, ...dataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfClasses: [],
            });
        }
    }, [dataSource, pagination]);

    return (
        <div className="class">
            {/* <Header /> */}
            <Header backEnable={false} backTitle={t("sidebar.manage_branch")} actions={[]} />
            <Spin spinning={loading}>
                <div className="class-filter">
                    {/* <span>{t("branch.list_branch")}</span> */}
                    <span></span>
                    <span>
                        <Select
                            className="app-select"
                            defaultValue={"1"}
                            onChange={(value) => handleFilterClass(value)}
                        >
                            {branchFilters.map((filterItem, filterIndex) => {
                                return (
                                    <Select.Option key={`branch-filter-${filterIndex}`} value={filterItem.value}>
                                        {t(filterItem.name)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </span>
                </div>
                <div className="class-list">
                    <BranchItems
                        classList={values.listOfClasses}
                        pagination={pagination}
                        handleFetchClassList={fetch}
                        // handleRefetchClassList={refetch}
                        handleRefetchClassList={() => {
                            fetch({ page: 1 }, true);
                        }}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default Branch;
