import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useRouting } from "src/utils/router";
import { useMediaQuery, useValues } from "src/hooks";
import { fetchSubscriptionPlans, planTypes } from "src/api/containers/plan";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import configs from "src/configs";
import CardSubscriptionPlan from "../CardSubscriptionPlan";
import Swiper from "src/modules/components/Swiper";
import ModalContactUs from "src/modules/components/Modal/ModalContactUs";
import "./ModalSubscriptionPlans.scss";
import { ReactComponent as SvgVipCrownDecor } from "src/assets/images/account/vip-crown-decoration.svg";

const ModalSubscriptionPlans = ({ className = "", visible = false, onOk = () => {}, onCancel = () => {}, ...rest }) => {
    const { t } = useTranslation();
    const { user, accountType } = useSelector((state) => state.auth);
    const lang = useSelector((state) => state.general.lang);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        planList: [],
    });
    const [values, setValues] = useValues({
        modalOpen: false,
        modalData: undefined,
    });
    const currMedia = useMediaQuery(["(min-width: 576px)"]);
    const { generate } = useRouting();
    const navigate = useNavigate();

    const handleCancel = () => {
        if (onCancel && onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleOk = () => {
        if (onOk && onOk instanceof Function) {
            onOk();
        }
    };

    const handlePreventCancel = (e) => {
        e.stopPropagation();
    };

    const getPlanInUsed = () => {
        if (user.subscriptions?.length) {
            if (user.subscriptions.includes(planTypes.organization)) {
                return planTypes.organization;
            }
            if (user.subscriptions.includes(planTypes.premium)) {
                return planTypes.premium;
            }
        }
        return planTypes.free;
    };

    const handleGoToPayment = (selectedPlan, selectedPrice) => {
        if (checkDemoAccountAction(accountType, "upgrade_account", "upgrade") === false) {
            notification.error({ message: t("shared.you_dont_have_permission") });
            return;
        }
        if (selectedPlan && selectedPrice) {
            const data = {
                planId: selectedPlan.id,
                planType: selectedPlan.type,
                planPriceId: selectedPrice.id,
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            handleCancel();
            navigate(generate("payment"));
        }
    };

    const handleToggleContactUsModal = (modalData) => {
        if (values.modalOpen) {
            setValues({
                modalOpen: false,
                modalData: undefined,
            });
        } else {
            setValues({
                modalOpen: "contactus",
                modalData: modalData,
            });
        }
    };

    const getActionInfoByPlanInUsed = (planInUsed, planMentioned) => {
        // Helpers:
        const getTitleByUpgradationMethod = (planType) => {
            const upgradationMethod = configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planType];
            if (upgradationMethod?.contact) {
                return t("account.contact_support");
            } else {
                return t("account.upgrade_now");
            }
        };

        // Default settings:
        let currTitle = "";
        let currClassName = "";
        let currLinkTo = { linkToIsLocal: false, linkTo: "https://testonline.vn/", target: "_blank" };
        let currHandleClick = (planItem, planItemPrice) => {
            const upgradationMethod = configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planItem?.type];
            if (upgradationMethod?.contact) {
                handleToggleContactUsModal({
                    plan_id: planItem?.id,
                    plan_price_id: planItemPrice?.id,
                });
            } else {
                handleGoToPayment(planItem, planItemPrice);
            }
        };

        // Suitable settings:
        if (planInUsed === planMentioned) {
            // There are some cases:
            // - If the plan is premium, it can be achieved many times.
            // - Otherwise, it can be achieved one time.
            if (planMentioned === planTypes.premium) {
                currTitle = getTitleByUpgradationMethod(planTypes.premium);
                currLinkTo = undefined;
            } else {
                currTitle = t("account.current_account");
                currClassName = "activated";
                currHandleClick = undefined;
            }
        } else {
            if (planInUsed === planTypes.free) {
                switch (planMentioned) {
                    case planTypes.premium:
                        currTitle = getTitleByUpgradationMethod(planTypes.premium);
                        currLinkTo = undefined;
                        break;
                    case planTypes.organization:
                        currTitle = getTitleByUpgradationMethod(planTypes.organization);
                        currLinkTo = undefined;
                        break;
                    default:
                        break;
                }
            } else if (planInUsed === planTypes.premium) {
                switch (planMentioned) {
                    case planTypes.free:
                        currTitle = t("plan.disabled");
                        currClassName = "disabled";
                        currHandleClick = undefined;
                        break;
                    case planTypes.organization:
                        currTitle = getTitleByUpgradationMethod(planTypes.organization);
                        currLinkTo = undefined;
                        break;
                    default:
                        break;
                }
            } else if (planInUsed === planTypes.organization) {
                switch (planMentioned) {
                    case planTypes.free:
                        currTitle = t("plan.disabled");
                        currClassName = "disabled";
                        currHandleClick = undefined;
                        break;
                    case planTypes.premium:
                        currTitle = getTitleByUpgradationMethod(planTypes.premium);
                        currLinkTo = undefined;
                        break;
                    default:
                        break;
                }
            }
        }

        return [currTitle, currClassName, currLinkTo, currHandleClick];
    };

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        if (visible && !data.planList.length) {
            setIsLoading(true);
            fetchSubscriptionPlans().then((res) => {
                if (res.status && res.data?.length) {
                    setData({ ...data, planList: res.data });
                }
                setIsLoading(false);
            });
        }
    }, [visible]);

    const renderPlanList = () => {
        return data.planList.map((planItem, planIndex) => {
            // Settings:
            const [currTitle, currClassName, currLinkTo, currHandleClick] = getActionInfoByPlanInUsed(
                getPlanInUsed(),
                planItem.type
            );
            // Content:
            let contentList = [];
            if (planItem.content?.length) {
                contentList = planItem.content.map((item) => {
                    return item[lang];
                });
            }
            // Price:
            let priceCurr = planItem.prices[0]?.price;
            let priceUsual = 0;
            const priceDiscount = planItem.prices[0]?.discount;
            if (planItem.prices[0]?.price === 0) {
                priceCurr = t("price.free");
            }
            if (planItem.prices[0]?.discount) {
                priceUsual = priceCurr;
                priceCurr = priceCurr - (priceCurr * planItem.prices[0]?.discount) / 100;
            }
            return (
                <CardSubscriptionPlan
                    key={`plan-${planIndex}`}
                    type={planItem.type}
                    title={planItem.name}
                    bodyContent={contentList}
                    className={[planTypes.premium].includes(planItem.type) ? "emphasized" : undefined}
                    actions={[
                        {
                            title: currTitle,
                            className: currClassName || undefined,
                            linkTo: currLinkTo?.linkTo,
                            linkToIsLocal: currLinkTo?.linkToIsLocal,
                            target: currLinkTo?.target,
                            onClick: currHandleClick ? () => currHandleClick(planItem, planItem.prices[0]) : undefined,
                        },
                    ]}
                    priceCurrent={priceCurr}
                    priceUsual={priceUsual}
                    priceUnit={t("price.vnd_per_month")}
                    priceDiscount={!isNaN(priceDiscount) && priceDiscount > 0 ? priceDiscount : undefined}
                />
            );
        });
    };

    return (
        <div className={`subscription-plans-modal${className ? " " + className : ""}`}>
            <div className={`modal-subsc-plans${visible ? " visible" : ""}`}>
                <div className="modal-wrapper">
                    <div className="modal-overlay"></div>
                    <div className="modal-content-wrapper" onClick={handleCancel}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="header-icon" onClick={handlePreventCancel}>
                                    <SvgVipCrownDecor />
                                </div>
                                <div className="header-title" onClick={handlePreventCancel}>
                                    {t("account.pick_your_suitable_subscription_plan")}
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className={`plan-list`}>
                                    {isLoading ? (
                                        <>
                                            <CardSubscriptionPlan isLoadingSkeleton={true} />
                                            <CardSubscriptionPlan isLoadingSkeleton={true} className="active" />
                                            <CardSubscriptionPlan isLoadingSkeleton={true} />
                                        </>
                                    ) : currMedia.breakpointValue === 576 ? (
                                        <>
                                            {data.planList.length ? (
                                                <Swiper
                                                    initialSlide={1}
                                                    coverflowEffect={{
                                                        rotate: 0,
                                                        modifier: 1,
                                                    }}
                                                    styleConfig={{
                                                        disableShadow: true,
                                                    }}
                                                    spaceBetween={30}
                                                    onClickSlide={handlePreventCancel}
                                                >
                                                    {renderPlanList()}
                                                </Swiper>
                                            ) : (
                                                <div className="empty-wrapper">
                                                    <CardSubscriptionPlan />
                                                    <div className="empty-msg">{t("shared.no_data")}</div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        renderPlanList()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContactUs
                visible={values.modalOpen === "contactus"}
                formData={{
                    plan_id: values.modalData?.plan_id,
                }}
                onCancel={handleToggleContactUsModal}
                onOk={handleToggleContactUsModal}
            />
        </div>
    );
};

export default ModalSubscriptionPlans;
