import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, notification, Select, Space, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, ShareAltOutlined } from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { deleteAssignment, fetchAssignmentsBank } from "src/api/containers/assignment";
import { fetchCategories } from "src/api/containers/category";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import Header from "src/modules/components/Header";
import FilterMenu from "src/modules/components/FilterMenu";
import { default as CustomButton } from "src/modules/components/Button";
import InputSearch from "src/modules/components/Input/InputSearch";
import Button from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import ModalDetailExam from "src/modules/components/ModalDetailExam";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import { getFilterValuesByURLSearchParams, getURLSearchParamsByFilterValues } from "src/utils/helpers/urlQueryString";
import { compareObjects } from "src/utils/helpers/comparison";
import { ReactComponent as SvgFilter } from "src/assets/images/filter-icon.svg";
import { ReactComponent as SvgPencil } from "src/assets/images/pencil.svg";
import ModalPersonalLearningProgress from "src/modules/containers/Class/components/ModalPersonalLearningProgress";
import ModalMarkAssignment from "src/modules/containers/Class/components/ModalMarkAssignment";
import Icon from "src/modules/components/Icon";
import "./Assignment.scss";

const Assignment = ({ isWithUrlQueryString = true, extraFixedFetchParams, excludedFilterFields }) => {
    const dispatch = useDispatch();

    // User and scope:
    const { user, accountType } = useSelector((state) => state.auth);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};
    // Date format:
    const formatDateTime = useSelector((state) => state.general.formatDateTime);
    // Table row animation:
    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;

    // FILTER SETTINGS:
    const fixedFetchParams = useRef({
        teacher_id: user.id,
    });
    const [filterInfo, setFilterInfo] = useValues({
        isFirstTimeFetchDone: false,
        // Search box:
        inputSearch: "",
        // Filter menu:
        isVisible: false,
        categories: [],
        isFetchingCates: false,
    });
    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const _filterValuesInitial = {
            page: 1,
            slug: undefined,
            // teacher_id: user.id,
            is_public: fParamsUserScope.organization_id,
        };
        const _filterValuesConfig = {
            page: {
                queryKey: "p",
            },
            slug: {
                queryKey: "s",
            },
            // teacher_id: {
            //     label: t("question.owner"),
            //     queryKey: "own",
            //     values: [user.id],
            //     valuesInfo: {
            //         [user.id]: { label: t("question.owner_me"), queryValue: "me" },
            //     },
            // },
            is_test: {
                label: t("shared.type"),
                queryKey: "type",
                values: ["0", "1"],
                valuesInfo: {
                    0: { label: t("assignment.assignment"), queryValue: "0" },
                    1: { label: t("assignment.exam"), queryValue: "1" },
                },
            },
            category_id: {
                label: t("shared.category"),
                queryKey: "cate",
                getValueLabel: (value) => {
                    for (let i = 0; i < filterInfo.categories.length; i++) {
                        if (value === filterInfo.categories[i].id) {
                            return filterInfo.categories[i].name;
                        }
                    }
                    return "Unknown";
                },
            },
            is_public: {
                label: t("shared.scope"),
                queryKey: "public",
                values: [
                    // "true",
                    // "false",
                    ...(fParamsUserScope.organization_id ? [fParamsUserScope.organization_id] : []),
                ],
                valuesInfo: {
                    // true: { label: t("shared.public"), queryValue: "1" },
                    // false: { label: t("shared.only_me"), queryValue: "0" },
                    ...(fParamsUserScope.organization_id
                        ? {
                              [fParamsUserScope.organization_id]: {
                                  label: user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown",
                                  queryValue: fParamsUserScope.organization_id,
                              },
                          }
                        : {}),
                },
            },
        };
        // Remove excluded filter values and config:
        const filterValuesInitial = {};
        const filterValuesConfig = {};
        Object.keys(_filterValuesInitial).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesInitial[elem] = _filterValuesInitial[elem];
            }
        });
        Object.keys(_filterValuesConfig).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesConfig[elem] = _filterValuesConfig[elem];
            }
        });
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [filterInfo]);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    // FILTER MENU:
    const formFields = useMemo(() => {
        const _formFields = {
            // ...(filterValuesConfig.teacher_id
            //     ? {
            //         teacher_id: {
            //             label: filterValuesConfig.teacher_id.label,
            //             element: (
            //                 <Select className="app-select" placeholder={t("question.select_owner")} allowClear>
            //                     {filterValuesConfig.teacher_id.values.map((item, itemIndex) => {
            //                         return (
            //                             <Select.Option key={`own${itemIndex}`} value={item}>
            //                                 {filterValuesConfig.teacher_id.valuesInfo[item].label}
            //                             </Select.Option>
            //                         );
            //                     })}
            //                 </Select>
            //             ),
            //             colProps: { span: 24 },
            //         },
            //     }
            //     : {}),
            ...(filterValuesConfig.is_test
                ? {
                      is_test: {
                          label: filterValuesConfig.is_test.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("assignment.select_type_assignment")}
                                  allowClear
                              >
                                  {filterValuesConfig.is_test.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`istest${itemIndex}`} value={item}>
                                              {filterValuesConfig.is_test.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.category_id
                ? {
                      category_id: {
                          label: filterValuesConfig.category_id.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("shared.search_and_select_category")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                              >
                                  {filterInfo.categories.map((item, index) => (
                                      <Select.Option key={`cate${index}`} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.is_public
                ? {
                      is_public: {
                          label: filterValuesConfig.is_public.label,
                          element: (
                              <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                                  {filterValuesConfig.is_public.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`scope${itemIndex}`} value={item}>
                                              {filterValuesConfig.is_public.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
        };
        return _formFields;
    }, [filterInfo]);
    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: [
                    // "teacher_id",
                    "is_test",
                    "category_id",
                    "is_public",
                ],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    // API CALL:
    const [loading, dataSource, pagination, fetch, refetch] = useFetch({}, fetchAssignmentsBank);

    // PAGE DATA:
    const currMedia = useMediaQuery(["(max-width: 768px)"]);
    const [editingAssignment, setEditingAssignment] = useState({});
    const [values, setValues] = useValues({
        // Modal:
        isConfirmModalVisible: false,
        isInvModalVisible: false,
        isAddAsgmtModalVisible: false,
        // Modal data:
        selectedItem: null,
        // Others:
        isOnMobile: false,
        isShowForm: false,
        examType: "exam",

        isMarkingModal: false,
        isModalPersonalLearningProgress: false,
        viewProgressAssignment: {},
        viewProgressClass: {},
        viewProgressSubject: {},
        studentInfo: {},
    });

    const columns = [
        {
            title: t("class.exam_name2"),
            dataIndex: "name",
            key: "name",
            width: "auto",
        },
        {
            title: t("class.name"),
            dataIndex: "class_name",
            key: "class_name",
            width: "auto",
            render: (text, record) => record?.class?.name,
        },
        {
            title: t("class.exam_name"),
            dataIndex: "exam_name",
            key: "exam_name",
            width: "auto",
            render: (text, record) => record?.exam?.name,
        },
        {
            title: t("shared.type"),
            dataIndex: "is_test",
            key: "is_test",
            width: "auto",
            render: (is_test) =>
                is_test ? (
                    <Tag color="#faad14">{t("assignment.exam")}</Tag>
                ) : (
                    <Tag color="#13c2c2">{t("assignment.assignment")}</Tag>
                ),
        },
        {
            title: t("class.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.begin_date).format(formatDateTime)
                    : t("class.time_not_limited");
            },
        },
        {
            title: t("class.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date ? moment(record.end_date).format(formatDateTime) : t("class.time_not_limited");
            },
        },
        {
            title: t("class.max_score"),
            dataIndex: "max_score",
            key: "max_score",
            width: "auto",
            render: (text, record) => record?.exam?.max_score || 0,
        },
        {
            title: t("class.duration"),
            dataIndex: "homework_time_minute",
            key: "homework_time_minute",
            width: "auto",
            render: (text, record) => record?.homework_time_minute || t("class.time_not_limited"),
        },
        {
            title: t("assignment.count_submit_exercise"),
            dataIndex: "count_submit_assignment",
            key: "count_submit_assignment",
            width: "auto",
            render: (text, record) => record?.count_submit_assignment,
        },

        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="table-action question-bank-action" style={{ justifyContent: "right" }}>
                    {checkDemoAccountAction(accountType, "assignment", "view") === false ? (
                        <span className="table-action__icon disabled">
                            <CustomButton type="ghost" title={t("question_bank.view")} className="table-action__btn" />
                        </span>
                    ) : (
                        <Tooltip placement="top" title={t("question_bank.view")}>
                            <span
                                type="ghost"
                                onClick={() => {
                                    setValues({
                                        isMarkingModal: true,
                                        markingAssignmentId: record.id,
                                        viewProgressAssignment: {
                                            id: record.id,
                                            name: record.name,
                                        },
                                        viewProgressClass: {
                                            id: record.class?.id,
                                            name: record.class?.name,
                                        },
                                        ...(record?.category
                                            ? {
                                                  viewProgressSubject: {
                                                      id: record?.category?.id,
                                                      name: record?.category?.name,
                                                  },
                                              }
                                            : {}),
                                    });
                                }}
                            >
                                <EyeOutlined />
                            </span>
                        </Tooltip>
                    )}

                    <Tooltip placement="top" title={t("assignment.invite_via_link")}>
                        {checkDemoAccountAction(accountType, "assignment", "invite") === false ? (
                            <span className="table-action__icon disabled">
                                <ShareAltOutlined className="action-icon" />
                            </span>
                        ) : (
                            <span
                                className="table-action__icon"
                                onClick={() => {
                                    setValues({
                                        isInvModalVisible: true,
                                        selectedItem: record,
                                    });
                                }}
                            >
                                <ShareAltOutlined className="action-icon" />
                            </span>
                        )}
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.update")}>
                        {checkDemoAccountAction(accountType, "assignment", "update") === false ? (
                            <span className="table-action__icon disabled">
                                <EditOutlined />
                            </span>
                        ) : (
                            <span className="table-action__icon" onClick={() => handleUpdateAssignment(record)}>
                                <EditOutlined />
                            </span>
                        )}
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.delete")}>
                        {checkDemoAccountAction(accountType, "assignment", "delete") === false ? (
                            <span className="table-action__icon disabled">
                                <DeleteOutlined />
                            </span>
                        ) : (
                            <span
                                className="table-action__icon question-bank-delete"
                                onClick={() => handleShowConfirmModal(record)}
                            >
                                <DeleteOutlined />
                            </span>
                        )}
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleUpdateQueryString = (urlQueries = {}) => {
        const sParams = {};
        urlQueries?.p && (sParams.p = urlQueries.p);
        urlQueries?.s && (sParams.s = urlQueries.s);
        // urlQueries?.own && (sParams.own = urlQueries.own);
        urlQueries?.type && (sParams.type = urlQueries.type);
        urlQueries?.cate && (sParams.cate = urlQueries.cate);
        urlQueries?.public && (sParams.public = urlQueries.public);
        setUrlSearchParams(sParams);
    };

    const handleFetchDataList = (fetchParams = {}, isReload = true) => {
        const fParams = {
            page: fetchParams.page,
            slug: fetchParams.slug,
            // teacher_id: fetchParams.teacher_id,
            is_test: fetchParams.is_test,
            category_id: fetchParams.category_id,
            // is_public: ["true", "false"].includes(fetchParams.is_public) ? fetchParams.is_public : undefined,
            organization_id: ["true", "false"].includes(fetchParams.is_public) ? undefined : fetchParams.is_public,
            ...(Object.keys(fixedFetchParams.current || {})?.length ? fixedFetchParams.current : {}),
            ...(Object.keys(extraFixedFetchParams || {})?.length ? extraFixedFetchParams : {}),
        };
        fetch({ ...fParams }, isReload);
        // Update search box's value:
        if (fParams.slug) {
            setFilterInfo({ inputSearch: fParams.slug });
        }
    };

    const handleSearch = () => {
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            slug: filterInfo.inputSearch,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handlePagination = (page) => {
        const newFilterValues = {
            ...currFilterValues,
            page: page,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            [fieldName]: undefined,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleUpdateAssignment = (item) => {
        setEditingAssignment(item);
        setValues({
            isAddAsgmtModalVisible: true,
            isShowForm: true,
            examType: item?.is_test === false ? "assignment" : "exam",
        });
    };

    const handleDeleteAssignment = () => {
        const item = values.selectedItem;
        deleteAssignment(item.id, fParamsUserScope.organization_id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isConfirmModalVisible: false });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleShowConfirmModal = (item) => {
        setValues({ isConfirmModalVisible: true, selectedItem: item });
    };

    useEffect(() => {
        dispatch(setCurrentPage("assignment"));

        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setFilterInfo({ categories: res.data });
        });
    }, []);

    useEffect(() => {
        if (isWithUrlQueryString) {
            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
            // Deal with one of these cases:
            // 1. Handle update query string whenever:
            // - The URL query string is empty and it's the first time fetch.
            // 2. Handle fetching whenever:
            // - The URL query string is not empty.
            // - The URL query string is empty but it's not the first time fetch.
            const isUrlQSEmpty = Object.keys(currUrlSearchParams).length === 0;
            const isFirstTimeFetch = filterInfo.isFirstTimeFetchDone === false;
            if (isUrlQSEmpty && isFirstTimeFetch) {
                const newUrlSearchParams = getURLSearchParamsByFilterValues(currFilterValues, filterValuesConfig);
                handleUpdateQueryString(newUrlSearchParams);
            } else {
                if (!isUrlQSEmpty || (isUrlQSEmpty && !isFirstTimeFetch)) {
                    const newFilterValues = getFilterValuesByURLSearchParams(currUrlSearchParams, filterValuesConfig);
                    if (compareObjects(currFilterValues, newFilterValues) === false) {
                        setCurrFilterValues(newFilterValues);
                        setFilterInfo({ ...filterInfo, isFirstTimeFetchDone: true });
                        // Update data list whenever the URL query string is changed:
                        handleFetchDataList(newFilterValues, true);
                    }
                }
            }
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [urlSearchParams, currFilterValues]);

    useEffect(() => {
        // Animation happens whenever asgmt is added/updated/cloned:
        if (animate?.name == "assignment") {
            const rowAnimation = (id, action, timeout = 4000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };
            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    useEffect(() => {
        if (currMedia.breakpointValue !== -1) {
            setValues({ isOnMobile: true });
        } else if (values.isOnMobile) {
            setValues({ isOnMobile: false });
        }
    }, [currMedia.breakpointValue]);

    return (
        <>
            <div className="question-bank exam-bank assignment-bank">
                <Header backEnable={false} backTitle={t("header.assignment")} actions={[]} />

                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                value={filterInfo.inputSearch}
                                onChange={(e) => setFilterInfo({ ...filterInfo, inputSearch: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {(!isWithUrlQueryString || filterInfo.isFirstTimeFetchDone) &&
                                        Object.keys(currFilterValues).map((fKey, i) => {
                                            const fValue = currFilterValues[fKey];
                                            const fValueLabel =
                                                filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                                (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                    ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                    : undefined);
                                            if (fValue && fValueLabel) {
                                                return (
                                                    <Tag
                                                        className="app-tag"
                                                        key={`filter-key${i}`}
                                                        closable
                                                        onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                    >
                                                        {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                    </Tag>
                                                );
                                            }
                                            return null;
                                        })}
                                </Space>
                            </div>
                            <Dropdown
                                forceRender
                                visible={filterInfo.isVisible}
                                overlay={
                                    <FilterMenu
                                        formLayout={"horizontal"}
                                        formFields={formFields}
                                        formFieldGroups={formFieldGroups}
                                        formData={currFilterValues}
                                        willResetForm={filterInfo.isVisible === false}
                                        onCancel={() => {
                                            setFilterInfo({
                                                ...filterInfo,
                                                isVisible: false,
                                            });
                                        }}
                                        onSubmit={(newFilterValues) => {
                                            newFilterValues.page = 1;
                                            if (isWithUrlQueryString) {
                                                const currUrlSearchParams = Object.fromEntries(
                                                    urlSearchParams.entries()
                                                );
                                                const newUrlSearchParams = getURLSearchParamsByFilterValues(
                                                    newFilterValues,
                                                    filterValuesConfig
                                                );
                                                if (compareObjects(currUrlSearchParams, newUrlSearchParams) === false) {
                                                    handleUpdateQueryString(newUrlSearchParams);
                                                }
                                            } else {
                                                setCurrFilterValues(newFilterValues);
                                            }
                                        }}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) => {
                                    setFilterInfo({ ...filterInfo, isVisible: val });
                                }}
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={<SvgFilter />}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions">
                        <Button
                            type="primary"
                            icon={<SvgPencil />}
                            title={t("shared.add_assignment")}
                            onClick={() => {
                                setEditingAssignment({});
                                setValues({ isAddAsgmtModalVisible: true });
                            }}
                            disabled={checkDemoAccountAction(accountType, "assignment", "create") === false}
                        ></Button>
                    </div>
                </div>

                <div className="table assignment-bank-table">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        nodata_title={t("shared.no_data")}
                        loading={loading}
                        pagination={{
                            showSizeChanger: false,
                            ...pagination,
                            position: ["bottomCenter"],
                            onChange: handlePagination,
                        }}
                        scroll={{ x: "auto" }}
                        rowKey="id"
                    />
                </div>
            </div>

            <ModalDetailExam
                isLoading={loading}
                visible={values.isAddAsgmtModalVisible}
                isOnMobile={values.isOnMobile}
                isShowFormSelect={values.isAddAsgmtModalVisible && values.isShowForm && values.isOnMobile}
                editingAssignment={editingAssignment}
                examType={values.examType}
                setValuesExam={setValues}
                onOk={() => {
                    refetch();
                    setValues({ isAddAsgmtModalVisible: false });
                    setEditingAssignment({});
                    setValues({
                        isShowForm: false,
                    });
                }}
                onCancel={() => {
                    setValues({ isAddAsgmtModalVisible: false });
                    setEditingAssignment({});
                    setValues({
                        isShowForm: false,
                        // examType: "",
                    });
                }}
                classScopeId={fParamsUserScope.organization_id}
                destroyOnClose
            ></ModalDetailExam>

            <PopConfirm
                type="delete"
                visible={values.isConfirmModalVisible}
                onOk={handleDeleteAssignment}
                onCancel={() => setValues({ isConfirmModalVisible: false })}
            />

            <ModalInviteLink
                targetId={`${location.origin}/assignment/` + values.selectedItem?.id}
                visible={values.isInvModalVisible}
                onOk={() => {
                    setValues({ isInvModalVisible: false });
                }}
                onCancel={() => {
                    setValues({ isInvModalVisible: false });
                }}
            />

            <ModalMarkAssignment
                className="class-detail-modal detail-marking"
                centered
                title={t("class.mark_assignment")}
                footer={null}
                visible={values.isMarkingModal}
                onCancel={() => setValues({ isMarkingModal: false })}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
                markingAssignmentId={values.markingAssignmentId}
                setIsPersonalLearningProgress={(status) => setValues({ isModalPersonalLearningProgress: status })}
                setStudentInfo={(inf) => setValues({ studentInfo: inf })}
            />

            <ModalPersonalLearningProgress
                title={t("report.personal_learning_progress")}
                visible={values.isModalPersonalLearningProgress}
                viewProgressClass={values.viewProgressClass}
                viewProgressSubject={values.viewProgressSubject}
                onCancel={() => setValues({ isModalPersonalLearningProgress: false })}
                viewProgressStudent={values.studentInfo}
            />
        </>
    );
};

Assignment.propTypes = {
    isWithUrlQueryString: PropTypes.bool,
    extraFixedFetchParams: PropTypes.object,
    excludedFilterFields: PropTypes.array,
};

export default Assignment;
