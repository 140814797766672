import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "src/modules/components/Button";
import {
    CloudUploadOutlined,
    FileOutlined,
    FilePdfOutlined,
    PlusOutlined,
    RocketOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import NewTestCard from "src/modules/components/NewTestCard";
import "./ExamPDFCreate.scss";
import { Modal, Spin, Upload, message, notification } from "antd";
import { useValues } from "src/hooks";
import ImageSelector from "./components/ImageSelector";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { PDF_CONST } from "../DetailExamPDF/constant";
import { createExam } from "src/api/containers/exam";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import Header from "src/modules/components/Header";

function ExamPDFCreate({}) {
    const { t } = useTranslation();
    const [values, setValues] = useValues({
        file: {},
        loadingFile: false,
        wasConverted: false,
        imageArray: [],
        numberOfPage: 0,
        isModalSelectImages: false,
        selectedImageIds: [],
        examLoading: false,
    });
    const [imageUrls, setImageUrls] = useState([]);
    const [numOfPages, setNumOfPages] = useState(0);
    const pdfjsLib = useRef({});
    const navigate = useNavigate();
    const { generate } = useRouting();

    const onFileChange = (info) => {
        // if (info.file.status === "done") {
        //     message.success(`${info.file.name} file uploaded successfully`);
        //     setValues({ file: info.file.originFileObj });
        // } else if (info.file.status === "error") {
        //     message.error(`${info.file.name} file upload failed.`);
        // }
    };

    const checkFile = (file) => {
        const isPDF = file.type === "application/pdf";
        if (!isPDF) {
            message.error("You can only upload PDF files!");
        } else {
            setValues({ file: file });
        }

        return false;
    };

    const UrlUploader = (url) => {
        fetch(url).then((response) => {
            response.blob().then((blob) => {
                let reader = new FileReader();
                reader.onload = (e) => {
                    const data = atob(e.target.result.replace(/.*base64,/, ""));
                    renderPage(data);
                };
                reader.readAsDataURL(blob);
            });
        });
    };

    const renderPage = async (data) => {
        try {
            setValues({ loadingFile: true });
            const imagesList = [];
            const canvas = document.createElement("canvas");
            canvas.setAttribute("className", "canv");

            const pdf = await pdfjsLib.current.getDocument({ data }).promise;

            for (let i = 1; i <= pdf.numPages; i++) {
                var page = await pdf.getPage(i);
                console.log(page);
                var viewport = page.getViewport({ scale: 1.5 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                var render_context = {
                    canvasContext: canvas.getContext("2d"),
                    viewport: viewport,
                };
                await page.render(render_context).promise;
                let img = canvas.toDataURL("image/png");
                imagesList.push(img);
            }

            setNumOfPages(pdf.numPages);
            setImageUrls((e) => {
                const imageIds = imagesList.map((item, idx) => idx);

                setValues({ loadingFile: false, selectedImageIds: imageIds });

                return imagesList.map((src, idx) => {
                    return { id: idx, src: src, name: `${values.file.name}_p${idx}` };
                });
            });
        } catch (error) {
            //eslint-disable-next-line
            console.error(error);
        }
    };

    function loadScript() {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "pdfjs";
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.min.js";
        script.onload = function () {
            pdfjsLib.current = window["pdfjs-dist/build/pdf"];
            pdfjsLib.current.GlobalWorkerOptions.workerSrc =
                "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
            // message.success("load script success");
        };
        script.onerror = function () {
            message.error("An error occur");
        };

        document.body.appendChild(script);
    }
    const handleConvertFileToImgs = () => {
        try {
            UrlUploader(URL.createObjectURL(values.file));
            setValues({ isModalSelectImages: true });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!document.getElementById("pdfjs")) {
            loadScript();
        }
    }, []);

    const generateDefaultPaths = (file_ids) => {
        return file_ids.map((item) => {
            return {
                file_id: item,
                path: PDF_CONST.DEFAULT_PATHS,
            };
        });
    };

    function createBlankExamDraft() {
        const uniqueID = uuidv4();
        const examdata = {
            name: "",
            category_id: "",
            max_score: 0,
            is_publish: false,
            is_public: false,
            file_ids: [],
            type: "pdf",
            paths: generateDefaultPaths([uniqueID]),
        };

        setValues({ examLoading: true });
        createExam(examdata).then((res) => {
            if (res.status) {
                setValues({ examLoading: false });
                navigate(generate("update_exam_pdf", { id: res.id }));
            } else {
                notification.error({ message: t("message.an_error_occurred") });
                setValues({ examLoading: false });
            }
        });
    }

    console.log(imageUrls);
    return (
        <div className="question-bank exam-bank">
            <Header backEnable={true} backTitle={t("header.exam_bank")} actions={[]} />

            <div className="exam-pdf-create flex flex-center">
                <div className="exam-pdf-create_container flex flex-center">
                    {Object.keys(values.file).length > 0 ? (
                        <NewTestCard
                            icon={<FilePdfOutlined />}
                            description={`${values.file.name}  (${Number(values.file.size / 1024 ** 2).toFixed(2)}MB)`}
                            renderActions={() => (
                                <Button
                                    title={t("help.next")}
                                    icon={<RocketOutlined />}
                                    onClick={handleConvertFileToImgs}
                                />
                            )}
                        />
                    ) : (
                        <Upload
                            accept=".pdf"
                            onChange={onFileChange}
                            showUploadList={false}
                            beforeUpload={checkFile} // Prevent automatic upload
                            multiple={false}
                            className="upload-area"
                        >
                            <NewTestCard
                                icon={<CloudUploadOutlined />}
                                title={t("exam_pdf.create_from_pdf")}
                                description={t("exam_pdf.upload_pdf_and_create")}
                                renderActions={() => (
                                    <Button type="ghost" title="Upload" icon={<UploadOutlined />} onClick={() => {}} />
                                )}
                            />
                        </Upload>
                    )}
                    <Spin spinning={values.examLoading}>
                        <NewTestCard
                            icon={<FileOutlined />}
                            title={t("exam_pdf.create_width_blank_page")}
                            description={t("exam_pdf.create_width_blank_page_desc")}
                            renderActions={() => (
                                <Button
                                    type="ghost"
                                    title={t("exam_pdf.create_test")}
                                    icon={<RocketOutlined />}
                                    onClick={createBlankExamDraft}
                                />
                            )}
                        />
                    </Spin>
                </div>
                <Modal
                    title={"Chọn trang"}
                    visible={values.isModalSelectImages}
                    className="app-modal type-basic flexible-height"
                    centered
                    footer={null}
                    closeIcon={<SvgCross />}
                    onCancel={() => setValues({ isModalSelectImages: false })}
                    bodyStyle={{ maxHeight: "76vh", overflowY: "auto" }}
                >
                    {values.loadingFile ? (
                        <Spin tip={t("shared.processing")}>
                            <div style={{ minHeight: 300 }}></div>
                        </Spin>
                    ) : (
                        <ImageSelector
                            images={imageUrls}
                            selectedImageIds={values.selectedImageIds}
                            setSelectedImageIds={(ids) => setValues({ selectedImageIds: ids })}
                        />
                    )}
                </Modal>
            </div>
        </div>
    );
}

ExamPDFCreate.propTypes = {};

export default ExamPDFCreate;
