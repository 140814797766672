import { DoubleRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import clsx from "clsx";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchRecordByAssignmentId } from "src/api/containers/record";
import BookIcon from "src/assets/images/book.svg";
import ExitIcon from "src/assets/images/exit.svg";
import PencilIcon from "src/assets/images/pencil.svg";
import Button from "src/modules/components/Button";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import QuestionPanel from "src/modules/components/QuestionPanel";
import { setIsReadOnly } from "src/reducers/answer";
import { initialClock, setIsLoadingClock } from "src/reducers/clock";
import { setCurrentPage } from "src/reducers/general";
import { addRecord, gotoQuestionOrderTempTime, setIsShowConfirmModal } from "src/reducers/test";
import { convertQuestionState } from "src/utils/helpers";
import { useRouting } from "src/utils/router";
import TestLayout from "../Layout/TestLayout";
import NumberQuestion from "../Layout/TestLayout/Sidebar/NumberQuestion";
import Header from "./Header";
import "./TestLayout.scss";
import TestPDF from "../Exam/DetailExamPDF/components/TestPDF";

const Test = (props) => {
    const dispatch = useDispatch();
    TestPDF;
    const [loadingRecord, setLoadingRecord] = useState(true);
    const [dataRecord, setDataRecord] = useState([]);
    const { listAnswers, isReadonly } = useSelector((state) => state.answer);
    const { currentQuestionOrder, isSubmitingTest } = useSelector((state) => state.test);
    const { id: idAssignment } = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const [isShowExitModal, setIsShowExitModal] = useState(false);
    const { questions, record_id, homework_time_minute, start_time, now } = dataRecord;
    const [isCollapseNumberQuestionMobile, setIsCollapseNumberQuestionMobile] = useState(false);
    const intervalId = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const is_rework = searchParams.get("is_rework");
    const competition_id = searchParams.get("is_competition");

    const currentQuestionInfo = useMemo(() => {
        if (questions?.length > 0) {
            return questions[+String(currentQuestionOrder).split(".")[0] - 1] || {};
        }
    }, [currentQuestionOrder, dataRecord]);

    const handleExit = () => {
        if (competition_id) {
            navigate(`${generate("rounds")}?competition_id=${competition_id}`);
        } else {
            navigate(generate("home"));
        }
    };

    useEffect(() => {
        if (record_id) {
            const currentQuestion = dataRecord.questions[0];
            // Nếu là câu passage thì order mặc định sẽ là 1.1
            const order = currentQuestion.type === "passage" ? 1.1 : 1;

            // Nếu là câu passage thì questionId mặc định sẽ là id của câu đầu tiền trong paragraph đầu tiên
            const questionId =
                currentQuestion.type === "passage"
                    ? currentQuestion?.paragraphs?.[0]?.question_details?.[0]?.id
                    : currentQuestion?.id;
            dispatch(
                gotoQuestionOrderTempTime({
                    order,
                    recordId: record_id,
                    questionId,

                    // Câu passage sẽ phải thêm field
                    ...(currentQuestion.type === "passage" && { passageId: currentQuestion.id }),
                })
            );
        }
    }, [record_id]);

    useEffect(() => {
        setLoadingRecord(true);
        fetchRecordByAssignmentId({ assignment_id: idAssignment, is_rework: is_rework == "true" }).then((res) => {
            if (res.status) {
                setDataRecord(res.data);
                setLoadingRecord(false);
                if (res.data?.competition_id) {
                    setSearchParams({ competition_id: res.data.competition_id }); // remove is_rework
                } else {
                    setSearchParams({}); // remove is_rework
                }
            } else {
                // navigate(generate("home"));
                handleExit();
                notification.error({ message: res.message });
            }
        });
    }, [idAssignment]);

    useEffect(() => {
        dispatch(addRecord({ dataRecord, idAssignment }));
    }, [dataRecord]);

    const handleChangeQuestionOrder = (order) => {
        let newOrder = order;
        let newQuestionId = dataRecord.questions[order - 1].id;
        let passageId = null;

        if (dataRecord.questions[order - 1].type === "passage") {
            newOrder = order + ".1";

            // mặc định khi nhảy vào câu passage thì sẽ tính tempTime từ câu *.1
            newQuestionId = dataRecord.questions[order - 1]?.paragraphs?.[0]?.question_details?.[0]?.id;
            passageId = dataRecord.questions[order - 1].id;
        }

        dispatch(
            gotoQuestionOrderTempTime({
                order: newOrder,
                recordId: record_id,
                questionId: newQuestionId,
                ...(passageId && { passageId }),
            })
        );
    };

    const handleNextQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder + 1;
        const continueQuestionInfo = dataRecord.questions[continueQuestionOrder - 1];
        let questionId = continueQuestionInfo.id;
        let passageId = null;
        if (continueQuestionOrder <= questions.length) {
            let newOrder = continueQuestionOrder;

            if (continueQuestionInfo.type === "passage") {
                newOrder = continueQuestionOrder + ".1";
                questionId = continueQuestionInfo?.paragraphs?.[0]?.question_details?.[0]?.id;
                passageId = continueQuestionInfo.id;
            }

            dispatch(
                gotoQuestionOrderTempTime({
                    order: newOrder,
                    recordId: record_id,
                    questionId,
                    ...(passageId && { passageId }),
                })
            );
        }
    };

    const handlePrevQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder - 1;
        const continueQuestionInfo = dataRecord.questions[continueQuestionOrder - 1];
        let questionId = continueQuestionInfo.id;
        let passageId = null;

        if (continueQuestionOrder > 0) {
            let newOrder = continueQuestionOrder;

            if (continueQuestionInfo.type === "passage") {
                newOrder = continueQuestionOrder + ".1";
                passageId = dataRecord.questions[continueQuestionOrder - 1].id;
                questionId = continueQuestionInfo?.paragraphs?.[0]?.question_details?.[0]?.id;
            }

            dispatch(
                gotoQuestionOrderTempTime({
                    order: newOrder,
                    recordId: record_id,
                    questionId,
                    ...(passageId && { passageId }),
                })
            );
        }
    };

    const initialDecreaseClock = () => {
        const dateNowSecond = Math.round(new Date(now) / 1000);
        const startTimeSecond = new Date(start_time).getTime() / 1000;

        const homeworkTimeSecond = homework_time_minute * 60;
        const endTimeSecond = startTimeSecond + homeworkTimeSecond;

        const leftTimeSecond = Math.round(endTimeSecond - dateNowSecond);

        if (dateNowSecond > endTimeSecond) {
            dispatch(
                initialClock({
                    secondTotal: homeworkTimeSecond,
                    secondLeft: 0,
                    type: "decrease",
                })
            );

            dispatch(setIsReadOnly(true));
        } else {
            if (leftTimeSecond > 0) {
                dispatch(
                    initialClock({
                        secondTotal: homeworkTimeSecond,
                        secondLeft: leftTimeSecond,
                        type: "decrease",
                    })
                );
            } else {
                alert("Lỗi thời gian bắt đầu làm bài không hợp lệ");
            }
        }
    };

    const initialIncreaseClock = () => {
        // Handle get start time
        const dateNowSecond = Math.round(Date.now() / 1000);
        const startTimeSecond = new Date(start_time).getTime() / 1000;

        dispatch(
            initialClock({
                secondTotal: 0,
                secondLeft: dateNowSecond - startTimeSecond || 0,
                type: "increase",
            })
        );
    };

    useEffect(() => {
        dispatch(setCurrentPage("test"));
    }, []);

    // Handle initial clock
    useEffect(() => {
        if (!loadingRecord) {
            if (homework_time_minute) {
                initialDecreaseClock();
            } else {
                initialIncreaseClock();
            }
        }
    }, [homework_time_minute, loadingRecord]);

    useEffect(() => {
        dispatch(setIsLoadingClock(loadingRecord));
    }, [loadingRecord]);

    useEffect(() => {
        // Remove dispatch ticktacDecrease when secondLeft is zero
        if (isReadonly) {
            clearInterval(intervalId.current);
        }
    }, [isReadonly]);

    useEffect(() => {
        return () => {
            dispatch(setIsReadOnly(false));
        };
    }, []);

    if (loadingRecord) {
        return (
            <TestLayout>
                <LoadingOutlined />
            </TestLayout>
        );
    }

    if (dataRecord.exam_type === "pdf") {
        return <TestPDF data={dataRecord} />;
    }

    return (
        <TestLayout>
            <>
                {!loadingRecord && (
                    <div className="test_content">
                        <Header title={dataRecord?.name || ""} icon={BookIcon} />

                        <div className="test_question">
                            {currentQuestionOrder > 0 && (
                                <React.Fragment key={currentQuestionOrder}>
                                    <QuestionPanel
                                        goNextQuestion={handleNextQuestionOrder}
                                        goPrevQuestion={handlePrevQuestionOrder}
                                        questionInfo={currentQuestionInfo}
                                        recordId={record_id}
                                        questionAmount={questions?.length}
                                        questionOrder={currentQuestionOrder}
                                        mode={isReadonly ? "readonly" : "test"}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
                {loadingRecord && <LoadingOutlined />}
                {!loadingRecord && (
                    <div
                        className={clsx(
                            "NumberQuestion_Responsive",
                            isCollapseNumberQuestionMobile && "NumberQuestion_Responsive--collapsed"
                        )}
                    >
                        <div
                            className={clsx(
                                "NumberQuestion_toggleBtn",
                                isCollapseNumberQuestionMobile && "NumberQuestion_toggleBtn--collapsed"
                            )}
                            onClick={() => setIsCollapseNumberQuestionMobile(!isCollapseNumberQuestionMobile)}
                        >
                            <DoubleRightOutlined />
                        </div>
                        <NumberQuestion
                            amount={questions?.length}
                            listOrderAnswered={convertQuestionState(questions, listAnswers[dataRecord.record_id])}
                            onChangeQuestionOrder={handleChangeQuestionOrder}
                            isSlider={true}
                        />
                        {isReadonly && <div className="test_timeoutTest">{t("test.timeout_test")}</div>}
                        <div className="Submit_Test">
                            <Button
                                type="grey"
                                icon={ExitIcon}
                                onClick={() => {
                                    setIsShowExitModal(true);
                                }}
                                title={t("test.exit")}
                            ></Button>
                            <Button
                                type="pink"
                                icon={PencilIcon}
                                onClick={() => {
                                    dispatch(setIsShowConfirmModal(true));
                                }}
                                title={t("test.submit")}
                            ></Button>
                        </div>
                    </div>
                )}
            </>
            {/* on mobile up => src\modules\containers\Layout\TestLayout\Sidebar\index.js */}
            {/* on mobile */}
            <PopConfirm
                type="delete"
                visible={isShowExitModal}
                onOk={handleExit}
                onCancel={() => setIsShowExitModal(false)}
                okText={t("test.exit")}
                cancelText={t("test.cancel")}
            >
                <p className="modal-title" style={{ margin: 0 }}>
                    {t("test.confirm_exit")}
                </p>
            </PopConfirm>
        </TestLayout>
    );
};

export default Test;
