import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Divider, Form, Input, notification, Row, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fetchCategories } from "src/api/containers/category";
import { createTag, fetchTags } from "src/api/containers/tag";
import { useValues } from "src/hooks";
import ColorPicker from "src/modules/components/ColorPicker";
import { convertHexAToRGBA, convertRGBAToHexA } from "src/utils/helpers";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./SettingTopBar.scss";

function SettingTopBar(props) {
    const {
        optionListCategory = [],
        optionListTag = [],
        handleAddTagPending = () => {},
        handleAddTagFulfilled = () => {},
        onChangeCategoryList = () => {},
        onChangeTagList = () => {},
        onDeleteTag = () => {},
    } = props;

    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const [values, setValues] = useValues({
        isFetchingCates: false,
        isFetchingTags: false,
    });
    const [inputTag, setInputTag] = useState({
        tagName: "",
        tagColorHex: "",
        tagColorRGBA: {},
    });
    const [isTagSelectOpen, setIsTagSelectOpen] = useState(false);
    const tagSelectRef = useRef(null);
    const timeoutIdGetCates = useRef(null);
    const timeoutIdGetTags = useRef(null);

    const handleAddNewTag = (e) => {
        e.preventDefault();
        if (user.id && inputTag.tagName) {
            handleAddTagPending();
            createTag({ name: inputTag.tagName, color: inputTag.tagColorHex || "", owner_id: user.id }).then((res) => {
                if (res.status && res.id) {
                    const newTagList = [{ id: res.id, name: inputTag.tagName }, ...optionListTag];
                    setInputTag({
                        tagName: "",
                        tagColorHex: "",
                        tagColorRGBA: {},
                    });
                    handleAddTagFulfilled(newTagList);
                    notification.success({
                        message: `${t("tag.add_tag_success")}: "${inputTag.tagName}"`,
                    });
                } else {
                    notification.error({
                        message: res.message || t("message.sth_wrong"),
                    });
                }
            });
        }
    };

    const handleSearchCategories = (keyword) => {
        clearTimeout(timeoutIdGetCates.current);
        if (typeof keyword === "string") {
            setValues({ ...values, isFetchingCates: true });
            onChangeCategoryList([]);
            timeoutIdGetCates.current = setTimeout(() => {
                fetchCategories({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, isFetchingCates: false });
                        onChangeCategoryList(res.data);
                    }
                });
            }, 500);
        }
    };

    const handleSearchTags = (keyword) => {
        clearTimeout(timeoutIdGetTags.current);
        if (typeof keyword === "string") {
            setValues({ ...values, isFetchingTags: true });
            onChangeTagList([]);
            const fetchParams = {
                ...(keyword ? { noPagination: true } : { page: 1 }),
            };
            timeoutIdGetTags.current = setTimeout(() => {
                fetchTags({ slug: keyword, ...fetchParams }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, isFetchingTags: false });
                        onChangeTagList(res.data);
                    }
                });
            }, 500);
        }
    };

    const handleChangeTagColorUsingInput = useCallback(
        (newColor) => {
            if (newColor.length === 4 || newColor.length === 5 || newColor.length == 7 || newColor.length === 9) {
                // Preparation:
                let param = newColor;
                if (param.length === 4) {
                    param = `#${param[1]}${param[1]}${param[2]}${param[2]}${param[3]}${param[3]}ff`;
                }
                if (param.length === 7) {
                    param = `${param}ff`;
                }
                // Handling:
                const newColorInRGBA = convertHexAToRGBA(param);
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColor,
                    tagColorRGBA:
                        {
                            hex: newColor,
                            rgb: { ...newColorInRGBA.rgb, a: newColorInRGBA.rgb?.a?.toFixed(2) || 0 },
                        } || {},
                });
            } else {
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColor,
                    tagColorRGBA: {
                        hex: "#00000000",
                        rgb: { r: 0, g: 0, b: 0, a: 0 },
                    },
                });
            }
        },
        [inputTag]
    );

    const handleChangeTagColorUsingPicker = useCallback(
        (newColor, from) => {
            if (from === "rgba") {
                const newColorInHex = convertRGBAToHexA(newColor.rgb.r, newColor.rgb.g, newColor.rgb.b, newColor.rgb.a);
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColorInHex || "",
                });
            } else if (from === "hex") {
                if (newColor.hex.length === 5 || newColor.hex.length === 9) {
                    const newColorInRGBA = convertHexAToRGBA(newColor.hex);
                    setInputTag({
                        ...inputTag,
                        tagColorHex: newColorInRGBA.rgb || {},
                    });
                }
            }
        },
        [inputTag]
    );

    return (
        <Row className="question-formitems top-bar-setting">
            <Col xs={24} md={12}>
                <Form.Item
                    name="category_id"
                    label={t("question.category")}
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Select
                        className="app-select show-arrow has-rd"
                        placeholder={t("question.select_category")}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        showArrow={false}
                        // onSearch={(keyword) => {
                        //     handleSearchCategories(keyword);
                        // }}
                        // notFoundContent={
                        //     values.isFetchingCates === true ? (
                        //         <i>
                        //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        //         </i>
                        //     ) : (
                        //         undefined
                        //     )
                        // }
                    >
                        {optionListCategory.map((opt, i) => {
                            if (opt) {
                                return (
                                    <Select.Option key={`cat${i}`} value={opt.id}>
                                        {opt.name}
                                    </Select.Option>
                                );
                            }
                        })}
                    </Select>
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item name="tag_ids" label={t("question.tags")}>
                    <Select
                        ref={tagSelectRef}
                        className="app-select show-arrow has-rd set-tags"
                        mode="multiple"
                        placeholder={t("question.select_tags")}
                        optionFilterProp="children"
                        showArrow={false}
                        showSearch
                        allowClear
                        onSearch={(keyword) => {
                            handleSearchTags(keyword);
                        }}
                        notFoundContent={
                            values.isFetchingTags === true ? (
                                <i>
                                    <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                </i>
                            ) : undefined
                        }
                        open={isTagSelectOpen}
                        onDropdownVisibleChange={(isOpen) => setIsTagSelectOpen(isOpen)}
                        // onFocus={() => setIsTagSelectOpen(true)}
                        // onBlur={() => setIsTagSelectOpen(false)}
                        dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
                        dropdownClassName="set-tags-dropdown"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div
                                    className="tag-collection-dropdown from-top-bar-setting"
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    // onMouseDown={(e) => {
                                    //     // e.preventDefault();
                                    //     e.stopPropagation();
                                    //     e.target.focus();
                                    //     setIsTagSelectOpen(true);
                                    // }}
                                >
                                    <Divider style={{ margin: "8px 0" }} />
                                    <div className="dropdown-bottom-title">{t("tag.create_tag")}</div>
                                    <div className="app-select-dropdown-input">
                                        <Input
                                            className="app-input has-rd"
                                            placeholder={t("question.input_new_tag")}
                                            value={inputTag.tagName}
                                            onChange={(e) =>
                                                setInputTag({
                                                    ...inputTag,
                                                    tagName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="app-select-dropdown-input tag-color">
                                        <Input
                                            className="app-input has-rd"
                                            placeholder={
                                                t("question.input_new_tag_color") + ": #fd0, #fd0f, #ffdd00, #ffdd00ff"
                                            }
                                            value={inputTag.tagColorHex}
                                            onChange={(e) => handleChangeTagColorUsingInput(e.target.value)}
                                        />
                                        <ColorPicker
                                            currColor={
                                                Object.keys(inputTag.tagColorRGBA).length
                                                    ? inputTag.tagColorRGBA
                                                    : "unset"
                                            }
                                            onChangeColor={(val) => handleChangeTagColorUsingPicker(val, "rgba")}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        />
                                    </div>
                                    <div className="dropdown-bottom-action-list">
                                        <CustomButton
                                            className="btn-add-tag"
                                            type="ghost"
                                            icon={<PlusOutlined />}
                                            title={t("tag.add_tag")}
                                            onClick={handleAddNewTag}
                                            isDisabled={!inputTag.tagName}
                                        ></CustomButton>
                                    </div>
                                </div>
                            </>
                        )}
                    >
                        {optionListTag.map((opt, i) => {
                            if (opt) {
                                return (
                                    <Select.Option key={`tag${i}`} value={opt.id || opt._id || ""}>
                                        <div className="tag-container">
                                            <div className="tag-name">{opt.name}</div>
                                            <CustomButton
                                                className="tag-btn-del"
                                                type="simple"
                                                icon={<Icon name="icon-delete" />}
                                                onClick={(e) => onDeleteTag(e, opt.id || opt._id)}
                                            ></CustomButton>
                                        </div>
                                    </Select.Option>
                                );
                            }
                        })}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
}

export default SettingTopBar;
