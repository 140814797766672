import parse from "html-react-parser";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ResultAnswer from "../components/ResultAnswer";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import StaticMathField from "src/modules/components/StaticMathField";
import { AudioIconPlay, AudioIcon } from "src/utils/drawer";
import parser from "src/utils/parser";
import { compareStringsInDefaultFormat } from "src/utils/helpers/comparison";

function QuestionContentDetail(props) {
    const {
        value, // { question: "", answer: [], answer_submit }.
        selectedAnswer,
        // Distinguish between uppercase and lowercase letters
        is_require_uppercase,
    } = props;

    const { t } = useTranslation();
    const audioTimeoutId = useRef(null);
    const [audioSettings, setAudioSettings] = useState({
        state: "stop", // "play", "pause", "stop".
        targetId: "",
    });

    const handleSetAudio = (audioElementId, audioState) => {
        const audioElem = audioElementId ? document.getElementById(audioElementId) : undefined;
        if (audioElem) {
            clearTimeout(audioTimeoutId.current);
            if (audioState === "play") {
                audioElem.play();
                audioTimeoutId.current = setTimeout(() => {
                    setAudioSettings({ state: "stop", targetId: "" });
                }, Math.ceil(audioElem.duration) * 1000);
            } else if (audioState === "pause") {
                audioElem.pause();
            } else {
                audioElem.pause();
                audioElem.currentTime = 0;
            }
        }
    };

    const handleTogglePlayPauseAudio = (audioType, audioIndex) => {
        const targetId = `audio-${audioType}-elem-${audioIndex}`;
        if (audioSettings.targetId === "" || audioSettings.state !== "play") {
            // PLAY:
            handleSetAudio(targetId, "play");
            setAudioSettings({ state: "play", targetId: targetId });
        } else if (audioSettings.targetId !== targetId && audioSettings.state === "play") {
            // STOP CURRENT AUDIO:
            handleSetAudio(audioSettings.targetId, "stop");
            // PLAY SELECTED AUDIO:
            handleSetAudio(targetId, "play");
            setAudioSettings({ state: "play", targetId: targetId });
        } else {
            // STOP:
            handleSetAudio(targetId, "stop");
            setAudioSettings({ state: "stop", targetId: "" });
        }
    };

    // Generate component:
    const generateResultAnswer = (selectorIndex, isCorrect) => {
        return (
            <>
                <ResultAnswer letter={selectorIndex + 1} resultStatus={isCorrect ? "correct" : "incorrect"}>
                    <HTMLDisplayer htmlString={value.answer_submit?.[selectorIndex]} />
                </ResultAnswer>
            </>
        );
    };

    // Generate component:
    const generateAudioButton = (audioIndex) => {
        return (
            <span
                className={`audio-icon-button${
                    audioSettings.targetId === `audio-icon-elem-${audioIndex}` && audioSettings.state === "play"
                        ? " audio-play"
                        : ""
                }`}
                onClick={() => {
                    handleTogglePlayPauseAudio("icon", audioIndex);
                }}
            >
                <span className="audio-icon-wrapper">
                    {audioSettings.targetId === `audio-icon-elem-${audioIndex}` && audioSettings.state === "play" ? (
                        <AudioIconPlay />
                    ) : (
                        <AudioIcon />
                    )}
                </span>
            </span>
        );
    };

    // Generate component:
    const qContent = useMemo(() => {
        if (value.question && typeof value.question === "string") {
            let indexOfSelector = -1; // Used for <fillbox>.
            let indexOfAudioIcon = -1; // Used for <audio> (type: icon).
            let indexOfAudioPlayer = -1; // Used for <audio> (type: player).

            // Replace <p> tags by <div style="margin-bottom: 1em;"> tags:
            let htmlString = value.question.replace(/<p>/g, `<div class="div-as-p">`);
            htmlString = htmlString.replace(/<p /g, `<div class="div-as-p" `);
            htmlString = htmlString.replace(/<\/p>/g, "</div>");

            // Replace all <fillbox> tag with easy-to-be-identified tag:
            htmlString = htmlString.replace(/<fillbox(.*?)<\/fillbox>/g, '<img class="input-blank"></img>');

            // Remove all invalid:
            // - html-react-parser doesn't accept '!important', so we should remove it or the CSS attribute won't work.
            htmlString = htmlString.replace(/!important/g, "");

            // Convert to valid React elements:
            const elements = parse(htmlString, {
                replace: (domNode) => {
                    // Replace all <img> by Select component from Antd:
                    if (domNode.attribs && domNode.attribs.class === "input-blank") {
                        indexOfSelector++;

                        let isCorrect = null;
                        let ansExpected = value.answer?.[indexOfSelector]?.trim();
                        let ansActual = value.answer_submit?.[indexOfSelector]?.trim();
                        ansExpected = parser.compactMathField(ansExpected);
                        ansActual = parser.compactMathField(ansActual);
                        if (!is_require_uppercase) {
                            ansExpected = ansExpected.toLowerCase();
                            ansActual = ansActual.toLowerCase();
                        }
                        isCorrect = compareStringsInDefaultFormat(ansExpected, ansActual);

                        return generateResultAnswer(indexOfSelector, isCorrect);
                    } else if (domNode.name === "math-static") {
                        return <StaticMathField>{domNode.attribs["data-latex"]}</StaticMathField>;
                    }
                    // Set event handler for <audio>:
                    else if (domNode.attribs && domNode.attribs.class === "media-audio-element--type-player") {
                        indexOfAudioPlayer++;
                        domNode.attribs.id = `audio-player-elem-${indexOfAudioPlayer}`;
                        domNode.attribs.onPlay = () => {
                            if (audioSettings.targetId === "" || audioSettings.state !== "play") {
                                setAudioSettings({ state: "play", targetId: domNode.attribs.id });
                            } else if (
                                audioSettings.targetId !== domNode.attribs.id &&
                                audioSettings.state === "play"
                            ) {
                                // STOP CURRENT AUDIO:
                                handleSetAudio(audioSettings.targetId, "stop");
                                // PLAY SELECTED AUDIO:
                                setAudioSettings({ state: "play", targetId: domNode.attribs.id });
                            }
                        };
                    }
                    // Replace all <audio>'s icon/image button by button component with event handler:
                    else if (domNode.attribs && domNode.attribs.class === "media-audio-element--type-icon") {
                        indexOfAudioIcon++;
                        domNode.attribs.id = `audio-icon-elem-${indexOfAudioIcon}`;
                    } else if (domNode.attribs && domNode.attribs.class === "media-audio-icon") {
                        return <>{generateAudioButton(indexOfAudioIcon)}</>;
                    }
                },
            });

            return elements;
        }
        return null;
    }, [value, selectedAnswer, audioSettings]); // You can add "selectedAnswer" to dependency array to activate isDisabledSelectedAnswers.

    return <React.Fragment>{qContent}</React.Fragment>;
}

export default React.memo(QuestionContentDetail);
