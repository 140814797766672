import React, { useContext, useEffect, useRef } from "react";
import PDFPreviewer from "../PDFPreviewer";
import QuestionList from "../QuestionList";
import { Button, Form, Input, Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import "./Worksheet.scss";

function Worksheet({ form, currentTab }) {
    const { currentThumbnail, listThumbnails, drawingTool, setCurrentThumbnail, setListThumbnails } = useContext(
        ExamPDFContext
    );

    const [values, setValues] = useValues({
        isModalQuestionType: false,
        total_score: 0,
    });

    const previewRef = useRef();
    const { t } = useTranslation();

    return (
        <Layout className="pdf-worksheet">
            {/* {currentTab.tabKey === "detail" ? (
                <div className="pdf-worksheet-header">
                    <ZwibblerToolbars ctx={drawingTool} />
                </div>
            ) : null} */}

            <Layout className="pdf-worksheet-content">
                {/* <Sider
                    className="pdf-worksheet-thumbnails"
                    theme="light"
                    width={160}
                    style={{ display: isShowThumbnails ? "block" : "none", backgroundColor: "unset" }}
                >
                    <Thumbnails />
                </Sider> */}
                <Content className="pdf-worksheet-preview" ref={previewRef}>
                    <PDFPreviewer
                        previewRef={previewRef}
                        currentThumbnail={currentThumbnail}
                        listThumbnails={listThumbnails}
                        canvasContext={drawingTool}
                        onSelectThumbnails={setCurrentThumbnail}
                        onChangeListThumbnails={setListThumbnails}
                        mode={currentTab?.tabKey}
                    />
                </Content>
                <Sider
                    className="pdf-worksheet-questionlist"
                    theme="light"
                    width={400}
                    style={{ backgroundColor: "unset", position: "relative" }}
                >
                    {currentTab.tabKey !== "preview" && (
                        <div className="question-list-acttion">
                            <Button
                                icon={<PlusCircleOutlined />}
                                onClick={() => setValues({ isModalQuestionType: true })}
                            >
                                {t("exam_bank.add_question")}
                            </Button>
                            <span>
                                <strong style={{ fontSize: 16 }}> Tổng điểm:</strong>
                                {` ${form.getFieldValue("max_score")}`}
                                <Form.Item name="max_score" style={{ display: "none" }}>
                                    <Input disabled className="app-input" style={{ display: "none" }} />
                                </Form.Item>
                            </span>
                        </div>
                    )}
                    <QuestionList
                        form={form}
                        isModalQuestionType={values.isModalQuestionType}
                        showModalQuestionType={(data) => setValues({ isModalQuestionType: data })}
                    />
                </Sider>
            </Layout>
        </Layout>
    );
}

export default Worksheet;
