import { notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkActiveUser } from "src/api/containers/auth";
import LoadingScreen from "src/modules/components/LoadingScreen";
import { useRouting } from "src/utils/router";
import LoginV2 from "../..";

const ActiveUserOrganization = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const [username, setUsername] = useState("");
    const [isLoaddingFirst, setIsLoadingFirst] = useState(true);

    const { t } = useTranslation();

    const lang = useSelector((state) => state.general.lang);

    const checkIsUserActive = (username) => {
        checkActiveUser(username).then(({ status, data, message }) => {
            if (status) {
                if (data?.is_active) {
                    notification.error({ message: t("login.account_activated") });
                    navigate(`/${lang}/login`);
                } else if (data?.is_active === false) {
                    setIsLoadingFirst(false);
                    setUsername(username);
                }
            } else {
                notification.error({ message: message || t("login.invalid_path") });
                navigate(`/${lang}/login`);
            }
        });
        return false;
    };

    useEffect(() => {
        //check user in organization is active
        if (location?.pathname && location.pathname?.includes("/organization/active-user")) {
            if (location.search) {
                let usrname = null;
                usrname = new URLSearchParams(location.search).get("username");
                if (usrname) {
                    setUsername(usrname);
                    checkIsUserActive(usrname);
                } else {
                    navigate(`/${lang}/login`);
                    notification.error({ message: t("login.invalid_path") });
                }
            } else {
                navigate(`/${lang}/login`);
                notification.error({ message: t("login.invalid_path") });
            }
        }
    }, []);

    return isLoaddingFirst ? <LoadingScreen /> : <LoginV2 username={username} firstResetPW={true} />;
};

export default ActiveUserOrganization;
