import moment from "moment";
import api from "..";

const rankingFields = [
    "score",
    "start_time",
    "end_time",
    "count_correct_sentences",
    "count_sentences",
    "count_submit_exercise",
    "rank",
    "time",
    "result",
];

const competitionFields = ["name", "category_id", "class_id", "begin_date", "end_date", "user_id", "is_official"];

export const checkRanking = (exam_id) => {
    return api
        .get(`/exam_question/${exam_id}/check_ranking`)
        .then(({ status, is_ranking, message }) => ({ status, is_ranking, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const listRanking = (id, fetchParams) => {
    if (!id) return;
    const filters = [];
    filters.push(`assignment_id:${id}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.student_name && filters.push(`student_name:${fetchParams.student_name}`);
    fetchParams.result && filters.push(`result:${fetchParams.result}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _record_fields: `${rankingFields}`,
        _assignment_fields: `${["exam"]}`,
        _user_fields: `${["name"]}`,
        _relations: `${["user", "assignment"]}`,
        _orderBy: fetchParams?.orderBy
            ? Array.isArray(fetchParams.orderBy)
                ? (fetchParams?.orderBy && fetchParams.orderBy?.join(";")) || undefined
                : `${fetchParams?.orderBy}`
            : "",
        _perPage: fetchParams?.perPage,
        _page: fetchParams?.page,
    };
    return api
        .get(`/record/list_ranking`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const listCompetitions = (fetchParams) => {
    const filters = [];
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.begin_date && filters.push(`begin_date:${moment(fetchParams.begin_date).format("YYYY-MM-DD")}`);
    fetchParams.end_date && filters.push(`end_date:${moment(fetchParams.end_date).format("YYYY-MM-DD")}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _competition_fields: `${competitionFields}`,
        _category_fields: `${["name"]}`,
        _class_fields: `${["name", "is_official"]}`,
        _user_fields: `${["name"]}`,
        _relations: `${["category", "class", "user"]}`,
        _perPage: fetchParams?.perPage,
        _page: fetchParams?.page,
    };
    return api
        .get(`/competition`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const findCompetition = (id) => {
    const params = {
        _competition_fields: `${competitionFields}`,
        _category_fields: `${["name"]}`,
        _class_fields: `${["name", "is_official"]}`,
        _user_fields: `${["name"]}`,
        _relations: `${["category", "class", "user"]}`,
    };
    return api
        .get(`/competition/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createCompetition = (data) => {
    return api
        .post("/competition", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateCompetition = (id, data) => {
    return api
        .put(`/competition/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteCompetition = (id) => {
    return api
        .delete(`/competition/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

//CREATE/UPDATE ASSIGMENT IN COMPETITION
