import { ReactComponent as GraphPoint } from "../assets/graph-elements/point.svg";
import { ReactComponent as GraphSegment } from "../assets/graph-elements/segment.svg";
import { ReactComponent as GraphLine } from "../assets/graph-elements/line.svg";
import { ReactComponent as GraphPolygon } from "../assets/graph-elements/polygon.svg";
import { ReactComponent as GraphVector } from "../assets/graph-elements/vector.svg";
import { ReactComponent as GraphCircle } from "../assets/graph-elements/circle.svg";
import { ReactComponent as GraphEllipse } from "../assets/graph-elements/ellipse.svg";
// import { ReactComponent as GraphParabola } from "../assets/graph-elements/parabola.svg";
// import { ReactComponent as GraphHyperbola } from "../assets/graph-elements/hyperbola.svg";

/**
 * Graph types:
 */
export const graphTypes = {
    default: {
        wrapperClassName: "geometry-board",
        isAllowedGeometries: true,
        attributes: {
            boundingBox: [-10, 10, 10, -10],
            keepAspectRatio: true,
            axis: true,
            grid: false,
            showCopyright: false,
            showNavigation: true,
            defaultAxes: {
                x: {
                    withLabel: true,
                    ticks: {
                        visible: true,
                        drawZero: false,
                        minorHeight: 8,
                        majorHeight: -1,
                    },
                },
                y: {
                    withLabel: true,
                    ticks: {
                        visible: true,
                        drawZero: false,
                        minorHeight: 8,
                        majorHeight: -1,
                    },
                },
            },
        },
    },
    numberline: {
        wrapperClassName: "numberline-board",
        isAllowedGeometries: false,
        isAllowedNumberlineLabels: true,
        attributes: {
            boundingBox: [-10, 10, 10, -10],
            keepAspectRatio: false,
            axis: true,
            grid: false,
            showCopyright: false,
            showNavigation: true,
            defaultAxes: {
                x: {
                    withLabel: false,
                    ticks: {
                        visible: true,
                        drawZero: true,
                        minorHeight: 8,
                        majorHeight: 16,
                    },
                    firstArrow: true,
                    lastArrow: true,
                },
                y: {
                    withLabel: false,
                    ticks: {
                        visible: false,
                        drawZero: false,
                        minorHeight: 8,
                        majorHeight: 16,
                    },
                    firstArrow: false,
                    lastArrow: false,
                    strokeColor: "#d9d9d9",
                    highlight: false,
                    visible: false,
                },
            },
        },
    },
};

/**
 * Graph modes:
 */
export const graphModes = {
    creating: {
        default: {
            enableElemUpdate: true,
            enableElemDelete: true,
        },
        numberline: {
            enableElemUpdate: true,
            enableElemDelete: false,
        },
    },
    solving: {
        default: {
            enableElemUpdate: true,
            enableElemDelete: true,
        },
        numberline: {
            enableElemUpdate: true,
            enableElemDelete: false,
        },
    },
};

/**
 * Graph geometry elements:
 */
export const graphElements = {
    point: {
        icon: <GraphPoint />,
    },
    segment: {
        icon: <GraphSegment />,
    },
    line: {
        icon: <GraphLine />,
    },
    polygon: {
        icon: <GraphPolygon />,
    },
    // ray: {
    //     icon: <GraphVector />,
    // },
    vector: {
        icon: <GraphVector />,
        jsxgraphElementType: "arrow",
    },
    circle: {
        icon: <GraphCircle />,
    },
    ellipse: {
        icon: <GraphEllipse />,
    },
    // parabola: {
    //     icon: <GraphParabola />,
    // },
    // hyperbola: {
    //     icon: <GraphHyperbola />,
    // },
    numberline_label: {
        icon: null,
        jsxgraphElementType: "text",
        paramsForSetEvents: {
            fixedY: 0,
        },
        getDisplayOfText: (text, position) => {
            return (
                '<div class="numberline-label-wrapper">' +
                '<div class="numberline-label">' +
                '<div class="label">' +
                `${text || ""}` +
                "</div>" +
                '<div class="label-pos">' +
                `${typeof position === "number" ? position : ""}` +
                "</div>" +
                '<div class="label-btn-out">&#8722;</div>' +
                "</div>" +
                "</div>"
            );
        },
    },
};
