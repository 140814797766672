module.exports = {
    // API:
    API_ENDPOINT: "https://api-dev.testonline.edu.vn/api/app",
    API_ENDPOINT_DEMO: "https://api-dev.testonline.edu.vn/api/app",
    GOOGLE: {
        ANALYTICS_TRACKING_ID: "G-HKB9XZ498Z", // e.g. UA-000000-2 or UA-000000-3
    },

    // Authentication - upgradation:
    ALLOW_UPGRADE_ACCOUNT: true,
    UPGRADE_ACCOUNT_ALLOWED_METHODS: {
        /**
         * If contact === true, open contact modal.
         * Otherwise, go to make-payment page.
         */
        team: {
            contact: false,
        },
        organization: {
            contact: true,
        },
    },

    // Authentication - demo account:
    ALLOW_DEMO_ACCOUNT: true,
    DEMO_ACCOUNT: {
        username: "teacher_demo@gmail.com",
        password: "1234",
    },
    DEMO_ACCOUNT_ALLOW_ACTIONS: {
        class: {
            create: true,
            // read: true,
            update: true,
            delete: false,
            mark_assignment: true,
            invite_student: false,
            invite_teacher: false,
            assign_exam: true,
        },
        question: {
            create: true,
            // read: true,
            update: true,
            delete: false,
        },
        exam: {
            create: true,
            // read: true,
            update: true,
            delete: false,
        },
        assignment: {
            create: true,
            // read: true,
            update: true,
            delete: false,
            invite: true,
            view: true,
        },
        file: {
            list_folders: true,
            list_files: true,
            create_folder: false,
            upload_files: false,
            remove_files: false,
            rename_file: false,
            move_files: false,
        },
        upgrade_account: {
            upgrade: false,
        },
        org_class: {
            create: false,
            read: false,
            update: false,
            delete: false,
            mark_assignment: false,
            invite_student: false,
            invite_teacher: false,
            assign_exam: false,
        },
    },

    // Actions:
    ALLOW_ACTIONS: {
        question: {
            clone: true,
        },
        exam: {
            clone: true,
        },
    },

    // Temp:
    VITE_PUSHER_APP_KEY: "",
    VITE_PUSHER_APP_CLUSTER: "",

    //Feedback config
    FEEDBACK: {
        SPACE: 10,
        /**
         * false: user ko muốn feedback -> tắt modal đi thì ko show lại tại page đó nữa,
         * nếu sang trang khác hoặc reload thì vẫn có thể show
         */
        CAN_SHOW_AFTER_CLOSE: false,
    },
};
