import { Col, notification, Row } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRole } from "src/api/containers/auth";
import LoginClock from "src/assets/images/login-clock.png";
import GirlAndBook from "src/assets/images/login-girl-and-book.png";
import { login } from "src/reducers/auth";
import "./ThirdPartyRole.scss";

const ThirdPartyRole = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();
    const handleChooseRole = (isTeacher = false) => {
        const formData = {};
        if (isTeacher) {
            formData.role = "teacher";
        } else {
            formData.role = "student";
        }
        updateRole(user?.id, formData).then((res) => {
            if (res?.status) {
                notification.success({
                    message: t("message.login_success"),
                });
                dispatch(login(res.data));
            } else {
                notification.error({
                    message: res?.message || t("message.third_party_login_error"),
                });
            }
        });
    };

    useEffect(() => {
        if (user?.role !== "guest") {
            navigate("/");
        }
    }, [user]);

    return (
        <div>
            <div className="role third-party-role">
                <div className="role-wrapper">
                    <h1>{t("login.who_are_you")}</h1>
                    <p>{t("login.intro")}</p>

                    <Row gutter={[30, 20]}>
                        <Col md={12} xs={24}>
                            <button type="button" onClick={() => handleChooseRole(true)}>
                                {t("login.teacher")}
                            </button>
                        </Col>
                        <Col md={12} xs={24}>
                            <button type="button" onClick={() => handleChooseRole()}>
                                {t("login.student")}
                            </button>
                        </Col>
                    </Row>
                    <span className="role-girl-and-book mobile">
                        <img src={GirlAndBook} alt="" />
                    </span>
                    <span className="role-clock mobile">
                        <img src={LoginClock} alt="" />
                    </span>
                </div>
                <div className="role-img-wrap">
                    <div className="color"></div>
                    <span className="role-girl-and-book">
                        <img src={GirlAndBook} alt="" />
                    </span>
                    <span className="role-clock">
                        <img src={LoginClock} alt="" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ThirdPartyRole;
