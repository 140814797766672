import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputNumber, Modal, notification, Spin } from "antd";
import { useValues } from "src/hooks";
import Icon from "src/modules/components/Icon";
import actionClose from "src/assets/images/action-close.svg";
import { default as CustomButton } from "src/modules/components/Button";
import { updateUserStorage } from "src/api/containers/storage";
import "./ModalEditStorage.scss";

const ModalEditStorage = (props) => {
    const { classId = "", user, visible = false, onOk = () => {}, onCancel = () => {}, organization, ...rest } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [values, setValues] = useValues({
        isLoading: false,
        isVisible: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmitForm = () => {
        const storage_supplied = form.getFieldsValue("storage").storage;
        // getStorageSpaceEmpty({ organization_id: organization?.organization_id }).then((res) => {
        //     if (storage_supplied > 0) {
        //         if (storage_supplied > res.data.storage_available) {
        //             notification.error({ message: t("organization.over_storage") });
        //         } else {
        updateUserStorage({
            user_id: props.user.id,
            storage: storage_supplied,
            organization_id: organization?.organization_id,
        }).then((res) => {
            if (res.status) {
                onOk();
                notification.success({ message: t("organization.set_storage_success") });
            } else {
                notification.error({ message: res.message || t("organization.storage_error") });
            }
        });
    };
    // }
    // });
    // };

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({ storage: user?.storage_organization_empty || 0 });
        }
    }, [visible]);

    return (
        <Modal
            visible={visible}
            onOk={handleSubmitForm}
            onCancel={handleCancel}
            centered
            title={t("update_info")}
            closeIcon={<img src={actionClose}></img>}
            footer={null}
            width="auto"
            {...rest}
            className={`app-modal type-basic flexible-height maxw-520${props.className ? " " + props.className : ""}`}
        >
            <Spin spinning={values.isVisible}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form} className="form-storage">
                        <Form.Item
                            label={t("organization.storage_available") + " (MB)"}
                            name="storage"
                            rules={[{ required: true, message: t("message.required") }]}
                            className="field-storage-available"
                        >
                            <InputNumber
                                // className="app-input has-rd"
                                placeholder={t("organization.storage_available")}
                                min={0}
                                maxLength={16}
                                autoFocus
                            />
                        </Form.Item>

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-delete" />}
                                title={t("shared.cancel")}
                                onClick={handleCancel}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save")}
                                onClick={handleSubmitForm}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalEditStorage;
