import { Button, Col, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCategories } from "src/api/containers/category";
import {
    cloneQuestion,
    deleteChildPassage,
    deleteChildQuestion,
    findPassageQuestion,
    quickAddOrDeleteQuestionToPassage,
    updateQuestion,
} from "src/api/containers/question";
import { deleteTag, fetchTags } from "src/api/containers/tag";
import { useValues } from "src/hooks";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { getQuestionDataPreview } from "src/api/helpers/question";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "src/modules/components/Header";
import Icon from "src/modules/components/Icon";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import QuestionContent from "src/modules/components/QuestionContent";
import QuestionPanel from "src/modules/components/QuestionPanel";
import DragDrop from "src/modules/components/QuestionPanel/DragDrop";
import DragDropGroup from "src/modules/components/QuestionPanel/DragDropGroup";
import FillBlanks from "src/modules/components/QuestionPanel/FillBlanks";
import FillBlanksDragDrop from "src/modules/components/QuestionPanel/FillBlanksDragDrop";
import FillBlanksInput from "src/modules/components/QuestionPanel/FillBlanksInput";
import MultipleChoice from "src/modules/components/QuestionPanel/MultipleChoice";
import MultipleChoiceBoolean from "src/modules/components/QuestionPanel/MultipleChoiceBoolean";
import MultipleChoiceTable from "src/modules/components/QuestionPanel/MultipleChoiceTable";
import Sort from "src/modules/components/QuestionPanel/Sort";
import Writing from "src/modules/components/QuestionPanel/Writing";
import WritingShort from "src/modules/components/QuestionPanel/WritingShort";
import { animationId } from "src/reducers/animation";
import { setCurrentPage as setCurrentPage1 } from "src/reducers/general";
import { useRouting } from "src/utils/router";
import QuestionDetail from "../..";
import SettingPublishingStatus from "../../components/SettingPublishingStatus";
import SettingTopBar from "../../components/SettingTopBar";
import DetailPassage from "./DetailPassage";
import "./DetailPassageQuestion.scss";
import ImageWithLabels from "src/modules/components/QuestionPanel/ImageWithLabels";
import MultipleChoiceSentenceQuiz from "src/modules/components/QuestionPanel/MultipleChoiceSentenceQuiz";
import HighlightImage from "src/modules/components/QuestionPanel/HighlightImage";
import Hotspot from "src/modules/components/QuestionPanel/Hotspot";
import Shading from "src/modules/components/QuestionPanel/Shading";
import Matrix from "src/modules/components/QuestionPanel/Matrix";
import SpeechToText from "src/modules/components/QuestionPanel/SpeechToText";
import { PlusOutlined } from "@ant-design/icons";
import MathTextDropdown from "src/modules/components/QuestionPanel/MathTextDropdown";
import Graph from "src/modules/components/QuestionPanel/Graph";
import QuickAddPassageQuestions from "./QuickAddPassageQuestions";
import GraphNumberLine from "src/modules/components/QuestionPanel/GraphNumberLine";

function DetailPassageQuestion(props) {
    const { type = "", closeModal = () => {}, refetch = () => {}, editingQuestion = null, onChange = () => {} } = props;

    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // Form:
    const [form] = Form.useForm();
    const [formStates, setFormStates] = useState({
        // formQuestionData: undefined, // Form data to be used for any child/tab.
        // formQuestionDataPreview: undefined, // Form data to be used for preview tab only.
        questionParagraphsPreview: undefined, // Question paragraphs data to be used for preview tab only.
    });

    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();
    const [values, setValues] = useValues({
        loading: false,
        optionListCategory: [],
        optionListTag: [],
        ownerID: "",
        isPublish: false,
        paragraphs: [],
        currentParagraphQuestions: [],
    });

    const [currentTab, setCurrentTab] = useState({
        tabKey: "modify", // Values: "modify" || "preview" || "".
        data: null,
    });
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [showQuickAddQuestionModal, setShowQuickAddQuestionModal] = useState(false);
    const [selectedParagraphId, setSelectedParagraphId] = useState("");
    const [isShowAnswer, setIsShowAnswer] = useState(false);

    const [currentPage, setCurrentPage] = useState({
        page: "",
        params: {},
    });
    const [currQuestionId, setCurrQuestionId] = useState(
        type === "exam_bank" && editingQuestion ? editingQuestion?.id : type === "exam_bank" ? "" : params.id
    );
    const [qScopeId, setQScopeId] = useState(false);

    const saveData = (isPublish = false) => {
        let formData = { ...(currentTab.data || {}), ...form.getFieldsValue() };

        if (isPublish === true && (!formData.category_id || !formData.question)) {
            notification.warning({
                message: t("message.warning_missing_fields"),
            });
            return;
        }
        if (values.paragraphs.length === 0) {
            notification.error({
                message: t("question_passage.require_question_data"),
            });
            return;
        }

        // Generate values to be sent to database:
        const { is_public, ...rest } = formData;
        // - Question scope:
        let isPublic = is_public;
        let organizationId = null; // Must be "null" if the question is public/private scope.
        if (isPublic !== true && isPublic !== false) {
            organizationId = isPublic;
            isPublic = true;
        }
        // - Question form data:
        formData = {
            ...rest,
            type: "passage",
            is_publish: isPublish,
            is_public: isPublic || false,
            organization_id: organizationId,
        };

        // Make api calls:
        setValues({ ...values, loading: true });
        if (currQuestionId) {
            updateQuestion(currQuestionId, formData).then((res) => {
                if (res.status) {
                    dispatch(animationId({ name: "question", update: currQuestionId }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    if (type === "exam_bank") {
                        closeModal();
                        if (refetch) refetch();
                    } else {
                        navigate(generate("question_bank"));
                    }
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
                if (onChange && onChange instanceof Function) {
                    formData.id = editingQuestion?.id;
                    formData.category = values.optionListCategory.find((item) => item.id === formData.category_id);
                    formData.tags = values.optionListTag.filter((item) => formData.tag_ids.includes(item.id));
                    onChange(formData);
                }
                setValues({ ...values, loading: false });
            });
        } else {
            setValues({ ...values, loading: false });
            closeModal();
        }
    };

    const handleSaveAsDraft = () => {
        saveData(false);
    };

    const handleSubmit = () => {
        saveData(true);
    };

    const handleChangeChildQuestion = (value) => {
        const newScore = values.paragraphs.reduce((total, cur) => {
            const scoreOfParagraph = cur.question_details.reduce((total, q) => {
                if (q._id === value.childId) {
                    return (total += value.score);
                }

                return (total += q.score);
            }, 0);

            return (total += scoreOfParagraph);
        }, 0);

        // Case update childId
        if (value.childId) {
            onChange({
                id: currQuestionId,
                score: newScore,
            });
        } // Case create new question
        else if (value.paragraphId) {
            onChange({
                id: currQuestionId,
                score: newScore + value.score,
            });
        } else {
        }
    };

    const handleCloneQuestion = (e) => {
        e.preventDefault();
        cloneQuestion(currQuestionId, { organization_id: fParamsOrgMember.organization_id }).then(
            ({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "question", clone: id }));
                    notification.success({
                        message: t("message.clone_success"),
                    });
                    navigate(generate("question_bank"));
                } else {
                    notification.error({
                        message: message || t("message.clone_error"),
                    });
                }
            }
        );
    };

    const handleGoBack = (e) => {
        e.preventDefault();
        if (type === "exam_bank") {
            closeModal();
        } else {
            navigate(generate("question_bank"));
        }
    };

    const handleClickAddChildPassage = (questionId) => {
        if (questionId) {
            setCurrentPage({
                page: "child-passage",
                params: {
                    questionId: questionId,
                },
            });
        } else {
            setCurrentPage({
                page: "child-passage",
                params: {},
            });
        }
    };

    const handleDeleteChildPassage = (childId) => {
        if (childId) {
            setValues({ ...values, loading: true });
            deleteChildPassage(childId).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload passage detail:
                    findPassageQuestion(currQuestionId).then((res) => {
                        if (res.status) {
                            const childParagraphs = res?.data?.detail?.paragraphs || [];
                            let finalScore = 0;
                            childParagraphs.forEach((paragraph) => {
                                let total_score = 0;
                                paragraph?.question_details?.forEach((question) => {
                                    total_score += question.score;
                                });
                                paragraph.total_score = total_score;
                                finalScore += total_score;
                            });

                            form.setFieldsValue({
                                score: finalScore,
                            });

                            onChange({
                                id: currQuestionId,
                                score: finalScore,
                            });

                            setValues({
                                ...values,
                                loading: false,
                                paragraphs: res.data?.detail?.paragraphs || [],
                            });
                        }
                    });
                }
            });
        }
    };

    // const handleDeleteChildQuestion = (childId) => {
    //     if (childId) {
    //         setValues({ ...values, loading: true });
    //         deleteChildQuestion(childId).then((res) => {
    //             if (res.status === true) {
    //                 notification.success({
    //                     message: t("message.delete_success"),
    //                 });
    //                 // Reload passage detail:
    //                 findPassageQuestion(currQuestionId).then((res) => {
    //                     if (res.status) {
    //                         const childParagraphs = res?.data?.detail?.paragraphs || [];
    //                         let finalScore = 0;
    //                         childParagraphs.forEach((paragraph) => {
    //                             let total_score = 0;
    //                             paragraph?.questions?.forEach((question) => {
    //                                 total_score += question.score;
    //                             });
    //                             paragraph.total_score = total_score;
    //                             finalScore += total_score;
    //                         });

    //                         form.setFieldsValue({
    //                             score: finalScore,
    //                         });

    //                         onChange({
    //                             id: currQuestionId,
    //                             score: finalScore,
    //                         });

    //                         setValues({
    //                             ...values,
    //                             loading: false,
    //                             paragraphs: res.data?.detail?.paragraphs || [],
    //                         });
    //                     }
    //                 });
    //             }
    //         });
    //     }
    // };

    const handleShowModalAddChildQuestion = (paragraphId) => {
        if (paragraphId) {
            setSelectedParagraphId(paragraphId);
            setShowAddQuestionModal(true);
        }
    };

    const handleShowModalQuickAddQuestion = (paragraphId, paragraphIndex) => {
        if (paragraphId) {
            setSelectedParagraphId(paragraphId);
            setShowQuickAddQuestionModal(true);
            setValues({
                currentParagraphQuestions: values?.paragraphs?.[paragraphIndex] || [],
            });
        }
    };

    const handleDeleteTag = (e, id) => {
        e.stopPropagation();

        deleteTag(id).then((res) => {
            if (res.status) {
                const newOptionListTag = values.optionListTag.filter((opt) => opt.id !== id);

                setValues({
                    optionListTag: newOptionListTag,
                });

                notification.success({ message: t("tag.remove_tag_success") });
            } else {
                notification.error({ message: t("tag.remove_tag_error") });
            }
        });
    };

    const redirectToChildQuestionDetail = (type, questionId, childId) => {
        // QUESTION DETAIL V2:
        setCurrentPage({
            page: `child-question-${type}`,
            params: {
                questionId: questionId,
                childId: childId,
            },
        });
    };

    const reloadPassageData = () => {
        setValues({ loading: true });
        // Reload passage detail:
        findPassageQuestion(currQuestionId).then((res) => {
            if (res.status) {
                const childParagraphs = res?.data?.detail?.paragraphs || [];
                let finalScore = 0;
                childParagraphs.forEach((paragraph) => {
                    let total_score = 0;
                    paragraph?.question_details?.forEach((question) => {
                        total_score += question.score;
                    });
                    paragraph.total_score = total_score;
                    finalScore += total_score;
                });

                form.setFieldsValue({
                    score: finalScore,
                });

                onChange({
                    id: currQuestionId,
                    score: finalScore,
                });

                setValues({
                    loading: false,
                    paragraphs: res.data?.detail?.paragraphs || [],
                });
            }
        });
    };

    const handleDeleteChildQuestion2 = (paragraphId, childId) => {
        quickAddOrDeleteQuestionToPassage(paragraphId, [childId], "delete").then((res) => {
            if (res.status) {
                notification.success({ message: t("message.delete_success") });
                reloadPassageData();
            } else {
                notification.error({ message: res?.message || t("message.delete_error") });
            }
        });
    };

    useEffect(() => {
        dispatch(setCurrentPage1("passage_question"));
        if (currentPage.page) {
            return;
        }
        if (currQuestionId) {
            setValues({ ...values, loading: true });
            Promise.all([
                findPassageQuestion(currQuestionId, { organization_id: fParamsOrgMember.organization_id }),
                fetchCategories({ slug: "", noPagination: true }),
                fetchTags({ slug: "", owner_id: user.id, page: 1 }),
            ]).then((res) => {
                if (res[0].status && res[1].status && res[2].status) {
                    // 1. Get default category list:
                    const defaultCategories = res[1].data ? [...res[1].data] : [];
                    // 2. Get default tag list:
                    let defaultTags = res[2].data ? [...res[2].data] : [];
                    if (res[0].data?.tags?.length) {
                        if (user.id !== res[0].data?.user_id) {
                            defaultTags = [...res[0].data?.tags];
                        } else {
                            const extraTags = res[0].data?.tags.filter((tagItem) => {
                                for (let i = 0; i < defaultTags.length; i++) {
                                    if (tagItem.id === defaultTags[i].id) {
                                        return false;
                                    }
                                }
                                return true;
                            });
                            defaultTags = [...defaultTags, ...extraTags];
                        }
                    }

                    const childParagraphs = res[0].data?.detail?.paragraphs || [];
                    let finalScore = 0;
                    childParagraphs.forEach((paragraph) => {
                        let total_score = 0;
                        paragraph?.question_details?.forEach((question) => {
                            total_score += question.score;
                        });
                        paragraph.total_score = total_score;
                        finalScore += total_score;
                    });

                    const questionFormData = {
                        is_public:
                            form.getFieldValue("is_public") !== undefined
                                ? form.getFieldValue("is_public")
                                : res[0].data?.is_public || false,
                        is_publish: res[0].data?.is_publish || false,
                        category_id: form.getFieldValue("category_id") || res[0].data?.category?.id || undefined,
                        tag_ids:
                            form.getFieldValue("tag_ids") ||
                            (res[0].data?.tags?.length ? res[0].data?.tags?.map((tag) => tag.id) : []),
                        question: form.getFieldValue("question") || res[0].data?.detail?.question || "",
                        score: finalScore,
                    };

                    // Question scope:
                    const questionScope = getItemScopeSelected(res[0].data);
                    if (questionScope.scopeId) {
                        (questionFormData.is_public =
                            form.getFieldValue("is_public") !== undefined
                                ? form.getFieldValue("is_public")
                                : questionScope.scopeId || false),
                            setQScopeId(questionScope.scopeId);
                    }

                    // Update form state:
                    form.setFieldsValue(questionFormData);
                    setValues({
                        ...values,
                        loading: false,
                        optionListCategory: defaultCategories,
                        optionListTag: defaultTags,
                        ownerID: res[0].data?.user_id || "",
                        isPublish: res[0].data?.is_publish || false,
                        paragraphs: res[0].data?.detail?.paragraphs || [],
                        finalScore: finalScore,
                    });
                    if (user.id !== res[0].data?.user_id) {
                        setCurrentTab({ ...currentTab, tabKey: "preview" });
                    }
                }
            });
        } else {
            setValues({ ...values, loading: true });
            Promise.all([
                fetchCategories({ slug: "", noPagination: true }),
                fetchTags({ slug: "", owner_id: user.id, page: 1 }),
            ]).then((res) => {
                if (res[0].status && res[1].status) {
                    // 1. Get default category list:
                    const defaultCategories = res[0].data ? [...res[0].data] : [];
                    // 2. Get default tag list:
                    const defaultTags = res[1].data ? [...res[1].data] : [];
                    // 3. Update form state:
                    form.setFieldsValue({
                        is_public: false,
                        score: 0,
                    });
                    setValues({
                        ...values,
                        loading: false,
                        optionListCategory: defaultCategories,
                        optionListTag: defaultTags,
                    });
                }
            });
        }
    }, [currentPage]);

    const renderActionList = () => {
        if (type !== "exam_bank") {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={currQuestionId ? t("shared.back") : t("shared.cancel")}
                            onClick={(e) => handleGoBack(e)}
                        ></CustomButton>

                        {(!currQuestionId ||
                            (currQuestionId && user.id === values.ownerID && currQuestionId && !values.isPublish)) && (
                            <CustomButton
                                htmlType="submit"
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleSaveAsDraft}
                            ></CustomButton>
                        )}

                        {!currQuestionId || (currQuestionId && user.id === values.ownerID) ? (
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-rocket" />}
                                title={currQuestionId && values.isPublish ? t("shared.save") : t("shared.publish")}
                                onClick={handleSubmit}
                            ></CustomButton>
                        ) : (
                            <>
                                {checkIfActionIsAllowed("question", "clone") === true ? (
                                    <CustomButton
                                        htmlType="submit"
                                        type="primary"
                                        icon={<Icon name="icon-rocket" />}
                                        title={t("shared.clone")}
                                        onClick={handleCloneQuestion}
                                    ></CustomButton>
                                ) : null}
                            </>
                        )}
                    </Form.Item>
                </div>
            );
        } else {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={currQuestionId ? t("shared.back") : t("shared.cancel")}
                            onClick={(e) => handleGoBack(e)}
                        ></CustomButton>
                        {!editingQuestion && (
                            <CustomButton
                                htmlType="submit"
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleSaveAsDraft}
                            ></CustomButton>
                        )}
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("shared.save")}
                            onClick={handleSubmit}
                        ></CustomButton>
                    </Form.Item>
                </div>
            );
        }
    };

    const renderChildPage = () => {
        if (currentPage.page === "child-passage") {
            return (
                <DetailPassage
                    questionId={currentPage.params.questionId || ""}
                    childId={currentPage.params.childId || ""}
                    onGoBack={(idNewPassageQuestion) => {
                        setCurrentPage({ page: "", params: {} });
                        if (idNewPassageQuestion && typeof idNewPassageQuestion === "string") {
                            setCurrQuestionId(idNewPassageQuestion);
                        }
                        if (idNewPassageQuestion && type !== "exam_bank") {
                            navigate(generate("update_passage", { id: idNewPassageQuestion }));
                        }
                    }}
                    type={type}
                ></DetailPassage>
            );
        } else {
            return (
                <QuestionDetail
                    typeOfQuestion={currentPage.page.replace("child-question-", "")}
                    questionId={currentPage.params.questionId || ""}
                    paragraphId={currentPage.params.paragraphId || ""}
                    childId={currentPage.params.childId || ""}
                    onGoBack={() => setCurrentPage({ page: "", params: {} })}
                    onChange={handleChangeChildQuestion}
                ></QuestionDetail>
            );
        }
    };

    const handleChangePassage = (newValue) => {
        setCurrentTab({
            ...currentTab,
            data: {
                ...currentTab.data,
                answered: newValue.answered,
            },
        });
    };

    // dòng này note lại: đưa paragraph-title lên trên paragraph-heading, sau đó đưa renderChildPage ra ngoài

    if (currentPage.page) {
        return renderChildPage();
    }

    return (
        <div className="question-detail-wrapper">
            <Header
                backTitle={t(`q_detail.header_passage`)}
                backFunc={(e) => handleGoBack(e)}
                actions={[
                    {
                        title: t("header.modify"),
                        icon: <Icon name="icon-modify" fill={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: () => {
                            setCurrentTab({ ...currentTab, tabKey: "modify", data: null });
                        },
                        type: currentTab.tabKey === "modify" ? "primary" : "",
                        isDisabled: !params.id || user.id === values.ownerID || type === "exam_bank" ? false : true,
                    },
                    {
                        title: t("header.preview"),
                        icon: <Icon name="icon-preview" fill={currentTab.tabKey === "preview" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: () => {
                            if (currentTab.tabKey !== "preview") {
                                setIsShowAnswer(false);
                                setCurrentTab({
                                    ...currentTab,
                                    tabKey: "preview",
                                    data: {
                                        ...form.getFieldsValue(),
                                        // answered,
                                    },
                                });

                                const questionParagraphsPreviewNew = values.paragraphs.map((pItem) => {
                                    return {
                                        ...pItem,
                                        question_details: pItem.question_details?.map((qItem) => {
                                            return getQuestionDataPreview(qItem);
                                        }),
                                    };
                                });
                                setFormStates({
                                    ...formStates,
                                    questionParagraphsPreview: questionParagraphsPreviewNew,
                                });
                            }
                        },
                        type: currentTab.tabKey === "preview" ? "primary" : "",
                        isDisabled: values.loading,
                    },
                ]}
            />
            <Spin spinning={values.loading}>
                <Form form={form}>
                    {currentTab.tabKey === "modify" && (
                        <div className="question-detail passage">
                            <div className="question-detail-content">
                                <SettingTopBar
                                    optionListCategory={values.optionListCategory}
                                    optionListTag={values.optionListTag}
                                    handleAddTagPending={() => {
                                        setValues({ ...values, loading: true });
                                    }}
                                    handleAddTagFulfilled={(newTagList) => {
                                        setValues({ ...values, loading: false, optionListTag: newTagList });
                                    }}
                                    onChangeCategoryList={(newCateList) => {
                                        setValues({ ...values, optionListCategory: newCateList });
                                    }}
                                    onChangeTagList={(newTagList) => {
                                        setValues({ ...values, optionListTag: newTagList });
                                    }}
                                    onDeleteTag={handleDeleteTag}
                                />

                                <div className="detail-passage-question">
                                    <Form.Item
                                        name="question"
                                        label={t("question_passage.title")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <Input className="app-input" placeholder={t("question_passage.title")} />
                                    </Form.Item>
                                </div>

                                <div className="question-setup-wrapper">
                                    <CustomButton
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        title={t("question_passage.add_paragraph")}
                                        onClick={() => {
                                            handleClickAddChildPassage(currQuestionId);
                                        }}
                                    ></CustomButton>
                                </div>

                                <div className="question-passage-wrapper">
                                    {!values.loading &&
                                        values.paragraphs?.map((p, pIndex) => {
                                            return (
                                                <Row
                                                    className="question-passage"
                                                    key={`child-passage-${pIndex}`}
                                                    gutter={[32, 32]}
                                                >
                                                    <Col xs={24} sm={24} md={24} lg={12}>
                                                        <div className="paragraph-wrapper">
                                                            <div className="paragraph-title">{p.title || ""}</div>
                                                            <div className="paragraph-heading">{p.heading || ""}</div>
                                                            <div
                                                                className="paragraph-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: p.content || "",
                                                                }}
                                                            ></div>
                                                            <span className="action-list">
                                                                <div>
                                                                    {t("shared.total_score")}: {p?.total_score || 0}
                                                                </div>
                                                                <div>
                                                                    <Popconfirm
                                                                        title={`${t("shared.msg_confirm_delete")}?`}
                                                                        onConfirm={() => {
                                                                            handleDeleteChildPassage(p._id);
                                                                        }}
                                                                        okText={t("shared.yes")}
                                                                        cancelText={t("shared.no")}
                                                                    >
                                                                        <span className="action-button">
                                                                            <Icon name="icon-delete" />
                                                                        </span>
                                                                    </Popconfirm>
                                                                    <span
                                                                        className="action-button"
                                                                        onClick={() => {
                                                                            setCurrentPage({
                                                                                page: "child-passage",
                                                                                params: {
                                                                                    questionId: currQuestionId,
                                                                                    childId: p._id,
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        <Icon name="icon-edit" />
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12}>
                                                        <div className="question-list-wrapper">
                                                            <div className="list-header">
                                                                <CustomButton
                                                                    type="primary"
                                                                    icon={<PlusOutlined />}
                                                                    title={t("question_passage.add_question")}
                                                                    onClick={() => {
                                                                        handleShowModalAddChildQuestion(p._id);
                                                                    }}
                                                                ></CustomButton>
                                                                <CustomButton
                                                                    type="primary"
                                                                    icon={<PlusOutlined />}
                                                                    title={t("question_passage.quick_add_question")}
                                                                    onClick={() => {
                                                                        handleShowModalQuickAddQuestion(p._id, pIndex);
                                                                    }}
                                                                ></CustomButton>
                                                            </div>
                                                            <div className="list-body">
                                                                {p.question_details?.map((q, qIndex) => {
                                                                    const qPreview = getQuestionDataPreview(q);
                                                                    const defaultProps = {
                                                                        question: qPreview.question,
                                                                        answer: qPreview.answer,
                                                                    };
                                                                    return (
                                                                        <div
                                                                            className="question-wrapper"
                                                                            key={`child-question-${qIndex}`}
                                                                        >
                                                                            {q.type === "writing" && (
                                                                                <Writing
                                                                                    wordLimit={q.word_limit}
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}
                                                                            {q.type === "writing_short" && (
                                                                                <WritingShort {...defaultProps} />
                                                                            )}
                                                                            {q.type === "fill_in_the_blank" && (
                                                                                <FillBlanks {...defaultProps} />
                                                                            )}
                                                                            {q.type ===
                                                                                "fill_in_the_blank_drag_drop" && (
                                                                                <FillBlanksDragDrop {...defaultProps} />
                                                                            )}
                                                                            {q.type === "fill_in_the_blank_text" && (
                                                                                <FillBlanksInput {...defaultProps} />
                                                                            )}
                                                                            {q.type === "fill_in_the_blank_image" && (
                                                                                <ImageWithLabels
                                                                                    coordinates={q.coordinates}
                                                                                    src={q.file?.src}
                                                                                    width={q.width}
                                                                                    height={q.height}
                                                                                    labelType="dropdown"
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}
                                                                            {q.type ===
                                                                                "fill_in_the_blank_drag_drop_image" && (
                                                                                <ImageWithLabels
                                                                                    coordinates={q.coordinates}
                                                                                    src={q.file?.src}
                                                                                    width={q.width}
                                                                                    height={q.height}
                                                                                    labelType="drag&drop"
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}
                                                                            {q.type ===
                                                                                "fill_in_the_blank_text_image" && (
                                                                                <ImageWithLabels
                                                                                    coordinates={q.coordinates}
                                                                                    src={q.file?.src}
                                                                                    width={q.width}
                                                                                    height={q.height}
                                                                                    labelType="text"
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}

                                                                            {q.type === "multiple_choice" && (
                                                                                <MultipleChoice
                                                                                    {...defaultProps}
                                                                                    answer={q.answer?.map(
                                                                                        (aswr) => aswr.text
                                                                                    )}
                                                                                />
                                                                            )}
                                                                            {q.type ===
                                                                                "multiple_choice_sentence_quiz" && (
                                                                                <MultipleChoiceSentenceQuiz
                                                                                    {...defaultProps}
                                                                                    answer={q.answer?.map(
                                                                                        (aswr) => aswr.text
                                                                                    )}
                                                                                />
                                                                            )}
                                                                            {q.type === "multiple_choice_boolean" && (
                                                                                <MultipleChoiceBoolean
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}
                                                                            {q.type === "multiple_choice_table" && (
                                                                                <MultipleChoiceTable
                                                                                    {...defaultProps}
                                                                                    answer1={q.answer1}
                                                                                    answer2={q.answer2}
                                                                                    match={q.match}
                                                                                />
                                                                            )}
                                                                            {q.type === "drag_drop" && (
                                                                                <DragDrop
                                                                                    {...defaultProps}
                                                                                    answer={{
                                                                                        answer1: q.answer1,
                                                                                        answer2: q.answer2,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {q.type === "drag_drop_group" && (
                                                                                <DragDropGroup
                                                                                    {...defaultProps}
                                                                                    answers={q.answer1}
                                                                                    groups={q.answer2}
                                                                                />
                                                                            )}
                                                                            {q.type === "drag_drop_group_order_by" && (
                                                                                <DragDropGroup
                                                                                    {...defaultProps}
                                                                                    answers={q.answer1}
                                                                                    groups={q.answer2}
                                                                                />
                                                                            )}
                                                                            {q.type === "sort" && (
                                                                                <Sort {...defaultProps} />
                                                                            )}
                                                                            {q.type === "hot_spot" && (
                                                                                <Hotspot
                                                                                    {...defaultProps}
                                                                                    qestionData={{
                                                                                        src: q.file?.src,
                                                                                        width: q.width,
                                                                                        height: q.height,
                                                                                        paths: q.path,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {q.type === "highlight_image" && (
                                                                                <HighlightImage
                                                                                    {...defaultProps}
                                                                                    isReadonly={true}
                                                                                    answer={[
                                                                                        {
                                                                                            src: q.file?.src,
                                                                                            width: q.width,
                                                                                            height: q.height,
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                            )}
                                                                            {q.type === "highlight_square" && (
                                                                                <Shading
                                                                                    {...defaultProps}
                                                                                    // isReadonly={true}
                                                                                    qInfo={q}
                                                                                />
                                                                            )}
                                                                            {q.type === "convert_measurement" && (
                                                                                <MathTextDropdown {...defaultProps} />
                                                                            )}
                                                                            {q.type === "speech_to_text" && (
                                                                                <SpeechToText
                                                                                    key={`k${pIndex}`}
                                                                                    {...defaultProps}
                                                                                    speaker={q.speaker}
                                                                                    is_sentence={q.is_sentence}
                                                                                    lang={q?.language?.code}
                                                                                    q={{ q, p }}
                                                                                    hideMicro={true}
                                                                                />
                                                                            )}
                                                                            {q.type === "matrix" && (
                                                                                <Matrix
                                                                                    {...defaultProps}
                                                                                    template_latex={q.template_latex}
                                                                                />
                                                                            )}
                                                                            {q.type === "fill_in_the_blank_latex" && (
                                                                                <Matrix
                                                                                    {...defaultProps}
                                                                                    template_latex={q.template_latex}
                                                                                />
                                                                            )}
                                                                            {q.type === "draw_graph" && (
                                                                                <Graph {...defaultProps} />
                                                                            )}
                                                                            {q.type === "number_line" && (
                                                                                <GraphNumberLine
                                                                                    max_value={q.max_value}
                                                                                    min_value={q.min_value}
                                                                                    jump_value={q.jump_value}
                                                                                    {...defaultProps}
                                                                                />
                                                                            )}
                                                                            <span className="action-list">
                                                                                <div>
                                                                                    {t("shared.score")}: {q?.score || 0}
                                                                                </div>
                                                                                <div>
                                                                                    <Popconfirm
                                                                                        title={`${t(
                                                                                            "shared.msg_confirm_delete"
                                                                                        )}?`}
                                                                                        onConfirm={() => {
                                                                                            handleDeleteChildQuestion2(
                                                                                                p._id,
                                                                                                q._id
                                                                                            );
                                                                                        }}
                                                                                        okText={t("shared.yes")}
                                                                                        cancelText={t("shared.no")}
                                                                                    >
                                                                                        <span className="action-button">
                                                                                            <Icon name="icon-delete" />
                                                                                        </span>
                                                                                    </Popconfirm>
                                                                                    <span
                                                                                        className="action-button"
                                                                                        onClick={() => {
                                                                                            redirectToChildQuestionDetail(
                                                                                                q.type,
                                                                                                currQuestionId,
                                                                                                q._id
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <Icon name="icon-edit" />
                                                                                    </span>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                </div>

                                <div className="detail-passage-total-score">
                                    <Form.Item name="score" label={t("shared.total_score")}>
                                        <InputNumber readOnly className="app-input" />
                                    </Form.Item>
                                </div>

                                <SettingPublishingStatus questionScopeId={qScopeId} />
                            </div>

                            <div className="question-detail-actions">{renderActionList()}</div>
                        </div>
                    )}
                    {currentTab.tabKey === "preview" && (
                        <div className="question-detail-preview">
                            <div className="question-preview-content">
                                <button onClick={() => setIsShowAnswer(!isShowAnswer)} className="question-show-answer">
                                    {isShowAnswer ? t("q_detail.hide_answer") : t("q_detail.show_answer")}
                                </button>
                                {!isShowAnswer && (
                                    <Form.Item>
                                        <QuestionPanel
                                            showOrdinalNumber={false}
                                            showQuestionNumber={false}
                                            showBottomNav={false}
                                            onChange={handleChangePassage}
                                            questionInfo={{
                                                type: "passage",
                                                paragraphs: formStates.questionParagraphsPreview,
                                                answered: currentTab.data.answered,
                                            }}
                                        ></QuestionPanel>
                                    </Form.Item>
                                )}
                                {isShowAnswer && (
                                    <QuestionContent
                                        type="review"
                                        questionInfo={{
                                            type: "passage",
                                            paragraphs: values.paragraphs,
                                            answered: currentTab.data.answered,
                                        }}
                                        showResults={true}
                                        isShowScore={false}
                                        // Question display type:
                                        displayType="question-panel"
                                    />
                                )}

                                {(!currQuestionId || user.id === values.ownerID || type === "exam_bank") && (
                                    <SettingPublishingStatus questionScopeId={qScopeId} />
                                )}
                            </div>

                            <div className="question-preview-actions">{renderActionList()}</div>
                        </div>
                    )}
                </Form>
            </Spin>

            {/* Modals: */}
            <ModalSelectQuestionType
                visible={showAddQuestionModal}
                onOk={() => {}}
                onCancel={() => setShowAddQuestionModal(false)}
                type="passage"
                onClickItem={
                    currQuestionId
                        ? (item) => {
                              setCurrentPage({
                                  page: `child-question-${item}`,
                                  params: {
                                      questionId: currQuestionId,
                                      paragraphId: selectedParagraphId,
                                  },
                              });
                              setShowAddQuestionModal(false);
                          }
                        : (item) => {
                              setCurrentPage({
                                  page: `child-question-${item}`,
                                  params: {},
                              });
                          }
                }
            />

            <Modal
                className="add-exam-question-modal scroll_primary"
                centered
                title={t("question_passage.quick_add_question")}
                footer={null}
                visible={showQuickAddQuestionModal}
                onCancel={() => setShowQuickAddQuestionModal(false)}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                bodyStyle={{
                    overflowY: "auto",
                    height: "calc(100vh - 100px)",
                    backgroundColor: "#F0F6FB",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
            >
                <QuickAddPassageQuestions
                    setIsModalAddQuestions={setShowQuickAddQuestionModal}
                    value={values.currentParagraphQuestions?.question_detail_ids || []}
                    paragraphI={selectedParagraphId}
                    reloadPassageData={reloadPassageData}
                    onChange={(value) => {
                        // Handle add question to exam
                        // if (values.questions.length === 0) {
                        //     setCurrentQuestionOrder(0);
                        // }
                        // setValues({
                        //     questions: value,
                        // });
                    }}
                />
            </Modal>
            {/* <div className="button-list-wrapper">
                            <div className="button-list">
                                {Object.keys(questionTypes).map((item, itemIndex) => {
                                    if (item === "highlight") return; // Câu highlight k có trong passage
                                    return (
                                        <Button key={`nav-to-q${itemIndex}`} onClick={} style={{ flex: "initial" }}>
                                            {t(`q.${item}`)}
                                        </Button>
                                    );
                                })}
                            </div>
                        </div> */}
            {/* </ModalSelectQuestionType> */}
        </div>
    );
}

export default DetailPassageQuestion;
