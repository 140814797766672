import React, { useEffect, useMemo, useState } from "react";
import ImageLabel from "../ImageLabel";
import "./ImageLabelsContainer.scss";

function ImageLabelsContainer({
    isReadonly,
    // HTML properties:
    className,
    // Image:
    children,
    src /** @param {string} src */,
    // Labels:
    labelType /** @param {string} type. Values: "text", "dropdown", "drag&drop". */,
    labelList /** @param {Object[]} labelList */,
    labelAnswerList /** @param {string[]} labelAnswerList */,
    // Container:
    containerSize /** @param {Object} containerSize. Value: { width: 0, height: 0 } */,
    // Event handlers:
    onUpdateLabelAnswer,
    onRemoveLabelAnswer,
    onSwapLabelAnswer,
}) {
    const [labels, setLabels] = useState([]);
    const [labelIsFocused, setLabelIsFocused] = useState(false);

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        return r;
    }, [className]);

    const handleFocusLabelContent = (lblLocalId) => {
        setLabelIsFocused(lblLocalId);
    };

    const handleBlurLabelContent = () => {
        setLabelIsFocused(false);
    };

    useEffect(() => {
        if (labelList?.length >= 0) {
            setLabels(labelList);
        }
    }, [labelList]);

    if (!labelType) {
        return <div className={`view-image-labels-container${extraClassnames}`}>{children}</div>;
    }

    return (
        <div className={`view-image-labels-container${extraClassnames}`}>
            {children ? (
                children
            ) : src ? (
                <span className="image-wrapper">
                    <img
                        src={src}
                        alt="image-with-labels"
                        draggable={false}
                        style={{
                            width: containerSize.width || 0,
                            height: containerSize.height || 0,
                            maxWidth: "unset",
                            maxHeight: "unset",
                        }}
                    />
                </span>
            ) : null}

            {labels.map((item, itemIndex) => {
                return (
                    <div key={`lbl-${itemIndex}`}>
                        <ImageLabel
                            localId={itemIndex}
                            style={{ left: `${item.left}px`, top: `${item.top}px` }}
                            type={labelType}
                            content={item.content}
                            contentList={labelAnswerList}
                            // Event handlers:
                            onUpdateLabelAnswer={onUpdateLabelAnswer}
                            onRemoveLabelAnswer={onRemoveLabelAnswer}
                            onSwapLabelAnswer={onSwapLabelAnswer}
                            // Focus and blur:
                            isFocused={labelIsFocused === itemIndex}
                            onFocusContent={handleFocusLabelContent}
                            onBlurContent={handleBlurLabelContent}
                            // Others:
                            isReadonly={isReadonly}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default ImageLabelsContainer;
