import React from "react";
import { Avatar as AntdAvatar } from "antd";
import { useTranslation } from "react-i18next";
import badgeWrapper from "src/assets/images/account/badge-polygon.svg";
import { ReactComponent as SvgTypePremium } from "src/assets/images/account/type-premium.svg";
import { ReactComponent as SvgTypeOrg } from "src/assets/images/account/type-org.svg";
// import { ReactComponent as SvgVipCrown } from "src/assets/images/account/vip-crown.svg";
import "./Avatar.scss";

const Avatar = ({
    displayType = "default", // Values: "default", "by-account-type".
    className,
    src,
    alt,
    accountType, // undefined, "premium", "organization".
    ...rest
}) => {
    const { t } = useTranslation();

    // Generate ClassNames:
    const currClassNames = ["avatar-img-wrapper"];
    if (className) {
        currClassNames.push(className);
    }
    if (accountType) {
        currClassNames.push("wrap-with-badge");
    }

    // Render badge:
    const renderBadge = () => {
        if (accountType) {
            let Icon = null;
            switch (accountType) {
                case "premium":
                    Icon = SvgTypePremium;
                    break;
                case "organization":
                    Icon = SvgTypeOrg;
                    break;
            }
            return (
                <span className="avatar-img-badge" style={{ backgroundImage: `url(${badgeWrapper})` }}>
                    {Icon ? <Icon /> : null}
                </span>
            );
        } else {
            return null;
        }
    };

    // Render label:
    const renderLabel = () => {
        if (accountType) {
            return (
                <span className="avatar-img-label">
                    <span className="label-content">
                        {/* <span className="label-icon">
                            <SvgVipCrown />
                        </span> */}
                        <span>{t(`account.${accountType}`)}</span>
                    </span>
                </span>
            );
        } else {
            return null;
        }
    };

    if (displayType === "by-account-type") {
        return (
            <span className={currClassNames.join(" ")} {...rest}>
                <span className="avatar-img">
                    <span className="img-wrapper">
                        <img src={src} alt={alt} />
                    </span>
                    {renderBadge()}
                </span>
                {renderLabel()}
            </span>
        );
    }

    return <AntdAvatar className={`app-avatar${className ? " " + className : ""}`} src={src} alt={alt} {...rest} />;
};

export default Avatar;
