import { Table, Tooltip } from "antd";
import { t } from "i18next";
import React, { useMemo } from "react";
import { getColorForPercentChart } from "src/utils/helpers";
import "./ReportTable.scss";
import { getStudentRatings } from "src/modules/components/Chart/ClassificationChart";

const ReportTable = (props) => {
    const { data = [], isPagination = true } = props;
    const newData = useMemo(() => {
        return data.map((record) => {
            return {
                key: record._id,
                nameOfClass: record.assignment?.class?.name ?? t("shared.unknown"),
                subject: record.assignment.category?.name ?? t("shared.unknown"),
                assignment: record?.assignment?.name || record?.assignment?.exam_question?.name,
                grasp: record.grasp,
                averageScore: record.score_percent,
                assignmentData: record?.assignment,
            };
        });
    }, [data]);

    const columns = [
        {
            title: t("report.name_of_class"),
            dataIndex: "nameOfClass",
            align: "center",
            width: 100,
        },
        {
            title: t("report.subject2"),
            dataIndex: "subject",
            align: "center",
            width: 100,
        },
        {
            title: t("report.test"),
            dataIndex: "assignment",
            align: "center",
            width: 100,
        },
        // {
        //     title: t("shared.scope"),
        //     dataIndex: "assignmentData",
        //     align: "center",
        //     width: 100,
        //     render: (data) => {
        //         if (data?.class?.name) {
        //             return `${t("class.class")}: ${data?.class?.name}`;
        //         } else if (data?.organization?.name) {
        //             if (data?.organization?.is_team) {
        //                 return `${t("organization.team")}: ${data?.organization?.name}`;
        //             }
        //             return `${t("organization.organization")}: ${data?.organization?.name}`;
        //         }
        //         return t("shared.unknown");
        //     },
        // },

        // {
        //     title: t("report.grasp"),
        //     dataIndex: "grasp",
        //     align: "center",
        // },
        {
            title: t("report.average_score"),
            dataIndex: "averageScore",
            align: "center",
            sorter: {
                compare: (a, b) => {
                    return a.averageScore - b.averageScore;
                },
            },
            render: (text) => {
                const color = getColorForPercentChart(text);
                return (
                    <Tooltip title={t(getStudentRatings(text))} color={color}>
                        <div className="reportTable_scoreBar" style={{ backgroundColor: color }}>
                            {text}%
                        </div>
                    </Tooltip>
                );
            },
            width: 100,
        },
    ];

    return (
        <div className="reportTable">
            <div className="reportTable_header">
                <div className="reportTable_title">{t("report.test_classification")}</div>
            </div>
            <div className="reportTable_table">
                <Table
                    columns={columns}
                    dataSource={newData}
                    pagination={
                        isPagination
                            ? {
                                  pageSize: 9,
                                  position: ["bottomCenter"],
                              }
                            : isPagination
                    }
                />
            </div>
        </div>
    );
};

export default React.memo(ReportTable);
