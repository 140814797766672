import clsx from "clsx";
import { useCallback, useEffect, useRef } from "react";
import LatexMathField from "./components/LatexMathField";
import LatexOptionsDropdown from "./components/LatexOptionsDropdown";
import "./InputLatexFlanks.scss";

const InputLatexFlanks = ({
    className,
    disabled,
    placeholder,
    value,
    onChange,
    isResetMath,
    setIsResetMath,
    // Latex dropdown:
    dropdownProps,
    // Latex options:
    filterOptions,
    filterOptionSelected,
}) => {
    const __mathFieldRef = useRef(null);
    const __positionRef = useRef(null);

    const change = useCallback((_value) => {
        if (onChange && onChange instanceof Function) {
            onChange(_value);
        }
    }, []);

    const handleSelectOption = (opt, keystrokes) => {
        opt && __mathFieldRef.current.write(opt);

        keystrokes?.length && __mathFieldRef.current?.keystroke(keystrokes?.join(" ") || "");

        const { clientX, clientY, target } = __positionRef.current;

        if (__mathFieldRef.current.__controller.cursor[1]) {
            __mathFieldRef.current.clickAt(clientX, clientY, target);
        } else {
            const textarea = __mathFieldRef.current.el().querySelector(".mq-textarea textarea");
            textarea.focus();
        }
    };

    useEffect(() => {
        if (isResetMath && value) {
            __mathFieldRef.current?.latex(value || "");
            setIsResetMath(false);
        }
    }, [value, isResetMath]);

    return (
        <LatexOptionsDropdown
            filterOptions={filterOptions}
            filterOptionSelected={filterOptionSelected}
            onSelectOption={handleSelectOption}
            sizeRow={8}
            {...dropdownProps}
        >
            <div className={clsx("app-input-latex app-input-latex--fillblanks", className, disabled && " disabled")}>
                <div className="input-latex">
                    <LatexMathField change={change} mathFieldRef={__mathFieldRef} position={__positionRef} />
                </div>
            </div>
        </LatexOptionsDropdown>
    );
};

export default InputLatexFlanks;
