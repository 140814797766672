import { generateConfigForInput } from "./input";
import { generateConfigForInputMultiChoiceQuestion } from "./inputForMultiChoiceQuestion";
import { generateConfigForInputRich } from "./inputRich";
import { generateConfigForTextarea } from "./textarea";
import { generateConfigForQuestion } from "./question";
import { generateConfigForQuestionFB } from "./question_fillblanks";
import { generateConfigForQuestionHL } from "./question_highlight";

const configEditor = {
    input: generateConfigForInput,
    inputMultiChoiceQuestion: generateConfigForInputMultiChoiceQuestion,
    inputRich: generateConfigForInputRich,
    textArea: generateConfigForTextarea,
    question: generateConfigForQuestion,
    question_fillblanks: generateConfigForQuestionFB,
    question_highlight: generateConfigForQuestionHL,
};

export default configEditor;
