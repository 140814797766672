import React, { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import NotificationPanel from "..";
import Icon from "../../Icon";
import "./ModalNotification.scss";

const ModalNotification = ({ className = "", countNewItems, onChangeCountNewItems, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Modal
            centered
            className={`app-modal maxw-520 modal-notification${className ? " " + className : ""}`}
            title={t("notification.notifications")}
            closeIcon={<Icon name="icon-cross-thin" />}
            footer={null}
            {...rest}
        >
            <NotificationPanel countNewItems={countNewItems} onChangeCountNewItems={onChangeCountNewItems} />
        </Modal>
    );
};

export default ModalNotification;
