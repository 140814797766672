import { t } from "i18next";
import DefaultAvatar from "src/assets/images/DefaultAvatar.png";
import "./styles.scss";
const UserBoard = (props) => {
    const { fullName, nameOfClasses = [], subject, avatar = DefaultAvatar, exportPdf: fireExportPdf } = props;

    return (
        <div className="userBoard_wrapper">
            <div className="userBoard_inner">
                <div className="userBoard_avatarWrapper">
                    <img src={avatar} alt={`avatar of ${fullName}`} />
                </div>
                <div className="userBoard_info">
                    <div className="userBoard_info_item">
                        <h5>{t("userBoard.full_name")}</h5>
                        <p>{fullName}</p>
                    </div>
                    <div className="userBoard_info_item">
                        <h5>{t("userBoard.class")}</h5>
                        <p>{nameOfClasses.join(", ")}</p>
                    </div>
                    <button className="userBoard__exportBtn" onClick={fireExportPdf}>
                        {t("report.export_pdf")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserBoard;
