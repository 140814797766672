import React from "react";

function IconSave(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5556 0H2.22222C0.988889 0 0 1 0 2.22222V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V4.44444L15.5556 0ZM10 17.7778C8.15556 17.7778 6.66667 16.2889 6.66667 14.4444C6.66667 12.6 8.15556 11.1111 10 11.1111C11.8444 11.1111 13.3333 12.6 13.3333 14.4444C13.3333 16.2889 11.8444 17.7778 10 17.7778ZM13.3333 6.66667H2.22222V2.22222H13.3333V6.66667Z" />
        </svg>
    );
}

export default IconSave;
