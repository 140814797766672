import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import useValues from "src/hooks/useValues";
import { questionTypes } from "src/api/containers/question";
import { fetchCategories } from "src/api/containers/category";
import { fetchTagsWithFiltering } from "src/api/containers/tag";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";

const FilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchQuestionList = () => {},
    handleCloseFilterMenu = () => {},
    excludeFields = [],
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        categories: [],
        tags: [],
        isFetchingCates: false,
        isFetchingTags: false, // "searching", true||false.
        isLoadingMoreTags: false,
        currPageForTags: 1,
    });
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.
    const timeoutIdGetCates = useRef(null);
    const timeoutIdGetTags = useRef(null);

    const getTypeName = (type) => {
        return t(`q.${type}`);
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            ...(!excludeFields.includes("owner_id")
                ? { owner_id: formData.owner_id === "all" ? undefined : formData.owner_id }
                : {}),
            category_id: formData.category_id,
            tag_name: formData.tag_name,
            type: formData.type,
            ...(!excludeFields.includes("is_publish") ? { is_publish: formData.is_publish } : {}),
            is_public: formData.is_public,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.owner_id &&
            newFilterParams.push({
                name: "owner_id",
                value: fetchParams.owner_id,
                labelName: t("question.owner"),
                labelValue: t(`question.owner_${fetchParams.owner_id === "all" ? "all" : "me"}`),
            });
        fetchParams.category_id &&
            newFilterParams.push({
                name: "category_id",
                value: fetchParams.category_id,
                labelName: t("shared.category"),
                labelValue:
                    values.categories.filter((cate) => {
                        return cate.id === fetchParams.category_id;
                    })[0].name || "",
            });
        fetchParams.tag_name &&
            newFilterParams.push({
                name: "tag_name",
                value: fetchParams.tag_name,
                labelName: t("shared.tags"),
                labelValue: fetchParams.tag_name,
            });
        fetchParams.type &&
            newFilterParams.push({
                name: "type",
                value: fetchParams.type,
                labelName: t("shared.type"),
                labelValue: getTypeName(fetchParams.type),
            });
        fetchParams.is_publish !== undefined &&
            newFilterParams.push({
                name: "is_publish",
                value: fetchParams.is_publish,
                labelValue: fetchParams.is_publish ? t("question_bank.done") : t("question_bank.draft"),
            });
        if (fetchParams.is_public !== undefined) {
            if (fetchParams.is_public === true || fetchParams.is_public === false) {
                fetchParams.organization_id = undefined;
                newFilterParams.push({
                    name: "is_public",
                    value: fetchParams.is_public,
                    labelValue: fetchParams.is_public ? t("shared.public") : t("shared.only_me"),
                });
            } else {
                fetchParams.organization_id = fetchParams.is_public;
                fetchParams.is_public = undefined;
                newFilterParams.push({
                    name: "is_public",
                    value: fetchParams.organization_id,
                    labelValue: scopeName,
                });
            }
        }
        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleFetchQuestionList({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    const handleSearchCategories = (keyword) => {
        clearTimeout(timeoutIdGetCates.current);
        if (typeof keyword === "string") {
            setValues({ ...values, categories: [], isFetchingCates: true });
            timeoutIdGetCates.current = setTimeout(() => {
                fetchCategories({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, categories: res.data, isFetchingCates: false });
                    }
                });
            }, 500);
        }
    };

    const handleSearchTags = (keyword) => {
        clearTimeout(timeoutIdGetTags.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                // tags: [],
                isFetchingTags: "searching",
            });
            const fetchParams = {
                slug: keyword,
                page: 1,
                // ...(form.getFieldValue("owner_id") && form.getFieldValue("owner_id") !== "all"
                //     ? { owner_id: user.id || "" }
                //     : {}),
            };
            timeoutIdGetTags.current = setTimeout(() => {
                fetchTagsWithFiltering(fetchParams).then((res) => {
                    if (res.status && res.data) {
                        setValues({
                            ...values,
                            tags: res.data,
                            isFetchingTags: false,
                            isLoadingMoreTags: false,
                            currPageForTags: 1,
                        });
                    }
                });
            }, 500);
        }
    };

    const handleScroll = (e) => {
        if (values.isLoadingMoreTags === true || values.isLoadingMoreTags === "disabled") {
            return;
        }

        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            setValues({ ...values, isLoadingMoreTags: true });
            const nextPageToLoad = values.currPageForTags + 1;
            const fetchParams = {
                slug: "",
                page: nextPageToLoad,
                // ...(form.getFieldValue("owner_id") && form.getFieldValue("owner_id") !== "all"
                //     ? { owner_id: user.id || "" }
                //     : {}),
            };
            fetchTagsWithFiltering(fetchParams).then((res) => {
                if (res.status === true) {
                    if (res.data?.length === 0) {
                        setValues({
                            ...values,
                            isLoadingMoreTags: "disabled",
                        });
                    } else {
                        setValues({
                            ...values,
                            isLoadingMoreTags: false,
                            currPageForTags: res.pagination?.current || nextPageToLoad,
                            tags: [...values.tags, ...res.data],
                        });
                    }
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreTags: false,
                    });
                }
            });
        }
    };

    const handleChangeSelectedOwner = (val) => {
        // const newPage = 1;
        // setValues({
        //     ...values,
        //     isFetchingTags: true,
        //     currPageForTags: newPage,
        //     tags: [],
        // });
        // fetchTagsWithFiltering({
        //     slug: "",
        //     page: newPage,
        //     // ...(val && val !== "all" ? { owner_id: user.id || "" } : {}),
        // }).then((res) => {
        //     if (res.status === true) {
        //         setValues({
        //             ...values,
        //             isFetchingTags: false,
        //             isLoadingMoreTags: false,
        //             currPageForTags: res.pagination?.current || newPage,
        //             tags: res.data || [],
        //         });
        //     } else {
        //         setValues({
        //             ...values,
        //             isFetchingTags: false,
        //         });
        //     }
        // });
    };

    useEffect(() => {
        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setValues({
                categories: res.data,
            });
        });

        fetchTagsWithFiltering({ slug: "", page: values.currPageForTags }).then((res) => {
            setValues({
                tags: res.data,
            });
        });
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            const fetchParams = {
                ...(!excludeFields.includes("owner_id") ? { owner_id: newFormData.owner_id } : {}),
                category_id: newFormData.category_id,
                tag_name: newFormData.tag_name,
                type: newFormData.type,
                is_public: newFormData.is_public,
                ...(!excludeFields.includes("is_publish") ? { is_publish: newFormData.is_publish } : {}),
            };
            // Scope:
            if (fetchParams.is_public === true || fetchParams.is_public === false) {
                fetchParams.organization_id = undefined;
            } else {
                fetchParams.organization_id = fetchParams.is_public;
                fetchParams.is_public = undefined;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            setCurrFilterParams(filterParams);
            handleFetchQuestionList({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid" layout="vertical">
            <Row gutter={[24, 12]}>
                {!excludeFields.includes("owner_id") && (
                    <Col xs={24} sm={12}>
                        <Form.Item name="owner_id" label={t("question.owner")}>
                            <Select
                                className="app-select"
                                placeholder={t("question.select_owner")}
                                allowClear
                                onChange={handleChangeSelectedOwner}
                            >
                                <Select.Option value={user.id || "me"}>{t("question.owner_me")}</Select.Option>
                                <Select.Option value="all">{t("question.owner_all")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12}>
                    <Form.Item name="type" label={t("shared.type")}>
                        <Select className="app-select" placeholder={t("shared.choose_type")} allowClear>
                            {Object.keys(questionTypes).map((item, itemIndex) => {
                                return (
                                    <Select.Option key={`q-${itemIndex}`} value={item}>
                                        {getTypeName(item)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="category_id" label={t("shared.category")}>
                        <Select
                            className="app-select"
                            placeholder={t("shared.search_and_select_category")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            // onSearch={(keyword) => {
                            //     handleSearchCategories(keyword);
                            // }}
                            // notFoundContent={
                            //     values.isFetchingCates === true ? (
                            //         <i>
                            //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                            //         </i>
                            //     ) : (
                            //         undefined
                            //     )
                            // }
                        >
                            {values.categories.map((item, index) => (
                                <Select.Option key={`cate-${index}`} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="tag_name" label={t("shared.tags")}>
                        <Select
                            className="app-select"
                            placeholder={t("shared.search_and_select_tags")}
                            optionFilterProp="children"
                            showSearch
                            onSearch={(keyword) => {
                                handleSearchTags(keyword);
                            }}
                            onPopupScroll={(e) => handleScroll(e)}
                            allowClear
                            notFoundContent={
                                values.isFetchingTags === true ? (
                                    <i>
                                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                    </i>
                                ) : values.isFetchingTags === "searching" ? (
                                    <React.Fragment></React.Fragment>
                                ) : undefined
                            }
                            dropdownClassName="app-select-dropdown"
                            dropdownRender={(menu) => (
                                <>
                                    {values.isFetchingTags === "searching" ? (
                                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                                        </div>
                                    ) : null}
                                    {menu}
                                </>
                            )}
                        >
                            <>
                                {values.tags.map((item, index) => (
                                    <Select.Option key={`tag-${index}`} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                                {values.isLoadingMoreTags === true ? (
                                    <Select.Option key={"load-more-tags"} value={""}>
                                        <Spin spinning={values.isLoadingMoreTags} style={{ marginRight: "10px" }} />
                                        {t("shared.loading")}...
                                    </Select.Option>
                                ) : null}
                            </>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="is_public" label={t("shared.public")}>
                        <Select className="app-select" placeholder={t("shared.choose_public")} allowClear>
                            <Select.Option value={true}>{t("shared.public")}</Select.Option>
                            <Select.Option value={false}>{t("shared.only_me")}</Select.Option>
                            {fParamsOrgMember?.organization_id ? (
                                <Select.Option value={fParamsOrgMember.organization_id}>
                                    {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                                </Select.Option>
                            ) : null}
                        </Select>
                    </Form.Item>
                </Col>

                {!excludeFields.includes("is_publish") && (
                    <Col xs={24} sm={12}>
                        <Form.Item name="is_publish" label={t("shared.status")}>
                            <Select className="app-select" placeholder={t("shared.choose_status")} allowClear>
                                <Select.Option value={true}>{t("question_bank.done")}</Select.Option>
                                <Select.Option value={false}>{t("question_bank.draft")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
