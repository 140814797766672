import "./ScoreStudent.scss";
import StudentFrame from "src/modules/components/StudentFrame";
import ClassImg from "src/assets/images/Rectangle5289.png";
import Button from "src/modules/components/Button";
import ReviewIcon from "src/assets/images/review-icon.svg";
import StudentHeader from "src/modules/components/StudentHeader";
import { Alert, Dropdown, Empty, Spin, Tag } from "antd";
import InputSearch from "src/modules/components/Input/InputSearch";
import FilterMenu from "./components/FilterMenu";
import { useFetch, useValues } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { fetchRecordsOfStudent } from "src/api/containers/record";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "src/reducers/general";

const ScoresStudent = () => {
    const [loadingRecord, dataRecord, paginationRecord, fetchRecord, refetchRecord] = useFetch(
        { page: 1, slug: "", perPage: 11 },
        fetchRecordsOfStudent
    );
    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        inputSearchQuestion: "",
        filterParams: [],
    });

    const [values, setValues] = useValues({
        listPages: 0,
        listOfRecords: [],
    });

    const isLastPage = useMemo(() => {
        const { current, pageSize, total } = paginationRecord;
        return current * pageSize >= total;
    }, [paginationRecord]);

    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchRecord({}, true);
    }, []);

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleClickButton = (id) => {
        navigate(
            generate("score", {
                id,
            }) + "?prev=scores"
        );
    };

    const handleLoadMore = () => {
        fetchRecord(
            {
                page: paginationRecord.current + 1,
            },
            true
        );
    };

    const handleSearch = () => {
        if (filterSettings) {
            fetchRecord({ page: 1, slug: filterSettings.inputSearchQuestion });
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("score"));
    }, []);

    useEffect(() => {
        if (dataRecord.length >= 0) {
            if (paginationRecord.current === 1) {
                setValues({
                    ...values,
                    listPages: paginationRecord.current,
                    listOfRecords: dataRecord,
                });
            } else if (paginationRecord.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: paginationRecord.current,
                    listOfRecords: [...values.listOfRecords, ...dataRecord],
                });
            }
        }
    }, [dataRecord, paginationRecord]);

    const renderTestType = (record) => {
        if (record.assignment?.type === "test_input") return <Tag color="green">{t("entry_test.entry_test")}</Tag>;
        else {
            return record?.assignment?.is_test ? (
                <Tag color="red">{t("assignment.exam")}</Tag>
            ) : (
                <Tag color="geekblue">{t("assignment.assignment")}</Tag>
            );
        }
    };

    return (
        <div className="homeStudent_wrapper">
            <div className="homeStudent_header">
                <StudentHeader title={t("assignment.your_score")}>
                    <div className="homeStudent_controlPanel">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_question")}
                                onChange={(e) =>
                                    setFilterSettings({
                                        ...filterSettings,
                                        inputSearchQuestion: e.target.value,
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="homeStudent_controlPanel-search">
                            <div className="homeStudent_controlPanel_title">{t("assignment.filter")}</div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetch={fetchRecord}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottom"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="homeStudent_filter">
                                    {filterSettings.filterParams?.length > 0 ? (
                                        filterSettings.filterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                >
                                                    {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                        fKey.labelValue || ""
                                                    }`}
                                                </Tag>
                                            );
                                        })
                                    ) : (
                                        <div>{t("assignment.filter_option")}</div>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </StudentHeader>
            </div>
            <StudentFrame title={t("assignment.your_score")}>
                <Spin spinning={loadingRecord} tip="loading">
                    <div className="cardList">
                        {values.listOfRecords.map((record) => {
                            return (
                                <div key={record.id} className="cardItem">
                                    <div className="cardItem_inner">
                                        <div className="cardItem_thumbNail">
                                            <img src={ClassImg} />
                                        </div>
                                        <div className="cardItem_info">
                                            <h3>{record?.assignment?.name || t("shared.unknown")}</h3>
                                            <p>
                                                {t("shared.type")}: {renderTestType(record)}
                                            </p>

                                            <p>
                                                {t("assignment.subject")}:{" "}
                                                {record?.assignment?.category?.name || t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.class")}:{" "}
                                                {record?.assignment?.class?.name || t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.class_code")}:{" "}
                                                {record?.assignment?.class?.code || t("shared.unknown")}
                                            </p>

                                            <div className="cardItem_scoreWrapper">
                                                <span className="cardItem_score">{record?.score || 0}</span>
                                                <span className="cardItem_unit">{t("assignment.score")}</span>
                                            </div>
                                            <div className="cardItem_board">
                                                {t("number_of_correct_answers")}{" "}
                                                <span>
                                                    {record?.count_correct_sentences || "0"}/
                                                    {record?.count_sentences || "0"}
                                                </span>
                                            </div>
                                            <div style={{ marginTop: "15px" }}>
                                                <Button
                                                    onClick={() => handleClickButton(record?.id)}
                                                    title={t("assignment.review")}
                                                    icon={ReviewIcon}
                                                    type="primary"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {Boolean(values?.listOfRecords.length && !isLastPage && !loadingRecord) && (
                            <div className="cardItem loadmore_btn" onClick={handleLoadMore}>
                                <div className="cardItem_inner">{t("assignment.load_more")}</div>
                            </div>
                        )}
                    </div>
                    {!values.listOfRecords.length && <Empty description={t("message.empty_data")} />}
                </Spin>
            </StudentFrame>
        </div>
    );
};

export default ScoresStudent;
