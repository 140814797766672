import { useTranslation } from "react-i18next";
import "./ButtonSubscription.scss";
import { ReactComponent as SvgArrowRight } from "src/assets/images/app-icons/arrow-right.svg";
// import { ReactComponent as SvgVipCrown } from "src/assets/images/account/vip-crown.svg";
import SvgVipCrownIMG from "src/assets/images/account/vip-crown.svg";

const ButtonSubscription = ({ className, ...rest }) => {
    const { t } = useTranslation();

    return (
        <span className={"subsc-btn" + (className ? ` ${className}` : "")} {...rest}>
            <span className="subsc-btn-icon">
                <img src={SvgVipCrownIMG} />
            </span>
            <span className="subsc-section">
                <span className="subsc-btn-name">{t(`account.upgrade_now`)}</span>
                <SvgArrowRight />
            </span>
        </span>
    );
};

export default ButtonSubscription;
