import React, { useEffect, useState } from "react";
import ExamChart from "../ExamChart";
import QuestionNumberList from "src/modules/components/NumberList/QuestionNumberList";
import "./ExamChartController.scss";

function ExamChartController(props) {
    const {
        className = "",
        examData = [],
        timeData = [],
        limitYColEachPage = 10,
        yLabel = { visible: false, labelLeft: "", labelRight: "" },
        ...rest
    } = props;

    const [paginationSettings, setPaginationSettings] = useState({
        currPage: 0,
        totalPages: 0,
        currExamData: [],
    });

    const handleSelectPage = (selectedPage) => {
        setPaginationSettings({
            ...paginationSettings,
            currPage: selectedPage,
        });
    };

    const generatePageExamData = () => {
        const newExamData = [];
        for (let i = 0; i < paginationSettings.currExamData.length; i++) {
            if (
                i >= (paginationSettings.currPage - 1) * limitYColEachPage &&
                i < (paginationSettings.currPage - 1) * limitYColEachPage + limitYColEachPage
            ) {
                if (paginationSettings.currExamData[i].length) {
                    newExamData.push(...paginationSettings.currExamData[i]);
                } else {
                    newExamData.push(paginationSettings.currExamData[i]);
                }
            }
        }
        return newExamData;
    };

    const generatePageTimeData = () => {
        const newTimeData = [];
        for (let i = 0; i < timeData.length; i++) {
            if (
                i >= (paginationSettings.currPage - 1) * limitYColEachPage &&
                i < (paginationSettings.currPage - 1) * limitYColEachPage + limitYColEachPage
            ) {
                newTimeData.push(timeData[i]);
            }
        }
        return newTimeData;
    };

    useEffect(() => {
        if (examData?.length > 0) {
            // 1. Prepare data:
            const separatedItems = [];
            let filterKey = "";
            let from = -1;
            let to = -1;
            examData?.forEach((item, i) => {
                if (item.xLabel !== filterKey) {
                    // Group multiple items with the same xLabel:
                    if (from !== -1 && to !== -1) {
                        separatedItems.push(examData.slice(from, to + 1));
                        to = -1;
                    }
                    // Consider the current item as a group, if:
                    // - xLabel of the next item is not the same as current item.
                    // - The current item is the last element.
                    filterKey = item.xLabel;
                    from = i;
                    if (i < examData.length - 1 && examData[i + 1].xLabel !== filterKey) {
                        separatedItems.push(item);
                    } else if (i === examData.length - 1) {
                        separatedItems.push(item);
                    }
                } else {
                    to = i;
                    if (i === examData.length - 1) {
                        separatedItems.push(examData.slice(from, to + 1));
                    }
                }
            });

            // 2. Set up data to make pagination:
            setPaginationSettings({
                currPage: separatedItems.length > 0 ? 1 : 0,
                totalPages: Math.ceil(separatedItems.length / limitYColEachPage),
                currExamData: separatedItems,
            });
        }
    }, [examData]);

    return (
        <div
            className={`exam-chart-controller-wrapper${className ? " " + className : ""}${
                yLabel.visible === true ? " with-y-label" : ""
            }`}
        >
            <ExamChart {...rest} examData={generatePageExamData()} timeData={generatePageTimeData()}></ExamChart>

            {paginationSettings.totalPages > 1 ? (
                <div className="control-menu">
                    <QuestionNumberList
                        type="horizontal"
                        itemType="button"
                        order={paginationSettings.currPage}
                        numberOfQuestions={paginationSettings.totalPages}
                        onChangeSelectedQuestion={(val) => {
                            handleSelectPage(val);
                        }}
                    ></QuestionNumberList>
                </div>
            ) : null}

            {yLabel.visible === true ? (
                <div className="y-labels-wrapper">
                    <div className="y-label y-label-left">{yLabel.labelLeft || ""}</div>
                    <div className="y-label y-label-right">{yLabel.labelRight || ""}</div>
                </div>
            ) : null}
        </div>
    );
}

export default ExamChartController;
