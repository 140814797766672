import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchCategories } from "src/api/containers/category";
import useValues from "src/hooks/useValues";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";
import moment from "moment";

const CompetitionFilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchAssignmentList = () => {},
    handleCloseFilterMenu = () => {},
    classData = [],
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        categories: [],
        optionListSubject: [],
        isFetchingCates: false,
        currFilterParams: filterParamsInitial?.length ? filterParamsInitial : [],
    });

    const timeoutIdGetCates = useRef(null);

    // Api
    // const [classLoading, classData, classPagination, fetchClass, reFetchClass] = useFetch(
    //     { noPagination: 1, relations: ["categories"] },
    //     fetchClasses
    // );

    const handleSubmit = () => {
        const fetchParams = form.getFieldsValue();
        // const fetchParams = {
        //     category_id: formData.category_id,
        //     is_test: formData.is_test,
        //     organization_id: formData.organization_id,
        // };
        // Save new applied filter values:

        const newFilterParams = [];
        if (fetchParams.begin_date) {
            // fetchParams.begin_date = moment(fetchParams.begin_date).format("DD-MM-YYYY");
            newFilterParams.push({
                name: "begin_date",
                value: fetchParams.begin_date,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.begin_date).format("DD-MM-YYYY"),
            });
        }

        fetchParams.end_date &&
            newFilterParams.push({
                name: "end_date",
                value: fetchParams.end_date,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.end_date).format("DD-MM-YYYY"),
            });

        fetchParams.class_id &&
            newFilterParams.push({
                name: "class_id",
                value: fetchParams.class_id,
                labelName: t("class.name"),
                labelValue:
                    classData?.filter((item) => {
                        return item.id === fetchParams.class_id;
                    })[0].name || "",
            });

        fetchParams.category_id &&
            newFilterParams.push({
                name: "category_id",
                value: fetchParams.category_id,
                labelName: t("shared.category"),
                labelValue:
                    values.categories.filter((cate) => {
                        return cate.id === fetchParams.category_id;
                    })[0].name || "",
            });
        fetchParams.organization_id !== undefined &&
            newFilterParams.push({
                name: "organization_id",
                value: fetchParams.organization_id,
                labelValue: scopeName,
            });
        setValues({
            currFilterParams: newFilterParams,
        });
        // Fetch assignment list by the corresponding filter values:
        handleFetchAssignmentList({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    const getListSubject = (classList, classId) => {
        for (let i = 0; i < classList.length; i++) {
            if (classId === classList[i].id) {
                setValues({
                    optionListSubject: classList[i].categories || [],
                });
                break;
            }
        }
    };

    const handleChangeClass = (class_id) => {
        getListSubject(classData, class_id);
    };

    const handleSearchCategories = (keyword) => {
        clearTimeout(timeoutIdGetCates.current);
        if (typeof keyword === "string") {
            setValues({ categories: [], isFetchingCates: true });
            timeoutIdGetCates.current = setTimeout(() => {
                fetchCategories({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ categories: res.data, isFetchingCates: false });
                    }
                });
            }, 500);
        }
    };

    useEffect(() => {
        // fetchClass({
        //     organization_id: fParamsOrgMember?.organization_id,
        // });

        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setValues({
                categories: res.data,
            });
        });
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (values.currFilterParams.length) {
                for (let i = 0; i < values.currFilterParams.length; i++) {
                    newFormData[values.currFilterParams[i].name] = values.currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== values.currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                category_id: newFormData.category_id,
                class_id: newFormData.class_id,
                organization_id: newFormData.organization_id,
                begin_date: newFormData.begin_date,
                end_date: newFormData.end_date,
            };
            setValues({
                currFilterParams: filterParams,
            });
            handleFetchAssignmentList({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <div className="filter-competitions">
            <Form form={form} className="form form-full-label filter-menu layout-grid " layout="vertical">
                <Row gutter={[10, 0]}>
                    <Col span={12}>
                        <Form.Item name="class_id" label={t("class.name")}>
                            <Select
                                className="app-select"
                                placeholder={t("class.select_class")}
                                allowClear
                                onChange={(item) => handleChangeClass(item)}
                            >
                                {classData.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="category_id" label={t("class.subject")} labelCol={24}>
                            <Select
                                className="app-select"
                                placeholder={t("shared.choose_category")}
                                optionFilterProp="children"
                                showSearch
                                allowClear
                                // onSearch={(keyword) => {
                                //     handleSearchCategories(keyword);
                                // }}
                                // notFoundContent={
                                //     values.isFetchingCates === true ? (
                                //         <i>
                                //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                //         </i>
                                //     ) : (
                                //         undefined
                                //     )
                                // }
                            >
                                {form.getFieldValue("class_id")
                                    ? values.optionListSubject?.map((item) => (
                                          <Select.Option key={item.id} value={item.id}>
                                              {item.name}
                                          </Select.Option>
                                      ))
                                    : values.categories?.map((item) => (
                                          <Select.Option key={item.id} value={item.id}>
                                              {item.name}
                                          </Select.Option>
                                      ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[10, 0]}>
                    <Col span={12}>
                        <Form.Item name="begin_date" label={t("report.dateFrom")}>
                            <DatePicker
                                placeholder={t("report.please_select_date")}
                                className=" ant-picker filter-person_input"
                                // suffixIcon={<img src={CalanderIcon} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="end_date" label={t("report.dateTo")}>
                            <DatePicker
                                placeholder={t("report.please_select_date")}
                                className="filter-person_input"
                                // suffixIcon={<img src={CalanderIcon} />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {fParamsOrgMember?.organization_id && (
                    <Row gutter={[10, 0]}>
                        <Col span={24}>
                            <Form.Item name="organization_id" label={t("shared.scope")}>
                                <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                                    <Select.Option value={fParamsOrgMember.organization_id}>
                                        {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                <div className="filter-menu-footer">
                    <CustomButton
                        type="grey"
                        title={t("shared.cancel")}
                        icon={<Icon name="icon-cross-thick" />}
                        onClick={() => handleCloseFilterMenu(false)}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        title={t("shared.apply")}
                        icon={<Icon name="icon-tick" />}
                        onClick={handleSubmit}
                    ></CustomButton>
                </div>
            </Form>
        </div>
    );
};

export default CompetitionFilterMenu;
