import { en_questions } from "./questions/en";

export default {
    // NEW:
    ...en_questions,

    role: {
        teacher: "Teacher",
        student: "Student",
    },

    // Actions:
    download_sample_file: "Download sample file",
    download_sample_file_to_import: "Download sample file for importing",

    // Fields:
    no_name_yet: "No name yet",
    too_many_request: "Too many request",
    number_of_correct_answers: "Number of correct answers",
    scored_manually: "Scored manually",
    // Actions:
    update_info: "Update information",
    view: "View",
    new: "New",

    // OLD:
    manage_role: {
        role: "Role",
        name: "Name",
        description: "Description",
        permissions: "Permissions",
        // Actions:
        find_role: "Find role",
        add_role: "Add role",
        update_role: "Update role",
        select_roles: "Select roles",
    },

    help: {
        help: "Help",
        next: "Next",
        pre: "Previous",
        nodata: "No data",
        category: "Category",
        collapse_sidebar: "Collapse sidebar",
    },

    shared: {
        // Title:
        warning: "Warning",
        // Info:
        fullname: "Full name",
        phonenumber: "Phone number",
        message: "Message",
        // Reactions:
        reactions: "Reactions",
        reaction_count: "Number of reactions",
        clone_count: "Number of clones",
        // Scope:
        scope: "Scope",
        public: "Public",
        private: "Private",
        only_me: "Only me",
        team: "Group",
        organization: "Organization",
        local_of_team: "Group scope",
        local_of_organization: "Organization scope",
        // Others:
        no_data: "No data",
        exit: "Exit",
        loading: "Loading",
        redirecting: "Redirecting",
        searching: "Searching",
        setting: "Setting",
        signout: "Sign out",
        save: "Save",
        back: "Back",
        confirm: "Confirm",
        clone: "Clone",
        apply: "Apply",

        category: "Category",
        tags: "Tags",
        owner: "Owner",
        question: "Question",
        type: "Type",
        status: "Status",
        created_at: "Date created",
        updated_at: "Date modified",
        choose_category: "Choose category",
        choose_tags: "Choose tags",
        choose_type: "Choose type",
        choose_status: "Choose status",
        choose_public: "Choose public",
        score: "Score",
        total_score: "Total score",
        answer: "Answer",

        save_draft: "Save as draft",
        publish: "Publish",
        add_new_question: "Add new question",
        add_new_exam: "Add new exam",
        find_question: "Find question",
        find_exam: "Find exam",
        find_assignment: "Find assignment",
        add_assignment: "Add assignment",
        option_filter: "Filter",
        unknown: "Unknown",
        search_and_select_category: "Search and select a category...",
        search_and_select_tags: "Search and select a tag...",
        search_and_select_classes: "Search and select a class...",
        mark: "Mark",
        copy_invite_link: "Copy invite link",
        copy_invite_link_success: "Link has been copied to clipboard!",
        or: "or",
        copy_the_link_below: "Copy the link below",
        active: "Active",
        inactive: "Inactive",
        all: "All",
        is_removed: "Removed",
        // Fields:
        note: "Note",
        // Actions:
        ok: "Ok",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        reset: "Reset",
        add_new: "Add New",
        update: "Update",
        edit: "Edit",
        delete: "Delete",
        create: "Create",
        copy: "Copy",
        submit: "Submit",
        input: "Input",
        view_detail: "View detail",
        upate_image: "Update image",
        select_scope: "Select a scope",
        contact_us: "Contact Us",
        // Confirm:
        confirm_cancel: "Cancel",
        confirm_reset: "Reset",
        confirm_update: "Update",
        confirm_delete: "Delete",
        confirm_leave: "Leave",
        confirm_action: "Confirm",
        msg_confirm_cancel: "Are you sure to cancel",
        msg_confirm_reset: "Are you sure to reset?",
        msg_confirm_delete: "Are you sure to delete",
        msg_confirm_leave: "Are you sure to leave?",
        msg_confirm_action: "Are you sure to do this action",
        msg_confirm_action_of: "Are you sure to",
        msg_confirm_action_of_demo_account: "Are you sure to login with demo account",
        msg_confirm_action_of_main_account: "Are you sure to get back to your main account",
        // Message:
        warning_demo_account: "You are logged in with a demo account!",
        you_dont_have_permission: "You don't have permission to do this!",
        input_fullname: "Please enter your full name",
        input_phonenumber: "Please enter your phone number",
        input_message: "Please enter a message",
        //user
        gender: "Gender",
        male: "Male",
        female: "Female",
        find: "Search...",
        select_image: "Select image",

        //qrcode
        download_qr: "Download QR code",
        set_correct_answer: "Set correct answer",
        minute: "minute",
        result: "Result",
        ascending: "Ascending",
        descending: "Descending",
        something_went_wrong: "Oops! Something went wrong.",
        passed: "Passed",
        failed: "Failed",
        order: "Order",
        input_order: "Input order",
        close_modal: "Close",
        processing: "Processing",
    },

    login: {
        welcome_back: "Welcome back",
        login_to_experience: "Login to experience",
        enter_your_email: "Enter your email",
        password: "Password",
        retype_password: "Retype password",
        forget_password: "Forget password",
        remember: "Remember me",
        or_login_with: "Or login with",
        or_register_with: "Or register with",
        login_with_facebook: "Facebook",
        login_with_google: "Google",
        sign_in: "Sign in",
        sign_up: "Sign up",
        forgot_password: "Forgot password",
        dont_have_account: "Don't have an account?",
        free_register: "Free register",
        start_signup_free: "Start creating a free account!",
        intro: "Join the growing teacher community using Test Online methods to promote learning",
        who_are_you: "Who are you?",
        teacher: "Teacher",
        student: "Student",
        sign_up_now: "Sign up now!",
        please_fill_in_the_form: "Please fill in the form",
        still_remember: "Still remember password?",
        send: "Send",
        enter_mail_to_reset_password: "Enter your email to reset password",
        email_sent: "Email sent!",
        send_error: "Fail to send mail!",
        check_email: "Please check your email and click the link to reset password",
        havent_received_link: "Haven't received the link?",
        resend_link: "Resend link",
        reset_password: "Create new password",
        rule_new_password: "New password must be different from the old password",
        receive_code: "Receive verify code in your email",
        new_password: "New password",
        retype_new_password: "Retype new password",
        save_password: "Save password",
        verify_email: "Verify email",
        please_verify: "Please input the code sent to your email",
        enter_code: "Enter code",
        verify: "Verify",
        verify_code: "Verify code",
        open_account_for: "Open account for",
        account_activated: "This account is already activated",
        invalid_path: "Invalid path",
        update_your_password: "Update your new password",
        update_password: "Update password",
    },

    header: {
        go_back: "Go back",
        modify: "Modify",
        preview: "Preview",
        question_bank: "Question bank",
        exam_bank: "Exam bank",
        say_hello: "Hello",
        assignment: "Assignment",
        organization: "Organization",
        my_exam: "My Exam",
        my_questions: "My Question",
        help: "Help",
        manage_student: "Student management",
        manage_teacher: "Teacher management",
        manage_employee: "Employee management",
        manage_role: "Decentralization",
        organization_profile: "Organization profile",
        dashboard: "Dashboard",
    },

    // Used for nav menu:
    nav: {
        notification: "Notification",
        account: "Account",
        profile: "Profile",
        logout: "Logout",
        demo_account: "Demo account",
        main_account: "Main account",

        // Role - teacher:
        class: "Manage classes",
        question_bank: "Question bank",
        exam_bank: "Exam bank",
        assignment: "Assignment",
        learning_progress: "Learning progress",
        personal_leaning_progress: "Personal learning progress",
        my_storage: "My storage",
        branch: "Branch",
        organization: "Organization",
        my_resources: "My Resources",
        my_resources_question: "Questions",
        my_resources_exam: "Exam",

        // Role - student:
        classes: "Classes",
        assignments: "Assignments",
        scores: "Scores",
        gradebook: "Gradebook",

        admin_dashboard: "Admin dashboard",
        admin_dashboard_team: "Team management",
        admin_dashboard_org: "Organization management",
        ranking: "Ranking",
        competitions: "Competitions",
        feedback: "Feedback",
        home: "Dashboard",
        setting: "Setting",
        entry_test: "Entry test",
    },

    sidebar: {
        hello: "Hello",
        class: "Manage class",
        question_bank: "Question bank",
        exam_bank: "Exam bank",
        assignment: "Assign exam",
        logout: "Logout",
        profile: "Profile",
        my_storage: "My storage",
        assign_homework: "Assign homework",
        assignments: "Assignments",
        scores: "Scores",
        classes: "Classes",
        notification: "Notification",
        account: "Manage account",
        report: "Report",
        learning_progress: "Learning progress",
        my_exam: "My Exam",
        my_questions: "My Question",
        my_resources: "My Resources",
        my_resources_question: "My Questions",
        my_resources_exam: "My Exam",
        demo_account: "Demo account",
        main_account: "Main account",
        // Organization management:
        organization: "Organization",
        organization_detail: "Organization details",
        manage_class: "Class management",
        manage_student: "Student management",
        manage_teacher: "Teacher management",
        manage_employee: "Employee management",
        manage_branch: "Branch management",
        manage_capacity: "Capacity mangement",
        manage_data: "Storage mangement",
        manage_role: "Decentralization",
        organization_detail_team: "Team's information",
        organization_detail_org: "Organization's infomation",
        manage_ranking: "Ranking",
        manage_competitions: "Competitions",
    },

    lang: {
        english: "English",
        vietnamese: "Tiếng Việt",
    },

    select_question: {
        // Messages:
        null: "Have not selected the type",
        tutorial_tooltip: "Click me to display instruction for question types",

        scoring_manual: "Manual scoring",
        scoring_auto: "Automatic scoring",
    },

    question: {
        passage: "Passage",
        question: "Question",
        show_answer: "Show answer",
        empty_answer: "The answer has been left blank",
        question_multiple_choice: "Multiple choice",
        question_multiple_choice_boolean: "Multiple choice (boolean)",
        question_multiple_choice_table: "Multiple choice (table)",
        question_fill_in_the_blanks: "Fill in the blanks (select)",
        question_fill_in_the_blanks_text: "Fill in the blanks (write)",
        question_fill_in_the_blanks_drag_drop: "Fill in the blanks (drag drop)",
        question_writing: "Writing",
        question_passage: "Passage",
        question_sort: "Sort",
        question_drag_drop: "Drag drop",
        question_drag_drop_group: "Drag drop group",
        question_drag_drop_group_order_by: "Drag drop group (order by)",
        question_highlight: "Highlight",
        question_highlight_image: "Highlight Image",
        header_drag_drop_group: "Drag drop group",
        header_drag_drop_group_order_by: "Drag drop group (order by)",
        header_multiple_choice: "Multiple choice question",
        header_multiple_choice_table: "Multiple choice (table)",
        header_multiple_choice_boolean: "Multiple choice (boolean)",
        header_fill_in_the_blanks: "Fill in the blanks (select)",
        header_fill_in_the_blanks_text: "Fill in the blanks (write)",
        header_fill_in_the_blanks_drag_drop: "Fill in the blanks (drag drop)",
        header_highlight: "Highlight",
        header_highlightImage: "Highlight Image",
        hint_create_blanks_1: "Press",
        hint_create_blanks_2: "button to add a blank",
        hint_highlight_1: "Highlight (word, sentence, paragraph) then click the",
        hint_highlight_2: "button to choose the correct answer",
        header_writing: "Writing",
        header_passage: "Passage",
        header_sort: "Sort",
        header_drag_drop: "Drag drop",
        header_passage_add_child: "Add passage",
        answer: "Answer",
        group: "Group",
        score: "Score",
        publishing_status: "Publishing status",
        private: "Private",
        public: "Public",
        program: "Program",
        level: "Level",
        category: "Category",
        tags: "Tags",
        input_answer: "Input answer",
        select_answer: "Select an answer",
        select_question_type: "Select a question type",
        select_question_group: "Select a question group",
        select_category: "Select category",
        select_tags: "Select tags",
        input_new_tag: "New tag name...",
        input_new_tag_color: "Tag color (hex)",
        required_publishing_status: "You haven't selected publishing status!",
        your_answer: "Your answer",
        correct_answer: "Correct answer",
        question_number_list: "List of questions",
        owner: "Owner",
        owner_me: "Me",
        owner_all: "All",
        select_owner: "Select the owner",
        message_help_group: "This is the column used to group answers",
        message_choose_the_correct_answer: "Choose correct answer of group",
        message_shuffle: "Answers will be shuffle when students do the test",
        no_question: "No questions",
        help_highlight:
            "Highlight (word, sentence, paragraph) then click the Highlight button to choose the correct answer",
        options: "Options",
        method: "Method",
        correct: "Correct",
        incorrect: "Incorrect",
    },

    question_panel: {
        question: "Question",
        write_here: "Write here",
    },

    question_writing: {
        word_limit: "Word limit",
    },

    question_fillblanks: {
        add_answer: "Add an answer",
        require_answers: "Answer fields are required",
        is_require_uppercase: "Uppercase and lowercase validation",
    },

    question_passage: {
        add_paragraph: "Add a paragraph",
        add_question: "Add a question",
        quick_add_question: "Quick add question",
        heading: "Heading",
        title: "Title",
        placeholder_heading: "Passage's heading",
        placeholder_title: "Passage's title",
        require_question_data: "Question data should not be empty!",
        btn_toggle_note_extend: "Click on the arrow to expand the worksheet",
        btn_toggle_note_down: "Click on the arrow to narrow the worksheet",
    },

    question_dragdrop: {
        add_answer: "Add an answer",
        require_corresponding_answers: "Please fill in the corresponding answer",
        require_answers: "Please fill in answer",
    },

    question_sort: {
        add_answer: "Add an answer",
        require_answers: "Answer fields are required",
    },

    create_multiple_choice: {
        answer: "Answer",
        blank_answer: "Please enter all the answers",
        blank_result: "Please enter choose at least one result",
        add_success: "New question has been added",
        add_error: "Fail to add new question",
        save_as_draft_success: "Save successfully",
        save_as_draft_error: "Fail to save as draft",
    },

    class: {
        class: "Class",
        list_class: "Class list",
        active: "Active classes",
        inactive: "Inactive classes",
        code: "Class code",
        name: "Class name",
        subject: "Subject",
        begin_date: "Begin date",
        end_date: "End date",
        owner: "Owner",
        teacher: "Teacher",
        teachers: "Teachers",
        total_student: "Total student",
        no_teacher: "No teacher",
        load_more_class: "Load more",
        add_class: "Add class",
        update_class: "Update class",
        add_student: "Add student",
        add_teacher: "Add teacher",
        invite_student: "Invite student",
        invite_teacher: "Invite teacher",
        select_student: "Select student",
        select_teacher: "Select teacher",
        upload_student_list: "Upload a list of student",
        manage_class: "Manage classes",
        manage_student: "Manage students",
        manage_exam: "Manage assignments",
        student_name: "Student's name",
        phone_number: "Phone number",
        email: "Email",
        gender: "Gender",
        male: "Male",
        female: "Female",
        unknown: "Unknown",
        status: "Status",
        password: "Password",
        add_exam: "Add new exam",
        assign_exam: "Assign exam",
        exam_name: "Name",
        exam_name2: "Name",
        exam_name_description: "You can choose an another name for the assignment, or it will have a default name",
        asgmt_name: "Name",
        max_score: "Max score",
        submission: "Number of submissions",
        graded: "Number of graded submissions",
        edit_list: "Edit list",
        done: "Done",
        student_amount: "Number of students",
        confirm_delete: "Are you sure you want to delete?",
        will_delete: "will be removed from the list. Are you sure?",
        input_class_code: "Input class code",
        input_class_name: "Input class name",
        input_begin_date: "Input start date",
        input_end_date: "Input end date",
        select_subjects: "Select subjects",
        input_teacher_name: "Input teacher name",
        input_exam_name: "Input a new name for the exam",
        input_asgmt_name: "Input a new name for the assignment",
        select_exam: "Select exam",
        select_asgmt: "Select assignment",
        select_class: "Select class",
        find_and_select_class: "Find and select class",
        duration: "Duration",
        input_time: "Minutes",
        exam: "Exam",
        assignment_name: "Assignment name",
        ownership: "Ownership",
        all: "All",
        me: "Mine",
        mark_assignment: "Mark assignment",
        learning_progress: "Learning progress",
        report: "Report",
        start_time: "Start time",
        end_time: "End time",
        time_not_limited: "Indefinitely",
        count_correct_sentences: "Correct",
        // Actions:
        find_class: "Find class",
        leave_class: "Leave class",
        remove_student: "Remove student",
        remove_teacher: "Remove teacher",
        confirm_leave_class: "Are you sure want to leave the class?",
        confirm_remove_student: "Are you sure want to remove student?",
        confirm_remove_teacher: "Are you sure want to remove teacher?",
        change_class_image: "Change class image",
        add_user: "Add users",
        import_user: "Import users",
        // Invitation:
        send_invitation: "Invite",
        send_invitation_success: "Invitation sent successfully",
        send_invitation_error: "Failed to send invitation",
        you_are_not_teacher: "You are not teacher",
        you_are_not_student: "You are not student",
        // Invite teacher:
        teacher_email: "Teacher's email",
        add_teacher_not_others: "Only teacher can join the class",
        add_teacher_success: "Add teacher successfully",
        add_teacher_error: "Failed to add teacher",
        // Invite student:
        add_student_not_others: "Only student can join the class",
        add_student_success: "Add student successfully",
        add_student_error: "Failed to add student",
        //branch
        branch: "Branch",
        select_branch: "Select branch",
        organization: "Organization",

        no_class: "No classes",
        chanage_password: "Change pasword",

        official_class: "Official class",
        intended_class: "Intended class",
        intended_class_note:
            "The intended class is the class used to organize competitions to place students in the official class",
        merge_class: "Merge class",
        class_type: "Type",
        select_class_type: "Select type",
    },

    statistical: {
        overview: "Overview",
        student: "Student",
        question: "Question",
        choose_all: "CHOOSE ALL",
        filter_by_status: "FILTER BY STATUS",
        assigned_student: "Assigned students",
        not_submit: "Have not submitted",
        doing: "Doing",
        submitted: "Submitted",
        graded: "Graded",
        progress: "Progress",
        submit_at: "Submit at",
        question_grade: "Question grade",
    },

    question_bank: {
        done: "Done",
        draft: "Draft",
        view: "View",
        copy: "Make a copy",
        choose_option: "Choose an option:",
        personal_learning_progress: "Personal learning progress",
    },

    exam_bank: {
        name: "Name",
        owner: "Owner",
        list_question: "List question",
        add_question: "Add question",
        total_score: "Total score",
        add_question_to_exam: "Add questions to exam",
        question_list_is_empty: "Question list is empty",
        create_exam_success: "New exam has been created",
        create_exam_error: "Fail to create new exam",
        score_is_empty: "Score is empty",
        create_question: "Create question",
        up: "Up",
        down: "Down",
    },

    exam_result: {
        count_correct_answers: "Number of correct answers",
        your_assignment: "Your assignment",
        comment: "Comment",
        teacher_comment: "Teacher's comment",
        no_comment: "No comment",
    },

    multiple_choice: {
        index: "No.",
    },

    message: {
        content_highlight_not_valid: "Content highlight is not valid",
        please_choose_correct_answer: "Please choose correct answer",
        you_have_chosen_this_correct_answer: "You have chosen this correct answer",
        empty_data: "Empty data",
        warning_missing_fields: "You must fill out the form",
        warning_duplicate_answer: "You are using two identical answers",
        warning_duplicate_title: "You are using two identical titles",
        warning_duplicate_group: "You are using two identical groups",
        warning_answer_duplication_found: "Answer duplication found",
        warning_answer_empty: "Answers are require",
        warning_require_field: "Field is required",
        warning_require_question: "Exam should have at least than one question ",
        title_require_more_than_two: "Title needs to be more than two",
        required: "Please fill out this field!",
        free_text: "Please input or delete this field!",
        min0: "Input value must be greater than 0!",
        not_found: "Data not found!",
        add_success: "New added success!",
        add_error: "New add failed!",
        update_success: "Update successful!",
        update_error: "Update failed!",
        delete_success: "Delete successful!",
        delete_error: "Delete failed!",
        submit_success: "Submit successful!",
        submit_error: "Submit failed!",
        sth_wrong: "Oh, something went wrong!",
        clone_success: "Copy successful!",
        clone_error: "Copy failed!",
        password_rule: "Password must be at least 4 characters long",
        password_not_match: "Password does not match",
        invalid_email: "Invalid email address",
        register_success: "Sign up successful!",
        register_error: "Sign up failed!",
        login_error: "Wrong email or password!",
        login_fail: "Login fail",
        third_party_login_error: "Login failed!",
        err_begin_date_is_greater: "End date must be greater than begin date!",
        email_sent: "Email sent!",
        reset_success: "Reset password successful!",
        reset_error: "Reset password failed!",
        mark_success: "Grade successful!",
        mark_error: "Grade failed!",
        login_success: "Login successful!",
        login_demo_account_success: "Login successful (demo account)!",
        login_demo_account_error: "Failed to login with demo account!",
        please_check_role: "Please check role!",
        logout_success: "Logout successful!",
        require_less_than_anwers: "Answers must be have less than two",
        require_less_than_groups: "Groups must be have less than two",
        require_fill_in_the_blank: "Fill blank must be have least one",
        // Question:
        answer_empty: "The answer has been left empty",
        // File manager:
        file_extension_supported: "Supported file extension",
        please_try_again_later: "Please try again later",
        please_try_another_file_extension: "Please try another file extension",
        err_can_not_upload_files: "Error! Can not upload files",
        err_file_extension_not_valid: "Error! File extension is not valid",
        verify_success: "Verification Successful",
        an_error_occurred: "An error occurred",
    },

    table: {
        actions: "Actions",
    },

    profile: {
        profile: "Profile",
        full_name: "Full name",
        name: "Name",
        gender: "Gender",
        gender_male: "Male",
        gender_female: "Female",
        role: "Role",
        role_student: "Student",
        role_teacher: "Teacher",
        phone: "Phone",
        active: "Active status",
        active_false: "Inactive",
        active_true: "Active",
        update_profile: "Update profile",
        first_name: "First name",
        last_name: "Last name",
        phone_number: "Phone number",
        email: "Email",
        address: "Address",
        password: "Password",
        new_password: "New password",
        confirm_newPassword: "Confirm new password",
        cancel: "Cancel",
        save_profile: "Save profile",
        update_success: "Update profile success",
        update_error: "Update profile error",
        please_input_password: "Please input password field",
        please_input_phone: "Please input phone number field",
        wrong_password: "Wrong password",
        select_gender: "Select gender",
        select_active: "Select active status",
        please_enter_minimum_character: "Please enter at least 4 characters",
        field_is_require: "This field is require",
        change_password: "Change password",
        system_error: "System error",
    },

    score: {
        donot: "Do not",
        submited: "Worked",
        marked: "Marked",
        class: "Class",
        subject: "Subject",
        deadline: "Deadline",
        status: "Status",
        listAssignment: "List Assignment",
        assignments: "Assignments",
        do_homework: "Do Homework",
        review: "Review",
        correct_answers: "Correct answers",
        your_answers: "Your answers",
    },

    assignment: {
        donot: "Do not",
        submited: "Worked",
        marked: "Marked",
        expired: "Expired",
        not_start: "Not started yet",
        class: "Class",
        class_code: "Class code",
        subject: "Subject",
        deadline: "Deadline",
        start_from: "Start from",
        status: "Status",
        listAssignment: "List Assignment",
        your_score: "Your score",
        assignments: "Assignments",
        do_homework: "Do Homework",
        review: "Review",
        doing: "Doing",
        score: "Score",
        invite_link: "Invite link",
        invite_via_link: "Invite via link",
        add_user_successfully: "You has been added to the assignment!",
        assignment_teacher_not_allowed: "Teacher is not allowed to attend to the assignment!",
        choose_subject: "Choose subject",
        choose_class: "Choose class",
        choose_status: "Choose status",
        filter_option: "Filter option",
        filter: "Filter",
        choose_owner: "Owner",
        owner: "Choose owner",
        assignment_not_existed: "Assignment is not existed!",
        correct: "Correct",
        correct_nearly: "Partially correct",
        incorrect: "Incorrect",
        mark_by_hand: "Mark by hand",
        skip: "Skip",
        time_spent: "Time spent",
        load_more: "Load more",
        no_assignment: "No assignments!",
        exam_note:
            "Note: <strong>Students are only allowed to submit assignments once</strong>. If the submission deadline is exceeded, the student can still submit it but the system will record it as late.",
        assignment_note:
            "Note: <strong>there is no limit to the number of submissions.</strong> The system will record the results of the last submission",
        exam: "Exam",
        assignment: "Assignment",
        redo_homework: "Re-do",
        count_submit: "Number of submissions",
        select_type_assignment: "Choose exercise type",
        all: "All",
        continue_doing: "Continue doing",
        result: "Result",
        havent_take_test: "Haven't taken this test yet",
        continue: "Continue",
        submit: "Submit",
        count_submit_exercise: "Number of submissions",
        submited_lately: "Submited lately",
        marked_and_submited_lately: "Marked (Submited lately)",
        create_from_scratch: "Create from scratch",
        select_question_from_q_bank: "Select questions from the question bank.",
        create_from_pdf: "Create from PDF",
        upload_pdf_and_create: "Upload your pdf document and create a quiz",
    },

    classes: {
        your_class_list: "Your class list",
        view_detail: "View detail",
        owner: "Owner",
    },

    test: {
        submit_record_success: "Submit record success",
        submit_record_error: "Submit record error",
        submit: "Submit",
        confirm_submit: "Do you sure submit ?",
        time_remaining: "Time remaining",
        cancel: "Cancel",
        exit: "Exit",
        confirm_exit: "Do you sure exit ?",
        timeout_test: "The test time has expired, you cannot change the answer.",
    },

    report: {
        export_pdf: "Export pdf",
        question_number: "Question",
        select_question: "Select a question",
        classification: "Classification",
        very_good: "Very good",
        good: "Good",
        ordinary: "Ordinary",
        failed: "Failed",
        course_completion_score: "Course completion score",
        complete_progress: "Complete progress",
        ratio: "Ratio",
        dateFrom: "Date from",
        dateTo: "Date to",
        subject: "Subject",
        class: "Class",
        test_name: "Test name",
        subject_completion_score: "Subject completion score",
        student_list: "Student list",
        please_select_question: "Please select question",
        please_select_date: "Please select date",
        please_select_subject: "Please select subject",
        please_select_class: "Please select class",
        please_select_student: "Please select student",
        please_select_test_name: "Please select test name",
        apply: "Apply",
        cancel: "Cancel",
        filter: "Filter",
        personal_learning_progress: "Personal learning progress",
        general_learning_progress: "General learning progress",
        test_classification: "Test Classification",
        rate_tests_taken: "Rate test taken",
        name_of_class: "Name of class",
        subject2: "Subject",
        test: "Test",
        average_score: "Average score",
        grasp: "Grasp",
        all_classes: "All classes",
        all_subject: "All subject",
        all: "All",
        progress: "Progress",
        personal_learning: "Personal learning",
        general_learning: "General learning",
        student_name: "Student name",
        score: "Score",
        select_student: "Select student",
        is_student_submitted: "Submitted",
        is_student_not_submitted: "Not submitted yet",
        is_student_not_started: "Not started yet",
        is_teacher_submitted: "Scored",
        is_teacher_not_submitted: "Not scored yet",
        student_submitted: "Submitted",
        student_not_submitted: "Not submitted",
        warning_select_student:
            "The student that needs statistics has not been selected or the student you selected does not have data.",
        student: "Student",
        view_detail: "View detail",
        empty_data: "Empty data",
        number_of_students: "Number of students",
        total_time_spent_in_seconds: "Time spent (in seconds)",
        average_time_spent_in_seconds: "Average time spent (in seconds)",
        grade_of_question: "Question's grade",
        assignment: "Assignment",
        exam: "Exam",
        message_no_one_has_done_the_homework: "No one has done the home work, cannot be counted",
        message_student_have_not_selected:
            "Students have not been selected for statistics or students do not have data",
        message_general_data_not_found: "Data not found or you've not select a class yet",
        // Fields:
        asgmt_datefrom: "Assignment start date",
        asgmt_dateto: "Assignment end date",
        // Titles:
        learning_progress_report: "Learning progress report",
        learning_progress_general: "General learning progress report",
        learning_progress_personal: "Personal learning progress report",
        not_submit: "Không nộp bài",
        is_good: "Good",
        not_good: "Not good",
        equal: "Normal",
    },

    userBoard: {
        full_name: "Full name",
        class: "Class",
        subject: "Subject",
    },

    tag: {
        tag: "Tag",
        add_tag: "Add tag",
        create_tag: "Create a new tag",
        add_tag_success: "Add tag successfully",
        add_tag_error: "Fail to add tag",
        remove_tag_success: "Remove tag successfully",
        remove_tag_error: "Remove tag error",
    },

    editor: {
        attach_files: "Attach files",
        create_blank: "Create a blank",
        blank: "Blank",
        highlight: "Highlight",
        select_audio_visible_type: "Select audio visible type",
        audio_type_icon: "Audio icon",
        audio_type_player: "Audio player",
        insert_or_edit_image: "Insert/edit image",
        insert_or_edit_media: "Insert/edit media",
        insert_or_edit_image_1: "Attach image",
        insert_or_edit_media_1: "Attach media",
        // Plugins - paste-options:
        paste_options: "Paste options",
        paste_option_keep_text_only: "Keep text only",
        paste_option_keep_source_formatting: "Keep source formatting",
        // Plugins - math:
        standard_keypads: "Standard keypads",
        numbers_only: "Numbers only",
        numbers_n_basic_symbols: "Numbers and basic symbols",
        basic: "Basic",
        basic_wo_numbers: "Basic without numbers",
        intermediate: "Intermediate",
        intermediate_wo_numbers: "Intermediate without numbers",
        matrices: "Matrices",
        matrices_basic: "Matrices (basic)",
        trignometry: "Trignometry",
        geometry: "Geometry",
        chemistry: "Chemistry",
        units_SI: "Units (SI)",
        units_US: "Units (US)",
        full: "Full",
        // Plugins - wordcount:
        wordcountmax_reached: "You have exceeded the maximum word limit allowed",
    },

    file_manager: {
        // Fields:
        folder: "Folder",
        // Actions:
        rename: "Rename",
        add_folder: "Add folder",
        add_files: "Add files",
        move_to: "Move to",
        delete: "Delete",
        input_folder_name: "Enter folder name",
        // Messages:
        require_folder: "You have to select a folder!",
        rename_file_success: "The file has been successfully renamed",
        rename_folder_success: "The folder has been successfully renamed",
    },

    my_storage: {
        my_storage: "My storage",
        new_folder: "New folder",
        view_file: "View file",
        view_file_error: "Can not view the file!",
        space_used: "Space used",
        space_left: "Space left",
        space_usage: "Space usage",
        total: "Total",
        used: "Used",
        left: "Available",
        filemanager_loading: "Loading",
        filemanager_uploading: "Uploading",
        filemanager_removing: "Removing",
        // Messages:
        space_used_note_for_org: "The sum of the utilized and allocated capacities",
    },

    organization: {
        // Fields:
        name: "Name",
        avatar: "Avatar",
        avatar_desc: "Avatar will be displayed as your main organization's profile image",
        organization_details: "Organization details",
        organization_overview: "Overview",
        owner: "Owner",
        branches: "Branches",
        classes: "Classes",
        total_branches: "Number of branches",
        total_classes: "Number of classes",
        total_employees: "Number of employees",
        total_teachers: "Number of teachers",
        total_students: "Number of students",
        your_organization: "Your organization",
        your_group: "Your group",
        personal: "Personal",
        // Actions:
        pick_login_option: "Please select a login option below...",
        remove_user_employee_role: "Remove user's employee role",
        // Messages:
        you_are_not_teacher: "Only teachers can accept this invitation!",
        you_are_not_student: "Only students can accept this invitation!",
        teacher_detail_roles_description: "A teacher will become an employee if he/she is assigned a role",
        // Alert:
        confirm_remove_user: "Are you sure you want to remove this user?",
        confirm_remove_user_employee_role: "Are you sure you want to remove this user's employee role?",

        organization: "Organization",
        organization_personal: "Personal",
        tablecol_user: "User",
        find_user: "Find user",
        add_user: "Add user",
        export: "Export file",
        import_user_success: "The file upload is successful, the data import process may take a few minutes or longer",
        add_user_fail: "Add user failed",
        // Invite teacher:
        user_email: "User's email",
        send_invitation: "Send invitation",
        send_invitation_success: "Invitation sent successfully",
        send_invitation_error: "Failed to send invitation",
        add_teacher_not_others: "Only teacher can join the class",
        add_teacher_success: "Add teacher successfully",
        add_teacher_error: "Failed to add teacher",
        //storage
        storage: "Storage",
        storage_used: "Storage used",
        storage_available: "Storage available",
        over_storage: "Storage is not enough",
        storage_error: "Updating storage failed",
        set_storage_success: "Set storage success",
        // Actions:
        add_employee: "Add employee",

        //manage user
        full_name: "Full name",
        email: "Email",
        status: "Status",
        first_name: "First name",
        last_name: "Last name",
        gender: "Gender",
        phone: "Phone",
        address: "Address",
        male: "Male",
        female: "Female",
        organization_role: "Role",
        invite_employee: "Invite employee",
        begin_date: "Begin date",
        end_date: "End date",
        branch: "Branch",
        class_list: "List class",
        select_class: "Select class",
        total_class: "Total class",
        total_student: "Total student",
        code: "Class code",
        class_name: "Name",
        update_user: "Update user",
        detail: "Detail",
        update_user_success: "Update user success!",
        update_user_error: "Update user fail!",
        remove_user: "Remove the user from the organization?",
        select_type: "Select type",
        select_branch: "Select branch",
        type: "Type",
        class: "Class",
        batch_import: "Batch import",
        class_in_organization: "Class in organization",
        my_class: "My class",
        team: "Team",
        team_personal: "Personal",
    },

    branch: {
        branch: "Branch",
        list_branch: "List branches",
        all_branch: "All branches",
        active_branch: "Active branches",
        inactive_branch: "Inactive branches",
        add_branch: "Add branch",
        update_branch: "Update branch",
        select_branch: "Select branch",
        name: "Branch name",
        input_name: "Input branch name",
        status: "Status",
        select_status: "Select status",
        active: "Active",
        inactive: "Inactive",
        creator: "Creator",
        input_branch_name: "Please input branch name!",
    },

    notification: {
        notifications: "Notifications",
        read: "Read",
        unread: "Unread",
        unread_messages: "Unread messages",
        // Actions:
        mark_all_as_read: "Mark all as read",
        // Messages:
        list_end: "It is all, nothing more!",
    },

    account: {
        premium: "Premium",
        organization: "Org.",
        subscription_plan_best_value: "Best value",
        current_account: "Current account",
        // Actions:
        upgrade_now: "Upgrade now",
        contact_support: "Contact support",
        pick_your_suitable_subscription_plan: "Pick your suitable subscription plan",
    },

    plan: {
        plan_name: "Plan name",
        disabled: "Disabled",
    },

    price: {
        amount: "Amount",
        total: "Total",
        paid: "Paid",
        vnd: "VND",
        vnd_per_month: "VND/month",
        free: "Free",
        month: "month",
        price: "Price",
    },

    payment: {
        payment: "Payment",
        order: "Order",
        order_total: "Order total",
        promotion: "Promotion",
        payment_result: "Payment result",
        method_CASH: "Pay in cash",
        method_TRANSFER: "Bank transfer",
        method_VISA: "Visa payment",
        method_ATM_VNPAY: "ATM card and bank account",
        method_VNMART: "Pay with VNPAY",
        method_VNPAYQR: "Pay with VNPAY QR",
        method_PORTONE: "Online Payment",
        method_ONEPAY_QR: "QR payment",
        method_ONEPAY_DOMESTIC: "Domestic card and Bank account",
        method_ONEPAY_INTERNATIONAL: "International payment card",
        bank: "Bank",
        account_name: "Account name",
        account_number: "Account number",
        payment_content: "Payment content",
        // Description:
        method_CASH_please_make_payment_at: "Please make payment at Phuong Nam Education.",
        method_CASH_payment_address: "Address: 357 Le Hong Phong street, ward 2, district 10, Ho Chi Minh City.",
        method_TRANSFER_description: "As soon as we receive your payment, your plan will be activated.",
        method_PORTONE_description: "Support payment via ATM, VISA, MASTERCARD, e-wallet.",
        // Actions:
        purchase: "Purchase",
        input_promotion_code_or_not: "Enter your promo code",
        select_plan_price: "Select a plan",
        // Messages:
        err_can_not_purchase: "Payment error",
        err_can_not_apply_promocode: "Can not apply the promotional code",
        please_input_promocode: "Please enter a promo code",
        please_select_a_payment_method: "Please select a payment method",
        cannot_apply_more_promocodes: "You can not apply more promotional codes",
        sucess_make_payment_transaction: "You have done a payment transaction",
        fail_make_payment_transaction: "Your payment transaction cannot be completed",
        discount: "Discount",
        price: "Price",
        PAY_ERROR: "Payment failed",
        PAYED: "Payment success",
        NOT_PAYED: "Unpaid",
    },

    html_status_code: {
        page_404_title: "Page not found!",
        page_404_msg: "Sorry, the page doesn't exist!",
        action_go_back_home: "Go back to Homepage",
    },

    zwibbler_toolbar: {
        helper: "help",
        pick_tool_helper: "Select the relevant button to use a function.",
        brush_tool_helper: "Tap and drag to draw lines and shapes.",
        line_tool_helper: "Tap to start and add a point, double tap to end.",
        shape_tool_helper: "Tap to start and drag to draw a shape.",
    },

    hotspot: {
        set_correct_answer: "Set correct answer",
        drawing_areas: "Drawing shape",
        node_must_be_closed: "Shape must be closed",
        missing_correct_answer: "Missing correct answer",
    },

    shade: {
        row_count: "Row count",
        column_count: "Column count",
        cell_width: "Cell width",
        cell_height: "Cell height",
        lock_shade_cells: "Lock cells",
        by_location: "By location",
        by_count: "By count",
        unlockcell_note: "Note: You only need to select the number of squares corresponding to the answer",
    },

    speech_to_text: {
        sentence: "Sentence",
        word: "Word",
        please_allow_microphone: "Please allow access to the microphone",
        no_browser_support: "No browser support",
        question: "Question:",
        solution: "Your solution:",
        language: "Language",
        speaker: "Voice",
        select_speaker: "Choose voice",
        tap_to_open_mic: "Tap to turn on the microphone",
        tap_to_close_mic: "Tap to turn off the microphone",
    },

    ranking: {
        ranking: "Ranking",
        ranking_table: "Ranking",
        ranking_type: "Ranking type",
        choose_ranking_type: "Choose a ranking type",
        by_point: "By score",
        by_top: "By top",
        enter_the_number_point: "Enter the score",
        enter_the_number_top: "Enter the top of the people",
        top: "Top",
        point: "Score",
        previous: "The previous test",
        previous_condition: "Students need to pass this test in order to take the test",
        passed: "Passed",
        failed: "Failed",
        score: "Score",
        time: "Time",
        rank: "Ranking",
        choose_asignment_to_view_ranking: "Choose an assignment to see the ranking list",
        result: "Result",

        list_of_competitions: "List of competitions",
        add_new_competition: "Add new competition",
        competition_name: "Name of the competitions",
        create_competition: "Create a contest",
        update_competition: "Contest update",
        round: "Round",
        round_name: "Round name",
        upcoming: "Upcoming",
        finished: "Finished",
        happening: "Happening",
        add_round: "Add new round",
        donot: "Do not",
        not_graded: "Not submitted",
        select_competition: "Choose competition",
        view_result: "View result",
        no_rounds: "Empty rounds",
        create_round: "Create a new round",
        update_round: "Update round",
        take_exam: "Take the exam",
        must_complete_previous_round: "You need to complete the previous round",
        enter_competition_name: "Enter the contest name",
        not_submitted: "Not submitted",
        not_result: "There are no results yet",
        err_rounds_time_begin: "Time must be greater than or equal to the start time of the previous round",
        err_rounds_time_end: "Time must be greater than or equal to the end time of the previous round",
        err_rounds_time_between: "Time must be within the duration of the contest",
        err_rounds_next_begin_time: "The time duration must be less than the start time of the next round",
        select_round: "Select round",
        round2: "Round",
        not_ranked: "Not ranked",
        competition_type: "Type",
        select_competition_type: "Select competition type",
        official_competition: "Official competition",
        note: "Note",
        assignment_note: "Note: Tests with hand-marked questions will not be used for rounding",
    },
    feedback: {
        title: "Product Customer Feedback Form",
        subtitle:
            "If you have any suggestions, please enter them in the box below. Thank you for taking the time to comment on our product.",
        thanks:
            "Thank you for taking time to provide feedback. We will consider this feedback to improve the product and get back to you as soon as possible.",
        send_feedback: "Send feedback",
    },

    dashboard: {
        total_student: "Total number of students",
        total_class: "Total number of classes",
        total_created_class: "Total number of classes created",
        total_created_question: "Total number of questions created",
        total_created_assignment: "Total number of assigned assignments",
        total_created_exam: "Total number of created exams",
        total_created_competition: "Total number of created competitions",
    },
    setting: {
        code: "Code",
        subscription: "Subscription",
        amount: "Amount",
        expiration_date: "Expiration date",
        payment_method: "Payment method",
        payment_status: "Payment status",
        payment_history: "Payment history",
        your_teams_and_org: "Your teams and organization",
        alive: "Alive",
        extend: "Extend",
        select_plan: "Select plan",
    },

    entry_test: {
        entry_test: "Entry test",
        exam: "Exam",
        select_exam: "Select exam",
        test_name: "Test name",
        input_test_name: "Enter the test name",
        entry_test_level: "Entry test level",
        level: "Level",
        score: "Score",
        submitted_marked: "Submitted/ Marked",
        score_tooltip: "Minimum score to reach this level",
    },

    exam_pdf: {
        upload_pdf: "Upload your PDF file",
        create_from_pdf: "Create from PDF",
        upload_pdf_and_create: "Upload your assessment in PDF format and proceed to create an Assessment test",
        create_width_blank_page: "Answer Only Assessment",
        create_width_blank_page_desc: "Want to create an assessment with no content?",
        create_test: "New test",
        update_test: "Update test",
        new_blank_page: "Add blank page",
        delete_page: "Delete page",
    },
};
