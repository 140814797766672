import { useFetch } from "src/hooks";
import { getPersonalProgress } from "src/api/containers/statistic";
import { Alert, Modal, Spin } from "antd";
import { t } from "i18next";
import html2canvas from "html2canvas";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClassificationChart from "src/modules/components/Chart/ClassificationChart";
import PercentColumnPlot from "src/modules/components/PercentColumnPlot";
import UserBoard from "src/modules/components/UserBoard";
import ReportTable from "src/modules/containers/PersonalLearningProgress/components/ReportTable";
import { exportPersonalLearningToPdfText } from "src/utils/helpers";

const ModalPersonalLearningProgress = (props) => {
    const { title, visible, viewProgressStudent, viewProgressClass, viewProgressSubject, onCancel } = props;

    const navigate = useNavigate();
    const [
        loadingPersonalProgress,
        dataPersonalProgress,
        paginationPersonalProgress,
        fetchPersonalProgress,
        refetchPersonalProgress,
    ] = useFetch({ page: 1, slug: "" }, getPersonalProgress);

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        filterParams: (() => {
            const result = [];

            Object.keys(viewProgressClass || {}).length &&
                result.push({
                    name: "class_id",
                    value: viewProgressClass?.id,
                    labelName: t("report.class"),
                    labelValue: viewProgressClass?.name,
                }),
                Object.keys(viewProgressStudent || {}).length &&
                    result.push({
                        name: "student_id",
                        value: viewProgressStudent?.id,
                        labelName: t("report.student"),
                        labelValue: viewProgressStudent?.name,
                    }),
                Object.keys(viewProgressSubject).length &&
                    result.push({
                        name: "subject_id",
                        value: viewProgressSubject.id,
                        labelName: t("report.subject"),
                        labelValue: viewProgressSubject.name,
                    });

            return result;
        })(),
    });

    const [isResized, setIsResized] = useState(true);
    const [isMobile, setIsMobile] = useState(null);
    const timer = useRef();

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleExportPersonalLearningToPdf = async () => {
        const logoOrganization = document.createElement("div");
        logoOrganization.classList.add("pdf__logo");

        const base64img = "";
        // base64 img
        logoOrganization.innerHTML = `<img src="${base64img}" /> <span>Hogwarts</span>`;
        document.body.appendChild(logoOrganization);

        const canvas = await html2canvas(logoOrganization);
        const logo = canvas.toDataURL();

        exportPersonalLearningToPdfText(
            dataPersonalProgress,
            dataPersonalProgress?.name,
            logo,
            dataPersonalProgress?.arr_name_class
        );

        logoOrganization.remove();
    };

    const handleResize = (e) => {
        const width = window.innerWidth;

        if (width <= 1024) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

        clearInterval(timer.current);
        setIsResized(false);

        timer.current = setTimeout(() => {
            setIsResized(true);
        }, 1000);
    };

    useEffect(() => {
        const result = [];
        Object.keys(viewProgressClass || {}).length &&
            result.push({
                name: "class_id",
                value: viewProgressClass?.id,
                labelName: t("report.class"),
                labelValue: viewProgressClass?.name,
            }),
            Object.keys(viewProgressStudent || {}).length &&
                result.push({
                    name: "student_id",
                    value: viewProgressStudent?.id,
                    labelName: t("report.student"),
                    labelValue: viewProgressStudent?.name,
                }),
            Object.keys(viewProgressSubject).length &&
                result.push({
                    name: "subject_id",
                    value: viewProgressSubject.id,
                    labelName: t("report.subject"),
                    labelValue: viewProgressSubject.name,
                });

        setFilterSettings({
            ...filterSettings,
            filterParams: result,
        });
    }, [viewProgressStudent, viewProgressSubject, viewProgressClass]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const width = window.innerWidth;

        if (width <= 1024) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        const _filterParams = {};
        (filterSettings.filterParams || []).forEach((item) => {
            _filterParams[item.name] = {
                value: item.value,
            };
        });

        const _class_id = _filterParams.class_id?.value || "";
        const _student_id = _filterParams.student_id?.value || "";
        const _subject_id = _filterParams.subject_id?.value || "";
        if (_student_id) {
            fetchPersonalProgress(
                {
                    class_id: _class_id,
                    student_id: _student_id,
                    subject_id: _subject_id,
                },
                true
            );
        }
    }, [filterSettings.filterParams]);

    return (
        <Modal
            className="add-exam-question-modal modal-mark-assignment-table"
            title={title}
            visible={visible}
            footer={null}
            onCancel={onCancel}
            width={"80%"}
            bodyStyle={{ padding: "0px" }}
            destroyOnClose
        >
            <div className="reportStudent_wrapper">
                <div className="reportStudent_box m-0">
                    <div className="reportStudent_filter">
                        {/* <div className="tags">
                            {filterSettings.filterParams?.length > 0 &&
                                filterSettings.filterParams.map((fKey, i) => {
                                    return (
                                        <Tag
                                            className="tag_item_inner"
                                            key={`filter-key${i}`}
                                            closable
                                            onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                        >
                                            {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`}
                                        </Tag>
                                    );
                                })}
                        </div>
                        <div className="reportStudent_filter_inner">
                            <div className="filterButton">
                                <Dropdown
                                    visible={filterSettings.isFilterMenuVisible}
                                    overlay={
                                        <FilterMenu
                                            isModal
                                            isTeacher={true}
                                            filterParams={filterSettings.filterParams}
                                            handleFetch={fetchPersonalProgress}
                                            // dataSource={}
                                            handleCloseFilterMenu={(newFilterParams) =>
                                                setFilterSettings({
                                                    ...filterSettings,
                                                    isFilterMenuVisible: false,
                                                    ...(newFilterParams && { filterParams: newFilterParams }),
                                                })
                                            }
                                            willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                        />
                                    }
                                    overlayClassName="progress-filter"
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    onVisibleChange={(val) =>
                                        setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                    }
                                >
                                    <Button
                                        type="primary"
                                        title={t("report.filter")}
                                        icon={<FilterIcon />}
                                        className="tags_button"
                                    />
                                </Dropdown>
                            </div>
                        </div> */}
                    </div>
                    {loadingPersonalProgress && <Spin tip={`${t("shared.loading")}...`}></Spin>}
                    {!loadingPersonalProgress && (
                        <>
                            {dataPersonalProgress?._id ? (
                                <>
                                    <div className="reportStudent_item">
                                        <UserBoard
                                            fullName={dataPersonalProgress?.name || ""}
                                            nameOfClasses={dataPersonalProgress?.arr_name_class}
                                            avatar={dataPersonalProgress?.avatar}
                                            exportPdf={handleExportPersonalLearningToPdf}
                                        />
                                        {isResized && (
                                            <PercentColumnPlot
                                                label={t("report.course_completion_score")}
                                                data={dataPersonalProgress?.chart_for_assignment_category}
                                            />
                                        )}

                                        {!isResized && (
                                            <div style={{ width: "100%", padding: "10px", height: "400px" }}>
                                                <div
                                                    style={{
                                                        backgroundColor: "#fff",
                                                        borderRadius: "4px",
                                                        height: "100%",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    <Spin spinning={true}></Spin>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="reportStudent_item">
                                        <div className="reportStudent_chartPie_wrapper">
                                            <div className="reportStudent_chartPie">
                                                <div className="reportStudent_title">
                                                    {t("report.rate_tests_taken")}
                                                </div>
                                                {isResized && (
                                                    <ClassificationChart
                                                        isMobile={isMobile}
                                                        data={dataPersonalProgress?.chart_for_assignment}
                                                    ></ClassificationChart>
                                                )}
                                                {!isResized && <Spin spinning={true}></Spin>}
                                            </div>
                                        </div>
                                        <div className="reportStudent_table_wrapper">
                                            <div className="reportStudent_table_inner">
                                                <div
                                                    id="pdf__tableWithPagination"
                                                    className="reportStudent_table_inner"
                                                >
                                                    {isResized && (
                                                        <ReportTable
                                                            data={dataPersonalProgress?.records_without_history}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    id="pdf__tableWithoutPagination"
                                                    style={{ display: "none" }}
                                                    className="reportStudent_table_inner"
                                                >
                                                    {isResized && (
                                                        <ReportTable
                                                            data={dataPersonalProgress?.records_without_history}
                                                        />
                                                    )}
                                                </div>

                                                {!isResized && <Spin spinning={true}></Spin>}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Alert
                                    description={t("report.message_student_have_not_selected")}
                                    type="warning"
                                    style={{ marginTop: 20 }}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(ModalPersonalLearningProgress);
