import React, { useEffect, useRef, useState } from "react";
import { CaretUpOutlined } from "@ant-design/icons";
import "./BackTop.scss";

function BackTopButton({ duration = 0, onClick = () => {}, ...rest }) {
    const [showBackTop, setShowBackTop] = useState(false);
    const previousScrollTop = useRef(0);
    const timeoutIdScroll = useRef(null);

    const handleScroll = () => {
        // If the scrolling is done, trigger event handler:
        clearTimeout(timeoutIdScroll.current);
        timeoutIdScroll.current = setTimeout(() => {
            if (
                window.pageYOffset < previousScrollTop.current &&
                window.pageYOffset > duration &&
                window.pageYOffset > window.innerHeight / 2
            ) {
                setShowBackTop(true);
            } else {
                setShowBackTop(false);
            }
            // Store the current scrollX:
            previousScrollTop.current = window.pageYOffset;
        }, 250);
    };

    const handleClickBackTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        onClick();
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let extraClassnames = "";
    if (showBackTop === true) {
        extraClassnames += " visible";
    }
    if (rest.className) {
        extraClassnames += ` ${rest.className}`;
    }

    return (
        <span className={`backtop-btn-wrapper${extraClassnames}`} onClick={handleClickBackTop}>
            <span className="backtop-btn-content">
                <CaretUpOutlined />
            </span>
        </span>
    );
}

export default BackTopButton;
