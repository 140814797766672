import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Form, Input, notification, Radio, Row, Spin, Upload } from "antd";
import { t } from "i18next";
import { getMe } from "src/api/containers/auth";
import { fetchProfile, updateProfile } from "src/api/containers/profile";
import { addFiles } from "src/api/containers/storage";
import { useAuth } from "src/modules/auth";
import { useFetch } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "src/reducers/auth";
import BoxContent from "src/modules/components/BoxContent";
import Header from "src/modules/components/Header";
import Button from "src/modules/components/Button";
import { deleteCookie } from "src/utils/helpers";
import "./Profile.scss";
import NoAvatar from "src/assets/images/no-avatar.png";
import CameraIcon from "src/assets/images/camera-icon.svg";
import Pencil from "src/assets/images/pencil.svg";
import SaveAsDraftWhite from "src/assets/images/SaveAsDraftWhite.svg";
import TrashIcon from "src/assets/images/icon-question-delete.svg";
import EyeIcon from "src/assets/images/eye-icon.svg";
import { setCurrentPage } from "src/reducers/general";
import PopupFileManager from "src/modules/components/FileManager/popup";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";

const ShowPassword = (props) => {
    const { onClick = () => {} } = props;
    return (
        <div className="profile_showPassword" onClick={onClick}>
            <img src={EyeIcon} />
        </div>
    );
};

const Profile = (props) => {
    const auth = useAuth();
    const { user, accountType } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [urlImagePreview, setUrlImagePreview] = useState(null);
    const [isUpdateProfile, setIsUpdateProfile] = useState(true);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false);
    const [isShowRePassword, setIsShowRePassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);
    const [isLoadingAvatar, setIsLoadingAvatar] = useState(true);

    const [profileLoading, profileDataSource, profilePagination, profileFetch, profileRefetch] = useFetch(
        {},
        fetchProfile
    );
    const fmController = PopupFileManager.useController();

    useLayoutEffect(() => {
        if (isChangePassword) {
            form.setFieldsValue({
                password: "",
            });
        } else {
            form.setFieldsValue({
                password: "**********",
            });
        }
    }, [isChangePassword]);

    useEffect(() => {
        dispatch(setCurrentPage("manage_account"));
        profileFetch({ id: auth.user?.id || user?.id }, true);
    }, []);

    useEffect(() => {
        if (profileDataSource?.id) {
            form.setFieldsValue({
                email: profileDataSource?.username || "",
                last_name: profileDataSource?.last_name || "",
                first_name: profileDataSource?.first_name || "",
                gender: profileDataSource?.gender || 0,
                phone: profileDataSource?.phone || "",
                address: profileDataSource?.address || "",
                password: "**********",
                password_confirm: "",
                password_new: "",
                avatar: profileDataSource?.avatar || "",
            });

            setUrlImagePreview(profileDataSource?.avatar || NoAvatar);
            setIsLoadingAvatar(false);
        }
    }, [profileDataSource]);

    const updateMyProfile = () => {
        getMe(user?.paramsForOrgOrTeam?.organization_id).then((res) => {
            if (res?.status === true && res?.data?.user) {
                dispatch(login(res.data.user));
            } else {
                localStorage.removeItem("token");
                deleteCookie("token");
            }
        });
    };

    const handleChangeAvatar = (ava_id, src) => {
        updateProfile({ id: auth.user?.id, data: { avatar_id: ava_id } }).then((resUpdateProfile) => {
            if (resUpdateProfile.status) {
                updateMyProfile();
                setIsLoadingAvatar(false);
                setUrlImagePreview(src);
                notification.success({
                    message: t("profile.update_success"),
                });
            } else {
                notification.error({
                    message: t("message.update_error"),
                });
                setIsLoadingAvatar(false);
            }
        });
    };

    const handleSubmitProfile = (values) => {
        // Check if user is valid:
        if (accountType === "demo") {
            notification.error({ message: t("shared.you_dont_have_permission") });
            return;
        }

        // Preparation:
        if (!isChangePassword) {
            if (auth.user?.login_with_social) {
                delete values.password_new;
            } else {
                delete values.password;
            }
        }
        const formData = {
            ...values,
            avatar: undefined,
        };

        // Handle updating user profile:
        updateProfile({ id: auth.user?.id, data: formData }).then((res) => {
            const { data: profile, message = "" } = res;
            if (res.status) {
                dispatch(login({ ...auth.user, ...profile }));

                notification.success({ message: t("profile.update_success") });
                setIsUpdateProfile(false);
                setIsChangePassword(false);
                form.setFieldsValue({
                    email: profile?.username || "",
                    last_name: profile?.last_name || "",
                    first_name: profile?.first_name || "",
                    gender: profile?.gender || 0,
                    phone: profile?.phone || "",
                    address: profile?.address || "",
                    password: "**********",
                    password_confirm: "",
                    password_new: "",
                    // avatar: auth.user?.avatar || "",
                });
            } else if (message) {
                notification.error({ message });
            }

            setIsShowCurrentPassword(false);
            setIsShowNewPassword(false);
            setIsShowRePassword(false);
        });
    };

    const ChangePasswordSuffix = (props) => {
        const { onClick = () => {} } = props;
        return (
            <div className="profile_changePassword" onClick={onClick}>
                {t("profile.change_password")}
            </div>
        );
    };

    const generateFormPassword = (isLoginSocial) => {
        if (isLoginSocial) {
            return (
                <>
                    <div className="profile_changePasswordForm">
                        <Row className="profile_row">
                            <Col sm={24} md={24}>
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    name="password_new"
                                    label={t("profile.new_password")}
                                    className="profile_groupItem"
                                    {...(isChangePassword
                                        ? {
                                              rules: [
                                                  {
                                                      type: "string",
                                                  },
                                                  {
                                                      required: true,
                                                      message: t("profile.please_input_password_new"),
                                                  },
                                                  {
                                                      min: 4,
                                                      message: t("profile.please_enter_minimum_character"),
                                                  },
                                              ],
                                          }
                                        : {})}
                                >
                                    <Input
                                        type={isShowCurrentPassword ? "text" : "password"}
                                        readOnly={!isChangePassword}
                                        suffix={
                                            accountType === "demo" ? null : (
                                                <>
                                                    {isChangePassword && (
                                                        <ShowPassword
                                                            onClick={() => {
                                                                // setIsShowCurrentPassword(!isShowCurrentPassword);
                                                                setIsShowCurrentPassword((pre) => !pre);
                                                            }}
                                                        />
                                                    )}
                                                    <ChangePasswordSuffix
                                                        onClick={() => {
                                                            setIsChangePassword((pre) => !pre);
                                                            form.setFieldsValue({
                                                                password_confirm: "",
                                                                password_new: "",
                                                            });
                                                        }}
                                                    />
                                                </>
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {isChangePassword && (
                            <div className="profile_changePasswordForm">
                                <Row className="profile_row">
                                    <Col sm={24} md={24}>
                                        <Form.Item
                                            labelCol={{ span: 5 }}
                                            name="password_confirm"
                                            label={t("profile.confirm_newPassword")}
                                            className="profile_groupItem"
                                            dependencies={["password_new"]}
                                            rules={[
                                                {
                                                    type: "string",
                                                },
                                                {
                                                    min: 4,
                                                    message: t("profile.please_enter_minimum_character"),
                                                },
                                                {
                                                    required: true,
                                                    message: t("profile.please_input_password_confirm"),
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue("password_new") === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error("Mật khẩu không khớp."));
                                                    },
                                                }),
                                            ]}
                                        >
                                            {/* {isUpdateProfile ? ( */}
                                            <Input
                                                type={isShowRePassword ? "text" : "password"}
                                                suffix={
                                                    <ShowPassword
                                                        onClick={() => {
                                                            setIsShowRePassword(!isShowRePassword);
                                                        }}
                                                    />
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Row className="profile_row">
                        <Col>
                            <Form.Item
                                name="password"
                                label={t("profile.password")}
                                className="profile_groupItem"
                                labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                rules={[
                                    {
                                        required: true,
                                        message: t("profile.please_input_password"),
                                    },
                                    {
                                        min: 4,
                                        message: t("profile.please_enter_minimum_character"),
                                    },
                                ]}
                            >
                                {/* {isUpdateProfile ? ( */}
                                <Input
                                    type={isShowCurrentPassword ? "text" : "password"}
                                    placeholder={isChangePassword ? t("profile.please_input_password") : ""}
                                    readOnly={!isChangePassword}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile.please_input_password"),
                                        },
                                        {
                                            min: 4,
                                            message: t("profile.please_enter_minimum_character"),
                                        },
                                    ]}
                                    disabled={!isChangePassword}
                                    suffix={
                                        accountType === "demo" ? null : (
                                            <>
                                                {isChangePassword && (
                                                    <ShowPassword
                                                        onClick={() => {
                                                            setIsShowCurrentPassword(!isShowCurrentPassword);
                                                        }}
                                                    />
                                                )}
                                                <ChangePasswordSuffix
                                                    onClick={() => {
                                                        setIsChangePassword((pre) => !pre);
                                                        form.setFieldsValue({
                                                            password_confirm: "",
                                                            password_new: "",
                                                        });
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                />
                                {/* // ) : (
                                //     <Input
                                //         type="text"
                                //         suffix={<ChangePasswordSuffix onClick={() => alert("k")} />}
                                //         disabled
                                //     />
                                // )} */}
                            </Form.Item>
                        </Col>
                    </Row>
                    {isChangePassword && (
                        <div className="profile_changePasswordForm">
                            <Row className="profile_row">
                                <Col>
                                    <Form.Item
                                        labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                        name="password_new"
                                        label={t("profile.new_password")}
                                        className="profile_groupItem"
                                        rules={[
                                            {
                                                type: "string",
                                            },
                                            {
                                                required: true,
                                                message: t("profile.please_input_password"),
                                            },
                                            {
                                                min: 4,
                                                message: t("profile.please_enter_minimum_character"),
                                            },
                                        ]}
                                    >
                                        {/* {isUpdateProfile ? ( */}
                                        <Input
                                            type={isShowNewPassword ? "text" : "password"}
                                            suffix={
                                                <ShowPassword
                                                    onClick={() => {
                                                        setIsShowNewPassword(!isShowNewPassword);
                                                    }}
                                                />
                                            }
                                        />
                                        {/* ) : (
                                            <Input type="text" suffix={<ShowPassword />} disabled />
                                        )} */}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="profile_row">
                                <Col>
                                    <Form.Item
                                        labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                        name="password_confirm"
                                        label={t("profile.confirm_newPassword")}
                                        className="profile_groupItem"
                                        dependencies={["password_new"]}
                                        rules={[
                                            {
                                                type: "string",
                                            },
                                            {
                                                required: true,
                                                message: t("profile.please_input_password"),
                                            },
                                            {
                                                min: 4,
                                                message: t("profile.please_enter_minimum_character"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password_new") === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error("Mật khẩu không khớp."));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            type={isShowRePassword ? "text" : "password"}
                                            suffix={
                                                <ShowPassword
                                                    onClick={() => {
                                                        setIsShowRePassword(!isShowRePassword);
                                                    }}
                                                />
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    )}
                </>
            );
        }
    };

    return (
        <div className="profile_wrapper">
            {!props.isHideHeader && (
                <div className="profile_headerWrapper">
                    <Header
                        backFunc={() => {
                            navigate(-1);
                        }}
                        className="profile_header"
                        // backTitle={isUpdateProfile ? t("profile.update_profile") : t("profile.profile")}
                        backTitle={t("profile.profile")}
                    />
                </div>
            )}
            <div className="profile_container">
                <Form form={form} onFinish={handleSubmitProfile} className="profile_form">
                    <BoxContent className="profile_boxContent">
                        <div className="profile_scroll scroll_primary">
                            <div className="profile_avatar">
                                <Spin spinning={isLoadingAvatar}>
                                    <label
                                        onClick={() => {
                                            if (accountType === "demo") {
                                                notification.error({ message: t("shared.you_dont_have_permission") });
                                                return;
                                            }
                                            if (auth.user.role === "teacher" || auth.user.role == "employee") {
                                                fmController.call("open");
                                            }
                                        }}
                                        htmlFor="avatar"
                                        className={"profile_avatarLabel " + (!isUpdateProfile ? "disabled" : "")}
                                    >
                                        {!profileLoading && urlImagePreview && (
                                            <img src={urlImagePreview} className="profile_image" />
                                        )}
                                        {(auth.user.role === "teacher" || auth.user.role == "employee") && (
                                            <img src={CameraIcon} className="profile_camera" />
                                        )}
                                        {profileLoading && (
                                            <span className="profile_avatarloading skeleton-loading"></span>
                                        )}
                                    </label>
                                </Spin>
                            </div>
                            <Row>
                                <Col span={24} xl={{ span: 22 }} xxl={{ span: 21 }}>
                                    <Row className="profile_row">
                                        <Col lg={24} xl={12}>
                                            <Form.Item
                                                labelCol={{ xl: { span: 10 }, lg: { span: 24 } }}
                                                name="last_name"
                                                label={t("profile.last_name")}
                                                className="profile_groupItem"
                                                rules={[
                                                    {
                                                        type: "string",
                                                    },
                                                    {
                                                        required: true,
                                                        message: t("profile.field_is_require"),
                                                    },
                                                ]}
                                            >
                                                <Input readOnly={accountType === "demo"} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} xl={12}>
                                            <Form.Item
                                                labelCol={{ xl: { span: 7 }, lg: { span: 24 } }}
                                                name="first_name"
                                                label={t("profile.first_name")}
                                                className="profile_groupItem"
                                                rules={[
                                                    {
                                                        type: "string",
                                                    },
                                                    {
                                                        required: true,
                                                        message: t("profile.field_is_require"),
                                                    },
                                                ]}
                                            >
                                                <Input readOnly={accountType === "demo"} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="profile_row">
                                        <Col>
                                            <Form.Item
                                                labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                                name="gender"
                                                label={t("profile.gender")}
                                                className="profile_groupItem"
                                            >
                                                <Radio.Group
                                                    onChange={() => {}}
                                                    value={0}
                                                    disabled={accountType === "demo"}
                                                >
                                                    <Radio value={0}>{t("profile.gender_female")}</Radio>
                                                    <Radio value={1}>{t("profile.gender_male")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="profile_row">
                                        <Col>
                                            <Form.Item
                                                labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                                name="email"
                                                label={t("profile.email")}
                                                className="profile_groupItem"
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="profile_row">
                                        <Col>
                                            <Form.Item
                                                labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                                name="phone"
                                                label={t("profile.phone_number")}
                                                className="profile_groupItem"
                                            >
                                                <Input readOnly={accountType === "demo"} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="profile_row">
                                        <Col>
                                            <Form.Item
                                                labelCol={{ xl: { span: 5 }, lg: { span: 24 } }}
                                                name="address"
                                                label={t("profile.address")}
                                                className="profile_groupItem"
                                            >
                                                <Input readOnly={accountType === "demo"} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* Render change password form based on login status with social media */}
                                    {generateFormPassword(auth.user?.login_with_social)}
                                </Col>
                            </Row>
                        </div>
                    </BoxContent>
                    <div className="profile_btnWrapper">
                        <Row className="profile_btn">
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={SaveAsDraftWhite}
                                title={t("profile.save_profile")}
                                disabled={accountType === "demo"}
                            ></Button>
                        </Row>
                    </div>
                </Form>
            </div>

            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        if (validateFileBeforeUpload(selectedFile.src).type === "image") {
                            handleChangeAvatar(selectedFile.id, selectedFile.src);
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </div>
    );
};

export default Profile;
