import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { Dropdown, Select, Space, Spin, Tag } from "antd";
import FilterMenu from "src/modules/components/FilterMenu";
import Header from "../../components/Header";
import ClassItems from "../../components/ClassItems";
import InputSearch from "src/modules/components/Input/InputSearch";
import Button, { default as CustomButton } from "src/modules/components/Button";
import { fetchClasses } from "src/api/containers/class";
import { useFetch, useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import { compareObjects } from "src/utils/helpers/comparison";
import { getFilterValuesByURLSearchParams, getURLSearchParamsByFilterValues } from "src/utils/helpers/urlQueryString";
import FilterIcon from "src/assets/images/filter-icon.svg";
import "./ListClass.scss";
import { MergeCellsOutlined } from "@ant-design/icons";
import ModalMergeClass from "../../components/ModalMergeClass";

const ListClass = ({ isWithUrlQueryString = true }) => {
    const dispatch = useDispatch();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};

    // FILTER SETTINGS:
    const [filterInfo, setFilterInfo] = useValues({
        isFirstTimeFetchDone: false,
        // Search box:
        inputSearch: "",
        // Page:
        currPage: 1,
        // Filter menu:
        isVisible: false,
    });
    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const filterValuesInitial = {
            slug: undefined,
            // is_active: 1,
            // is_official: 1,
        };
        const filterValuesConfig = {
            slug: {
                queryKey: "s",
            },
            is_active: {
                label: "Active status",
                queryKey: "act",
                values: ["1", "0"],
                valuesInfo: {
                    1: { label: "class.active", queryValue: "1" },
                    0: { label: "class.inactive", queryValue: "0" },
                },
            },
            is_official: {
                label: "Active status",
                queryKey: "official",
                values: ["1", "0"],
                valuesInfo: {
                    1: { label: "class.official_class", queryValue: "1" },
                    0: { label: "class.intended_class", queryValue: "0" },
                },
            },
        };
        if (fParamsUserScope?.organization_id) {
            filterValuesInitial.organization_id = fParamsUserScope.organization_id;
            filterValuesConfig.organization_id = {
                label: t("shared.scope"),
                queryKey: "scope",
                values: [fParamsUserScope.organization_id],
                valuesInfo: {
                    [fParamsUserScope.organization_id]: {
                        label: user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown", // {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                        queryValue: fParamsUserScope.organization_id,
                    },
                },
            };
        }
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [filterInfo]);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    // FILTER MENU:
    const formFields = useMemo(() => {
        const _formFields = {
            ...(filterValuesConfig.is_active
                ? {
                      is_active: {
                          label: t("shared.status"),
                          element: (
                              <Select className="app-select" placeholder={t("shared.choose_status")} allowClear>
                                  {filterValuesConfig.is_active.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`act${itemIndex}`} value={item}>
                                              {t(`${filterValuesConfig.is_active.valuesInfo[item].label}`)}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: {
                              span: 24,
                          },
                      },
                  }
                : {}),
            ...(filterValuesConfig.is_official
                ? {
                      is_official: {
                          label: t("class.class_type"),
                          element: (
                              <Select className="app-select" placeholder={t("class.select_class_type")} allowClear>
                                  {filterValuesConfig.is_official.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`offi${itemIndex}`} value={item}>
                                              {t(`${filterValuesConfig.is_official.valuesInfo[item].label}`)}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: {
                              span: 24,
                          },
                      },
                  }
                : {}),
        };
        if (fParamsUserScope?.organization_id && filterValuesConfig.organization_id) {
            _formFields.organization_id = {
                label: t("shared.scope"),
                element: (
                    <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                        {filterValuesConfig.organization_id.values.map((item, itemIndex) => {
                            return (
                                <Select.Option key={`scope${itemIndex}`} value={item}>
                                    {t(`${filterValuesConfig.organization_id.valuesInfo[item].label}`)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                ),
                colProps: {
                    span: 24,
                },
            };
        }
        return _formFields;
    }, [filterInfo]);
    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: ["is_active", "organization_id", "is_official"],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    // API CALL:
    const [loading, dataSource, pagination, fetch, refetch] = useFetch({}, fetchClasses);

    // PAGE DATA:
    const [values, setValues] = useValues({
        listPages: 0,
        listOfClasses: [],
        loadingClasses: false,
        isModalMergeClass: false,
    });

    const handleUpdateQueryString = (urlQueries = {}) => {
        const sParams = {};
        urlQueries?.s && (sParams.s = urlQueries.s);
        urlQueries?.official && (sParams.official = urlQueries.official);
        urlQueries?.act && (sParams.act = urlQueries.act);
        urlQueries?.scope && (sParams.scope = urlQueries.scope);
        setUrlSearchParams(sParams);
    };

    const handleFetchDataList = (fetchParams = {}, isReload = true, extraFetchParams) => {
        const fParams = {
            page: filterInfo.currPage,
            slug: fetchParams.slug,
            is_active: fetchParams.is_active,
            is_official: fetchParams.is_official,
            organization_id: fetchParams.organization_id,
            ...(Object.keys(extraFetchParams || {})?.length ? extraFetchParams : {}),
        };
        fetch({ ...fParams }, isReload);
        // Update search box's value:
        if (fParams.slug) {
            setFilterInfo({ inputSearch: fParams.slug });
        }
    };

    const handleSearch = () => {
        const newFilterValues = {
            ...currFilterValues,
            slug: filterInfo.inputSearch,
        };
        setFilterInfo({ currPage: 1 });
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            [fieldName]: undefined,
        };
        setFilterInfo({ currPage: 1 });
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("class"));
    }, []);

    useEffect(() => {
        if (isWithUrlQueryString) {
            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
            // Deal with one of these cases:
            // 1. Handle update query string whenever:
            // - The URL query string is empty and it's the first time fetch.
            // 2. Handle fetching whenever:
            // - The URL query string is not empty.
            // - The URL query string is empty but it's not the first time fetch.
            const isUrlQSEmpty = Object.keys(currUrlSearchParams).length === 0;
            const isFirstTimeFetch = filterInfo.isFirstTimeFetchDone === false;
            if (isUrlQSEmpty && isFirstTimeFetch) {
                const newUrlSearchParams = getURLSearchParamsByFilterValues(currFilterValues, filterValuesConfig);
                handleUpdateQueryString(newUrlSearchParams);
                // When there aren't any fetchs are made at the first time, force fetching with initial filter values:
                if (!Object.keys(newUrlSearchParams).length) {
                    handleFetchDataList(currFilterValues, true);
                }
            } else {
                if (!isUrlQSEmpty || (isUrlQSEmpty && !isFirstTimeFetch)) {
                    const newFilterValues = getFilterValuesByURLSearchParams(currUrlSearchParams, filterValuesConfig);
                    if (compareObjects(currFilterValues, newFilterValues) === false) {
                        setCurrFilterValues(newFilterValues);
                        setFilterInfo({ ...filterInfo, isFirstTimeFetchDone: true });
                        // Update data list whenever the URL query string is changed:
                        handleFetchDataList(newFilterValues, true);
                    }
                }
            }
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [urlSearchParams, currFilterValues]);

    useEffect(() => {
        if (dataSource.length) {
            if (pagination.current === 1) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: dataSource,
                });
            } else if (pagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfClasses: [...values.listOfClasses, ...dataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfClasses: [],
            });
        }
    }, [dataSource, pagination]);

    return (
        <div className="class">
            <Header />

            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    {/* ------search input------ */}
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("class.find_class")}
                            value={filterInfo.inputSearch}
                            onChange={(e) => setFilterInfo({ ...filterInfo, inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>

                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {(!isWithUrlQueryString || filterInfo.isFirstTimeFetchDone) &&
                                    Object.keys(currFilterValues).map((fKey, i) => {
                                        const fValue = currFilterValues[fKey];
                                        const fValueLabel =
                                            filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                            (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                : undefined);
                                        if (fValue && fValueLabel) {
                                            return (
                                                <Tag
                                                    className="app-tag"
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                >
                                                    {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                </Tag>
                                            );
                                        }
                                        return null;
                                    })}
                            </Space>
                        </div>

                        <Dropdown
                            forceRender
                            visible={filterInfo.isVisible}
                            overlay={
                                <FilterMenu
                                    formLayout={"horizontal"}
                                    formFields={formFields}
                                    formFieldGroups={formFieldGroups}
                                    formData={currFilterValues}
                                    willResetForm={filterInfo.isVisible === false}
                                    onCancel={() => {
                                        setFilterInfo({
                                            ...filterInfo,
                                            isVisible: false,
                                        });
                                    }}
                                    onSubmit={(newFilterValues) => {
                                        setFilterInfo({ currPage: 1 });
                                        if (isWithUrlQueryString) {
                                            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
                                            const newUrlSearchParams = getURLSearchParamsByFilterValues(
                                                newFilterValues,
                                                filterValuesConfig
                                            );
                                            if (compareObjects(currUrlSearchParams, newUrlSearchParams) === false) {
                                                handleUpdateQueryString(newUrlSearchParams);
                                            }
                                        } else {
                                            setCurrFilterValues(newFilterValues);
                                        }
                                    }}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => {
                                setFilterInfo({ ...filterInfo, isVisible: val });
                            }}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={FilterIcon}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions"></div>
                <span>
                    <Button
                        icon={<MergeCellsOutlined />}
                        title={t("class.merge_class")}
                        onClick={() => {
                            setValues({
                                isModalMergeClass: true,
                            });
                        }}
                    />
                </span>
            </div>

            <Spin spinning={loading || values.loadingClasses}>
                <div className="class-list">
                    <ClassItems
                        classList={values.listOfClasses}
                        pagination={pagination}
                        handleFetchClassList={(fParams) => {
                            setFilterInfo({ currPage: fParams?.page || 1 });
                            handleFetchDataList(currFilterValues, true, { page: fParams?.page || 1 });
                        }}
                        handleRefetchClassList={() => {
                            setFilterInfo({ currPage: 1 });
                            handleFetchDataList(currFilterValues, true, { page: 1 });
                        }}
                        setLoadingClasses={(status) => setValues({ loadingClasses: status })}
                    />
                </div>
            </Spin>

            <ModalMergeClass
                visible={values.isModalMergeClass}
                onOk={() => {
                    setFilterInfo({ currPage: 1 });
                    handleFetchDataList(currFilterValues, true, { page: 1 });
                    setValues({ isModalMergeClass: false });
                }}
                onCancel={() => {
                    setValues({ isModalMergeClass: false });
                }}
                destroyOnClose
            ></ModalMergeClass>
        </div>
    );
};

export default ListClass;
