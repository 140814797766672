import * as Sentry from "@sentry/browser";
import configs from "../configs";
import { BrowserTracing } from "@sentry/tracing";

const sentry = {
    init: () => {
        if (configs.NODE_ENV === "production") {
            if (process.env.NODE_ENV === "production") {
                const tunnel_enpoint = configs.API_ENDPOINT.replace("/api/app", "");
                Sentry.init({
                    dsn: "https://0d330bf17a0f45e99bb16aa6e443d00d@sentry.phuongnamdigital.com/19",
                    integrations: [new BrowserTracing()],
                    tracesSampleRate: 1.0,
                    tunnel: `${tunnel_enpoint}/sentry_tunnel.php`,
                });
            }
        }
    },
};

export default sentry;
