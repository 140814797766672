import clsx from "clsx";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ThumbnailsItem from "../ThumbnailsItem";
import "./Thumbnails.scss";

function Thumbnails({ listThumbnails, currentThumbnail, setCurrentThumbnail = () => {}, isShowThumbnails = false }) {
    return (
        <div className={clsx("pdf-thumbnails")}>
            <div className={clsx("thumbnails-list", { show: isShowThumbnails })}>
                <PerfectScrollbar>
                    {listThumbnails.map((item) => (
                        <ThumbnailsItem
                            key={item.file_id}
                            data={item}
                            currentThumbnail={currentThumbnail}
                            onClick={(data) => {
                                setCurrentThumbnail(item);
                            }}
                        />
                    ))}
                </PerfectScrollbar>
            </div>
        </div>
    );
}

export default Thumbnails;
