import clsx from "clsx";
import { useEffect, useMemo, useRef } from "react";
import Editor from "../Editor";
import configEditor from "src/utils/configEditor";
import "./InputEditor.scss";

const inputEditorTypes = {
    default: {
        funcNameForConfig: "input",
    },
    "for-multichoice-question": {
        funcNameForConfig: "inputMultiChoiceQuestion",
    },
};

const InputEditor = ({
    suffix,
    inputType = "default", // Values: "default", "for-multichoice-question".
    value,
    onChange,
    placeholder,
    className,
    isSuffix = false,
    disabled,
    ...rest
}) => {
    const _inputType = inputEditorTypes[inputType] ? inputType : "default";
    const _inputConfigFunc = inputEditorTypes[_inputType].funcNameForConfig;

    const editorRef = useRef(null);
    const editorConfigInput = useRef(configEditor[_inputConfigFunc]());

    useEffect(() => {
        if (disabled) {
            document.activeElement.blur();
        }
    }, [disabled]);

    return (
        <div
            ref={editorRef}
            className={clsx("input-editor", className, disabled && "input-editor--disabled")}
            onMouseDown={(e) => {
                if (disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            <div className="input-editor__editor">
                <Editor
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    config={editorConfigInput.current}
                />
            </div>
            {isSuffix && <div className="input-editor__suffix">{suffix}</div>}
        </div>
    );
};

export default InputEditor;
