import { Image } from "antd";
import React, { useContext } from "react";

import "./ThumbnailsItem.scss";
import clsx from "clsx";
import BlankPNG from "src/assets/images/pdf/blankPDF.png";

function ThumbnailsItem({ data, currentThumbnail, onClick }) {
    console.log({ a: currentThumbnail.file_id, b: data.file_id });

    return (
        <div
            className={clsx("pdf-thumbnails-item", { active: currentThumbnail?.file_id === data?.file_id })}
            onClick={() => {
                onClick?.(data);
            }}
        >
            <div className="pdf-thumbnails-item_img">
                <Image preview={false} src={data?.src || BlankPNG} />
                <span className="pdf-thumbnails-item_number">{data?.page}</span>
            </div>
        </div>
    );
}

export default ThumbnailsItem;
