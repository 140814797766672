import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import { ReactComponent as PenIcon } from "src/assets/images/app-icons/pen.svg";
import "./Highlight.scss";
import { notification } from "antd";

function Highlight(props) {
    const {
        isReadonly = false,
        question,
        highlight_submit, // string
        passage_student, // string
        defaultAnswer,
        onChange = () => {},
    } = props;

    const { t } = useTranslation();

    const contentEle = useRef(null);
    const buttonEle = useRef(null);

    buttonEle.setIsActive = (value) => {
        if (value) {
            buttonEle.current.classList.add("active");
        } else {
            buttonEle.current.classList.remove("active");
        }
    };

    useEffect(() => {
        if (highlight_submit) {
            contentEle.current.querySelectorAll("span.selected").forEach((span) => {
                span.onclick = (e) => {
                    // get currentTarget value must always be the <highlight> tag
                    const target = e.currentTarget;

                    setTimeout(() => {
                        buttonEle.setIsActive(true);

                        if (target.classList.contains("selected")) {
                            target.setAttribute("data-selected", true);
                        }
                    });
                };
            });
        }
    }, [highlight_submit]);

    const handleClickPassage = () => {
        const items = Array.from(contentEle.current.querySelectorAll(".selected"));

        items.forEach((item) => {
            item.removeAttribute("data-selected");
        });

        // Remove active button highlight
        setTimeout(() => {
            const isSelected = contentEle.current.querySelectorAll(".selected[data-selected=true]");

            if (!Array.from(isSelected).length) {
                buttonEle.setIsActive(false);
            }
        });
    };

    const handleHighlight = () => {
        // Async function - Fill data-index, data-position for all <highlight> after surroundSelection run
        const reloadIndexs = () => {
            function locations(substring, string) {
                var a = [],
                    i = -1;
                while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
                return a;
            }

            let items = Array.from(contentEle.current.querySelectorAll(".selected"));

            items.forEach((item, index) => {
                // Handle set data-index
                // item.setAttribute("data-index", index + 1);

                // Remove deep tag
                if (items.length > 1) {
                    for (let i = 1; i < items.length; i++) {
                        const itemN = items[i];
                        if (item.contains(itemN) && item.outerHTML !== itemN.outerHTML) {
                            itemN.outerHTML = itemN.innerText;
                        }
                    }
                }

                // Handle set data-position
                let text = item.innerText;
                let html = item.outerHTML;
                let content = contentEle.current.innerHTML;
                let content_after = content.replace(html, text + "!").replace(/<\/?([^>]+)?>/gi, "");
                let all_indexes = locations(text, content_after);
                let position = all_indexes.indexOf(content_after.indexOf(text + "!"));

                // items.splice(0, 1);
                item.setAttribute("data-position", position);
            });
        };

        function surroundSelection() {
            var span = document.createElement("span");
            span.className = "selected";

            // Handle get focus highlight tag
            span.onclick = (e) => {
                // get currentTarget value must always be the <highlight> tag
                const target = e.currentTarget;

                setTimeout(() => {
                    buttonEle.setIsActive(true);

                    if (target.classList.contains("selected")) {
                        target.setAttribute("data-selected", true);
                    }
                });
            };

            // Handle add highlight tag for selected value
            if (window.getSelection) {
                var sel = window.getSelection();

                if (sel.rangeCount && sel.toString()) {
                    var range = sel?.getRangeAt(0).cloneRange();

                    const includesNodeNames = ["#text", "SPAN", "STRONG"];

                    const isValidNodeName = Array.prototype.every.call(range.cloneContents().childNodes, (cNode) => {
                        return includesNodeNames.includes(cNode.nodeName);
                    });

                    // Just highlight when selection content in once tag html
                    if (isValidNodeName) {
                        span.appendChild(range.extractContents());
                        range.insertNode(span);
                        sel.removeAllRanges();
                    } else {
                        notification.warn({
                            message: t("message.content_highlight_not_valid"),
                        });
                    }
                }
            }

            setTimeout(reloadIndexs);
        }

        // Handle get answer and fire onChange
        const handleFireOnchange = () => {
            let items = Array.from(contentEle.current.querySelectorAll(".selected"));

            const listAnswers = [];
            items?.forEach((item) => {
                // Regex remove all tag
                const newItem = item.innerHTML.replace(/<[^>]*>/g, "");

                // Handle get position
                listAnswers.push({
                    key: item.dataset.position,
                    value: newItem,
                });
            });

            // Sau này sẽ phải đổi answered của highlight thành listAnswer không cần passage_highlighted nhưng phải đợi a Thành sửa APi
            onChange({
                answered: listAnswers,
                passage_highlighted: contentEle.current.innerHTML,
            });
        };

        const focusingHighlight = contentEle.current.querySelector(".selected[data-selected=true]");

        if (focusingHighlight) {
            buttonEle.setIsActive(false);
            focusingHighlight.outerHTML = focusingHighlight.innerText;
        } else {
            surroundSelection();
        }

        setTimeout(handleFireOnchange);
    };

    return (
        <div className="q-highlight">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="question-editor-hint-text">
                {t("question.hint_highlight_1")}
                <button disabled type="button" className="highlight_btn">
                    <PenIcon width={18} height={18} />
                </button>
                {t("question.hint_highlight_2")}
            </div>
            <button
                disabled={isReadonly}
                type="button"
                className="highlight_btn"
                ref={buttonEle}
                onClick={handleHighlight}
            >
                <PenIcon width={18} height={18} />
            </button>

            <div
                className="content-highlight"
                ref={contentEle}
                onClick={handleClickPassage}
                dangerouslySetInnerHTML={{ __html: highlight_submit || passage_student || "" }}
            ></div>
        </div>
    );
}

export default Highlight;
