import { useEffect, useState } from "react";
import "./Dropdown.scss";

const Dropdown = ({ children, overlay, className, overlayClassName, visible, onVisibleChange, ...rest }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleTriggerDropdown = (e, triggerType) => {
        if (triggerType === "click") {
            setIsVisible(!isVisible);
            if (onVisibleChange instanceof Function) {
                onVisibleChange(!isVisible);
            }
        }
    };

    useEffect(() => {
        if (visible === true || visible === false) {
            setIsVisible(visible);
        }
    }, [visible]);

    // Dropdown extra class names:
    let extraClassnames = "";
    if (className) {
        extraClassnames += ` ${className}`;
    }
    if (isVisible) {
        extraClassnames += ` app-dropdown-open`;
    }

    // Dropdown overlay extra class names:
    let extraClassnamesOverlay = "";
    if (overlayClassName) {
        extraClassnamesOverlay += ` ${overlayClassName}`;
    }

    return (
        <div className={`app-dropdown menu-as-child${extraClassnames}`} {...rest}>
            <div
                className="app-dropdown-trigger"
                onClick={(e) => {
                    handleTriggerDropdown(e, "click");
                }}
            >
                {children}
            </div>
            <div className={`app-dropdown-content${extraClassnamesOverlay}`}>
                <div>{overlay}</div>
            </div>
        </div>
    );
};

export default Dropdown;
