import { useSelector } from "react-redux";
import DetailClass from "src/modules/containers/Class/containers/DetailClass";

const DetailClassInScope = () => {
    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};

    // DetailClass:
    const fetchParamsGetDetail = {
        class_in_organization: 1,
        organization_id: fParamsUserScope?.organization_id,
    };

    return <DetailClass fetchParamsGetDetail={fetchParamsGetDetail} />;
};

export default DetailClassInScope;
