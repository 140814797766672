import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import "./NumberlineLabelDraggable.scss";

function NumberlineLabelDraggable({
    className,
    children,
    customDragElement,
    dropzoneCssSelectors /** @param {string} dropzoneCssSelectors. String of class names (comma separeted). */,
    position,
    positionFixedDirection,
    isFixedPosBeforeDrag = true,
    cancel,
    onDragStart,
    onDragStop,
    isDisabled,
}) {
    const childRef = useRef(false);
    const dragzoneRef = useRef(false);
    const timeoutDrag = useRef(false);
    const isDragOverTarget = useRef(false);

    const [isFixedPos, setIsFixedPos] = useState(false);

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (isFixedPos) {
            r += " is-fixed-pos";
        }
        return r;
    }, [className, isFixedPos]);

    const toggleFixedPos = (_x, _y, _width, _height) => {
        if (_x && _y) {
            setIsFixedPos(true);
            childRef.current.style.position = "fixed";
            // Position values:
            switch (positionFixedDirection) {
                case "right-top": {
                    childRef.current.style.right = `${_x}px`;
                    childRef.current.style.top = `${_y}px`;
                    break;
                }
                case "left-top":
                default: {
                    childRef.current.style.left = `${_x}px`;
                    childRef.current.style.top = `${_y}px`;
                    break;
                }
            }
            // Width & height:
            childRef.current.style.width = `${_width}px`;
            childRef.current.style.height = `${_height}px`;
        } else {
            setIsFixedPos(false);
            childRef.current.style.position = "";
            childRef.current.style.left = "";
            childRef.current.style.right = "";
            childRef.current.style.top = "";
            childRef.current.style.bottom = "";
            childRef.current.style.width = "";
            childRef.current.style.height = "";
        }
    };

    const startDrag = (_event, _draggedWhat) => {
        if (onDragStart instanceof Function) {
            onDragStart(_event, _draggedWhat);
        }
    };

    const stopDrag = (_event, _droppedWhat, _droppedAtTarget) => {
        if (onDragStop instanceof Function) {
            onDragStop(_event, _droppedWhat, _droppedAtTarget);
        }
    };

    const handleDragBeforeStart = useCallback(
        (e) => {
            if (dragzoneRef.current) {
                if (isFixedPosBeforeDrag === true) {
                    const elem = dragzoneRef.current.getBoundingClientRect();
                    switch (positionFixedDirection) {
                        case "right-top": {
                            toggleFixedPos(
                                document.body.clientWidth - elem.left - elem.width,
                                elem.top,
                                elem.width,
                                elem.height
                            );
                            break;
                        }
                        case "left-top":
                        default: {
                            toggleFixedPos(elem.left, elem.top, elem.width, elem.height);
                            break;
                        }
                    }
                }
            }
        },
        [dragzoneRef.current]
    );

    const handleDragStart = useCallback(
        (e) => {
            startDrag(e, childRef.current);
        },
        [childRef.current]
    );

    const handleDrag = useCallback(
        (e) => {
            // Handle dragging:
            clearTimeout(timeoutDrag.current);
            timeoutDrag.current = setTimeout(() => {
                if (childRef.current) {
                    // WHAT TO DO HERE?
                    // Take the "draggable" element and drop it onto "droppable" element.
                    // But how to find and check if an element is "droppable" element? How to handle dropping?
                    // Note that using mouseover/mouseup on "droppable" element won't work! Because,
                    // while we're dragging, the draggable element is always above other elements.

                    // Checking device type:
                    let currE = undefined;
                    switch (e.type) {
                        case "mousemove":
                            currE = e;
                            break;
                        case "touchmove":
                            currE = e.touches[0];
                            break;
                        default:
                            break;
                    }

                    // 1. Hide the above element and get the below one:
                    childRef.current.hidden = true;
                    const elemBelowChild = document.elementFromPoint(currE.clientX, currE.clientY);
                    childRef.current.hidden = false;

                    // 2. Check "droppable" element and handle dropping:
                    let dropzone = elemBelowChild ? elemBelowChild.closest(dropzoneCssSelectors) : false;
                    if (dropzone) {
                        // Add CSS effect:
                        dropzone.classList.add("under-dragelem");
                        // Remove CSS effect of previous drop target:
                        if (isDragOverTarget.current?.id !== dropzone.id) {
                            isDragOverTarget.current?.classList?.remove("under-dragelem");
                        }
                        // Store current drop target:
                        isDragOverTarget.current = dropzone;
                    } else {
                        // Remove CSS effect of previous drop target:
                        isDragOverTarget.current?.classList?.remove("under-dragelem");
                    }
                }
            }, 100);
        },
        [childRef.current]
    );

    const handleDrop = useCallback(
        (e) => {
            toggleFixedPos();
            // Handle dropping:
            clearTimeout(timeoutDrag.current);
            if (childRef.current) {
                // WHAT TO DO HERE?
                // Take the "draggable" element and drop it onto "droppable" element.
                // But how to find and check if an element is "droppable" element? How to handle dropping?
                // Note that using mouseover/mouseup on "droppable" element won't work! Because,
                // while we're dragging, the draggable element is always above other elements.

                // Checking device type:
                let currE = undefined;
                switch (e.type) {
                    case "mouseup":
                        currE = e;
                        break;
                    case "touchend":
                        currE = e.changedTouches[0];
                        break;
                    default:
                        break;
                }

                // 1. Hide the above element and get the below one:
                childRef.current.hidden = true;
                const elemBelowChild = document.elementFromPoint(currE.clientX, currE.clientY);
                childRef.current.hidden = false;

                // 2. Check "droppable" element and handle dropping:
                let dropzone = elemBelowChild ? elemBelowChild.closest(dropzoneCssSelectors) : false;
                if (dropzone) {
                    stopDrag(currE, childRef.current, dropzone);
                } else {
                    stopDrag(currE, childRef.current);
                }

                // 3. Remove CSS effect of previous drop target:
                isDragOverTarget.current?.classList?.remove("under-dragelem");
            }
        },
        [childRef.current]
    );

    return (
        <div className={`nllbl-drg-wrapper${extraClassnames}`}>
            <Draggable
                position={position}
                cancel={cancel} // Prevent dragging on elements that has this class name.
                onMouseDown={handleDragBeforeStart}
                onStart={handleDragStart}
                onDrag={handleDrag}
                onStop={handleDrop}
                disabled={isDisabled}
            >
                <div ref={childRef} className="nllbl-drg">
                    <div className="drg-wrapper">
                        <div className="drg-zone" ref={dragzoneRef}>
                            {/* Drag zone is very important! It allows you to drag and auto-scroll. */}
                            {/* By default, react-draggable has this feature but not work in this case! */}
                        </div>
                        <div className="drg-content">{customDragElement}</div>
                    </div>
                </div>
            </Draggable>
            {children}
        </div>
    );
}

export default NumberlineLabelDraggable;
