import React from "react";
import moment from "moment";
import { t } from "i18next";
import { Upload, notification } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import { useValues } from "src/hooks";
import { downloadSampleUserListFile, importUserListToClass } from "src/api/containers/class";
import "./ImportUsers.scss";

const ImportUsers = ({ classId = "", onError, onSuccess }) => {
    const [values, setValues] = useValues({
        loading: false, // Values: "download-sample", "upload", false.
    });

    const handleError = () => {
        if (onError instanceof Function) {
            onError();
        }
    };

    const handleSuccess = () => {
        if (onSuccess instanceof Function) {
            onSuccess();
        }
    };

    const uploadFile = async (options) => {
        const { onSuccess, onError, file } = options;

        const formData = new FormData();
        formData.append("files", file);

        setValues({ loading: "upload" });
        importUserListToClass(classId, formData).then(({ status, message }) => {
            setValues({ loading: false });
            if (status) {
                notification.success({
                    message: message || t("message.add_success"),
                });
                handleSuccess();
            } else {
                notification.error({
                    message: message || t("message.add_error"),
                });
                handleError();
            }
        });
    };

    const handleDownloadSampleFile = () => {
        if (values.loading === "download-sample") {
            return;
        }
        setValues({ loading: "download-sample" });
        downloadSampleUserListFile()
            .then((data) => {
                setValues({ loading: false });

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${"class_users_template"}_${moment().format("YYMMDDHHmm")}.xlsx`); //or any other extension
                link.click();
            })
            .catch((err) => {
                setValues({ loading: false });
                notification.error({ message: err || t("shared.something_went_wrong") });
            });
    };

    return (
        <div className="importusers">
            <Upload
                name="file"
                accept=".xls,.xlsx,.csv"
                // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                customRequest={uploadFile}
                multiple={false}
                showUploadList={false}
                maxCount={1}
                className="importusers-btn"
            >
                <CustomButton
                    type="normal"
                    icon={<CloudUploadOutlined />}
                    title={t("class.import_user")}
                    isLoading={values.loading === "upload"}
                ></CustomButton>
            </Upload>
            <div className="importusers-subbtn" onClick={handleDownloadSampleFile}>
                ({t("download_sample_file_to_import")})
            </div>
        </div>
    );
};

export default ImportUsers;
