import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Dropdown, Empty, Menu, notification, Popconfirm, Row, Space } from "antd";
import { FieldTimeOutlined, UserOutlined, EllipsisOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import { default as CustomButton } from "src/modules/components/Button";
import ModalDetailEntryTestLevel from "../ModalDetailEntryTestLevel";
import Icon from "src/modules/components/Icon";
import { useRouting } from "src/utils/router";
import { Link, useNavigate, useParams } from "react-router-dom";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { ReviewIcon } from "src/utils/drawer";
import Pencil from "src/assets/images/pencil.svg";

import { useValues } from "src/hooks";
import "./EntryTestLevel.scss";
import Tooltip from "src/modules/components/Tooltip";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
import { useMemo } from "react";
import CustomizeRenderEmpty from "src/modules/components/CustomizeRenderEmpty";
import { deleteEntryTestLevel } from "src/api/containers/entry_test";
import ModalStudentRecords from "../ModalStudentRecords";

const EntryTestLevel = ({
    handleRefetchAsignment = () => {},
    entryTestLevel = {},
    listAssignments = [], //list vòng thi
}) => {
    const { accountType, user } = useSelector((state) => state.auth);
    const { generate } = useRouting();
    const navigate = useNavigate();
    const { id: assignmentId } = useParams();

    const { rolesAllow, pagesAllow, checkPermission } = permissionUser;

    const checkRoles = useMemo(() => {
        return {
            edit_competition: checkPermission(pagesAllow.competition, rolesAllow.update, user),
            delete_competition: checkPermission(pagesAllow.competition, rolesAllow.delete, user),
        };
    }, [user]);

    const [values, setValues] = useValues({
        isShowModalDetailRound: false,
        classId: "",
        classScopeId: "",
        editingAssignment: {},
        isBeginning: true,
        isEnd: false,
        isModalViewRecord: false,
        testLevelInputId: "",
    });

    const handleDeleteRound = (id) => {
        if (id) {
            deleteEntryTestLevel(id).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload class list:
                    handleRefetchAsignment();
                } else {
                    notification.error({
                        message: res.message || "",
                    });
                }
            });
        }
    };

    const renderMenu = (item) => {
        if (!item) {
            return null;
        }

        return (
            <Menu className="class-item-menu">
                {/* {(checkRoles.edit_competition || !user?.currentOrg) && ( */}
                <Menu.Item
                    key="menu-item-1"
                    onClick={() => {
                        setValues({
                            isShowModalDetailRound: true,
                            classId: item.id,
                            classScopeId: getItemScopeSelected(item).scopeId,
                            editingAssignment: item,
                        });
                    }}
                    // disabled={checkDemoAccountAction(accountType, "class", "update") === false}
                >
                    <Space className="class-item-menu-btn-wrapper">
                        <Icon name="icon-modify" />
                        {t("shared.update")}
                    </Space>
                </Menu.Item>
                {/* )} */}

                {/* {(checkRoles.delete_competition || !user?.currentOrg) && ( */}
                <Popconfirm
                    title={`${t("shared.msg_confirm_delete")}?`}
                    onConfirm={() => {
                        handleDeleteRound(item.id);
                    }}
                    okText={t("shared.yes")}
                    cancelText={t("shared.no")}
                    style={{ backgroundColor: "unset" }}
                >
                    <Menu.Item
                        key="menu-item-2"
                        disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                    >
                        <Space className="class-item-menu-btn-wrapper">
                            <Icon name="icon-delete" />
                            {t("shared.delete")}
                        </Space>
                    </Menu.Item>
                </Popconfirm>
                {/* )} */}
            </Menu>
        );
    };

    const getRoundStatus = (curentDate, beginDate, endDate) => {
        if (moment(curentDate).isBefore(beginDate)) {
            return "upcoming";
        }
        if (moment(curentDate).isAfter(endDate)) {
            return "finished";
        }
        if (moment(curentDate).isAfter(beginDate) && moment(curentDate).isBefore(endDate)) {
            return "happening";
        }
        return "";
    };

    const handleChangeSlide = (type) => {
        if (type === "prev") {
            swiperRef.current?.slidePrev();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        } else if (type === "next") {
            swiperRef.current?.slideNext();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        }
    };

    return (
        <div className="class-list rounds">
            <Row gutter={[30, 30]} wrap="true">
                <Col span={24}>
                    <div className="rounds-header">
                        <div className="rounds-header-title">
                            <h2>{entryTestLevel?.name}</h2>
                        </div>

                        {/* <Can I={rolesAllow.create} page={pagesAllow.competition} orLogic={() => !user?.currentOrg}> */}
                        <CustomButton
                            type="primary"
                            icon={Pencil}
                            title={`${t("shared.create")} ${t("entry_test.level").toLowerCase()}`}
                            onClick={() => {
                                setValues({ isShowModalDetailRound: true, classId: "", classScopeId: "" });
                            }}
                            disabled={false}
                        ></CustomButton>
                        {/* </Can> */}
                    </div>
                </Col>
            </Row>

            {/* <br /> */}
            <Row gutter={[30, 30]}>
                {listAssignments.map((item, index) => (
                    <Col key={index} xs={24} md={12} lg={8} xxl={6}>
                        <div className="class-item-wrapper" style={{ minWidth: 200 }}>
                            <div
                                className="class-item"
                                // onClick={() => navigate({ pathname: generate("ranking", { id: item.id }) })}
                            >
                                <div className="class-item-header-top"></div>

                                <Tooltip title={item?.name}>
                                    <p className="class-item-header">{item?.name}</p>
                                </Tooltip>

                                <p>
                                    <FieldTimeOutlined className="class-item-icon" />

                                    {`${t("class.name")}: `}

                                    <Link target="_blank" to={generate("detail_class", { id: item?.class?.id || "#" })}>
                                        {`${item?.class?.name}`}
                                    </Link>
                                </p>

                                <p>
                                    <CheckCircleOutlined className="class-item-icon" />
                                    {`${t("entry_test.score")}: ${item.score}`}
                                </p>
                                <p>
                                    <UserOutlined className="class-item-icon" />
                                    {`${t("shared.owner")}: ${item?.user?.name || ""}`}
                                </p>
                                <CustomButton
                                    title={t("report.student_list")}
                                    icon={<ReviewIcon />}
                                    type="primary"
                                    isDisabled={false}
                                    onClick={() => {
                                        setValues({ isModalViewRecord: true, testLevelInputId: item.id });
                                    }}
                                />
                            </div>
                            <Dropdown overlay={renderMenu(item)} trigger={["click"]} placement="bottomRight">
                                <Button className="class-item-dropdown-btn">
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                ))}
            </Row>

            {listAssignments.length <= 0 && <CustomizeRenderEmpty />}

            <ModalDetailEntryTestLevel
                // isRoundEnded={competition}
                entryTestLevel={entryTestLevel}
                visible={values.isShowModalDetailRound}
                classId={values.classId}
                editingAssignment={values.editingAssignment}
                previousItem={(function () {
                    if (values.editingAssignment?.id) {
                        return values.editingAssignment?.order > 1
                            ? listAssignments?.[values.editingAssignment.order - 2]
                            : {};
                    } else {
                        if (listAssignments?.length > 0) {
                            return listAssignments?.[listAssignments?.length - 1];
                        }
                    }
                    return {};
                })()}
                nextItem={(function () {
                    if (values.editingAssignment?.id) {
                        return listAssignments?.[values.editingAssignment.order] || {};
                    }
                    return {};
                })()}
                organization={values.classScopeId ? { organization_id: values.classScopeId } : undefined}
                onOk={() => {
                    handleRefetchAsignment();
                    setValues({ isShowModalDetailRound: false });
                }}
                onCancel={() => {
                    setValues({ isShowModalDetailRound: false, editingAssignment: {} });
                }}
                destroyOnClose
            ></ModalDetailEntryTestLevel>

            {values.isModalViewRecord && (
                <ModalStudentRecords
                    visible={values.isModalViewRecord}
                    assignmentId={assignmentId}
                    testLevelInputId={values.testLevelInputId}
                    title={t("report.student_list")}
                    onCancel={() => setValues({ isModalViewRecord: false, testLevelInputId: "" })}
                    bodyStyle={{ padding: 20 }}
                />
            )}
        </div>
    );
};

export default EntryTestLevel;
