import { generateConfig } from "./_default";

/**
 * Editor (input - Plain text format).
 * IMPORTANT NOTES:
 * - Input's value has to contain only HTML elements with 'display: inline;'.
 * - Input's value hasn't to include <br>, <div>, <p> or any HTML tag with with 'display: block | inline-block;'.
 * @param {Object} values Custom config values.
 * @returns Editor's config.
 */
export const generateConfigForInputMultiChoiceQuestion = (values = {}) => {
    const {
        // Counting & limiting words/characters:
        wordCountMax,
        onCountWord,
    } = values;

    const { setup, ...rest } = generateConfig({
        customTypeName: "input",
        editingMode: "inline",
        isToolbarInsideEditorRoot: false,
        wordCountMax,
        onCountWord,
    });

    return {
        ...rest,

        // Plugins, toolbar, menubar:
        plugins: "paste-options" + " image imagetools file-manager" + " math",
        toolbar: "undo redo" + " | image file-manager" + " | math",
        toolbar_mode: "sliding",
        menubar: false,
        contextmenu: "image imagetools",
        quickbars_selection_toolbar: false,
        quickbars_insert_toolbar: false,

        // Settings:
        forced_root_block: false, // Setting 'forced_root_block: false' has been deprecated in TinyMCE 5.10.
        image_dimensions: false, // Disable the image dimensions input field in the image dialog.

        // Elements:
        invalid_elements: "hr",

        // Custom settings:
        setup: (editor) => {
            if (setup instanceof Function) {
                setup(editor);
            }

            editor.on("Change", function (e) {
                /**
                 * Removing `width`, `height` attribute after pasting content with images.
                 */
                const imgs = e.target?.targetElm?.getElementsByTagName("img");
                for (let i = 0; i < imgs.length; i++) {
                    imgs[i].removeAttribute("width");
                    imgs[i].removeAttribute("height");
                }
            });
        },
    };
};
