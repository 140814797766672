import { Radio } from "antd";
import parser from "src/utils/parser";
import LatexDangerously from "src/modules/components/LatexDangerously";
import "./MyTable.scss";

const MyTable = (props) => {
    const {
        correctAnswer1 /** @param  correctAnswer1: string[] */,
        correctAnswer2 /** @param correctAnswer2: {key: number, value: string}[]  */,
        value: correctMatch,
        isReadonly,
        onChange,
    } = props;

    const handleChangeMatch = (e, iRow, iCol) => {
        const isChecked = e.target.checked;

        const newCorrectMatch = [...correctMatch];

        if (newCorrectMatch[iRow]) newCorrectMatch[iRow] = "";

        if (isChecked) {
            newCorrectMatch[iRow] = iCol;

            if (onChange && onChange instanceof Function) onChange(newCorrectMatch);
        }
    };

    return (
        <div className="questionpanel__multiplechoicetable__table">
            <div className="multiplechoicetable__table-skin">
                <table className="multiplechoicetable__table__skeleton">
                    <tbody>
                        <tr className="sticky-row">
                            <td className="sticky-col first-col"></td>
                            {correctAnswer2.map((casr2, i) => (
                                <td className="multiplechoicetable_item text-center" key={i}>
                                    <LatexDangerously innerHTML={casr2.value} />
                                </td>
                            ))}
                        </tr>
                        {correctAnswer1.map((casr1, i) => (
                            <tr key={i}>
                                <td className="multiplechoicetable_item sticky-col first-col">
                                    <div>
                                        <LatexDangerously innerHTML={casr1} />
                                    </div>
                                </td>

                                {/* Td value */}
                                {correctAnswer2.map((casr2, iCol) => {
                                    return (
                                        <td className="multiplechoicetable_item text-center" key={iCol}>
                                            <Radio
                                                className="app-radio"
                                                disabled={isReadonly}
                                                onChange={(e) => handleChangeMatch(e, i, casr2.key)}
                                                checked={correctMatch[i] === casr2.key}
                                            ></Radio>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MyTable;
