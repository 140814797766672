import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Form, Input, Modal, notification, Row, Select, Spin } from "antd";
import moment from "moment";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import { compareArrays } from "src/utils/helpers";
import { fetchRoles } from "src/api/containers/role";
import { fetchBranches } from "src/api/containers/branch";
import { fetchClasses } from "src/api/containers/class";
import { addUserToOrganization, updateUserInOrganization } from "src/api/containers/organization";
import { permissionUser } from "src/utils/permission";
import "./ModalDetailTeacher.scss";
import actionClose from "src/assets/images/action-close.svg";

// NOTES:
// - This component is the same structure as ModalDetailStudent/index.js.
// - No code in this component is useless! If you want to modify, please make sure that all cases work well!

const ModalDetailTeacher = ({
    modalClassName = "",
    organization_id,
    selectedUser,
    onOk = () => {},
    onCancel = () => {},
    ...rest
}) => {
    const { t } = useTranslation();

    // User:
    const { user } = useSelector((state) => state.auth);
    // Date format:
    const { formatDate } = useSelector((state) => state.general);

    // Form:
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false); // Values: "submitting", "change-branch", "find-class", true||false.
    const [data, setData] = useState({
        roleList: [],
        // Branches and classes:
        branchList: [],
        classList: [],
        classListFiltered: [],
        classGroups: [],
        branchSelected: undefined,
        classSelected: undefined,
    });
    const [settings, setSettings] = useState({
        isBranchDropdownVisible: false,
    });
    const timeoutIds = useRef({
        findClass: null,
    });

    // User's permissions:
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;
    const userPermission = useMemo(
        () => ({
            role_list: checkPermission(pagesAllow.role, rolesAllow.list, user),
        }),
        [user]
    );

    const getClassGroups = (classes) => {
        if (!data.branchSelected?.length) {
            return [];
        }

        const r = [];
        // Get the list of selected groups:
        for (let i = 0; i < data.branchSelected.length; i++) {
            let branchIndex = -1;
            for (let j = 0; j < data.branchList.length; j++) {
                if (data.branchSelected[i] === data.branchList[j].id) {
                    branchIndex = j;
                    break;
                }
            }
            if (branchIndex > -1) {
                r.push({
                    branchIndex: branchIndex,
                    classes: [],
                });
            }
        }
        // Get class list for each group:
        for (let i = 0; i < classes.length; i++) {
            for (let j = 0; j < data.branchSelected.length; j++) {
                if (classes[i].branch_id === data.branchSelected[j]) {
                    r[j]?.classes?.push(classes[i]);
                    break;
                }
            }
        }
        return r.filter((item) => item.classes.length > 0);
    };

    const handleOk = () => {
        form.resetFields();
        setData({
            ...data,
            classList: [],
            classListFiltered: [],
            branchSelected: undefined,
            classSelected: undefined,
        });
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setData({
            ...data,
            classList: [],
            classListFiltered: [],
            branchSelected: undefined,
            classSelected: undefined,
        });
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmitForm = (formData) => {
        if (!organization_id && loading !== "submitting") {
            return;
        }

        setLoading("submitting");

        // Preparation:
        const { roles, branch_ids, class_ids, ...rest } = formData;
        const userInfo = {
            ...(selectedUser?.id ? { id: selectedUser.id } : {}),
            role_ids: roles || [],
            branch_ids: branch_ids || [],
            class_ids: class_ids || [],
            ...rest,
            organization_id: organization_id,
        };

        // Make api calls:
        if (selectedUser?.id) {
            updateUserInOrganization("teacher", selectedUser?.id, userInfo).then((res) => {
                setLoading(false);
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        } else {
            addUserToOrganization("teacher", userInfo).then((res) => {
                setLoading(false);
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleChangeBranch = (branchIds) => {
        if (branchIds?.length) {
            setLoading("change-branch");
            fetchClasses({ branch_ids: branchIds, organization_id: organization_id }).then((res) => {
                setLoading(false);
                if (res.status && res.data?.length) {
                    setData({
                        ...data,
                        classList: res.data,
                        branchSelected: branchIds,
                    });
                }
            });
        } else {
            setData({
                ...data,
                classList: [],
                branchSelected: undefined,
                classSelected: undefined,
            });
            form.setFieldsValue({
                ...form.getFieldsValue(),
                branch_ids: undefined,
                class_ids: undefined,
            });
        }
    };

    // const handleChangeClass = (classIds) => {
    //     if (classIds?.length) {
    //         let selected = [];
    //         for (let i = 0; i < data.classList.length; i++) {
    //             if (classIds.includes(data.classList[i].id || "_")) {
    //                 selected.push(data.classList[i]);
    //             }
    //         }
    //         setData({
    //             ...data,
    //             classSelected: selected,
    //         });
    //     } else {
    //         setData({
    //             ...data,
    //             classListFiltered: [],
    //             classSelected: undefined,
    //         });
    //     }
    // };

    const handleChangeSelectedBranch = (action, branchId) => {
        if (!form.getFieldValue("branch_ids")?.length) {
            setData({
                ...data,
                classList: [],
                classListFiltered: [],
                classGroups: [],
                branchSelected: undefined,
                classSelected: undefined,
            });
            form.setFieldsValue({
                ...form.getFieldsValue(),
                branch_ids: undefined,
                class_ids: undefined,
            });
            return;
        }
        switch (action) {
            // 1. Select a branch:
            case "select": {
                return;
            }
            // 2. If a branch is removed, the selected classes of this branch should be removed too!
            case "deselect": {
                if (branchId) {
                    // Update the list of selected classes:
                    let newClassSelected = data.classSelected;
                    let newClassIdSelected = form.getFieldValue("class_ids");
                    if (newClassSelected?.length) {
                        newClassSelected = newClassSelected.filter((item) => item.branch_id !== branchId);
                        newClassIdSelected = newClassSelected.map((item) => item.id);
                    }
                    // Update form and states:
                    setData({
                        ...data,
                        classList: data.classList.filter((item) => item.branch_id !== branchId),
                        classListFiltered: data.classListFiltered.filter((item) => item.branch_id !== branchId),
                        branchSelected: data.branchSelected?.length
                            ? data.branchSelected.filter((item) => item !== branchId)
                            : undefined,
                        classSelected: newClassSelected,
                    });
                    form.setFieldsValue({ class_ids: newClassIdSelected });
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        class_ids: newClassIdSelected,
                    });
                }
                return;
            }
            default:
                return;
        }
    };

    const handleChangeSelectedClass = (action, classId) => {
        if (!form.getFieldValue("class_ids")?.length) {
            setData({
                ...data,
                classListFiltered: [],
                classSelected: undefined,
            });
            return;
        }
        switch (action) {
            case "select": {
                if (classId) {
                    let selectedItems = data.classSelected || [];
                    const currClassList = [...data.classList, ...data.classListFiltered];
                    for (let i = 0; i < currClassList.length; i++) {
                        if (classId === currClassList[i].id) {
                            selectedItems.push(currClassList[i]);
                            break;
                        }
                    }
                    setData({
                        ...data,
                        classSelected: selectedItems,
                    });
                }
                return;
            }
            case "deselect": {
                if (classId) {
                    let selectedItems = data.classSelected || [];
                    setData({
                        ...data,
                        classSelected: selectedItems.filter((item) => item.id !== classId),
                    });
                }
                return;
            }
            default:
                return;
        }
    };

    const handleSearchClass = (keyword) => {
        if (!data.branchSelected?.length) {
            return;
        }
        clearTimeout(timeoutIds.current.findClass);
        if (keyword && typeof keyword === "string") {
            setLoading("find-class");
            timeoutIds.current.findClass = setTimeout(() => {
                fetchClasses({ branch_ids: data.branchSelected, code: keyword }).then((res) => {
                    setLoading(false);
                    if (res.status && res.data?.length) {
                        setData({
                            ...data,
                            classListFiltered: res.data,
                        });
                    }
                });
            }, 500);
        } else {
            setData({
                ...data,
                classListFiltered: [],
            });
        }
    };

    // useEffect(() => {
    //     setLoading(true);
    //     fetchRoles({ noPagination: true }).then((res) => {
    //         setLoading(false);
    //         if (res.status) {
    //             setData({
    //                 ...data,
    //                 roleList: res.data,
    //             });
    //         }
    //     });
    // }, []);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchBranches({ active: 1, noPagination: true, organization_id: organization_id }),
            ...(userPermission.role_list === true
                ? [fetchRoles({ noPagination: true, organization_id: organization_id })]
                : []),
        ]).then((res) => {
            setLoading(false);
            setData({
                ...data,
                branchList: res[0].data?.length ? res[0].data : [],
                roleList: res[1]?.data?.length ? res[1].data : [],
            });
        });
    }, []);

    useEffect(() => {
        if (rest.visible && selectedUser?.id) {
            // Get selected branches and classes:
            let newBranchSelected = undefined;
            let newClassSelected = undefined;
            if (selectedUser.branches?.length) {
                newBranchSelected = selectedUser.branches.map((item) => item.id);
            }
            if (selectedUser.class_detail_in_organization?.length) {
                newClassSelected = selectedUser.class_detail_in_organization;
            }
            // Update form and states:
            form.setFieldsValue({
                first_name: selectedUser.first_name,
                last_name: selectedUser.last_name,
                username: selectedUser.username,
                phone: selectedUser.phone,
                address: selectedUser.address,
                gender: selectedUser.gender,
                roles: selectedUser.roles?.length ? selectedUser.roles.map((item) => item.id) : undefined,
                branch_ids: newBranchSelected,
                class_ids: newClassSelected?.length ? newClassSelected.map((item) => item.id) : undefined,
            });
            if (newBranchSelected?.length) {
                setData({
                    ...data,
                    classList: newClassSelected || [],
                    branchSelected: newBranchSelected,
                    classSelected: newClassSelected,
                });
                if (!newClassSelected?.length) {
                    handleChangeBranch(newBranchSelected);
                }
            }
        }
    }, [rest.visible, selectedUser]);

    useEffect(() => {
        if (data.classListFiltered.length) {
            setData({
                ...data,
                classGroups: getClassGroups(data.classListFiltered),
            });
        } else if (data.classList.length) {
            setData({
                ...data,
                classGroups: getClassGroups(data.classList),
            });
        } else {
            setData({
                ...data,
                classGroups: [],
            });
        }
    }, [data.classList, data.classListFiltered]);

    return (
        <Modal
            centered
            footer={null}
            title={selectedUser ? t("organization.update_user") : t("organization.add_user")}
            closeIcon={<img src={actionClose}></img>}
            onOk={handleOk}
            onCancel={handleCancel}
            className={`app-modal type-basic flexible-height modal-detail-teacher${
                modalClassName ? " " + modalClassName : ""
            }`}
            {...rest}
        >
            <Spin wrapperClassName="app-spin-nested-loading layout-full" spinning={["submitting"].includes(loading)}>
                <Form form={form} onFinish={handleSubmitForm}>
                    <Form.Item
                        name="first_name"
                        label={t("organization.first_name")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.first_name")} />
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label={t("organization.last_name")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.last_name")} />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label={t("organization.email")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                            {
                                type: "email",
                                message: t("message.invalid_email"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.email")} />
                    </Form.Item>

                    <Form.Item name="phone" label={t("organization.phone")}>
                        <Input className="app-input" placeholder={t("organization.phone")} />
                    </Form.Item>

                    <Form.Item name="address" label={t("organization.address")}>
                        <Input className="app-input" placeholder={t("organization.address")} />
                    </Form.Item>

                    <Form.Item name="gender" label={t("shared.gender")}>
                        <Select
                            className="app-select show-arrow"
                            placeholder={t("profile.select_gender")}
                            showArrow={false}
                        >
                            <Select.Option value={1}>{t("shared.male")}</Select.Option>
                            <Select.Option value={0}>{t("shared.female")}</Select.Option>
                        </Select>
                    </Form.Item>

                    <hr className="hr-between-formitems" />

                    {userPermission.role_list === true && (
                        <Form.Item
                            name="roles"
                            label={
                                <>
                                    <span>{t("manage_role.role")}</span>
                                    <CustomTooltip
                                        type="question"
                                        placement="right"
                                        title={t("organization.teacher_detail_roles_description")}
                                        style={{ order: "4", marginLeft: "4px" }}
                                    />
                                </>
                            }
                        >
                            <Select
                                className="app-select show-arrow"
                                mode="multiple"
                                placeholder={t("manage_role.select_roles")}
                                optionFilterProp="children"
                                showArrow={false}
                                showSearch
                                allowClear
                            >
                                {data.roleList.map((roleItem, roleIndex) => {
                                    return (
                                        <Select.Option key={`role${roleIndex}`} value={roleItem.id}>
                                            {roleItem.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    <Spin spinning={["change-branch"].includes(loading)}>
                        <Form.Item name="branch_ids" label={t("branch.branch")}>
                            <Select
                                mode="multiple"
                                className="app-select show-arrow"
                                placeholder={t("branch.select_branch")}
                                showArrow={false}
                                allowClear
                                value={data.branchSelected}
                                // onChange={(values) => {
                                //     if (!settings.isBranchDropdownVisible) {
                                //         handleChangeBranch(values);
                                //     }
                                // }}
                                onDropdownVisibleChange={(isOpen) => {
                                    setSettings({
                                        ...settings,
                                        isBranchDropdownVisible: isOpen,
                                    });
                                    const selectedItemsCurr = form.getFieldsValue().branch_ids;
                                    const selectedItemsBefore = data.branchSelected;
                                    if (
                                        !isOpen &&
                                        !compareArrays("compare-values", selectedItemsCurr, selectedItemsBefore)
                                    ) {
                                        handleChangeBranch(form.getFieldsValue().branch_ids);
                                    }
                                }}
                                // onSelect={(branchId) => handleChangeSelectedBranch("select", branchId)}
                                onDeselect={(branchId) => handleChangeSelectedBranch("deselect", branchId)}
                                optionFilterProp="children"
                            >
                                {data.branchList.map((item, itemIndex) => {
                                    return (
                                        <Select.Option key={`branch-${itemIndex}`} value={item.id}>
                                            {t(item.name)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item name="class_ids" label={t("class.code")}>
                            <Select
                                mode="multiple"
                                className="app-select show-arrow"
                                placeholder={t("class.find_and_select_class")}
                                showArrow={false}
                                allowClear
                                showSearch
                                value={data.classSelected?.map((item) => item.id)}
                                // onChange={handleChangeClass}
                                onSelect={(classId) => handleChangeSelectedClass("select", classId)}
                                onDeselect={(classId) => handleChangeSelectedClass("deselect", classId)}
                                onSearch={handleSearchClass}
                                optionFilterProp="children"
                                notFoundContent={
                                    loading === "find-class" ? (
                                        <i>
                                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                                        </i>
                                    ) : undefined
                                }
                            >
                                {/* {(data.classListFiltered.length ? data.classListFiltered : data.classList).map(
                                (item, itemIndex) => {
                                    return (
                                        <Select.Option key={`class-${itemIndex}`} value={item.id}>
                                            {t(item.code)}
                                        </Select.Option>
                                    );
                                }
                            )} */}
                                {data.classGroups?.map((groupItem, groupIndex) => {
                                    return (
                                        <Select.OptGroup
                                            key={`group-${groupIndex}-class`}
                                            label={data.branchList[groupItem.branchIndex]?.name}
                                        >
                                            {groupItem.classes.length ? (
                                                groupItem.classes.map((item, itemIndex) => {
                                                    return (
                                                        <Select.Option
                                                            key={`group-${groupIndex}-class${itemIndex}`}
                                                            value={item.id}
                                                        >
                                                            {t(item.code)}
                                                        </Select.Option>
                                                    );
                                                })
                                            ) : (
                                                <Select.Option key={`group-${groupIndex}-empty`} disabled>
                                                    {t("shared.no_data")}
                                                </Select.Option>
                                            )}
                                        </Select.OptGroup>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {data.classSelected ? (
                            <Form.Item label={t("shared.confirm")} className="hide-label info-confirmation-wrapper">
                                {data.classSelected.map((item, itemIndex) => {
                                    return (
                                        <Row key={`confirm-class-${itemIndex}`} className="info-confirmation-box">
                                            <Col xs={24} sm={8} xl={8}>
                                                {t("class.name")}:
                                            </Col>
                                            <Col xs={24} sm={16} xl={16}>
                                                {item.name}
                                            </Col>
                                            <Col xs={24} sm={8} xl={8}>
                                                {t("class.begin_date")}:
                                            </Col>
                                            <Col xs={24} sm={16} xl={16}>
                                                {item.begin_date ? moment(item.begin_date).format(formatDate) : "_"}
                                            </Col>
                                            <Col xs={24} sm={8} xl={8}>
                                                {t("class.end_date")}:
                                            </Col>
                                            <Col xs={24} sm={16} xl={16}>
                                                {item.end_date ? moment(item.end_date).format(formatDate) : "_"}
                                            </Col>
                                            <Col xs={24} sm={8} xl={8}>
                                                {t("class.teacher")}:
                                            </Col>
                                            <Col xs={24} sm={16} xl={16}>
                                                <div className="teacher-list">
                                                    {/* {item.teachers?.map((item, itemIndex) => {
                                                    return (
                                                        <span key={`class-teacher-${itemIndex}`}>
                                                            {itemIndex > 0 ? ", " : ""}
                                                            <span>{item.name}</span>
                                                        </span>
                                                    );
                                                })} */}
                                                    {typeof item.owner === "string" ? (
                                                        <span key={`class-teacher-owner`}>
                                                            <span>{item.owner || "_"}</span>
                                                        </span>
                                                    ) : item.owner?.name ? (
                                                        <span key={`class-teacher-owner`}>
                                                            <span>{item.owner.name || "_"}</span>
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Form.Item>
                        ) : null}
                    </Spin>

                    <div className="btn-group">
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-cross-thick" />}
                            title={t("shared.cancel")}
                            onClick={handleCancel}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-tick" />}
                            title={t("shared.save")}
                            disabled={loading === "submitting"}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalDetailTeacher;
