import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, Col, Dropdown, Empty, Menu, notification, Popconfirm, Row, Space, Spin } from "antd";
import {
    FieldTimeOutlined,
    QrcodeOutlined,
    UserOutlined,
    PlusCircleOutlined,
    EllipsisOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import { default as CustomButton } from "src/modules/components/Button";
import ModalDetailClass from "../ModalDetailClass";
import ModalMergeClass from "../ModalMergeClass";
import { permissionUser } from "src/utils/permission";
import Icon from "src/modules/components/Icon";
import { useRouting } from "src/utils/router";
import { useNavigate } from "react-router-dom";
import { deleteClass } from "src/api/containers/class";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import NoAvatar from "src/assets/images/no-avatar.png";
import "./ClassItem.scss";
import { useValues } from "src/hooks";
import clsx from "clsx";

const ClassItems = (props) => {
    const {
        classList = [],
        pagination = {},
        handleFetchClassList = () => {},
        handleRefetchClassList = () => {},
        setLoadingClasses = () => {},
    } = props;

    const { user, accountType } = useSelector((state) => state.auth);
    const formatDate = useSelector((state) => state.general.formatDate);
    const { generate } = useRouting();
    const navigate = useNavigate();

    const [showModalDetailClass, setShowModalDetailClass] = useValues({
        isShown: false,
        classId: "",
        classScopeId: "",
    });
    const [values, setValues] = useState({
        loadingClassItem: false,
        classId: "",
        classScopeId: "",
        isModalMergeClass: false,
    });

    // permissions
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;
    const [roles, isAllowOneOfCUD] = useMemo(() => {
        const roles = {
            isAllowUpdateClass: checkPermission(pagesAllow.class, rolesAllow.update, user),
            isAllowDeleteClass: checkPermission(pagesAllow.class, rolesAllow.delete, user),
        };
        const isAllowOneOfCUD = roles.isAllowUpdateClass || roles.isAllowDeleteClass;
        return [roles, isAllowOneOfCUD];
    }, [user]);

    const handleDeleteClass = (classId, classScopeId) => {
        if (classId) {
            setValues({ loadingClassItem: true, classId: classId });
            deleteClass(classId, {
                organization_id: classScopeId || undefined,
                class_in_organization: undefined,
            }).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload class list:
                    handleRefetchClassList();
                } else {
                    notification.error({
                        message: res.message || "",
                    });
                }
                setValues({ loadingClassItem: false, classId: "" });
            });
        }
    };

    const renderMenu = (classData) => {
        if (!classData) {
            return null;
        }
        const isOwnerOfClassOutsideScope = !getItemScopeSelected(classData).scopeId && classData.owner?._id === user.id;
        const allowUpdateClassInsideScope = isOwnerOfClassOutsideScope || roles.isAllowUpdateClass;
        const allowDeleteClassInsideScope = isOwnerOfClassOutsideScope || roles.isAllowDeleteClass;
        return (
            <Menu className="class-item-menu">
                {allowUpdateClassInsideScope && (
                    <Menu.Item
                        key="menu-item-1"
                        onClick={() => {
                            setShowModalDetailClass({
                                isShown: true,
                                classId: classData.id,
                                classScopeId: getItemScopeSelected(classData).scopeId,
                            });
                        }}
                        disabled={checkDemoAccountAction(accountType, "class", "update") === false}
                    >
                        <Space className="class-item-menu-btn-wrapper">
                            <Icon name="icon-modify" />
                            {t("shared.update")}
                        </Space>
                    </Menu.Item>
                )}
                {allowDeleteClassInsideScope && (
                    <Popconfirm
                        title={`${t("shared.msg_confirm_delete")}?`}
                        onConfirm={() => {
                            handleDeleteClass(classData.id, getItemScopeSelected(classData).scopeId);
                        }}
                        okText={t("shared.yes")}
                        cancelText={t("shared.no")}
                        style={{ backgroundColor: "unset" }}
                    >
                        <Menu.Item
                            key="menu-item-2"
                            disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                        >
                            <Space className="class-item-menu-btn-wrapper">
                                <Icon name="icon-delete" />
                                {t("shared.delete")}
                            </Space>
                        </Menu.Item>
                    </Popconfirm>
                )}
            </Menu>
        );
    };

    return (
        <>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={12} lg={8} xxl={6}>
                    <div className="class-item-add">
                        <CustomButton
                            title={t("class.add_class")}
                            icon={<PlusCircleOutlined />}
                            onClick={() => setShowModalDetailClass({ isShown: true, classId: "", classScopeId: "" })}
                            disabled={checkDemoAccountAction(accountType, "class", "create") === false}
                        />
                    </div>
                </Col>

                {classList.map((item, index) => {
                    return (
                        <Col key={index} xs={24} md={12} lg={8} xxl={6}>
                            <div className="class-item-wrapper">
                                <Spin spinning={values.classId === item.id ? values.loadingClassItem || false : false}>
                                    <div
                                        className="class-item"
                                        onClick={() =>
                                            navigate({ pathname: generate("detail_class", { id: item.id }) })
                                        }
                                    >
                                        {item?.is_official === false && (
                                            <span className="class-item-label">
                                                <span>{t("class.intended_class")}</span>
                                            </span>
                                        )}
                                        <div className={clsx({ "class-item-content": true, "with-label": true })}>
                                            <p className="class-item-header">{item.name}</p>
                                            <div className="class-item-student">
                                                {item.user_avatars?.avatars?.length ? (
                                                    <Avatar.Group maxCount={6}>
                                                        {item.user_avatars.avatars.map((user, index) => (
                                                            <Avatar src={user?.src || NoAvatar} key={index} />
                                                        ))}
                                                        {item.user_avatars.user_avatars_left ? (
                                                            <Avatar>+{item.user_avatars.user_avatars_left}</Avatar>
                                                        ) : null}
                                                    </Avatar.Group>
                                                ) : null}
                                            </div>
                                            <p>
                                                <QrcodeOutlined className="class-item-icon" />
                                                {`${t("class.code")}: ${item.code ? item.code : ""}`}
                                            </p>
                                            <p>
                                                <UserOutlined className="class-item-icon" />
                                                {`${t("class.teacher")}: ${item.owner?.name ? item.owner?.name : ""}`}
                                            </p>
                                            <p>
                                                <FieldTimeOutlined className="class-item-icon" />
                                                {`${t("class.begin_date")}: ${
                                                    item.begin_date ? moment(item.begin_date).format(formatDate) : ""
                                                }`}
                                            </p>
                                            <p>
                                                <FieldTimeOutlined className="class-item-icon" />
                                                {`${t("class.end_date")}: ${
                                                    item.end_date ? moment(item.end_date).format(formatDate) : ""
                                                }`}
                                            </p>
                                        </div>
                                    </div>
                                </Spin>
                                {(!getItemScopeSelected(item).scopeId && item.owner?._id === user.id) ||
                                isAllowOneOfCUD ? (
                                    <Dropdown overlay={renderMenu(item)} trigger={["click"]} placement="bottomRight">
                                        <Button className="class-item-dropdown-btn">
                                            <EllipsisOutlined />
                                        </Button>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Col>
                    );
                })}
                {pagination.current < pagination.lastPage ? (
                    <Col xs={24} md={12} lg={8} xxl={6}>
                        <div
                            className="class-item-loadmore"
                            onClick={() => handleFetchClassList({ page: pagination.current + 1 })}
                        >
                            <CustomButton title={`${t("class.load_more_class")}...`} icon={null} />
                        </div>
                    </Col>
                ) : null}
            </Row>

            {classList?.length <= 0 && <Empty description={t("class.no_class")} />}

            <ModalDetailClass
                visible={showModalDetailClass.isShown}
                classId={showModalDetailClass.classId}
                organization={{
                    organization_id: showModalDetailClass.classScopeId || undefined,
                    class_in_organization: undefined,
                }}
                onOk={() => {
                    handleRefetchClassList();
                }}
                onCancel={() => {
                    setShowModalDetailClass({ ...showModalDetailClass, isShown: false });
                }}
                destroyOnClose
            ></ModalDetailClass>

            <ModalMergeClass
                visible={values.isModalMergeClass}
                classId={showModalDetailClass.classId}
                organization={{
                    organization_id: showModalDetailClass.classScopeId || undefined,
                    class_in_organization: undefined,
                }}
                onOk={() => {
                    handleRefetchClassList();
                }}
                onCancel={() => {
                    setValues({ isModalMergeClass: false });
                }}
                destroyOnClose
            ></ModalMergeClass>
        </>
    );
};

export default ClassItems;
