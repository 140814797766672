import { Form, Input, Modal, Spin, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import Icon from "src/modules/components/Icon";
import CustomButton from "src/modules/components/Button";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import { updateOrganizationDetails } from "src/api/containers/organization";
import { getMe } from "src/api/containers/auth";

function ModalDetailTeamAndOrg({ visible, data, className, handleOk = () => {}, ...rest }) {
    const [values, setValues] = useValues({ loading: false });
    const { t } = useTranslation();
    const [form] = useForm();
    const { user } = useSelector((state) => state.auth);

    const handleRefetchMe = () => {
        // getMe(user?.paramsForOrgOrTeam?.organization_id).then((res) => {
        //     if (res?.status === true && res?.data?.user) {
        //         dispatch(login(res.data.user));
        //     } else {
        //         localStorage.removeItem("token");
        //         deleteCookie("token");
        //     }
        // });
    };

    const handleSubmitForm = (formData) => {
        if (user?.organization?.is_organization_owner || user?.currentOrg?.is_team_owner) {
            setValues({ loading: true });

            // Make api calls:
            updateOrganizationDetails(user?.currentOrg?.organization_id || user?.currentOrg?.team_id, formData).then(
                (res) => {
                    setValues({ loading: false });
                    if (res.status) {
                        notification.success({
                            message: t("message.update_success"),
                        });
                        handleRefetchMe();
                        handleOk?.();
                    } else {
                        notification.error({
                            message: res.message || t("message.update_error"),
                        });
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (visible && data?.id) {
            form.setFieldsValue({ name: data.name });
        }
    }, [visible]);

    return (
        <Modal
            visible={visible}
            centered
            title={t("shared.update")}
            closeIcon={<IconClose />}
            width="auto"
            style={{ maxWidth: 600 }}
            destroyOnClose
            {...rest}
            className={`app-modal type-basic flexible-height${className ? " " + className : ""}`}
            footer={null}
        >
            <Spin spinning={values.loading}>
                <div>
                    <Form
                        form={form}
                        className="modal-detail-exam__skeleton"
                        layout="vertical"
                        onFinish={handleSubmitForm}
                    >
                        <Form.Item
                            name="name"
                            required
                            label={t("organization.name")}
                            rules={[{ required: true, message: t("message.required") }]}
                        >
                            <Input placeholder={t("organization.name")} />
                        </Form.Item>
                        <Form.Item>
                            <div className="btn-group">
                                <CustomButton
                                    htmlType="submit"
                                    type="primary"
                                    icon={<Icon name="icon-save" />}
                                    title={t("shared.save")}
                                    // onClick={handleSubmitForm}
                                ></CustomButton>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
}

export default ModalDetailTeamAndOrg;
