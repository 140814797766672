import { EditOutlined, LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import styles from "./PageAdjustTools.module.css";

const defaultFunction = function () {};

function PageAdjustTools({
    isShowThumbnails,
    handleScale = defaultFunction,
    handleAddBlankPage = defaultFunction,
    handleRemovePage = defaultFunction,
    toggleThumbnails = defaultFunction,
}) {
    const { t } = useTranslation();

    const thumbnailsMenu = (
        <Menu>
            <Menu.Item onClick={handleAddBlankPage}>{t("exam_pdf.new_blank_page")}</Menu.Item>
            <Menu.Item onClick={handleRemovePage}>{t("exam_pdf.delete_page")}</Menu.Item>
        </Menu>
    );

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <Button
                    shape="circle"
                    onClick={toggleThumbnails}
                    icon={isShowThumbnails ? <LeftOutlined /> : <RightOutlined />}
                ></Button>
                <Button shape="circle" onClick={() => handleScale("incre")} icon={<PlusOutlined />}></Button>
                <Button shape="circle" icon={<MinusOutlined />} onClick={() => handleScale("decre")}></Button>
                <Dropdown overlay={thumbnailsMenu}>
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleScale("decre")}></Button>
                </Dropdown>
            </div>
        </div>
    );
}

export default PageAdjustTools;
