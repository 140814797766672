import React, { useContext, useEffect, useRef } from "react";
import Worksheet from "./components/Worksheet";
import Header from "src/modules/components/Header";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import { useValues } from "src/hooks";
import "./DetailExamPDF.scss";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "src/modules/components/Icon";
import { CopyOutlined, FileTextOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { findExam, updateExam } from "src/api/containers/exam";
import { Form, message, notification } from "antd";
import SettingTopBar from "src/modules/containers/DetailExam/components/SettingTopBar";
import SettingPublishingStatus from "src/modules/containers/DetailExam/components/SettingPublishingStatus";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { fetchCategories } from "src/api/containers/category";
import Button from "src/modules/components/Button";
import { SvgCross } from "src/utils/drawer";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { useRouting } from "src/utils/router";
import { PDF_CONST } from "./constant";
import { animationId } from "src/reducers/animation";

function DetailExamPDF() {
    const { t } = useTranslation();
    const [form] = useForm();
    const { id: examId } = useParams();
    const params = useParams();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};
    const { generate } = useRouting();
    const dispatch = useDispatch();
    const pathsData = useRef(PDF_CONST.DEFAULT_PATHS);
    const loadData = useRef();
    const valuesData = useRef();

    const navigate = useNavigate();

    const [values, setValues] = useValues({
        examData: {},
        currentThumbnail: {
            file_id: "",
            page: "",
            src: "",
        },
        isShowThumbnails: true,
        loadingExam: false,
        optionListCategory: [],
        examScopeId: "",
        listThumbnails: [],
        drawingTool: undefined,
        listQuestion: [], // [{question: {}, order:1, score:10, page: 1,file_id:""}]
        defaultListQuestion: [],
        pathsByFile: {},
    });
    valuesData.current = values;

    const [currentTab, setCurrentTab] = useValues({
        tabKey: "detail", // Values:  detail || "preview" || ""
        data: null,
    });

    function modifyQuestionList(questions = []) {
        return questions.map((item, idx) => {
            return { ...item, page: idx + 1 };
        });
    }

    function fetchExamData() {
        if (examId) {
            setValues({ loadingExam: true });
            findExam(examId)
                .then(({ status, data, message }) => {
                    if (status) {
                        if (user.id !== data?.user?.id) {
                            setCurrentTab({ tabKey: "preview" });
                        }
                        const examFormData = {
                            name: data?.name,
                            category_id: data?.category_id,
                            max_score: data?.max_score,
                            is_public: data?.is_public,
                        };

                        const examScope = getItemScopeSelected(data);
                        if (examScope.scopeId) {
                            examFormData.is_public = examScope.scopeId;
                        }

                        const question_list = modifyQuestionList(data.question_list);

                        setValues({
                            examData: data,
                            loadingExam: false,
                            listThumbnails: question_list,
                            currentThumbnail: question_list?.[0] || {},
                            examScopeId: examScope.scopeId,
                            defaultListQuestion: question_list || [],
                        });

                        form.setFieldsValue(examFormData);
                    } else {
                        setValues({ loadingExam: false });
                        if (message) {
                            notification.error({ message: message });
                        }
                    }
                })
                .catch((err) => {
                    notification.error({ message: t("message.sth_wrong") });
                });
        }
    }

    function getListCategories() {
        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            if (res.status) {
                setValues({ optionListCategory: res.data });
            }
        });
    }

    useEffect(() => {
        fetchExamData();
        getListCategories();
    }, [examId]);

    const getPathsForSubmit = () => {
        return values.listThumbnails.map((item) => ({
            file_id: item.file_id,
            path: item.path,
            sections: item.sections || [],
        }));
    };

    /**
     *  Data submit format:
     *      "name": "PDF",
            "category_id": "629f031d111d7144c60285f7",
            "max_score": 22,
            "organization_id": null,
            "is_publish": false,
            "is_public": false,
            "type": "pdf",
            "file_ids": [
                "6406bf181f5e000098002472",
                "6406c7371f5e000098002475"
            ],
            "questions":[
                            {
                                "question_id": "658907bd75e4f7274202ecc5",
                                "order": 1,
                                "score": 10
                            },
                        ],
            "paths" : [
                            {
                                "file_id" : "6406bf181f5e000098002472",
                                "path" : ""
                            },      
                        ]
     */
    const handleSaveExam = (isPublish = false) => {
        let formData = form.getFieldsValue();
        formData.type = "pdf";
        // Generate values to be sent to database:
        // - Exam scope:
        let isPublic = formData.is_public;
        let organizationId = null; // Must be "null" if the question is public/private scope.
        if (isPublic !== true && isPublic !== false) {
            organizationId = isPublic;
            isPublic = true;
        }
        formData.is_public = isPublic;
        formData.organization_id = organizationId;
        // - Exam form data:

        const allQuestions = values.listThumbnails.reduce((all, curr) => {
            if (Array.isArray(curr.questions)) {
                return [...all, ...curr.questions];
            }
            return all;
        }, []);

        formData.questions = allQuestions.map((question, index) => ({
            question_id: question.id,
            order: index + 1,
            score: question?.score,
        }));

        if (isPublish === false) {
            // Form data:

            formData.is_publish = false;
        } else {
            // xuất bản đề
            formData.is_publish = true;

            // Question list:
            if (allQuestions.length === 0) {
                notification.error({
                    message: t("exam_bank.question_list_is_empty"),
                });
                return;
            }
            // Question list with score:
            const noScoreQuestion = allQuestions.filter((question) => question?.score === null);
            if (noScoreQuestion.length > 0) {
                notification.error({
                    message: t("exam_bank.score_is_empty"),
                });
                return;
            }
        }

        const file_ids = values.listThumbnails.map((item) => item.file_id);
        formData.file_ids = file_ids;

        formData.paths = getPathsForSubmit();

        // Make api calls:
        setValues({ loading: true });
        if (params.id) {
            updateExam(params.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "exam", update: params.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    navigate(generate("exam_bank"));
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false });
            });
        }
    };

    const renderActionList = () => {
        return (
            <div className="btn-group">
                <Form.Item>
                    <Button
                        type="grey"
                        icon={<SvgCross />}
                        title={examId ? t("shared.back") : t("shared.cancel")}
                        // onClick={(e) => handleGoBack(e)}
                    ></Button>
                    {/* {(!params.id || (params.id && user.id === values.ownerID && !values.isPublish)) && ( */}
                    <Button
                        htmlType="submit"
                        type="grey"
                        // icon={<Icon name="icon-save" />}
                        title={t("shared.save_draft")}
                        // onClick={(e) => handleSaveAsDraft(e)}
                    ></Button>
                    {/* )} */}
                    {/* {params.id && user.id !== values.ownerID && type !== "modal" ? ( */}
                    <>
                        {checkIfActionIsAllowed("exam", "clone") === true ? (
                            <Button
                                type="primary"
                                icon={<CopyOutlined />}
                                title={t("shared.clone")}
                                // onClick={(e) => handleCloneExam(e)}
                            ></Button>
                        ) : null}
                    </>
                    {/* ) : ( */}
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<Icon name="icon-rocket" />}
                        title={
                            examId && user.id === values.ownerID && values.isPublish
                                ? t("shared.save")
                                : t("shared.publish")
                        }
                        // onClick={handleSubmit}
                    ></Button>
                    {/* )} */}
                </Form.Item>
            </div>
        );
    };

    const handleSubmit = () => {
        handleSaveExam(true);
    };

    function handleSetPaths({ path }, values) {
        if (path) {
            const results = values?.current?.listThumbnails.map((item) => {
                if (item.file_id === values?.current?.currentThumbnail.file_id) {
                    const newItem = structuredClone(item);
                    newItem.path = path;
                    return newItem;
                }

                return item;
            });

            setValues({ listThumbnails: results });
        }
        // setValues({ pathsByFile: (values.pathsByFile[file_id] = { file_id: file_id, paths: paths }) });
    }

    // useEffect(() => {
    //     // khi change page: lưu cái pathData hiện tại và gán
    //    const currentPath = pathsData.current[values.currentThumbnail.page];

    //     if (loadData.current && currentPath.paths) {
    //         loadData.current?.(currentPath.paths);
    //     }
    // }, [loadData.current, values.currentThumbnail.page]);
    function onPageChange() {}
    return (
        <ExamPDFContext.Provider
            value={{
                examData: values.examData,
                setExamData: (examData) => setValues({ examData }),
                currentThumbnail: values.currentThumbnail,
                setCurrentThumbnail: (question) => {
                    console.log("aaa", question);
                    setValues({ currentThumbnail: question });
                },
                isShowThumbnails: values.isShowThumbnails,
                toggleThumbnails: () => setValues({ isShowThumbnails: !values.isShowThumbnails }),
                listThumbnails: values.listThumbnails,
                setListThumbnails: (data) => setValues({ listThumbnails: data }),
                setDrawingTool: (tool) => setValues({ drawingTool: tool }),
                drawingTool: values.drawingTool,
                listQuestion: values.listQuestion,
                setListQuestion: (questions) => setValues({ listQuestion: questions }),
                defaultListQuestion: values.defaultListQuestion,
                pathsByFile: values.pathsByFile,
                setPathByFile: ({ path }) => handleSetPaths({ path }, valuesData),
                pathsData: pathsData,
                loadData: loadData,
                currentTab: currentTab,
            }}
        >
            <div className="exam-detail-wrapper detail-exam-pdf">
                <Header
                    backTitle={values.examData?.name}
                    actions={[
                        {
                            title: t("header.modify"),
                            icon: (
                                <Icon name="icon-modify" fill={currentTab.tabKey === "detail" ? "#0077FF" : "#fff"} />
                            ),
                            link: "",
                            onClick: () => setCurrentTab({ tabKey: "detail" }),
                            type: currentTab.tabKey === "detail" ? "primary" : "",
                        },
                        {
                            title: t("header.preview"),
                            icon: (
                                <Icon name="icon-preview" fill={currentTab.tabKey === "preview" ? "#0077FF" : "#fff"} />
                            ),
                            link: "",
                            onClick: () => setCurrentTab({ tabKey: "preview" }),
                            type: currentTab.tabKey === "preview" ? "primary" : "",
                        },
                    ]}
                />
                <Form form={form} onFinish={handleSubmit}>
                    {currentTab.tabKey === "detail" ? (
                        <SettingTopBar
                            optionListCategory={values.optionListCategory}
                            onChangeCategoryList={(newCateList) => {
                                setValues({ optionListCategory: newCateList });
                            }}
                        />
                    ) : null}

                    <Worksheet form={form} currentTab={currentTab} />
                    {currentTab.tabKey === "detail" ? (
                        <>
                            <SettingPublishingStatus examScopeId={values.examScopeId} form={form} />
                            <div className="exam-preview-actions">{renderActionList()}</div>
                        </>
                    ) : null}
                </Form>
            </div>
        </ExamPDFContext.Provider>
    );
}

export default DetailExamPDF;
