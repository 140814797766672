import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Dropdown, Modal, notification, Progress, Row, Space, Table, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import { getStorageValueWithUnitFromMB } from "src/utils/helpers/unit";
import { fetchTeachers } from "src/api/containers/user";
import { removeUserFromOrganization } from "src/api/containers/organization";
import { getStorageSpaceUsed } from "src/api/containers/storage";
import { permissionUser } from "src/utils/permission";
import Can from "src/modules/components/Can";
import InputSearch from "src/modules/components/Input/InputSearch";
import FilterMenu from "../FilterMenu";
import ModalAddUser from "../ModalAddUser";
import ModalEditStorage from "../ModalEditStorage";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "src/modules/components/Button";
// Icons from assets:
import FilterIcon from "src/assets/images/filter-icon.svg";
import Pencil from "src/assets/images/pencil.svg";
// CSS:
import "./OrganizationUserList.scss";

function OrganizationUserList(props) {
    const user = useSelector((state) => state.auth.user);
    const isFetchUserList = user?.currentOrg?.is_organization_owner || user?.currentOrg?.is_team_owner || false;

    const [loadingUserList, dataSource, pagination, fetch, refetch] = useFetch(
        {
            page: 1,
            slug: "",
            user_organization: isFetchUserList,
            organization_id: user.paramsForOrgOrTeam?.organization_id,
            type: user?.currentOrg?.scopeOptionKey,
        },
        fetchTeachers
    );

    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const role = useMemo(() => {
        return {
            setStorageUser: checkPermission(pagesAllow.organization, rolesAllow.setStorageUser, user),
            removeUserOrganization: checkPermission(pagesAllow.organization, rolesAllow.removeUserOrganization, user),
        };
    }, [user]);

    const { t } = useTranslation();
    const [storageData, setStorageData] = useState({
        overviewList: undefined,
    });
    const [values, setValues] = useValues({
        isEditStorageModalVisible: false,
        isAddUserModalVisible: false,
        isConfirmDeleteModalVisible: false,
        selectedUser: null,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchUser: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });
    const columns = [
        {
            title: t("organization.tablecol_user"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => {
                return record?.name || "_";
            },
        },
        {
            title: t("profile.gender"),
            dataIndex: "gender",
            key: "gender",
            width: "auto",
            render: (text, record) => {
                switch (record?.gender) {
                    case 1:
                        return t(`profile.gender_male`);
                    case 0:
                        return t(`profile.gender_female`);
                    default:
                        return "_";
                }
            },
        },
        {
            title: t("profile.role"),
            dataIndex: "role",
            key: "role",
            width: "auto",
            render: (text, record) => (record?.role ? t(`profile.role_${record?.role}`) : "_"),
        },
        {
            title: t("profile.email"),
            dataIndex: "username",
            key: "username",
            width: "auto",
            render: (text, record) => record?.username || "_",
        },
        {
            title: t("profile.active"),
            dataIndex: "active",
            key: "active",
            width: "auto",
            render: (text, record) => {
                switch (record?.active) {
                    case false:
                        return t(`profile.active_false`);
                    case true:
                        return t(`profile.active_true`);
                    default:
                        return "_";
                }
            },
        },
        {
            title: t("organization.storage_used"),
            dataIndex: "storage",
            key: "storage",
            width: "auto",
            render: (text, record) => {
                const storageValue = record?.used_storage;
                return !isNaN(storageValue) ? getStorageValueWithUnitFromMB(storageValue, false) : "_";
            },
        },
        {
            title: t("organization.storage_available"),
            dataIndex: "storage",
            key: "storage",
            width: "auto",
            render: (text, record) => {
                const storageValue = record?.storage_organization_empty;
                return !isNaN(storageValue) ? getStorageValueWithUnitFromMB(storageValue, false) : "_";
            },
        },
    ];

    if (role.setStorageUser || role.removeUserOrganization) {
        columns.push({
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <div className="organization-user-list-actions">
                    <Can I={rolesAllow.setStorageUser} page={pagesAllow.organization}>
                        <Tooltip placement="top" title={t("shared.update")}>
                            <span onClick={() => handleShowModalEditStorage(record)} className="action-btn">
                                <EditOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                    <Can I={rolesAllow.removeUserOrganization} page={pagesAllow.organization}>
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <span
                                onClick={() => handleShowModalConfirmDelete(record)}
                                className="action-btn organization-user-delbtn"
                            >
                                <DeleteOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                </div>
            ),
        });
    }

    const handleShowModalConfirmDelete = (selectedUser) => {
        setValues({ isConfirmDeleteModalVisible: true, selectedUser: selectedUser });
    };

    const handleShowModalEditStorage = (selectedUser) => {
        setValues({ isEditStorageModalVisible: true, selectedUser: selectedUser });
    };

    const handleDeleteSelectedUser = () => {
        const selectedUser = values.selectedUser;
        removeUserFromOrganization(selectedUser.id, user.paramsForOrgOrTeam?.organization_id).then(
            ({ status, message }) => {
                if (status) {
                    notification.success({ message: t("message.delete_success") });
                    setValues({ isConfirmDeleteModalVisible: false, selectedUser: null });
                    refetch();
                } else {
                    notification.error({ message: message || t("message.delete_error") });
                }
            }
        );
    };

    const handleSearchUser = () => {
        if (filterSettings) {
            fetch({
                page: 1,
                slug: filterSettings.inputSearchUser,
                user_organization: isFetchUserList,
            });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        fetch({ page: 1, slug: "", user_organization: isFetchUserList }, true);
    }, []);

    useEffect(() => {
        getStorageSpaceUsed(user.paramsForOrgOrTeam).then((res) => {
            if (res.status === true) {
                setStorageData({
                    overviewList: res.data || [],
                });
            }
        });
    }, []);

    return (
        <div className="question-bank organization-user-list">
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("organization.find_user")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchUser: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchUser();
                                    }
                                }}
                                onClickIconSearch={handleSearchUser}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetchUserList={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    {/* <div className="filter-toolbar-actions">
                        <CustomButton
                            type="primary"
                            icon={Pencil}
                            title={t("organization.add_user")}
                            onClick={() => setValues({ isAddUserModalVisible: true })}
                        ></CustomButton>
                    </div> */}
                </div>
            </div>

            {storageData.overviewList?.length
                ? storageData.overviewList.map((dItem, dIndex) => {
                      if (dItem.type === "organization" || dItem.type === "team") {
                          return (
                              <Row
                                  key={`org-row-${dIndex}`}
                                  className="organization-user-list__row storage-available-info"
                              >
                                  <Col xs={24} sm={8}>
                                      {t("organization.storage_available")}
                                  </Col>
                                  <Col xs={24} sm={16}>
                                      <div className="storage-available-content">
                                          <span className="storage-available-value">
                                              <span>
                                                  {!isNaN(dItem.left)
                                                      ? getStorageValueWithUnitFromMB(dItem.left, false)
                                                      : "_"}
                                              </span>
                                              <span>/</span>
                                              <span>
                                                  {!isNaN(dItem.total)
                                                      ? getStorageValueWithUnitFromMB(dItem.total, false)
                                                      : "_"}
                                              </span>
                                          </span>
                                          {!isNaN(dItem.used) && !isNaN(dItem.total) ? (
                                              <Progress
                                                  type="circle"
                                                  percent={(dItem.used / dItem.total) * 100}
                                                  width={35}
                                                  strokeWidth={10}
                                                  showInfo={false}
                                              />
                                          ) : null}
                                      </div>
                                  </Col>
                              </Row>
                          );
                      }
                      return null;
                  })
                : null}

            <div className="organization-user-list__table">
                <Table
                    className="app-table"
                    columns={columns}
                    dataSource={dataSource}
                    loading={loadingUserList}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            <ModalAddUser
                organization={user.paramsForOrgOrTeam}
                visible={values.isAddUserModalVisible}
                onOk={() => setValues({ isAddUserModalVisible: false })}
                onCancel={() => setValues({ isAddUserModalVisible: false })}
            />

            <ModalEditStorage
                organization={user.paramsForOrgOrTeam}
                visible={values.isEditStorageModalVisible}
                onOk={() => {
                    setValues({ isEditStorageModalVisible: false });
                    fetch({ page: 1, slug: "", user_organization: isFetchUserList }, true);
                    getStorageSpaceUsed(user.paramsForOrgOrTeam).then((res) => {
                        if (res.status === true) {
                            setStorageData({
                                overviewList: res.data || [],
                            });
                        }
                    });
                }}
                user={values.selectedUser}
                onCancel={() => setValues({ isEditStorageModalVisible: false })}
            />

            <PopConfirm
                type="delete"
                visible={values.isConfirmDeleteModalVisible}
                onOk={handleDeleteSelectedUser}
                onCancel={() => setValues({ isConfirmDeleteModalVisible: false })}
            />
        </div>
    );
}

export default OrganizationUserList;
