// File is verified!
import api from "..";

// Get role list:
export const fetchPermissions = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
    };
    fetchParams.organization_id && Object.assign(params, { organization_id: fetchParams.organization_id });

    return api
        .get("/role/permissions", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(meta
                ? {
                      pagination: {
                          showSizeChanger: false,
                          current: meta.pagination.page,
                          pageSize: meta.pagination.perPage,
                          total: meta.pagination.total,
                          lastPage: meta.pagination.lastPage,
                      },
                  }
                : {}),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
