import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, InputNumber, notification, Row, Spin, Typography } from "antd";
import Editor from "src/modules/components/Editor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { useValues } from "src/hooks";
import configEditor from "src/utils/configEditor";
import ImageWithLabels from "src/modules/containers/QuestionDetail/components/ImageWithLabels";
import "./DetailHighLightImage.scss";

const DetailHighLightImage = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        loading: false,
        optionListCategory: [],
        optionListTag: [],
        ownerID: "",
        isPublish: false,
        imageId: "",
        currentHeight: "",
        currentWidth: "",
        src: "",
    });

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });
    const [dataImage, setDataImage] = useState(undefined); // Values: { id, src, currentWidth, currentHeight }.

    const handleChangeImage = (_image) => {
        setDataImage(_image);
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const _questionData = {
                type: "highlight_image",
            };

            if (dataImage) {
                _questionData.file_id = dataImage.id;
                _questionData.width = dataImage.currentWidth;
                _questionData.height = dataImage.currentHeight;
                _questionData.src = dataImage.src;
            }

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: "",
                // Special fields:
                type: _questionData.type,
                // src: dataImage?.src, // Will be deleted later!
                file: { src: dataImage?.src },
                width: _questionData.width,
                height: _questionData.height,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData?.dataImage) {
            setDataImage(formStates.extraQuestionData.dataImage);
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Set form data:
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                category_id: qData?.category?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                question: qData?.detail?.question || "",
                                score: qData?.detail?.score,
                                // Special fields: "file_id", "src", "width", "height".
                            };
                            setDataImage({
                                id: qData?.detail?.file_id,
                                // src: qData?.detail?.src, // Will be deleted later!
                                src: qData?.detail?.file?.src,
                                currentWidth: qData?.detail?.width,
                                currentHeight: qData?.detail?.height,
                            });
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const questionFormData = {
                                question: res[1].data?.question || "",
                                score: res[1].data?.score || undefined,
                                // Special fields: "file_id", "src", "width", "height".
                            };
                            const qData = res?.[1]?.data;
                            if (qData) {
                                setDataImage({
                                    id: qData?.file_id,
                                    // src: qData?.src, // Will be deleted later!
                                    src: qData?.file?.src,
                                    currentWidth: qData?.width,
                                    currentHeight: qData?.height,
                                });
                            }

                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);
    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và category list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData, { dataImage });
        }
    }, [isFormFilled, detailData]);

    return (
        <>
            {/* <div className="question-detail-wrapperr"> */}
            <Spin spinning={values.loading}>
                <div className="question-detail writing">
                    <div className="question-detail-content">
                        <Row className="question-editor">
                            <Typography className="question-editor-title">{t("question.question")}</Typography>
                            <Form.Item
                                name="question"
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Editor config={editorConfigQuestion.current}></Editor>
                            </Form.Item>
                        </Row>

                        <Row className="question-image-drag-drop">
                            <Col span={24}>
                                <ImageWithLabels imageData={dataImage} onChangeImage={handleChangeImage} />
                                <br />
                            </Col>
                        </Row>

                        <Row className="question-formitems">
                            <Col>
                                <Form.Item
                                    name="score"
                                    label={t("question.score")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className="app-input"
                                        min={0}
                                        onKeyDown={(e) => {
                                            if (e.code === "Enter") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
            {/* </div> */}
        </>
    );
};

export default DetailHighLightImage;
