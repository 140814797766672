import { useEffect, useState } from "react";
import { Dropdown, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import ContextMenu from "./ContextMenu";
import { focusAndSetCaretPosition } from "src/utils/helpers/webAPIs";
import { ReactComponent as EmptyFolder } from "../assets/custom/folder-empty.svg";
import { ReactComponent as NotEmptyFolder } from "../assets/custom/folder-not-empty.svg";
import { ReactComponent as BlankFile } from "../assets/custom/file-without-content.svg";

const Item = ({ controller, file, path, selectFile, renameFile }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(false);
    const [currentMode, setCurrentMode] = useState(""); // Value: "edit".
    const [values, setValues] = useValues({
        fileName: undefined,
        menuVisible: false,
    });
    // const inputNameRef = useRef(null);

    const handleDoubleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (file.type === "folder") {
            controller.call("shared.push_flow_folder", file);
            controller.call("content.open_folder", file);
            controller.call(`sidebar_item.${file.id}.open`);
        } else {
            selectFile(file, () => controller.call("close"));
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        selected ? controller.call("content.un_select_file", file) : controller.call("content.select_file", file);
        setSelected(!selected);
    };

    const handleFocusInput = (e) => {
        focusAndSetCaretPosition(e.target, (values.fileName || file.name).length);
    };

    const handleBlurInput = () => {
        setCurrentMode("");
        if (values.fileName && values.fileName !== file.name) {
            const res = renameFile(file.id, { name: values.fileName });
            if (res instanceof Promise) {
                res.then((resFile) => {
                    // Update file/folder in Content:
                    controller.call("content.update_file", resFile);
                    // Update sidebar item for the renamed folder:
                    if (resFile.type === "folder") {
                        resFile.id = resFile._id;
                        controller.call(`sidebar_item.${resFile.id}.update`, resFile);
                    }
                    // Show notification:
                    const fileType = resFile.type === "folder" ? "folder" : "file";
                    controller.call("notification.show", t(`file_manager.rename_${fileType}_success`), 5000, "success");
                }).catch(({ message }) => {
                    setValues({ ...values, fileName: file.name });
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        } else {
            setValues({ ...values, fileName: file.name });
        }
    };

    useEffect(() => {
        controller.set(`content_item.${file.id}.un_select_file`, () => {
            setSelected(false);
        });

        return () => {
            controller.remove(`content_item.${file.id}.un_select_file`);
        };
    }, []);

    // useEffect(() => {
    //     if (currentMode === "edit") {
    //         console.log(inputNameRef.current);
    //     }
    // }, [inputNameRef.current, currentMode]);

    return (
        <Dropdown
            trigger={["contextMenu"]}
            overlay={
                <ContextMenu
                    controller={controller}
                    onClickRename={() => {
                        setValues({ menuVisible: false });
                        setCurrentMode("edit");
                    }}
                    onClickMoveTo={() => {
                        setValues({ menuVisible: false });
                    }}
                />
            }
            visible={values.menuVisible}
            onVisibleChange={(isVisible) => {
                setValues({ menuVisible: isVisible });
            }}
        >
            <div
                className={[
                    "file-manager-file-item",
                    selected ? "selected" : "",
                    currentMode === "edit" ? "focused" : "",
                ]
                    .filter((c) => c)
                    .join(" ")}
                onDoubleClick={handleDoubleClick}
                onClick={handleClick}
            >
                {/* Icon: */}
                {file.type === "folder" &&
                    (!file.state || file.state === "empty" ? (
                        <div className="fm-item-banner fm-item-banner-folder">
                            <EmptyFolder />
                        </div>
                    ) : (
                        <div className="fm-item-banner fm-item-banner-folder">
                            <NotEmptyFolder />
                        </div>
                    ))}
                {file.type !== "folder" && (
                    <div className="fm-item-banner fm-item-banner-file">
                        {(typeof file.src === "string" &&
                            /(http|https).*(\.png|\.jpg|\.jpeg|\.webp|\.bmp|\.dib|\.ico)$/.test(file.src)) ||
                        /.*(\.png|\.jpg|\.jpeg|\.webp|\.bmp|\.dib|\.ico)$/.test(file.name?.toLowerCase()) ? (
                            <img src={file.src} />
                        ) : (
                            <BlankFile />
                        )}
                    </div>
                )}

                {/* Name: */}
                {currentMode === "edit" ? (
                    <Input.TextArea
                        autoSize
                        autoFocus
                        className="fm-item-file-name-edit"
                        defaultValue={file.name || undefined}
                        value={values.fileName}
                        onFocus={handleFocusInput}
                        onBlur={handleBlurInput}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onDoubleClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            setValues({ ...values, fileName: e.target.value });
                        }}
                    />
                ) : (
                    <div className="fm-item-file-name">{values.fileName || file.name}</div>
                )}
            </div>
        </Dropdown>
    );
};

export default Item;
