import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Divider, Layout, Menu, notification, Tooltip } from "antd";
import { CaretDownOutlined, SwapOutlined, TeamOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getCookie } from "src/utils/helpers";
import { useValues } from "src/hooks";
import { generalNav, restrictedNav, studentNav, teacherNav } from "src/routes/navs";
import { permissionUser } from "src/utils/permission";
import { countUnreadNotifications } from "src/api/containers/notification";
import { changeLang, toggleCollapsed, toggleFeedbackModalVisible } from "src/reducers/general";
import { logout } from "src/reducers/auth";
import configs from "src/configs";
import Avatar from "../../components/Avatar";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import Dropdown from "src/modules/components/Dropdown";
import UserScopePicker from "../../components/UserScopePicker";
import ModalNotification from "src/modules/components/NotificationPanel/ModalNotification";
import ModalSubscriptionPlans from "../../components/ModalSubscriptionPlans";
import ButtonSubscription from "../../components/ButtonSubscription";
import TextScroll from "src/modules/components/TextScroll";
import { ReactComponent as SvgFeedback } from "src/assets/images/nav/feedback.svg";
import { ReactComponent as SvgPowerGradient } from "src/assets/images/nav/power-gradient.svg";
import vietnamFlag from "src/assets/images/language/flag_vi.svg";
import usFlag from "src/assets/images/language/flag_en.svg";
import NoAvatar from "src/assets/images/no-avatar.png";
import { ReactComponent as NotificationGradientIcon } from "src/assets/images/notification-gradiant-icon.svg";
import { ReactComponent as TK_ThuNghiem } from "src/assets/images/nav/tk_thunghiem.svg";
import "./NavSidebar.scss";
import clsx from "clsx";

const FEEDBACK_CONFIGS = configs.FEEDBACK;

function NavSidebar({ sidebarRef }) {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { generate } = useRouting();
    const collapsed = useSelector((state) => state.general.collapsed);
    const user = useSelector((state) => state.auth.user);
    const accountType = useSelector((state) => state.auth.accountType);
    const lang = useSelector((state) => state.general.lang);
    const [values, setValues] = useValues({ selectedKeys: [], countUnreadNotifs: 0 });
    const [subnavActive, setSubnavActive] = useState("");
    const [showSubnav, setShowSubnav] = useState({ name: "", show: false });
    const [showModal, setShowModal] = useState({
        modal: "",
        isVisible: true,
    });
    const [isLoginPickerVisible, setIsLoginPickerVisible] = useState(false);
    const isDemoAccActivable = configs.ALLOW_DEMO_ACCOUNT;
    const isUpgradable =
        configs.ALLOW_UPGRADE_ACCOUNT &&
        ["teacher"].includes(user.role) &&
        accountType !== "demo" &&
        (user?.currentOrg?.team_name || !user?.currentOrg);

    const [scopeOptionKey, setScopeOptionKey] = useState(() => user?.currentOrg?.scopeOptionKey);

    const isFeedbackShown = useRef(false);

    const { routesNotAllow, routesAllow } = permissionUser;
    const allowRoutes = useMemo(() => routesAllow(user), [user]);

    const suitableNav = useMemo(() => {
        if (user.role === "student") {
            return studentNav;
        }
        if (user.role === "teacher") {
            return teacherNav;
        }
        return [];
    }, [user]);

    // Sidebar:
    const handleToggleCollapsed = (e, actionType) => {
        if (actionType === "hide") {
            if (collapsed === false) {
                dispatch(toggleCollapsed(true));
                if (isLoginPickerVisible === true) {
                    setIsLoginPickerVisible(false);
                }
            }
        } else if (actionType === "show") {
            if (collapsed === true) {
                dispatch(toggleCollapsed(false));
            }
        }
    };
    const handleShowSubnav = (name) => {
        if (name !== showSubnav.name) {
            setShowSubnav(() => ({ name: name, show: true }));
        } else {
            setShowSubnav((pre) => ({ name: name, show: !pre.show }));
        }
    };

    // Auth:
    const handleLogOut = () => {
        notification.success({
            message: t("message.logout_success"),
        });
        // Log out:
        dispatch(logout());
    };
    const handleChangeAccountType = () => {
        if (accountType === "demo") {
            dispatch(logout({ isChangingAccType: "main" }));
        } else {
            const token = localStorage.getItem("token") || getCookie("token");
            dispatch(logout({ isChangingAccType: "demo", user, token }));
        }
    };

    // Modal:
    const handleShowModal = (modalName, modalVisible) => {
        setShowModal({
            modal: modalName,
            isVisible: modalVisible,
        });
        if (isLoginPickerVisible === true) {
            setIsLoginPickerVisible(false);
        }
    };
    const handleCancelModal = () => {
        setShowModal({
            modal: "",
            isVisible: false,
        });
        if (isLoginPickerVisible === true) {
            setIsLoginPickerVisible(false);
        }
    };

    // Language:
    const handleChangeLang = (newLang) => {
        dispatch(changeLang(newLang));
    };

    // Account type:
    const getAccountType = () => {
        switch (user?.currentOrg?.scopeOptionKey) {
            case "organization":
                return "organization";
            case "teams":
                return "premium";
            default:
                break;
        }
        return undefined;
    };

    // Side effects:
    useEffect(() => {
        countUnreadNotifications().then((res) => {
            if (res.status) {
                setValues({ countUnreadNotifs: res.data });
            }
        });

        // if (user?.show_feedback_modal) {
        //     handleShowModal("feedback", true);
        // }
    }, []);

    useEffect(() => {
        const path = location.pathname.replace(`/${lang}`, "");
        const nav = [...suitableNav, ...generalNav].find((n) => {
            if (n.active instanceof Array) {
                for (let i = 0; i < n.active.length; i++) {
                    const m = n.active[i];
                    if (typeof m.test === "function" && m.test(path)) {
                        return true;
                    } else if (m === path) {
                        return true;
                    }
                }
            }
            return false;
        });
        if (subnavActive && subnavActive !== path) {
            setSubnavActive("");
        }
        if (nav) {
            setValues({ selectedKeys: [nav.name] });
            if (nav?.subnav?.length > 0) {
                for (let v of nav.subnav) {
                    if (v?.active?.includes(path)) {
                        setSubnavActive(v.active[0]);
                    }
                }
            }
        } else {
            setValues({ selectedKeys: [] });
        }

        // random to show feedback modal
        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        let random = getRndInteger(0, FEEDBACK_CONFIGS?.SPACE || 10);

        const flag = Math.round((FEEDBACK_CONFIGS?.SPACE || 10) / 2);

        let condition = random === flag;

        if (!FEEDBACK_CONFIGS?.CAN_SHOW_AFTER_CLOSE) {
            condition = condition && isFeedbackShown.current === false;
        }

        if (condition) {
            dispatch(toggleFeedbackModalVisible(true));
            isFeedbackShown.current = true;
        }
    }, [location.pathname]);

    // For render React elements:
    const renderNavItemsBy = (navItemList = []) => {
        return navItemList
            .filter((navItem) => {
                if (user?.is_in_organization && navItem?.hide_with_owner) {
                    return false;
                }

                return true;
            })
            .map((navItem) => {
                const NavIcon = navItem.icon;
                let navItemClassName = "";
                if (collapsed) {
                    navItemClassName = "collapsed";
                }

                if (navItem?.subnav) {
                    navItemClassName += " has-subnav";
                    if (showSubnav?.name == navItem.name && showSubnav.show) {
                        navItemClassName += " show-subnav";
                    }
                }

                if (navItem.name === "admin_dashboard" && Array.isArray(allowRoutes) && allowRoutes?.length <= 0) {
                    return null;
                }

                if (navItem.isAllowedDemoAccount === false && accountType === "demo") {
                    return null;
                }

                if (navItem?.allowRole && user.role && !navItem?.allowRole?.includes(user.role)) {
                    return null;
                }

                // ẩn cuộc thi và BXH nằm bên ngoài admin_dashboard đối với tk teams or tổ chức
                if (
                    getAccountType() &&
                    (navItem.name === "competitions" || navItem.name === "ranking") & (user?.role === "teacher")
                ) {
                    return null;
                }
                return {
                    key: `${navItem.name}`,
                    title: t(`nav.${navItem.name}`),
                    className: navItemClassName,
                    label:
                        navItem?.subnav?.length > 0 ? (
                            <div className="nav-menu-item-wrapper has-subnav">
                                <span className="nav-menu-item" onClick={() => handleShowSubnav(navItem.name)}>
                                    <span className="nav-icon">
                                        <NavIcon />
                                    </span>
                                    <span className="item-section">
                                        {navItem.name === "admin_dashboard" ? (
                                            user?.currentOrg?.scopeOptionKey === "teams" ? (
                                                <span className="nav-name">{t(`nav.${navItem.name}_team`)}</span>
                                            ) : user?.currentOrg?.scopeOptionKey === "organization" ? (
                                                <span className="nav-name">{t(`nav.${navItem.name}_org`)}</span>
                                            ) : (
                                                <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                                            )
                                        ) : (
                                            <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                                        )}
                                        <CaretDownOutlined className="nav-collapsible-icon" />
                                    </span>
                                </span>
                                {/* <Menu
                                    items={renderNavItemsBy(navItem.subnav)}
                                    selectedKeys={values.selectedKeys}
                                /> */}

                                {renderSubnav(navItem.subnav, navItem.name)}
                            </div>
                        ) : (
                            <Link to={generate(navItem.routeName, navItem.routeParams || {})}>
                                <span className="nav-menu-item">
                                    <span className="nav-icon">
                                        <NavIcon />
                                    </span>
                                    <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                                </span>
                            </Link>
                        ),
                    // disabled: values.selectedKeys.includes(navItem.name) ? true : false,
                    // icon: <NavIcon />,
                    // onClick: () => { },
                    // onClick: navItem?.subnav?.length > 0 ? () => handleShowSubnav(navItem.name) : undefined,
                    // children:  navItem?.subnav?.length > 0 ? renderNavItemsBy(navItem.subnav):undefined,
                };
            });
    };

    const getNavsForSubscription = () => {
        return [
            isUpgradable
                ? {
                      key: "subscription",
                      title: t(`account.upgrade_now`),
                      className: "item-subsc",
                      label: <ButtonSubscription className="nav-menu-item" />,
                      // disabled: false,
                      // icon: null,
                      onClick: () => {
                          dispatch(toggleCollapsed(true));
                          handleShowModal("subscription", true);
                      },
                  }
                : {},
        ];
    };

    const getNavsForTopSection = () => {
        return [
            // ...getNavsForSubscription(111),

            {
                key: "notification",
                title: t(`nav.notification`),
                label: (
                    <Badge count={values.countUnreadNotifs} showZero={false}>
                        <span className="nav-menu-item">
                            <span className="nav-icon">
                                <NotificationGradientIcon />
                            </span>
                            <span className="nav-name">{t(`nav.notification`)}</span>
                        </span>
                    </Badge>
                ),
                // disabled: false,
                // icon: null,
                onClick: () => handleShowModal("notification", true),
            },
            isDemoAccActivable && ["teacher"].includes(user.role)
                ? {
                      key: "demo_account",
                      title: t(`nav.demo_account`),
                      label: (
                          <span className="nav-menu-item">
                              <span className="nav-icon">
                                  <TK_ThuNghiem />
                              </span>
                              <span className="nav-name">
                                  {accountType === "demo" ? t(`sidebar.main_account`) : t(`sidebar.demo_account`)}
                              </span>
                          </span>
                      ),
                      // disabled: false,
                      // icon: null,
                      onClick: () => handleShowModal("demo_account", true),
                  }
                : {},
        ];
    };

    const getNavsForMidSection = () => {
        return renderNavItemsBy(suitableNav);
    };

    const getNavsForBottomSection = () => {
        return renderNavItemsBy(generalNav);
    };

    // const getRestrictedNavs = () => {
    //     if (user.organization?.is_organization_owner === true) {
    //         return renderNavItemsBy(restrictedNav);
    //     }
    //     return [];
    // };

    const getExtraNavs = () => {
        return [
            ...(accountType !== "demo"
                ? [
                      {
                          key: "feedback",
                          title: t(`nav.feedback`),
                          label: (
                              <span className="nav-menu-item">
                                  <span className="nav-icon" style={{ color: "#4fe3c1" }}>
                                      <SvgFeedback />
                                  </span>
                                  <span className="nav-name">{t(`nav.feedback`)}</span>
                              </span>
                          ),
                          onClick: () => {
                              dispatch(toggleFeedbackModalVisible(false));
                          },
                      },
                  ]
                : []),
            ...(accountType !== "demo"
                ? [
                      {
                          key: "logout",
                          title: t(`nav.logout`),
                          label: (
                              <span className="nav-menu-item">
                                  <span className="nav-icon">
                                      <SvgPowerGradient />
                                  </span>
                                  <span className="nav-name">{t(`nav.logout`)}</span>
                              </span>
                          ),
                          onClick: handleLogOut,
                      },
                  ]
                : []),
        ];
    };

    // React elements:
    const renderAvatar = () => {
        return (
            <div className={collapsed ? "avatar" : "avatar avatar-show"}>
                {/* <span className="avatar-img-wrapper">
                    <span className="avatar-img">
                        <img src={user.avatar || NoAvatar} alt="55x55" />
                    </span>
                </span> */}
                <Avatar
                    src={user.avatar || NoAvatar}
                    alt={"55x55"}
                    displayType="by-account-type"
                    accountType={getAccountType()}
                />
                {!collapsed && (
                    <p>
                        <span className="avatar-email"></span>
                        <span className="avatar-welcome">{t("sidebar.hello")}</span>
                        <Tooltip title={user.name} placement="right">
                            <span className="avatar-name">{user.name}</span>
                        </Tooltip>
                    </p>
                )}
            </div>
        );
    };

    const renderSubnav = (subnav, fatherName) => {
        return (
            <div className="subnav">
                {subnav &&
                    subnav.map((nav) => {
                        let active = "";
                        active = subnavActive === nav.active[0] ? "active" : "";
                        if (
                            (fatherName === "admin_dashboard" && allowRoutes.includes(nav.name)) ||
                            fatherName !== "admin_dashboard"
                        ) {
                            return (
                                <div key={nav.key} className={`subnav-item ${active}`}>
                                    <Link to={generate(nav.routeName, nav.routeParams || {})}>
                                        <div>
                                            <span className="subnav-icon">{React.createElement(nav.icon)}</span>
                                            {nav.name === "organization_detail" ? (
                                                <Tooltip
                                                    title={t(
                                                        `sidebar.${nav.name}_${
                                                            scopeOptionKey == "teams" ? "team" : "org"
                                                        }`
                                                    )}
                                                >
                                                    <span className="subnav-title">
                                                        {t(
                                                            `sidebar.${nav.name}_${
                                                                scopeOptionKey == "teams" ? "team" : "org"
                                                            }`
                                                        )}
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={t(`sidebar.${nav.name}`)}>
                                                    <span className="subnav-title">{t(`sidebar.${nav.name}`)}</span>
                                                </Tooltip>
                                            )}
                                            {/* <span className="subnav-title">{t(`sidebar.${nav.name}`)}</span> */}
                                        </div>
                                    </Link>
                                </div>
                            );
                        }
                    })}
            </div>
        );
    };

    return (
        <div className="sidebar-menu-wrapper fixed-nav-menu-item">
            <div
                className={`sidebar-menu-overlay${collapsed ? "" : " visible"}`}
                onMouseOver={(e) => handleToggleCollapsed(e, "hide")}
            ></div>
            <div
                className={`sidebar-menu-overlay for-small-device${collapsed ? "" : " visible"}`}
                onClick={(e) => handleToggleCollapsed(e, "hide")}
            ></div>

            <Layout.Sider
                className="sidebar-menu sidebar_scroll scroll_grey"
                collapsible
                collapsed={collapsed}
                collapsedWidth={85}
                width={280}
                onCollapse={(e) => handleToggleCollapsed(e, "show")}
                onMouseOver={(e) => handleToggleCollapsed(e, "show")}
                theme="light"
                defaultCollapsed="fixed"
                ref={sidebarRef}
                trigger={null}
            >
                <div className={`sidebar-container${isLoginPickerVisible ? " show-overlay" : ""}`}>
                    <div className="sidebar-container-overlay" onClick={() => setIsLoginPickerVisible(false)}></div>

                    {accountType === "demo" ? (
                        <div className="sidebar-login-picker">{renderAvatar()}</div>
                    ) : (
                        <Dropdown
                            visible={collapsed === true ? false : isLoginPickerVisible}
                            onVisibleChange={(val) => setIsLoginPickerVisible(val)}
                            overlay={
                                <>
                                    {isUpgradable ? (
                                        <Menu
                                            className="sidebar-nav-menu for-login-picker"
                                            items={[...getNavsForSubscription()]}
                                            selectedKeys={values.selectedKeys}
                                        />
                                    ) : null}
                                    <UserScopePicker />
                                </>
                            }
                            className="sidebar-login-picker"
                        >
                            {renderAvatar()}
                            {!collapsed && <CaretDownOutlined className="picker-toggle-icon" />}
                        </Dropdown>
                    )}

                    {user?.role === "teacher" &&
                        (user?.currentOrg?.team_name ? (
                            <>
                                <div className="sidebar-coll-item mr-bottom">
                                    <div className="upgrade-now">
                                        <ButtonSubscription
                                            className={clsx("nav-menu-item", { collapsed: collapsed })}
                                            onClick={() => {
                                                dispatch(toggleCollapsed(true));
                                                handleShowModal("subscription", true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <Divider style={{ margin: "0 0 10px 0" }} />
                                <div className="sidebar-coll-item mr-bottom">
                                    <TextScroll
                                        text={user.currentOrg.team_name}
                                        icon={user?.currentOrg?.avatar}
                                        defaultIcon={<TeamOutlined />}
                                    />
                                </div>
                            </>
                        ) : user?.currentOrg?.organization_name ? (
                            <div className="sidebar-coll-item mr-bottom">
                                <TextScroll
                                    text={user.currentOrg.organization_name}
                                    icon={user?.currentOrg?.avatar}
                                    defaultIcon={<TeamOutlined />}
                                />
                            </div>
                        ) : !user?.currentOrg ? (
                            <>
                                <div className="sidebar-coll-item mr-bottom">
                                    <div className="upgrade-now">
                                        <ButtonSubscription
                                            className={clsx("nav-menu-item", { collapsed: collapsed })}
                                            onClick={() => {
                                                dispatch(toggleCollapsed(true));
                                                handleShowModal("subscription", true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <Divider style={{ margin: "0 0 10px 0" }} />
                            </>
                        ) : null)}

                    <div className="sidebar-scrollable-section">
                        <div className="sidebar-section">
                            <Menu
                                className="sidebar-nav-menu"
                                items={[...getNavsForTopSection()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <Menu
                                className="sidebar-nav-menu"
                                items={[...getNavsForMidSection()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <Menu
                                className="sidebar-nav-menu"
                                items={[...getNavsForBottomSection(), ...getExtraNavs()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <div className="language-box">
                                {lang === "en" ? (
                                    <a onClick={() => handleChangeLang("vi")}>
                                        <span className="flag">
                                            <img src={vietnamFlag} />
                                        </span>
                                        <span className="language-text">{t("lang.vietnamese")}</span>
                                    </a>
                                ) : (
                                    <a onClick={() => handleChangeLang("en")}>
                                        <span className="flag">
                                            <img src={usFlag} />
                                        </span>
                                        <span className="language-text">{t("lang.english")}</span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout.Sider>

            <PopConfirm
                type="action"
                visible={showModal.modal === "demo_account" && showModal.isVisible}
                onOk={handleChangeAccountType}
                onCancel={handleCancelModal}
                icon={SwapOutlined}
                title={`${
                    accountType === "demo"
                        ? t("shared.msg_confirm_action_of_main_account")
                        : t("shared.msg_confirm_action_of_demo_account")
                }?`}
            />

            <ModalNotification
                visible={showModal.modal === "notification" && showModal.isVisible}
                onOk={handleCancelModal}
                onCancel={handleCancelModal}
                countNewItems={values.countUnreadNotifs}
                onChangeCountNewItems={(v) => {
                    setValues({ countUnreadNotifs: v });
                }}
            />

            <ModalSubscriptionPlans
                visible={showModal.modal === "subscription" && showModal.isVisible}
                onOk={handleCancelModal}
                onCancel={handleCancelModal}
            />
        </div>
    );
}

export default NavSidebar;
