import { Buffer } from "buffer";
import { t } from "i18next";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const base64_encode = (data) => {
    return Buffer.from(data).toString("base64");
};

export const base64_decode = (data) => {
    return Buffer.from(data, "base64").toString();
};

export function parse_jwt(token) {
    try {
        return JSON.parse(base64_decode(token.split(".")[1]));
    } catch (e) {
        return null;
    }
}
export function formatNumber(number) {
    if (typeof number === "undefined") {
        number = 0;
    }
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(key, value, days = 1) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = key + "=" + (value || "") + expires + "; path=/";
}

/**
 * ARRAY:
 */
export function compareArrays(comparisonType, ...arrayList) {
    if (!arrayList?.length || arrayList?.length <= 1) {
        return false;
    }
    switch (comparisonType) {
        // 1. Compare array indexes, and values:
        case "compare-exact": {
            const exactList = arrayList[0] || [];
            for (let i = 1; i < arrayList.length; i++) {
                const currList = arrayList[i];
                if (currList?.length !== exactList?.length) {
                    return false;
                }
                for (let j = 0; j < currList.length; j++) {
                    if (currList[j] !== exactList[j]) {
                        return false;
                    }
                }
            }
            return true;
        }
        // 2. Compare array indexes, and values, in this case:
        // - "null" value is the same as "undefined" value.
        case "compare-exact-nearly": {
            const exactList = JSON.stringify(arrayList[0]);
            for (let i = 1; i < arrayList.length; i++) {
                if (JSON.stringify(arrayList[i]) !== exactList) {
                    return false;
                }
            }
            return true;
        }
        // 3. Compare values only:
        case "compare-values": {
            const exactList = arrayList[0] || [];
            for (let i = 1; i < arrayList.length; i++) {
                const currList = arrayList[i];
                if (currList?.length !== exactList?.length) {
                    return false;
                }
                for (let j = 0; j < currList.length; j++) {
                    if (!exactList.includes(currList[j])) {
                        return false;
                    }
                }
            }
            return true;
        }
        default:
            return false;
    }
}

/**
 * Convert color:
 * @param {*} r red
 * @param {*} g green
 * @param {*} b blue
 * @param {*} a alpha
 * @returns (string) A hexadecimal color.
 */
export function convertRGBAToHexA(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
}

/**
 * Convert color:
 * @param {string} h hex
 * @returns (object) A rgba color in detail.
 */
export function convertHexAToRGBA(h) {
    let r = 0,
        g = 0,
        b = 0,
        a = 1;

    if (h.length == 5) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
        a = "0x" + h[4] + h[4];
    } else if (h.length == 9) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
        a = "0x" + h[7] + h[8];
    }
    a = +(a / 255).toFixed(3);

    return {
        str: "rgba(" + +r + "," + +g + "," + +b + "," + a + ")",
        rgb: {
            r: +r,
            g: +g,
            b: +b,
            a: +a,
        },
    };
}

/**
 * Convert color:
 * @param {number} value A number
 * @returns (string) A color.
 */
export function getColorForPercentChart(value) {
    if (value <= 100 && value >= 80) {
        return "#7FB6A1";
    } else if (value < 80 && value >= 60) {
        return "#7ACBBE";
    } else if (value < 60 && value >= 40) {
        return "#F9D26D";
    } else if (value < 40 && value >= 20) {
        return "#FBB36B";
    } else if (value < 20 && value >= 0) {
        return "#FB836B";
    } else {
        return "#757575";
    }
}

/**
 * @param {string[]} arr: list need check duplicate
 * @return {boolean}: is duplicate
 */
export const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length;
};

export const convertQuestionState = (questions, listAnswersInRecord) => {
    const result = [];

    questions?.forEach((question) => {
        if (question?.id && listAnswersInRecord) {
            if (listAnswersInRecord[question.id]) {
                // Rest question types
                if (Array.isArray(listAnswersInRecord[question.id].answered)) {
                    listAnswersInRecord[question.id].answered.length > 0 && result.push(question.order);

                    return;
                }

                // Drag drop group
                if (listAnswersInRecord[question.id]?.answered?.match) {
                    Object.values(listAnswersInRecord[question.id]?.answered?.match || {}).forEach((value) => {
                        if (value?.length > 0) {
                            result.push(question.order);
                            return;
                        }
                    });
                }

                // Passage
                if (typeof listAnswersInRecord[question.id].answered === "object") {
                    Object.keys(listAnswersInRecord[question.id]?.answered || {}).forEach((childId) => {
                        if (listAnswersInRecord[question.id]?.answered[childId]?.length) {
                            result.push(question.order);
                        }
                    });
                }
            }
        }
    });

    return result;
};

export const checkIsObject = (object) => {
    if (typeof object === "object" && !Array.isArray(object) && object !== null) return true;
    return false;
};

/*
 * @param {response of getPersonalProgress api} data Element for snapshot and printf
 * @param {string} fileName name's file will save when export pdf
 * @returns (void)
 *
 *  */
export const exportPersonalLearningToPdfText = async (data, fileName, logo, nameOfClass) => {
    const renderUserBoard = (username) => {
        return [
            {
                text: [
                    {
                        text: `${t("userBoard.full_name")}: `,
                        bold: true,
                    },
                    username,
                ],
            },
            {
                text: [
                    {
                        text: `${t("userBoard.class")}: `,
                        bold: true,
                    },
                    nameOfClass.join(", "),
                ],
            },
        ];
    };

    const renderBodyChartAssignmentCategory = (data = []) => {
        const columns = data?.map((col) => [
            col.name,
            { text: col.percent, color: getColorForPercentChart(col.percent) },
        ]);

        return columns;
    };

    const renderBodyChartAllAssignmentDone = (data = {}) => {
        const propsLabel = {
            very_good: t("report.very_good"),
            good: t("report.good"),
            medium: t("report.ordinary"),
            failed: t("report.failed"),
        };

        const propsColor = {
            very_good: "#2DAC81",
            good: "#2FB8E1",
            medium: "#FBD729",
            failed: "#EF8628",
        };

        const columns = Object.entries(data || {})?.map(([classification, value]) => {
            classification = classification.split("percent_assignment_")[1];
            return [
                {
                    text: propsLabel[classification],
                    color: propsColor[classification],
                },
                value + "%",
            ];
        });

        return columns;
    };

    const renderBodyReportTable = (data) => {
        const result = data?.map((record) => {
            const kq = [
                record.assignment?.class?.name,
                record.assignment?.category?.name ?? t("shared.unknown"),
                record.assignment?.name,
                {
                    text: record.score_percent,
                    alignment: "center",
                    fillColor: getColorForPercentChart(record.score_percent),
                    color: "#fff",
                },
            ];

            return kq;
        });

        return result;
    };

    const myCustomLayout = {
        fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#f8f8f8" : null;
        },
        hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
        },
        vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
        },
        hLineColor: function (i, node) {
            return "red";
        },
        vLineColor: function (i, node) {
            return "red";
        },
        hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return null;
            }
            return 0;
        },
        vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
                return null;
            }
            return 0;
        },
        paddingTop: () => {
            return 14;
        },
        paddingBottom: () => {
            return 14;
        },
        // paddingLeft: function (i, node) {
        //     return 8;
        // },
        // paddingRight: function (i, node) {
        //     return 8;
        // },
    };

    const docDefinition = {
        content: [
            {
                image: logo,
                fit: [200, 60],
                alignment: "right",
            },
            renderUserBoard(data.name),
            {
                text: t("report.course_completion_score"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["25%", "25%"],
                    body: [
                        [t("report.class"), t("report.complete_progress")],
                        ...renderBodyChartAssignmentCategory(data?.chart_for_assignment_category),
                    ],
                },
                layout: myCustomLayout,
            },
            {
                text: t("report.rate_tests_taken"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["25%", "25%"],
                    body: [
                        [t("report.classification"), t("report.ratio")],
                        ...renderBodyChartAllAssignmentDone(data?.chart_for_assignment),
                    ],
                },
                layout: myCustomLayout,
            },
            {
                text: t("report.test_classification"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*", "25%", "*", "20%"],

                    body: [
                        [t(`report.name_of_class`), t("report.subject2"), t("report.test"), t("report.average_score")],
                        ...renderBodyReportTable(data?.records_without_history),
                    ],
                },
                layout: myCustomLayout,
            },
        ],
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // for product
    pdfDocGenerator.download(fileName);

    // for debug
    // pdfDocGenerator.getBlob((blob) => {
    //     const url = URL.createObjectURL(blob);
    //     window.open(url);
    // });
};

// func for add external script file
export function AddExternalScript(urlOfTheLibrary) {
    if (!document.getElementById("zdraw")) {
        const script = document.createElement("script");
        script.id = "zdraw";
        script.type = "text/javascript";
        script.src = urlOfTheLibrary;
        // script.async = true;
        document.body.appendChild(script);
    }
}

export function compareTwoTime(t1, t2) {
    //check is t1>t2
    const time1 = moment(t1);
    const time2 = moment(t2);

    return time2.isBefore(time1);
}

export function getAssignmentResult({ record }) {
    //kq của vòng thi
    const textResult = {
        not_submitted: t("ranking.not_submitted"), // Rớt (chưa nộp)
        passed: t("ranking.passed"), // đậu (đã nộp)
        failed: t("ranking.failed"), // rớt (đã nộp)
        marked: t("ranking.marked"), // đã chấm (đã nộp)
        not_ranked: t("ranking.not_ranked"), // chưa xếp hạng (đã nộp và đã chấm)
    };
    if (!record || !(record?.id || record?._id)) {
        return (
            <span className="competition-assignment-result failed">
                {`${t("ranking.failed")} (${t("ranking.donot")})`}
            </span>
        );
    }
    if (record?.result === "not_submitted") {
        return (
            <span className="competition-assignment-result failed">{`${t("ranking.failed")} (${t(
                "ranking.not_submitted"
            )})`}</span>
        );
    }

    return (
        <span className="competition-assignment-result passed">
            {textResult?.[record?.result] ? textResult?.[record?.result] : record?.result}
        </span>
    );
}

const uniqueArray = (array) => {
    if (Array.isArray(array)) {
        return [...new Set(array)];
    }
    return [];
};

const removeItemInArray = (arr, itemToRemove) => {
    if (!Array.isArray(arr)) return [];
    return arr.filter((item) => item !== itemToRemove);
};

export { uniqueArray, removeItemInArray };

/**
 *
 * @param {blob} blob :  Object Blob
 * @returns
 */
export const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

function base64ToBlob(base64String, mimeType) {
    const binaryString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: mimeType || "application/octet-stream" });
}

function isFunction(variable) {
    return typeof variable === "function";
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            let dataUrl = reader.result;
            let base64 = dataUrl.split(",")[1];
            resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export const replaceBlobWithBase64 = async (img) => {
    let src = img.src;
    if (src.startsWith("blob:")) {
        try {
            const response = await fetch(src);
            const blob = await response.blob();
            const base64 = await blobToBase64(blob);
            img.src = "data:image/png;base64," + base64;
        } catch (error) {
            console.error("Error:", error);
        }
    }
};

export { isFunction, base64ToBlob };
