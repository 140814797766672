import React, { useEffect, useMemo, useRef } from "react";
import StudentPDFPreviewer from "./StudentPDFPreviewer";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "./Sidebar";
import { useValues } from "src/hooks";
import TestHeader from "./Header";
import BookIcon from "src/assets/images/book.svg";
import ExitIcon from "src/assets/images/exit.svg";
import PencilIcon from "src/assets/images/pencil.svg";

import "./TestLayout.scss";
import Thumbnails from "./Thumbnails";

const TestPDF = ({ data, previewRef }) => {
    const toggleRef = useRef();
    const listThumbnails = useMemo(() => {
        return data?.questions?.map((item, idx) => {
            return { ...item, page: idx + 1 };
        });
    }, [data?.questions]);

    const [values, setValues] = useValues({ currentPageData: listThumbnails?.[0], showThumbnails: false });

    function toggleThumbnails() {
        setValues({ showThumbnails: !values.showThumbnails });
    }

    return (
        <div className="testLayout_wrapper pdf">
            <Layout style={{ flexDirection: "column", padding: 10 }}>
                <TestHeader title={data?.name || ""} icon={BookIcon} />
                <Layout style={{ flexDirection: "row" }}>
                    <Thumbnails
                        testData={listThumbnails}
                        currentThumbnail={values.currentPageData}
                        setCurrentThumbnails={(data) => {
                            setValues({ currentPageData: data });
                        }}
                        toggleRef={toggleRef}
                        toggleThumbnails={toggleThumbnails}
                        isShowThumbnails={values.showThumbnails}
                    />
                    <Content className="testLayout_content">
                        <StudentPDFPreviewer
                            width={previewRef?.current?.offsetWidth || 600}
                            height={previewRef?.current?.offsetHeight || 800}
                            src={values.currentPageData?.src}
                            currentThumbnails={values.currentPageData}
                            toggleThumbnail={toggleThumbnails}
                            isShowThumbnails={values.showThumbnails}
                        />
                    </Content>
                    <Sider theme="light" width="35%">
                        {/* <QuestionList questions={data?.questions} /> */}
                        <Sidebar questions={values.currentPageData?.questions} recordId={data?.record_id} />
                    </Sider>
                </Layout>
            </Layout>
        </div>
    );
};

export default TestPDF;
