// File is verified!
import api from "..";

const orgFields = [
    "name",
    "owner_id",
    "file_id",
    "reseller_id",
    "total_branchs",
    "total_classes",
    "total_employees",
    "total_teachers",
    "total_students",
];

const orgRelations = ["owner", "users", "reseller", "classes", "subscriptions", "branches", "image"];

const userFields = {
    basicInfo: [
        "role",
        "name",
        "first_name",
        "last_name",
        "phone",
        "gender",
        "username",
        "active",
        "avatar",
        "address",
    ],

    authInfo: ["is_new_user", "code_verify", "code_verify_time", "login_with_social", "facebook_id", "google_id"],

    organizationInfo: [
        "organization_id",
        "class_detail_in_organization",
        "user_organization",
        "storage_organization_empty",
    ],

    classInfo: ["count_class", "count_student", "class_user_status", "class_user_role", "class_in_organization"],
};

const userRelations = {
    organizationInfo: ["branches", "roles", "organization", "subscriptions", "teams"],
    fileInfo: ["files", "fileAuth"],
    classInfo: ["classUsers"],
};

// Get organization user list:
export const fetchOrganizationUsers = (fetchParams) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    // fetchParams.role && filters.push(`role:${fetchParams.role}`);
    !isNaN(fetchParams.gender) && filters.push(`gender:${fetchParams.gender}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    // fetchParams.is_employee_in_organization &&
    //     filters.push(`is_employee_in_organization:${fetchParams.is_employee_in_organization}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.is_employee_a_teacher && filters.push(`is_employee_a_teacher:${fetchParams.is_employee_a_teacher}`);

    // Fields to get:
    const usedUserFields = [...userFields.basicInfo, "storage_organization_empty"];
    if (["student", "teacher"].includes(fetchParams.role)) {
        usedUserFields.push("class_detail_in_organization", "class_user_status");
    }
    if (fetchParams.role === "student") {
        usedUserFields.push("class_in_organization");
    }
    if (fetchParams.role === "teacher") {
        usedUserFields.push("count_class", "count_student");
    }

    // Parameters:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${usedUserFields}`,
        _relations: `${userRelations.organizationInfo}`,
        _branch_fields: `${["name"]}`,
        _role_fields: `${["name"]}`,
    };

    return api
        .get(`/manage/${fetchParams.role}`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

// Organization details:
export const fetchOrganizationDetails = (fetchParams) => {
    const params = {
        _relations: `${["owner", "image", "branches"]}`,
        _organization_fields: `${[
            "name",
            "owner_id",
            "file_id",
            "total_branchs",
            "total_classes",
            "total_employees",
            "total_teachers",
            "total_students",
        ]}`,
        _user_fields: `${["name"]}`,
        _file_fields: `${["name", "src"]}`,
        _branch_fields: `${["name", "active"]}`,
        // _class_fields: `${["code", "name", "owner", "teachers", "total_student"]}`,
    };

    return api
        .get(`/organization/${fetchParams.organization_id}`, params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const updateOrganizationDetails = (organization_id, data) => {
    const { name, file_id } = data;

    if (!organization_id || !name) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .put(`/organization/${organization_id}`, data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// (ORGANIZATION INVITATION) Invite a user:
export const inviteUserToOrganization = (data) => {
    if (!data.email) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .post("/organization/mail_invite_user", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

export const addUserToOrganizationByInvitation = (organizationId) => {
    return api
        .put(`/organization/${organizationId}/add_user_to_organization`)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

// export const updateUserInOrganization = ({ organization_id, userInfo }) => {
//     return api
//         .put(`/organization/${organization_id}/user`, userInfo)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// (ORGANIZATION USER MANAGEMENT) add/update:
export const addUserToOrganization = (userRole, userData) => {
    return api
        .post(`/manage/${userRole}`, userData)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateUserInOrganization = (userRole, userId, userData) => {
    return api
        .put(`/manage/${userRole}/${userId}`, userData)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const removeUserEmployeeRole = (userRole, userId, userData) => {
    if (!userData.organization_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .put(`/manage/${userRole}/remove_role/${userId}`, userData)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteUserInOrganization = (userRole, userId) => {
    return api
        .delete(`/manage/${userRole}/${userId}`)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const removeUserFromOrganization = (userId, organization_id) => {
    let url = `/organization/${userId}/remove_user_organization`;

    if (organization_id) {
        url += `?organization_id=${organization_id}`;
    }

    return api
        .put(url)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// API lấy ra team và tổ chức của user là owner
export const fetchTeamsAndOrgForUser = (fetchParams) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    (fetchParams.is_team || fetchParams.is_team == 0) && filters.push(`is_team:${fetchParams.is_team}`);
    (fetchParams.status || fetchParams.status == 0) && filters.push(`status:${fetchParams.status}`);

    // Parameters:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _organization_fields: `${["name", "is_team", "expired_date"]}`,
        _relations: `${["activeSubscription"]}`,
        _subscription_fields: `${["used_plan"]}`,
    };

    return api
        .get(`/organization`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
