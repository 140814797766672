import { CheckCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Drawer, Form, Input, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { createFeedback } from "src/api/containers/feedback";
import { useValues } from "src/hooks";
import CustomButton from "src/modules/components/Button";
import { SendingIcon } from "src/utils/drawer";
import "./FeedbackDrawer.scss";
import { useSelector } from "react-redux";

const FeedbackDrawer = ({ visible, className, onCancel = () => {}, ...rest }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.auth.user);

    const [values, setValues] = useValues({
        feedbackStatus: "notsubmit", //notsubmit, submited
        loading: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
            //ko dùng api skip nữa
            // if (user?.show_feedback_modal) {
            //     skipFeedback().then(({ status, message }) => {
            //         if (!status) {
            //             console.error("error #%d", message);
            //         }
            //     });
            // }
        }
    };

    const handleSubmit = (data) => {
        const content = data?.content;
        if (content) {
            setValues({
                loading: true,
            });
            createFeedback({ content }).then(({ status, data, message }) => {
                if (status) {
                    setValues({
                        loading: false,
                        feedbackStatus: "submited",
                    });
                } else {
                    notification.warning({ message });
                    setValues({
                        loading: false,
                    });
                }
            });
        }
    };

    const renderActions = () => {
        return (
            <>
                {values.feedbackStatus === "notsubmit" ? (
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<SendingIcon />}
                        title={t("feedback.send_feedback")}
                        // onClick={}
                    />
                ) : (
                    <CustomButton
                        htmlType="button"
                        type="primary"
                        // icon={<SendingIcon />}
                        title={t("shared.close_modal")}
                        onClick={handleCancel}
                    />
                )}
            </>
        );
    };

    return (
        <Drawer
            // title={t("feedback.title")}
            width="auto"
            visible={visible}
            bodyStyle={{
                paddingBottom: 80,
                maxWidth: 600,
            }}
            onClose={handleCancel}
            destroyOnClose
            className="feedback_drawer"
        >
            <Spin spinning={values.loading}>
                {values.feedbackStatus === "notsubmit" ? (
                    <Form
                        // form={form}
                        layout="vertical"
                        className="app-form type-primary has-rd"
                        onFinish={handleSubmit}
                    >
                        <div className="feedback-title">
                            <Typography.Title level={3}>{t("feedback.title")}</Typography.Title>
                            <Typography.Title level={5}>{t("feedback.subtitle")}</Typography.Title>
                        </div>
                        <br />

                        <Form.Item
                            // label={t("shared.message")}
                            name="content"
                            rules={[{ required: true, message: t("message.required") }]}
                        >
                            <Input.TextArea className="feedback_input" placeholder={t("shared.message")} rows={10} />
                        </Form.Item>
                        <div className="form-actions">{renderActions()}</div>
                    </Form>
                ) : (
                    <div className="feedback-result">
                        <span className="feedback-result_icon">
                            <CheckCircleFilled />
                        </span>
                        <span className="feedback-result_desc">{t("feedback.thanks")}</span>
                        {renderActions()}
                    </div>
                )}
            </Spin>
        </Drawer>
    );
};
export default FeedbackDrawer;
