import { Image } from "antd";
import React from "react";
import clsx from "clsx";

import "./ThumbnailsItem.scss";

function ThumbnailsItem({ data, currentThumbnail = {}, page, onClick = () => {} }) {
    return (
        <div
            className={clsx("pdf-thumbnails-item test", { active: currentThumbnail.file_id === data?.file_id })}
            onClick={onClick}
        >
            <div className="pdf-thumbnails-item_img">
                <Image
                    preview={false}
                    src={
                        data?.src ||
                        "https://img.freepik.com/free-vector/brochure-design-template-mockup-vector_53876-61567.jpg"
                    }
                />
                <span className="pdf-thumbnails-item_number">{page}</span>
            </div>
        </div>
    );
}

export default ThumbnailsItem;
