import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { Col, Form, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import useValues from "src/hooks/useValues";
import { classFilters } from "src/api/containers/class";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import "./FilterMenu.scss";

// THIS COMPONENT IS USED FOR:
// src/modules/containers/Class/containers/ListClass/index.js
// src/modules/containers/ManageClass/ListClassInScope/index.js
// src/modules/containers/ManageTeacher/components/ModalClassList/index.js

const FilterMenu = ({
    filterParams = [],
    willFormBeCleared = false,
    excludeFields = [],
    // Data lists:
    branchDataList,
    // Callbacks:
    onFetchDataList = () => {},
    onCloseFilterMenu = () => {},
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        branches: [],
    });

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            is_active: formData.is_active === "undefined" ? undefined : formData.is_active,
            is_official: formData.is_official === "undefined" ? undefined : formData.is_official,
            ...(!excludeFields.includes("branch_id") ? { branch_id: formData.branch_id } : {}),
            ...(!excludeFields.includes("organization_id") ? { organization_id: formData.organization_id } : {}),
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.is_active !== undefined &&
            newFilterParams.push({
                name: "is_active",
                value: fetchParams.is_active,
                labelName: t("shared.status"),
                labelValue: t(`class.${classFilters.is_active[fetchParams.is_active]}`),
            });
        fetchParams.branch_id &&
            newFilterParams.push({
                name: "branch_id",
                value: fetchParams.branch_id,
                labelValue:
                    values.branches.filter((item) => {
                        return item.id === fetchParams.branch_id;
                    })[0].name || "",
            });
        fetchParams.organization_id &&
            newFilterParams.push({
                name: "organization_id",
                value: fetchParams.organization_id,
                labelValue: scopeName,
            });
        fetchParams.is_official !== undefined &&
            newFilterParams.push({
                name: "is_official",
                value: fetchParams.is_official,
                labelName: t("class.class_type"),
                labelValue:
                    fetchParams.is_official === 1
                        ? t(`class.official_class`)
                        : fetchParams.is_official === 0
                        ? t(`class.intended_class`)
                        : "",
            });
        // Fetch data:
        onFetchDataList({ page: 1, ...fetchParams });
        onCloseFilterMenu(newFilterParams);
    };

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (filterParams.length) {
                for (let i = 0; i < filterParams.length; i++) {
                    newFormData[filterParams[i].name] = filterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        const newFormData = {};
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i].name] = filterParams[i].value;
        }
        const fetchParams = {
            is_active: newFormData.is_active,
            is_official: newFormData.is_official,
            ...(!excludeFields.includes("branch_id") ? { branch_id: newFormData.branch_id } : {}),
            ...(!excludeFields.includes("organization_id") ? { organization_id: newFormData.organization_id } : {}),
        };
        // Update form:
        form.resetFields();
        form.setFieldsValue(newFormData);
        // Fetch data:
        onFetchDataList({ page: 1, ...fetchParams });
    }, [filterParams]);

    // READ DATA LISTS:
    useEffect(() => {
        if (!excludeFields.includes("branch_id")) {
            // Read from props:
            if (branchDataList instanceof Array) {
                setValues({ branches: branchDataList });
            }
            // Read from fetching:
            // ...
        }
    }, [branchDataList]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid form-horizontal" layout="horizontal">
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Form.Item name="is_active" label={t("shared.status")}>
                        <Select className="app-select" placeholder={t("shared.choose_status")} allowClear>
                            {Object.keys(classFilters.is_active).map((item, itemIndex) => {
                                return (
                                    <Select.Option key={`ia${itemIndex}`} value={item}>
                                        {t(`class.${classFilters.is_active[item]}`)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="is_official" label={t("class.class_type")}>
                        <Select className="app-select" placeholder={t("class.select_class_type")}>
                            <Select.Option value={1}>{t(`class.official_class`)}</Select.Option>
                            <Select.Option value={0}>{t(`class.intended_class`)}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                {!excludeFields.includes("branch_id") && (
                    <>
                        {values.branches.length ? (
                            <Col xs={24}>
                                <Form.Item name="branch_id" label={t("class.branch")}>
                                    <Select className="app-select" placeholder={t("class.select_branch")} allowClear>
                                        {values.branches.map((item, index) => (
                                            <Select.Option key={`br${index}`} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}
                    </>
                )}

                {!excludeFields.includes("organization_id") && (
                    <>
                        {fParamsOrgMember?.organization_id ? (
                            <Col span={24}>
                                <Form.Item name="organization_id" label={t("shared.scope")}>
                                    <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                                        <Select.Option value={fParamsOrgMember.organization_id}>
                                            {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}
                    </>
                )}
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => onCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
