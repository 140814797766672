import { useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import HTMLDisplayer from "../../Displayers/HTMLDisplayer";
import "./FillBlanks.scss";
import clsx from "clsx";

function FillBlanks(props) {
    const {
        isReadonly = false,
        question = "",
        answer /** @params {string[]} answer */,
        defaultAnswer,
        onChange = () => {},
        showAnswerList = true,
        disabledSelectedAnswers = false,
        exam_types,
    } = props;

    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState(defaultAnswer || []);

    const handleSelectAnswer = (selectorIndex, inputValue) => {
        const newSelectedAnswer = [...selectedAnswer];
        newSelectedAnswer[selectorIndex] = inputValue;
        setSelectedAnswer(newSelectedAnswer);
        onChange({ answered: newSelectedAnswer });
    };

    return (
        <div className={clsx("q-fill-blanks", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            {answer?.length > 0 && showAnswerList && (
                <div className="view-answer-list">
                    {answer?.map((answer, i) => {
                        return <HTMLDisplayer key={`ans${i}`} rootType="span" htmlString={answer} />;
                    })}
                </div>
            )}

            <div className={clsx("q-content-title", { exam_types })}>
                <QuestionContentDetail
                    blankVisibleType="select"
                    isReadonly={isReadonly}
                    isDisabledSelectedAnswers={disabledSelectedAnswers}
                    value={{
                        question,
                        answerList: answer,
                    }}
                    selectedAnswer={selectedAnswer}
                    handleSelectAnswer={handleSelectAnswer}
                />
            </div>
        </div>
    );
}

export default FillBlanks;
