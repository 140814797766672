import React from 'react';

function IconPreview(props) {
    const {
        fill = '#000'
    } = props;

    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10.0043 7.88306C8.8366 7.88306 7.88721 8.83245 7.88721 10.0002C7.88721 11.1679 8.8366 12.1173 10.0043 12.1173C11.172 12.1173 12.1214 11.1679 12.1214 10.0002C12.1214 8.83245 11.172 7.88306 10.0043 7.88306Z" />
            <path d="M18.2509 9.35498C16.3951 6.94347 14.1126 4.71058 10.0074 4.70728H10.0041H10.0008C5.89225 4.70728 3.60975 6.94016 1.75397 9.35498C1.46287 9.7354 1.46287 10.2647 1.75397 10.6451C3.60975 13.0566 5.89225 15.2895 9.99745 15.2928H10.0008C10.0008 15.2928 10.0008 15.2928 10.0041 15.2928C10.0074 15.2928 10.0107 15.2928 10.014 15.2928C10.0173 15.2928 10.0239 15.2928 10.0272 15.2928C14.1192 15.2829 16.3951 13.0533 18.2475 10.6451C18.5453 10.2647 18.5453 9.7354 18.2509 9.35498ZM10.0074 13.1757C10.0041 13.1757 10.0008 13.1757 10.0008 13.1757C8.25084 13.1724 6.82841 11.75 6.82841 10C6.82841 8.25012 8.25084 6.82769 10.0008 6.82438H10.0041C11.754 6.82769 13.1764 8.25012 13.1764 10C13.1764 11.75 11.754 13.1724 10.0074 13.1757Z" />
            <path d="M4.61192 19.5997H0.953296C0.648962 19.5997 0.400864 19.3516 0.400864 19.0473V15.2927H0.00390625V19.0506C0.00390625 19.5732 0.430635 20 0.953296 20H4.61192V19.5997V19.5997Z" />
            <path d="M19.6037 15.2927V19.0506C19.6037 19.3549 19.3556 19.603 19.0513 19.603H15.396V20H19.0546C19.5773 20 20.004 19.5732 20.004 19.0506V15.2927H19.6037Z" />
            <path d="M4.61192 0H0.953296C0.430635 0 0.00390625 0.426729 0.00390625 0.94939V4.70725H0.400864V0.94939C0.400864 0.645056 0.648962 0.396957 0.953296 0.396957H4.61192V0V0Z" />
            <path d="M19.0546 0H15.396V0.396957H19.0546C19.359 0.396957 19.6071 0.645056 19.6071 0.94939V4.70725H20.004V0.94939C20.0007 0.426729 19.5773 0 19.0546 0Z" />
        </svg>
    );
}

export default IconPreview;