import React from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import "./PaymentOrderPanel.scss";

const PaymentOrderPanel = ({
    isLoading,
    orderItems,
    amountUsual,
    amountCurrent,
    showActions = true,
    disableActions = false,
    promotions = [],
    ...rest
}) => {
    const { t } = useTranslation();

    const checkIsPrice = (val) => {
        return !!val || !isNaN(val);
    };

    return (
        <div className="payment-order-panel" {...rest}>
            <div className="order-header">
                <span className="order-title">{t("payment.order")}</span>
                {showActions ? (
                    <span className="order-actions">
                        <CustomButton
                            type="simple"
                            icon={<Icon name="icon-edit" />}
                            title={t("shared.edit")}
                            onClick={() => {}}
                            disabled={isLoading === true || disableActions === true}
                        ></CustomButton>
                    </span>
                ) : null}
            </div>
            <div className="order-body">
                {isLoading ? (
                    <div className="order-msg">
                        <Spin spinning={isLoading} />
                        <span> {t("shared.loading")}...</span>
                    </div>
                ) : null}
                {orderItems?.length
                    ? orderItems.map((item, i) => {
                          let discountPrice = item.priceUsual - item.priceCurrent;

                          return (
                              <div key={`order-item-${i}`}>
                                  <span className="order-item">
                                      <span>{t("plan.plan_name")}</span>
                                      <span className="order-item-name">{item.name}</span>
                                  </span>
                                  <span className="order-item">
                                      <span>{t("payment.price")}</span>
                                      <span className="order-item-name  item-right">
                                          <span>{item.priceUsual ? item.priceUsual.toLocaleString() : "_"}</span>
                                          <span>{t("price.vnd")}</span>
                                      </span>
                                  </span>
                                  {discountPrice && discountPrice > 0 ? (
                                      <span className="order-item">
                                          <span>
                                              {t("payment.discount")}{" "}
                                              <span>{`(-${(discountPrice / item.priceUsual) * 100}%) `}</span>
                                          </span>
                                          <span className="order-item-name item-right">
                                              <span className="current-price">
                                                  <span>-{discountPrice.toLocaleString()}</span>
                                                  <span>{t("price.vnd")}</span>
                                              </span>
                                          </span>
                                      </span>
                                  ) : null}
                                  {promotions?.length > 0 ? (
                                      <>
                                          {promotions.map((item) => {
                                              return (
                                                  <span key={item.code} className="order-item">
                                                      <span>{item.title}</span>
                                                      <span className="order-item-name item-right">
                                                          <span className="current-price">
                                                              <span>
                                                                  {item?.money?.toLocaleString().toLocaleString()}
                                                              </span>
                                                              <span>{t("price.vnd")}</span>
                                                          </span>
                                                      </span>
                                                  </span>
                                              );
                                          })}
                                      </>
                                  ) : null}

                                  {/* <span className="order-item-price">
                                      <span className="current-price">
                                          <span>{item.priceCurrent ? item.priceCurrent.toLocaleString() : "_"}</span>
                                          <span>{t("price.vnd")}</span>
                                      </span>
                                      {checkIsPrice(item.priceUsual) ? (
                                          <span className="usual-price">
                                              <span>{item.priceUsual.toLocaleString()}</span>
                                              <span>{t("price.vnd")}</span>
                                          </span>
                                      ) : null}
                                  </span> */}
                              </div>
                          );
                      })
                    : null}
            </div>
            <div className="order-footer">
                <span className="order-amount-title">{t("price.total")}</span>
                <span className="order-amount-price">
                    {checkIsPrice(amountCurrent) ? (
                        <span className="current-price order-amount-value">
                            <span>{amountCurrent.toLocaleString()}</span>
                            <span>{t("price.vnd")}</span>
                        </span>
                    ) : null}
                    {/* {checkIsPrice(amountUsual) ? (
                        <span className="usual-price">
                            <span>{amountUsual.toLocaleString()}</span>
                            <span>{t("price.vnd")}</span>
                        </span>
                    ) : null} */}
                </span>
            </div>
        </div>
    );
};

export default PaymentOrderPanel;
