import { Modal, Table } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchStudentListByAssignmentID } from "src/api/containers/record";
import { useFetch } from "src/hooks";

function ModalStudentRecords({
    assignmentId,
    testLevelInputId,
    visible,
    title,
    onCancel = () => {},
    className,
    ...rest
}) {
    const { t } = useTranslation();
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, filter: `assignment_id:${assignmentId}`, userFields: `${["name", "username", "phone", "gender"]}` },
        fetchStudentListByAssignmentID
    );

    const columns = [
        {
            title: t("class.student_name"),
            dataIndex: "name",
            key: "name",
            width: 180,
            render: (text, record) => record?.user?.name,
        },
        {
            title: t("Email"),
            dataIndex: "username",
            key: "username",
            width: 200,
            render: (text, record) => record?.user?.username,
        },
        {
            title: t("profile.phone"),
            dataIndex: "phone",
            key: "phone",
            width: 200,
            render: (text, record) => record?.user?.phone,
        },
        {
            title: t("profile.gender"),
            dataIndex: "gender",
            key: "gender",
            width: 200,
            render: (text, record) => record?.user?.gender,
        },
    ];

    const handlePagination = (page) => {
        fetch({ page: page }, true);
    };

    useEffect(() => {
        if (assignmentId && testLevelInputId) {
            fetch({ filter: `assignment_id:${assignmentId};test_level_input_id:${testLevelInputId}` }, true);
        }
    }, [assignmentId, testLevelInputId]);

    return (
        <Modal
            title={title}
            visible={visible}
            footer={null}
            onCancel={onCancel}
            width={"80%"}
            bodyStyle={{ padding: "0px" }}
            destroyOnClose
            {...rest}
            className={`add-exam-question-modal modal-mark-assignment-table${className ? " " + className : ""}`}
        >
            <Table
                className="app-table"
                dataSource={dataSource}
                columns={columns}
                // actions={actions}
                loading={loading}
                pagination={{
                    showSizeChanger: false,
                    ...pagination,
                    position: ["bottomCenter"],
                    onChange: handlePagination,
                }}
                scroll={{ x: "auto" }}
            />
        </Modal>
    );
}

export default ModalStudentRecords;
