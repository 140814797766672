import api from "..";

export const fetchGuides = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.type && filters.push(`type:${fetchParams.type}`);
    fetchParams.lang && filters.push(`lang:${fetchParams.lang}`);

    const params = {
        _noPagination: 1,
        _filter: filters.join(";") || undefined,
        _guide_fields: `${[
            "type",
            "image",
            "description",
            "title",
            "order",
            "roles",
            "lang",
            "childGuides",
            "parent_id",
            "embed_link",
        ]}`,
    };
    return api
        .get("/guide", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            meta,
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};
