import React, { useEffect, useRef } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import { fetchExams } from "src/api/containers/exam";
import { createAssignment, fetchAssignmentsBank, updateAssignment } from "src/api/containers/assignment";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses } from "src/api/containers/class";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { default as CustomButton } from "src/modules/components/Button";
import moment from "moment";
import iconClose from "src/assets/images/action-close.svg";
import { animationId } from "src/reducers/animation";
import Card from "./components/Card";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import BaiKiemtraGif from "src/assets/images/form-icon/baitap.gif";
// import BaiTapGif from "src/assets/images/form-icon/thuchanh.gif";
import "./ModalDetailEntryTest.scss";
import { createEntryTest, updateEntryTest } from "src/api/containers/entry_test";

let lastScrollTop = 0;

const ModalDetailEntryTest = ({
    visible = false,
    classId,
    categoryList = [], //list môn học -classDetail
    editingEntryTest = {},
    isLoading = false,
    isOnMobile,
    setValuesExam = () => {},
    isShowFormSelect,
    className,
    children,
    onOk = () => {},
    onCancel = () => {},

    classScopeId /** @param {string} classScopeId - Organization id, team id or undefined. */,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const [values, setValues] = useValues({
        loading: false,
        loadingExam1: false,
        optionListClass: [],
        optionListSubject: [],
        optionListExams: [],
        optionListAssignment: [],
        // optionListExamsDefault: [],
        exam_question_id: "",
        pagination: {},
    });

    const timeoutIdGetExams = useRef(0);

    const dispatch = useDispatch();

    const currMedia = useMediaQuery(["(max-width: 768px)"]);

    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [loadingAsignment, optionListAssignment, pagination, fetch, refetch] = useFetch(
        { slug: "", teacher_id: user.id, ...fParams },
        fetchAssignmentsBank
    );

    const fetchEntryTestParams = useRef({
        page: 0,
        slug: "",
    });

    const handleSearchExams = (keyword) => {
        if (!values.loadingExam1) {
            setValues({
                loadingExam1: true,
            });
        }
        clearTimeout(timeoutIdGetExams.current);
        if (typeof keyword === "string") {
            setValues({
                loadingExam1: true,
            });

            fetchEntryTestParams.current.slug = keyword;

            timeoutIdGetExams.current = setTimeout(() => {
                fetchExams({
                    category_id: form.getFieldValue("category_id"),
                    user_id: user.id,
                    slug: keyword,
                    page: 1,
                }).then((res) => {
                    setValues({
                        loadingExam1: false,
                        optionListExams: res.data || [],
                        pagination: res.pagination,
                    });
                });
            }, 800);
        }
    };

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();

        if (classScopeId) {
            formData.organization_id = classScopeId;
        }

        if (classId && !formData.class_id) {
            formData.class_id = classId;
        }

        formData.begin_date = formData.begin_date?.format("YYYY-MM-DD HH:mm") || undefined;
        formData.end_date = formData.end_date?.format("YYYY-MM-DD HH:mm") || undefined;
        formData.category_id === null && (formData.category_id = undefined);

        setValues({ loading: true });
        if (!Object.keys(editingEntryTest || {}).length) {
            createEntryTest(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "entry_test", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    onOk?.();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setValues({ loading: false });
            });
        } else {
            updateEntryTest(editingEntryTest.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", update: editingEntryTest.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false });
            });
        }
    };

    const handleChangeCate = (cateId) => {
        setValues({ loading: true });
        fetchExams({ category_id: cateId, user_id: user.id }).then((res) => {
            setValues({
                loading: false,
                optionListExams: res.data || [],
                pagination: res.pagination,
            });
        });
    };

    const handleChangeExam = (value) => {
        const data = {
            name: values.optionListExams.find((exam) => exam.id === value)?.name,
        };

        form.setFieldsValue({
            ...data,
        });
    };

    const handleScrollExam = (e) => {
        if (values.loadingExam1) {
            return;
        }

        const currentScrollTop = e.target.scrollTop;

        if (currentScrollTop > lastScrollTop) {
            if (e.target.offsetHeight + currentScrollTop >= e.target.scrollHeight * 0.8) {
                const nextPageToLoad = values.pagination?.page + 1;

                if (nextPageToLoad > values.pagination?.lastPage) return;

                fetchEntryTestParams.current = {
                    ...fetchEntryTestParams.current,
                    page: nextPageToLoad,
                };
                setValues({ loadingExam1: true });
                fetchExams({
                    category_id: form.getFieldValue("category_id"),
                    user_id: user.id,
                    ...fetchEntryTestParams.current,
                })
                    .then((res) => {
                        setValues({
                            loadingExam1: false,
                            optionListExams: [...values.optionListExams, ...(res.data || [])],
                            pagination: res.pagination,
                        });
                    })
                    .catch(() => {
                        setValues({
                            loadingExam1: false,
                        });
                    });
            }
        }
        lastScrollTop = currentScrollTop;
    };

    useEffect(() => {
        /**
         * Get classes and all categories of each class.
         * Get all exams created by the user.
         */
        Promise.all([
            // fetchClasses({ organization_id: classScopeId, relations: ["categories"], noPagination: true }),
            fetchExams({ user_id: user.id }),
        ]).then(([resExams]) => {
            if (resExams.status) {
                setValues({
                    // optionListClass: resClasses.data || [],
                    optionListExams: resExams.data || [],
                    optionListExamsDefault: resExams.data || [],
                    pagination: resExams.pagination,
                });
            }
        });
    }, [classScopeId]);

    useEffect(() => {
        if (isLoading !== values.loading) {
            setValues({ loading: isLoading });
        }
    }, [isLoading]);

    useEffect(() => {
        if (Object.keys(editingEntryTest || {}).length) {
            const classId = editingEntryTest?.class?.id;
            const cateId = editingEntryTest?.category?.id;
            // Get subject list:
            const newSubjectList = [];
            if (classId) {
                for (let i = 0; i < values.optionListClass.length; i++) {
                    if (classId === values.optionListClass[i].id) {
                        newSubjectList.push(...(values.optionListClass[i].categories || []));
                        break;
                    }
                }
            }
            // Get exam list for the selected subject:
            setValues({ loading: true });
            fetchExams({
                user_id: user.id,
                ...(cateId ? { category_id: cateId } : {}),
            }).then((res) => {
                // xử lý case đề bài ko thuộc list exams,
                // vì chỉ lấy 10 bài nên nếu ko có bài đó trong list 10 bài thì sẽ hiện id mà ko hiện tên
                const newResData = res.data;
                if (editingEntryTest?.exam) {
                    const exam = res?.data?.find((item) => item?.id === editingEntryTest?.exam?.id);
                    if (!exam) {
                        newResData?.push(editingEntryTest?.exam);
                    }
                }
                setValues({
                    loading: false,
                    optionListSubject: newSubjectList,
                    optionListExams: newResData || [],
                    pagination: res.pagination,
                });

                form.setFieldsValue({
                    category_id: editingEntryTest?.category_id,
                    name: editingEntryTest?.name,
                    exam_question_id: editingEntryTest?.exam?.id,
                    begin_date: editingEntryTest?.begin_date ? moment(editingEntryTest?.begin_date) : null,
                    end_date: editingEntryTest?.end_date ? moment(editingEntryTest?.end_date) : null,
                    homework_time_minute: editingEntryTest?.homework_time_minute,
                });
            });
        } else {
            form.setFieldsValue({
                category_id: undefined,
                name: undefined,
                exam_question_id: undefined,
                begin_date: undefined,
                end_date: undefined,
                homework_time_minute: undefined,
            });
        }
    }, [editingEntryTest]);

    useEffect(() => {
        if (visible === false) {
            setValues({
                optionListSubject: [],
                // optionListExams: values.optionListExamsDefault,
                exam_question_id: "",
            });
        }
    }, [visible]);

    useEffect(() => {
        if (currMedia.breakpointValue !== -1 && !isOnMobile) {
            setValuesExam({ isOnMobile: true });
        }
    }, [currMedia, isOnMobile]);

    const renderModalName = (id) => {
        const action = id ? t("shared.update") : t("shared.create");
        return `${action} ${t("entry_test.entry_test").toLowerCase()}`;
    };

    return (
        <Modal
            centered
            // title={t("class.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            {...rest}
            className={`app-modal type-basic flexible-height${
                className ? " " + className : ""
            } modal-detail-exam modal-detail-entry-test`}
        >
            <Spin spinning={values.loading}>
                {children ? (
                    children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {(!isOnMobile || !isShowFormSelect) && (
                            <div className="modal-detail-exam__left">
                                <div className="modal-detail-exam__left-header">{renderModalName()}</div>
                                <div className="modal-detail-exam_list">
                                    <Card
                                        active={true}
                                        title={t("entry_test.entry_test")}
                                        src={BaiKiemtraGif}
                                        onClick={() => {}}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Right section */}
                        {(!isOnMobile || isShowFormSelect) && (
                            <div className="modal-detail-exam__right">
                                <div className="detail-exam-form">
                                    <Form form={form} onFinish={handleSubmitForm}>
                                        <Form.Item
                                            label={t("shared.category")}
                                            name="category_id"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("shared.choose_category")}
                                                onChange={(value) => handleChangeCate(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {categoryList.map((item, index) => (
                                                    <Select.Option key={`subject-${item.id}`} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={t("entry_test.exam")}
                                            name="exam_question_id"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("entry_test.select_exam")}
                                                onChange={(value) => handleChangeExam(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                                onSearch={handleSearchExams}
                                                onPopupScroll={handleScrollExam}
                                                loading={values.loadingExam1}
                                                notFoundContent={
                                                    values.loadingExam1 === true ? (
                                                        <span style={{ display: "flex" }}>
                                                            <Spin style={{ marginRight: "10px" }} />{" "}
                                                            {`${t("shared.loading")}...`}
                                                        </span>
                                                    ) : undefined
                                                }
                                            >
                                                {values.optionListExams.map((exam) => (
                                                    <Select.Option key={exam.id} value={exam.id}>
                                                        {exam.name || t("shared.unknown")}
                                                    </Select.Option>
                                                ))}
                                                {values.loadingExam1 ? (
                                                    <Select.Option>
                                                        <Spin
                                                            spinning={true}
                                                            tip={`${t("shared.loading")}...`}
                                                            style={{ display: "flex", gap: 10 }}
                                                        ></Spin>
                                                    </Select.Option>
                                                ) : null}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={<span>{t("entry_test.entry_test")}</span>}
                                            name="name"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Input
                                                className="app-input"
                                                placeholder={t("entry_test.input_test_name")}
                                                maxLength={100}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("class.begin_date")}
                                            name="begin_date"
                                            rules={[{ required: true, message: t("class.input_begin_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("class.input_begin_date")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.end_date")}
                                            name="end_date"
                                            rules={[{ required: true, message: t("class.input_end_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("class.input_end_date")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.duration")}
                                            name="homework_time_minute"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <InputNumber
                                                min={0}
                                                className="app-input"
                                                placeholder={t("class.input_time")}
                                            />
                                        </Form.Item>

                                        {/* <div className="note">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        examType === "exam"
                                                            ? t("assignment.exam_note")
                                                            : t("assignment.assignment_note"),
                                                }}
                                            ></span>
                                        </div> */}
                                        <div className="btn-group">
                                            <CustomButton
                                                type="second"
                                                htmlType="submit"
                                                icon={<SendIcon />}
                                                title={t("shared.save")}
                                                style={{ width: "100%", maxWidth: "unset" }}
                                            ></CustomButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailEntryTest;
