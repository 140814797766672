import React, { useRef } from "react";
import { Alert, Layout, Space, notification } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCollapsed } from "src/reducers/general";
import { useMediaQuery } from "src/hooks";
import NavSidebar from "./NavSidebar";
import BackTopButton from "src/modules/components/BackTop";
import FeedbackButtonFloat from "src/modules/components/FeedbackButtonFloat";
import { toggleFeedbackModalVisible } from "src/reducers/general";

import { default as CustomButton } from "src/modules/components/Button";
import "./GeneralLayout.scss";
import moment from "moment";
import { useRouting } from "src/utils/router";
import { useLocation, useNavigate } from "react-router-dom";

const generateExpriedDateAlert = (org_name, lang, type) => {
    if (lang === "vi") {
        return (
            <>
                {type === "team" ? "Nhóm" : "Tổ chức"}
                <strong> {org_name || ""}</strong> của bạn đã hết hạn. Vui lòng gia hạn thêm để sử dụng đầy đủ các tính
                năng!
            </>
        );
    } else {
        return (
            <>
                {type === "team" ? "Your team" : "Your organization"}
                <strong> {org_name || ""}</strong> has expired. Please renew to use full features!
            </>
        );
    }
};

const GeneralLayout = (props) => {
    const { children } = props;
    const { generate } = useRouting();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { collapsed, isTooManyRequest, lang } = useSelector((state) => state.general);
    const { accountType, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const sidebarRef = useRef();
    const currMedia = useMediaQuery(["(min-width: 768px)"]);
    const orgExpried_date = user?.currentOrg?.expired_date;

    const handleToggleNavSidebar = (e) => {
        const { target } = e;
        if (!collapsed && !sidebarRef.current.contains(target)) {
            dispatch(setCollapsed(true));
        } else {
            dispatch(setCollapsed(false));
        }
    };

    const handleClickOutsideSidebar = (e) => {
        const { target } = e;
        if (!collapsed && !sidebarRef.current.contains(target)) {
            dispatch(setCollapsed(true));
        }
    };

    const location = useLocation();
    const pathname = location.pathname;

    const handleGoToPayment = () => {
        let planType = "";
        const curORG = user?.currentOrg;
        if (curORG.team_id) {
            planType = "team";
        } else if (curORG.organization_id) {
            planType = "organization";
        }
        const planId = curORG?.plan_id;
        const planPriceId = curORG?.plan_price_id;

        if (planId && planPriceId && planType) {
            const data = {
                planId: planId,
                planType: planType,
                planPriceId: planPriceId,
                is_extend: true,
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            navigate(generate("payment"));
        } else {
            notification.warning({ message: t("message.an_error_occurred") });
        }
    };

    return (
        <div
            className={"general_layout_wrapper" + (collapsed ? " general_layout_wrapper-collapsed" : "")}
            onClick={handleClickOutsideSidebar}
        >
            <NavSidebar sidebarRef={sidebarRef} />
            <Layout className="general_layout">
                <Layout.Content style={{ backgroundColor: "#F0F6FB" }}>
                    {isTooManyRequest && (
                        <div className="layout-msg-wrapper">
                            <Alert showIcon type="warning" message={t("too_many_request")} closable={false} />
                        </div>
                    )}
                    {accountType === "demo" ? (
                        <div className="layout-msg-wrapper">
                            <Alert
                                showIcon
                                type="warning"
                                message={t("shared.warning_demo_account")}
                                // action={
                                //     <Space>
                                //         <CustomButton
                                //             type="grey"
                                //             icon={<Icon name="icon-cross-thin" />}
                                //             title={t("shared.exit")}
                                //             onClick={() => {}}
                                //         ></CustomButton>
                                //     </Space>
                                // }
                                closable={false}
                            />
                        </div>
                    ) : null}
                    {orgExpried_date && moment(orgExpried_date).isBefore(moment()) && (
                        <div className="layout-msg-wrapper">
                            <Alert
                                showIcon
                                type="warning"
                                message={generateExpriedDateAlert(
                                    user?.currentOrg?.team_name || user?.currentOrg?.organization_name,
                                    lang,
                                    user?.currentOrg?.team_id ? "team" : "organization"
                                )}
                                closable={true}
                                action={
                                    pathname?.includes("payment") || !user.paramsForOrgOrTeam?.isOwner ? (
                                        <></>
                                    ) : (
                                        <Space>
                                            <CustomButton
                                                // type="green"
                                                // icon={<Icon name="icon-cross-thin" />}
                                                title={t("setting.extend")}
                                                onClick={() => handleGoToPayment()}
                                            ></CustomButton>
                                        </Space>
                                    )
                                }
                            />
                        </div>
                    )}
                    {children}
                </Layout.Content>
            </Layout>
            {currMedia.breakpointValue !== 768 ? (
                <span
                    className={"toggle-btn-for-navsidebar" + (collapsed ? "" : " active")}
                    onClick={handleToggleNavSidebar}
                >
                    <MoreOutlined />
                </span>
            ) : null}
            <BackTopButton
            // duration={window.innerHeight / 2}
            />
            <FeedbackButtonFloat
                tooltip={"Feedback"}
                onClick={() => {
                    dispatch(toggleFeedbackModalVisible(true));
                }}
            />
        </div>
    );
};

GeneralLayout.layout = "GeneralLayout";

export default GeneralLayout;
