import { useEffect, useState } from "react";
import { Spin } from "antd";
import { FileImageOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import Breadcrumb from "./Breadcrumb";
import DragAndDrop from "./DragAndDrop";
import Item from "./Item";
import { validateFileList } from "../utils/helpers";
import "./Content.scss";

const Content = ({
    controller,
    isMultiple = false,
    listFiles = () => {},
    selectFile = () => {},
    uploadFiles = () => {},
    removeFiles = () => {},
    renameFile = () => {},
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false); // Values: "loading" || false.

    const [parent, setParent] = useState(null);
    const [files, setFiles] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [settings, setSettings] = useValues({
        displayMode: false, // Values: "list", "thumbnails", false.
        displayFiles: { all: [], type_folder: [], type_other: [] },
    });

    useEffect(() => {
        controller.set("content.open_folder", (folder) => {
            controller.call("shared.set_open_folder", folder);
            setParent(folder);
            setFiles([]);
            setSelectedItems([]);
            const res = listFiles(folder?.id);
            if (res instanceof Promise) {
                setIsLoading("loading");
                res.then((files) => {
                    setIsLoading(false);
                    if (files instanceof Array) {
                        setFiles(files);
                    }
                }).catch(({ message }) => {
                    setIsLoading(false);
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        });

        return () => {
            controller.remove("content.open_folder");
        };
    }, []);

    useEffect(() => {
        controller.set("content.get_current_parent", () => {
            return parent;
        });

        return () => {
            controller.remove("content.get_current_parent");
        };
    }, [parent]);

    useEffect(() => {
        controller.set("content.get_current_files", () => {
            return files;
        });
        controller.set("content.add_files", (__files) => {
            if (__files instanceof Array) {
                const currentFolders = [...files].filter((f) => f.type === "folder");
                const currentFiles = [...files].filter((f) => f.type !== "folder");
                const newFolders = [...__files].filter((f) => f.type === "folder");
                const newFiles = [...__files].filter((f) => f.type !== "folder");
                setFiles([...newFolders, ...currentFolders, ...newFiles, ...currentFiles]);
            }
        });
        controller.set("content.update_file", (__file) => {
            if (__file) {
                const currentFiles = [...files];
                for (let i = 0; i < currentFiles.length; i++) {
                    if (__file._id === currentFiles[i].id) {
                        currentFiles[i] = { ...currentFiles[i], ...__file };
                    }
                }
                setFiles(currentFiles);
            }
        });

        return () => {
            controller.remove("content.get_current_files");
            controller.remove("content.add_files");
            controller.remove("content.update_file");
        };
    }, [files]);

    useEffect(() => {
        controller.set("content.get_current_selected_files", () => {
            return selectedItems;
        });
        controller.set("content.select_file", (files) => {
            if (!(files instanceof Array)) {
                files = [files];
            }
            if (files instanceof Array && files.length > 0) {
                if (isMultiple) {
                    const newSelectedItems = [...selectedItems];
                    files.forEach((file) => {
                        if (!newSelectedItems.includes(file)) {
                            newSelectedItems.push(file);
                        }
                    });
                    setSelectedItems(newSelectedItems);
                } else {
                    let file = files[0];
                    selectedItems.forEach((_file) => {
                        if (file.id != _file.id) {
                            controller.call(`content_item.${_file.id}.un_select_file`);
                        }
                    });
                    setSelectedItems([file]);
                }
            }
        });
        controller.set("content.un_select_file", (files) => {
            if (!(files instanceof Array)) {
                files = [files];
            }
            if (files instanceof Array && files.length > 0) {
                let ids = files.map((file) => file.id);
                const newSelectedItems = [...selectedItems].filter((file) => !ids.includes(file.id));
                setSelectedItems(newSelectedItems);
            }
        });
        controller.set("content.click_delete", () => {
            let files = [...selectedItems];
            const res = removeFiles(files);
            if (res instanceof Promise) {
                res.then(() => {
                    // 1. Remove selected files & folders from Content section:
                    controller.call("content.remove_files", files);
                    // 2. Remove selected folders from Sidebar section:
                    let folders = files.filter((f) => f.type == "folder");
                    controller.call(`sidebar_item.${parent.id || null}.remove_child_list`, folders);
                    // folders.forEach((folder) => {
                    //     let parent_id = folder.parent_id || null;
                    //     controller.call(`sidebar_item.${parent_id}.remove_child`, folder);
                    // });
                }).catch(({ message }) => {
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        });
        controller.set("content.remove_files", (removedFiles) => {
            controller.call("content.un_select_file", removedFiles);
            let ids = removedFiles.map((file) => file.id);
            let newFiles = [...files].filter((file) => !ids.includes(file.id));
            setFiles(newFiles);
        });

        if (selectedItems.length > 0) {
            controller.call("toolbar.add_action", ["delete", "moveto"]);
        } else {
            controller.call("toolbar.remove_action", ["delete", "moveto"]);
        }

        return () => {
            controller.remove("content.get_current_selected_files");
            controller.remove("content.select_file");
            controller.remove("content.un_select_file");
            controller.remove("content.click_delete");
            controller.remove("content.remove_files");
        };
    }, [selectedItems]);

    useEffect(() => {
        const sortedFiles = { all: [], type_folder: [], type_other: [] };
        let currType = "";
        for (let i = 0; i < files.length; i++) {
            currType = files[i].type === "folder" ? "folder" : "other";
            sortedFiles[`type_${currType}`].push(files[i]);
        }
        sortedFiles.all = [...sortedFiles.type_folder, ...sortedFiles.type_other];
        setSettings({ displayFiles: sortedFiles });
    }, [files]);

    const handleClickOut = () => {
        selectedItems.forEach((file) => {
            controller.call(`content_item.${file.id}.un_select_file`);
        });
        setSelectedItems([]);
    };

    let extraClassnames = "";
    if (settings.displayMode) {
        extraClassnames += ` display-as-${settings.displayMode}`;
    }

    return (
        <Spin
            spinning={isLoading !== false}
            tip={<>{t(`my_storage.filemanager_${isLoading}`)}</>}
            wrapperClassName="app-spin-nested-loading layout-full fm-files-spin"
        >
            <div className={`fm-files${extraClassnames}`}>
                <DragAndDrop
                    className="fm-file-items"
                    onClick={handleClickOut}
                    draggingTitle="Thả file để upload"
                    onDrop={(e) => {
                        if (e.dataTransfer.files?.length > 0) {
                            const chk = validateFileList(e.dataTransfer.files);
                            if (chk.status === false) {
                                controller.call("notification.show", chk.message, 5000, "error");
                                return;
                            }
                            const res = uploadFiles(
                                e.dataTransfer.files,
                                controller.call("content.get_current_parent")
                            );
                            if (res instanceof Promise) {
                                res.then((newFiles) => {
                                    controller.call("content.add_files", newFiles);
                                }).catch(({ message }) => {
                                    message && controller.call("notification.show", message, 5000, "error");
                                });
                            }
                        }
                    }}
                >
                    <div className="fm-grid-files">
                        {settings.displayFiles.all.map((file, fileIndex) => {
                            return (
                                <Item
                                    controller={controller}
                                    key={file.id || `file-${fileIndex}`}
                                    file={file}
                                    selectFile={selectFile}
                                    renameFile={renameFile}
                                />
                            );
                        })}
                    </div>
                </DragAndDrop>

                <div className="fm-footer">
                    <Breadcrumb controller={controller} />
                    <span className="fm-settings">
                        <span
                            className={`setting-btn${settings.displayMode === "list" ? " selected" : ""}`}
                            onClick={() => {
                                setSettings({ displayMode: "list" });
                            }}
                        >
                            <UnorderedListOutlined />
                        </span>
                        <span
                            className={`setting-btn${
                                !settings.displayMode || settings.displayMode === "thumbnails" ? " selected" : ""
                            }`}
                            onClick={() => {
                                setSettings({ displayMode: "thumbnails" });
                            }}
                        >
                            <FileImageOutlined />
                        </span>
                    </span>
                </div>
            </div>
        </Spin>
    );
};

export default Content;
