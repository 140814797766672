import React, { useEffect, useMemo, useState } from "react";
import { Alert, Col, Row } from "antd";
import { t } from "i18next";
import Answer from "./components/Answer";
import QuestionContentDetail from "../ContentDetail";
import "./DragDrop.scss";

function DragDrop(props) {
    const {
        question /** @param {String} question: question of the sentence */,
        correct_answer,
        /** @param {
        answer1:
        answer2:
    } */ answered,
    } = props;

    // const [inputAnswers, setInputAnswers] = useState([]);
    // const [inputAnswered, setInputAnswered] = useState([]); // Sau render từ prop xuống k cần dùng state

    const inputAnswers = useMemo(() => {
        const result = [];

        correct_answer?.answer1?.forEach((answer, index) => {
            const textOfAnswer = correct_answer.answer2[index];

            result.push({
                text: textOfAnswer,
                answer,
                value: index,
                charASCII: String.fromCharCode(65 + index),
            });
        });

        return result;
    }, [correct_answer?.answer1, correct_answer?.answer2]);

    const inputAnswered = useMemo(() => {
        const result = [];
        answered?.answer1.forEach((answer, index) => {
            const textOfAnswer = answered.answer2[index];
            const indexInAnswer = correct_answer.answer1.findIndex((item) => item === answer);
            const isCorrect = correct_answer.answer2[indexInAnswer] === textOfAnswer;

            result.push({
                text: textOfAnswer,
                answer,
                value: index,
                charASCII: String.fromCharCode(65 + index),
                isCorrect,
            });
        });

        return result;
    }, [answered]);

    return (
        <div className="q-result q-drag-drop">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                    }}
                />
            </div>

            <Row className="q-content-section" gutter={[30, 30]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <h3>{t("score.your_answers")}</h3>
                    <div className="qc_selected-answers">
                        {inputAnswered.map((item, itemIndex) => (
                            <Answer isAlert key={`ans-${itemIndex}`} answer={item} />
                        ))}
                    </div>
                    {!Boolean(inputAnswered.length) && (
                        <Alert
                            // message="Error Text"
                            description={t("message.answer_empty")}
                            type="error"
                            className="empty_question"
                        />
                    )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <h3>{t("score.correct_answers")}</h3>
                    <div className="qc_correct-answers">
                        {inputAnswers.map((item, itemIndex) => (
                            <Answer key={`ans-${itemIndex}`} answer={item} />
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default DragDrop;
