/**
 * RULES:
 *
 * 1. Prefix:
 * - "set_": Settings, set up, ...
 * - "msg_": Messages.
 *
 * 2. Comment each section:
 * - Fields.
 * - Messages.
 * - Actions.
 */

export const en_questions = {
    q: {
        // Fields:
        question: "Question",
        answer: "Answer",
        template: "Template",
        // Types:
        writing: "Writing",
        writing_short: "Writing (short sentences)",
        multiple_choice: "Multiple choice",
        multiple_choice_sentence_quiz: "Multiple choice (Multiple select)",
        multiple_choice_boolean: "Multiple choice (boolean)",
        multiple_choice_table: "Multiple choice (table)",
        fill_in_the_blank: "Fill in the blank (select)",
        fill_in_the_blank_text: "Fill in the blank (text)",
        fill_in_the_blank_drag_drop: "Fill in the blank (drag drop)",
        fill_in_the_blank_latex: "Fill in the blank (math)",
        sort: "Sort",
        drag_drop: "Drag drop",
        drag_drop_group: "Drag drop group",
        drag_drop_group_order_by: "Drag drop group (order by)",
        highlight: "Highlight",
        fill_in_the_blank_drag_drop_image: "Image labels and drag drop",
        fill_in_the_blank_image: "Image labels and dropdown",
        fill_in_the_blank_text_image: "Image labels and text",
        highlight_image: "Highlight image",
        highlight_square: "Shading",
        passage: "Passage",
        hot_spot: "Hotspot",
        speech_to_text: "Speech to text",
        matrix: "Matrix",
        convert_measurement: "Unit conversion",
        draw_graph: "Graphing",
        // Actions:
        make_question: "Make a question",
        write_answer_here: "Write answwer here",
        allow_multi_answers: "Allow multiple answers",
        answer_placeholder: "Please fill in answer",
        please_input_answer: "Please fill in answer",
        please_input_answers: "Please fill in answer",
        please_input_groups: "Please fill in group",
        please_select_language: "Please select language",
    },
    q_detail: {
        // Types:
        header_writing: "Writing",
        header_writing_short: "Writing (short sentences)",
        header_multiple_choice: "Multiple choice",
        header_multiple_choice_boolean: "Multiple choice (boolean)",
        header_multiple_choice_sentence_quiz: "Multiple choice (Multiple select)",
        header_multiple_choice_table: "Multiple choice (table)",
        header_fill_in_the_blank: "Fill in the blank (select)",
        header_fill_in_the_blank_text: "Fill in the blank (text)",
        header_fill_in_the_blank_drag_drop: "Fill in the blank (drag drop)",
        header_sort: "Sort",
        header_drag_drop: "Drag drop",
        header_drag_drop_group: "Drag drop group",
        header_drag_drop_group_order_by: "Drag drop group (order by)",
        header_highlight: "Highlight",
        header_fill_in_the_blank_drag_drop_image: "Image labels and drag drop",
        header_fill_in_the_blank_image: "Image labels and dropdown",
        header_fill_in_the_blank_text_image: "Image labels and text",
        header_highlight_image: "Highlight image",
        header_convert_measurement: "Unit conversion",
        header_highlight_square: "Shading",
        header_passage: "Passage",
        header_hot_spot: "Hotspot",
        header_speech_to_text: "Speech to text",
        header_matrix: "Matrix",
        header_draw_graph: "Graphing",
        header_number_line: "Number line",
        // Actions:
        show_answer: "Show answers",
        hide_answer: "Hide answers",
    },
    q_group: {
        writing: "Writing",
        multiple_choice: "Multiple choice",
        passage: "passage",
        drag_drop: "Drag drop",
        fill_in_the_blank: "Fill in the blank",
        highlight: "Highlight",
    },
    q_imglbls: {
        image_and_content_here: "Image with labels",
        // Fields:
        img_and_lbls: "Image and labels",
        // Actions:
        set_img_and_lbls: "Image and labels settings",
    },
    q_imglbls_dragdrop: {
        // Messages:
        msg_set_label_for_image: "To set up image's labels, please click/touch anywhere on the image",
        msg_set_answer_for_label: "To set up label's correct answer, drag and drop it into the label",
    },
    q_imglbls_dropdown: {
        // Messages:
        msg_set_label_for_image: "To set up image's labels, please click/touch anywhere on the image",
        msg_set_answer_for_label: "To set up label's correct answer, drag and drop it into the label",
    },
    q_multi_boolean: {
        true: "True",
        false: "False",
    },
    q_math_text_dropdown: {
        value: "Value",
        unit: "Unit",
    },
    q_highlight: {
        enter_passage: "Enter passage",
    },
    q_graph: {
        graph: "Graph",
        number_line: "Number line",
        point: "Point",
        segment: "Segment",
        line: "Line",
        polygon: "Polygon",
        ray: "Ray",
        vector: "Vector",
        circle: "Circle",
        ellipse: "Ellipse",
        parabola: "Parabola",
        parabola3: "Parabola",
        hyperbola: "Hyperbola",
        min_value: "Minimum value",
        max_value: "Maximum value",
        jump_value: "Step value",
        // Actions:
        update_graph_elem: "Update element",
        add_numberline_label: "Add number-line label",
    },
};
