import React, { useMemo } from "react";
import parse from "html-react-parser";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import LatexDangerously from "src/modules/components/LatexDangerously";
import { Image } from "antd";
import "./Answer.scss";

const Answer = (props) => {
    const { disabled, letter = "a", answer, handleChooseAnswer = () => {} } = props;

    const generateClassName = () => {
        if (answer?.chosen) {
            return " chosen";
        } else {
            return "";
        }
    };

    const answerText = useMemo(() => {
        if (answer?.text && typeof answer?.text === "string") {
            let htmlString = answer.text;

            // Convert to valid React elements:
            const elements = parse(htmlString, {
                replace: (domNode) => {
                    // 1. Replace all <img> by Image component from Antd:
                    if (domNode.name === "img") {
                        return (
                            <span className="multichoice-ans-imgviwer" onClick={(e) => e.stopPropagation()}>
                                <Image
                                    src={domNode.attribs.src}
                                    width={domNode.attribs.width ? `${domNode.attribs.width}px` : undefined}
                                    height={domNode.attribs.height ? `${domNode.attribs.height}px` : undefined}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </span>
                        );
                    }
                    // 2. Replace all <math-static> with LaTeX by LaTeX viewer:
                    else if (domNode.name === "math-static") {
                        return <HTMLDisplayer rootType="span" exprString={domNode.attribs["data-latex"]} />;
                    }
                },
            });

            return elements;
        }
        return null;
    }, [answer]);

    return (
        <div
            className={`answer-for-multiple-choice${generateClassName()} ${disabled ? "disabled" : ""}`}
            onClick={() => handleChooseAnswer()}
        >
            <div className="answer-wrapper">
                <div className="answer-content">
                    <span className="answer-letter">{letter}</span>
                    <div className="answer-text">
                        {answerText}
                        {/* <LatexDangerously innerHTML={answer?.text} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Answer;
