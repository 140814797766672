import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { fetchStudentListByAssignmentID } from "src/api/containers/record";
import useFetch from "src/hooks/useFetch";
import Table from "src/modules/components/Table";
import ViewScoreStudent from "src/modules/containers/ViewScoreStudent";
import "./ModalMarkAssignment.scss";
import moment from "moment";
import { ReactComponent as ContactIcon } from "src/assets/images/contact.svg";
import ModalPersonalLearningProgress from "../ModalPersonalLearningProgress";
import { useValues } from "src/hooks";

const ModalMarkAssignment = ({
    visible = false,
    onCancel = () => {},
    title = "",
    markingAssignmentId = "",
    setIsPersonalLearningProgress = () => {},
    setStudentInfo = () => {},
    className = "",
    extraFilterParams = {},
    ...rest
}) => {
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, ...extraFilterParams },
        fetchStudentListByAssignmentID
    );
    const cloneDataSource = [...dataSource];

    const [isQuestionResultModal, setIsQuestionResultModal] = useState(false);
    const [isMarkingScore, setIsMarkingScore] = useState(null);
    const [markingRecord, setMarkingRecord] = useState({});
    const [values, setValues] = useValues({
        isModalPersonalLearningProgress: false,
        studentInfo: {},
        viewProgressClass: {},
        viewProgressSubject: {},
    });

    const dateFormat = "DD/MM/YYYY HH:mm:ss";

    const columns = [
        {
            title: t("class.student_name"),
            dataIndex: "name",
            key: "name",
            width: 180,
            render: (text, record) => record?.user?.name,
        },
        {
            title: t("class.start_time"),
            dataIndex: "start_time",
            key: "start_time",
            width: 200,
            render: (text, record) => moment(record?.start_time).format(dateFormat),
        },
        {
            title: t("class.end_time"),
            dataIndex: "end_time",
            key: "end_time",
            width: 200,
            render: (text, record) => moment(record?.end_time).format(dateFormat),
        },
        {
            title: t("class.count_correct_sentences"),
            dataIndex: "count_correct_sentences",
            key: "count_correct_sentences",
            width: 150,
            render: (text, record) => `${record?.count_correct_sentences || 0}/${record?.count_sentences || 0}`,
        },
        {
            title: t("shared.score"),
            dataIndex: "score",
            key: "score",
            width: 150,
        },
        {
            title: t("assignment.count_submit"),
            dataIndex: "count_submit_exercise",
            key: "count_submit_exercise",
            width: 150,
            render: (text, record) => {
                return record?.count_submit_exercise || 0;
            },
        },
    ];

    const actions = [
        {
            tooltip: t("question_bank.personal_learning_progress"),
            icon: <ContactIcon />,
            onClick: (record) => {
                // setStudentInfo(record.user);
                // setIsPersonalLearningProgress(true);
                setValues({ isModalPersonalLearningProgress: true, studentInfo: record.user });
            },
        },
        {
            tooltip: t("shared.mark"),
            icon: <EditOutlined />,
            onClick: (record) => {
                setMarkingRecord(record);
                setIsQuestionResultModal(true);
                setIsMarkingScore(true);
            },
        },
        {
            tooltip: t("question_bank.view"),
            icon: <EyeOutlined />,
            onClick: (record) => {
                setMarkingRecord(record);
                setIsQuestionResultModal(true);
                setIsMarkingScore(false);
            },
        },
    ];

    const handlePagination = (page) => {
        fetch({ page: page }, true);
    };

    useEffect(() => {
        if (markingAssignmentId) {
            fetch({ filter: `assignment_id:${markingAssignmentId}` }, true);
        }
    }, [markingAssignmentId]);

    return (
        <>
            <Modal
                title={title}
                visible={visible}
                footer={null}
                onCancel={onCancel}
                width={"80%"}
                bodyStyle={{ padding: "0px" }}
                destroyOnClose
                {...rest}
                className={`add-exam-question-modal modal-mark-assignment-table${className ? " " + className : ""}`}
            >
                <Table
                    className="app-table"
                    dataSource={cloneDataSource}
                    columns={columns}
                    actions={actions}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: handlePagination,
                    }}
                    scroll={{ x: "auto" }}
                />
            </Modal>

            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={isQuestionResultModal}
                onCancel={() => setIsQuestionResultModal(false)}
                width={"80%"}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose
            >
                <ViewScoreStudent
                    markingRecord={{ ...markingRecord }}
                    isMarking={isMarkingScore}
                    closeModal={() => {
                        refetch();
                        setIsQuestionResultModal(false);
                    }}
                    isModalBox={true}
                />
            </Modal>

            <ModalPersonalLearningProgress
                title={t("report.personal_learning_progress")}
                visible={values.isModalPersonalLearningProgress}
                viewProgressClass={values.viewProgressClass}
                viewProgressSubject={values.viewProgressSubject}
                onCancel={() => setValues({ isModalPersonalLearningProgress: false })}
                viewProgressStudent={values.studentInfo}
            />
        </>
    );
};

export default ModalMarkAssignment;
