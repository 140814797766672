import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useRealtimeListener() {
    const user = useSelector((state) => state.auth.user);
    const [echo, setEcho] = useState("");

    useEffect(() => {
        window.Echo.channel("teacher." + user.id).listen("StudentJoinClassEvent", (data) => {
            setEcho(data);
        });
    }, []);

    return (
        <>
            <span>hello</span>
        </>
    );
}
export default useRealtimeListener;
