/**
 * RULES:
 *
 * 1. Prefix:
 * - "set_": Settings, set up, ...
 * - "msg_": Messages.
 *
 * 2. Comment each section:
 * - Fields.
 * - Messages.
 * - Actions.
 */

export const vi_questions = {
    q: {
        // Fields:
        question: "Câu hỏi",
        answer: "Đáp án",
        template: "Đề bài",
        // Types:
        writing: "Viết",
        writing_short: "Viết (câu ngắn)",
        multiple_choice: "Trắc nghiệm",
        multiple_choice_sentence_quiz: "Trắc nghiệm (Chọn nhiều)",
        multiple_choice_boolean: "Trắc nghiệm (đúng sai)",
        multiple_choice_table: "Trắc nghiệm (bảng)",
        fill_in_the_blank: "Điền vào chỗ trống (chọn)",
        fill_in_the_blank_text: "Điền vào chỗ trống (viết)",
        fill_in_the_blank_drag_drop: "Điền vào chỗ trống (kéo thả)",
        fill_in_the_blank_latex: "Điền vào chỗ trống (toán học)",
        sort: "Sắp xếp",
        drag_drop: "Kéo thả",
        drag_drop_group: "Kéo thả nhóm",
        drag_drop_group_order_by: "Kéo thả nhóm (theo thứ tự)",
        highlight: "Tô đậm",
        fill_in_the_blank_drag_drop_image: "Kéo thả vào hình ảnh",
        fill_in_the_blank_image: "Chọn đáp án đúng trong ảnh",
        fill_in_the_blank_text_image: "Điền vào chỗ trống trong ảnh",
        highlight_image: "Đánh dấu vào hình",
        highlight_square: "Tô màu ô vuông",
        passage: "Đoạn văn",
        hot_spot: "Hotspot",
        speech_to_text: "Chuyển giọng nói thành văn bản",
        convert_measurement: "Chuyển đổi đơn vị",
        phonetic_table: "Bảng phiên âm",
        draw_graph: "Đồ thị",
        // Actions:
        make_question: "Đặt câu hỏi",
        write_answer_here: "Điền đáp án ở đây",
        allow_multi_answers: "Cho phép chọn nhiều đáp án",
        answer_placeholder: "Nhập đáp án",
        please_input_answer: "Vui lòng điền đáp án",
        please_input_answers: "Vui lòng điền đáp án",
        please_input_groups: "Vui lòng điền tên nhóm",
        please_select_language: "Vui lòng chọn ngôn ngữ",
    },
    q_group: {
        writing: "Viết",
        multiple_choice: "Trắc nghiệm",
        passage: "Đoạn văn",
        drag_drop: "Kéo thả",
        fill_in_the_blank: "Điền vào chỗ trống",
        highlight: "Tô đâm",
    },
    q_detail: {
        // Types:
        header_writing: "Viết",
        header_writing_short: "Viết (câu ngắn)",
        header_multiple_choice: "Trắc nghiệm",
        header_multiple_choice_sentence_quiz: "Trắc nghiệm (Chọn nhiều)",
        header_multiple_choice_boolean: "Trắc nghiệm (đúng sai)",
        header_multiple_choice_table: "Trắc nghiệm (bảng)",
        header_fill_in_the_blank: "Điền vào chỗ trống (chọn)",
        header_fill_in_the_blank_text: "Điền vào chỗ trống (viết)",
        header_fill_in_the_blank_drag_drop: "Điền vào chỗ trống (kéo thả)",
        header_fill_in_the_blank_latex: "Điền vào chỗ trống (toán học)",
        header_sort: "Sắp xếp",
        header_drag_drop: "Kéo thả",
        header_drag_drop_group: "Kéo thả nhóm",
        header_drag_drop_group_order_by: "Kéo thả nhóm (theo thứ tự)",
        header_highlight: "Tô đậm",
        header_fill_in_the_blank_drag_drop_image: "Kéo thả vào hình ảnh",
        header_fill_in_the_blank_image: "Chọn đáp án đúng trong ảnh",
        header_fill_in_the_blank_text_image: "Điền vào chỗ trống trong ảnh",
        header_highlight_image: "Đánh dấu vào hình",
        header_convert_measurement: "Chuyển đổi đơn vị",
        header_highlight_square: "Tô màu ô vuông",
        header_hot_spot: "Hotspot",
        header_passage: "Đoạn văn",
        header_speech_to_text: "Chuyển giọng nói thành văn bản",
        header_phonetic_table: "Bảng phiên âm",
        header_draw_graph: "Vẽ đồ thị",
        header_number_line: "Trục số",
        // Actions:
        show_answer: "Hiện đáp án",
        hide_answer: "Ẩn đáp án",
    },
    q_imglbls: {
        image_and_content_here: "Ảnh với nhãn",
        // Fields:
        img_and_lbls: "Ảnh và nhãn",
        // Actions:
        set_img_and_lbls: "Thiết lập ảnh và nhãn",
    },
    q_imglbls_dragdrop: {
        // Messages:
        msg_set_label_for_image: "Để thiết lập nhãn (ô trống) trên ảnh, hãy click/nhấn vào vị trí bất kỳ trên ảnh",
        msg_set_answer_for_label: "Để thiết lập đáp án đúng cho nhãn, hãy kéo thả đáp án vào nhãn đó",
    },
    q_imglbls_dropdown: {
        // Messages:
        msg_set_label_for_image: "Để thiết lập nhãn (ô trống) trên ảnh, hãy click/nhấn vào vị trí bất kỳ trên ảnh",
        msg_set_answer_for_label: "Để thiết lập đáp án đúng cho nhãn, hãy kéo thả đáp án vào nhãn đó",
    },
    q_multi_boolean: {
        true: "Đúng",
        false: "Sai",
    },
    q_math_text_dropdown: {
        value: "Giá trị",
        unit: "Đơn vị",
    },
    q_highlight: {
        enter_passage: "Nhập vào đoạn văn",
    },
    q_graph: {
        graph: "Đồ thị",
        number_line: "Trục số",
        point: "Điểm",
        segment: "Đoạn thẳng",
        line: "Đường thẳng",
        polygon: "Đa giác",
        ray: "Tia",
        vector: "Vector",
        circle: "Đường tròn",
        ellipse: "Ellipse",
        parabola: "Parabola",
        parabola3: "Parabola",
        hyperbola: "Hyperbola",
        min_value: "Giá trị nhỏ nhất",
        max_value: "Giá trị lớn nhất",
        jump_value: "Bước nhảy",
        // Actions:
        update_graph_elem: "Cập nhật phần tử",
        add_numberline_label: "Thêm nhãn cho trục số",
    },
};
