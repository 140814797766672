import { Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useValues } from "src/hooks";
import "./Toolbars.scss";
import ColorPickerV2 from "src/modules/components/ColorPicker";

import { ReactComponent as PolygonImg } from "src/assets/images/drawing-tool/polygon.svg";
import { ReactComponent as RectangleImg } from "src/assets/images/drawing-tool/rectangle.svg";
import { ReactComponent as MouseImg } from "src/assets/images/drawing-tool/mouse.svg";
import { ReactComponent as CircleImg } from "src/assets/images/drawing-tool/circle.svg";
import { ReactComponent as CurveIcon } from "src/assets/images/drawing-tool/curvee.svg";

import { BgColorsOutlined, DeleteOutlined, HighlightOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const defaultSelectedColor = {
    hex: "transparent",
    rgb: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
    },
};
// convert rgba in string to Object JS.
const toRGBObj = (rgb) => {
    let temp = [];
    if (typeof rgb == "string") {
        temp = rgb.slice(5, -1).split(",");
    }
    return {
        r: +temp?.[0],
        g: +temp?.[1],
        b: +temp?.[2],
        a: +temp?.[3],
    };
};

const toolName = {
    BRUSH: "brush",
    LINE: "line",
    RECTANGLE: "rectangle",
    CIRCLE: "circle",
    TRIANGLE: "triangle",
    CURVE: "curve",
    ARROW: "arrow",
    PICK: "pick",
    QUAD: "quad", // tứ giác
    TEXT: "text",
};

const ZwibblerToolbars = ({ ctx, handleClearPaths }) => {
    const defaultColor = {
        // stroke: "rgba(236,155,59,1)",
        stroke: "rgba( 0, 178, 255,1)",
        line: "rgba( 0, 178, 255,1)",
        fill: "rgba( 0, 178, 255,0.3)",
    };

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        shapeStyle: {
            lineWidth: 3,
            strokeStyle: defaultColor.stroke,
            fillStyle: defaultColor.fill,
            dashes: "5,2",
        },
        linesStyle: {
            lineWidth: 3,
            strokeStyle: defaultColor.stroke,
            dashes: "5,2",
        },
        currentTool: toolName.PICK,
        isDeleteMode: false,
    });

    const isDeleteMode = useRef(false);

    const useTool = (type) => {
        if (ctx) {
            switch (type) {
                case toolName.CIRCLE:
                    ctx.useCircleTool({ ...values.shapeStyle });
                    break;
                case toolName.LINE:
                    ctx.useLineTool({ ...values.shapeStyle });
                    break;
                case toolName.RECTANGLE:
                    ctx.useRectangleTool({ ...values.shapeStyle });
                    break;
                case toolName.CURVE:
                    ctx.useCurveTool({ ...values.shapeStyle });
                    break;
                default:
                    ctx.usePickTool({ ...values.shapeStyle });
            }
        }
    };

    const handleClear = (e) => {
        e && e.preventDefault();
        if (ctx) {
            ctx.newDocument();
            if (handleClearPaths instanceof Function) {
                handleClearPaths("", []);
            }
        }
    };

    let crrTool;
    if (ctx) {
        crrTool = ctx.getCurrentTool();
    }

    useEffect(() => {
        if (ctx) {
            const cTool = ctx.getCurrentTool();
            setValues({ currentTool: cTool });
            //tắt delete mode
            if (cTool !== "pick" && values.isDeleteMode && isDeleteMode.current) {
                setValues({ isDeleteMode: false });
                isDeleteMode.current = false;
            }
        }
    }, [crrTool, ctx, isDeleteMode.current]);

    useEffect(() => {
        if (ctx) {
            // mỗi lần chạy vô đây là nó sẽ tạo ra 1 event listener "node-clicked" mới
            // => hạn chế thêm code khác vô đây
            ctx.on("node-clicked", (node) => {
                if (isDeleteMode.current) {
                    ctx.deleteNode(node);
                }
            });
            ctx.setColour(defaultColor.stroke, false);
            ctx.setProperties({
                dashes: "5,2",
            });
        }
    }, [ctx]);

    useEffect(() => {
        if (ctx) {
            ctx.setToolProperty("strokeStyle", values.linesStyle.strokeStyle);
            ctx.setToolProperty("fillStyle", values.shapeStyle.fillStyle);
        }
    }, [values.linesStyle.lineWidth, values.linesStyle.strokeStyle, values.shapeStyle.fillStyle]);

    return (
        <div className="zwibbler-toolbars">
            <div className="zwibbler-toolbar-wrapper">
                <div className="zwibbler-toolbar-buttons">
                    <Space style={{ display: "flex", flexWrap: "wrap" }}>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                setValues({ currentTool: toolName.PICK, isDeleteMode: false });
                                isDeleteMode.current = false;
                                useTool(toolName.PICK);
                            }}
                            className={values.currentTool === "pick" && !values.isDeleteMode ? "active" : ""}
                        >
                            <MouseImg />
                        </button>
                        <button
                            className={values.currentTool === "line" ? "active" : ""}
                            onClick={(e) => {
                                e && e.preventDefault();
                                setValues({ currentTool: toolName.LINE });
                                useTool(toolName.LINE);
                            }}
                        >
                            <PolygonImg />
                        </button>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                setValues({ currentTool: toolName.CURVE });
                                useTool(toolName.CURVE);
                            }}
                            className={values.currentTool === "curve" ? "active" : ""}
                        >
                            <CurveIcon />
                        </button>
                        <button
                            onC
                            onClick={(e) => {
                                e && e.preventDefault();

                                setValues({ currentTool: toolName.RECTANGLE });
                                useTool(toolName.RECTANGLE);
                            }}
                            className={values.currentTool === "rectangle" ? "active" : ""}
                        >
                            <RectangleImg />
                        </button>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                setValues({ currentTool: toolName.CIRCLE });
                                useTool(toolName.CIRCLE);
                            }}
                            className={values.currentTool === "circle" ? "active" : ""}
                        >
                            <CircleImg />
                        </button>

                        {/* //stroke color */}
                        <Space>
                            <span>
                                <HighlightOutlined />
                            </span>
                            <ColorPickerV2
                                onChangeColor={(value) => {
                                    // let rgba = `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${value?.rgb?.a})`;
                                    // console.log(rgba);
                                    if (ctx) {
                                        ctx.setColour(value.hex, false);
                                        ctx.setToolProperty("strokeStyle", value.hex);
                                        setValues({
                                            shapeStyle: {
                                                ...values.shapeStyle,
                                                strokeStyle: value.hex,
                                            },
                                        });
                                    }
                                }}
                                defaultSelectedColor={{ rgb: toRGBObj(values.linesStyle.strokeStyle) }}
                                // currColor={() => toRGBObj(values.linesStyle.strokeStyle)}
                                width={200}
                            />
                        </Space>

                        <Space>
                            {/* <span>Fill</span> */}
                            <span>
                                <BgColorsOutlined />
                            </span>
                            {/* Fill shape color */}
                            <ColorPickerV2
                                onChangeColor={(value) => {
                                    let rgba = `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${value?.rgb?.a})`;
                                    if (ctx) {
                                        ctx.setToolProperty("fillStyle", rgba);
                                        setValues({
                                            shapeStyle: {
                                                ...values.shapeStyle,
                                                fillStyle: rgba,
                                            },
                                        });
                                    }
                                }}
                                // currColor={() => toRGBObj(values.shapeStyle.fillStyle)}
                                width={200}
                                defaultSelectedColor={{ rgb: toRGBObj(values.shapeStyle.fillStyle) }}
                            />
                        </Space>
                    </Space>

                    <Space style={{ display: "flex", flexWrap: "wrap" }}>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                ctx && ctx.undo();
                            }}
                        >
                            <UndoOutlined />
                        </button>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                ctx && ctx.redo();
                            }}
                        >
                            <RedoOutlined />
                        </button>
                        <button
                            onClick={(e) => {
                                e && e.preventDefault();
                                setValues({ currentTool: toolName.PICK, isDeleteMode: true });
                                isDeleteMode.current = true;
                                useTool(toolName.PICK);
                            }}
                            className={values.currentTool === "pick" && values.isDeleteMode ? "active" : ""}
                        >
                            <DeleteOutlined />
                        </button>
                        <button onClick={handleClear}>Clear</button>
                    </Space>
                </div>
            </div>
        </div>
    );
};

export default ZwibblerToolbars;
