import { Radio, Space } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { checkIsObject } from "src/utils/helpers";
import QuestionContentDetail from "../components/ContentDetail";
import MyTable from "./components/MyTable";
import "./MultipleChoiceTable.scss";
import clsx from "clsx";

const MultipleChoiceTable = (props) => {
    const {
        isReadonly,
        id,
        question = "",
        answer1, // string[]
        answer2, // {key: number, value: string}[] | string[]
        defaultAnswer, // number[]
        type,
        onChange,
        exam_types,
    } = props;

    // Convert type {key: number, value: string}[] to string[]
    const newAnswer2 = useMemo(() => {
        if (checkIsObject(answer2?.[0])) {
            return answer2;
        } else {
            return answer2.map((aswr, i) => ({
                key: i,
                value: aswr,
            }));
        }
    }, [answer2]);

    const [match, setMatch] = useState(defaultAnswer || []);

    const handleChooseAnswer = (value) => {
        if (onChange && onChange instanceof Function) {
            onChange({
                answered: value,
            });
        }

        setMatch(value);
    };

    return (
        <div className={clsx("q-multiple-choice-table", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="view-multiple-choice">
                <MyTable
                    isReadonly={isReadonly}
                    correctAnswer1={answer1}
                    correctAnswer2={newAnswer2}
                    value={match}
                    onChange={handleChooseAnswer}
                />
            </div>
        </div>
    );
};

export default MultipleChoiceTable;
