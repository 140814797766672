import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Form, Input, Modal, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useValues } from "src/hooks";
import { fetchCategories } from "src/api/containers/category";
import { createClass, findClass, updateClass } from "src/api/containers/class";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import Icon from "src/modules/components/Icon";
import { fetchBranches } from "src/api/containers/branch";
import { fetchOrganizationUsers } from "src/api/containers/organization";
import CustomTooltip from "src/modules/components/Tooltip";

const ModalDetailClass = (props) => {
    const {
        classId = "",
        visible = true,
        onOk = () => {},
        onCancel = () => {},
        page,
        roles,
        organization,
        ...rest
    } = props;

    const user = useSelector((state) => state.auth.user);

    const [values, setValues] = useValues({
        loading: false,
        loadingTeachers: false,
        optionListCategory: [],
        optionListTeacher: [],
        optionListBranch: [],
        isEditingAndDisableClassType: false,
    });

    const { t } = useTranslation();
    const [form] = useForm();

    const handleCancelForm = (willFormBeCleared = false) => {
        if (!classId && willFormBeCleared === true) {
            form.resetFields();
        }
        // setValues({ isEditingAndDisableClassType: false });
        onCancel();
    };

    const handleAddClass = (newClassData) => {
        if (newClassData) {
            setValues({ loading: true });
            createClass(newClassData).then((res) => {
                setValues({ loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleUpdateClass = (id, data) => {
        if (id && data) {
            setValues({ loading: true });
            updateClass(id, data).then((res) => {
                setValues({ loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        }
    };

    const handleSubmitForm = () => {
        let formData = form.getFieldsValue();
        if (!formData.name || !formData.code || !formData.begin_date || !formData.end_date) {
            return;
        }
        if (formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }
        formData = {
            ...formData,
            begin_date: formData.begin_date.format("Y-M-D"),
            end_date: formData.end_date.format("Y-M-D"),
            organization_id: organization?.organization_id,
            class_in_organization: organization?.class_in_organization,
        };
        if (classId) {
            handleUpdateClass(classId, formData);
        } else {
            handleAddClass(formData);
        }
    };

    useEffect(() => {
        setValues({ loading: true });
        const arrApi = [];
        const arrApiPlaceholder = ["categories"];
        const extraFetchParams = { organization_id: organization?.organization_id };
        if (roles?.isAllowFetchTeacher) {
            arrApi.push(
                fetchOrganizationUsers({
                    noPagination: true,
                    role: "teacher",
                    is_employee_a_teacher: 1,
                    ...extraFetchParams,
                })
            );
            arrApiPlaceholder.push("users");
        }
        if (roles?.isAllowFetchBranch) {
            arrApi.push(fetchBranches({ noPagination: true, filter: "active:1", ...extraFetchParams }));
            arrApiPlaceholder.push("branches");
        }

        Promise.all([fetchCategories({ noPagination: true }), ...arrApi]).then((res) => {
            const result = {};
            if (res[0]?.status) {
                result.optionListCategory = res[0].data || [];
            }

            // lấy ra các gv khác trừ gv hiện tại ra
            if (res[1]?.status && arrApiPlaceholder[1] === "users") {
                let rslt = [];
                rslt = res[1]?.data?.length
                    ? res[1].data.filter((item) => {
                          return item.id !== user?.id;
                      })
                    : [];
                result.optionListTeacher = rslt;
            }

            //branchs
            const branchIndex = arrApiPlaceholder.findIndex((u) => u === "branches");
            if (branchIndex !== -1 && res[branchIndex]?.status) {
                result.optionListBranch = res[branchIndex].data || [];
            }

            if (Object.keys(result).length > 0) {
                setValues({
                    loading: false,
                    ...result,
                });
            } else {
                setValues({
                    loading: false,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (visible) {
            if (classId) {
                setValues({ loading: true });
                const param = {
                    organization_id: organization?.organization_id,
                    class_in_organization: organization?.class_in_organization,
                    relations: ["categories"],
                };
                if (roles?.isAllowFetchBranch) {
                    param.relations.push("branch");
                }
                findClass(classId, param).then((res) => {
                    if (res.status) {
                        form.setFieldsValue({
                            name: res.data.name || "",
                            code: res.data.code || "",
                            begin_date: res.data.begin_date ? moment(new Date(res.data.begin_date), "YYYY-MM-DD") : "",
                            end_date: res.data.end_date ? moment(new Date(res.data.end_date), "YYYY-MM-DD") : "",
                            teacher_ids: (res.data.teachers || []).filter((i) => i._id != user.id).map((t) => t._id),
                            category_ids: (res.data.categories || []).map((item) => item?.id),
                            branch_id: res.data?.branch?.id,
                            is_official: res.data?.is_official,
                        });
                        setValues({ loading: false });
                        if (res.data?.is_official || res.data?.is_official === false) {
                            setValues({ isEditingAndDisableClassType: true });
                        } else {
                            setValues({ isEditingAndDisableClassType: false });
                        }
                    } else {
                        form.resetFields();
                        setValues({ loading: false });
                        notification.error({
                            message: res.message || t("message.not_found"),
                        });
                    }
                });
            } else {
                form.resetFields();
                if (values.isEditingAndDisableClassType) {
                    setValues({ isEditingAndDisableClassType: false });
                }
            }
        }
    }, [classId]);

    return (
        <Modal
            visible={visible}
            onOk={handleSubmitForm}
            onCancel={handleCancelForm}
            centered
            title={classId ? t("class.update_class") : t("class.add_class")}
            closeIcon={<IconClose />}
            footer={null}
            width="auto"
            {...rest}
            className={`app-modal type-basic flexible-height${props.className ? " " + props.className : ""}`}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form}>
                        {roles?.isAllowFetchBranch && (
                            <Form.Item
                                label={t("class.branch")}
                                name="branch_id"
                                rules={[{ required: true, message: t("class.select_branch") }]}
                            >
                                <Select
                                    className="app-select"
                                    placeholder={t("class.select_branch")}
                                    optionFilterProp="children"
                                    showArrow
                                    showSearch
                                    allowClear
                                >
                                    {values.optionListBranch.map((item) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name || "(Unknown)"}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item
                            label={t("class.name")}
                            name="name"
                            rules={[{ required: true, message: t("class.input_class_name") }]}
                        >
                            <Input placeholder={t("class.input_class_name")} maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            label={t("class.code")}
                            name="code"
                            rules={[{ required: true, message: t("class.input_class_code") }]}
                        >
                            <Input placeholder={t("class.input_class_code")} maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            label={t("class.begin_date")}
                            name="begin_date"
                            rules={[{ required: true, message: t("class.input_begin_date") }]}
                        >
                            <DatePicker placeholder={t("class.input_begin_date")} />
                        </Form.Item>

                        <Form.Item
                            label={t("class.end_date")}
                            name="end_date"
                            rules={[{ required: true, message: t("class.input_end_date") }]}
                        >
                            <DatePicker placeholder={t("class.input_end_date")} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <span>{t("class.class_type")}</span>
                                    <CustomTooltip
                                        type="question"
                                        placement="right"
                                        title={t("class.intended_class_note")}
                                        style={{ order: "4", marginLeft: "4px" }}
                                    />
                                </>
                            }
                            name="is_official"
                            rules={[{ required: true, message: t("message.required") }]}
                        >
                            <Select
                                className="app-select"
                                placeholder={t("class.select_class")}
                                showArrow
                                allowClear
                                disabled={values.isEditingAndDisableClassType}
                                // defaultValue={true}
                            >
                                <Select.Option value={true}>{t("class.official_class")}</Select.Option>
                                <Select.Option value={false}>{t("class.intended_class")}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t("class.subject")}
                            name="category_ids"
                            // rules={[{ required: true, message: t("class.select_subjects") }]}
                        >
                            <Select
                                className="app-select"
                                mode="multiple"
                                placeholder={t("class.select_subjects")}
                                optionFilterProp="children"
                                showArrow
                                showSearch
                                allowClear
                            >
                                {values.optionListCategory.map((opt, i) => {
                                    return (
                                        <Select.Option key={`subject${i}`} value={opt.id}>
                                            {opt.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {roles?.isAllowFetchTeacher && (
                            <Form.Item label={t("class.teacher")} name="teacher_ids">
                                <Select
                                    className="app-select"
                                    mode="multiple"
                                    placeholder={t("class.input_teacher_name")}
                                    optionFilterProp="children"
                                    showArrow
                                    showSearch
                                    allowClear
                                    // onSearch={(keyword) => {
                                    //     handleSearchTeachers(keyword);
                                    // }}
                                    // notFoundContent={
                                    //     values.loadingTeachers === true ? (
                                    //         <i>
                                    //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                    //         </i>
                                    //     ) : undefined
                                    // }
                                >
                                    {values.optionListTeacher.map((opt, i) => {
                                        return (
                                            <Select.Option key={`teacher${i}`} value={opt.id}>
                                                {opt.name || "(Unknown)"}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-delete" />}
                                title={t("shared.cancel")}
                                onClick={handleCancelForm}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save")}
                                onClick={handleSubmitForm}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailClass;
