import { t } from "i18next";

tinymce.PluginManager.add("input", function (editor, url) {
    let inputBlankButton = null;

    const getInputBlankButton = () => {
        const buttons = editor.editorContainer.querySelectorAll(".tox-toolbar__group .tox-tbtn") || [];
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].getAttribute("aria-label") === t("editor.create_blank")) {
                return buttons[i];
            }
        }
        return null;
    };

    const reloadInputList = function () {
        // List of inputs:
        let items = editor.contentDocument.querySelectorAll("fillbox");
        items.forEach(function (item, index) {
            // Set data-index of each element to specify ordinal number:
            item.setAttribute("data-index", index + 1);
        });
    };

    editor.ui.registry.addIcon(
        "create-blank",
        `
            <svg width="24px" height="24px" viewBox="-2 -4 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-rectangle">
                <path d='M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z'/>
            </svg>
        `
    );

    editor.ui.registry.addButton("input", {
        text: t("editor.create_blank"),
        icon: "create-blank",
        tooltip: t("editor.create_blank"),
        onSetup: function (api) {
            inputBlankButton = getInputBlankButton();
            if (inputBlankButton) {
                inputBlankButton.id = "custom-tox-tbtn--input-blank";
            }
        },
        onAction: function () {
            // 1. Generate id for each input:
            let items = editor.contentDocument.querySelectorAll("fillbox");
            let id = 0;
            if (items.length === 0) {
                id = 1;
            } else {
                items.forEach(function (item, index) {
                    const itemNumber = parseInt(item.getAttribute("id") || 0);
                    if (id < itemNumber) {
                        id = itemNumber;
                    }
                });
                id = id + 1;
            }
            // 2. Create input:
            editor.insertContent(`&nbsp;<fillbox contenteditable="false" data-index="1" id="${id}" />&nbsp;`);
            // 3. Re-specify the order:
            setTimeout(reloadInputList);
        },
    });

    editor.on("Change", function () {
        setTimeout(reloadInputList);
    });

    // editor.on("KeyDown", function (e) {
    //     e.key === "Backspace" && setTimeout(reloadInputList);
    // });

    // editor.on("Cut", function () {
    //     setTimeout(reloadInputList);
    // });

    // editor.on("Paste", function () {
    //     setTimeout(reloadInputList);
    // });

    return {
        getMetadata: function () {
            return {
                name: "Input",
                url: "",
            };
        },
    };
});
