import { Breadcrumb, Select } from "antd";
import { useEffect, useState } from "react";
import { t } from "i18next";
import "./StudentFrame.scss";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";

const StudentFrame = (props) => {
    const {
        title = "",
        isSelectBoxMobile = false,
        children,
        handleChooseQuestion = () => {},
        questionAmount = "-",
        order = 1,
        assignmentName = "Unknow exam",
        isShowBreadcrumb = false,
    } = props;
    const numbers = Array(questionAmount).fill(0);
    const navigate = useNavigate();
    const { generate } = useRouting();

    const onChange = (value) => {
        handleChooseQuestion(value);
    };

    return (
        <div className="studentFrame_wrapper">
            <div className="studentFrame_inner">
                {isShowBreadcrumb && (
                    <div className="studentFrame_nav">
                        <Breadcrumb>
                            <Breadcrumb.Item
                                className="studentFrame_navItem"
                                onClick={() => {
                                    navigate(generate("home"));
                                }}
                            >
                                <HomeOutlined />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{assignmentName}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                )}
                <div className="studentFrame_header">
                    <div className="studentFrame_title">{title}</div>
                    {isSelectBoxMobile && (
                        <div className="studentFrame_select">
                            <Select
                                showSearch
                                value={`${t("q.question")} ${order}/${questionAmount}`}
                                optionFilterProp="children"
                                onChange={onChange}
                            >
                                {numbers.map((_, index) => {
                                    return (
                                        <Select.Option key={index} value={index + 1}>
                                            {index + 1}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                </div>
                <div className="studentFrame_container">{children}</div>
            </div>
        </div>
    );
};

export default StudentFrame;
