import React from "react";
import { t } from "i18next";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import { default as CustomButton } from "src/modules/components/Button";
import { getStorageValueWithUnitFromMB } from "src/utils/helpers/unit";
import "./DiskUsageStat.scss";

function DiskUsageStat({
    className = "",
    total = 0,
    data = [],
    dataColors = {},
    dataLabels = {},
    dataNotes = {},
    statisticConfig = {
        content: "",
        contentType: "total", // Values: "total", "left-per-total".
    },
    plotContainer = {
        appendPadding: [0, 0, 30, 0],
    },
    ...rest
}) {
    // Sample data:
    // const propsColor = {
    //     space_used: "#445cfe",
    //     space_left: "rgba(0, 0, 0, 0.2)",
    // };
    // const propsLabel = {
    //     space_used: t("my_storage.space_used"),
    //     space_left: t("my_storage.space_left"),
    // };
    // const propsData = [
    //     {
    //         name: "space_used",
    //         value: 19,
    //     },
    //     {
    //         name: "space_left",
    //         value: 36,
    //     },
    // ];

    const generateDefaultStatisticTitle = () => {
        if (!statisticConfig.contentType || statisticConfig.contentType === "total") {
            return t("my_storage.total");
        } else if (statisticConfig.contentType === "left-per-total") {
            return t("my_storage.left");
        } else {
            return "_";
        }
    };

    const generateDefaultStatisticValue = () => {
        if (!statisticConfig.contentType || statisticConfig.contentType === "total") {
            return total
                ? getStorageValueWithUnitFromMB(total, false)
                : getStorageValueWithUnitFromMB(
                      data.reduce((r, d) => r + d.value, 0),
                      false
                  );
        } else if (statisticConfig.contentType === "left-per-total") {
            let l = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].name === "space_left") {
                    l = getStorageValueWithUnitFromMB(data[i].value, false);
                    break;
                }
            }
            const t = total
                ? getStorageValueWithUnitFromMB(total, false)
                : getStorageValueWithUnitFromMB(
                      data.reduce((r, d) => r + d.value, 0),
                      false
                  );
            return `${l} / ${t}`;
        } else {
            return "_";
        }
    };

    // Chart data:
    const propsColor = dataColors;
    const propsLabel = dataLabels;
    const propsData = data;

    // Chart config:
    const config = {
        // Plot Container:
        appendPadding: plotContainer.appendPadding || 0,
        width: 250,
        height: 350,
        autoFit: false,

        // Data Mapping:
        data: propsData,
        angleField: "value",
        colorField: "name",

        // Geometry Style:
        radius: 1,
        innerRadius: 0.8,
        color: (params) => {
            if (params.name) {
                return propsColor[params.name] || "";
            }
        },
        pieStyle: {
            // stroke: "#000",
            strokeOpacity: 0,
            cursor: "pointer",
        },

        // Plot Components:
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            layout: "overlap",
            position: "middle",
            labelLine: null,
            labelEmit: true,
            autoHide: true,
            autoRotate: false,
            style: {
                fill: "#000",
                textAlign: "center",
                fontSize: 14,
                fontWeight: "400",
                opacity: 0,
            },
        },
        legend: {
            layout: "vertical",
            position: "bottom-right",
            flipPage: false,
            itemWidth: 200,
            maxItemWidth: 200,
            marker: (params) => {
                return {
                    symbol: "circle",
                    style: {
                        lineWidth: 1,
                        r: 8,
                    },
                    spacing: 8,
                };
            },
            itemName: {
                formatter: (text, item, index) => {
                    return propsLabel[text] || text;
                },
                style: {
                    fill: "#000",
                    fontSize: 16,
                    fontWeight: "400",
                    lineHeight: 25,
                },
            },
            itemValue: {
                alignRight: true,
                formatter: (text, item, index) => {
                    return propsData[index].value !== undefined
                        ? getStorageValueWithUnitFromMB(propsData[index].value, false)
                        : "";
                },
                style: {
                    fill: "#000",
                    fontSize: 16,
                    fontWeight: "700",
                    lineHeight: 25,
                },
            },
        },
        statistic: {
            title: {
                offsetY: -4,
                // content: t("my_storage.total")
                customHtml: (container, view, datum) => {
                    const text = datum ? t(`my_storage.${datum.name}`) : generateDefaultStatisticTitle();
                    return `
                        <span style="line-height: 30px;">${text}</span>
                    `;
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                // content: statisticConfig.content || "",
                customHtml: (container, view, datum, data) => {
                    const text = datum
                        ? getStorageValueWithUnitFromMB(datum.value, false)
                        : generateDefaultStatisticValue();
                    return text;
                },
            },
        },

        // Plot Interactions:
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
            {
                type: "pie-statistic-active",
            },
        ],
    };

    return (
        <div className="disk-usage-statistics">
            <div className={"disk-usage-chart-wrapper donut-plot-with-statistics"}>
                <div className="chart-legend-notes">
                    {propsData.map((item, itemIndex) => {
                        const itemNoteContent = dataNotes[item?.name];
                        if (itemNoteContent) {
                            return (
                                <Tooltip key={`note${itemIndex}`} placement="bottomLeft" title={itemNoteContent}>
                                    <CustomButton
                                        type="simple"
                                        icon={<QuestionCircleFilled />}
                                        className="btn-note"
                                    ></CustomButton>
                                </Tooltip>
                            );
                        } else {
                            return <div key={`note${itemIndex}`} className="btn-note"></div>;
                        }
                    })}
                </div>
                <Pie {...config} />
            </div>
        </div>
    );
}

export default React.memo(DiskUsageStat);
