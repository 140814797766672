import api from "..";

export const reactQuestion = (data) => {
    const { question_id, organization_id } = data;

    if (!question_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing question id!" });
        });
    }

    const _data = {};
    organization_id && (_data.organization_id = organization_id);

    return api
        .put(`/question/${question_id}/react`, _data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const reactExamQuestion = (data) => {
    const { exam_question_id, organization_id } = data;

    if (!exam_question_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing exam question id!" });
        });
    }

    const _data = {};
    organization_id && (_data.organization_id = organization_id);

    return api
        .put(`/exam_question/${exam_question_id}/react`, _data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};
