import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Dropdown, Modal, notification, Radio, Spin, Tabs, Tooltip } from "antd";
import {
    CaretDownOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
    LogoutOutlined,
    PlusCircleOutlined,
    QuestionOutlined,
    ShareAltOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import { useFetch, useValues } from "src/hooks";
import { fetchAssignments } from "src/api/containers/assignment";
import { deleteClassUser, findClass } from "src/api/containers/class";
import { fetchUsers } from "src/api/containers/user";
import Table from "src/modules/components/Table";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "../../components/Header";
import Icon from "src/modules/components/Icon";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
// Class management tab:
import ModalAddUser from "../../components/ModalAddUser";
import ModalAddTeacher from "../../components/ModalAddTeacher";
import ImportUsers from "../../components/ImportUsers";
// Exam management tab:
import ModalMarkAssignment from "../../components/ModalMarkAssignment";
import ModalGeneralLearningProgress from "../../components/ModalGeneralLearningProgress";
import "./DetailClass.scss";
import { ReactComponent as Bin } from "src/assets/images/bin.svg";
import Contact from "src/assets/images/contact.svg";
import Monitor from "src/assets/images/monitor.svg";
import ModalPersonalLearningProgress from "../../components/ModalPersonalLearningProgress";
import { setCurrentPage, toggleHelpModalVisible } from "src/reducers/general";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { permissionUser } from "src/utils/permission";
import Can from "src/modules/components/Can";
import { useRouting } from "src/utils/router";
import ModalDetailExam from "src/modules/components/ModalDetailExam";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { deleteCompetition, listCompetitions, updateCompetition } from "src/api/containers/Competition";
import ModalDetailCompetition from "src/modules/containers/Competitions/components/ModalDetailCompetition";

const DetailClass = ({ fetchParamsGetDetail }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();

    // User and scope:
    const { user, accountType } = useSelector((state) => state.auth);
    // Date format:
    // const { formatDateTime } = useSelector((state) => state.general);
    const formatDateTime = "DD/MM/YYYY HH:mm";

    // API CALL:
    // - Fetch class members:
    const [loadingMems, dataSourceMems, paginationMems, fetchMems, refetchMems] = useFetch(
        { page: 1, class_id: params.id, class_user_status: 1 },
        fetchUsers
    );
    // - Fetch class assignments:
    const [loadingAsgmts, dataSourceAsgmts, paginationAsgmts, fetchAsgmts, refetchAsgmts] = useFetch(
        { page: 1, class_id: params.id },
        fetchAssignments
    );

    // - Fetch class competitions:
    const [
        loadingCompetition,
        dataCompetitions,
        paginationCompetitions,
        fetchCompetitions,
        refetchCompetitions,
    ] = useFetch({ page: 1, class_id: params.id }, listCompetitions);

    // Others:
    const tabs = {
        class: "1",
        exam: "2",
        competition: "3",
    };
    const [values, setValues] = useValues({
        // Class info:
        classData: {},
        classFetchedSuccess: false,
        classMemsFetched: false,
        classAsgmtsFetched: false,
        // Page tabs:
        currentTab: tabs.class,
        // Others:
        isEdited: false,
        isConfirmModal: false,
        pendingDeleteItem: null,
        loading: false,
        markingAssignmentId: "",
        studentNumber: undefined,
        viewProgressAssignment: {},
        viewProgressClass: {},
        viewProgressSubject: {},
        isOnMobile: false,
        isShowForm: false,
        examType: "exam",
        // Filtering:
        filterListUser: {},
        filterListAsgmts: {},
        selectedKeys: "",
        editingCompetition: {},
        isCompetitionModal: false,
        selectedCompetition: {},
        confirmModalFor: "",
    });
    const [modal, setModal] = useState({
        name: null,
        data: null,
    });
    const [isAssignExamModal, setIsAssignExamModal] = useState(false);
    const [isPersonalLearningProgress, setIsPersonalLearningProgress] = useState(false);
    const [isGeneralLearningProgress, setIsGeneralLearningProgress] = useState(false);
    const [studentInfo, setStudentInfo] = useState({});

    // Permission:
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;
    const _p_deleteUser = useMemo(() => {
        return checkPermission(pagesAllow.class, rolesAllow.removeUser, user);
    }, [user]);

    // User:
    const isTeacherOfClass = useMemo(() => {
        if (values.classData?.owner?._id === user?.id) {
            return true;
        }
        if (user && values.classData && Array.isArray(values.classData.teachers)) {
            if (values.classData.teachers.find((item) => item._id == user?.id)) {
                return true;
            }
        }
        return false;
    }, [user, values.classData]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const newRow = selectedRows?.[0];
            newRow.is_official = true;
            if (selectedRowKeys) {
                setValues({ loading: true });
                updateCompetition(selectedRowKeys?.[0], { is_official: true })
                    .then(({ status, message }) => {
                        if (status) {
                            notification.success({ message: t("message.update_success") });
                            refetchCompetitions();
                        } else {
                            notification.error({ message });
                        }
                    })
                    .catch(({ message }) => {
                        notification.error({ message });
                    })
                    .finally(() => {
                        setValues({ loading: false });
                    });
            }
        },
        // renderCell: (data) => console.log(data),
        columnTitle: "Cuộc thi chính thức",
        selectedRowKeys: values.selectedKeys,
    };

    const studentColumns = [
        {
            title: t("profile.full_name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) =>
                values.isEdited ? (
                    <span>
                        <span className="icon" onClick={() => handleShowConfirmModal(record)}>
                            <Bin />
                        </span>
                        <span>{record.name}</span>
                    </span>
                ) : (
                    <span>{record.name}</span>
                ),
        },
        {
            title: t("class.phone_number"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("class.email"),
            dataIndex: "username",
            key: "username",
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="gender"
                            cateName="class"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "female", value: 0 },
                                { name: "male", value: 1 },
                                { name: "unknown", value: null },
                            ]}
                            selectedFilterKey={values.filterListUser.gender}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterListUser.gender) {
                                    setValues({
                                        filterListUser: { ...values.filterListUser, gender: undefined },
                                    });
                                } else {
                                    setValues({ filterListUser: { ...values.filterListUser, gender: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="gender-wrapper">
                        <span>{t("class.gender")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "gender",
            key: "gender",
            render: (text, record) =>
                record.gender === 0
                    ? t("class.female")
                    : record.gender === 1
                    ? t("class.male")
                    : record.gender === null
                    ? t("class.unknown")
                    : "",
        },
        {
            title: t("profile.role"),
            dataIndex: "role",
            key: "role",
            width: "auto",
            render: (text, record) => (record?.role ? t(`profile.role_${record?.role}`) : "_"),
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="status"
                            cateName="shared"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "active", value: true },
                                { name: "inactive", value: false },
                                { name: "is_removed", value: "is_rm" },
                            ]}
                            selectedFilterKey={values.filterListUser.active}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterListUser.active) {
                                    setValues({
                                        filterListUser: { ...values.filterListUser, active: undefined },
                                    });
                                } else {
                                    setValues({ filterListUser: { ...values.filterListUser, active: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="status-wrapper">
                        <span>{t("class.status")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "class_user_status",
            key: "class_user_status",
            render: (text, record) => (record.active === true ? t("shared.active") : t("shared.inactive")),
        },
        {
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => {
                // The user can remove hisself/herself from the class:
                // - If user is the owner, disable this action.
                // - If user is not the owner, this action is enabled and its name is "Leave class".
                if (record?.id === user.id) {
                    if (user?.id === values.classData?.owner?._id) {
                        return null;
                    }
                    return (
                        <Tooltip placement="top" title={t("class.leave_class")}>
                            <CustomButton
                                className="class-delbtn"
                                type="simple"
                                icon={<LogoutOutlined />}
                                onClick={() => handleShowConfirmModal(record)}
                                disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                            />
                        </Tooltip>
                    );
                }
                // The user can remove others from the class, if he/she:
                // - Is the owner of the class.
                // - Has required permissions.
                if (user?.id === values.classData?.owner?._id || _p_deleteUser) {
                    return (
                        <Tooltip placement="top" title={t(`class.remove_${record?.role}`)}>
                            <CustomButton
                                className="class-delbtn"
                                type="simple"
                                icon={<DeleteOutlined />}
                                onClick={() => handleShowConfirmModal(record)}
                                disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                            />
                        </Tooltip>
                    );
                }
                return null;
            },
        },
    ];

    const examColumns = [
        {
            title: t("class.exam_name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="is_test"
                            cateName="assignment"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "exam", value: 1 },
                                { name: "assignment", value: 0 },
                            ]}
                            selectedFilterKey={values.filterListAsgmts.is_test}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterListAsgmts.is_test) {
                                    setValues({
                                        filterListAsgmts: { ...values.filterListAsgmts, is_test: undefined },
                                    });
                                } else {
                                    setValues({ filterListAsgmts: { ...values.filterListAsgmts, is_test: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="owner-ship-wrapper">
                        <span>{t("shared.type")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "is_test",
            key: "is_test",
            render: (text, record) =>
                [null, true].includes(record?.is_test)
                    ? t("assignment.exam")
                    : record?.is_test == false
                    ? t("assignment.assignment")
                    : t("shared.unknown"),
        },
        {
            title: (filters, sortOrder) => {
                if (values.classData?.categories?.length > 0) {
                    const filterKeyList = [];
                    for (let i = 0; i < values.classData.categories.length; i++) {
                        filterKeyList.push({
                            name: values.classData.categories[i].name,
                            value: values.classData.categories[i].id,
                        });
                    }
                    return (
                        <Dropdown
                            overlay={
                                <TableColumnFilterMenu
                                    columnName="subject"
                                    cateName="class"
                                    filterKeys={[{ name: "all", value: undefined }, ...filterKeyList]}
                                    cateNameNotFrom={1}
                                    selectedFilterKey={values.filterListAsgmts.category_id}
                                    onChangeFilterKey={(val) => {
                                        if (val === values.filterListAsgmts.category_id) {
                                            setValues({
                                                filterListAsgmts: {
                                                    ...values.filterListAsgmts,
                                                    category_id: undefined,
                                                },
                                            });
                                        } else {
                                            setValues({
                                                filterListAsgmts: { ...values.filterListAsgmts, category_id: val },
                                            });
                                        }
                                    }}
                                />
                            }
                            placement="bottom"
                        >
                            <div className="subject-wrapper">
                                <span>{t("class.subject")}</span> <CaretDownOutlined />
                            </div>
                        </Dropdown>
                    );
                } else {
                    return (
                        <div className="subject-wrapper">
                            <span>{t("class.subject")}</span>
                        </div>
                    );
                }
            },
            dataIndex: "category",
            key: "category",
            render: (text, record) => (record.category?.name ? record.category.name : "_"),
        },
        {
            title: t("class.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            render: (text, record) => moment(record.begin_date).format(formatDateTime),
        },
        {
            title: t("class.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            render: (text, record) => moment(record.end_date).format(formatDateTime),
        },
        {
            title: t("class.max_score"),
            dataIndex: "max_score",
            key: "max_score",
            render: (text, record) => record?.exam?.max_score || 0,
        },
        {
            title: t("class.submission"),
            dataIndex: "count_submission",
            key: "count_submission",
            render: (text, record) => record?.count_record?.count_submit || 0,
        },
        {
            title: t("class.graded"),
            dataIndex: "count_marked",
            key: "count_marked",
            render: (text, record) => record?.count_record?.count_marked || 0,
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="ownership"
                            cateName="class"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "me", value: user.id },
                            ]}
                            selectedFilterKey={values.filterListAsgmts.teacher_id}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterListAsgmts.teacher_id) {
                                    setValues({
                                        filterListAsgmts: { ...values.filterListAsgmts, teacher_id: undefined },
                                    });
                                } else {
                                    setValues({ filterListAsgmts: { ...values.filterListAsgmts, teacher_id: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="owner-ship-wrapper">
                        <span>{t("class.ownership")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "ownership",
            key: "ownership",
            render: (text, record) => record?.exam?.user_name || t("shared.unknown"),
        },
    ];

    const examActions = [
        {
            title: t("class.mark_assignment"),
            onClick: (record) => {
                setValues({
                    markingAssignmentId: record.id,
                    viewProgressClass: {
                        id: record.class.id,
                        name: record.class.name,
                    },
                });
                setModal({
                    name: "markingModal",
                    data: null,
                });
                setValues({
                    viewProgressAssignment: {
                        id: record.id,
                        name: record.name,
                    },
                    viewProgressClass: {
                        id: record.class?.id,
                        name: record.class?.name,
                    },
                    ...(record?.category
                        ? {
                              viewProgressSubject: {
                                  id: record?.category?.id,
                                  name: record?.category?.name,
                              },
                          }
                        : {}),
                });
            },
            disabled: checkDemoAccountAction(accountType, "class", "mark_assignment") === false,
        },
        {
            tooltip: t("class.learning_progress"),
            icon: Contact,
            onClick: (record) => {
                setValues({
                    viewProgressAssignment: {
                        id: record?.id,
                        name: record?.name,
                    },
                    viewProgressClass: {
                        id: record.class?.id,
                        name: record.class?.name,
                    },
                    ...(record?.category
                        ? {
                              viewProgressSubject: {
                                  id: record?.category?.id,
                                  name: record?.category?.name,
                              },
                          }
                        : {}),
                });
                setIsGeneralLearningProgress(true);
            },
        },
        {
            tooltip: t("class.report"),
            icon: Monitor,
            onClick: (record) => navigate(`assignment/${record?.id}`),
        },
        {
            tooltip: t("assignment.invite_via_link"),
            icon: <ShareAltOutlined className="class-detail-action-icon" />,
            onClick: (record) => {
                setModal({
                    name: "inviteAssignmentLink",
                    data: { assignmentId: `${location.origin}/assignment/` + record.id },
                });
            },
        },
    ];

    const competitionColumns = [
        {
            title: t("ranking.competition_name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => record?.name,
        },

        {
            title: t("class.subject"),
            dataIndex: "subject",
            key: "subject",
            width: "auto",
            render: (text, record) => record?.category?.name,
        },

        {
            title: t("class.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.begin_date).format(formatDateTime)
                    : t("class.time_not_limited");
            },
        },
        {
            title: t("class.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date ? moment(record.end_date).format(formatDateTime) : t("class.time_not_limited");
            },
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="table-action question-bank-action" style={{ justifyContent: "center" }}>
                    <Tooltip placement="top" title={t("shared.view_detail")}>
                        <span>
                            <CustomButton
                                className="list-btn"
                                type="simple"
                                onClick={() => {
                                    const a = generate("rounds", { competition_id: record?.id });
                                    navigate(`${a}?competition_id=${record?.id}`);
                                }}
                                disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                                icon={<UnorderedListOutlined />}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title={t("ranking.update_competition")}>
                        <span>
                            <CustomButton
                                className="list-btn"
                                type="simple"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setValues({
                                        isCompetitionModal: true,
                                        editingCompetition: record,
                                    });
                                }}
                                disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                            />
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.delete")}>
                        <span>
                            <CustomButton
                                className="list-btn btn-danger"
                                type="simple"
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                    setValues({
                                        isConfirmModal: true,
                                        selectedCompetition: record,
                                        confirmModalFor: "competition",
                                    })
                                }
                                disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                            />
                        </span>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleDeleteCompetition = () => {
        const item = values.selectedCompetition;
        deleteCompetition(item.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isConfirmModal: false, selectedCompetition: {}, confirmModalFor: "competition" });
                refetchCompetitions();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const hideModal = () => {
        setModal({
            name: null,
            data: null,
        });
    };

    const handleShowConfirmModal = (record) => {
        if (record?.id) {
            setValues({
                isConfirmModal: true,
                pendingDeleteItem: { id: record.id, name: record.name, role: record.role },
            });
        }
    };

    const handelChangeTab = (tabKey) => {
        setValues({ currentTab: tabKey });
    };

    const handleDeleteUser = () => {
        setValues({ loading: true, isConfirmModal: false });
        deleteClassUser(
            values.classData.id,
            values.pendingDeleteItem.id,
            getItemScopeSelected(values.classData).scopeId
        ).then(({ status, message }) => {
            setValues({
                loading: false,
                pendingDeleteItem: null,
            });
            if (status) {
                if (user.id === values.pendingDeleteItem?.id) {
                    navigate(generate("class"));
                } else {
                    refetchMems();
                }
                notification.success({
                    message: message || t("message.delete_success"),
                });
            } else {
                notification.error({
                    message: message || t("message.delete_error"),
                });
            }
        });
    };

    const handleCloseModalGeneralLearningProgress = useCallback(() => {
        setIsGeneralLearningProgress(false);
    }, []);

    const handleCloseModalPersonalLearningProgress = useCallback(() => {
        setIsPersonalLearningProgress(false);
        setStudentInfo({});
    }, []);

    function handleClickHelp() {
        dispatch(toggleHelpModalVisible(true));
    }

    useEffect(() => {
        dispatch(setCurrentPage("class_detail"));
        setValues({ loading: true });
        findClass(params.id, {
            relations: ["categories", "file"],
            ...(Object.keys(fetchParamsGetDetail || {}).length ? fetchParamsGetDetail : {}),
        }).then((res) => {
            if (res?.status) {
                setValues({
                    loading: false,
                    classData: res.data,
                    classFetchedSuccess: true,
                });
            } else {
                setValues({
                    loading: false,
                    classFetchedSuccess: false,
                });
                notification.error({
                    message: res.message || "Not found!",
                });
                navigate(generate("not-found"));
            }
        });
    }, []);

    useEffect(() => {
        // If class data is fetched, then fetch its related info:
        if (values.classFetchedSuccess && values.currentTab === tabs.class) {
            if (values.classMemsFetched === false) {
                setValues({ classMemsFetched: true });
                fetchMems({}, true);
            }
            if (values.filterListUser) {
                const fetchParams = {};

                // Fetch params:
                // - gender:
                fetchParams.gender = values.filterListUser.gender;
                // - status:
                if (values.filterListUser.active !== undefined) {
                    if (values.filterListUser.active === "is_rm") {
                        fetchParams.active = undefined;
                        fetchParams.class_user_status = 0;
                    } else {
                        fetchParams.active = values.filterListUser.active;
                        fetchParams.class_user_status = 1;
                    }
                } else {
                    fetchParams.active = undefined;
                    fetchParams.class_user_status = 1;
                }

                // Fetch:
                // fetchMems({ page: 1, ...fetchParams }, true);
                fetchMems({ page: 1, ...fetchParams });
            }
        }
    }, [values.classFetchedSuccess, values.currentTab, values.filterListUser]);

    useEffect(() => {
        // If class data is fetched, then fetch its related info:
        if (values.classFetchedSuccess && values.currentTab === tabs.exam) {
            if (values.classAsgmtsFetched === false) {
                setValues({ classAsgmtsFetched: true });
                fetchAsgmts({}, true);
            }
            if (values.filterListAsgmts) {
                const fetchParams = {};

                // Fetch params:
                // - teacher_id:
                fetchParams.teacher_id = values.filterListAsgmts.teacher_id;
                fetchParams.category_id = values.filterListAsgmts.category_id;
                fetchParams.is_test = values.filterListAsgmts.is_test;

                // Fetch:
                // fetchAsgmts({ page: 1, ...fetchParams }, true);
                fetchAsgmts({ page: 1, ...fetchParams });
            }
        }
        if (values.classFetchedSuccess && values.currentTab === tabs.competition) {
            fetchCompetitions({}, true);
        }
    }, [values.classFetchedSuccess, values.currentTab, values.filterListAsgmts]);

    useEffect(() => {
        if (dataCompetitions?.length > 0) {
            const selectedKeys = dataCompetitions?.filter((item) => item.is_official).map((item) => item?.id);
            if (selectedKeys) {
                setValues({
                    selectedKeys,
                });
            }
        }
    }, [dataCompetitions]);

    return (
        <div className="detail-class">
            <Spin spinning={values.loading}>
                <Header classData={values.classData} />

                <div className="detail-class-top">
                    <p className="detail-class-title">{values?.classData?.name || "Unknown"}</p>
                    <div className="class-actions-wrapper">
                        {values.currentTab === tabs.class ? (
                            <div className="class-actions for-class">
                                <div className="class-actions">
                                    <Can
                                        I={rolesAllow.inviteUser}
                                        orLogic={() => isTeacherOfClass}
                                        page={pagesAllow.organization}
                                        memoDependencies={[values.classData]} //dependencies for usememo
                                    >
                                        <CustomButton
                                            title={t("class.invite_student")}
                                            icon={<ShareAltOutlined />}
                                            onClick={() => {
                                                setModal({
                                                    name: "inviteAssignmentLink",
                                                    data: {
                                                        assignmentId: `${location.origin}/class-invitation-student/${params.id}`,
                                                    },
                                                });
                                            }}
                                            disabled={
                                                checkDemoAccountAction(accountType, "class", "invite_student") === false
                                            }
                                        />
                                    </Can>
                                    <Can
                                        I={rolesAllow.inviteTeacher}
                                        // orLogic={() => isTeacherOfClass}
                                        page={pagesAllow.class}
                                        memoDependencies={[values.classData]} //dependencies for usememo
                                    >
                                        <CustomButton
                                            title={t("class.invite_teacher")}
                                            icon={<PlusCircleOutlined />}
                                            onClick={() =>
                                                setModal({
                                                    name: "addTeacher",
                                                    data: null,
                                                })
                                            }
                                            disabled={
                                                checkDemoAccountAction(accountType, "class", "invite_teacher") === false
                                            }
                                        />
                                    </Can>
                                </div>
                                <div className="class-actions">
                                    <Can
                                        I={rolesAllow.addUsers}
                                        orLogic={() => isTeacherOfClass}
                                        page={pagesAllow.organization}
                                        memoDependencies={[values.classData]} //dependencies for usememo
                                    >
                                        <CustomButton
                                            title={t("class.add_user")}
                                            icon={<PlusCircleOutlined />}
                                            onClick={() => {
                                                setModal({
                                                    name: "addUsers",
                                                    data: null,
                                                });
                                            }}
                                            // disabled={
                                            //     checkDemoAccountAction(accountType, "class", "add_users") === false
                                            // }
                                        />
                                    </Can>
                                    <Can
                                        I={rolesAllow.addUsers}
                                        orLogic={() => isTeacherOfClass}
                                        page={pagesAllow.organization}
                                        memoDependencies={[values.classData]} //dependencies for usememo
                                    >
                                        <ImportUsers
                                            classId={params.id}
                                            onSuccess={() => {
                                                refetchMems();
                                            }}
                                        />
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div className="class-actions for-exam">
                                {values.currentTab === tabs.exam ? (
                                    <span>
                                        <CustomButton
                                            type="ghost"
                                            title={t("class.assign_exam")}
                                            icon={<CloudUploadOutlined />}
                                            onClick={() =>
                                                values.currentTab === tabs.class ? null : setIsAssignExamModal(true)
                                            }
                                            disabled={
                                                checkDemoAccountAction(accountType, "class", "assign_exam") === false
                                            }
                                        />
                                    </span>
                                ) : values.currentTab === tabs.competition ? (
                                    <span>
                                        <CustomButton
                                            // type="primary"
                                            title={t("ranking.create_competition")}
                                            icon={<CloudUploadOutlined />}
                                            onClick={() =>
                                                values.currentTab === tabs.competition &&
                                                setValues({ isCompetitionModal: true, editingCompetition: {} })
                                            }
                                            disabled={
                                                checkDemoAccountAction(accountType, "class", "assign_exam") === false
                                            }
                                        />
                                    </span>
                                ) : null}
                            </div>
                        )}
                        <div className="help">
                            <span className="act-button-wrapper">
                                <Tooltip title={t("header.help")} placement="bottomRight">
                                    <span
                                        className={`act-button question ${
                                            values.currentTab === tabs.exam ? "outline" : ""
                                        }`}
                                        onClick={handleClickHelp}
                                    >
                                        <QuestionOutlined />
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="detail-class-management">
                    <Tabs defaultActiveKey="1" type="card" centered onChange={handelChangeTab}>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <UserOutlined />
                                    {t("class.manage_class")}
                                </span>
                            }
                            key={tabs.class}
                        >
                            <div className="student-list-table">
                                <Table
                                    className="app-table"
                                    columns={studentColumns.filter((item) => !!item)}
                                    dataSource={dataSourceMems}
                                    scroll={{ x: "auto" }}
                                    pagination={{
                                        showSizeChanger: false,
                                        ...paginationMems,
                                        position: ["bottomCenter"],
                                        onChange: (page) => {
                                            fetchMems({ page: page });
                                        },
                                    }}
                                    loading={loadingMems}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <FileTextOutlined />
                                    {t("class.manage_exam")}
                                </span>
                            }
                            key={tabs.exam}
                        >
                            <div className="exam-list-table">
                                <Table
                                    className="app-table"
                                    columns={examColumns}
                                    actions={examActions}
                                    actionAlign="right"
                                    dataSource={dataSourceAsgmts}
                                    scroll={{ x: "auto" }}
                                    pagination={{
                                        showSizeChanger: false,
                                        ...paginationAsgmts,
                                        position: ["bottomCenter"],
                                        onChange: (page) => {
                                            fetchAsgmts({ page: page });
                                        },
                                    }}
                                    loading={loadingAsgmts}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <FileTextOutlined />
                                    {t("nav.competitions")}
                                </span>
                            }
                            key={tabs.competition}
                        >
                            <div className="exam-list-table competition-table">
                                <Table
                                    className="app-table"
                                    columns={competitionColumns}
                                    rowSelection={
                                        !values.classData?.is_official
                                            ? {
                                                  type: "radio",
                                                  ...rowSelection,
                                              }
                                            : null
                                    }
                                    // actions={examActions}
                                    actionAlign="right"
                                    dataSource={dataCompetitions}
                                    scroll={{ x: "auto" }}
                                    pagination={{
                                        showSizeChanger: false,
                                        ...paginationCompetitions,
                                        position: ["bottomCenter"],
                                        onChange: (page) => {
                                            fetchCompetitions({ page: page });
                                        },
                                    }}
                                    loading={loadingCompetition}
                                />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </Spin>

            {/* Class detail modal: */}
            <PopConfirm
                type={user.id === values.pendingDeleteItem?.id ? "leave" : "delete"}
                visible={values.isConfirmModal}
                onOk={values.confirmModalFor === "competition" ? handleDeleteCompetition : handleDeleteUser}
                onCancel={() => setValues({ isConfirmModal: false, selectedCompetition: {}, confirmModalFor: "" })}
            >
                {values.confirmModalFor === "competition" ? (
                    <p className="modal-title">{t("class.confirm_delete")}</p>
                ) : (
                    <>
                        <p className="modal-title">
                            {user.id === values.pendingDeleteItem?.id
                                ? t("class.confirm_leave_class")
                                : t("class.confirm_delete")}
                        </p>
                        <p>
                            {t(`profile.role_${values.pendingDeleteItem?.role}`)} {values.pendingDeleteItem?.name}{" "}
                            {t("class.will_delete")}
                        </p>
                    </>
                )}
            </PopConfirm>

            {/* Used for Class Management tab: */}
            <ModalAddUser
                classId={params?.id}
                visible={modal.name === "addUsers"}
                onOk={() => {
                    refetchMems();
                    hideModal();
                }}
                onCancel={hideModal}
            />
            <ModalAddTeacher
                classId={params?.id}
                visible={modal.name === "addTeacher"}
                onOk={() => {
                    refetchMems();
                    hideModal();
                }}
                onCancel={hideModal}
            />
            <ModalInviteLink
                targetId={modal.data?.assignmentId}
                visible={modal.name === "inviteAssignmentLink"}
                onOk={hideModal}
                onCancel={hideModal}
            />

            {/* Exam modal: */}
            {/* <Modal
                className="class-detail-modal create-exam"
                centered
                title={null}
                footer={null}
                visible={isCreateExamModal}
                onCancel={() => setIsCreateExamModal(false)}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
            >
                <DetailExam type="modal" handleCloseModal={() => setIsCreateExamModal(false)} />
            </Modal> */}

            <ModalDetailExam
                classId={params.id}
                categoryList={values.classData?.categories}
                isLoading={loadingAsgmts}
                visible={isAssignExamModal}
                isOnMobile={values.isOnMobile}
                isShowFormSelect={isAssignExamModal && values.isShowForm && values.isOnMobile}
                editingAssignment={false}
                examType={values.examType}
                setValuesExam={setValues}
                onOk={() => {
                    refetchAsgmts();
                    setIsAssignExamModal(false);
                    setValues({
                        isShowForm: false,
                    });
                }}
                onCancel={() => {
                    setIsAssignExamModal(false);
                    setValues({
                        isShowForm: false,
                        // examType: "",
                    });
                }}
                destroyOnClose
            ></ModalDetailExam>

            <ModalMarkAssignment
                className="class-detail-modal detail-marking"
                centered
                title={t("class.mark_assignment")}
                footer={null}
                visible={modal.name === "markingModal"}
                onCancel={hideModal}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
                markingAssignmentId={values.markingAssignmentId}
                setIsPersonalLearningProgress={setIsPersonalLearningProgress}
                setStudentInfo={setStudentInfo}
            />

            <ModalGeneralLearningProgress
                className="class-detail-modal detail-learning-progress"
                centered
                title={t("report.general_learning_progress")}
                footer={null}
                visible={isGeneralLearningProgress}
                onCancel={handleCloseModalGeneralLearningProgress}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
                viewProgressAssignment={values.viewProgressAssignment}
                viewProgressSubject={values.viewProgressSubject}
                viewProgressClass={values.viewProgressClass}
            />

            <ModalPersonalLearningProgress
                title={t("report.personal_learning_progress")}
                visible={isPersonalLearningProgress}
                viewProgressClass={values.viewProgressClass}
                viewProgressSubject={values.viewProgressSubject}
                onCancel={handleCloseModalPersonalLearningProgress}
                viewProgressStudent={studentInfo}
            />
            {/* ----Modal cuộc thi */}
            <ModalDetailCompetition
                isLoading={loadingCompetition}
                visible={values.isCompetitionModal}
                editingCompetition={values.editingCompetition}
                classId={params.id}
                classList={[values.classData]}
                onOk={() => {
                    refetchCompetitions();
                    setValues({
                        isCompetitionModal: false,
                        editingCompetition: {},
                    });
                }}
                onCancel={() => {
                    setValues({
                        isCompetitionModal: false,
                        editingCompetition: {},
                    });
                }}
            ></ModalDetailCompetition>
        </div>
    );
};

const TableColumnFilterMenu = (props) => {
    const {
        cateName = "",
        cateNameNotFrom = -1,
        columnName = "",
        filterKeys = [],
        selectedFilterKey = -1,
        onChangeFilterKey = () => {},
    } = props;

    return (
        <ul className="table-column-filter-menu">
            {filterKeys.map((keyItem, keyIndex) => {
                return (
                    <li
                        key={`${columnName}-${keyIndex}`}
                        className={selectedFilterKey === keyItem.value ? "active" : ""}
                        onClick={() => onChangeFilterKey(keyItem.value)}
                    >
                        {cateNameNotFrom === -1 || keyIndex < cateNameNotFrom ? (
                            <>{t(`${cateName}.${keyItem.name}`)}</>
                        ) : (
                            <>{t(`${keyItem.name}`)}</>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default DetailClass;
