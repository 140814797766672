import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth, RequireGuest, RequireGuestTarget, ExternalAccess } from "src/modules/auth";
import { LanguageProvider } from "src/modules/lang";
import Authed from "src/modules/containers/Layout/Authed";
import Login from "src/modules/containers/LoginV2";
// import Login from "src/modules/containers/LoginV1";
import LoadingScreen from "src/modules/components/LoadingScreen";
import { setupApiDefaultsBaseURL } from "src/api/helpers/demoAccount";
import ActiveUserOrganization from "src/modules/containers/LoginV2/components/ActiveUserOrganization";
// GLOBAL CSS (DON'T CHANGE!):
import "antd/dist/antd.min.css";
import "src/assets/styles/index.scss";
import { useGoogleAnalytics } from "src/hooks";

const Root = () => {
    // Language:
    const lang = useSelector((state) => state.general.lang);

    // Account type:
    // Whenever the account type is changed, we have to change the API baseURL with suitable value:
    const accountType = useSelector((state) => state.auth.accountType);
    if (accountType === "demo") {
        setupApiDefaultsBaseURL("demo");
    }

    return (
        <BrowserRouter>
            <RoutesComponent lang={lang} />
        </BrowserRouter>
    );
};

const RoutesComponent = () => {
    const lang = useSelector((state) => state.general.lang);
    useGoogleAnalytics();
    return (
        <Routes>
            <Route path="/external-access" element={<ExternalAccess />} />
            <Route
                path="/:lang/*"
                element={
                    <LanguageProvider>
                        <AuthProvider>
                            <Routes>
                                <Route path="/callback/google" element={<LoadingScreen />} />
                                <Route path="/callback/facebook" element={<LoadingScreen />} />
                                <Route
                                    path="/login"
                                    element={
                                        <RequireGuestTarget>
                                            <RequireGuest>
                                                <Login />
                                            </RequireGuest>
                                        </RequireGuestTarget>
                                    }
                                />
                                <Route
                                    path="/register"
                                    element={
                                        <RequireGuestTarget>
                                            <RequireGuest>
                                                <Login register={true} />
                                            </RequireGuest>
                                        </RequireGuestTarget>
                                    }
                                />
                                <Route
                                    path="/forget-password"
                                    element={
                                        <RequireGuestTarget>
                                            <RequireGuest>
                                                <Login forget={true} />
                                            </RequireGuest>
                                        </RequireGuestTarget>
                                    }
                                />
                                <Route
                                    path="/choose-role"
                                    element={
                                        <RequireAuth>
                                            <Login role={true} />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="/organization/active-user"
                                    element={
                                        <RequireGuestTarget>
                                            <RequireGuest>
                                                <ActiveUserOrganization />
                                            </RequireGuest>
                                        </RequireGuestTarget>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <RequireAuth>
                                            <Authed />
                                        </RequireAuth>
                                    }
                                />
                            </Routes>
                        </AuthProvider>
                    </LanguageProvider>
                }
            />
            <Route path="*" element={<Navigate to={`/${lang}`} replace />} />
        </Routes>
    );
};

export default Root;
