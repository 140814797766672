import api from "..";
import configs from "src/configs";

/**
 * Each account type has each own rules, such as api url.
 * This function id used to setup api url by account type.
 * @param {string} accountType Type of account ("main" || "demo").
 */
export const setupApiDefaultsBaseURL = (accountType) => {
    switch (accountType) {
        case "demo":
            api.defaults.baseURL = configs.API_ENDPOINT_DEMO;
            break;
        case "main":
        default:
            api.defaults.baseURL = configs.API_ENDPOINT;
            break;
    }
};

/**
 * When stopping using "demo" account, use this function to reset all configs to default.
 */
export const cancelUsingDemoAccount = () => {
    setupApiDefaultsBaseURL();
    localStorage.removeItem("previousAccount");
    localStorage.removeItem("currentAccount");
};

/**
 * Respecify account type in cases of:
 * - Missing token on page refresh.
 * - Failed to login.
 */
export const specifyAccountType = () => {
    const nextAct = localStorage.getItem("nextAction");
    if (nextAct) {
        setupApiDefaultsBaseURL();
    } else {
        setupApiDefaultsBaseURL();
        localStorage.removeItem("previousAccount");
        localStorage.removeItem("currentAccount");
    }
};

/**
 * Check if an action on an target is allowed if using demo account.
 * @param {string} accountType Type of account ("main" || "demo").
 * @param {string} targetName Target name: one of the the name of the files listed in src/api/containers.
 * @param {string} actionName Actions ("create" || "read" || "update" || "delete").
 * @returns boolean.
 */
export const checkDemoAccountAction = (accountType = "demo", targetName, actionName) => {
    const allowedActionList = configs.DEMO_ACCOUNT_ALLOW_ACTIONS;
    if (accountType === "demo") {
        return allowedActionList?.[targetName]?.[actionName] || false;
    }
    return true;
};
