import { Col, Form, Row, Select, DatePicker, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef } from "react";
import useValues from "src/hooks/useValues";
import Button from "../../../../components/Button";
import { fetchClasses } from "src/api/containers/class";
import { fetchCategories } from "src/api/containers/category";
import { fetchStudents } from "src/api/containers/user";
import moment from "moment";
import Icon from "src/modules/components/Icon";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import { useSelector } from "react-redux";
import "./FilterMenu.scss";

const filterMenu = (props) => {
    const {
        isModal = false,
        filterParams = [],
        handleFetch = () => {},
        handleCloseFilterMenu = () => {},
        isStudent,
    } = props;
    const [form] = useForm();

    const _filterParams = useMemo(() => {
        const _r = {};
        filterParams.map((item) => {
            _r[item.name] = {
                value: item.value,
                labelName: item.labelName,
                labelValue: item.labelValue,
            };
        });
        return _r;
    }, [filterParams]);

    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    const [values, setValues] = useValues({
        loading: false,
        optionListClass: [],
        optionListSubject: [],
        optionListStudent: [],
        isFetchingClasses: false, // Values: "searching", true, false.
        isFetchingSubjects: false, // Values: "searching", true, false.
        isFetchingStudents: false, // Values: "searching", true, false.
        isLoadingMoreClasses: false, // Values: "disabled" (Disable loading-more), true, false (Allow loading-more).
        isLoadingMoreStudents: false, // Values: "disabled" (Disable loading-more), true, false (Allow loading-more).
    });
    const timeoutIdGetClasses = useRef(null);
    const timeoutIdGetStudents = useRef(null);
    const fetchClassesParams = useRef({
        page: 0,
        slug: "",
    });
    const fetchStudentsParams = useRef({
        page: 0,
        slug: "",
    });

    const handleCloseModal = () => {
        handleCloseFilterMenu(false);
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            class_id: formData.class_id,
            subject_id: formData.subject_id,
            student_id: formData.student_id,
            organization_id: formData.organization_id,
        };

        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.dateFrom &&
            newFilterParams.push({
                name: "dateFrom",
                value: fetchParams.dateFrom,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.dateFrom).format("YYYY-MM-DD"),
            });
        fetchParams.dateTo &&
            newFilterParams.push({
                name: "dateTo",
                value: fetchParams.dateTo,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.dateTo).format("YYYY-MM-DD"),
            });
        fetchParams.class_id &&
            newFilterParams.push({
                name: "class_id",
                value: fetchParams.class_id,
                labelName: t("report.class"),
                labelValue: values.optionListClass.filter((classItem) => {
                    return classItem.id === fetchParams.class_id;
                })[0]?.name,
            });
        fetchParams.subject_id &&
            newFilterParams.push({
                name: "subject_id",
                value: fetchParams.subject_id,
                labelName: t("report.subject"),
                labelValue:
                    values.optionListSubject.filter((subject) => {
                        return subject.id === fetchParams.subject_id;
                    })[0]?.name || "_",
            });
        const labelValueStudent = values.optionListStudent.filter((student) => {
            return student.id === fetchParams.student_id;
        })[0];
        fetchParams.student_id &&
            newFilterParams.push({
                name: "student_id",
                value: fetchParams.student_id,
                labelName: t("report.student"),
                labelValue: labelValueStudent?.name || labelValueStudent?.username,
            });
        fetchParams.organization_id !== undefined &&
            newFilterParams.push({
                name: "organization_id",
                value: fetchParams.organization_id,
                labelValue: scopeName,
            });

        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams);
    };

    // 1. Scope:
    const handleChangeScope = (scopeId) => {
        // Reset class, subject, student data when the scope is changed:
        fetchClassesParams.current = {
            page: 0,
            slug: "",
            organization_id: scopeId,
        };
        fetchStudentsParams.current = {
            page: 0,
            slug: "",
            organization_id: scopeId,
        };
        // Reset form fields:
        form.setFieldsValue({
            class_id: undefined,
            subject_id: undefined,
            student_id: undefined,
        });
        // Fetch:
        handleFetchClass({
            organization_id: scopeId,
        });
    };

    // 2. Class:
    const handleFetchClass = (fetchParams = {}, classId) => {
        if (classId) {
            const selectedScopeId = "";
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                ...fetchParams,
                page: 1,
            };
            const fetchParamsCates = { class_ids: [classId], noPagination: true };
            fetchStudentsParams.current = {
                ...fetchStudentsParams.current,
                page: 1,
                organization_id: selectedScopeId,
                class_id: classId,
            };

            setValues({
                ...values,
                isFetchingClasses: true,
                isFetchingSubjects: true,
                isFetchingStudents: true,
            });
            const _fetchClasses = fetchClasses(fetchClassesParams.current);
            const _fetchCates = fetchCategories(fetchParamsCates);
            const _fetchStudents = fetchStudents(fetchStudentsParams.current);
            Promise.all([_fetchClasses, _fetchCates, _fetchStudents]).then((res) => {
                const resClasses = res[0];
                const resCates = res[1];
                const resStdnts = res[2];

                const _newValues = {};
                const _classes = resClasses.data || [];
                const _cates = resCates.data || [];
                const _stdnts = resStdnts.data || [];

                if (resClasses.status) {
                    _newValues.isFetchingClasses = false;
                    _newValues.optionListClass = _classes;
                    _newValues.isLoadingMoreClasses =
                        resClasses.data?.length === 0 ||
                        resClasses.pagination.lastPage === fetchClassesParams.current.page
                            ? "disabled"
                            : false;
                    fetchClassesParams.current.page = resClasses.pagination?.page || 1;
                } else {
                    _newValues.isFetchingClasses = false;
                    _newValues.optionListClass = [];
                    _newValues.isLoadingMoreClasses = false;
                    fetchClassesParams.current.page = 0;
                }

                if (resCates.status) {
                    _newValues.isFetchingSubjects = false;
                    _newValues.optionListSubject = _cates;
                } else {
                    _newValues.isFetchingSubjects = false;
                    _newValues.optionListSubject = [];
                }

                if (resStdnts.status) {
                    _newValues.isFetchingStudents = false;
                    _newValues.optionListStudent = _stdnts;
                    fetchStudentsParams.current.page = resStdnts.pagination?.page || 1;
                } else {
                    _newValues.isFetchingStudents = false;
                    _newValues.optionListStudent = [];
                    fetchStudentsParams.current.page = 0;
                }

                setValues(_newValues);
            });
        } else {
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                ...fetchParams,
                page: 1,
            };

            setValues({ ...values, isFetchingClasses: true });
            fetchClasses(fetchClassesParams.current).then((res) => {
                if (res.status === true) {
                    const _classes = res.data || [];
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                        optionListClass: _classes,
                        optionListSubject: [],
                        optionListStudent: [],
                        isLoadingMoreClasses:
                            res.data?.length === 0 || res.pagination.lastPage === fetchClassesParams.current.page
                                ? "disabled"
                                : false,
                        isLoadingMoreStudents: false,
                    });
                    fetchClassesParams.current.page = res.pagination?.page || 1;
                } else {
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                        optionListClass: [],
                        optionListSubject: [],
                        optionListStudent: [],
                        isLoadingMoreClasses: false,
                        isLoadingMoreStudents: false,
                    });
                    fetchClassesParams.current.page = 0;
                }
            });
        }
    };

    // 2.1. Class - change:
    const handleChangeClass = (classId) => {
        // Reset slug when the dropdown is closed (when the search-input is cleared):
        fetchClassesParams.current.slug = "";

        if (!classId) {
            // Reset states:
            setValues({
                ...values,
                optionListSubject: [],
                optionListStudent: [],
            });
            // Reset form fields:
            form.setFieldsValue({
                subject_id: undefined,
                student_id: undefined,
            });
            return;
        }

        const selectedScopeId = form.getFieldValue("organization_id");
        const fetchParamsCates = { class_ids: [classId] };
        fetchStudentsParams.current = {
            ...fetchStudentsParams.current,
            page: 1,
            organization_id: selectedScopeId,
            class_id: classId,
        };

        setValues({ ...values, isFetchingSubjects: true, isFetchingStudents: true });
        Promise.all([fetchCategories(fetchParamsCates), fetchStudents(fetchStudentsParams.current)]).then((res) => {
            const resCates = res[0];
            const resStdnts = res[1];
            if (resCates.status || resStdnts.status) {
                setValues({
                    ...values,
                    isFetchingSubjects: false,
                    isFetchingStudents: false,
                    optionListSubject: resCates.data || [],
                    optionListStudent: resStdnts.data || [],
                });
                fetchStudentsParams.current.page = resStdnts.pagination?.page || 1;
            } else {
                setValues({
                    ...values,
                    isFetchingSubjects: false,
                    isFetchingStudents: false,
                    optionListSubject: [],
                    optionListStudent: [],
                });
                fetchStudentsParams.current.page = 0;
            }
        });
        // Reset form fields:
        form.setFieldsValue({
            subject_id: undefined,
            student_id: undefined,
        });
    };

    // 2.2. Class - keydown:
    const handleKeyDownClass = (e) => {
        if (values.isFetchingClasses === true || values.isLoadingMoreClasses === true) {
            e.preventDefault();
        }
    };

    // 2.3. Class - search:
    const handleSearchClasses = (keyword) => {
        clearTimeout(timeoutIdGetClasses.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                isFetchingClasses: "searching",
            });
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                page: 1,
                slug: keyword,
            };
            timeoutIdGetClasses.current = setTimeout(() => {
                fetchClasses(fetchClassesParams.current).then((res) => {
                    if (res.status) {
                        const _classes = res.data || [];
                        setValues({
                            ...values,
                            optionListClass: _classes,
                            isFetchingClasses: false,
                            isLoadingMoreClasses: false,
                        });
                    } else {
                        setValues({
                            ...values,
                            isFetchingClasses: false,
                        });
                    }
                });
            }, 500);
        }
    };

    // 2.4. Class - scroll:
    const handleScrollClasses = (e) => {
        if (
            ["searching", true].includes(values.isFetchingClasses) ||
            ["disabled", true].includes(values.isLoadingMoreClasses)
        ) {
            return;
        }
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = fetchClassesParams.current.page + 1;
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                page: nextPageToLoad,
            };
            setValues({ ...values, isLoadingMoreClasses: true });
            fetchClasses(fetchClassesParams.current).then((res) => {
                if (res.status) {
                    const _classes = [...values.optionListClass, ...res.data];
                    setValues({
                        ...values,
                        isLoadingMoreClasses:
                            res.data?.length === 0 || res.pagination.lastPage === nextPageToLoad ? "disabled" : false,
                        optionListClass: _classes,
                    });
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreClasses: false,
                    });
                }
            });
        }
    };

    // 2.5. Class - element:
    const classFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("shared.search_and_select_classes")}
            allowClear
            showSearch
            optionFilterProp="children"
            // dropdownMatchSelectWidth={false}
            disabled={values.isFetchingSubjects || values.isFetchingStudents}
            onChange={handleChangeClass}
            onInputKeyDown={handleKeyDownClass}
            onSearch={(keyword) => handleSearchClasses(keyword)}
            onPopupScroll={(e) => handleScrollClasses(e)}
            notFoundContent={
                values.isFetchingClasses === true ? (
                    <i className="notfound-when-loading">
                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                    </i>
                ) : values.isFetchingClasses === "searching" ? (
                    <React.Fragment></React.Fragment>
                ) : undefined
            }
            dropdownClassName="app-select-dropdown"
            dropdownRender={(menu) => (
                <>
                    {values.isFetchingClasses === "searching" ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                        </div>
                    ) : null}
                    {menu}
                    {values.isLoadingMoreClasses === true ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        </div>
                    ) : null}
                </>
            )}
        >
            {_filterParams.class_id?.value && (
                <Select.Option key={`class-extra`} value={_filterParams.class_id?.value} style={{ display: "none" }}>
                    {_filterParams.class_id?.labelValue}
                </Select.Option>
            )}
            {values.optionListClass.map((item, itemIndex) => (
                <Select.Option key={`class-${item.id}`} value={item.id}>
                    ({item.code}) {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    // 3. Subject:
    // 3.1. Subject - change:
    const handleChangeSubject = (subjectId) => {
        // const selectedScopeId = form.getFieldValue("organization_id");
        // const selectedClassId = form.getFieldValue("class_id");
        // fetchStudentsParams.current = {
        //     ...fetchStudentsParams.current,
        //     page: 1,
        //     organization_id: selectedScopeId,
        //     class_id: selectedClassId,
        //     category_id: subjectId,
        // };
        // setValues({ ...values, isFetchingStudents: true });
        // fetchStudents(fetchStudentsParams.current).then((res) => {
        //     if (res.status) {
        //         setValues({
        //             ...values,
        //             isFetchingStudents: false,
        //             optionListStudent: res.data || [],
        //         });
        //         fetchStudentsParams.current.page = res.pagination?.page || 1;
        //     } else {
        //         setValues({
        //             ...values,
        //             isFetchingStudents: false,
        //             optionListStudent: [],
        //         });
        //         fetchStudentsParams.current.page = 0;
        //     }
        // });
        // // Reset form fields:
        // form.setFieldsValue({
        //     student_id: undefined,
        // });
    };

    // 3.1. Subject - element:
    const subjectFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("report.please_select_subject")}
            allowClear
            showSearch
            optionFilterProp="children"
            // dropdownMatchSelectWidth={false}
            disabled={values.isFetchingClasses || values.isFetchingStudents}
            onChange={handleChangeSubject}
        >
            {values.optionListSubject.map((item, itemIndex) => (
                <Select.Option key={`cate-${item.id}`} value={item.id}>
                    {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    // 4. Student:
    // 4.1. Student - change:
    const handleChangeStudent = (stdntId) => {
        // Reset slug when the dropdown is closed (when the search-input is cleared):
        fetchStudentsParams.current.slug = "";
    };

    // 4.2. Student - keydown:
    const handleKeyDownStudent = (e) => {
        if (values.isFetchingStudents === true || values.isLoadingMoreStudents === true) {
            e.preventDefault();
        }
    };

    // 4.3. Student - search:
    const handleSearchStudents = (keyword) => {
        clearTimeout(timeoutIdGetStudents.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                isFetchingStudents: "searching",
            });
            fetchStudentsParams.current = {
                ...fetchStudentsParams.current,
                page: 1,
                slug: keyword,
            };
            timeoutIdGetStudents.current = setTimeout(() => {
                fetchStudents(fetchStudentsParams.current).then((res) => {
                    if (res.status) {
                        setValues({
                            ...values,
                            optionListStudent: res.data,
                            isFetchingStudents: false,
                            isLoadingMoreStudents: false,
                        });
                    } else {
                        setValues({
                            ...values,
                            isFetchingStudents: false,
                        });
                    }
                });
            }, 500);
        }
    };

    // 4.4. Student - scroll:
    const handleScrollStudents = (e) => {
        if (
            ["searching", true].includes(values.isFetchingStudents) ||
            ["disabled", true].includes(values.isLoadingMoreStudents)
        ) {
            return;
        }
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = fetchStudentsParams.current.page + 1;
            fetchStudentsParams.current = {
                ...fetchStudentsParams.current,
                page: nextPageToLoad,
            };
            setValues({ ...values, isLoadingMoreStudents: true });
            fetchStudents(fetchStudentsParams.current).then((res) => {
                if (res.status) {
                    setValues({
                        ...values,
                        isLoadingMoreStudents:
                            res.data?.length === 0 || res.pagination.lastPage === nextPageToLoad ? "disabled" : false,
                        optionListStudent: [...values.optionListStudent, ...res.data],
                    });
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreStudents: false,
                    });
                }
            });
        }
    };

    // 4.5. Student - element:
    const studentFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("report.please_select_student")}
            allowClear
            showSearch
            optionFilterProp="children"
            // dropdownMatchSelectWidth={false}
            disabled={values.isFetchingClasses || values.isFetchingSubjects}
            onChange={handleChangeStudent}
            onInputKeyDown={handleKeyDownStudent}
            onSearch={(keyword) => handleSearchStudents(keyword)}
            onPopupScroll={(e) => handleScrollStudents(e)}
            notFoundContent={
                values.isFetchingStudents === true ? (
                    <i className="notfound-when-loading">
                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                    </i>
                ) : values.isFetchingStudents === "searching" ? (
                    <React.Fragment></React.Fragment>
                ) : undefined
            }
            dropdownClassName="app-select-dropdown"
            dropdownRender={(menu) => (
                <>
                    {values.isFetchingStudents === "searching" ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                        </div>
                    ) : null}
                    {menu}
                    {values.isLoadingMoreStudents === true ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        </div>
                    ) : null}
                </>
            )}
        >
            {_filterParams.student_id?.value && (
                <Select.Option key={`class-extra`} value={_filterParams.student_id?.value} style={{ display: "none" }}>
                    {_filterParams.student_id?.labelValue}
                </Select.Option>
            )}
            {values.optionListStudent.map((item, itemIndex) => (
                <Select.Option key={`stdnt-${item.id}`} value={item.id}>
                    {item.name || t("no_name_yet")} - {item.username}
                </Select.Option>
            ))}
        </Select>
    );

    useEffect(() => {
        if (!_filterParams.class_id?.value) {
            handleFetchClass();
        } else {
            handleFetchClass({}, _filterParams.class_id?.value);
        }
    }, []);

    useEffect(() => {
        const newFormData = {};
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i]?.name] = filterParams[i]?.value;
        }

        // Update form:
        form.resetFields();
        form.setFieldsValue(newFormData);

        // Refetch data:
        if (!isModal) {
            const fetchParams = {
                dateFrom: newFormData.dateFrom,
                dateTo: newFormData.dateTo,
                class_id: newFormData.class_id,
                subject_id: newFormData.subject_id,
                student_id: newFormData.student_id,
                organization_id: newFormData.organization_id,
            };
            handleFetch({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    return (
        <div className="filter-personal-learning-progress_wrapper">
            <Spin
                spinning={
                    values.isFetchingClasses === true ||
                    values.isFetchingStudents === true ||
                    values.isFetchingSubjects === true
                }
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    className="form form-full-label filter-personal-learning-progress_inner scroll_primary"
                >
                    <Row>
                        {fParamsUserScope?.organization_id ? (
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item name="organization_id" label={t("shared.scope")}>
                                    <Select
                                        className="filter-person_selector"
                                        placeholder={t("shared.select_scope")}
                                        allowClear
                                        // dropdownMatchSelectWidth={false}
                                        onChange={handleChangeScope}
                                    >
                                        <Select.Option value={fParamsUserScope.organization_id}>
                                            {scopeName || "Unknown"}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="dateFrom"
                                label={
                                    <>
                                        <span>{t("report.dateFrom")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_datefrom")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    // suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="dateTo"
                                label={
                                    <>
                                        <span>{t("report.dateTo")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_dateto")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    // suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="class_id"
                                label={t("report.class")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                {classFilterMenu}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item name="subject_id" label={t("report.subject")}>
                                {subjectFilterMenu}
                            </Form.Item>
                        </Col>

                        {!isStudent && (
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name="student_id"
                                    label={t("report.student_list")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    {studentFilterMenu}
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    <Row className="filter_btn">
                        <Button
                            type="grey"
                            onClick={handleCloseModal}
                            icon={<Icon name="icon-delete" />}
                            title={t("report.cancel")}
                        ></Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("report.apply")}
                        ></Button>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default filterMenu;
