import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import "./RecordButton.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const RecordButton = ({ isTalking, onClick = () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className="record-button-wrapper">
            <button type="button" className="record-button" onClick={onClick}>
                <div className={clsx({ "pulse-ring": true, recording: isTalking })}></div>
                <div className="record-icon">{isTalking ? <AudioOutlined /> : <AudioMutedOutlined />}</div>
            </button>
            {isTalking ? (
                <div className="record-note">({t("speech_to_text.tap_to_close_mic")})</div>
            ) : (
                <div className="record-note">({t("speech_to_text.tap_to_open_mic")})</div>
            )}
        </div>
    );
};

export default RecordButton;
