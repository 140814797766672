import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Progress, Typography } from "antd";
import QuestionContentDetail from "../components/ContentDetail";
import "./SpeechToText.scss";
import { useValues } from "src/hooks";
import UserSpeechRecognition from "src/modules/components/UserSpeechRecognition";

const { TextArea } = Input;

// câu này có 1 chút khác biêt.
// Trong câu pasage đang sử dụng antd tabs.
// Nó sẽ render toàn bộ câu hỏi ra 1 lúc đồng nghĩ với việc khởi tạo cùng lúc nhiều đối tượng speechtotext.
// Tuy nhiên chrome chỉ hỗ trợ 1 instance 1 lúc thôi.
// nên khi thu âm 1 câu hỏi thì các câu khác sẽ bị thay đổi theo luôn.
// hay cả 2 câu đều đang dùng chung 1 instance speechToText trong chrome
// cách đang giải quyết là: chỉ render câu hỏi đó khi chuyển đến đúng tab. (sd key isRenderInPassage)

function SpeechToText(props) {
    const {
        isReadonly = false,
        question,
        defaultAnswer,
        onChange = () => {},
        answer, // đáp án
        is_sentence,
        speaker,
        lang,
        spelling,
        mode,
        isRenderInPassage,
        hideMicro,
    } = props;

    const { t } = useTranslation();
    const [inputAnswer, setInputAnswer] = useState(answer?.[0] || defaultAnswer || answer);

    const [values, setValues] = useValues({
        accuracy: 0,
        hadSpeech: false,
    });

    const checkHadSpeech = () => {
        if (!values.hadSpeech) {
            setValues({ hadSpeech: true });
        }
    };

    return (
        <div className="q-writing qp-speech-to-text">
            <div className="q-title">{t("q.question")}</div>

            {question && (
                <div className="q-content-title">
                    <QuestionContentDetail isReadonly={isReadonly} value={{ question }} />
                </div>
            )}

            {(isRenderInPassage == undefined || (isRenderInPassage != undefined && isRenderInPassage == true)) && (
                <div className="content-writing content-speech-to-text">
                    <div className="speech-to-text-frame">
                        <div className="speech-to-text-accuracy">
                            {values.hadSpeech && <Progress percent={values.accuracy} />}
                        </div>
                        {/* <div className="sample-sentence">
                        <Typography.Title level={4} style={{ textAlign: "center" }}>
                            {inputAnswer}
                        </Typography.Title>
                    </div> */}

                        <div className="speech-and-text">
                            <UserSpeechRecognition
                                is_sentence={is_sentence}
                                setSpeechValues={setValues}
                                answer={answer}
                                valuesSpeech={values}
                                checkHadSpeech={checkHadSpeech}
                                speaker={Array.isArray(speaker) ? speaker?.[0] : speaker}
                                // speaker={"cmn-Hans-CN"}
                                lang={lang}
                                onChange={onChange}
                                spelling={spelling}
                                mode={mode}
                                answered={defaultAnswer}
                                hideMicro={hideMicro}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SpeechToText;
