import React, { useContext, useEffect, useMemo } from "react";
import PDFQuestionItem from "../PDFQuestionItem";
import { useValues } from "src/hooks";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

function Sidebar({ questions = [], loading = false, recordId }) {
    const { t } = useTranslation();

    const [values, setValues] = useValues({
        selectedQuestion: {},
        isShowResult: false,
    });

    return (
        <Spin spinning={loading}>
            <div className="pdf-test-sidebar">
                {questions.map((item, idx) => {
                    return (
                        <PDFQuestionItem
                            recordId={recordId}
                            mode={"test"}
                            question={item}
                            key={`${item.key}_${idx}`}
                            qIndex={item.order}
                            isShowResult={false}
                        />
                    );
                })}
            </div>
        </Spin>
    );
}

export default Sidebar;
