import { useTranslation } from "react-i18next";
import "./RoundStatus.scss";

function RoundStatus({ status }) {
    const { t } = useTranslation();

    const renderRecordStatus = (status) => {
        switch (status) {
            case "upcoming":
                return <div className="round cardItem_status cardItem_status-upcoming">{t("ranking.upcoming")}</div>;
            case "happening":
                return <div className="round cardItem_status cardItem_status-happenning">{t("ranking.happening")}</div>;
            case "finished":
                return <div className="round cardItem_status cardItem_status-finished">{t("ranking.finished")}</div>;
            default:
                return "";
        }
    };

    return renderRecordStatus(status);
}

export default RoundStatus;
