import React from "react";
import "./Header.scss";
import { ReactComponent as TVStatistic } from "src/assets/images/tv-statistic.svg";
import { t } from "i18next";
import NoAvatar from "src/assets/images/no-avatar.png";
import ShowMoreIcon from "src/assets/images/showMore-icon.svg";
import NotificationIcon from "src/assets/images/Notification.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsed, toggleHelpModalVisible } from "src/reducers/general";
import { QuestionCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Header = ({ title = "", description, children }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const handleToggleCollapsed = () => {
        dispatch(toggleCollapsed());
    };

    const handleClickHelp = () => {
        dispatch(toggleHelpModalVisible(true));
    };

    return (
        <>
            <div className="student-header">
                <div className="student-header_container">
                    <div className="student-header-wrapper">
                        <div className="student-header-wrapper-pc">
                            <div className="student-header-left">
                                <TVStatistic />
                                <p className="student-header-title">{title}</p>
                                {description && <p>{description}</p>}
                            </div>
                            <div className="student-header-right">
                                <span className="act-button-wrapper">
                                    <Tooltip title={t("header.help")} placement="bottomRight">
                                        <span className="act-button question" onClick={handleClickHelp}>
                                            <QuestionCircleFilled />
                                        </span>
                                    </Tooltip>
                                </span>
                            </div>
                        </div>

                        <div className="student-header_controlPanel">
                            <div className="student-header_showMore" onClick={handleToggleCollapsed}>
                                <img src={ShowMoreIcon} />
                            </div>
                            <div className="student-header_help">
                                <Tooltip title={t("header.help")} placement="bottomRight">
                                    <span className="act-button-wrapper">
                                        <span className="act-button question" onClick={handleClickHelp}>
                                            <QuestionCircleOutlined />
                                        </span>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="student-header_notification">
                                <img src={NotificationIcon} />
                            </div>
                            <div className="student-header_avatarWrapper">
                                <img src={user?.avatar || NoAvatar} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="student-filter">{children}</div>
            </div>
        </>
    );
};

export default Header;
