import { vi_questions } from "./questions/vi";

export default {
    // NEW:
    ...vi_questions,

    role: {
        teacher: "Giáo viên",
        student: "Học viên",
    },

    // Actions:
    download_sample_file: "Tải file mẫu",
    download_sample_file_to_import: "Tải file mẫu để nhập hàng loạt",

    // Fields:
    no_name_yet: "Chưa có tên",
    too_many_request: "Quá nhiều yều cầu được gửi đi",
    number_of_correct_answers: "Số câu đúng",
    scored_manually: "Đã chấm tay",
    view: "Xem",
    new: "Mới",

    // Actions:
    update_info: "Cập nhật thông tin",

    // OLD:
    manage_role: {
        role: "Vai trò",
        name: "Tên",
        description: "Mô tả",
        permissions: "Quyền",
        // Actions:
        find_role: "Tìm vai trò",
        add_role: "Thêm vai trò",
        update_role: "Cập nhật vai trò",
        select_roles: "Chọn vai trò",
    },

    help: {
        help: "Hướng dẫn",
        next: "Tiếp tục",
        pre: "Quay lại",
        nodata: "Không có dữ liệu",
        category: "Danh mục",
        collapse_sidebar: "Thu nhỏ",
    },

    shared: {
        // Title:
        warning: "Cảnh báo",
        // Info:
        fullname: "Họ và tên",
        phonenumber: "Số điện thoại",
        message: "Tin nhắn",
        // Reactions:
        reactions: "Phản ứng",
        reaction_count: "Số lượt thích",
        clone_count: "Số lượt sao chép",
        // Scope:
        scope: "Phạm vi",
        public: "Công khai",
        private: "Chỉ mình tôi",
        only_me: "Chỉ mình tôi",
        team: "Nhóm",
        organization: "Tổ chức",
        local_of_team: "Phạm vi nhóm",
        local_of_organization: "Phạm vi tổ chức",
        // Others:
        no_data: "Không có dữ liệu",
        exit: "Thoát ra",
        loading: "Đang tải",
        redirecting: "Đang chuyển hướng",
        searching: "Đang tìm kiếm",
        setting: "Thiết lập",
        signout: "Đăng xuất",
        save: "Lưu",
        back: "Quay lại",
        confirm: "Xác nhận",
        clone: "Sao chép",
        apply: "Áp dụng",
        category: "Danh mục",
        tags: "Thẻ",
        owner: "Người tạo",
        question: "Câu hỏi",
        type: "Loại",
        status: "Trạng thái",

        created_at: "Ngày tạo",
        updated_at: "Ngày cập nhật",
        choose_category: "Chọn danh mục",
        choose_tags: "Chọn tags",
        choose_type: "Chọn loại",
        choose_status: "Chọn trạng thái",
        choose_public: "Chọn công khai",
        score: "Điểm",
        total_score: "Tổng điểm",
        answer: "Đáp án",
        save_draft: "Lưu bản nháp",
        publish: "Xuất bản",
        add_new_question: "Thêm câu hỏi mới",
        add_new_exam: "Thêm đề bài mới",
        find_question: "Tìm câu hỏi",
        find_exam: "Tìm đề thi",
        find_assignment: "Tìm kiếm",
        add_assignment: "Giao bài",
        option_filter: "Bộ lọc",
        unknown: "Không rõ",
        search_and_select_category: "Tìm và chọn danh mục...",
        search_and_select_tags: "Tìm và chọn thẻ...",
        search_and_select_classes: "Tìm và chọn lớp học...",
        mark: "Chấm điểm",
        copy_invite_link: "Sao chép link",
        copy_invite_link_success: "Đã sao chép link",
        or: "hoặc",
        copy_the_link_below: "Sao chép đường link dưới đây",
        active: "Hoạt động",
        inactive: "Không hoạt động",
        all: "Tất cả",
        is_removed: "Đã bị xóa",
        // Fields:
        note: "Ghi chú",
        // Actions:
        ok: "Đồng ý",
        cancel: "Hủy",
        yes: "Có",
        no: "Không",
        reset: "Đặt lại",
        add_new: "Thêm mới",
        update: "Cập nhật",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        create: "Tạo",
        copy: "Sao chép",
        submit: "Gửi",
        input: "Nhập",
        view_detail: "Xem chi tiết",
        upate_image: "Cập nhật ảnh",
        select_scope: "Chọn phạm vi",
        contact_us: "Liên hệ tư vấn",
        // Confirm:
        confirm_cancel: "Hủy",
        confirm_reset: "Đặt lại",
        confirm_update: "Cập nhật",
        confirm_delete: "Xóa",
        confirm_leave: "Rời",
        confirm_action: "Xác nhận",
        msg_confirm_cancel: "Bạn có chắc chắn muốn hủy",
        msg_confirm_reset: "Bạn có chắc chắn muốn đặt lại?",
        msg_confirm_delete: "Bạn có chắc chắn muốn xóa",
        msg_confirm_leave: "Bạn có chắc chắn muốn rời đi?",
        msg_confirm_action: "Bạn có chắc chắn muốn thực hiện hành động này",
        msg_confirm_action_of: "Bạn có chắc chắn muốn",
        msg_confirm_action_of_demo_account: "Bạn có chắc chắn muốn đăng nhập tài khoản thử nghiệm",
        msg_confirm_action_of_main_account: "Bạn có chắc chắn muốn quay lại tài khoản chính",
        // Message:
        warning_demo_account: "Bạn đang đăng nhập với tài khoản thử nghiệm!",
        you_dont_have_permission: "Bạn không có quyền làm điều này!",
        input_fullname: "Hãy nhập họ và tên của bạn",
        input_phonenumber: "Hãy nhập số điện thoại của bạn",
        input_message: "Hãy nhập tin nhắn",
        //user
        gender: "Giới tính",
        male: "Nam",
        female: "Nữ",
        find: "Tìm kiếm...",
        select_image: "Chọn ảnh",

        //qrcode
        download_qr: "Tải xuống mã QR",
        set_correct_answer: "Chọn đáp án đúng",

        minute: "phút",
        result: "Kết quả",
        ascending: "Tăng dần",
        descending: "Giảm dần",
        something_went_wrong: "Rất tiếc! Đã xảy ra sự cố",
        passed: "Đậu",
        failed: "Rớt",
        order: "Thứ tự",
        input_order: "Nhập thứ thự",
        close_modal: "Đóng",
        processing: "Đang xử lý",
    },

    login: {
        welcome_back: "Chào mừng bạn quay lại!",
        login_to_experience: "Đăng nhập để trải nghiệm ngay",
        enter_your_email: "Nhập email của bạn",
        password: "Mật khẩu",
        retype_password: "Nhập lại mật khẩu",
        remember: "Ghi nhớ",
        forget_password: "Quên mật khẩu",
        sign_in: "Đăng nhập",
        sign_up: "Đăng ký",
        or_login_with: "Hoặc đăng nhập bằng tài khoản",
        or_register_with: "Hoặc đăng ký bằng tài khoản",
        login_with_facebook: "Facebook",
        login_with_google: "Google",
        dont_have_account: "Bạn chưa có tài khoản?",
        free_register: "Đăng ký miễn phí",
        start_signup_free: "Bắt đầu tạo tài khoản miễn phí",
        intro:
            "Tham gia cộng đồng giáo viên đang phát triển đang sử dụng phương pháp đánh giá Test Online để thúc đẩy học tập",
        who_are_you: "Bạn là ai?",
        teacher: "Giáo viên",
        student: "Học sinh",
        sign_up_now: "Đăng ký miễn phí ngay!",
        please_fill_in_the_form: "Vui lòng điền các thông tin bên dưới",
        still_remember: "Bạn vẫn nhớ mật khẩu?",
        send: "Gửi",
        enter_mail_to_reset_password: "Vui lòng nhập email đăng ký bên dưới để nhận được hướng dẫn lấy lại mật khẩu",
        email_sent: "Email đã được gửi!",
        send_error: "Gửi email không thành công!",
        reset_password: "Tạo mật khẩu mới",
        rule_new_password: "Mật khẩu mới phải khác mật khẩu trước đây mà bạn từng tạo",
        receive_code: "Nhận mã xác thực trong email",
        new_password: "Mật khẩu mới",
        retype_new_password: "Nhập lại mật khẩu mới",
        save_password: "Lưu mật khẩu",
        verify_email: "Xác thực email!",
        please_verify: "Vui lòng nhập mã xác thực được gửi đến email của bạn",
        enter_code: "Nhập mã xác thực",
        verify: "Xác thực",
        verify_code: "Mã xác thực",
        open_account_for: "Đăng ký dành cho",
        account_activated: "Tài khoản này đã được kích hoạt rồi",
        invalid_path: "Đường dẫn không hợp lệ",
        chanage_password: "Đổi mật khẩu",
        update_your_password: "Cập nhật mật khẩu mới của bạn",
        update_password: "Cập nhật mật khẩu",
    },

    header: {
        go_back: "Quay lại",
        modify: "Chỉnh sửa",
        preview: "Xem trước",
        question_bank: "Ngân hàng câu hỏi",
        exam_bank: "Ngân hàng đề bài",
        say_hello: "Xin chào",
        assignment: "Bài tập",
        organization: "Tổ chức",
        my_exam: "Đề thi của tôi",
        my_questions: "Câu hỏi của tôi",
        help: "Hỗ trợ",
        manage_student: "Quản lý học sinh",
        manage_teacher: "Quản lý giáo viên",
        manage_employee: "Quản lý nhân viên",
        manage_role: "Phân quyền",
        organization_profile: "Tổ chức",
        dashboard: "Dashboard",
    },

    // Used for nav menu:
    nav: {
        notification: "Thông báo",
        account: "Tài khoản",
        profile: "Hồ sơ",
        logout: "Đăng xuất",
        demo_account: "Tài khoản thử nghiệm",
        main_account: "Tài khoản chính",

        // Role - teacher:
        class: "Quản lý lớp học",
        question_bank: "Ngân hàng câu hỏi",
        exam_bank: "Ngân hàng đề",
        assignment: "Giao bài",
        learning_progress: "Tiến độ học tập",
        personal_leaning_progress: "Tiến độ học tập cá nhân",
        my_storage: "Quản lý lưu trữ",
        organization: "Tổ chức",
        my_resources: "Tài nguyên của tôi",
        my_resources_question: "Câu hỏi",
        my_resources_exam: "Đề thi",
        branch: "Chi nhánh",

        // Role - student:
        classes: "Lớp học",
        assignments: "Bài được giao",
        scores: "Điểm số",
        gradebook: "Gradebook",

        admin_dashboard: "Admin dashboard",
        admin_dashboard_team: "Quản lý nhóm",
        admin_dashboard_org: "Quản lý tổ chức",
        ranking: "Bảng xếp hạng",
        competitions: "Cuộc thi",
        feedback: "Feedback",
        home: "Dashboard",
        setting: "Cài đặt",
        entry_test: "Test đầu vào",
    },

    sidebar: {
        hello: "Xin chào",
        class: "Quản lý lớp học",
        question_bank: "Ngân hàng câu hỏi",
        exam_bank: "Ngân hàng đề",
        assignment: "Giao bài tập",
        logout: "Đăng xuất",
        profile: "Thông tin cá nhân",
        my_storage: "Quản lý lưu trữ",
        assign_homework: "Giao bài tập",
        assignments: "Bài được giao",
        scores: "Điểm số",
        classes: "Lớp học",
        notification: "Thông báo",
        account: "Quản lý tài khoản",
        learning_progress: "Tiến độ học tập",
        my_exam: "Bài kiểm tra của tôi",
        my_questions: "Câu hỏi của tôi",
        my_resources: "Tài nguyên của tôi",
        my_resources_question: "Câu hỏi",
        my_resources_exam: "Đề thi",
        demo_account: "Tài khoản thử nghiệm",
        main_account: "Tài khoản chính",
        // Organization management:
        organization: "Tổ chức",
        organization_detail: "Thông tin tổ chức",
        manage_class: "Quản lý lớp học",
        manage_student: "Quản lý học sinh",
        manage_teacher: "Quản lý giáo viên",
        manage_employee: "Quản lý nhân viên",
        manage_branch: "Quản lý chi nhánh",
        manage_capacity: "Quản lý dung lượng",
        manage_data: "Quản lý dung lượng",
        manage_role: "Phân quyền",
        organization_detail_team: "Thông tin nhóm",
        organization_detail_org: "Thông tin tổ chức",
        manage_ranking: "Bảng xếp hạng",
        manage_competitions: "Cuộc thi",
    },

    lang: {
        english: "English",
        vietnamese: "Tiếng Việt",
    },

    select_question: {
        // Messages:
        null: "Chưa chọn loại câu hỏi",
        tutorial_tooltip: "Nhấn vào tôi để hiện thị hướng dẫn cho các loại câu hỏi",

        scoring_manual: "Chấm điểm tay",
        scoring_auto: "Chấm điểm tự động",
    },
    question: {
        question: "Câu hỏi",
        passage: "Đoạn văn",
        show_answer: "Hiện đáp án",
        empty_answer: "Đáp án đã bị bỏ trống",
        question_multiple_choice: "Trắc nghiệm",
        question_multiple_choice_boolean: "Trắc nghiệm (đúng sai)",
        question_multiple_choice_table: "Trắc nghiệm (bảng)",
        question_fill_in_the_blanks: "Điền vào chỗ trống (chọn)",
        question_fill_in_the_blanks_text: "Điền vào chỗ trống (viết)",
        question_fill_in_the_blanks_drag_drop: "Điền vào chỗ trống (kéo thả)",
        question_writing: "Bài tập viết",
        question_passage: "Đoạn văn",
        question_sort: "Sắp xếp",
        question_drag_drop: "Kéo thả",
        question_drag_drop_group: "Kéo thả nhóm",
        question_drag_drop_group_order_by: "Kéo thả nhóm (theo thứ tự)",
        question_highlight: "Highlight",
        question_highlight_image: "Đánh dấu vào hình",
        header_multiple_choice: "Trắc nghiệm",
        header_multiple_choice_table: "Trắc nghiệm (bảng)",
        header_multiple_choice_boolean: "Trắc nghiệm (đúng sai)",
        header_fill_in_the_blanks: "Điền vào chỗ trống (chọn)",
        header_fill_in_the_blanks_input: "Điền vào chỗ trống (viết)",
        header_fill_in_the_blanks_drag_drop: "Điền vào chỗ trống (kéo thả)",
        header_drag_drop_group_order_by: "Kéo thả nhóm (theo thứ tự)",
        header_highlight: "Tô đậm",
        hint_create_blanks_1: "Chọn nút",
        hint_create_blanks_2: "để tạo ô trống",
        hint_highlight_1: "Bôi (từ, câu, đoạn văn) sau đó nhấn vào nút",
        hint_highlight_2: "để chọn đáp án đúng",
        header_writing: "Viết",
        header_sort: "Sắp xếp",
        header_drag_drop: "Kéo thả",
        header_drag_drop_group: "Kéo thả nhóm",
        header_passage: "Đoạn văn",
        header_passage_add_child: "Thêm đoạn văn",
        header_highlightImage: "Đánh dấu vào hình",
        answer: "Đáp án",
        group: "Nhóm",
        score: "Điểm",
        publishing_status: "Trạng thái xuất bản",
        private: "Chỉ mình tôi",
        public: "Công khai",
        program: "Chương trình",
        level: "Trình độ",
        category: "Danh mục",
        tags: "Thẻ",
        input_answer: "Nhập đáp án",
        select_answer: "Chọn đáp án",
        select_question_type: "Chọn loại câu hỏi",
        select_question_group: "Chọn nhóm câu hỏi",
        select_category: "Chọn danh mục",
        select_tags: "Chọn các thẻ",
        input_new_tag: "Tên thẻ mới...",
        input_new_tag_color: "Màu thẻ (hex)",
        required_publishing_status: "Bạn chưa chọn trạng thái xuất bản!",
        your_answer: "Đáp án của bạn",
        correct_answer: "Đáp án đúng",
        question_number_list: "Danh sách câu hỏi",
        owner: "Người sở hữu",
        owner_me: "Tôi",
        owner_all: "Tất cả",
        select_owner: "Chọn người sở hữu",
        message_help_group: "Đây là cột dùng để nhóm các đáp án",
        message_choose_the_correct_answer: "Kéo thả đáp án đúng vào nhóm",
        message_shuffle: "Đáp án sẽ được trộn khi học sinh làm bài",
        no_question: "Không có câu hỏi",
        help_highlight: "Bôi (từ, câu, đoạn văn) sau đó nhấn vào nút Highlight để chọn đáp án đúng",
        options: "Tùy chọn",
        method: "Phương thức",
        correct: "Chính xác",
        incorrect: "Không chính xác",
    },

    question_panel: {
        question: "Câu",
        write_here: "Viết bài ở đây",
    },

    question_writing: {
        word_limit: "Giới hạn số từ",
    },

    question_fillblanks: {
        add_answer: "Thêm đáp án",
        require_answers: "Hãy điền danh sách đáp án",
        is_require_uppercase: "Kiểm tra viết hoa/viết thường",
    },

    question_passage: {
        add_paragraph: "Thêm đoạn văn",
        add_question: "Thêm câu hỏi",
        heading: "Mô tả",
        title: "Tiêu đề",
        placeholder_heading: "Mô tả đoạn văn",
        placeholder_title: "Tiêu đề đoạn văn",
        require_question_data: "Dữ liệu câu hỏi không được để trống!",
        btn_toggle_note_extend: "Bấm vào mũi tên để mở rộng khung làm bài",
        btn_toggle_note_down: "Bấm vào mũi tên để thu hẹp khung làm bài",
        quick_add_question: "Thêm câu hỏi nhanh",
    },

    question_dragdrop: {
        add_answer: "Thêm đáp án",
        require_corresponding_answers: "Vui lòng điền đáp án tương ứng",
        require_answers: "Vui lòng điền đáp án",
    },

    question_sort: {
        add_answer: "Thêm đáp án",
        require_answers: "Hãy điền danh sách đáp án",
    },

    create_multiple_choice: {
        answer: "Đáp án",
        blank_answer: "Vui lòng điền đáp án",
        blank_result: "Vui lòng chọn ít nhất một đáp án đúng",
        save_as_draft_success: "Lưu thành công",
        save_as_draft_error: "Lưu bản nháp thất bại",
    },

    class: {
        class: "Lớp",
        list_class: "Danh sách lớp học",
        active: "Lớp học đang hoạt động",
        inactive: "Lớp học không hoạt động",
        code: "Mã lớp",
        name: "Tên lớp",
        subject: "Môn học",
        begin_date: "Ngày bắt đầu",
        end_date: "Ngày kết thúc",
        owner: "Chủ sở hữu",
        teacher: "Giáo viên",
        teachers: "Giáo viên",
        total_student: "Số học sinh",
        no_teacher: "Chưa có giáo viên",
        load_more_class: "Xem thêm",
        add_class: "Thêm lớp",
        update_class: "Cập nhật thông tin lớp",
        add_student: "Thêm học viên",
        add_teacher: "Thêm giáo viên",
        invite_student: "Mời học viên",
        invite_teacher: "Mời giáo viên",
        select_student: "Chọn học viên",
        select_teacher: "Chọn giáo viên",
        upload_student_list: "Tải lên danh sách học sinh",
        manage_class: "Quản lý lớp",
        manage_student: "Quản lý học sinh",
        manage_exam: "Quản lý bài được giao",
        student_name: "Tên học sinh",
        phone_number: "Số điện thoại",
        email: "Email",
        gender: "Giới tính",
        male: "Nam",
        female: "Nữ",
        unknown: "Không rõ",
        status: "Trạng thái",
        password: "Mật khẩu",
        add_exam: "Tạo bài kiểm tra mới",
        assign_exam: "Giao bài",
        exam_name: "Tên bài kiểm tra",
        exam_name2: "Tên",
        exam_name_description: "Là tên mới mà bạn muốn đặt cho đề, hoặc tên mặc định của đề",
        asgmt_name: "Tên bài tập",
        max_score: "Điểm tối đa",
        submission: "Đã nộp bài",
        graded: "Đã chấm điểm",
        edit_list: "Chỉnh sửa danh sách",
        done: "Xong",
        student_amount: "Tổng số học sinh",
        confirm_delete: "Bạn có chắc chắn muốn xóa?",
        will_delete: "sẽ bị xóa khỏi danh sách. Bạn có chắc chắn?",
        input_class_code: "Nhập mã lớp",
        input_class_name: "Nhập tên lớp",
        input_begin_date: "Nhập ngày bắt đầu",
        input_end_date: "Nhập ngày kết thúc",
        select_subjects: "Chọn môn học",
        input_teacher_name: "Nhập tên giáo viên",
        input_exam_name: "Nhập tên mới cho bài kiểm tra",
        input_asgmt_name: "Nhập tên mới cho bài tập",
        select_exam: "Chọn bài kiểm tra",
        select_asgmt: "Chọn bài tập",
        select_class: "Chọn lớp",
        find_and_select_class: "Tìm và chọn lớp",
        duration: "Thời gian làm bài",
        input_time: "Phút",
        exam: "Bài kiểm tra",
        assignment_name: "Tên bài tập",
        ownership: "Quyền sở hữu",
        all: "Tất cả",
        me: "Tôi",
        mark_assignment: "Thao tác",
        learning_progress: "Tiến độ học tập",
        report: "Báo cáo",
        start_time: "Thời gian bắt đầu",
        end_time: "Thời gian nộp bài",
        time_not_limited: "Không thời hạn",
        count_correct_sentences: "Số câu đúng",
        // Actions:
        find_class: "Tìm lớp học",
        leave_class: "Rời khỏi lớp",
        remove_student: "Xóa học viên",
        remove_teacher: "Xóa giáo viên",
        confirm_leave_class: "Bạn có chắc muốn rời khỏi lớp?",
        confirm_remove_student: "Bạn có chắc muốn xóa học viên?",
        confirm_remove_teacher: "Bạn có chắc muốn xóa giáo viên?",
        change_class_image: "Thay đổi ảnh lớp",
        add_user: "Thêm người dùng",
        import_user: "Nhập hàng loạt",
        // Invitation:
        send_invitation: "Mời",
        send_invitation_success: "Gửi lời mời thành công",
        send_invitation_error: "Gửi lời mời thất bại",
        you_are_not_teacher: "Bạn không phải giáo viên",
        you_are_not_student: "Bạn không phải học viên",
        // Invite teacher:
        teacher_email: "Email giáo viên",
        add_teacher_not_others: "Chỉ giáo viên mới được tham gia",
        add_teacher_success: "Thêm giáo viên thành công",
        add_teacher_error: "Không thể thêm giáo viên",
        // Invite student:
        add_student_not_others: "Chỉ học viên mới được tham gia",
        add_student_success: "Thêm học viên thành công",
        add_student_error: "Không thể thêm học viên",

        //branch
        branch: "Chi nhánh",
        select_branch: "Chọn chi nhánh",
        organization: "Tổ chức",

        no_class: "Không có lớp học nào",

        intended_class: "Lớp dự kiến",
        intended_class_note: "Lớp dự kiến là lớp dùng để tổ chức các cuộc thi phân loại học sinh vào lớp chính thức",
        official_class: "Lớp chính thức",
        merge_class: "Gộp lớp",
        class_type: "Loại lớp",
        select_class_type: "Chọn loại lớp",
    },

    statistical: {
        overview: "Tổng quan",
        student: "Học sinh",
        question: "Câu hỏi",
        choose_all: "CHỌN TẤT CẢ",
        filter_by_status: "LỌC THEO TRẠNG THÁI",
        assigned_student: "Học sinh được giao",
        not_submit: "Chưa nộp bài",
        doing: "Đang làm",
        submitted: "Đã nộp bài",
        graded: "Đã chấm điểm",
        progress: "Bài làm",
        submit_at: "Nộp bài lúc",
        question_grade: "Điểm của câu",
    },

    question_bank: {
        done: "Xong",
        draft: "Bản nháp",
        view: "Xem",
        copy: "Sao chép",
        choose_option: "Chọn một tùy chọn",
        personal_learning_progress: "Tiến độ học tập cá nhân",
    },

    exam_bank: {
        name: "Tên",
        owner: "Người tạo",
        list_question: "Danh sách câu hỏi",
        add_question: "Thêm câu hỏi",
        total_score: "Tổng điểm",
        add_question_to_exam: "Thêm câu hỏi vào đề bài",
        question_list_is_empty: "Danh sách câu hỏi trống",
        score_is_empty: "Điểm câu hỏi không được để trống",
        create_question: "Tạo câu hỏi mới",
        up: "Lên",
        down: "Xuống",
    },

    exam_result: {
        count_correct_answers: "Tổng số câu đúng",
        your_assignment: "Bài làm của bạn",
        comment: "Nhận xét",
        teacher_comment: "Nhận xét của giáo viên",
        no_comment: "Chưa có nhận xét",
    },

    multiple_choice: {
        index: "Câu",
    },

    message: {
        content_highlight_not_valid: "Nội dung highlight không hợp lệ",
        please_choose_correct_answer: "Vui lòng chọn đáp án",
        you_have_chosen_this_correct_answer: "Bạn đã chọn đúng đáp án",
        empty_data: "Không có dữ liệu",
        warning_missing_fields: "Bạn phải nhập đầy đủ thông tin",
        warning_duplicate_answer: "Bạn đang sử dụng hai đáp án giống hệt nhau",
        warning_duplicate_title: "Bạn đang sử dụng hai tiêu đề giống hệt nhau",
        warning_duplicate_group: "Bạn đang sử dụng hai nhóm giống hệt nhau",
        warning_answer_duplication_found: "Tìm thấy câu trả lời bị lặp",
        warning_answer_empty: "Đáp án không được bỏ trống",
        warning_require_field: "Thông tin bắt buộc",
        warning_require_question: "Đề cần có ít nhất một câu hỏi",
        title_require_more_than_two: "Tiêu đề cần phải nhiều hơn hai",
        required: "Trường này không được bỏ trống!",
        free_text: "Vui lòng nhập hoặc xóa trường này.",
        min0: "Giá trị nhập vào phải lớn hơn 0!",
        not_found: "Không tìm thấy dữ liệu!",
        add_success: "Thêm mới thành công!",
        add_error: "Thêm mới thất bại!",
        update_success: "Cập nhật thành công!",
        update_error: "Cập nhật thất bại!",
        delete_success: "Xóa thành công!",
        delete_error: "Xóa thất bại!",
        submit_success: "Gửi thành công!",
        submit_error: "Gửi thất bại!",
        sth_wrong: "Đã xảy ra lỗi không xác định!",
        clone_success: "Sao chép thành công!",
        clone_error: "Sao chép thất bại!",
        password_rule: "Mật khẩu phải có ít nhất 4 ký tự",
        password_not_match: "Mật khẩu không khớp",
        invalid_email: "Email không hợp lệ",
        register_success: "Đăng ký thành công!",
        register_error: "Đăng ký thất bại!",
        verify_success: "Xác nhận thành công!",
        verify_error: "Xác nhận thất bại!",
        login_error: "Sai tài khoản hoặc mật khẩu!",
        login_fail: "Đăng nhập thất bại",
        third_party_login_error: "Đăng nhập thất bại!",
        err_begin_date_is_greater: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc!",
        email_sent: "Email đã được gửi!",
        reset_success: "Thay đổi mật khẩu thành công!",
        reset_error: "Thay đổi mật khẩu thất bại!",
        mark_success: "Chấm điểm thành công!",
        mark_error: "Chấm điểm thất bại!",
        login_success: "Đăng nhập thành công!",
        login_demo_account_success: "Đăng nhập thành công (tài khoản thử nghiệm)!",
        login_demo_account_error: "Lỗi đăng nhập với tài khoản thử nghiệm!",
        logout_success: "Đăng xuất thành công!",
        please_check_role: "Vui lòng chọn vai trò!",
        require_less_than_answers: "Đáp án phải có ít nhất là hai",
        require_less_than_groups: "Nhóm phải có ít nhất là hai",
        require_fill_in_the_blank: "Phải có ít nhất một ô trống",
        // Question:
        answer_empty: "Câu trả lời đã bị bỏ trống",
        // File manager:
        file_extension_supported: "Định dạng file được hỗ trợ",
        please_try_again_later: "Xin hãy thử lại sau",
        please_try_another_file_extension: "Xin hãy thử file có định dạng khác",
        err_can_not_upload_files: "Lỗi không thể tải tệp lên",
        err_file_extension_not_valid: "File không hợp lệ",
        system_error: "Lỗi hệ thống",
        an_error_occurred: "Đã xảy ra lỗi",
    },

    table: {
        actions: "Hành động",
    },

    profile: {
        profile: "Hồ sơ",
        full_name: "Họ và tên",
        name: "Tên",
        gender: "Giới tính",
        gender_male: "Nam",
        gender_female: "Nữ",
        role: "Vai trò",
        role_student: "Học sinh",
        role_teacher: "Giáo viên",
        phone: "Số điện thoại",
        active: "Trạng thái",
        active_false: "Không hoạt động",
        active_true: "Hoạt động",
        update_profile: "Chỉnh sửa profile",
        first_name: "Tên",
        last_name: "Họ",
        phone_number: "Số điện thoại",
        email: "Email",
        address: "Địa chỉ",
        password: "Mật khẩu",
        new_password: "Mật khẩu mới",
        confirm_newPassword: "Nhập lại mật khẩu mới",
        cancel: "Hủy",
        save_profile: "Lưu profile",
        update_success: "Cập nhật profile thành công",
        update_error: "Cập nhật profile thất bại",
        please_input_password: "Vui lòng nhập vào mật khẩu",
        please_input_password_new: "Vui lòng nhập vào mật khẩu mới",
        please_input_password_confirm: "Vui lòng nhập vào xác nhận mật khẩu mới",
        please_input_phone: "Vui lòng nhập vào số điện thoại",
        wrong_password: "Sai mật khẩu",
        select_gender: "Chọn giới tính",
        select_active: "Chọn trạng thái tài khoản",
        please_enter_minimum_character: "Vui lòng nhập ít nhất 4 ký tự",
        field_is_require: "Trường này là bắt buộc",
        change_password: "Đổi mật khẩu",
    },

    score: {
        donot: "Chưa làm",
        submited: "Đã làm",
        marked: "Đã có điểm",
        class: "Lớp",
        subject: "Môn",
        deadline: "Hạn nộp",
        status: "Trạng thái",
        listAssignment: "Danh sách bài được giao",
        assignments: "Bài tập được giao",
        do_homework: "Làm bài",
        review: "Xem lại",
        correct_answers: "Đáp án đúng",
        your_answers: "Đáp án của bạn",
    },

    assignment: {
        donot: "Chưa làm",
        submited: "Đã làm",
        marked: "Đã có điểm",
        expired: "Đã hết hạn",
        not_start: "Chưa bắt đầu",
        class: "Lớp",
        class_code: "Mã lớp",
        subject: "Môn",
        deadline: "Hạn nộp",
        start_from: "Bắt đầu từ",
        status: "Trạng thái",
        listAssignment: "Danh sách bài được giao",
        your_score: "Điểm số của bạn",
        assignments: "Bài tập được giao",
        do_homework: "Làm bài",
        review: "Xem lại",
        doing: "Đang làm",
        score: "Điểm",
        invite_link: "Link mời",
        invite_via_link: "Mời qua link",
        add_user_successfully: "Bạn đã được thêm vào bài kiểm tra!",
        assignment_teacher_not_allowed: "Giáo viên không được tham dự bài kiểm tra!",
        assignment_not_existed: "Bài kiểm tra không tồn tại!",
        choose_subject: "Chọn môn học",
        choose_class: "Chọn lớp",
        choose_status: "Chọn trạng thái",
        filter_option: "Tùy chọn bộ lọc",
        filter: "Bộ lọc",
        choose_owner: "Chọn chủ nhiệm",
        owner: "Chủ nhiệm",
        correct: "Đúng",
        correct_nearly: "Đúng một phần",
        incorrect: "Sai",
        mark_by_hand: "Chấm tay",
        skip: "Bỏ qua",
        time_spent: "Thời gian",
        load_more: "Tải thêm",
        no_assignment: "Không có bài tập nào!",
        exam_note:
            "Lưu ý: <strong>học sinh chỉ được nộp bài 1 lần.</strong> Nếu quá thời hạn nộp bài, học sinh vẫn được nộp nhưng hệ thống sẽ ghi nhận là nộp trễ.",
        assignment_note:
            "Lưu ý: <strong>không giới hạn số lần nộp bài.</strong> Hệ thống sẽ ghi nhận lại kết quả làm bài của lần nộp bài gần nhất",
        exam: "Bài kiểm tra",
        assignment: "Bài tập",
        redo_homework: "Làm lại",
        count_submit: "Số lần nộp",
        select_type_assignment: "Chọn dạng bài tập",
        all: "Tất cả",
        continue_doing: "Tiếp tục làm",
        result: "Kết quả",
        havent_take_test: "Bạn chưa làm bài này",
        continue: "Làm tiếp",
        submit: "Nộp bài",
        count_submit_exercise: "Số lượt nộp bài",
        submited_lately: "Nộp trễ",
        marked_and_submited_lately: "Đã chấm (Nộp trễ)",
        create_from_scratch: "Tạo từ đầu",
        select_question_from_q_bank: "Chọn câu hỏi từ ngân hàng câu hỏi để tạo đề.",
        create_from_pdf: "Tạo từ PDF",
        upload_pdf_and_create: "Tải lên tài liệu PDF của bạn và tiến hành tạo đề.",
    },

    classes: {
        your_class_list: "Danh sách lớp của bạn",
        view_detail: "Xem chi tiết",
        owner: "Chủ nhiệm",
    },

    test: {
        submit_record_success: "Nộp bài thành công",
        submit_record_error: "Có lỗi xảy ra khi nộp bài",
        submit: "Nộp bài",
        confirm_submit: "Bạn có chắc muốn nộp bài ?",
        time_remaining: "Thời gian còn lại",
        cancel: "Hủy bỏ",
        exit: "Thoát",
        confirm_exit: "Bạn có chắc muốn thoát ?",
        timeout_test: "Đã hết thời giàn làm bài, bạn không thể thay đổi đáp án.",
    },

    report: {
        export_pdf: "Xuất pdf",
        question_number: "Câu hỏi số",
        select_question: "Chọn câu hỏi",
        classification: "Xếp loại",
        very_good: "Xếp loại giỏi",
        good: "Xếp loại khá",
        ordinary: "Xếp loại trung bình",
        failed: "Trượt",
        course_completion_score: "Số điểm hoàn thành môn",
        complete_progress: "Tiến độ hoàn thành",
        ratio: "Tỉ lệ",
        dateFrom: "Từ ngày",
        dateTo: "Đến ngày",
        subject: "Môn",
        class: "Lớp",
        test_name: "Tên bài kiểm tra",
        subject_completion_score: "Điểm hoàn thành môn",
        student_list: "Danh sách học sinh",
        please_select_question: "Vui lòng chọn câu hỏi",
        please_select_date: "Vui lòng chọn ngày",
        please_select_subject: "Vui lòng chọn môn",
        please_select_class: "Vui lòng chọn lớp",
        please_select_student: "Vui lòng chọn học sinh",
        please_select_test_name: "Vui lòng chọn tên bài kiểm tra",
        apply: "Áp dụng",
        cancel: "Hủy",
        filter: "Bộ lọc",
        personal_learning_progress: "Tiến độ học tập cá nhân",
        general_learning_progress: "Tiến độ học tập chung",
        test_classification: "Phân loại bài kiểm tra",
        rate_tests_taken: "Xếp loại các bài kiểm tra đã làm",
        name_of_class: "Tên lớp học",
        subject2: "Môn học",
        test: "Bài kiểm tra",
        average_score: "Điểm trung bình",
        grasp: "Nắm vững",
        all_classes: "Tất cả các lớp",
        all_subject: "Tất cả các môn",
        all: "Tất cả",
        progress: "Tiến độ",
        personal_learning: "Học tập cá nhân",
        general_learning: "Học tập chung",
        student_name: "Tên học sinh",
        score: "Điểm số",
        select_student: "Chọn học sinh",
        is_student_submitted: "Đã nộp bài",
        is_student_not_submitted: "Chưa nộp bài",
        is_student_not_started: "Chưa bắt đầu",
        is_teacher_submitted: "Đã chấm điểm",
        is_teacher_not_submitted: "Chưa chấm điểm",
        student_submitted: "Học sinh đã nộp bài",
        student_not_submitted: "Học sinh chưa nộp bài",
        warning_select_student: "Chưa chọn học sinh cần thống kê hoặc học sinh bạn chọn chưa có dữ liệu.",
        student: "Học sinh",
        view_detail: "Chi tiết",
        empty_data: "Không có dữ liệu",
        number_of_students: "Số học sinh",
        total_time_spent_in_seconds: "Tổng thời gian (giây)",
        average_time_spent_in_seconds: "Thời gian trung bình (giây)",
        grade_of_question: "Điểm câu hỏi",
        assignment: "Bài kiểm tra",
        exam: "Bài kiểm tra",
        message_no_one_has_done_the_homework: "Chưa có học sinh nào làm bài, không thể thống kê",
        message_student_have_not_selected: "Chưa chọn học sinh để thống kê hoặc học sinh cần thống kê không có dữ liệu",
        message_general_data_not_found: "Chưa chọn lớp cần thống kê hoặc lớp cần thống kê chưa có dữ liệu",
        // Fields:
        asgmt_datefrom: "Ngày bắt đầu bài kiểm tra",
        asgmt_dateto: "Ngày kết thúc bài kiểm tra",
        // Titles:
        learning_progress_report: "Báo cáo tiến độ học tập",
        learning_progress_general: "Tiến độ học tập chung",
        learning_progress_personal: "Tiến độ học tập cá nhân",
        not_submited: "Không nộp bài",
        is_good: "Có sự tiến bộ",
        not_good: "Không tiến bộ",
        equal: "Bình thường",
    },

    userBoard: {
        full_name: "Họ và tên",
        class: "Lớp",
        subject: "Môn",
    },

    tag: {
        tag: "Thẻ",
        add_tag: "Thêm thẻ",
        create_tag: "Tạo thẻ mới",
        add_tag_success: "Thêm thẻ thành công",
        add_tag_error: "Thêm thẻ thất bại",
        remove_tag_success: "Xóa thẻ thành công",
        remove_tag_error: "Xóa thẻ thất bại",
    },

    editor: {
        attach_files: "Đính kèm tệp",
        create_blank: "Tạo ô trống",
        blank: "Ô trống",
        highlight: "Highlight",
        select_audio_visible_type: "Chọn loại hiển thị cho tệp âm thanh",
        audio_type_icon: "Biểu tượng",
        audio_type_player: "Thanh công cụ",
        insert_or_edit_image: "Chèn/sửa ảnh",
        insert_or_edit_media: "Chèn/sửa đa phương tiện",
        insert_or_edit_image_1: "Nhúng ảnh",
        insert_or_edit_media_1: "Nhúng video",
        // Plugins - paste-options:
        paste_options: "Tùy chọn dán",
        paste_option_keep_text_only: "Chỉ giữ văn bản",
        paste_option_keep_source_formatting: "Giữ định dạng nguồn",
        // Plugins - math:
        standard_keypads: "Standard keypads",
        numbers_only: "Numbers only",
        numbers_n_basic_symbols: "Numbers and basic symbols",
        basic: "Basic",
        basic_wo_numbers: "Basic without numbers",
        intermediate: "Intermediate",
        intermediate_wo_numbers: "Intermediate without numbers",
        matrices: "Matrices",
        matrices_basic: "Matrices (basic)",
        trignometry: "Trignometry",
        geometry: "Geometry",
        chemistry: "Chemistry",
        units_SI: "Units (SI)",
        units_US: "Units (US)",
        full: "Full",
        // Plugins - wordcount:
        wordcountmax_reached: "Bạn đã nhập quá giới hạn số từ được cho phép",
    },

    file_manager: {
        // Fields:
        folder: "Thư mục",
        // Actions:
        rename: "Đổi tên",
        add_folder: "Thêm thư mục",
        add_files: "Thêm files",
        move_to: "Di chuyển tới",
        delete: "Xóa",
        input_folder_name: "Nhập tên thư mục",
        // Messages:
        require_folder: "Bạn phải chọn một thư mục!",
        rename_file_success: "Đã thay đổi tên tệp",
        rename_folder_success: "Đã thay đổi tên thư mục",
    },

    my_storage: {
        my_storage: "Quản lý lưu trữ",
        new_folder: "Thư mục mới",
        view_file: "Xem tệp",
        view_file_error: "Không thể xem được tệp!",
        space_used: "Đang dùng",
        space_left: "Còn lại",
        space_usage: "Dung lượng lưu trữ",
        total: "Tổng",
        used: "Dùng",
        left: "Khả dụng",
        filemanager_loading: "Đang tải",
        filemanager_uploading: "Đang tải lên",
        filemanager_removing: "Đang xóa",
        // Messages:
        space_used_note_for_org: "Là tổng dung lượng đã được sử dụng và đã cấp",
    },

    organization: {
        // Fields:
        name: "Tên",
        avatar: "Ảnh đại diện",
        avatar_desc: "Hình ảnh đại diện chính cho tổ chức của bạn",
        organization_details: "Thông tin chi tiết",
        organization_overview: "Tổng quan",
        owner: "Chủ sở hữu",
        branches: "Chi nhánh",
        classes: "Lớp",
        total_branches: "Số chi nhánh",
        total_classes: "Số lớp học",
        total_employees: "Số nhân viên",
        total_teachers: "Số giáo viên",
        total_students: "Số học sinh",
        your_organization: "Tổ chức của bạn",
        your_group: "Nhóm của bạn",
        personal: "Cá nhân",
        // Actions:
        pick_login_option: "Hãy chọn một tùy chọn đăng nhập dưới đây...",
        remove_user_employee_role: "Xóa vai trò nhân viên",
        // Messages:
        you_are_not_teacher: "Only teachers can accept this invitation!",
        you_are_not_student: "Only students can accept this invitation!",
        teacher_detail_roles_description: "Giáo viên sẽ trở thành nhân viên nếu được gán vai trò",
        // Alert:
        confirm_remove_user: "Bạn có chắc muốn xóa người dùng?",
        confirm_remove_user_employee_role: "Bạn có chắc muốn loại bỏ vai trò nhân viên của người dùng?",

        organization: "Tổ chức",
        organization_personal: "Cá nhân",
        tablecol_user: "Người dùng",
        find_user: "Tìm người dùng",
        add_user: "Thêm người dùng",
        import_user_success: "Tải file lên thành công, quá trình import dữ liệu có thể mất vài phút hoặc lâu hơn",
        add_user_fail: "Thêm người dùng không thành công",
        export: "Xuất file",
        // Invite teacher:
        user_email: "Email người dùng",
        send_invitation: "Mời",
        send_invitation_success: "Gửi lời mời thành công",
        send_invitation_error: "Gửi lời mời thất bại",
        add_teacher_not_others: "Chỉ giáo viên mới được tham gia",
        add_teacher_success: "Thêm giáo viên thành công",
        add_teacher_error: "Không thể thêm giáo viên",
        //storage
        storage: "Dung lượng",
        storage_used: "Dung lượng đã dùng",
        storage_available: "Dung lượng khả dụng",
        over_storage: "Không đủ dung lượng cấp",
        storage_error: "Lỗi hệ thống",
        set_storage_success: "Cấp dung lượng thành công",
        // Actions:
        add_employee: "Thêm nhân viên",

        //manage user
        full_name: "Họ và tên",
        email: "Email",
        status: "Trạng thái",
        first_name: "Họ",
        last_name: "Tên",
        gender: "Giới tính",
        phone: "Điện thoại",
        address: "Địa chỉ",
        male: "Nam",
        female: "Nữ",
        organization_role: "Vai trò",
        invite_employee: "Mời nhân viên",
        code: "Mã lớp",
        class: "Lớp",
        begin_date: "Ngày bắt đầu",
        end_date: "Ngày kết thúc",
        branch: "Chi nhánh",
        class_list: "Danh sách lớp",
        select_class: "Chọn lớp",
        total_class: "Tổng lớp dạy",
        total_student: "Tổng số học sinh dạy",
        class_name: "Tên lớp",
        invite_teacher: "Mời giáo viên",
        update_user: "Cập nhật người dùng",
        detail: "Chi tiết",
        update_user_success: "Cập nhật người dùng thành công!",
        update_user_error: "Cập nhật người dùng thất bại!",
        remove_user: "Xóa người dùng ra khỏi tổ chức?",

        select_type: "Chọn loại",
        select_branch: "Chọn chi nhánh",
        type: "Loại",
        batch_import: "Nhập hàng loạt",
        download_file: "Tải mẫu file excel về để nhập hàng loạt",
        class_in_organization: "Lớp trong tổ chức",
        my_class: "Lớp của tôi",
        team: "Nhóm",
        team_personal: "Cá nhân",
    },

    branch: {
        branch: "Chi nhánh",
        list_branch: "Danh sách chi nhánh",
        all_branch: "Tất cả chi nhánh",
        active_branch: "Chi nhánh đang hoạt động",
        inactive_branch: "Chi nhánh dừng hoạt động",
        add_branch: "Thêm chi nhánh",
        update_branch: "Cập nhật chi nhánh",
        select_branch: "Chọn chi nhánh",
        name: "Tên chi nhánh",
        input_name: "Nhập tên chi nhánh",
        status: "Trạng thái",
        select_status: "Chọn trạng thái",
        active: "Hoạt động",
        inactive: "Không hoạt động",
        creator: "Người tạo",
        input_branch_name: "Nhập tên chi nhánh!",
    },

    notification: {
        notifications: "Thông báo",
        read: "Đọc",
        unread: "Chưa đọc",
        unread_messages: "Thông báo chưa đọc",
        // Actions:
        mark_all_as_read: "Đánh dấu tất cả là đã đọc",
        // Message:
        list_end: "Không còn kết quả để hiển thị!",
    },

    account: {
        premium: "Premium",
        organization: "Tổ chức",
        subscription_plan_best_value: "Tối ưu",
        current_account: "Tài khoản hiện tại",
        // Actions:
        upgrade_now: "Nâng cấp ngay",
        contact_support: "Liên hệ tư vấn",
        pick_your_suitable_subscription_plan: "Chọn loại tài khoản hợp với bạn",
    },

    plan: {
        plan_name: "Tên gói",
        disabled: "Không kích hoạt",
        price: "Giá",
    },

    price: {
        amount: "Số tiền",
        total: "Tổng tiền",
        paid: "Đã thanh toán",
        vnd: "₫",
        vnd_per_month: "đ/tháng",
        free: "Miễn phí",
        month: "tháng",
    },

    payment: {
        payment: "Thanh toán",
        order: "Đơn hàng",
        order_total: "Thành tiền",
        promotion: "Mã giảm giá",
        payment_result: "Kết quả thanh toán",
        method_CASH: "Thanh toán bằng tiền mặt",
        method_TRANSFER: "Chuyển khoản ngân hàng",
        method_VISA: "Thanh toán VISA",
        method_ATM_VNPAY: "Thẻ ATM và tài khoản ngân hàng",
        method_VNMART: "Thanh toán với ví điện tử VNPAY",
        method_VNPAYQR: "Thanh toán quét mã VNPAY QR",
        method_PORTONE: "Thanh toán Online",
        method_ONEPAY_QR: "Thanh toán QR",
        method_ONEPAY_DOMESTIC: "Thẻ nội địa và tài khoản ngân hàng",
        method_ONEPAY_INTERNATIONAL: "Thẻ thanh toán quốc tế",
        bank: "Ngân hàng",
        account_name: "Tên tài khoản",
        account_number: "Số tài khoản",
        payment_content: "Nội dung chuyển khoản",
        // Description:
        method_CASH_please_make_payment_at: "Vui lòng đến thanh toán trực tiếp tại Phuong Nam Education.",
        method_CASH_payment_address: "Địa chỉ: 357 Lê Hồng Phong, P.2, Q.10, TP.HCM.",
        method_TRANSFER_description: "Ngay sau khi nhận được thanh toán của bạn, gói bạn chọn sẽ được kích hoạt.",
        method_PORTONE_description: "Hỗ trợ thanh toán qua ATM, VISA, MASTERCARD, ví điện tử.",
        // Actions:
        purchase: "Thanh toán",
        input_promotion_code_or_not: "Nhập mã giảm giá (nếu có)",
        select_plan_price: "Chọn một gói",
        // Messages:
        err_can_not_purchase: "Lỗi thanh toán",
        err_can_not_apply_promocode: "Lỗi áp dụng mã giảm giá",
        please_input_promocode: "Hãy nhập mã giảm giá",
        please_select_a_payment_method: "Hãy chọn phương thức thanh toán",
        cannot_apply_more_promocodes: "Bạn không thể áp dụng thêm mã giảm giá",
        sucess_make_payment_transaction: "Bạn đã hoàn tất thanh toán",
        fail_make_payment_transaction: "Bạn chưa hoàn tất thanh toán",
        discount: "Giảm giá",
        price: "Giá",
        PAY_ERROR: "Thanh toán thất bại",
        PAYED: "Đã thanh toán",
        NOT_PAYED: "Chưa thanh toán",
    },

    html_status_code: {
        page_404_title: "Không tìm thấy trang!",
        page_404_msg: "Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm",
        action_go_back_home: "Quay lại trang chủ",
    },

    zwibbler_toolbar: {
        helper: "Hướng dẫn",
        pick_tool_helper: "Chọn nút phù hợp để sử dụng chức năng.",
        brush_tool_helper: "Nhấn giữ chuột và kéo để vẽ hình.",
        line_tool_helper: "Nhấn để bắt đầu vẽ, nhấn đúp để kết thúc",
        shape_tool_helper: "Nhấn để bắt đầu vẽ, giữ chuột và kéo thả để vẽ hình.",
    },

    hotspot: {
        set_correct_answer: "Chọn đáp án đúng",
        drawing_areas: "Vẽ hình",
        node_must_be_closed: "Hình vẽ phải là hình khép kín",
        missing_correct_answer: "Bạn chưa chọn đáp án đúng",
    },

    shade: {
        row_count: "Số dòng",
        column_count: "Số cột",
        cell_width: "Chiều rộng của ô",
        cell_height: "Chiều cao của ô",
        lock_shade_cells: "Khóa ô đã chọn",
        by_location: "Theo vị trí",
        by_count: "Theo số lượng",
        unlockcell_note: "Chú ý: Bạn chỉ cần chọn số lượng ô vuông tương ứng với đáp án",
    },

    speech_to_text: {
        sentence: "Câu",
        word: "Từ",
        please_allow_microphone: "Vui lòng cho phép trình duyệt truy cập microphone",
        no_browser_support: "Trình duyệt này không hỗ trợ, vui lòng sử dụng trình duyệt khác",
        question: "Đề:",
        solution: "Đáp án của bạn:",
        language: "Ngôn ngữ",
        speaker: "Giọng đọc",
        select_speaker: "Chọn giọng đọc",
        tap_to_open_mic: "Nhấn để bật mic",
        tap_to_close_mic: "Nhấn để tắt mic",
    },

    //ranking and competitions
    ranking: {
        ranking: "Xếp hạng",
        ranking_table: "Bảng xếp hạng",
        ranking_type: "Loại xếp hạng",
        choose_ranking_type: "Chọn loại xếp hạng",
        by_point: "Theo điểm số",
        by_top: "Theo top",
        enter_the_number_point: "Nhập số điểm",
        enter_the_number_top: "Nhập top",
        top: "Top",
        point: "Điểm số",
        previous: "Bài kiểm tra trước đó",
        previous_condition: "Học sinh cần phải vượt qua bài này để được làm bài",
        passed: "Đậu",
        failed: "Rớt",
        score: "Điểm số",
        time: "Thời gian làm bài",
        rank: "Xếp hạng",
        choose_asignment_to_view_ranking: "Chọn một vòng thi để xem bảng xếp hạng",
        result: "Kết quả",
        list_of_competitions: "Danh sách các cuộc thi",
        add_new_competition: "Thêm cuộc thi mới",
        competition_name: "Tên cuộc thi",
        create_competition: "Tạo cuộc thi",
        update_competition: "Cập nhật cuộc thi",
        round: "Vòng",
        round_name: "Tên vòng thi",
        upcoming: "Sắp diễn ra",
        finished: "Đã kết thúc",
        happening: "Đang diễn ra",
        add_round: "Thêm vòng thi mới",
        not_graded: "Chưa nộp",
        donot: "Chưa làm bài",
        select_competition: "Chọn cuộc thi",
        view_result: "Xem kết quả",
        no_rounds: "Không có vòng thi nào",
        create_round: "Tạo vòng thi mới",
        update_round: "Cập nhật vòng thi",
        take_exam: "Vào làm bài",
        must_complete_previous_round: "Bạn cần hoàn thành vòng thi trước đó để làm bài này",
        enter_competition_name: "Nhập tên cuộc thi",
        not_submitted: "Chưa nộp",
        not_result: "Chưa có kết quả",
        err_rounds_time_begin: "Thời gian phải lớn hơn hoặc bằng thời gian bắt đầu của vòng thi trước đó",
        err_rounds_time_end: "Thời gian phải lớn hơn hoặc bằng thời gian kết thúc của vòng thi trước đó",
        err_rounds_time_between: "Thời gian phải nằm trong thời gian diễn ra cuộc thi",
        err_rounds_next_begin_time: "Thời gian phải nhỏ hơn thời gian bắt đầu của vòng thi tiếp theo",
        select_round: "Chọn vòng thi",
        round2: "Vòng thi",
        not_ranked: "Chưa xếp hạng",
        competition_type: "Loại cuộc thi",
        select_competition_type: "Chọn loại cuộc thi",
        official_competition: "Cuộc thi chính thức",
        note: "Lưu ý",
        assignment_note: "Lưu ý: Các đề có câu hỏi chấm tay sẽ không được sử dụng để tạo vòng thi",
    },
    feedback: {
        title: "Đóng góp ý kiến",
        subtitle:
            "Nếu bạn có ý kiến đóng góp, vui lòng nhập vào ô bên dưới. Cảm ơn bạn đã dành thời gian để góp ý cho sản phẩm của chúng tôi.",
        thanks:
            "Cảm ơn bạn đã dành thời gian để đóng góp ý kiến. Chúng tôi sẽ xem xét góp ý này để cải thiện sản phẩm và phản hồi cho bạn sớm nhất có thể.",
        send_feedback: "Gửi feedback",
    },

    dashboard: {
        total_student: "Tổng số học sinh",
        total_class: "Tổng số lớp học",
        total_created_class: "Tổng số lớp đã tạo",
        total_created_question: "Tổng số câu hỏi đã tạo",
        total_created_assignment: "Tổng bài đã giao",
        total_created_exam: "Tổng số đề đã tạo",
        total_created_competition: "Tổng số cuộc thi đã tạo",
    },

    setting: {
        code: "Mã code",
        subscription: "Gói",
        amount: "Thành tiền",
        expiration_date: "Ngày hết hạn",
        payment_method: "Phương thức thanh toán",
        payment_status: "Trạng thái thanh toán",
        payment_history: "Lịch sử thanh toán",
        your_teams_and_org: "Nhóm và tổ chức của bạn",
        alive: "Còn hạn",
        extend: "Gia hạn",
        select_plan: "Chọn gói",
    },

    entry_test: {
        entry_test: "Bài test đầu vào",
        exam: "Đề",
        select_exam: "Chọn đề",
        test_name: "Tên bài test đầu vào",
        input_test_name: "Nhập tên bài test đầu vào",
        entry_test_level: "Cấp độ đầu vào",
        level: "Cấp độ",
        score: "Điểm số",
        create_test: "Tạo bài test",
        submitted_marked: "Đã nộp/ Đã chấm",
        score_tooltip: "Số điểm tối thiểu để đạt được cấp độ này",
    },

    exam_pdf: {
        upload_pdf: "Tải lên tệp PDF của bạn",
        create_from_pdf: "Tạo đề từ PDF",
        upload_pdf_and_create: "Tải lên tài liệu định dạng PDF của bạn và tiến hành tạo đề",
        create_width_blank_page: "Tạo đề với trang trắng",
        create_width_blank_page_desc: "Bạn muốn tạo đề với trang trắng không có nội dung?",
        create_test: "Tạo đề",
        update_test: "Cập nhật",
        new_blank_page: "Thêm trang trắng",
        delete_page: "Xóa trang",
    },
};
