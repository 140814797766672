import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/components/Header";
import StudentTable from "./components/StudentTable";
import { useTranslation } from "react-i18next";
import "./ManageStudent.scss";
import { useEffect, useState } from "react";
import { setCurrentPage } from "src/reducers/general";

function ManageStudent(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentPage("organization"));
    }, []);

    return (
        <div className="organization-wrapper">
            <Header backEnable={false} backTitle={t("header.manage_student")} actions={[]} />
            <StudentTable />
        </div>
    );
}

export default ManageStudent;
