import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Avatar, Col, Form, Input, notification, Row } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import Icon from "src/modules/components/Icon";
import Header from "src/modules/components/Header";
import UploadFile from "src/modules/components/UploadFile";
import { default as CustomButton } from "src/modules/components/Button";
import { setCurrentPage } from "src/reducers/general";
import { fetchOrganizationDetails, updateOrganizationDetails } from "src/api/containers/organization";
import { getMe } from "src/api/containers/auth";
import { login } from "src/reducers/auth";
import "./OrganizationDetail.scss";

function OrganizationDetail(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [mode, setMode] = useState(false); // Values: "editing", true||false.
    const [loading, setLoading] = useState(false); // Values: "submitting", "fetch-org-details", true||false.
    const [data, setData] = useState({
        orgDetails: undefined,
        currSelectedFile: undefined,
    });

    const handleFetchOrgDetails = () => {
        const id = user?.currentOrg?.organization_id || user?.currentOrg?.team_id;
        if (id) {
            setLoading("fetch-org-details");
            fetchOrganizationDetails({ organization_id: id }).then((res) => {
                setLoading(false);
                if (res.status) {
                    if (user?.organization?.is_organization_owner || user?.currentOrg?.is_team_owner) {
                        setData({
                            ...data,
                            orgDetails: res.data,
                            currSelectedFile: res.data.image,
                        });
                        form.setFieldsValue({
                            name: res.data.name,
                            file_id: res.data.image?.id,
                        });
                    } else {
                        setData({
                            ...data,
                            orgDetails: res.data,
                        });
                    }
                }
            });
        }
    };

    const handleRefetchMe = () => {
        getMe(user?.paramsForOrgOrTeam?.organization_id).then((res) => {
            if (res?.status === true && res?.data?.user) {
                dispatch(login(res.data.user));
            } else {
                localStorage.removeItem("token");
                deleteCookie("token");
            }
        });
    };

    const handleEdit = (isEditing) => {
        setMode(isEditing ? "editing" : false);
    };

    const handleOk = () => {
        handleEdit(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setData({
            ...data,
            currSelectedFile: data.orgDetails?.image,
        });
        form.setFieldsValue({
            name: data.orgDetails?.name,
            file_id: data.orgDetails?.image?.id,
        });
        handleEdit(false);
    };

    const handleSubmitForm = (formData) => {
        if (user?.organization?.is_organization_owner || user?.currentOrg?.is_team_owner) {
            setLoading("submitting");
            // Make api calls:
            updateOrganizationDetails(user?.currentOrg?.organization_id || user?.currentOrg?.team_id, formData).then(
                (res) => {
                    setLoading(false);
                    if (res.status) {
                        notification.success({
                            message: t("message.update_success"),
                        });
                        handleFetchOrgDetails();
                        handleRefetchMe();
                        handleOk();
                    } else {
                        notification.error({
                            message: res.message || t("message.update_error"),
                        });
                    }
                }
            );
        }
    };

    const handleSelectFile = (selectedFile) => {
        if (selectedFile?.id && selectedFile?.src) {
            form.setFieldsValue({ file_id: selectedFile?.id });
            setData({
                ...data,
                currSelectedFile: selectedFile,
            });
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("organization-detail"));
        handleFetchOrgDetails();
    }, [user?.currentOrg?.scopeOptionKey]);

    const renderInfoOverview = () => {
        let valExtraClassNames = "";
        if (loading === "fetch-org-details") {
            valExtraClassNames += " skeleton-loading";
        }
        return (
            <>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.total_branches")}</div>
                    <div className={`item-value${valExtraClassNames}`}>{data.orgDetails?.branches?.length || "_"}</div>
                </div>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.total_classes")}</div>
                    <div className={`item-value${valExtraClassNames}`}>
                        {isNaN(data.orgDetails?.total_classes) === false ? data.orgDetails?.total_classes : "_"}
                    </div>
                </div>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.total_employees")}</div>
                    <div className={`item-value${valExtraClassNames}`}>
                        {isNaN(data.orgDetails?.total_classes) === false ? data.orgDetails?.total_employees : "_"}
                    </div>
                </div>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.total_teachers")}</div>
                    <div className={`item-value${valExtraClassNames}`}>
                        {isNaN(data.orgDetails?.total_classes) === false ? data.orgDetails?.total_teachers : "_"}
                    </div>
                </div>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.total_students")}</div>
                    <div className={`item-value${valExtraClassNames}`}>
                        {isNaN(data.orgDetails?.total_classes) === false ? data.orgDetails?.total_students : "_"}
                    </div>
                </div>
            </>
        );
    };

    const renderInfoBranches = () => {
        return (
            <div className="form-viewer-item layout-v">
                <div className="item-title">{t("organization.branches")}</div>
                <div className="item-value form-viewer-list list-wrapper">
                    <div className="list-branches">
                        {loading === "fetch-org-details"
                            ? Array(3)
                                  .fill()
                                  .map((_, itemIndex) => {
                                      return (
                                          <div key={`branch-${itemIndex}`} className="list-item skeleton-loading">
                                              <div className="list-item-title">_</div>
                                              <div className="list-item-info">_</div>
                                          </div>
                                      );
                                  })
                            : data.orgDetails?.branches?.map((item, itemIndex) => {
                                  return (
                                      <div key={`branch-${itemIndex}`} className="list-item">
                                          <div className="list-item-title">{item.name || "_"}</div>
                                          <div className="list-item-info">
                                              {item.active ? t("branch.active") : t("branch.inactive")}
                                          </div>
                                      </div>
                                  );
                              })}
                    </div>
                </div>
            </div>
        );
    };

    // const renderInfoClasses = () => {
    //     return (
    //         <div className="form-viewer-item layout-v">
    //             <div className="item-title">{t("organization.classes")}</div>
    //             <div className="item-value form-viewer-list list-wrapper">
    //                 <div className="list-classes">
    //                     {data.orgDetails?.classes?.map((item, itemIndex) => {
    //                         return (
    //                             <div key={`branch-${itemIndex}`} className="list-item">
    //                                 <div className="list-item-title">{item.name || "_"}</div>
    //                                 <div className="list-item-info">
    //                                     <div className="form-viewer-item">
    //                                         <div className="item-title">{t("class.code")}</div>
    //                                         <div className="item-value">{item?.code || "_"}</div>
    //                                     </div>
    //                                     <div className="form-viewer-item">
    //                                         <div className="item-title">{t("class.owner")}</div>
    //                                         <div className="item-value">{item?.owner?.name || "_"}</div>
    //                                     </div>
    //                                     <div className="form-viewer-item">
    //                                         <div className="item-title">{t("class.teachers")}</div>
    //                                         <div className="item-value">
    //                                             {item?.teachers?.map((teacher, teacherIndex) => {
    //                                                 return (
    //                                                     <span key={`teacher-${teacherIndex}`}>
    //                                                         {teacherIndex > 0 && teacherIndex < item.teachers.length ? (
    //                                                             <span>, </span>
    //                                                         ) : null}
    //                                                         <span>{teacher.name || "_"}</span>
    //                                                     </span>
    //                                                 );
    //                                             })}
    //                                         </div>
    //                                     </div>
    //                                     <div className="form-viewer-item">
    //                                         <div className="item-title">{t("class.total_student")}</div>
    //                                         <div className="item-value">{item?.total_student || "_"}</div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         );
    //                     })}
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    if (user?.currentOrg?.is_organization_owner || user.currentOrg?.is_team_owner) {
        return (
            <div className="organization-detail-wrapper">
                <Header backEnable={false} backTitle={t("header.organization_profile")} actions={[]} />

                <div className="organization-detail">
                    <Form
                        form={form}
                        onFinish={handleSubmitForm}
                        layout="vertical"
                        className="app-form type-default form-viewer"
                    >
                        <div>
                            <Row gutter={[8, 16]} className="form-viewer-section layout-row-center">
                                <Col xs={24} sm={24} md={8}>
                                    <UploadFile
                                        fileType="avatar"
                                        selectedFile={data.currSelectedFile || { icon: <TeamOutlined /> } || undefined}
                                        onChangeFile={handleSelectFile}
                                        readOnly={mode === false}
                                        isLoading={loading === "fetch-org-details"}
                                    />
                                    <Form.Item name="file_id" label={t("organization.avatar")} hidden>
                                        <Input className="app-input" placeholder={t("organization.avatar")} readOnly />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={16}>
                                    <div className="form-viewer-title">{t("organization.avatar")}</div>
                                    <div className="form-viewer-desc">{t("organization.avatar_desc")}</div>
                                </Col>
                            </Row>

                            <Row gutter={[8, 16]} className="form-viewer-section">
                                <Col xs={24} sm={24} md={8}>
                                    <div className="form-viewer-title highlight-with-singlebar">
                                        {t("organization.organization_overview")}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={16} className="container-for-fields">
                                    {renderInfoOverview()}
                                </Col>
                            </Row>

                            <Row gutter={[8, 16]} className="form-viewer-section">
                                <Col xs={24} sm={24} md={8}>
                                    <div className="form-viewer-title highlight-with-singlebar">
                                        {t("organization.organization_details")}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={16} className="container-for-boxes">
                                    <Form.Item
                                        name="name"
                                        label={t("organization.name")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={t("organization.name")}
                                            readOnly={mode === false}
                                        />
                                    </Form.Item>
                                    {renderInfoBranches()}
                                    {/* {renderInfoClasses()} */}
                                </Col>
                            </Row>
                        </div>

                        <div className="form-viewer-section">
                            <div className="btn-group for-organization-detail">
                                {mode === "editing" ? (
                                    <>
                                        <CustomButton
                                            type="grey"
                                            icon={<Icon name="icon-cross-thick" />}
                                            title={t("shared.cancel")}
                                            onClick={handleCancel}
                                        ></CustomButton>
                                        <CustomButton
                                            htmlType="submit"
                                            type="primary"
                                            icon={<Icon name="icon-tick" />}
                                            title={t("shared.save")}
                                            disabled={loading === "submitting"}
                                        ></CustomButton>
                                    </>
                                ) : (
                                    <>
                                        <CustomButton
                                            type="primary"
                                            icon={<Icon name="icon-edit" />}
                                            title={t("shared.edit")}
                                            onClick={() => {
                                                handleEdit(true);
                                            }}
                                        ></CustomButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    } else {
        return (
            <div className="organization-detail-wrapper view-as-guest">
                <Header backEnable={false} backTitle={t("header.organization_profile")} actions={[]} />

                <div className="organization-detail">
                    <div className="form-viewer layout-horizontal">
                        <Row gutter={[8, 16]} className="form-viewer-section layout-row-center">
                            <Col xs={24} sm={24} md={8}>
                                <div
                                    className={`org-avatar placeholder${
                                        ["fetch-org-details"].includes(loading) ? " " + "loading" : ""
                                    }`}
                                >
                                    {data.orgDetails?.image?.src ? (
                                        <Avatar className="app-avatar layout-full" src={data.orgDetails?.image.src} />
                                    ) : data.orgDetails?.image?.icon ? (
                                        <Avatar className="app-avatar layout-full" icon={data.orgDetails?.image.icon} />
                                    ) : null}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-fields">
                                <div className="form-viewer-item">
                                    <div className="item-title">{t("organization.name")}</div>
                                    <div className="item-value">{data.orgDetails?.name || "_"}</div>
                                </div>
                                <div className="form-viewer-item">
                                    <div className="item-title">{t("organization.owner")}</div>
                                    <div className="item-value">{data.orgDetails?.owner?.name || "_"}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[8, 16]} className="form-viewer-section">
                            <Col xs={24} sm={24} md={8}>
                                <div className="form-viewer-title highlight-with-singlebar">
                                    {t("organization.organization_overview")}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-fields">
                                {renderInfoOverview()}
                            </Col>
                        </Row>

                        <Row gutter={[8, 16]} className="form-viewer-section">
                            <Col xs={24} sm={24} md={8}>
                                <div className="form-viewer-title highlight-with-singlebar">
                                    {t("organization.organization_details")}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-boxes">
                                {renderInfoBranches()}
                                {/* {renderInfoClasses()} */}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrganizationDetail;
