import { Menu } from "antd";
import { EditOutlined, FolderOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./ContextMenu.scss";

const ContextMenu = ({ controller, onClickRename, onClickMoveTo }) => {
    const { t } = useTranslation();

    const handleClickRename = () => {
        if (onClickRename instanceof Function) {
            onClickRename();
        }
    };

    const handleClickMoveToFolder = () => {
        controller.call("movetomodal.show");

        if (onClickMoveTo instanceof Function) {
            onClickMoveTo();
        }
    };

    return (
        <div>
            <Menu
                className="fmitem-menu"
                selectedKeys={[]}
                items={[
                    {
                        key: "item-rename",
                        title: t("file_manager.rename"),
                        label: (
                            <span className="fmitem-menuitem-lbl">
                                <span className="item-icon">
                                    <EditOutlined />
                                </span>
                                <span className="item-name">{t("file_manager.rename")}</span>
                            </span>
                        ),
                        // disabled: false,
                        // icon: null,
                        onClick: handleClickRename,
                        className: "fmitem-menuitem",
                    },
                    {
                        key: "item-moveto",
                        title: t("file_manager.move_to"),
                        label: (
                            <span className="fmitem-menuitem-lbl">
                                <span className="item-icon">
                                    <FolderOutlined />
                                </span>
                                <span className="item-name">{t("file_manager.move_to")}</span>
                            </span>
                        ),
                        // disabled: false,
                        // icon: null,
                        onClick: handleClickMoveToFolder,
                        className: "fmitem-menuitem",
                    },
                ]}
            />
        </div>
    );
};

export default ContextMenu;
