import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import clsx from "clsx";
import render from "./editor";
import parser from "src/utils/parser";
import { editorHtmlStructure } from "src/utils/configEditor/_default";

const Editor = ({
    config,
    disabled,
    placeholder,
    value,
    onChange = () => {},
    onFocus = () => {},
    onKeydown = () => {},
}) => {
    const ref = useRef(null);
    const editor = useRef(null);
    const to__ = useRef();
    const value__ = useRef("");

    const onChange__ = useRef(onChange);
    const onFocus__ = useRef(onFocus);
    const onKeydown__ = useRef(onKeydown);

    const [loading, setLoading] = useState(true);
    const isDefault__ = useRef(null);

    const change = () => {
        clearTimeout(to__.current);
        to__.current = setTimeout(() => {
            /** Do trường hợp thêm ma trận vào Editor thì thẻ table sẽ sinh ra
             * nằm bên ngoài khi lấy nội dung với .getContent() nhưng thật chất thẻ table không nằm trong cây DOM do tinyMCE sinh ra
             * Để giải quyết trường hợp trên ta sẽ chỉ lấy phần tử nào nằm trong cây DOM. Thay thế bằng
             * getBody().innerHTML
             *
             * Nhưng sẽ gặp 1 trường hợp là khi không có nội dung bên trong editor
             *      + getBody().innerHTML có value là <br ...... />
             *      + getContent() có value là ""
             *
             * Vì tính chất trên nên khi validate trường hợp editor trống sẽ bị sai khi dùng getBody().innerHTML
             *
             *  */
            let content = editor.current.getBody().innerHTML; // Sẽ lấy những phần hiện thị trong cây DOM
            let content2 = editor.current.getContent(); // Sẽ validate những item không hợp lệ (bỏ thẻ <br />)

            // Tinymce sẽ thêm thẻ <br /> khi trong editor không có nội dung (Vì vậy cần phải xoá nó đi khi change)
            if (!content2) {
                content = "";
            }
            if (value__.current != content) {
                const newValue = parser.compactMathField(content);
                value__.current = newValue;
                onChange__.current(newValue);
            }
        });
    };

    const focus = (e) => {
        onFocus__.current(e);
    };

    const keydown = (e) => {
        onKeydown__.current(e);
    };

    useEffect(() => {
        onChange__.current = onChange;
    }, [onChange]);

    useEffect(() => {
        onFocus__.current = onFocus;
    }, [onFocus]);

    useEffect(() => {
        onKeydown__.current = onKeydown;
    }, [onKeydown]);

    useEffect(() => {
        if (editor.current) {
            return;
        }

        if (config) {
            placeholder && (config.placeholder = placeholder);
            config.readonly = disabled || false;
        }
        render(ref.current, config)
            .then(([e]) => {
                editor.current = e;
                editor.current.setContent(value);

                e.on("change", change);
                e.on("focus", change);
                e.on("keydown", change);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                throw new Error(err);
            });
    }, []);

    useEffect(() => {
        if (value !== value__.current) {
            if (!value) {
                value = "";
            }

            setTimeout(() => {
                if (loading === false) {
                    if (!isDefault__.current && value) {
                        editor.current.setContent(parser.parseMathField(value));
                        isDefault__.current = true;
                        return;
                    }
                    editor.current.setContent(value);
                }
            });
        }
    }, [value, loading]);

    return (
        <Spin spinning={loading}>
            <div
                id={config?.rootId}
                className={clsx(editorHtmlStructure.root.containerClassName, config?.rootClassName)}
            >
                {/* Editor's Inner Toolbar: */}
                {config?.fixed_toolbar_container ? (
                    <div className={editorHtmlStructure.toolbar.containerClassName}></div>
                ) : null}

                {/* Editor: */}
                <div ref={ref} id={config?.renderId} className={editorHtmlStructure.content.containerClassName}></div>
            </div>
        </Spin>
    );
};

export default Editor;
