import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import JXG from "jsxgraph";
import {
    CloseOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Row } from "antd";
import GraphElemMenu from "./components/GraphElemMenu";
import GraphElemConfigModal from "./components/GraphElemConfigModal";
import { default as CustomButton } from "src/modules/components/Button";
import Editor from "src/modules/components/Editor";
import NumberlineLabelDraggable from "./components/NumberlineLabelDraggable";
import HTMLDisplayer from "../Displayers/HTMLDisplayer";
import configEditor from "src/utils/configEditor";
import { graphElements, graphModes, graphTypes } from "./configs";
import {
    createGraphElementByData,
    getDataOfGraphElement,
    setCircleEvents,
    setPointEvents,
    setSegmentEvents,
    setTextEvents,
    removeGraphElementByData,
    updateGraphElementByData,
} from "./helpers";
import { roundToStepForArray } from "src/utils/helpers/number";
import { compareArrays, compareObjectsDeeply } from "src/utils/helpers/comparison";
import { ReactComponent as SvgBin } from "src/assets/images/app-icons/bin.svg";
import "./JSXGraphBoard.scss";

const JSXGraphBoard = ({
    graphMode = "creating",
    graphType = "default",
    id,
    width,
    height,
    boundingBox,
    coordsStep,
    value,
    isReadOnly = false,
    onChange,
}) => {
    // 1. Actions:
    const [currAction, setCurrAction] = useState(false);
    const currActionHandlers = useRef(null);

    // 2. Graph elements:
    // - Graph elements' reference:
    const graphObjs = useRef({ board: null });
    // - Queue of coords/points (the things queued up for use):
    const coordsInQueue = useRef([]);
    // - Temporary objects (the things to be used in the process of executing a graph board action):
    const tempObjs = useRef({ point: null, segment: null });
    const tempObjArrs = useRef({ points: [], segments: [] });

    // 3. Graph elements' data:
    const [graphElemDataList, setGraphElemDataList] = useState([]);

    // 4. Graph config:
    const [graphConfig, setGraphConfig] = useState({
        coordsStep: [1, 1], // To correct shape's position by rounding coordinates to the nearest (1, 1) or different values.
        elemDataIndex: -1, // The index of graphElemDataList's item that is selected and need to be created.
        pointNamesToBeCreated: [], // The list of names for a point that need to be created.
        pointCoordsToBeCreated: [], // The list of coords for a point that need to be created.
    });

    // 5. Graph helpers' reference (used for helpers):
    const graphHelperRefToConfig = useRef({});
    const graphHelperRefToEvents = useRef({});
    graphHelperRefToConfig.current.coordsStep = graphConfig.coordsStep;
    graphHelperRefToEvents.current.onUpdate = (element) => {
        if (isReadOnly) {
            return;
        }
        for (let i = 0; i < graphElemDataList.length; i++) {
            if (graphElemDataList[i].id === element?.id) {
                const graphElementsNew = [...graphElemDataList];
                const customConfig = {
                    type: graphElemDataList[i].type, // Used for all elements.
                    label: graphElemDataList[i].label, // Used for numberline_label.
                };
                graphElementsNew[i] = getDataOfGraphElement(element, customConfig);
                handleSetGraphElemDataList(graphElementsNew);
                break;
            }
        }
    };
    graphHelperRefToEvents.current.onRemoveValue = (element) => {
        if (isReadOnly) {
            return;
        }
        for (let i = 0; i < graphElemDataList.length; i++) {
            if (graphElemDataList[i].id === element?.id) {
                const graphElementsNew = [...graphElemDataList];
                handleCloseElementTag(graphElementsNew[i], i, "id&value");
                break;
            }
        }
    };

    // 6. Other variables & states:
    const isSolvingGeom = !isReadOnly && graphMode === "solving" && graphTypes[graphType].isAllowedGeometries;
    const isCreatingGeom = !isReadOnly && graphMode === "creating" && graphTypes[graphType].isAllowedGeometries;
    const isSolvingNlLbl = !isReadOnly && graphMode === "solving" && graphTypes[graphType].isAllowedNumberlineLabels;
    const isCreatingNlLbl = !isReadOnly && graphMode === "creating" && graphTypes[graphType].isAllowedNumberlineLabels;
    const enableElemUpdate = graphModes[graphMode]?.[graphType]?.enableElemUpdate || false;
    const enableElemDelete = graphModes[graphMode]?.[graphType]?.enableElemDelete || false;
    const editorConfigInput = useRef(configEditor.inputRich());
    const nextAct = useRef(false);
    const [values, setValues] = useState({
        showDropdown: false,
        showModal: false,
    });

    // (Methods) Actions:
    const handleChange = (_graphElemDataList) => {
        if (onChange instanceof Function) {
            onChange(_graphElemDataList);
        }
    };

    const handleSetAction = (actionName, isToggle = true) => {
        if (isToggle) {
            if (currAction === actionName) {
                setCurrAction(false);
            } else {
                setCurrAction(actionName);
            }
        } else {
            setCurrAction(actionName);
        }
    };

    // (Methods) Graph elements' data:
    const handleSetGraphElemDataList = (graphElemDataListNew) => {
        // Update states:
        setGraphElemDataList(graphElemDataListNew);
        // Trigger event handlers:
        handleChange(graphElemDataListNew);
    };

    const handleAddItemToGraphElemDataList = (graphElemDataListItem) => {
        if (typeof graphConfig.elemDataIndex === "number" && graphConfig.elemDataIndex > -1) {
            const graphElemDataListNew = [...graphElemDataList];
            graphElemDataListNew[graphConfig.elemDataIndex] = graphElemDataListItem;
            handleSetGraphElemDataList(graphElemDataListNew);
            handleSetAction(false);
            setGraphConfig({
                ...graphConfig,
                elemDataIndex: -1,
                pointNamesToBeCreated: [],
                pointCoordsToBeCreated: [],
            });
        } else {
            const graphElemDataListNew = [...graphElemDataList, graphElemDataListItem];
            handleSetGraphElemDataList(graphElemDataListNew);
            handleSetAction(false);
        }
    };

    const handleClickElementTag = (graphElemData, graphElemDataIndex) => {
        switch (graphMode) {
            case "creating": {
                /**
                 * What to do?
                 * Highlight the element corresponding with the data.
                 */
                break;
            }
            case "solving": {
                /**
                 * What to do?
                 * There are two cases:
                 * - If element for the data is not created yet, start creating it.
                 * - If element for the data is created, highlight it.
                 */
                if (graphElemData?.id) {
                    // Highlight the element.
                } else {
                    if (graphConfig.elemDataIndex === graphElemDataIndex) {
                        // Graph board action:
                        handleSetAction(false);
                        // Data used for the action:
                        setGraphConfig({
                            ...graphConfig,
                            elemDataIndex: -1,
                            pointNamesToBeCreated: [],
                        });
                    } else {
                        // Graph board action:
                        handleSetAction(graphElemData?.type, false);
                        // Data used for the action:
                        let pointNamesToBeCreatedNew;
                        if (graphElemData?.type === "point") {
                            pointNamesToBeCreatedNew = [graphElemData?.name];
                        } else {
                            pointNamesToBeCreatedNew = (graphElemData?.values || []).map((item) => item?.name);
                            if (graphElemData?.type === "polygon") {
                                graphElemData?.values?.length > 2 &&
                                    pointNamesToBeCreatedNew.push(graphElemData.values[0]?.name);
                            }
                        }
                        setGraphConfig({
                            ...graphConfig,
                            elemDataIndex: graphElemDataIndex,
                            pointNamesToBeCreated: pointNamesToBeCreatedNew,
                        });
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleCloseElementTag = (graphElemData, graphElemDataIndex, removeWhat = "all") => {
        switch (graphMode) {
            case "creating": {
                /**
                 * What to do?
                 * - Remove the element corresponding with the data.
                 * - Remove the data, too.
                 */
                const params = {
                    jxgBoard: graphObjs.current.board,
                };
                // Update graph board:
                removeGraphElementByData(graphElemData, params);
                // Update graph data:
                if (removeWhat === "all") {
                    const graphElemDataListNew = [...graphElemDataList];
                    graphElemDataListNew.splice(graphElemDataIndex, 1);
                    handleSetGraphElemDataList(graphElemDataListNew);
                } else if (removeWhat === "id&value") {
                    const graphElemDataListNew = [...graphElemDataList];
                    graphElemDataListNew[graphElemDataIndex] = structuredClone(
                        graphElemDataListNew[graphElemDataIndex]
                    );
                    const item = graphElemDataListNew[graphElemDataIndex];
                    delete item.id;
                    item.value && (item.value = undefined);
                    item.values && (item.values = []);
                    handleSetGraphElemDataList(graphElemDataListNew);
                }
                break;
            }
            case "solving": {
                /**
                 * What to do?
                 * - Remove the element corresponding with the data.
                 * - Update the data only.
                 */
                const params = {
                    jxgBoard: graphObjs.current.board,
                };
                // Update graph board:
                removeGraphElementByData(graphElemData, params);
                // Update graph data:
                const graphElemDataListNew = [...graphElemDataList];
                graphElemDataListNew[graphElemDataIndex] = structuredClone(graphElemDataListNew[graphElemDataIndex]);
                const elemData = graphElemDataListNew[graphElemDataIndex];
                switch (elemData?.type) {
                    case "point": {
                        delete elemData.id;
                        elemData.values = [];
                        break;
                    }
                    case "segment":
                    case "line":
                    case "polygon":
                    case "vector":
                    case "circle":
                    case "ellipse": {
                        delete elemData.id;
                        if (elemData.values instanceof Array) {
                            elemData.values.forEach((valItem) => {
                                valItem.id = undefined;
                                valItem.values = [];
                            });
                        }
                        break;
                    }
                    case "numberline_label": {
                        delete elemData.id;
                        elemData.value = undefined;
                        break;
                    }
                    default:
                        break;
                }
                handleSetGraphElemDataList(graphElemDataListNew);
                break;
            }
            default: {
                break;
            }
        }
    };

    // (Methods) Graph config:
    const setCoordsStep = () => {
        if (coordsStep) {
            return;
        }
        const coordStepX = graphObjs.current.board.defaultAxes.x.ticks[0].ticksDelta;
        const coordStepY = graphObjs.current.board.defaultAxes.y.ticks[0].ticksDelta;
        setGraphConfig({
            ...graphConfig,
            coordsStep: [coordStepX, coordStepY],
        });
    };

    // (Methods) Graph elements - Point:
    const createPoint = (coords, isTemp = false) => {
        const graphBoard = graphObjs.current.board;
        // 1. Preparation:
        const attrs = {};
        const graphConfigNew = { ...graphConfig };
        if (graphConfigNew.pointNamesToBeCreated.length) {
            attrs.name = graphConfigNew.pointNamesToBeCreated[0];
            // If the name is actually used, remove it:
            if (!isTemp) {
                const pointNamesToBeCreatedNew = [...graphConfigNew.pointNamesToBeCreated];
                pointNamesToBeCreatedNew.shift();
                graphConfigNew.pointNamesToBeCreated = pointNamesToBeCreatedNew;
            }
        }
        // 2. Update states:
        setGraphConfig(graphConfigNew);
        // 3. Create point:
        const point = graphBoard.create("point", coords, attrs);
        return point;
    };
    const handleCreatePoint = (event) => {
        const graphBoard = graphObjs.current.board;
        // 1. Create point:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        const point = createPoint(coordsRounded);
        // 2. Set up point's event handlers:
        const params = {
            jxgBoard: graphObjs.current.board,
            config: graphHelperRefToConfig.current,
            eventHandlers: graphHelperRefToEvents.current,
        };
        setPointEvents(point, undefined, params);
        // 3. Save point's data:
        handleAddItemToGraphElemDataList(getDataOfGraphElement(point));
    };

    // (Methods) Graph elements - Segment, line:
    const createSegment = (whatToCreate, listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const segmentOrLine = graphBoard.create(whatToCreate, listOfCoordsAndPoint);
        return segmentOrLine;
    };
    const handleCreateSegmentOrLine = (whatToCreate, event) => {
        if (whatToCreate !== "segment" && whatToCreate !== "line") {
            return;
        }
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Segment preview while moving):
        graphBoard.removeObject(tempObjs.current.point);
        graphBoard.removeObject(tempObjs.current.segment);
        tempObjs.current.point = null;
        tempObjs.current.segment = null;
        // 1. Create points & segment:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 1) {
            const point1 = coordsInQueue.current[0];
            const point2 = createPoint(coordsRounded);
            const segmentOrLine = createSegment(whatToCreate, [point1, point2]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            setPointEvents(segmentOrLine.point1, segmentOrLine, params);
            setPointEvents(segmentOrLine.point2, segmentOrLine, params);
            // 2.2. Set up segment's event handlers:
            setSegmentEvents(segmentOrLine, undefined, params);
            // 3. Save segment's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(segmentOrLine));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current = [point];
        }
    };
    const handleCreateSegmentOrLinePreview = (whatToCreate, event) => {
        if (whatToCreate !== "segment" && whatToCreate !== "line") {
            return;
        }
        const graphBoard = graphObjs.current.board;
        // Segment preview while moving:
        if (coordsInQueue.current.length === 1) {
            // Check if a temporary segment/line was created:
            // - If no, create it.
            // - If yes, move and preview it at the current mouse's position.
            if (!tempObjs.current.point && !tempObjs.current.segment) {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
                const point = createPoint(coordsRounded, true);
                // Segment (temporary):
                const coordsSegment = [...coordsInQueue.current, point];
                const segment = createSegment(whatToCreate, coordsSegment);
                // Data storing:
                tempObjs.current.point = point;
                tempObjs.current.segment = segment;
            } else {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                let pointTemp = null;
                if (tempObjs.current.point === tempObjs.current.segment.point1) {
                    pointTemp = tempObjs.current.segment.point1;
                } else if (tempObjs.current.point === tempObjs.current.segment.point2) {
                    pointTemp = tempObjs.current.segment.point2;
                }
                if (pointTemp) {
                    pointTemp.setPositionDirectly(JXG.COORDS_BY_USER, coords);
                    graphBoard.update();
                }
            }
        }
    };

    // (Methods) Graph elements - Polygon:
    const createPolygon = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const polygon = graphBoard.create("polygon", listOfCoordsAndPoint);
        return polygon;
    };
    const handleCreatePolygon = (event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Polygon's segment preview while moving):
        graphBoard.removeObject(tempObjs.current.point);
        graphBoard.removeObject(tempObjs.current.segment);
        tempObjs.current.point = null;
        tempObjs.current.segment = null;
        // Check if the first polygon's point is clicked:
        // - If yes, finish creating polygon.
        // - If no, create a new polygon's point.
        let isFinished = false;
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length) {
            const pointFirst = coordsInQueue.current[0];
            // Compare the first point with the current position:
            if (compareArrays(coordsRounded, [pointFirst.X(), pointFirst.Y()]) === true) {
                isFinished = true;
            }
            // // Compare the first point with the nearest point:
            // const objsUnderMouse = graphBoard.getAllObjectsUnderMouse(event);
            // for (let i = 0; i < objsUnderMouse.length; i++) {
            //     if (objsUnderMouse[i].elType === "point") {
            //         if (objsUnderMouse[i].X() === pointFirst.X() && objsUnderMouse[i].Y() === pointFirst.Y()) {
            //             isFinished = true;
            //         }
            //     }
            // }
        }
        if (isFinished) {
            // Reset temporary objects data (Polygon preview while connecting points):
            for (let i = 0; i < tempObjArrs.current.segments.length; i++) {
                graphBoard.removeObject(tempObjArrs.current.segments[i]);
            }
            tempObjArrs.current.segments = [];
            // 1.3. Create polygon:
            const polygon = createPolygon(coordsInQueue.current);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            Object.keys(polygon.ancestors).forEach((itemKey) => {
                setPointEvents(polygon.ancestors[itemKey], polygon, params);
            });
            // 2.2. Set up segment's event handlers:
            polygon.borders.forEach((item) => {
                setSegmentEvents(item, polygon, params);
            });
            // 3. Save segment's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(polygon));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            // Reset temporary objects data (Polygon preview while connecting points):
            // 1. Create points:
            const point = createPoint(coordsRounded);
            // 1.1. Polygon's points:
            coordsInQueue.current = [...coordsInQueue.current, point];
            // 1.2. Polygon preview while connecting points:
            if (coordsInQueue.current.length) {
                // With n points, we can create (n-1) segments.
                // So, whenever (number of segments + 1) is less than (number of points),
                // a segment will be created by connecting the last point and the next-to-last one:
                if (tempObjArrs.current.segments.length < coordsInQueue.current.length - 1) {
                    const pointLast = coordsInQueue.current[coordsInQueue.current.length - 1];
                    const pointNextToLast = coordsInQueue.current[coordsInQueue.current.length - 2];
                    // Segment (temporary):
                    const coordsSegment = [pointLast, pointNextToLast];
                    const segment = createSegment("segment", coordsSegment);
                    // Data storing:
                    tempObjArrs.current.segments.push(segment);
                }
            }
        }
    };
    const handleCreatePolygonPreview = (event) => {
        const graphBoard = graphObjs.current.board;
        // Polygon's segment preview while moving:
        if (coordsInQueue.current.length) {
            // Check if a temporary segment/line was created:
            // - If no, create it.
            // - If yes, move and preview it at the current mouse's position.
            if (!tempObjs.current.point && !tempObjs.current.segment) {
                const pointLast = coordsInQueue.current[coordsInQueue.current.length - 1];
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
                const point = createPoint(coordsRounded, true);
                // Segment (temporary):
                const coordsSegment = [pointLast, point];
                const segment = createSegment("segment", coordsSegment);
                // Data storing:
                tempObjs.current.point = point;
                tempObjs.current.segment = segment;
            } else {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                let pointTemp = null;
                if (tempObjs.current.point === tempObjs.current.segment.point1) {
                    pointTemp = tempObjs.current.segment.point1;
                } else if (tempObjs.current.point === tempObjs.current.segment.point2) {
                    pointTemp = tempObjs.current.segment.point2;
                }
                if (pointTemp) {
                    pointTemp.setPositionDirectly(JXG.COORDS_BY_USER, coords);
                    graphBoard.update();
                }
            }
        }
    };

    // (Methods) Graph elements - Arrow (vector):
    const createArrow = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const arrow = graphBoard.create("arrow", listOfCoordsAndPoint);
        return arrow;
    };
    const handleCreateArrow = (whatToCreate, event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Arrow preview while moving):
        graphBoard.removeObject(tempObjs.current.point);
        graphBoard.removeObject(tempObjs.current.arrow);
        tempObjs.current.point = null;
        tempObjs.current.arrow = null;
        // 1. Create points & arrow:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 1) {
            const point1 = coordsInQueue.current[0];
            const point2 = createPoint(coordsRounded);
            const arrow = createArrow([point1, point2]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            setPointEvents(arrow.point1, arrow, params);
            setPointEvents(arrow.point2, arrow, params);
            // 2.2. Set up arrow's event handlers:
            setSegmentEvents(arrow, undefined, params);
            // 3. Save arrow's data:
            handleAddItemToGraphElemDataList(
                getDataOfGraphElement(arrow, whatToCreate ? { type: whatToCreate } : undefined)
            );
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current = [point];
        }
    };
    const handleCreateArrowPreview = (whatToCreate, event) => {
        const graphBoard = graphObjs.current.board;
        // Arrow preview while moving:
        if (coordsInQueue.current.length === 1) {
            // Check if a temporary arrow was created:
            // - If no, create it.
            // - If yes, move and preview it at the current mouse's position.
            if (!tempObjs.current.point && !tempObjs.current.arrow) {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
                const point = createPoint(coordsRounded, true);
                // Arrow (temporary):
                const coordsArrow = [...coordsInQueue.current, point];
                const arrow = createArrow(coordsArrow);
                // Data storing:
                tempObjs.current.point = point;
                tempObjs.current.arrow = arrow;
            } else {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                let pointTemp = null;
                if (tempObjs.current.point === tempObjs.current.arrow.point1) {
                    pointTemp = tempObjs.current.arrow.point1;
                } else if (tempObjs.current.point === tempObjs.current.arrow.point2) {
                    pointTemp = tempObjs.current.arrow.point2;
                }
                if (pointTemp) {
                    pointTemp.setPositionDirectly(JXG.COORDS_BY_USER, coords);
                    graphBoard.update();
                }
            }
        }
    };

    // Arrow (ray):
    // const handleCreateRay = () => {
    //     const startPoint = board.create("point", [0, 0]);
    //     const endPoint = board.create("point", [5, 5]);
    //     const line = board.create("line", [startPoint, endPoint]);
    //     line.setAttribute({
    //         strokeOpacity: 0.5,
    //         strokeColor: "red",
    //         lastArrow: true,
    //     });
    // };

    // (Methods) Graph elements - Circle:
    const createCircle = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const circle = graphBoard.create("circle", listOfCoordsAndPoint);
        return circle;
    };
    const handleCreateCircle = (event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Circle preview while moving):
        graphBoard.removeObject(tempObjs.current.point);
        graphBoard.removeObject(tempObjs.current.circle);
        tempObjs.current.point = null;
        tempObjs.current.circle = null;
        // 1. Create points & circle:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 1) {
            const point1 = coordsInQueue.current[0];
            const point2 = createPoint(coordsRounded);
            const circle = createCircle([point1, point2]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            setPointEvents(circle.center, circle, params);
            setPointEvents(circle.point2, circle, params);
            // 2.2. Set up circle's event handlers:
            setCircleEvents(circle, params);
            // 3. Save circle's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(circle));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current = [point];
        }
    };
    const handleCreateCirclePreview = (event) => {
        const graphBoard = graphObjs.current.board;
        // Circle preview while moving:
        if (coordsInQueue.current.length === 1) {
            if (!tempObjs.current.point && !tempObjs.current.circle) {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
                const point = createPoint(coordsRounded, true);
                // Circle (temporary):
                const coordsCircle = [...coordsInQueue.current, point];
                const circle = graphBoard.create("circle", coordsCircle);
                // Data storing:
                tempObjs.current.point = point;
                tempObjs.current.circle = circle;
            } else {
                // Point (temporary):
                const coords = graphBoard.getUsrCoordsOfMouse(event);
                let pointTemp = null;
                if (tempObjs.current.point === tempObjs.current.circle.point1) {
                    pointTemp = tempObjs.current.circle.point1;
                } else if (tempObjs.current.point === tempObjs.current.circle.point2) {
                    pointTemp = tempObjs.current.circle.point2;
                }
                if (pointTemp) {
                    pointTemp.setPositionDirectly(JXG.COORDS_BY_USER, coords);
                    graphBoard.update();
                }
            }
        }
    };

    // (Methods) Graph elements - Ellipse:
    const createEllipse = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const ellipse = graphBoard.create("ellipse", listOfCoordsAndPoint);
        return ellipse;
    };
    const handleCreateEllipse = (event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Ellipse preview while moving):
        // Nothing!
        // 1. Create points & ellipse:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 2) {
            const point1 = coordsInQueue.current[0];
            const point2 = coordsInQueue.current[1];
            const point3 = createPoint(coordsRounded);
            const ellipse = createEllipse([point1, point2, point3]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            Object.keys(ellipse.ancestors).forEach((itemKey) => {
                const item = ellipse.ancestors[itemKey];
                if (item?.elType === "point" && item?.name) {
                    setPointEvents(item, ellipse, params);
                }
            });
            // 2.2. Set up ellipse's event handlers:
            // Nothing!
            // 3. Save ellipse's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(ellipse, { type: "ellipse" }));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current.push(point);
        }
    };

    // (Methods) Graph elements - Parabola:
    const createParabola = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const parabola = graphBoard.create("parabola", listOfCoordsAndPoint);
        return parabola;
    };
    const handleCreateParabola = (event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Parabola preview while moving):
        // Nothing!
        // 1. Create points & segment:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 3) {
            const line = coordsInQueue.current[2];
            const point3 = createPoint(coordsRounded);
            const parabola = createParabola([point3, line]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's and line's event handlers:
            Object.keys(parabola.ancestors).forEach((itemKey) => {
                const item = parabola.ancestors[itemKey];
                if (item?.elType === "point" && item?.name) {
                    setPointEvents(item, parabola, params);
                }
                if (item?.elType === "line" && item?.name) {
                    setSegmentEvents(item, parabola, params);
                }
            });
            // 2.2. Set up parabola's event handlers:
            // Nothing!
            // 3. Save parabola's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(parabola, { type: "parabola3" }));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else if (coordsInQueue.current.length === 1) {
            const point1 = coordsInQueue.current[0];
            const point2 = createPoint(coordsRounded);
            const line = createSegment("line", [point1, point2]);
            coordsInQueue.current.push(point2, line);
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current.push(point);
        }
    };

    // (Methods) Graph elements - Hyperbola:
    const createHyperbola = (listOfCoordsAndPoint) => {
        const graphBoard = graphObjs.current.board;
        const hyperbola = graphBoard.create("hyperbola", listOfCoordsAndPoint);
        return hyperbola;
    };
    const handleCreateHyperbola = (event) => {
        const graphBoard = graphObjs.current.board;
        // Reset temporary objects data (Hyperbola preview while moving):
        // Nothing!
        // 1. Create points & hyperbola:
        const coords = graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        if (coordsInQueue.current.length === 2) {
            const point1 = coordsInQueue.current[0];
            const point2 = coordsInQueue.current[1];
            const point3 = createPoint(coordsRounded);
            const hyperbola = createHyperbola([point1, point2, point3]);
            // 2. Set element's event handlers:
            const params = {
                jxgBoard: graphObjs.current.board,
                config: graphHelperRefToConfig.current,
                eventHandlers: graphHelperRefToEvents.current,
            };
            // 2.1. Set up point's event handlers:
            Object.keys(hyperbola.ancestors).forEach((itemKey) => {
                const item = hyperbola.ancestors[itemKey];
                if (item?.elType === "point" && item?.name) {
                    setPointEvents(item, hyperbola, params);
                }
            });
            // 2.2. Set up hyperbola's event handlers:
            // Nothing!
            // 3. Save hyperbola's data:
            handleAddItemToGraphElemDataList(getDataOfGraphElement(hyperbola, { type: "hyperbola" }));
            // 4. Clean up:
            coordsInQueue.current = [];
        } else {
            const point = createPoint(coordsRounded);
            coordsInQueue.current.push(point);
        }
    };

    // (Methods) Graph elements - Text (numberline_label):
    const createText = (listOfCoords, textContent, attrs = {}) => {
        const graphBoard = graphObjs.current.board;
        const text = graphBoard.create("text", [...listOfCoords, textContent], {
            anchorX: "middle",
            anchorY: "bottom",
            display: "html",
            ...attrs,
        });
        return text;
    };
    const handleCreateText = (whatToCreate, event) => {
        const graphBoard = graphObjs.current.board;
        // 1. Create text:
        const coords = graphConfig.pointCoordsToBeCreated || graphBoard.getUsrCoordsOfMouse(event);
        const coordsRounded = roundToStepForArray(coords, graphConfig.coordsStep);
        const textContent = graphElemDataList[graphConfig.elemDataIndex].label || "";
        const textContentDisplay =
            graphElements[whatToCreate].getDisplayOfText(textContent, coordsRounded?.[0]) || textContent;
        const text = createText(coordsRounded, textContentDisplay);
        // 2. Set up text's event handlers:
        const params = {
            jxgBoard: graphObjs.current.board,
            config: graphHelperRefToConfig.current,
            eventHandlers: graphHelperRefToEvents.current,
            ...graphElements[whatToCreate].paramsForSetEvents,
        };
        setTextEvents(text, params);
        // 3. Save text's data:
        const customConfig = {
            type: whatToCreate,
            label: textContent,
        };
        handleAddItemToGraphElemDataList(getDataOfGraphElement(text, customConfig));
    };

    // (Methods) Actions event handlers:
    const handleMouseDown = (event) => {
        switch (currAction) {
            case "point": {
                handleCreatePoint(event);
                break;
            }
            case "segment": {
                handleCreateSegmentOrLine("segment", event);
                break;
            }
            case "line": {
                handleCreateSegmentOrLine("line", event);
                break;
            }
            case "polygon": {
                handleCreatePolygon(event);
                break;
            }
            // case "ray": {
            //     break;
            // }
            case "vector": {
                handleCreateArrow("vector", event);
                break;
            }
            case "circle": {
                handleCreateCircle(event);
                break;
            }
            case "ellipse": {
                handleCreateEllipse(event);
                break;
            }
            // case "parabola3": {
            //     handleCreateParabola(event);
            //     break;
            // }
            // case "hyperbola": {
            //     handleCreateHyperbola(event);
            //     break;
            // }
            default:
                break;
        }
    };
    const handleMouseMove = (event) => {
        switch (currAction) {
            case "segment": {
                handleCreateSegmentOrLinePreview("segment", event);
                break;
            }
            case "line": {
                handleCreateSegmentOrLinePreview("line", event);
                break;
            }
            case "polygon": {
                handleCreatePolygonPreview(event);
                break;
            }
            // case "ray": {
            //     break;
            // }
            case "vector": {
                handleCreateArrowPreview("vector", event);
                break;
            }
            case "circle": {
                handleCreateCirclePreview(event);
                break;
            }
            case "ellipse": {
                break;
            }
            // case "parabola3": {
            //     break;
            // }
            // case "hyperbola": {
            //     break;
            // }
            case "numberline_label": {
                if (nextAct.current === "numberline_label") {
                    handleCreateText("numberline_label", event);
                    nextAct.current = false;
                }
                break;
            }
            default:
                break;
        }
    };

    // List of actions:
    let actionList;
    if (graphType === "default") {
        actionList = {
            point: {
                handlers: { down: handleMouseDown },
            },
            segment: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            line: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            polygon: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            // ray: {
            //     handlers: {},
            // },
            vector: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            circle: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            ellipse: {
                handlers: { down: handleMouseDown, move: handleMouseMove },
            },
            // parabola3: {
            //     handlers: { down: handleMouseDown, move: handleMouseMove },
            // },
            // hyperbola: {
            //     handlers: { down: handleMouseDown, move: handleMouseMove },
            // },
        };
    } else if (graphType === "numberline") {
        actionList = {
            numberline_label: {
                handlers: { move: handleMouseMove },
            },
        };
    }

    // Side effects:
    useEffect(() => {
        // 1. Create a new JSXGraph board:
        const attrs = graphTypes[graphType].attributes;
        const board = JXG.JSXGraph.initBoard(id, attrs);

        // 2. Attach event handlers to the board:
        board.on("down", (event) => {
            if (currActionHandlers.current?.down instanceof Function) {
                currActionHandlers.current.down(event);
            }
        });
        board.on("move", (event) => {
            if (currActionHandlers.current?.move instanceof Function) {
                currActionHandlers.current.move(event);
            }
        });

        // 3. Data storing:
        graphObjs.current.board = board;

        return () => {
            JXG.JSXGraph.freeBoard(board);
        };
    }, []);

    useEffect(() => {
        // 1. Update event handlers of the graph board:
        if (!isReadOnly && currAction) {
            currActionHandlers.current = actionList[currAction]?.handlers;
        } else {
            currActionHandlers.current = null;
        }
    }, [isReadOnly, currAction, graphElemDataList, graphConfig]);

    useEffect(() => {
        // 1. Reset graph board temporary data:
        // 1.1. Temporary queue:
        if (graphObjs.current.board) {
            graphObjs.current.board.removeObject(coordsInQueue.current);
        }
        coordsInQueue.current = [];
        // 1.2. Temporary objects:
        const tempObjsKeys = Object.keys(tempObjs.current);
        for (let i = 0; i < tempObjsKeys.length; i++) {
            const _key = tempObjsKeys[i];
            graphObjs.current.board.removeObject(tempObjs.current[_key]);
            tempObjs.current[_key] = null;
        }
    }, [currAction, graphElemDataList]);

    useEffect(() => {
        if (value && value instanceof Array && !compareObjectsDeeply(value, graphElemDataList)) {
            // 1. Create elements:
            const elemList = value.map((item) => {
                const params = {
                    jxgBoard: graphObjs.current.board,
                    config: graphHelperRefToConfig.current,
                    eventHandlers: graphHelperRefToEvents.current,
                    ...graphElements[item.type]?.paramsForSetEvents,
                };
                return createGraphElementByData(item, params);
            });
            // 2. Save elements' data:
            const elemDataList = elemList.map((item, itemIndex) => {
                const customConfig = {
                    type: value[itemIndex]?.type, // Used for all elements.
                    label: value[itemIndex]?.label, // Used for numberline_label.
                };
                return getDataOfGraphElement(item, customConfig) || value[itemIndex];
            });
            // 3. Remove invalid items:
            elemDataList.filter((item) => !!item);
            // 4. Store data:
            handleSetGraphElemDataList(elemDataList);
        }
    }, [value]);

    useEffect(() => {
        const graphBoard = graphObjs.current.board;
        // 1. Update graph board:
        if (graphBoard) {
            // 1.1. boundingBox:
            if (boundingBox) {
                graphBoard.setBoundingBox(boundingBox, graphTypes[graphType].attributes.keepAspectRatio, "reset");
            }
        }
        // 2. Update graph config:
        if (coordsStep) {
            // 2.1. graphConfig:
            setGraphConfig({
                ...graphConfig,
                coordsStep: coordsStep,
            });
        }
    }, [boundingBox, coordsStep]);

    // Rendering:
    return (
        <div className={`app-jsxgraph-board ${graphTypes[graphType].wrapperClassName}`}>
            {/* Geometry menu: */}
            {isCreatingGeom && (
                <div className="app-jsxgraph-actions">
                    {Object.keys(actionList).map((item, itemIndex) => {
                        return (
                            <CustomButton
                                key={`act${itemIndex}`}
                                type={item === currAction ? "second" : "grey"}
                                icon={graphElements[item].icon}
                                title={t(`q_graph.${item}`)}
                                onClick={() => {
                                    handleSetAction(item, true);
                                }}
                            ></CustomButton>
                        );
                    })}
                </div>
            )}

            {/* Numberline label menu: */}
            {isCreatingNlLbl && (
                <div className="app-jsxgraph-actions">
                    <CustomButton
                        key={`act-add-nllbl`}
                        type={"second"}
                        icon={<PlusOutlined />}
                        title={t(`q_graph.add_numberline_label`)}
                        onClick={() => {
                            handleAddItemToGraphElemDataList({
                                type: "numberline_label",
                                label: "",
                                values: undefined,
                            });
                        }}
                    ></CustomButton>

                    <Row gutter={[16, 8]}>
                        {graphElemDataList.map((item, itemIndex) => {
                            if (["numberline_label"].includes(item.type)) {
                                return (
                                    <Col key={`nllbl${itemIndex}`} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                                        <div className="nllbl-input-wrapper">
                                            <div className="nllbl-input">
                                                <Editor
                                                    config={editorConfigInput.current}
                                                    value={item.label}
                                                    onChange={(val) => {
                                                        const graphElemDataListNew = [...graphElemDataList];
                                                        graphElemDataListNew[itemIndex].label = val;
                                                        handleSetGraphElemDataList(graphElemDataListNew);
                                                        // Update graph board's numberline_label:
                                                        if (graphElemDataListNew[itemIndex].value) {
                                                            updateGraphElementByData(graphElemDataListNew[itemIndex], {
                                                                jxgBoard: graphObjs.current.board,
                                                                config: graphHelperRefToConfig.current,
                                                                eventHandlers: graphHelperRefToEvents.current,
                                                            });
                                                        }
                                                    }}
                                                ></Editor>
                                            </div>
                                            <span className="nllbl-actions">
                                                <span className="action-wrapper">
                                                    <CustomButton
                                                        className="action-btn danger"
                                                        type="simple"
                                                        icon={<SvgBin />}
                                                        onClick={() => handleCloseElementTag(item, itemIndex)}
                                                    ></CustomButton>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </div>
            )}

            {/* Graph board: */}
            <div className="app-jsxgraph-wrapper">
                <div
                    id={id}
                    className="app-jsxgraph"
                    style={{
                        "--w": width || "350px",
                        "--h": height || "350px",
                    }}
                ></div>
                <div className="app-jsxgraph-navigation">
                    <CustomButton
                        title={<ZoomOutOutlined />}
                        onClick={() => {
                            graphObjs.current.board.zoomOut();
                            setCoordsStep();
                        }}
                    ></CustomButton>
                    <CustomButton
                        title={"O"}
                        onClick={() => {
                            graphObjs.current.board.zoom100();
                            graphObjs.current.board.setBoundingBox(
                                boundingBox,
                                graphTypes[graphType].attributes.keepAspectRatio,
                                "reset"
                            );
                            setCoordsStep();
                        }}
                    ></CustomButton>
                    <CustomButton
                        title={<ZoomInOutlined />}
                        onClick={() => {
                            graphObjs.current.board.zoomIn();
                            setCoordsStep();
                        }}
                    ></CustomButton>
                </div>
            </div>

            {/* Graph board's geometry interaction menu: */}
            {(isCreatingGeom || isSolvingGeom || isCreatingNlLbl || isSolvingNlLbl) && (
                <div className="app-jsxgraph-elemtags">
                    {graphElemDataList.map((item, itemIndex) => {
                        // Content:
                        const itemId = item.id;
                        const itemType = item.type;
                        const itemTypeName = t(`q_graph.${itemType}`);
                        const itemName = item.name;
                        const itemLabel = item.label;
                        const itemIcon = graphElements[itemType].icon;
                        // Display:
                        const isAllowedUpdate = !!item?.id && enableElemUpdate;
                        const isAllowedDelete = !!item?.id && enableElemDelete;
                        let type = "grey";
                        let className = "";
                        let hasActionsWidth =
                            8 * 2 +
                            (isAllowedUpdate ? 26 : 0) +
                            (isAllowedDelete ? 26 : 0) +
                            (isAllowedUpdate + isAllowedDelete - 1) * 8;
                        if (graphConfig.elemDataIndex === itemIndex) {
                            type = "second";
                        }
                        if (isAllowedUpdate || isAllowedDelete) {
                            className += " has-actions";
                        }
                        return (
                            <div
                                key={`elemtag${itemIndex}`}
                                className={`elemtag ${className}`}
                                style={{ "--has-actions-width": `${hasActionsWidth}px` }}
                            >
                                {/* Tag for geometry: */}
                                {graphType === "default" && (
                                    <CustomButton
                                        className="btn-tag"
                                        type={type}
                                        icon={itemIcon}
                                        title={`${itemTypeName} ${itemName}`}
                                        onClick={() => {
                                            handleClickElementTag(item, itemIndex);
                                        }}
                                    ></CustomButton>
                                )}

                                {/* Tag for numberline label menu: */}
                                {graphType === "numberline" && (
                                    <>
                                        {!item.id && typeof item.value !== "number" ? (
                                            <NumberlineLabelDraggable
                                                className="nllbl-btn-drg"
                                                position={{ x: 0, y: 0 }} // Fixed position!
                                                positionFixedDirection="left-top"
                                                // cancel=".elemtag"
                                                dropzoneCssSelectors=".app-jsxgraph-wrapper"
                                                onDragStart={(event, draggedWhat) => {
                                                    //
                                                }}
                                                onDragStop={(event, droppedWhat, droppedAtTarget) => {
                                                    if (
                                                        !(droppedAtTarget?.className || "").includes(
                                                            "app-jsxgraph-wrapper"
                                                        )
                                                    ) {
                                                        return;
                                                    }
                                                    // 1. Position info:
                                                    const labelWidth = droppedWhat.offsetWidth;
                                                    const labelHeight = droppedWhat.offsetHeight;
                                                    const labelTop = droppedWhat.getBoundingClientRect().top;
                                                    const labelLeft = droppedWhat.getBoundingClientRect().left;
                                                    const labelTopExact = labelTop + labelHeight + 8;
                                                    const labelLeftExact = labelLeft + labelWidth / 2;

                                                    // 2. Graph board action:
                                                    handleSetAction("numberline_label", false);

                                                    // 3. Data used for the action:
                                                    nextAct.current = "numberline_label";
                                                    const fakeMouseEvent = new MouseEvent("mousemove", {
                                                        clientX: labelLeftExact,
                                                        clientY: labelTopExact,
                                                    });
                                                    const coords = graphObjs.current.board.getUsrCoordsOfMouse(
                                                        fakeMouseEvent
                                                    );
                                                    setGraphConfig({
                                                        ...graphConfig,
                                                        elemDataIndex: itemIndex,
                                                        pointCoordsToBeCreated: [coords[0], 0],
                                                    });
                                                }}
                                                customDragElement={
                                                    <CustomButton
                                                        className="btn-tag nllbl-btn"
                                                        type="grey"
                                                        icon={itemIcon}
                                                        title={
                                                            <HTMLDisplayer rootType="block" htmlString={itemLabel} />
                                                        }
                                                    ></CustomButton>
                                                }
                                            >
                                                <CustomButton
                                                    className="btn-tag nllbl-btn"
                                                    type={type}
                                                    icon={itemIcon}
                                                    title={<HTMLDisplayer rootType="block" htmlString={itemLabel} />}
                                                    onClick={() => {
                                                        handleClickElementTag(item, itemIndex);
                                                    }}
                                                ></CustomButton>
                                            </NumberlineLabelDraggable>
                                        ) : (
                                            <CustomButton
                                                className="btn-tag nllbl-btn"
                                                type={type}
                                                icon={itemIcon}
                                                title={<HTMLDisplayer rootType="block" htmlString={itemLabel} />}
                                                onClick={() => {
                                                    handleClickElementTag(item, itemIndex);
                                                }}
                                            ></CustomButton>
                                        )}
                                    </>
                                )}

                                {/* Actions: */}
                                <div className="btn-action-list">
                                    {isAllowedUpdate &&
                                        // <Dropdown
                                        //     visible={values.showDropdown === itemId}
                                        //     overlay={
                                        //         <div>
                                        //             {/* We have to wrap with a div because the Dropdown will binding its own "mode" to the overlay component. */}
                                        //             {/* The overlay component use its own "mode", not the "mode" from Dropdown! */}
                                        //             <GraphElemMenu
                                        //                 mode={graphMode}
                                        //                 elemData={item}
                                        //                 graphHelperRefs={{
                                        //                     jxgBoard: graphObjs.current.board,
                                        //                     config: graphHelperRefToConfig.current,
                                        //                     eventHandlers: graphHelperRefToEvents.current,
                                        //                 }}
                                        //                 onClickMenuItem={() => {
                                        //                     setValues({ ...values, showDropdown: false });
                                        //                 }}
                                        //                 onClickUpdate={(elemDataNew, elemNew) => {
                                        //                     graphHelperRefToEvents.current.onUpdate(elemNew);
                                        //                 }}
                                        //             />
                                        //         </div>
                                        //     }
                                        //     trigger={["click"]}
                                        //     placement="bottomRight"
                                        //     onVisibleChange={(val) => {
                                        //         setValues({ ...values, showDropdown: val ? itemId : false });
                                        //     }}
                                        //     overlayStyle={{ zIndex: "800" }}
                                        // >
                                        //     <CustomButton
                                        //         className="btn-action"
                                        //         type="simple"
                                        //         icon={<MoreOutlined />}
                                        //         onClick={() => {
                                        //             setValues({ ...values, showDropdown: itemId });
                                        //         }}
                                        //     ></CustomButton>
                                        // </Dropdown>
                                        null}
                                    {isAllowedUpdate && (
                                        <>
                                            <CustomButton
                                                className="btn-action"
                                                type="simple"
                                                icon={<EditOutlined />}
                                                onClick={() => {
                                                    setValues({ ...values, showModal: `graphelem-detail-${itemId}` });
                                                }}
                                            ></CustomButton>
                                            <GraphElemConfigModal
                                                mode={graphMode}
                                                visible={values.showModal === `graphelem-detail-${itemId}`}
                                                elemData={item}
                                                graphHelperRefs={{
                                                    jxgBoard: graphObjs.current.board,
                                                    config: graphHelperRefToConfig.current,
                                                    eventHandlers: graphHelperRefToEvents.current,
                                                }}
                                                onOk={(elemDataNew, elemNew) => {
                                                    graphHelperRefToEvents.current.onUpdate(elemNew);
                                                    setValues({ ...values, showModal: false });
                                                }}
                                                onCancel={() => {
                                                    setValues({ ...values, showModal: false });
                                                }}
                                            />
                                        </>
                                    )}
                                    {isAllowedDelete && (
                                        <CustomButton
                                            className="btn-action"
                                            type="simple"
                                            icon={<CloseOutlined />}
                                            onClick={() => {
                                                handleCloseElementTag(item, itemIndex);
                                            }}
                                        ></CustomButton>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

JSXGraphBoard.propTypes = {
    // JSXGraphBoard's settings:
    graphMode: PropTypes.oneOf(["creating", "solving"]),
    // JSXGraphBoard's DOM node id:
    id: PropTypes.string,
    // JSXGraphBoard's DOM node style:
    width: PropTypes.string,
    height: PropTypes.string,
    // Value:
    value: PropTypes.array,
    // Others:
    isReadOnly: PropTypes.bool,
    // Events:
    onChange: PropTypes.func,
};

export default JSXGraphBoard;
