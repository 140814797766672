import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Space, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentWidth } from "src/hooks";
import Calender from "src/assets/images/calender.svg";
import Book from "src/assets/images/book-icon.svg";
import iconChevronLeft from "src/assets/images/icon-chevron-left.svg";
import Files from "src/assets/images/nav/files.svg";
import Clock from "src/assets/images/nav/Clock.svg";
import Man from "src/assets/images/nav/man.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Header.scss";

const Header = (props) => {
    const { classData = {}, assignment = {}, loading, assignment_id } = props;
    const formatDate = useSelector((state) => state.general.formatDate);

    const navigate = useNavigate();

    const CourseMenu = () => (
        <div className="class-header-dropdown-menu">
            {classData.categories &&
                classData.categories.map((course, index) => {
                    return <strong key={`course-${index}`}>{course?.name}</strong>;
                })}
        </div>
    );

    return (
        <Spin spinning={loading}>
            <div className="detail-class-header ranking-header">
                <Row gutter={[30, 30]}>
                    <Col span={24}>
                        <nav className="header-navigate">
                            <div className="btn-wrapper">
                                <div className="btn-go-back">
                                    {assignment_id && (
                                        <span className="go-back-icon" onClick={() => navigate(-1)}>
                                            <img src={iconChevronLeft} alt=""></img>
                                        </span>
                                    )}
                                    <span className="go-back-title">{t("ranking.ranking_table")}</span>
                                </div>
                            </div>
                        </nav>
                    </Col>

                    {classData?.id && assignment?.id && (
                        <Col span={24}>
                            <div className="detail-class-header-wrapper">
                                <ul>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon icon-small">
                                                <img src={Man} alt="icon" />
                                            </span>
                                            <span className="detail-class-item-title">
                                                {t("class.name")}
                                                <span>:</span>
                                            </span>
                                            <span>
                                                {/* <div>{classData.code ? `(${classData.code})` : ""}</div> */}
                                                <strong>{classData.name ? classData.name : ""}</strong>
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon">
                                                <img src={Book} alt="icon" />
                                            </span>
                                            <span className="detail-class-item-title">
                                                {t("class.subject")}
                                                <span>:</span>
                                            </span>
                                            {classData?.categories?.length !== 0 ? (
                                                classData?.categories?.length <= 2 ? (
                                                    classData.categories.map((d, i) => {
                                                        return <strong key={`course-${i}`}>{d?.name || ""}</strong>;
                                                    })
                                                ) : (
                                                    <Dropdown overlay={<CourseMenu />} placement="bottom">
                                                        <strong className="show-more">...</strong>
                                                    </Dropdown>
                                                )
                                            ) : (
                                                <strong>_</strong>
                                            )}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon">
                                                <img src={Calender} alt="icon" />
                                            </span>
                                            <span className="detail-class-item-title">
                                                {t("class.begin_date")}
                                                <span>:</span>
                                            </span>
                                            <strong>
                                                {assignment?.begin_date
                                                    ? moment(assignment.begin_date).format(formatDate)
                                                    : ""}
                                            </strong>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon">
                                                <img src={Calender} alt="icon" />
                                            </span>
                                            <span className="detail-class-item-title">
                                                {t("class.end_date")} <span>:</span>
                                            </span>
                                            <strong>
                                                {assignment?.end_date
                                                    ? moment(assignment.end_date).format(formatDate)
                                                    : ""}
                                            </strong>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon icon-small">
                                                <img src={Files} alt="icon" />
                                            </span>
                                            {/* <span className="etail-class-item-title"> */}
                                            <span className="detail-class-item-title">
                                                {t("class.exam_name")}
                                                <span>:</span>
                                            </span>
                                            <strong>{assignment?.name}</strong>
                                            {/* </span> */}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span className="detail-class-icon icon-small">
                                                <img src={Clock} alt="icon" />
                                            </span>
                                            <span className="detail-class-item-title">
                                                {t("class.duration")}
                                                <span>:</span>
                                            </span>
                                            {assignment?.homework_time_minute && (
                                                <strong>{`${assignment?.homework_time_minute} ${t(
                                                    "shared.minute"
                                                )}`}</strong>
                                            )}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </Spin>
    );
};

export default Header;
