import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import { notification } from "antd";
import AuthContext from "../contexts/AuthContext";
import LoadingScreen from "../components/LoadingScreen";
import configs from "src/configs";
import api from "src/api";
import { login, logout } from "src/reducers/auth";
import { loginWithFacebook, loginWithGoogle } from "src/api/containers/auth";
import { setupApiDefaultsBaseURL, specifyAccountType } from "src/api/helpers/demoAccount";
import { handleLogin } from "../containers/LoginV2/components/SignInForm";
import { deleteCookie, getCookie, parse_jwt, setCookie } from "src/utils/helpers";
import {
    checkTargetByURLPathname,
    checkTargetURLQueryStringException,
    clearTargetURLQueryStringException,
    getTargetURLQueryString,
} from "./helpers";
import { checkIfUserHasAnyScope, clearSelectedUserScope } from "src/api/helpers/userScope";
import { useTranslation } from "react-i18next";

export const AuthProvider = ({ children }) => {
    const { user, accountType } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.general.lang);
    const location = window.location;

    const roleCheck = localStorage.getItem("role");
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes("/callback/google")) {
            loginWithGoogle(roleCheck, location.search).then((res) => {
                if (res) {
                    const { token, role } = res;
                    if (token) {
                        const user = parse_jwt(`${token}`);

                        dispatch(login({ ...user, ...res }));
                        setCookie("token", token);
                        if (role === "guest") {
                            navigate(`/${lang}/choose-role`);
                        } else {
                            notification.success({
                                message: t("message.login_success"),
                            });
                            navigate("/");
                        }
                    } else {
                        notification.error({
                            message: res.message || t("message.third_party_login_error"),
                        });
                        navigate("/");
                    }
                    localStorage.removeItem("role");
                }
            });
        } else if (location.pathname.includes("/callback/facebook")) {
            loginWithFacebook(roleCheck, location.search).then((res) => {
                if (res) {
                    const { token, role, avatar, id, name, user_name, login_with_social } = res;
                    if (token) {
                        // const user = parse_jwt(`${token}`);
                        // user.role = role;
                        const user = { role, avatar, id, name, user_name, login_with_social };
                        dispatch(login(user));
                        setCookie("token", token);
                        // document.cookie = `token=${token}; path=/`;
                        if (role === "guest") {
                            navigate(`/${lang}/choose-role`);
                        } else {
                            notification.success({
                                message: t("message.login_success"),
                            });
                            navigate("/");
                        }
                    } else {
                        notification.error({
                            message: res.message || t("message.third_party_login_error"),
                        });
                        navigate("/");
                    }
                    localStorage.removeItem("role");
                }
            });
        }
    }, [location.pathname]);

    const _login = (user, callback = () => {}) => {
        dispatch(login(user));
        callback();
    };

    const _logout = (callback = () => {}) => {
        dispatch(logout());
        callback();
    };

    const value = { user, accountType, login: _login, logout: _logout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

/**
 * PREPARATION:
 * - Prepare data before accessing the target URL.
 * - Case: The user has not login yet.
 * (A target is an URL the user want to access)
 */
export const RequireGuestTarget = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const lang = useSelector((state) => state.general.lang);

    // NOTE: These cases below are not related to each other!

    // CASE 1: Set up target by url:
    if (location.search) {
        let urlParamTargetId = null;

        // Class invitation for teacher:
        urlParamTargetId = new URLSearchParams(location.search).get("invite_class_id");
        if (urlParamTargetId) {
            localStorage.setItem("currentTargetPathname", `/${lang}/class-invitation/${urlParamTargetId}`);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
        } else {
            urlParamTargetId = null;
        }

        // Class invitation for student:
        urlParamTargetId = new URLSearchParams(location.search).get("invite_student_class_id");
        if (urlParamTargetId) {
            localStorage.setItem("currentTargetPathname", `/${lang}/class-invitation-student/${urlParamTargetId}`);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
        } else {
            urlParamTargetId = null;
        }

        // Class invitation for student - using assignment id:
        urlParamTargetId = new URLSearchParams(location.search).get("assignment_id");
        if (urlParamTargetId) {
            localStorage.setItem("currentTargetPathname", `/${lang}/assignment/${urlParamTargetId}`);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
        } else {
            urlParamTargetId = null;
        }

        // Organization invitation for a user:
        urlParamTargetId = new URLSearchParams(location.search).get("invite_organization_id");
        if (urlParamTargetId) {
            localStorage.setItem("currentTargetPathname", `/${lang}/organization-invitation/${urlParamTargetId}`);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
        } else {
            urlParamTargetId = null;
        }
    }

    // CASE 2: Handle actions before the user is authenticated:
    const nextAct = JSON.parse(localStorage.getItem("nextAction"));
    if (nextAct) {
        // - Action #1: Login with demo account.
        if (nextAct.action === "login-demo-account") {
            if (!configs.DEMO_ACCOUNT?.username || !configs.DEMO_ACCOUNT?.password) {
                localStorage.removeItem("nextAction");
                localStorage.removeItem("previousAccount");
                localStorage.removeItem("currentAccount");
                notification.error({
                    message: t("message.login_demo_account_error"),
                });
                return children;
            }
            // 1. Change base URL of all API requests:
            setupApiDefaultsBaseURL("demo");
            // 2. Then, login with demo account:
            const params = {
                device_id: uuidv4(),
                username: configs.DEMO_ACCOUNT.username,
                password: configs.DEMO_ACCOUNT.password,
            };
            handleLogin(
                params,
                (resToken, resUser) => {
                    localStorage.setItem("currentAccount", "demo");
                    auth.login(resUser, () => {
                        setCookie("token", resToken);
                    });
                    notification.success({
                        message: t("message.login_demo_account_success"),
                    });
                },
                () => {
                    localStorage.removeItem("currentAccount");
                    // Reset the base URL of all API requests:
                    setupApiDefaultsBaseURL();
                }
            );
            // 3. Cleanup and setup:
            localStorage.removeItem("nextAction");
            return <LoadingScreen />;
        }
        // - Action #2: Login with previous account.
        else if (nextAct.action === "login-main-account") {
            // 1. Reset the base URL of all API requests:
            setupApiDefaultsBaseURL();
            // 2. Then, login with previous account:
            setTimeout(() => {
                const prevAccount = JSON.parse(localStorage.getItem("previousAccount"));
                auth.login(prevAccount.user, () => {
                    setCookie("token", prevAccount.token);
                });
                localStorage.removeItem("previousAccount");
            }, 100);
            // 3. Cleanup and setup:
            localStorage.removeItem("nextAction");
            localStorage.removeItem("currentAccount");
            return <LoadingScreen />;
        }
    }

    return children;
};

/**
 * HANDLE CATCHING TARGET:
 * @param {any} auth User authentication object.
 * @param {any} location Location object.
 * @param {string} lang Language key.
 * @returns null if there is no target.
 */
const handleCatchTargetIfPossible = (auth = null, location = null, lang = "") => {
    if (!auth || !location) {
        return null;
    }

    // Reach target if possible:
    const currTarget = localStorage.getItem("currentTargetPathname");
    const currTargetStatus = localStorage.getItem("currentTargetPathnameStatus");
    if (currTarget && currTargetStatus === "done") {
        localStorage.removeItem("currentTargetPathname");
        localStorage.removeItem("currentTargetPathnameStatus");
        clearTargetURLQueryStringException();
    }
    if (!auth.user) {
        if (checkTargetByURLPathname("invAssignment", location.pathname)) {
            localStorage.setItem("currentTargetPathname", location.pathname);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
            const assignmentId = location.pathname.substring(location.pathname.indexOf("/assignment/") + 12);
            return <Navigate to={`/${lang}/login?assignment_id=${assignmentId}`} state={{ from: location }} replace />;
        } else if (checkTargetByURLPathname("invClassTeacher", location.pathname)) {
            localStorage.setItem("currentTargetPathname", location.pathname);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
            const inviteClassId = location.pathname.substring(location.pathname.indexOf("/class-invitation/") + 18);
            return (
                <Navigate to={`/${lang}/login?invite_class_id=${inviteClassId}`} state={{ from: location }} replace />
            );
        } else if (checkTargetByURLPathname("invClassStudent", location.pathname)) {
            localStorage.setItem("currentTargetPathname", location.pathname);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
            const inviteClassId = location.pathname.substring(
                location.pathname.indexOf("/class-invitation-student/") + 26
            );
            return (
                <Navigate
                    to={`/${lang}/login?invite_student_class_id=${inviteClassId}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else if (checkTargetByURLPathname("invOrganization", location.pathname)) {
            localStorage.setItem("currentTargetPathname", location.pathname);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
            const inviteOrganizationId = location.pathname.substring(
                location.pathname.indexOf("/organization-invitation/") + 25
            );
            return (
                <Navigate
                    to={`/${lang}/login?invite_organization_id=${inviteOrganizationId}`}
                    state={{ from: location }}
                    replace
                />
            );
        }
    } else {
        const handleCatchTarget = () => {
            if (currTarget && currTargetStatus === "pending") {
                localStorage.setItem("currentTargetPathnameStatus", "done");
                return <Navigate to={`${currTarget}`} state={{ from: location }} replace />;
            }
        };
        // Catch target if the URL contains target query string and one of these case happens:
        // - If the user has any scope and a selected scope.
        // - If the user doesn't have any scope.
        if (checkIfUserHasAnyScope(auth?.user) === true) {
            if (localStorage.getItem("selectedUserScope")) {
                const r = handleCatchTarget();
                if (r) {
                    return r;
                }
            }
        } else {
            const r = handleCatchTarget();
            if (r) {
                return r;
            }
        }
    }

    return null;
};

/**
 * Get api me/ url with params by selected scope.
 * @returns Api url.
 */
const getApiUrlForFetchMe = () => {
    const selectedUserScope = localStorage.getItem("selectedUserScope");
    if (selectedUserScope) {
        const { scopeOptionKey, organization_id } = JSON.parse(selectedUserScope);
        if ((scopeOptionKey === "teams" || scopeOptionKey === "organization") && organization_id) {
            return `/me?organization_id=${organization_id}`;
        }
    }
    return false;
};

export const RequireAuth = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const [check, setCheck] = useState(false);
    const lang = useSelector((state) => state.general.lang);

    useEffect(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!auth.user && !check) {
            if (token) {
                let url = getApiUrlForFetchMe() || "/me";
                api.get(url).then((res) => {
                    if (res?.status && res?.data?.user) {
                        const prevAccount = JSON.parse(localStorage.getItem("previousAccount"));
                        if (prevAccount && res?.data?.user.username === configs.DEMO_ACCOUNT?.username) {
                            localStorage.setItem("currentAccount", "demo");
                        }
                        auth.login(res.data.user, () => {
                            setCheck(true);
                        });
                    } else {
                        localStorage.removeItem("token");
                        deleteCookie("token");
                        clearSelectedUserScope();
                        setCheck(true);
                        // Respecify account type:
                        specifyAccountType();
                    }
                });
            } else {
                setCheck(true);
                // Respecify account type:
                specifyAccountType();
            }
        }
    }, [check, auth.user]);

    if (!auth.user && !check) {
        return <LoadingScreen />;
    }

    // Remove target if:
    // - URL doesn't contain target query string.
    // - There is no exception for catching target by query string.
    if (!getTargetURLQueryString() && !checkTargetURLQueryStringException()) {
        localStorage.removeItem("currentTargetPathname");
        localStorage.removeItem("currentTargetPathnameStatus");
    }
    // Check if there is any target and catch it:
    const targetToBeReached = handleCatchTargetIfPossible(auth, location, lang);
    if (targetToBeReached !== null) {
        return targetToBeReached;
    }

    let path = location.pathname.replace(`/${lang}`, "");

    if (auth?.user?.role === "student") {
        if (!path || path == "/") {
            return <Navigate to={`/${lang}/home${getTargetURLQueryString()}`} replace />;
        }
    } else {
        if (!path || path == "/") {
            return <Navigate to={`/${lang}/home${getTargetURLQueryString()}`} replace />;
        }
    }

    if (!auth.user) {
        if (path === "/register") {
            return <Navigate to={`/${lang}/register${getTargetURLQueryString()}`} state={{ from: location }} replace />;
        } else if (path === "/forget-password") {
            return (
                <Navigate
                    to={`/${lang}/forget-password${getTargetURLQueryString()}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else {
            return <Navigate to={`/${lang}/login${getTargetURLQueryString()}`} state={{ from: location }} replace />;
        }
    }

    return children;
};

export const RequireGuest = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const [check, setCheck] = useState(false);
    const lang = useSelector((state) => state.general.lang);

    useEffect(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!auth.user && !check) {
            if (token) {
                let url = getApiUrlForFetchMe() || "/me";
                api.get(url).then((res) => {
                    if (res?.status && res?.data?.user) {
                        auth.login(res.data.user, () => {
                            setCheck(true);
                        });
                    } else {
                        localStorage.removeItem("token");
                        deleteCookie("token");
                        clearSelectedUserScope();
                        setCheck(true);
                    }
                });
            } else {
                setCheck(true);
            }
        }
    }, [check, auth.user]);

    if (!auth.user && !check) {
        return <LoadingScreen />;
    }

    // Remove target if:
    // - URL doesn't contain target query string.
    // - There is no exception for catching target by query string.
    if (!getTargetURLQueryString() && !checkTargetURLQueryStringException()) {
        localStorage.removeItem("currentTargetPathname");
        localStorage.removeItem("currentTargetPathnameStatus");
    }

    if (auth.user) {
        // // Use one of these:
        // return <Navigate to={`/${lang}`} state={{ from: location }} replace />;
        return <Navigate to={`/${lang}${getTargetURLQueryString()}`} state={{ from: location }} replace />;
    }

    return children;
};

export const ExternalAccess = ({ children }) => {
    const [URLSearchParams] = useSearchParams();
    const token = URLSearchParams.get("token");
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (token) {
            setCookie("token", token);
            localStorage.removeItem("selectedUserScope");
            navigate("/");
            setTimeout(() => {
                window.location.reload();
            }, 100);
        } else {
            notification.error({ message: t("login.invalid_path") });
            setTimeout(() => {
                navigate("/");
            }, 1000);
        }
    }, [token]);
    return <>{children}</>;
};
