import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { submitRecord } from "src/api/containers/record";

const initialState = {
    listRecord: {},
    currentQuestionOrder: undefined,
    currentQuestionOrderInPassage: undefined,
    tempItem: {},
    listTimePerQuestion: {},
    isShowConfirmModal: false,
    isSubmittingTest: false,
};

try {
    initialState.listTimePerQuestion = JSON.parse(localStorage.getItem("listTimePerQuestion")) || {};
} catch (err) {
    initialState.listTimePerQuestion = {};
}

try {
    initialState.tempItem = JSON.parse(localStorage.getItem("tempTime")) || {};
} catch (err) {
    initialState.tempItem = {};
}

try {
    initialState.listTimePerQuestion = JSON.parse(localStorage.getItem("listTimePerQuestion")) || {};
} catch (err) {
    initialState.listTimePerQuestion = {};
}

export const handleSubmitRecord = createAsyncThunk("test/submitRecord", async (data, thunkAPI) => {
    thunkAPI.dispatch(setIsSubmittingTest(true));

    const { recordId } = data;
    const { listAnswers = {} } = thunkAPI.getState().answer;
    const { listTimePerQuestion = {} } = thunkAPI.getState().test;

    thunkAPI.dispatch(
        gotoQuestionOrderTempTime({
            order: undefined,
            recordId: recordId,
            questionId: undefined,
        })
    );

    const listAnswerOfRecord = listAnswers[recordId] || {};
    let listTimerOfRecord;

    try {
        listTimerOfRecord = JSON.parse(localStorage.getItem("listTimePerQuestion"))[recordId] || {};
    } catch (err) {
        listTimerOfRecord = listTimePerQuestion[recordId];
    }

    const result = {};

    Object.keys(listAnswerOfRecord || {}).forEach((answerId) => {
        // const order = findQuestionInfoByDetailId(answerId);
        const answerValue = listAnswerOfRecord[answerId]?.answered;

        // Rest question rest
        if (listAnswerOfRecord?.[answerId]?.type !== "passage") {
            let newAnswer = null;
            let timeOfAnswer = listTimerOfRecord[answerId] || 0;

            newAnswer = {
                answers: answerValue,
                ...(listAnswerOfRecord[answerId]?.passage_highlighted && {
                    highlight_submit: listAnswerOfRecord[answerId]?.passage_highlighted,
                }),
                time: timeOfAnswer,
            };
            result[answerId] = newAnswer;
        }

        // Passage
        else {
            const newAnswer = {};
            Object.keys(answerValue)?.forEach((questionChildId) => {
                let timeOfChildAnswer = listTimerOfRecord[answerId][questionChildId] || 0;

                newAnswer[questionChildId] = {
                    answers: answerValue[questionChildId],
                    time: timeOfChildAnswer || 0,
                };
            });

            result[answerId] = newAnswer;
        }
    });

    const response = await submitRecord({
        id: recordId,
        data: {
            questions: result,
        },
    });
    thunkAPI.dispatch(setIsSubmittingTest(false));
    return response;
});

export const testSlice = createSlice({
    name: "test",
    initialState,
    reducers: {
        addRecord: (state, action) => {
            state.listRecord[action.payload.idAssignment] = action.payload.dataRecord;
        },
        gotoQuestionOrder: (state, action) => {
            // Handle change question
            state.currentQuestionOrder = action.payload;
        },
        gotoQuestionOrderTempTime: (state, action) => {
            const { order, recordId, questionId, passageId } = action.payload;

            // Handle change question
            state.currentQuestionOrder = +String(order).split(".")[0];

            const currentTime = new Date();
            let prevOrderInfo = current(state.tempItem)[recordId] || {};
            // prevId just have in question passage
            const { order: prevOrder, start_time: timePrevOrder, id: prevId, passageId: prevPassageId } = prevOrderInfo;

            // Catch case rechosen replicate order
            if (prevOrder !== order) {
                const newTempTime = {
                    [recordId]: {
                        start_time: currentTime.toString(),
                        order,
                        id: questionId,
                        ...(passageId && { passageId }),
                    },
                };

                state.tempItem = newTempTime;

                localStorage.setItem("tempTime", JSON.stringify(newTempTime));

                if (prevOrder) {
                    const prevTime = new Date(timePrevOrder);
                    const totalTimeSecond = Math.round((currentTime - prevTime) / 1000);

                    let secondPrevTime;
                    try {
                        if (prevPassageId) {
                            secondPrevTime = state.listTimePerQuestion[recordId]?.[prevPassageId]?.[prevId] || false;
                        } else {
                            secondPrevTime = state.listTimePerQuestion[recordId][prevId] || false;
                        }
                    } catch (err) {
                        secondPrevTime = false;
                    }

                    // If order is exist in listTimePerSecond then add it up
                    if (secondPrevTime) {
                        if (prevPassageId) {
                            state.listTimePerQuestion[recordId] = {
                                ...state.listTimePerQuestion[recordId],
                                [prevPassageId]: {
                                    ...(state.listTimePerQuestion[recordId]?.[prevPassageId] || {}),
                                    [prevId]: totalTimeSecond + secondPrevTime || 0,
                                },
                            };
                        } else {
                            state.listTimePerQuestion[recordId] = {
                                ...state.listTimePerQuestion[recordId],
                                [prevId]: totalTimeSecond + secondPrevTime,
                            };
                        }
                    } else {
                        if (prevPassageId) {
                            state.listTimePerQuestion[recordId] = {
                                ...state.listTimePerQuestion[recordId],
                                [prevPassageId]: {
                                    ...(state.listTimePerQuestion[recordId]?.[prevPassageId] || {}),
                                    [prevId]: totalTimeSecond || 0,
                                },
                            };
                        } else {
                            state.listTimePerQuestion[recordId] = {
                                ...state.listTimePerQuestion[recordId],
                                [prevId]: totalTimeSecond,
                            };
                        }
                    }

                    localStorage.setItem("listTimePerQuestion", JSON.stringify(current(state.listTimePerQuestion)));
                }
            }
        },
        gotoQuestionOrderInPassagePreview: (state, action) => {
            const { questionOrder } = action.payload;
            state.currentQuestionOrderInPassage = +questionOrder;
        },
        removeTest: (state, action) => {
            state.listTimePerQuestion = {};
            state.tempItem = {};
            state.currentQuestionOrder = undefined;
            state.currentQuestionOrderInPassage = undefined;
        },
        setIsShowConfirmModal: (state, action) => {
            state.isShowConfirmModal = action.payload;
        },
        setIsSubmittingTest: (state, action) => {
            if (typeof action.payload === "boolean") {
                state.isSubmittingTest = action.payload;
            }
        },
    },
    extraReducers: {
        // [handleSubmitRecord.pending]: (action) => {
        //     console.log("Pending");
        // },
        // [handleSubmitRecord.rejected]: (action) => {},
        // [handleSubmitRecord.fulfilled]: (action) => {
        //     console.log("Fulfilled");
        // },
    },
});

export const {
    addRecord,
    nextQuestionOrder,
    prevQuestionOrder,
    gotoQuestionOrder,
    gotoQuestionOrderTempTime,
    gotoQuestionOrderInPassage,
    gotoQuestionOrderInPassagePreview,
    removeTest,
    setIsShowConfirmModal,
    setIsSubmittingTest,
} = testSlice.actions;

export default testSlice.reducer;
