import { createContext } from "react";

const defaultFunction = () => {};

const ExamPDFContext = createContext({
    examData: { file_id: "", page: 1, src: "" },
    setExamData: defaultFunction,
    currentThumbnail: {},
    setCurrentThumbnail: defaultFunction,
    isShowThumbnails: false,
    toggleThumbnails: defaultFunction,
    listThumbnails: [],
    setListThumbnails: defaultFunction,
    setDrawingTool: defaultFunction,
    drawingTool: undefined,
    listQuestion: [], // [{question: {}, order:1, score:10, page: 1,file_id:""}]
    setListQuestion: defaultFunction,
    defaultListQuestion: [],
    pathsByFile: {},
    setPathByFile: defaultFunction,
});

export default ExamPDFContext;
