import { EyeFilled, EyeInvisibleFilled, LockFilled, MailFilled } from "@ant-design/icons";
import { Checkbox, Form, Input, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { guestLoginWithFacebook, guestLoginWithGoogle, login } from "src/api/containers/auth";
import { useAuth } from "src/modules/auth";
import { default as CustomButton } from "src/modules/components/Button";
import { toggleCollapsed } from "src/reducers/general";
import { parse_jwt, setCookie } from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import {
    clearTargetURLQueryStringException,
    getTargetURLQueryString,
    setTargetURLQueryStringException,
} from "src/modules/auth/helpers";
import LoginClock from "src/assets/images/decoration-clock.svg";
import FacebookIcon from "src/assets/images/facebook-icon.png";
import GirlAndBook from "src/assets/images/girl_and_book.png";
import GoogleIcon from "src/assets/images/google-icon.png";
import "./SignInForm.scss";

export const handleLogin = (data, callbackSuccess = () => {}, callbackError = () => {}) => {
    login(data).then((res) => {
        if (res?.status) {
            const { token } = res;

            if (token) {
                const user = parse_jwt(`${token}`);
                if (callbackSuccess instanceof Function) {
                    callbackSuccess(token, { ...user, ...res });
                }
            }
        } else {
            notification.error({
                message: res?.message || t("message.login_error"),
            });
            if (callbackError instanceof Function) {
                callbackError();
            }
        }
    });
};

const SignInForm = ({ setLoading, getData }) => {
    const [form] = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auth = useAuth();
    const [remember, setRemember] = useState(false);

    const handleFinish = () => {
        // General login is not an exception for catching target url:
        clearTargetURLQueryStringException();
        // General login handling:
        form.validateFields().then((formValues) => {
            formValues.device_id = uuidv4();
            setLoading(true);
            login(formValues).then((res) => {
                if (res?.status) {
                    const { token } = res;
                    if (token) {
                        const user = parse_jwt(`${token}`);

                        auth.login({ ...user, ...res }, () => {
                            if (remember) {
                                localStorage.setItem("token", token);
                            } else {
                                setCookie("token", token);
                                // document.cookie = `token=${token}; path=/`;
                            }
                            dispatch(toggleCollapsed(true));
                        });
                        notification.success({
                            message: t("message.login_success"),
                        });
                        setLoading(false);
                    }
                } else {
                    if (res) {
                        setLoading(false);
                        if (res?.status == false) {
                            notification.error({
                                message: res?.message || t("message.login_error"),
                            });
                        }
                        if (res?.verify_status === "inactive") {
                            // navigate("/register", {
                            //     state: {
                            //         verify: true,
                            //         username: form.getFieldValue("username"),
                            //         password: form.getFieldValue("password"),
                            //     },
                            // });
                            getData(form.getFieldValue("username"), form.getFieldValue("password"));
                        }
                    } else {
                        //no internet or fail url req
                        setTimeout(() => {
                            setLoading(false);
                            notification.error({
                                message: res?.message || t("message.login_fail"),
                            });
                        }, 2000);
                    }
                }
            });
        });
    };

    const handleLoginWithGoogle = () => {
        // Third-party login is an exception for catching target url:
        if (getTargetURLQueryString()) {
            setTargetURLQueryStringException();
        }
        // Third-party login handling:
        guestLoginWithGoogle().then((res) => {
            if (res) {
                window.open(res?.url, "_self");
            } else {
                notification.error({
                    message: res.message || t("message.third_party_login_error"),
                });
            }
        });
    };

    const handleLoginWithFacebook = () => {
        // Third-party login is an exception for catching target url:
        if (getTargetURLQueryString()) {
            setTargetURLQueryStringException();
        }
        // Third-party login handling:
        guestLoginWithFacebook().then((res) => {
            if (res) {
                window.open(res?.url, "_self");
            } else {
                notification.error({
                    message: res.message || t("message.third_party_login_error"),
                });
            }
        });
    };

    return (
        <>
            <div className="sign-in-container">
                <div className="sign-in-wrapper">
                    <h1>{t("login.welcome_back")}</h1>
                    <h4>{t("login.login_to_experience")}</h4>

                    <Form form={form} onFinish={handleFinish} layout="vertical" className="sign-in-form">
                        <Form.Item
                            name="username"
                            label={"Email"}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                                {
                                    type: "email",
                                    message: t("message.invalid_email"),
                                },
                            ]}
                        >
                            <Input
                                className="app-input has-rd"
                                placeholder={t("login.enter_your_email")}
                                // prefix={<MailOutlined /> }
                                prefix={<MailFilled />}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label={t("login.password")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                        >
                            <Input.Password
                                className="app-input has-rd"
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                                prefix={<LockFilled />}
                                iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
                            />
                        </Form.Item>

                        <div className="sign-in-option">
                            <Checkbox
                                className="app-checkbox small-checkbox"
                                checked={remember}
                                onChange={() => setRemember(!remember)}
                            >
                                <span>{t("login.remember")}</span>
                            </Checkbox>
                            <Link to={`/forget-password${getTargetURLQueryString()}`}>
                                {t("login.forget_password")}
                            </Link>
                        </div>

                        <Form.Item>
                            <CustomButton
                                className="submit-btn"
                                type="primary"
                                htmlType="submit"
                                title={t("login.sign_in")}
                                hasResponsiveDesign={true}
                            />
                        </Form.Item>
                    </Form>

                    <p className="separator">
                        <span>{t("login.or_login_with")}</span>
                    </p>

                    <div className="sign-in-login-with">
                        <CustomButton
                            type="ghost"
                            icon={GoogleIcon}
                            title={t("login.login_with_google")}
                            hasResponsiveDesign={true}
                            onClick={handleLoginWithGoogle}
                        />
                        <CustomButton
                            type="ghost"
                            icon={FacebookIcon}
                            title={t("login.login_with_facebook")}
                            hasResponsiveDesign={true}
                            onClick={handleLoginWithFacebook}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignInForm;
