import { Input } from "antd";
import { useEffect, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import RecordButton from "src/modules/components/RecordButton";
const { TextArea } = Input;
import { diffChars, diffWords } from "diff";
import "./UserSpeechRecognition.scss";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import chineseToPinyin from "chinese-to-pinyin";
import { closest, distance } from "fastest-levenshtein";
import { CorrectIcon, InCorrectIcon } from "src/utils/drawer";
// import { toRomaji } from "wanakana";

const listSpecialCharacters = [
    "~",
    "!",
    "&",
    "(",
    ")",
    ":",
    "[",
    "]",
    "{",
    "}",
    "|",
    ";",
    ",",
    ".",
    "?",
    "¿",
    "¡",
    "'",
    "`",
    "’",
    "。",
    "，",
    "、",
    "「",
    "『",
    "』",
    "」",
    "？",
    "》",
    "《",
    "……",
    "·",
    "：",
    "”",
    "“",
];

const listSpecialLangs = ["CN", "TH", "KR", "JP"];

const UserSpeechRecognition = (props) => {
    const {
        lang = "EN",
        speaker = "en-US",
        is_sentence,
        setSpeechValues,
        answer,
        valuesSpeech = {},
        checkHadSpeech,
        readOnly = false,
        answered,
        onChange,
        mode,
        spelling,
        isCorrectSubmited,
        hideMicro,
    } = props;

    const { t } = useTranslation();
    const {
        transcript, //Transcription of all speech that has been spoken into the microphone
        interimTranscript, // Transcription of speech that the Web Speech API is still processing
        finalTranscript, // Transcription of speech that the Web Speech API has finished processing.
        resetTranscript,
        listening, // is listening to speech from the microphone.
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition({ transcribing: true, clearTranscriptOnListen: true });

    const [values, setValues] = useValues({
        userAnswer: answered ? answered?.[0] : "",
    });

    const nTimesSpeak = useRef(0); // check xem dan tung noi lan nao chua

    const handleClickRecord = () => {
        if (!listening) {
            resetTranscript();
            setSpeechValues({
                accuracy: 0,
            });
            setValues({
                userAnswer: "",
            });
            SpeechRecognition.startListening({
                continuous: true,
                language: speaker,
            });
            nTimesSpeak.current += 1;

            if (checkHadSpeech instanceof Function) {
                checkHadSpeech();
            }
        } else {
            SpeechRecognition.stopListening(); // it had another method abortListening()
            caclulateAccuracy(answer, transcript);
            // resetTranscript();
        }
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getChineseSpeechApproximately = (transcript, answer) => {
        // chuyển từ về dang pinyin để so sánh. Nếu giống thì user đọc đúng
        // => lấy answer làm kq của user
        let result = transcript;
        if (answer) {
            let text1 = chineseToPinyin(answer);
            let text2 = chineseToPinyin(transcript);

            if (text2 === text1) {
                result = answer;
            }
        }
        setValues({
            userAnswer: result,
        });

        if (onChange instanceof Function) {
            onChange({
                answered: [result || answer],
            });
        }
        return;
    };

    const ignorePunctuation = (str, key) => {
        let fragment = [];
        let tempArr = [];
        for (let i in str) {
            if (!listSpecialCharacters.includes(str[i])) {
                tempArr.push(str[i]);
            } else {
                const node = <span key={`${i}_${key}`}>{tempArr.join("")}</span>;
                fragment.push(node);
                tempArr = [];
                const node2 = (
                    <span style={{ color: "#222222b5" }} key={`${i}__${key}`}>
                        {str[i]}
                    </span>
                );
                fragment.push(node2);
            }
        }
        if (tempArr.length > 0) {
            const node = <span key={`${str}_${key}`}>{tempArr.join("")}</span>;
            fragment.push(node);
            tempArr = [];
        }

        return <>{fragment.map((item) => item)}</>;
    };

    function changed(a, b, answer, type) {
        // let diff = is_sentence ? diffWords(a, b) : diffChars(a, b);
        let diff = listSpecialLangs.includes(lang) || !is_sentence ? diffChars(a, b) : diffWords(a, b);
        if (type !== "userAnswer") {
            diff = formatAnswer(answer, diff);
        }
        let fragment = [];
        for (let i = 0; i < diff.length; i++) {
            if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
                let swap = diff[i];
                diff[i] = diff[i + 1];
                diff[i + 1] = swap;
            }

            let node;
            if (diff[i].removed && !listSpecialCharacters.includes(diff[i].value)) {
                node = (
                    <span key={i} style={{ color: "red" }}>
                        {/* {diff[i].value} */}
                        {ignorePunctuation(diff[i].value, i)}
                    </span>
                );
            } else if (diff[i].added) {
                // node = (
                //     <span key={i} style={{ color: "#406619", background: "#eaf2c2" }}>
                //         {diff[i].value}
                //     </span>
                // );
            } else {
                node = (
                    <span key={i} style={{ color: "#52c41a" }}>
                        {/* {diff[i].value} */}
                        {ignorePunctuation(diff[i].value, i)}
                    </span>
                );
            }
            fragment.push(node);
        }

        return (
            <span style={{ fontWeight: 700, fontSize: 18, textAlign: "left", display: "block" }}>
                <span style={{ fontWeight: 700 }}>{fragment.map((item) => item)}</span>
            </span>
        );
    }

    const formatAnswer = (answer = "", newAnswer = []) => {
        //chuyển từ chữ thường sang chữ hoa như định dang của câu hỏi lúc gv ra đề.
        /* --định dang của arr mà diff trả về:
            + removed: true là các từ ko nằm trong string answer
            + added: true là các từ nằm trong nhưng mà sai
            + !removed && !added là từ đúng.
        */

        let flag = 0;
        if (Array.isArray(newAnswer) && typeof answer === "string") {
            let result = newAnswer.map((item) => {
                if (!item?.added || !(item?.added || item?.removed)) {
                    const newItem = structuredClone(item);
                    const Itemlength = item?.value?.length;
                    newItem.value = answer.slice(flag, flag + Itemlength);
                    flag += item.value?.length;
                    return newItem;
                }
                return item;
            });
            return result;
        }

        return newAnswer;
    };

    const generateStudentAnswer = (type = "") => {
        /* eslint-disable */
        // if (listening) {
        //     return capitalizeFirstLetter(transcript);
        // } else
        if (values.userAnswer) {
            let answ = answer[0];
            // answ = answ ? answ?.replace(/[`~!&():\[\]{}|;,".?¿¡\'\/\\]/gi, "") : "";
            let fntr = values.userAnswer;
            // fntr ? fntr?.replace(/[`~!&():\[\]{}|;,".?¿¡\'\/\\]/gi, "") : "";

            if (type === "userAnswer") {
                return changed(
                    capitalizeFirstLetter(fntr.toLowerCase()),
                    capitalizeFirstLetter(answ.toLowerCase()),
                    values.userAnswer,
                    "userAnswer"
                );
            }
            return changed(
                capitalizeFirstLetter(answ.toLowerCase()),
                capitalizeFirstLetter(fntr.toLowerCase()),
                answer?.[0]
            );
        }
        if (readOnly && answered?.[0]) {
            return changed(
                capitalizeFirstLetter(answer?.[0]?.toLowerCase()),
                capitalizeFirstLetter(values.userAnswer?.toLowerCase())
            );
        }
        return "";
        /* eslint-enable */
    };

    const caclulateAccuracy = (answer, userAnswer) => {
        /* eslint-disable */
        if ((!answer || !userAnswer) && setSpeechValues instanceof Function) {
            setSpeechValues({
                accuracy: 0,
            });
            return;
        }

        let answ = answer?.[0];
        answ = answ ? answ?.toLowerCase() : answer;
        answ = answ?.replace(/[。：，、「『』」？》《……·`~!&():\[\]{}|;,“”".?¿¡\'\/\\]/gi, "");
        answ = answ ? answ.trim() : "";
        let ftranscript = userAnswer ? userAnswer?.toLowerCase() : userAnswer;
        ftranscript = ftranscript?.replace(/[。：，、「『』」？》《……·`~!&():\[\]{}|;,“”".?¿¡\'\/\\]/gi, "");
        ftranscript = ftranscript ? ftranscript.trim() : "";

        // const distance = similar_text(answ, ftranscript);
        // let accuracy = distance / Math.max(answ.length, ftranscript.length);

        const distance1 = distance(answ, ftranscript);
        let accuracy = 1 - distance1 / Math.max(answ.length, ftranscript.length);
        if (accuracy) accuracy = Math.floor(accuracy * 100);

        if (accuracy != valuesSpeech?.accuracy && setSpeechValues instanceof Function) {
            setSpeechValues({
                accuracy: accuracy,
            });
        }
        /* eslint-enable */
    };

    useEffect(() => {
        if (onChange instanceof Function && nTimesSpeak.current > 0) {
            onChange({
                answered: [transcript],
            });
        }

        if (["CN"].includes(lang) && !is_sentence && nTimesSpeak.current > 0) {
            getChineseSpeechApproximately(transcript ? transcript : interimTranscript, answer?.[0]);
        } else if (transcript && answer?.[0]) {
            setValues({
                userAnswer: capitalizeFirstLetter(transcript),
            });
        } else {
        }
        if (!transcript && !listening && setSpeechValues instanceof Function) {
            setSpeechValues({ accuracy: 0 });
        }
    }, [transcript]);

    useEffect(() => {
        caclulateAccuracy(answer, values.userAnswer);
    }, [values.userAnswer, listening]);

    useEffect(() => {
        if (answered?.[0] !== values.userAnswer && mode !== "preview") {
            setValues({
                userAnswer: answered?.[0],
            });
        }
    }, [answered?.[0]]);

    if (!browserSupportsSpeechRecognition) {
        return <span>{t("speech_to_text.no_browser_support")}</span>;
    }

    if (!isMicrophoneAvailable) {
        return <span>{t("speech_to_text.please_allow_microphone")}</span>;
    }

    return (
        <div className="speech-recognition">
            <div className="text-record">
                <table>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: 18, textAlign: "left", display: "block" }}>
                                {t("speech_to_text.question")}
                            </span>
                        </td>
                        <td style={{ paddingLeft: 20 }}>
                            <span style={{ fontWeight: 700, fontSize: 18, textAlign: "left", display: "block" }}>
                                {/* {listening ? answer?.[0] : values?.userAnswer ? generateStudentAnswer() : answer?.[0]} */}
                                {generateStudentAnswer() || answer?.[0]}
                            </span>
                            {/* <span style={{ fontWeight: 600, fontSize: 16, textAlign: "left", display: "block" }}>
                                {spelling && !(mode === "test") && `( ${spelling} )`}
                            </span> */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: 18, textAlign: "left", display: "block" }}>
                                {t("speech_to_text.solution")}
                            </span>
                        </td>
                        <td style={{ paddingLeft: 20 }}>{generateStudentAnswer("userAnswer")}</td>
                        <td className="answer-status">
                            {isCorrectSubmited !== undefined &&
                                (isCorrectSubmited ? (
                                    <span className="correct">
                                        (<CorrectIcon />
                                        <span style={{ marginLeft: 4 }}>{t("question.correct")}</span>)
                                    </span>
                                ) : (
                                    <span className="incorrect">
                                        ( <InCorrectIcon />
                                        <span style={{ marginLeft: 4 }}>{t("question.incorrect")}</span>)
                                    </span>
                                ))}
                        </td>
                    </tr>
                </table>
            </div>
            {!readOnly && !hideMicro && <RecordButton onClick={handleClickRecord} isTalking={listening} />}
        </div>
    );
};

export default UserSpeechRecognition;
