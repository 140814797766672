import { Tabs } from "antd";
import Header from "src/modules/components/Header";
import Profile from "../Profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Setting.scss";
import PaymentHistory from "./PaymentHistory";
import TeamAndOrg from "./TeamAndOrg";
import { useEffect } from "react";
import { useValues } from "src/hooks";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "src/reducers/general";

function Setting() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({ tab: "1" });
    const [values, setValues] = useValues({
        activeTab: "",
    });
    const dispatch = useDispatch();

    const handleChangeTabs = (tab) => {
        if (values.activeTab !== tab) {
            setValues({
                activeTab: tab,
            });
            setSearchParams({
                tab: tab,
            });
        }
    };
    const tab = searchParams.get("tab");
    useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab && values.activeTab !== tab) {
            setValues({
                activeTab: tab,
            });
        } else if (!tab) {
            setSearchParams({ tab: "1" });
        }
    }, [searchParams.get("tab")]);

    useEffect(() => {
        dispatch(setCurrentPage("setting"));
    }, []);

    const headerByTab = {
        1: t("nav.profile"),
        2: t("setting.payment_history"),
        3: t("setting.your_teams_and_org"),
    };

    return (
        <div className="setting_wrapper">
            <div className="setting_header-wrapper">
                <Header
                    backFunc={() => {
                        navigate(-1);
                    }}
                    className="profile_header"
                    // backTitle={isUpdateProfile ? t("profile.update_profile") : t("profile.profile")}
                    backTitle={headerByTab[values.activeTab] || ""}
                />
            </div>

            <Tabs
                defaultActiveKey="1"
                centered
                className="setting_tabs"
                onChange={handleChangeTabs}
                activeKey={values.activeTab}
                destroyInactiveTabPane={false}
            >
                <Tabs.TabPane tab={<span className="setting_tabs-name">{t("nav.profile")}</span>} key="1">
                    {/* Hồ sơ */}
                    <Profile isHideHeader={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>{t("setting.payment_history")}</span>} key="2">
                    {/* Lịch sử thanh toán */}
                    <PaymentHistory isTabActive={values.activeTab == "2"} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>{t("setting.your_teams_and_org")}</span>} key="3">
                    {/* Team và tootr chức của bạn */}
                    <TeamAndOrg isTabActive={values.activeTab == "3"} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}

export default Setting;
