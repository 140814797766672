import { Col, Row, Space } from "antd";
import NewTestCard from "src/modules/components/NewTestCard";
import "./ExamSelect.scss";
import { FileOutlined, FilePdfOutlined } from "@ant-design/icons";
import Button from "src/modules/components/Button";
import { useTranslation } from "react-i18next";
import Header from "src/modules/components/Header";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";

function ExamSelect() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    return (
        <div className="question-bank exam-bank">
            <Header backEnable={true} backTitle={t("header.exam_bank")} actions={[]} />
            <div className="exam-select">
                <div className="exam-select-inner">
                    <NewTestCard
                        icon={<FileOutlined />}
                        title={t("assignment.create_from_scratch")}
                        description={t("assignment.select_question_from_q_bank")}
                        renderActions={() => (
                            <Button
                                type="ghost"
                                title={`${t("shared.create")} ${t("entry_test.exam").toLowerCase()}`}
                                onClick={() => navigate(generate("add_exam_bank"))}
                            />
                        )}
                    />
                    <NewTestCard
                        icon={<FilePdfOutlined />}
                        title={t("assignment.create_from_pdf")}
                        description={t("assignment.upload_pdf_and_create")}
                        ribbonText={t("new")}
                        renderActions={() => (
                            <Button
                                type="ghost"
                                title={`${t("shared.create")} ${t("entry_test.exam").toLowerCase()}`}
                                onClick={() => navigate(generate("exam_pdf_create"))}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default ExamSelect;
