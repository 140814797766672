import React, { useEffect } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { login } from "src/reducers/auth";
import { clearSelectedUserScope, getItemScopeSelected } from "src/api/helpers/userScope";
import { getMe } from "src/api/containers/auth";
import { addStudentToClass, addTeacherToClass, findClass } from "src/api/containers/class";
import { addParticipantToAssignment, findAssignment } from "src/api/containers/assignment";
import { addUserToOrganizationByInvitation } from "src/api/containers/organization";
import { deleteCookie } from "src/utils/helpers";
import "./TargetController.scss";

function TargetController(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const handleAfterGetTargetInfo = (navToRouteName, navToParams, targetInfo, assignment_id) => {
        /**
         * Handle target scope:
         * - Refetch me if target has a scope and its scope is different from user's scope.
         * - Otherwise, handle navigation.
         */
        const targetScopeId = getItemScopeSelected(targetInfo).scopeId;
        if (targetScopeId && targetScopeId !== user.paramsForOrgOrTeam.organization_id) {
            getMe(targetScopeId).then((res) => {
                if (res?.status === true && res?.data?.user) {
                    dispatch(login(res.data.user));
                    // Clear current user scope:
                    clearSelectedUserScope();
                } else {
                    localStorage.removeItem("token");
                    deleteCookie("token");
                }
            });
        } else {
            if (assignment_id) {
                navigate(generate("test", { id: assignment_id }));
                return;
            }
            navigate(generate(navToRouteName, navToParams));
        }
    };

    useEffect(() => {
        if (params.invite_class_id) {
            // HANDLE ACCEPT CLASS INVITATION FOR TEACHER:
            if (user.id && user.role === "teacher") {
                addTeacherToClass(params.invite_class_id).then((res) => {
                    if (res.status === true) {
                        findClass(params.invite_class_id).then((resClass) => {
                            notification.success({
                                message:
                                    (
                                        <>
                                            {t("class.add_teacher_success")}
                                            <div>
                                                <i>{t("class.class")}: </i>
                                                <i>{resClass.data.name || "_"}</i>
                                            </div>
                                        </>
                                    ) ||
                                    res.message ||
                                    "",
                            });
                            handleAfterGetTargetInfo("class", undefined, resClass.data);
                        });
                    } else {
                        notification.error({
                            message: res.message || t("class.add_teacher_error"),
                        });
                        handleAfterGetTargetInfo("class");
                    }
                });
            } else {
                notification.error({
                    message: t("class.you_are_not_teacher"),
                });
                handleAfterGetTargetInfo("home");
            }
        } else if (params.invite_student_class_id) {
            // HANDLE ACCEPT CLASS INVITATION FOR STUDENT:
            if (user.id && user.role === "student") {
                addStudentToClass(params.invite_student_class_id).then((res) => {
                    if (res.status === true) {
                        findClass(params.invite_student_class_id).then((resClass) => {
                            notification.success({
                                message:
                                    (
                                        <>
                                            {t("class.add_student_success")}
                                            <div>
                                                <i>{t("class.class")}: </i>
                                                <i>{resClass.data.name || "_"}</i>
                                            </div>
                                        </>
                                    ) ||
                                    res.message ||
                                    "",
                            });

                            handleAfterGetTargetInfo("home", undefined, resClass.data, res.assignment_id);
                        });
                    } else {
                        notification.error({
                            message: res.message || t("class.add_student_error"),
                        });
                        handleAfterGetTargetInfo("home");
                    }
                });
            } else {
                notification.error({
                    message: t("class.you_are_not_student"),
                });
                handleAfterGetTargetInfo("class");
            }
        } else if (params.assignment_id) {
            // HANDLE ACCEPT CLASS INVITATION FOR STUDENT (ASSIGNMENT):
            if (user.id && user.role === "student") {
                addParticipantToAssignment(params.assignment_id).then((res) => {
                    if (res.status === true) {
                        findAssignment(params.assignment_id).then((resAsgmt) => {
                            notification.success({
                                message:
                                    (
                                        <>
                                            {t("assignment.add_user_successfully")}
                                            {resAsgmt.data?.class?.name ? (
                                                <div>
                                                    <i>{t("assignment.class")}: </i>
                                                    <i>{resAsgmt.data.class.name || "_"}</i>
                                                </div>
                                            ) : null}
                                        </>
                                    ) ||
                                    resAsgmt.message ||
                                    "",
                            });
                            handleAfterGetTargetInfo("home", undefined, resAsgmt.data, res.assignment_id);
                        });
                    } else {
                        notification.error({
                            message: res.message || "Unknown error!",
                        });
                        handleAfterGetTargetInfo("home");
                    }
                });
            } else if (user.role === "teacher") {
                notification.error({
                    message: t("assignment.assignment_teacher_not_allowed"),
                });
                handleAfterGetTargetInfo("class");
            }
        } else if (params.invite_organization_id) {
            // HANDLE ACCEPT ORGANIZATION INVITATION FOR USER:
            if (user.id && user.role === "teacher") {
                addUserToOrganizationByInvitation(params.invite_organization_id).then((res) => {
                    if (res.status === true) {
                        notification.success({
                            message: res.message || <>{t("organization.add_teacher_success")}</> || "",
                        });
                        handleAfterGetTargetInfo("class", undefined, {
                            organization_id: params.invite_organization_id,
                        });
                    } else {
                        notification.error({
                            message: res.message || t("organization.add_teacher_error"),
                        });
                        handleAfterGetTargetInfo("class");
                    }
                });
            } else if (user.role === "student") {
                notification.error({
                    message: t("organization.you_are_not_teacher"),
                });
                handleAfterGetTargetInfo("class");
            }
        }
    }, []);

    return (
        <div className="target-controller-wrapper">
            <div className="tc-title">{t("shared.redirecting")}...</div>
        </div>
    );
}

export default TargetController;
