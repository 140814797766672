import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import QuestionContentDetail from "../components/ContentDetail";
import JSXGraphBoard from "src/modules/components/JSXGraphBoard";
import { compareObjectsDeeply } from "src/utils/helpers/comparison";
import "./GraphNumberLine.scss";

function GraphNumberLine({
    isReadonly = false,
    // Question & answers:
    question = "" /** @param {string} question */,
    answer /** @param {string[]} answer - list of provided answers. */,
    defaultAnswer /** @param {string[]} defaultAnswered - list of selected answers */,
    max_value /** @param {Number} max_value Max value */,
    min_value /** @param {Number} min_value Min value */,
    jump_value /** @param {Number} jump_value Jump value */,
    // Event handlers:
    onChange,
}) {
    const refGraphId = useRef(`qpanel-jsxgraph-container-${uuidv4()}`);

    const refCurrValueSubmit = useRef();
    const [value, setValue] = useState(undefined); // Values: undefined (First time only!), [], Object[].

    const handleChange = (_answersSelected) => {
        setValue(_answersSelected);
        const valueSubmit = _answersSelected
            .map((item) => {
                if (typeof item.value === "number") {
                    return {
                        label: item.label,
                        value: item.value,
                    };
                } else {
                    return undefined;
                }
            })
            .filter((item) => !!item);

        if (onChange instanceof Function) {
            onChange({ answered: valueSubmit.length ? valueSubmit : undefined });
        }
    };

    useEffect(() => {
        if (!value || !compareObjectsDeeply(refCurrValueSubmit.current, defaultAnswer)) {
            // Preparation:
            const providedAnswers = value || answer || [];
            const selectedAnswers = [...(defaultAnswer || [])];
            // Data:
            const newValue = providedAnswers.map((item) => {
                const itemDetail = {
                    ...item,
                };
                // Custom field - type:
                if (!itemDetail.type) {
                    itemDetail.type = "numberline_label";
                }
                // Modified field - value:
                for (let i = 0; i < selectedAnswers.length; i++) {
                    if (itemDetail.label === selectedAnswers[i].label) {
                        itemDetail.value = selectedAnswers[i].value;
                        selectedAnswers.splice(i, 1);
                        break;
                    }
                }
                return itemDetail;
            });
            // Update JSXGraphBoard's value:
            setValue(newValue);
            // Store the current value of this component:
            refCurrValueSubmit.current = defaultAnswer;
        }
    }, []); // Dependency array can be [answer, defaultAnswer].

    return (
        <div className="q-graph-numberline">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-graph-numberline">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="numberline"
                        id={refGraphId.current}
                        width="100%"
                        height="350px"
                        boundingBox={[min_value, 10, max_value, -10]}
                        coordsStep={[jump_value, 0]}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default GraphNumberLine;
