/**
 * Check if the url's pathname refers to a specific target.
 *
 * Đoạn cần match: /vi/assignment/62c3df9c49627681680a041d
 * - Trường hợp đúng:
 * /vi/assignment/62c3df9c49627681680a041d
 * /vi/assignment/62c3df9c49627681680a041d/
 * - Trường hợp sai (KHÔNG ĐƯỢC MATCH CÁI NÀO):
 * /vi/assignment/62c3df9c49627681680a041d/abc
 * /vi/assignment/62c3df9c49627681680a041d/abc/
 * /vi/class/62b4358a85b9dbb46f0b53f3/assignment/62c3df9c49627681680a041d
 */
export const checkTargetByURLPathname = (targetName = "", pathname = "") => {
    // List of target patterns:
    const patternList = {
        invAssignment: new RegExp(/\/([a-z]{2})\/assignment\/(\w{1,})/),
        invClassTeacher: new RegExp(/\/([a-z]{2})\/class-invitation\/(\w{1,})/),
        invClassStudent: new RegExp(/\/([a-z]{2})\/class-invitation-student\/(\w{1,})/),
        invOrganization: new RegExp(/\/([a-z]{2})\/organization-invitation\/(\w{1,})/),
    };
    // Check if the pathname refers to a valid target:
    if (targetName && pathname) {
        const pattern = patternList[targetName];

        let str = pathname;
        if (str[str.length - 1] === "/") {
            str = str.slice(0, str.length - 1);
        }

        const _r = str.match(pattern);
        if (_r && _r[0]) {
            if (str.replace(_r[0], "")?.length === 0) {
                return true;
            }
        }
    }
    return false;
};

/**
 * Check if the url's query string refers to any valid target.
 * @param {string} queryString Example: ?invite_class_id=63e5b3d560664e6d4c0beb62.
 * @returns Boolean.
 */
export const checkIfTargetMatchURLQueryString = (queryString = "") => {
    // List of target patterns:
    const patternList = [
        new RegExp(/\?assignment_id=(\w{1,})/),
        new RegExp(/\?invite_class_id=(\w{1,})/),
        new RegExp(/\?invite_student_class_id=(\w{1,})/),
        new RegExp(/\?invite_organization_id=(\w{1,})/),
    ];
    const regexArr = patternList.map((pattern) => new RegExp(pattern));
    // Check if the query string refers to a valid target:
    if (queryString) {
        let str = queryString;
        for (let i = 0; i < regexArr.length; i++) {
            const _r = str.match(regexArr[i]);
            if (_r && _r[0]) {
                if (str.replace(_r[0], "")?.length === 0) {
                    return true;
                }
            }
        }
        return false;
    }
    return false;
};

/**
 * Get query string if the current action is trying co catch target.
 * @returns A query string.
 */
export const getTargetURLQueryString = () => {
    let str = "";
    if (location.search && checkIfTargetMatchURLQueryString(location.search)) {
        str += location.search;
    }
    return str;
};

/**
 * Set exception to disable query-string-for-catching-target.
 */
export const setTargetURLQueryStringException = () => {
    localStorage.setItem("currentTargetQueryStringException", "true");
};

/**
 * Clear exception to enable query-string-for-catching-target.
 */
export const clearTargetURLQueryStringException = () => {
    localStorage.removeItem("currentTargetQueryStringException");
};

/**
 * Check if there is any exception to disable query-string-for-catching-target.
 */
export const checkTargetURLQueryStringException = () => {
    const _r = localStorage.getItem("currentTargetQueryStringException");
    if (_r === "true") {
        return true;
    } else {
        return false;
    }
};
