import { useEffect } from "react";

const CRUDFolder = ({ controller, makeFolder }) => {
    useEffect(() => {
        controller.set("crud_folder.create", (folderName, parentInfo, doIf) => {
            const res = makeFolder(folderName, parentInfo);
            if (res instanceof Promise) {
                // Pending:
                doIf?.("pending");
                res.then((resFolder) => {
                    // FileManager - Content:
                    const _activeFolder = controller.call("sidebar.get_active_folder");
                    if (_activeFolder.id === parentInfo.id) {
                        controller.call("content.add_files", [resFolder]);
                    }
                    // FileManager - Sidebar:
                    controller.call(`sidebar_item.${parentInfo.id}.add_child`, resFolder);
                    // Fulfilled:
                    doIf?.("fulfilled", resFolder);
                }).catch((resErr) => {
                    // Rejected:
                    doIf?.("rejected", resErr);
                });
            }
        });
        controller.set("crud_folder.create_with_validate", (folderName, parentInfo, doIf, siblingList) => {
            folderName = folderName?.trim() || "";
            let folName = folderName;
            // 1. Check if folder name already exists:
            const currDuplicatedFolders = (siblingList || [])?.filter((item) => {
                return (
                    item.type === "folder" &&
                    (item.name?.length === folName.length ||
                        (item.name?.length > folName.length &&
                            item.name[folName.length] === " " &&
                            item.name.substring(folName.length + 1).match(/\((.*?)\)/)?.length)) &&
                    item.name?.substring(0, folName.length) === folName
                );
            });
            // 2. If it already exists, show confirmation:
            if (currDuplicatedFolders.length === 0) {
                controller.call("crud_folder.create", folName, parentInfo, doIf);
            } else if (currDuplicatedFolders.length === 1) {
                // Update folder name to be unique:
                folName = folName + " (1)";
                // Confirmation:
                doIf?.(
                    "confirm",
                    `Thư mục "${folderName}" đã tồn tại! Bạn có muốn thay thế bằng thư mục "${folName}" không?`,
                    () => {
                        controller.call("crud_folder.create", folName, parentInfo, doIf);
                    }
                );
            } else if (currDuplicatedFolders.length > 1) {
                // Update folder name to be unique:
                let currNum = 1;
                currDuplicatedFolders?.forEach((item) => {
                    let temp = item.name?.match(/\((.*?)\)/);
                    if (temp?.length && parseInt(temp[1]) > currNum) {
                        currNum = parseInt(temp[1]);
                    }
                });
                folName = folName + ` (${currNum + 1})`;
                // Confirmation:
                doIf?.(
                    "confirm",
                    `Thư mục "${folderName}" đã tồn tại! Bạn có muốn thay thế bằng thư mục "${folName}" không?`,
                    () => {
                        controller.call("crud_folder.create", folName, parentInfo, doIf);
                    }
                );
            }
        });

        return () => {
            controller.remove("crud_folder.create");
            controller.remove("crud_folder.create_with_validate");
        };
    }, []);

    return null;
};

export default CRUDFolder;
