import api from "..";

export const fetchCategories = (fetchParams = {}) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.class_ids?.length && filters.push(`class_ids:${fetchParams.class_ids.join(",")}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _category_fields: `${["name"]}`,
        // Pagination:
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };

    return api
        .get("/category", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
