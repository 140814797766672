import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fileManagerConfigs } from "src/modules/components/FileManager/utils/helpers";
import "./FileViewer.scss";

const FileViewer = ({ fileSrc = "", fileType = "", fileName = "" }) => {
    const { t } = useTranslation();

    let displayType = "";
    if (fileManagerConfigs.imageAcceptedExtensionList.includes("." + fileType.toLowerCase()) === true) {
        displayType = "image";
    } else if (fileManagerConfigs.audioAcceptedExtensionList.includes("." + fileType.toLowerCase()) === true) {
        displayType = "audio";
    } else if (fileManagerConfigs.videoAcceptedExtensionList.includes("." + fileType.toLowerCase()) === true) {
        displayType = "video";
    }

    useEffect(() => {
        if (fileSrc && displayType === "audio") {
            const audioElem = document.getElementById("audio-file-viewer");
            audioElem.volume = 0.5;
        }
    }, [fileSrc, fileType]);

    if (!fileSrc || !fileType) {
        return <div className="file-viewer view-file-error">{t("my_storage.view_file_error")}</div>;
    }

    if (displayType === "image") {
        return (
            <img
                onClick={(e) => {
                    e.stopPropagation();
                }}
                src={fileSrc || "#"}
                alt={fileName || "unknown_file"}
                title={fileName || "unknown_file"}
            ></img>
        );
    } else if (displayType === "video") {
        return (
            <div className="file-viewer view-file-wrapper align-center">
                <video
                    controls
                    width="320"
                    height="240"
                    title={fileName || "unknown_file"}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <source src={fileSrc || "#"} type="video/mp4" />
                    {/* <source src="file_path.ogg" type="video/ogg" /> */}
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    } else if (displayType === "audio") {
        return (
            <div className="file-viewer view-file-wrapper align-bottom">
                <audio id="audio-file-viewer" controls title={fileName || "unknown_file"}>
                    <source src={fileSrc || "#"} type="audio/mpeg" />
                    {/* <source src="file_path.ogg" type="audio/ogg" /> */}
                    Your browser does not support the audio element!
                </audio>
            </div>
        );
    } else {
        return (
            <div className="file-viewer view-other-file-wrapper">
                <iframe
                    src={fileSrc || "#"}
                    title={fileName || "unknown_file"}
                    style={{ width: "100%", height: "100%", border: "2px solid #aaa" }}
                ></iframe>
            </div>
        );
    }
};

export default FileViewer;
