import { Input } from "antd";
import clsx from "clsx";
import InputEditor from "src/modules/components/InputEditor";
import { Bin } from "src/utils/drawer";
import "./Answer.scss";

const Answer = (props) => {
    const { label, index, labelAlign, value, deleteAlign, listName, onChange, onDeleteAnswer } = props;

    const handleChange = (data) => {
        onChange(data);
    };

    const handleDeleteAnswer = () => {
        onDeleteAnswer(listName, index);
    };

    return (
        <div
            className={clsx(
                "drag-drop-detail__answer",
                deleteAlign === "right" && "drag-drop-detail__answer--align-right"
            )}
        >
            <div className="drag-drop-detail__deleteIcon" onClick={handleDeleteAnswer}>
                <Bin />
            </div>
            <div
                className={clsx(
                    "drag-drop-detail__answer-skeleton",
                    labelAlign === "right" && "drag-drop-detail__answer-skeleton--align-right"
                )}
            >
                {label && <span className="drag-drop-detail__label">{label}</span>}
                <div className="drag-drop-detail__input">
                    <InputEditor
                        // tabIndex={labelAlign === "right" ? (index + 1) * 2 : (index + 1) * 2 - 1}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Answer;
