import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    detail_exam_pdf: {},
    current_file_id: {},
    lastedQuestionChange: {
        question_id: "",
        type: "", //create || update
    },
};

export const examSlice = createSlice({
    name: "exam",
    initialState,
    reducers: {
        setDetailExamData: (state, action) => {
            state.detail_exam_pdf = action.payload;
        },
        setCurrentFileId: (state, action) => {
            state.current_file_id = action.payload;
        },
        setLastedQuestionChange: (state, action) => {
            state.lastedQuestionChange = action.payload;
        },
    },
});

export const { setDetailExamData, setCurrentFileId, setLastedQuestionChange } = examSlice.actions;

export default examSlice.reducer;
