import { ButtonsWithFilter } from "../../../Editor/editor/plugins/math/buttonsWithFilter";
import "./LatexOptions.scss";

const LatexOptions = ({ filterOptions, filterOptionSelected, onSelectOption }) => {
    return (
        <div className="latex-options">
            <ButtonsWithFilter
                filterOptions={filterOptions}
                filterOptionSelected={filterOptionSelected}
                onSelectOption={onSelectOption}
                isFillBox
                sizeRow={8}
            />
        </div>
    );
};

export default LatexOptions;
