import api from "..";

export const createFeedback = (data) => {
    return api
        .post("/feedback", data)
        .then(({ status, message, id }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message: message }));
};

export const skipFeedback = () => {
    return api
        .put("/skip_feedback_modal")
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message: message }));
};
