import { BulbOutlined, DoubleRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Spin, Tooltip, notification } from "antd";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { fetchQuestionType } from "src/api/containers/questionType";
import { getQuestionKeyByType } from "src/api/helpers/question";
import iconClose from "src/assets/images/action-close.svg";
import { useMediaQuery } from "src/hooks";
import QuestionDetail from "src/modules/containers/QuestionDetail";
import DetailPassageQuestion from "src/modules/containers/QuestionDetail/containers/DetailPassageQuestion";
import { NewIcon, TagVipIcon, VipIcon } from "src/utils/drawer";
import { useRouting } from "src/utils/router";
import "./ModalSelectQuestionType.scss";
import defaultThumbnail from "src/assets/images/default-thumbnail.jpg";

const defaultPreviewType = {
    src: null,
    name,
    isLoading: false,
    type: null,
    description: null,
    status_point: null, // ""
    status_question: null, // "super_vip"
};

const ModalSelectQuestionType = ({
    type = "",
    exam_file_id,
    refetch = () => {},
    onClickItem,
    exam_types,
    className,
    ...rest
}) => {
    const [questionTypeGroups, setQuestionTypeGroups] = useState(null);
    const currMedia = useMediaQuery(["(max-width: 768px)"]);
    const previewRef = useRef(null);
    const scrollTopRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    // Collapsed tutorial in mobile
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [previewType, setPreviewType] = useState(defaultPreviewType);

    const [modal, setModal] = useState(null);

    const handleShowPreview = ({ type, description, status_point, status_question, gif_src, name }) => {
        if (previewRef.current !== type) {
            previewRef.current = type;
            setPreviewType({
                isLoading: true,
                src: gif_src,
                type,
                name,
                description,
                status_point,
                status_question,
            });

            setTimeout(() => {
                setPreviewType((prevValue) => ({
                    ...prevValue,
                    isLoading: false,
                }));
            }, 1000);
        }
    };

    const handleShowScrollTopBtn = (e) => {
        const height = e.target.scrollTop;

        if (isCollapsed && scrollTopRef.current) {
            if (height > 50 && scrollTopRef.current.style.visibility === "hidden") {
                scrollTopRef.current.style.visibility = "visible";
            } else if (height < 50 && scrollTopRef.current.style.visibility === "visible") {
                scrollTopRef.current.style.visibility = "hidden";
            }
        }
    };

    const handleClickQuestionType = (type) => {
        setModal(type);
        if (exam_types === "pdf") {
            rest.onCancel?.();
        }
    };

    const handlePreviewQuestion = (e, typeItem) => {
        const { type } = typeItem;
        e.preventDefault();
        e.stopPropagation();

        if (previewType.type !== type) {
            setIsCollapsed(true);
            handleShowPreview(typeItem);
            return;
        } else {
            setIsCollapsed(!isCollapsed);
            setPreviewType(defaultPreviewType);
            previewRef.current = null;
        }
    };

    useEffect(() => {
        fetchQuestionType({ exam_types: exam_types })
            .then((res) => {
                if (res.status) {
                    setQuestionTypeGroups(res.data);
                } else {
                    notification.error({ message: res.message || "Failed to get question types!" });
                }
            })
            .catch((err) => {
                notification.error({ message: err || "Failed to get question types!" });
            });
    }, []);

    return (
        <>
            <Modal
                centered
                closeIcon={<img src={iconClose}></img>}
                footer={null}
                {...rest}
                className={clsx("modal-select-question", className)}
            >
                <div className="modal-select-question__skeleton">
                    <div
                        className={clsx(
                            "modal-select-question__group",
                            isCollapsed && "modal-select-question__group--collapsed"
                        )}
                        onScroll={handleShowScrollTopBtn}
                    >
                        <div className="modal-select-question__group-header">{t("question.select_question_type")}</div>
                        <div className="modal-select-question__group-list">
                            {questionTypeGroups?.map((group, itemIndex) => {
                                if (type === "passage" && group.childs[0].type === "passage") {
                                    return;
                                }

                                return (
                                    <div className="modal-select-question__group-item" key={itemIndex}>
                                        <div className="modal-select-question__group-item__title">{group.name}</div>
                                        <div className="modal-select-question__type-list">
                                            {group.childs?.map((typeItem) => {
                                                const typesExcludeInPassage = ["highlight"];
                                                if (
                                                    type === "passage" &&
                                                    typesExcludeInPassage.includes(typeItem.type)
                                                ) {
                                                    return;
                                                }

                                                const questionKey = getQuestionKeyByType(typeItem.type);
                                                return (
                                                    <Link
                                                        {...(type !== "exam_bank" && questionKey
                                                            ? {
                                                                  to: generate("add_question", {
                                                                      questionKey,
                                                                  }),
                                                              }
                                                            : {})}
                                                        {...(type === "exam_bank" && questionKey
                                                            ? {
                                                                  onClick: () => handleClickQuestionType(typeItem.type),
                                                              }
                                                            : {})}
                                                        className={clsx(
                                                            "modal-select-question__type-item",
                                                            !questionKey && "disable"
                                                        )}
                                                        key={`q-${typeItem.type}`}
                                                        {...(currMedia.breakpointValue !== 768
                                                            ? {
                                                                  onMouseEnter: () => handleShowPreview(typeItem),
                                                                  onMouseLeave: () =>
                                                                      handleShowPreview(defaultPreviewType),
                                                              }
                                                            : {})}
                                                        {...(onClickItem &&
                                                        onClickItem instanceof Function &&
                                                        isCollapsed === false
                                                            ? {
                                                                  onClick: (e) => {
                                                                      e.preventDefault();
                                                                      questionKey && onClickItem(typeItem.type);
                                                                  },
                                                              }
                                                            : {})}
                                                    >
                                                        <div
                                                            className={clsx(
                                                                "modal-select-question__type-item-skeleton",
                                                                !questionKey && "disable"
                                                            )}
                                                        >
                                                            <div
                                                                className="modal-select-question__type-icon"
                                                                style={{
                                                                    backgroundColor: group.color,
                                                                }}
                                                            >
                                                                {/* <RecordIcon /> */}
                                                                <img src={typeItem.icon_src} width={20} height={20} />
                                                                {(typeItem.status_question === "super_vip" ||
                                                                    typeItem.status_question === "vip") && (
                                                                    <div className="modal-select-question__group-privilege">
                                                                        <VipIcon />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="modal-select-question__type-label">
                                                                {typeItem.name}{" "}
                                                                {!questionKey && (
                                                                    <Tooltip title="Tính năng đang được phát triển">
                                                                        <BulbOutlined />
                                                                    </Tooltip>
                                                                )}
                                                                {typeItem.is_new && questionKey && <NewIcon />}
                                                            </div>
                                                            <QuestionCircleOutlined
                                                                className="modal-select-question__group-tips"
                                                                onClick={(e) => handlePreviewQuestion(e, typeItem)}
                                                            />
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="modal-select-question__preview">
                        <div
                            className={clsx(
                                "modal-select-question__preview-skeleton",
                                isCollapsed && "modal-select-question__preview-skeleton--collapsed"
                            )}
                        >
                            <Spin spinning={previewType.isLoading}>
                                <div className="modal-select-question__preview-thumb">
                                    <img
                                        src={previewType.src || defaultThumbnail}
                                        onLoad={() => {
                                            setPreviewType({
                                                ...previewType,
                                                isLoading: false,
                                            });
                                        }}
                                    />
                                </div>
                            </Spin>
                            <div className="modal-select-question__preview-content">
                                <div className="modal-select-question__preview-title">
                                    {previewType.name || t("select_question.null")}
                                </div>
                                <div className="modal-select-question__preview-description">
                                    {previewType.description}
                                </div>
                            </div>
                            <div className="modal-select-question__preview-benefit">
                                {previewType.status_question === "vip" ||
                                    (previewType.status_question === "super_vip" && <TagVipIcon />)}

                                {previewType.status_point && (
                                    <span className="modal-select-question__preview-benefit__label">
                                        {t(`select_question.scoring_${previewType.status_point}`)}
                                    </span>
                                )}
                            </div>

                            {currMedia.breakpointValue === 768 && (
                                <div
                                    style={{ visibility: "hidden" }}
                                    ref={scrollTopRef}
                                    className="modal-select-question__preview-collapse-btn"
                                    onClick={() => {
                                        setIsCollapsed(false);
                                        scrollTopRef.current.style.visibility = "hidden";
                                    }}
                                >
                                    <DoubleRightOutlined />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>

            {type === "exam_bank" && (
                <Modal
                    visible={modal !== null}
                    centered
                    title={""}
                    closable={false}
                    footer={null}
                    onCancel={() => setModal(null)}
                    width={"80%"}
                    bodyStyle={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 100px)",
                        backgroundColor: "#F0F6FB",
                    }}
                    destroyOnClose={true}
                >
                    <>
                        {modal === "passage" ? (
                            <DetailPassageQuestion
                                type="exam_bank"
                                typeOfQuestion={modal}
                                closeModal={() => setModal(null)}
                                refetch={refetch}
                            />
                        ) : (
                            <QuestionDetail
                                type="exam_bank"
                                exam_types={exam_types}
                                exam_file_id={exam_file_id}
                                typeOfQuestion={modal}
                                closeModal={() => setModal(null)}
                                refetch={refetch}
                            ></QuestionDetail>
                        )}
                    </>
                </Modal>
            )}
        </>
    );
};

export default React.memo(ModalSelectQuestionType);
