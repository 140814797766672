import { Layout, Modal } from "antd";
import React, { useEffect } from "react";
import { fetchStudentAssignments } from "src/api/containers/assignment";
import { useFetch } from "src/hooks";
import Sidebar from "./Sidebar";
import "./TestLayout.scss";

const TestLayout = (props) => {
    const { children } = props;
    const [
        loadingListAssignmentOfStudent,
        ListAssignmentOfStudent,
        paginationListAssignmentOfStudent,
        fetchListAssignmentOfStudent,
        refetchListAssignmentOfStudent,
    ] = useFetch({}, fetchStudentAssignments);

    useEffect(() => {
        fetchListAssignmentOfStudent({}, true);
    }, []);

    return (
        <div className="testLayout_wrapper">
            <Layout hasSider style={{ background: "none" }}>
                <Layout.Content className="container">{children}</Layout.Content>
                <Sidebar listTest={ListAssignmentOfStudent} />
            </Layout>
        </div>
    );
};

TestLayout.layout = "TestLayout";

export default TestLayout;
