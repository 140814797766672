import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import ResultAnswer from "../../../components/ResultAnswer";
import "./Answer.scss";

const Answer = (props) => {
    const { isAlert = false, answer = {}, handleChooseAnswer = () => {} } = props;

    const generateClassName = () => {
        if (isAlert === true) {
            return answer.isCorrect ? " chosen-correctly" : " chosen-incorrectly";
        } else {
            if (answer.chosen) {
                return " chosen";
            } else {
                return "";
            }
        }
    };

    return (
        <div
            className={`answer-for-drag-drop${isAlert ? generateClassName() : " "}`}
            onClick={() => handleChooseAnswer()}
        >
            <div className="sec-left">
                {isAlert ? (
                    <ResultAnswer resultStatus={answer.isCorrect ? "correct" : "incorrect"}>
                        <HTMLDisplayer rootType="inline" htmlString={answer?.answer} />
                    </ResultAnswer>
                ) : (
                    <ResultAnswer>
                        <HTMLDisplayer rootType="inline" htmlString={answer?.answer} />
                    </ResultAnswer>
                )}
            </div>
            <div className="sec-right">
                <span>{answer.charASCII}. </span>
                <HTMLDisplayer rootType="inline" htmlString={answer?.text} />
            </div>
        </div>
    );
};

export default Answer;
