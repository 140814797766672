import React from "react";
import { t } from "i18next";
import { Button, Col, Form, Input, Modal, Row, Select, Space, notification } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import { userRoles } from "src/api/containers/auth";
import { addUsersToClass } from "src/api/containers/class";
import { useValues } from "src/hooks";
import { validatePhoneNumber } from "src/utils/helpers/validation";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import "./ModalAddUser.scss";

const ModalAddUser = ({ classId = "", modalClassName = "", visible, onOk, onCancel, ...rest }) => {
    const [form] = Form.useForm();

    const [values, setValues] = useValues({
        loading: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleOk = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();

        if (!classId) {
            notification.error({
                message: "Please select a class!",
            });
            return;
        }

        if (formData.phone) {
            const isNotPhoneNumberLbl = validatePhoneNumber(formData.phone);
            if (isNotPhoneNumberLbl) {
                notification.error({
                    message: isNotPhoneNumberLbl,
                });
                return;
            }
        }

        setValues({ loading: true });
        formData.class_id = classId;
        addUsersToClass(formData)
            .then(({ status, data, message }) => {
                setValues({ loading: false });
                if (status) {
                    form.resetFields();
                    notification.success({ message: message || t("message.add_success") });
                    handleOk();
                } else if (message) {
                    notification.error({ message: message || t("message.add_error") });
                }
            })
            .catch((err) => {
                setValues({ loading: false });
                notification.error({ message: err });
            });
    };

    const renderFormItemsForUsers = () => {
        return (
            <Form.List name="users">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            return (
                                <div key={`std${key}`} className="student-row">
                                    <div className="num">{`(${key + 1})`}</div>
                                    <Row gutter={[8, 8]}>
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "username"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("message.required"),
                                                    },
                                                    {
                                                        type: "email",
                                                        message: t("message.invalid_email"),
                                                    },
                                                ]}
                                            >
                                                <Input className="app-input has-rd" placeholder="Email" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "name"]}
                                                rules={[{ required: true, message: t("message.required") }]}
                                            >
                                                <Input
                                                    className="app-input has-rd"
                                                    placeholder={t("profile.full_name")}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "role"]}
                                                rules={[{ required: true, message: t("message.required") }]}
                                            >
                                                <Select
                                                    className="app-select has-rd"
                                                    placeholder={t("profile.role")}
                                                    allowClear
                                                    showSearch
                                                    optionFilterProp="children"
                                                >
                                                    {Object.keys(userRoles).map((item, itemIndex) => {
                                                        return (
                                                            <Select.Option key={`urole${itemIndex}`} value={item}>
                                                                {t(userRoles[item].title)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Form.Item {...restField} name={[name, "phone"]}>
                                                <Input className="app-input has-rd" placeholder={t("profile.phone")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8}>
                                            <Form.Item {...restField} name={[name, "gender"]}>
                                                <Select
                                                    className="app-select has-rd"
                                                    placeholder={t("profile.select_gender")}
                                                >
                                                    <Select.Option value={1}>{t("shared.male")}</Select.Option>
                                                    <Select.Option value={0}>{t("shared.female")}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className="acts">
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </div>
                                </div>
                            );
                        })}

                        <Form.Item style={{ marginBottom: "45px" }}>
                            <Button
                                type="dashed"
                                size="large"
                                style={{ borderRadius: 10 }}
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                {t("shared.add_new")}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        );
    };

    return (
        <Modal
            centered
            visible={visible}
            title={t("class.add_user")}
            closeIcon={<SvgClose />}
            footer={null}
            className={
                "app-modal flexible-height type-primary modal-addusertoclass" +
                (modalClassName ? ` ${modalClassName}` : "")
            }
            wrapClassName="modal-addusertoclass-wrap"
            onOk={handleSubmit}
            onCancel={handleCancel}
            {...rest}
        >
            <Form
                form={form}
                layout="vertical"
                className="app-form type-primary has-rd"
                initialValues={{ users: [{}] }}
                onFinish={handleSubmit}
            >
                {renderFormItemsForUsers()}

                <div className="form-actions">
                    <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<SvgTick />}
                        title={t("shared.submit")}
                        isLoading={values.loading}
                    ></CustomButton>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalAddUser;
