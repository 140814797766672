import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, notification, Row, Spin, Typography } from "antd";
import { useValues } from "src/hooks";
import Editor from "src/modules/components/Editor";
import {
    addChildPassageForPassageQuestion,
    findChildPassage,
    createQuestion,
    updateChildPassage,
    findPassageQuestion,
} from "src/api/containers/question";
import Header from "src/modules/components/Header";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import QuestionContentDetail from "src/modules/components/QuestionPanel/components/ContentDetail";
import "./DetailPassage.scss";
import { animationId } from "src/reducers/animation";
import configEditor from "src/utils/configEditor";
import { replaceBlobWithBase64 } from "src/utils/helpers";

function DetailPassage(props) {
    const { questionId, childId, onGoBack, type = "" } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [values, setValues] = useValues({
        loading: false,
        ownerID: "",
    });
    const [currentTab, setCurrentTab] = useState("modify");

    const handleCancel = (e, idNewPassageQuestion) => {
        if (e) {
            e.preventDefault();
        }
        if (onGoBack && onGoBack instanceof Function) {
            onGoBack(idNewPassageQuestion);
        } else {
            navigate(-1);
        }
    };

    const convertBlobToBase64 = async (content) => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(content, "text/html");
        let images = doc.querySelectorAll("img");
        if (images.length > 0) {
            await Promise.all(Array.from(images).map(replaceBlobWithBase64));
            content = doc.body.innerHTML;
        }
        return content;
    };

    const handleSubmit = async () => {
        let formData = form.getFieldsValue();
        if (!formData.title || !formData.content) {
            return;
        }
        //convert all images has tag src is Blob
        formData.content = await convertBlobToBase64(formData.content);

        // Make api calls:
        setValues({ ...values, loading: true });
        if (!childId) {
            if (questionId) {
                // Add a child passage for the question:
                addChildPassageForPassageQuestion({
                    ...formData,
                    question_id: questionId,
                }).then((childRes) => {
                    setValues({ ...values, loading: false });
                    if (childRes.status === true) {
                        notification.success({
                            message: t("message.add_success"),
                        });
                        handleCancel();
                    }
                });
            } else {
                // 1. Create a new passage question:
                createQuestion({
                    type: "passage",
                    is_publish: false,
                    is_public: false,
                }).then((questionRes) => {
                    if (questionRes.status === true) {
                        dispatch(animationId({ name: "question", add: questionRes.id }));
                        // 2. Add a child passage for the question:
                        addChildPassageForPassageQuestion({
                            ...formData,
                            question_id: questionRes.id,
                        }).then((childRes) => {
                            setValues({ ...values, loading: false });
                            if (childRes.status === true) {
                                notification.success({
                                    message: t("message.add_success"),
                                });
                                handleCancel(undefined, questionRes.id);
                            }
                        });
                    }
                });
            }
        } else {
            // Update the child passage of a question:
            updateChildPassage(childId, formData).then((childRes) => {
                setValues({ ...values, loading: false });
                if (childRes.status === true) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    handleCancel();
                }
            });
        }
    };

    useEffect(() => {
        if (questionId && childId) {
            setValues({ ...values, loading: true });
            Promise.all([findPassageQuestion(questionId), findChildPassage(childId)]).then((res) => {
                if (res[0].status && res[1].status) {
                    form.setFieldsValue({
                        heading: res[1].data.heading || "",
                        title: res[1].data.title || "",
                        content: res[1].data.content || "",
                    });
                    setValues({ ...values, loading: false, ownerID: res[0].data?.user_id || "" });
                } else {
                    setValues({ ...values, loading: false });
                }
            });
        } else if (questionId && !childId) {
            setValues({ ...values, loading: true });
            findPassageQuestion(questionId).then((res) => {
                if (res.status) {
                    setValues({ ...values, loading: false, ownerID: res.data?.user_id || "" });
                } else {
                    setValues({ ...values, loading: false });
                }
            });
        }
    }, []);

    return (
        <div className="question-detail-wrapper">
            <Header
                backTitle={t("question.header_passage_add_child")}
                backFunc={handleCancel}
                actions={[
                    {
                        title: t("header.modify"),
                        icon: <Icon name="icon-modify" fill={currentTab === "modify" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: () => {
                            setCurrentTab("modify");
                        },
                        type: currentTab === "modify" ? "primary" : "",
                    },
                    {
                        title: t("header.preview"),
                        icon: <Icon name="icon-preview" fill={currentTab === "preview" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: () => {
                            setCurrentTab("preview");
                        },
                        type: currentTab === "preview" ? "primary" : "",
                    },
                ]}
            />

            <Spin spinning={values.loading}>
                <Form form={form} onFinish={handleSubmit}>
                    <div
                        className="question-detail detail-passage"
                        style={{
                            ...(currentTab !== "modify" ? { display: "none" } : {}),
                        }}
                    >
                        <div className="question-detail-content">
                            <div className="passage-header">
                                <Form.Item
                                    name="title"
                                    label={t("question_passage.title")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    <Input
                                        className="app-input"
                                        placeholder={t("question_passage.placeholder_title")}
                                    />
                                </Form.Item>
                                <Form.Item name="heading" label={t("question_passage.heading")}>
                                    <Input
                                        className="app-input"
                                        placeholder={t("question_passage.placeholder_heading")}
                                    />
                                </Form.Item>
                            </div>

                            <Row className="question-editor">
                                <Typography className="question-editor-title">{t("q.question")}</Typography>
                                <Form.Item
                                    name="content"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    <Editor config={editorConfigQuestion.current}></Editor>
                                </Form.Item>
                            </Row>
                        </div>

                        <div className="question-detail-actions">
                            <div className="btn-group">
                                <Form.Item>
                                    <CustomButton
                                        type="grey"
                                        icon={<Icon name="icon-delete" />}
                                        title={t("shared.cancel")}
                                        onClick={handleCancel}
                                    ></CustomButton>
                                    {(!questionId || user.id === values.ownerID || type === "exam_bank") && (
                                        <CustomButton
                                            htmlType="submit"
                                            type="primary"
                                            icon={<Icon name="icon-rocket" />}
                                            title={t("shared.save")}
                                        ></CustomButton>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    {currentTab === "preview" && (
                        <div
                            className="question-detail-preview"
                            style={{
                                ...(currentTab !== "preview" ? { display: "none" } : {}),
                            }}
                        >
                            <div className="question-preview-content">
                                <div className="passage-child-preview">
                                    <div className="passage-child-title">{form.getFieldsValue().title || ""}</div>
                                    <div className="passage-child-heading">{form.getFieldsValue().heading || ""}</div>
                                    <div className="passage-child-content">
                                        <QuestionContentDetail value={{ question: form.getFieldsValue().content }} />
                                    </div>
                                </div>
                            </div>

                            <div className="question-preview-actions">
                                <div className="btn-group">
                                    <Form.Item>
                                        <CustomButton
                                            type="grey"
                                            icon={<Icon name="icon-delete" />}
                                            title={t("shared.cancel")}
                                            onClick={handleCancel}
                                        ></CustomButton>
                                        {(!questionId || user.id === values.ownerID || type === "exam_bank") && (
                                            <CustomButton
                                                htmlType="submit"
                                                type="primary"
                                                icon={<Icon name="icon-rocket" />}
                                                title={t("shared.save")}
                                            ></CustomButton>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    )}
                </Form>
            </Spin>
        </div>
    );
}

export default DetailPassage;
