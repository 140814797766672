import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Dropdown, Empty, Menu, notification, Popconfirm, Row, Space } from "antd";
import {
    FieldTimeOutlined,
    UserOutlined,
    EllipsisOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import moment from "moment";
import { default as CustomButton } from "src/modules/components/Button";
import ModalDetailRound from "../ModalDetailRound";
import Icon from "src/modules/components/Icon";
import { useRouting } from "src/utils/router";
import { useNavigate } from "react-router-dom";
import { checkDemoAccountAction } from "src/api/helpers/demoAccount";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { SwiperSlide, Swiper } from "swiper/react";
import { ReviewIcon } from "src/utils/drawer";
import Pencil from "src/assets/images/pencil.svg";

// Import Swiper styles
import "swiper/swiper.min.css";

import SwiperButton from "../SwiperButton";
import RoundStatus from "../RoundStatus";
import { useMediaQuery, useValues } from "src/hooks";
import "./Rounds.scss";
import Tooltip from "src/modules/components/Tooltip";
import { deleteCompetition } from "src/api/containers/Competition";
import { deleteAssignment } from "src/api/containers/assignment";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
import { useMemo } from "react";

const slidePerPage = {
    320: {
        slidesPerView: 1,
        spaceBetween: 20,
    },
    576: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    992: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 3,
        spaceBetween: 30,
    },
    1920: {
        slidesPerView: 5,
        spaceBetween: 30,
    },
};
const Rounds = (props) => {
    const {
        handleRefetchAsignment = () => {},
        competition = {},
        listAssignments = [], //list vòng thi
    } = props;

    const { accountType, user } = useSelector((state) => state.auth);
    const { generate } = useRouting();
    const navigate = useNavigate();

    // const [swiperRef, setSwiperRef] = useState(null);
    const swiperRef = useRef(null);
    const { rolesAllow, pagesAllow, checkPermission } = permissionUser;

    const checkRoles = useMemo(() => {
        return {
            edit_competition: checkPermission(pagesAllow.competition, rolesAllow.update, user),
            delete_competition: checkPermission(pagesAllow.competition, rolesAllow.delete, user),
        };
    }, [user]);

    const [values, setValues] = useValues({
        isShowModalDetailRound: false,
        classId: "",
        classScopeId: "",
        editingAssignment: {},
        isBeginning: true,
        isEnd: false,
    });

    const handleDeleteRound = (id) => {
        if (id) {
            deleteAssignment(id).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload class list:
                    handleRefetchAsignment();
                } else {
                    notification.error({
                        message: res.message || "",
                    });
                }
            });
        }
    };

    const renderMenu = (item) => {
        if (!item) {
            return null;
        }

        return (
            <Menu className="class-item-menu">
                {(checkRoles.edit_competition || !user?.currentOrg) && (
                    <Menu.Item
                        key="menu-item-1"
                        onClick={() => {
                            setValues({
                                isShowModalDetailRound: true,
                                classId: item.id,
                                classScopeId: getItemScopeSelected(item).scopeId,
                                editingAssignment: item,
                            });
                        }}
                        disabled={checkDemoAccountAction(accountType, "class", "update") === false}
                    >
                        <Space className="class-item-menu-btn-wrapper">
                            <Icon name="icon-modify" />
                            {t("shared.update")}
                        </Space>
                    </Menu.Item>
                )}

                {(checkRoles.delete_competition || !user?.currentOrg) && (
                    <Popconfirm
                        title={`${t("shared.msg_confirm_delete")}?`}
                        onConfirm={() => {
                            handleDeleteRound(item.id);
                        }}
                        okText={t("shared.yes")}
                        cancelText={t("shared.no")}
                        style={{ backgroundColor: "unset" }}
                    >
                        <Menu.Item
                            key="menu-item-2"
                            disabled={checkDemoAccountAction(accountType, "class", "delete") === false}
                        >
                            <Space className="class-item-menu-btn-wrapper">
                                <Icon name="icon-delete" />
                                {t("shared.delete")}
                            </Space>
                        </Menu.Item>
                    </Popconfirm>
                )}
            </Menu>
        );
    };

    const getRoundStatus = (curentDate, beginDate, endDate) => {
        if (moment(curentDate).isBefore(beginDate)) {
            return "upcoming";
        }
        if (moment(curentDate).isAfter(endDate)) {
            return "finished";
        }
        if (moment(curentDate).isAfter(beginDate) && moment(curentDate).isBefore(endDate)) {
            return "happening";
        }
        return "";
    };

    const handleChangeSlide = (type) => {
        if (type === "prev") {
            swiperRef.current?.slidePrev();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        } else if (type === "next") {
            swiperRef.current?.slideNext();
            setValues({ isEnd: swiperRef.current?.isEnd, isBeginning: swiperRef.current?.isBeginning });
        }
    };

    const isRoundEnded = (time) => {
        return moment(new Date()).isAfter(moment(time));
    };

    return (
        <div className="class-list rounds">
            <Row gutter={[30, 30]} wrap="true">
                <Col span={24}>
                    <div className="rounds-header">
                        <div className="rounds-header-title">
                            <h2>{competition?.name}</h2>
                        </div>

                        <Can I={rolesAllow.create} page={pagesAllow.competition} orLogic={() => !user?.currentOrg}>
                            <CustomButton
                                type="primary"
                                icon={Pencil}
                                title={t("ranking.add_round")}
                                onClick={() => {
                                    setValues({ isShowModalDetailRound: true, classId: "", classScopeId: "" });
                                }}
                                disabled={false}
                            ></CustomButton>
                        </Can>
                    </div>
                </Col>
            </Row>
            <Row justify="end">
                <Col>
                    <div className="rounds-header-actions">
                        <div className="rounds-scroll-btn">
                            <Button
                                disabled={values.isBeginning}
                                onClick={() => handleChangeSlide("prev")}
                                icon={<ArrowLeftOutlined />}
                            ></Button>
                            <Button
                                onClick={() => handleChangeSlide("next")}
                                icon={<ArrowRightOutlined />}
                                disabled={values.isEnd}
                            ></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                {/* {!(user?.currentOrg?.is_organization_owner || user?.currentOrg?.is_team_owner) && (
                    <Col xs={24} md={12} lg={8} xxl={6}>
                        <div className="class-item-add">
                            <CustomButton
                                title={t("class.add_class")}
                                icon={<PlusCircleOutlined />}
                                onClick={() => setValues({ isShowModalDetailRound: true, classId: "", classScopeId: "" })}
                                disabled={checkDemoAccountAction(accountType, "class", "create") === false}
                            />
                        </div>
                    </Col>
                )} */}
                <Col span={24}>
                    <Swiper
                        // slidesPerView={getSlidePerPage(currMedia?.breakpointValue)}
                        // centeredSlides={true}
                        autoplay={true}
                        spaceBetween={30}
                        centeredSlidesBounds={true}
                        breakpoints={slidePerPage}
                        pagination={{
                            type: "fraction",
                        }}
                        className="mySwiper"
                    >
                        <SwiperButton swiperRef={swiperRef} style={{ display: "none" }} />
                        {listAssignments.map((item, index) => (
                            <Col key={index} xs={24} md={12} lg={8} xxl={6}>
                                <SwiperSlide key={index}>
                                    <div className="class-item-wrapper" style={{ minWidth: 200 }}>
                                        <div
                                            className="class-item"
                                            // onClick={() => navigate({ pathname: generate("ranking", { id: item.id }) })}
                                        >
                                            <div className="class-item-header-top">
                                                <span className="round-index">{`${t("ranking.round")} ${
                                                    item?.order
                                                }`}</span>
                                                <RoundStatus
                                                    status={getRoundStatus(
                                                        new Date(),
                                                        item?.begin_date,
                                                        item?.end_date
                                                    )}
                                                />
                                            </div>

                                            <Tooltip title={item?.name}>
                                                <p className="class-item-header">{item?.name}</p>
                                            </Tooltip>
                                            {/* <Tooltip title={item?.name}> */}
                                            {/* </Tooltip> */}
                                            {/* <div className="class-item-student">
                                                {item.user_avatars?.avatars?.length ? (
                                                    <Avatar.Group maxCount={6}>
                                                        {item.user_avatars.avatars.map((user, index) => (
                                                            <Avatar src={user?.src || NoAvatar} key={index} />
                                                        ))}
                                                        {item.user_avatars.user_avatars_left ? (
                                                            <Avatar>+{item.user_avatars.user_avatars_left}</Avatar>
                                                        ) : null}
                                                    </Avatar.Group>
                                                ) : null}
                                            </div>
                                            <p>
                                                <QrcodeOutlined className="class-item-icon" />
                                                {`${t("class.code")}: ${item.code ? item.code : ""}`}
                                            </p> */}

                                            <p>
                                                <FieldTimeOutlined className="class-item-icon" />
                                                {`${t("class.begin_date")}: ${
                                                    item.begin_date
                                                        ? moment(item.begin_date).format("DD/MM/YYYY HH:mm")
                                                        : ""
                                                }`}
                                            </p>
                                            <p>
                                                <FieldTimeOutlined className="class-item-icon" />
                                                {`${t("class.end_date")}: ${
                                                    item.end_date
                                                        ? moment(item.end_date).format("DD/MM/YYYY HH:mm")
                                                        : ""
                                                }`}
                                            </p>
                                            <p>
                                                <UserOutlined className="class-item-icon" />
                                                {`${t("shared.owner")}: ${item?.user?.name || ""}`}
                                            </p>
                                            <CustomButton
                                                title={t("ranking.view_result")}
                                                icon={<ReviewIcon />}
                                                type="primary"
                                                isDisabled={false}
                                                onClick={() => {
                                                    const a = generate("ranking", { assignment_id: item?.id });
                                                    navigate(
                                                        `${a}?assignment_id=${item?.id}&competition_id=${competition?.id}`
                                                    );
                                                }}
                                            />
                                        </div>
                                        {/* {!isRoundEnded(item?.end_date) && ( */}
                                        <Dropdown
                                            overlay={renderMenu(item)}
                                            trigger={["click"]}
                                            placement="bottomRight"
                                        >
                                            <Button className="class-item-dropdown-btn">
                                                <EllipsisOutlined />
                                            </Button>
                                        </Dropdown>
                                        {/* )} */}
                                    </div>
                                </SwiperSlide>
                            </Col>
                        ))}
                    </Swiper>
                </Col>
            </Row>

            {listAssignments.length <= 0 && <Empty description={t("ranking.no_rounds")} />}

            <ModalDetailRound
                // isRoundEnded={competition}
                competition={competition}
                visible={values.isShowModalDetailRound}
                classId={values.classId}
                editingAssignment={values.editingAssignment}
                previousItem={(function () {
                    if (values.editingAssignment?.id) {
                        return values.editingAssignment?.order > 1
                            ? listAssignments?.[values.editingAssignment.order - 2]
                            : {};
                    } else {
                        if (listAssignments?.length > 0) {
                            return listAssignments?.[listAssignments?.length - 1];
                        }
                    }
                    return {};
                })()}
                nextItem={(function () {
                    if (values.editingAssignment?.id) {
                        return listAssignments?.[values.editingAssignment.order] || {};
                    }
                    return {};
                })()}
                organization={values.classScopeId ? { organization_id: values.classScopeId } : undefined}
                onOk={() => {
                    handleRefetchAsignment();
                    setValues({ isShowModalDetailRound: false });
                }}
                onCancel={() => {
                    setValues({ isShowModalDetailRound: false, editingAssignment: {} });
                }}
                destroyOnClose
            ></ModalDetailRound>
        </div>
    );
};

export default Rounds;
