import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Modal, notification, Row, Spin, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { fetchCategories } from "src/api/containers/category.js";
import { cloneExam, createExam, findExam, updateExam } from "src/api/containers/exam";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import useValues from "src/hooks/useValues";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "src/modules/components/Header";
import Icon from "src/modules/components/Icon";
import QuestionContent from "src/modules/components/QuestionContent";
import QuestionPanel from "src/modules/components/QuestionPanel";
import Table from "src/modules/components/Table";
import { animationId } from "src/reducers/animation";
import { clearListAnswers } from "src/reducers/answer";
import { setCurrentPage } from "src/reducers/general";
import { getQuestionListDataPreview } from "src/api/helpers/question";
import QuestionDetail from "../QuestionDetail";
import DetailPassageQuestion from "../QuestionDetail/containers/DetailPassageQuestion";
import AddExamQuestions from "./components/AddExamQuestions";
import SettingPublishingStatus from "./components/SettingPublishingStatus";
import SettingTopBar from "./components/SettingTopBar";
import iconChevronLeft from "src/assets/images/icon-question-chevron-left.svg";
import iconChevronRight from "src/assets/images/icon-question-chevron-right.svg";
import { EditPencil, ExamBin, ExamDown, ExamUp, SvgCross } from "src/utils/drawer";
import LatexDangerously from "src/modules/components/LatexDangerously";
import "./DetailExam.scss";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";

const DetailExam = ({ type = "", handleCloseModal = () => {} }) => {
    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // Exam scope:
    const [examScopeId, setExamScopeId] = useState(false);

    const [form] = useForm();
    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const [values, setValues] = useValues({
        loading: false,
        optionListCategory: [],
        questions: [],
        ownerID: "",
        isPublish: false,
    });

    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState({
        tabKey: "modify", // Values: "modify" || "preview" || "".
        data: null,
    });
    const [isModalAddQuestions, setIsModalAddQuestions] = useState(false);
    const [isShowAnswers, setIsShowAnswers] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);

    const questions = values.questions;
    const [currentQuestionOrder, setCurrentQuestionOrder] = useState(0);

    // Question preview:
    const qDataListPreview = useMemo(() => {
        return getQuestionListDataPreview(values.questions);
    }, [questions]);

    const qDataPreview = useMemo(() => {
        const questionInfo = qDataListPreview[currentQuestionOrder] || {};

        try {
            questionInfo.answered = currentTab?.data[questionInfo?.id];
        } catch (err) {
            // questionInfo = undefined;
        }

        if (questionInfo?.type === "highlight_square") {
            questionInfo.examBankPrevew = true;
        }

        // Trường hợp tạo đề api sẽ trả questionInfo trong trường detail.
        // Trường hợp xem lại đề đã tạo api sẽ trả về questionInfo bên ngoài và không có trường detail
        if (questionInfo?.detail?._id) {
            const data = {
                ...questionInfo,
                ...questionInfo.detail,
            };

            return data;
        }
        return questionInfo;
    }, [qDataListPreview, qDataListPreview[currentQuestionOrder], currentTab?.data]);

    const columns = useMemo(
        () => [
            {
                title: t("shared.category"),
                dataIndex: "category",
                key: "category",
                width: 150,
                render: (text, record) => record?.category?.name,
            },
            {
                title: t("shared.tags"),
                dataIndex: "tags",
                key: "tags",
                width: 200,
                render: (text, record) => {
                    return (
                        <div className="tags-wrapper">
                            {record?.tags?.map((tag) => (
                                <Tag className="app-tag-label" key={tag._id || tag.id} color={tag.color}>
                                    {tag.name.toUpperCase()}
                                </Tag>
                            ))}
                        </div>
                    );
                },
            },
            {
                title: t("q.question"),
                dataIndex: "question",
                key: "question",
                width: 350,
                render: (text, record) => {
                    let htmlStr = record?.question;
                    htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");
                    return (
                        <div className="table-content-format">
                            <div className="text-limit-lines">
                                <HTMLDisplayer htmlString={htmlStr} />
                            </div>
                        </div>
                    );
                },
            },
            {
                title: t("shared.type"),
                dataIndex: "type",
                key: "type",
                width: 150,
                render: (text, record) => record?.detail?.question_type?.name || record?.question_type?.name,
            },
            {
                title: t("shared.score"),
                dataIndex: "score",
                key: "score",
                width: 150,
                render: (text, record) => (
                    <InputNumber
                        className={`${record?.type === "passage" ? "app-input passage-input-disable" : "app-input"}`}
                        value={record?.score}
                        onChange={(value) => handleMutateScore(value, record)}
                        readOnly={record?.type === "passage"}
                    ></InputNumber>
                ),
            },
            {
                dataIndex: "action",
                key: "action",
                width: 150,
                render: (text, record) => {
                    return (
                        <div className="table-action">
                            {user.id === record?.user?.id || !record.user ? (
                                <Tooltip placement="top" title={t("shared.update")}>
                                    <span className="table-action__icon" onClick={() => setEditingQuestion(record)}>
                                        <EditPencil />
                                    </span>
                                </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={t("shared.delete")}>
                                <span className="table-action__icon" onClick={() => handleDeleteQuestion(record)}>
                                    <ExamBin />
                                </span>
                            </Tooltip>
                            <Tooltip placement="top" title={t("exam_bank.down")}>
                                <span className="table-action__icon" onClick={() => handleOrderDown(record)}>
                                    <ExamDown />
                                </span>
                            </Tooltip>
                            <Tooltip placement="top" title={t("exam_bank.up")}>
                                <span className="table-action__icon" onClick={() => handleOrderUp(record)}>
                                    <ExamUp />
                                </span>
                            </Tooltip>
                        </div>
                    );
                },
            },
        ],
        [values.questions]
    );

    const handleMutateScore = (value, record) => {
        const newQuestionList = [...values.questions];
        const index = newQuestionList.findIndex((question) => question.id === record.id);
        newQuestionList[index].score = value;
        setValues({ ...values, questions: newQuestionList });
    };

    const handleUpdateQuestion = (newQuestion) => {
        const newQuestionList = values.questions;
        const index = newQuestionList.findIndex((question) => {
            return question.id === newQuestion.id;
        });

        newQuestionList[index] = {
            ...values.questions[index],
            ...newQuestion,
        };

        setValues({ ...values, questions: [...newQuestionList] });
    };

    const handleDeleteQuestion = (record) => {
        const index = values.questions.findIndex((item) => item.id === record.id);
        if (index >= 0) {
            // Reorder the list of selected questions:
            let num = values.questions[index].order || 0;
            const arr = [...values.questions.filter((item) => item.id !== record.id)];
            for (let i = index; i < arr.length; i++) {
                arr[i].order = num;
                num++;
            }
            setValues({
                questions: arr,
            });
            setCurrentQuestionOrder(currentQuestionOrder - 1 >= 0 ? currentQuestionOrder - 1 : 0);
        }
    };

    const handleOrderUp = (record) => {
        const newQuestionList = [...values.questions] || [];
        const index = newQuestionList.findIndex((item) => item.id === record.id);
        if (index > 0) {
            let num = newQuestionList[index - 1].order || 0;
            // Swap:
            newQuestionList.splice(index, 1);
            newQuestionList.splice(index - 1, 0, record);
            // Reorder:
            for (let i = index - 1; i < newQuestionList.length; i++) {
                newQuestionList[i].order = num;
                num++;
            }

            // Update:
            setValues({
                questions: newQuestionList,
            });
            setCurrentQuestionOrder(0);
        }
    };

    const handleOrderDown = (record) => {
        const newQuestionList = [...values.questions] || [];
        const index = newQuestionList.findIndex((item) => item.id === record.id);
        if (index < newQuestionList.length - 1) {
            let num = newQuestionList[index].order || 0;
            // Swap:
            const temp = newQuestionList[index + 1];
            newQuestionList[index + 1] = newQuestionList[index];
            newQuestionList[index] = temp;
            // Reorder:
            for (let i = index; i < newQuestionList.length; i++) {
                newQuestionList[i].order = num;
                num++;
            }
            // Update:
            setValues({
                questions: newQuestionList,
            });
            setCurrentQuestionOrder(0);
        }
    };

    const handleAddQuestion = (e) => {
        e.preventDefault();
        setIsModalAddQuestions(true);
    };

    const handleGoBack = (e) => {
        e.preventDefault();
        if (type === "modal") {
            handleCloseModal();
        } else {
            navigate(-1);
        }
    };

    const handleCloneExam = (e) => {
        e.preventDefault();

        cloneExam(params.id, {
            organization_id: fParamsOrgMember.organization_id,
        }).then((res) => {
            if (res.status) {
                navigate(generate("exam_bank"));
            } else {
                notification.error({
                    message: res.message,
                });
            }
        });
    };

    const handleSaveExam = (isPublish = false) => {
        let formData = form.getFieldsValue();

        // Generate values to be sent to database:
        // - Exam scope:
        let isPublic = formData.is_public;
        let organizationId = null; // Must be "null" if the question is public/private scope.
        if (isPublic !== true && isPublic !== false) {
            organizationId = isPublic;
            isPublic = true;
        }
        formData.is_public = isPublic;
        formData.organization_id = organizationId;
        // - Exam form data:
        if (isPublish === false) {
            // Form data:
            formData.questions = questions.map((question, index) => ({
                question_id: question.id,
                order: index + 1,
                score: question?.score,
            }));
            formData.is_publish = false;
        } else {
            const { questions } = values;
            form.validateFields(["category_id", "name", "description", "questions"]);
            // Category:
            if (!formData.category_id) {
                return;
            }
            // Question list:
            if (questions.length === 0) {
                notification.error({
                    message: t("exam_bank.question_list_is_empty"),
                });
                return;
            }
            // Question list with score:
            const noScoreQuestion = questions.filter((question) => question?.score === null);
            if (noScoreQuestion.length > 0) {
                notification.error({
                    message: t("exam_bank.score_is_empty"),
                });
                return;
            }
            // Form data:
            formData.questions = questions.map((question, index) => ({
                question_id: question.id,
                order: index + 1,
                score: question?.score,
            }));
            formData.is_publish = true;
        }

        // Make api calls:
        setValues({ loading: true });
        if (params.id && type !== "modal") {
            updateExam(params.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "exam", update: params.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    navigate(-1);
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false });
            });
        } else {
            formData.type = "normal";
            createExam(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "exam", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    if (type === "modal") {
                        handleCloseModal();
                    } else {
                        navigate(-1);
                    }
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setValues({ loading: false });
            });
        }
    };

    const handleSubmit = () => {
        handleSaveExam(true);
    };

    const handleSaveAsDraft = (e) => {
        e.preventDefault();
        handleSaveExam(false);
    };

    const handleChangeAnswered = (id, newValue) => {
        // Case highlight để tạm thời v sau sửa api sẽ xoá
        if (newValue.passage_highlighted) {
            setCurrentTab({
                ...currentTab,
                data: {
                    ...currentTab.data,
                    [id]: newValue,
                },
            });
        } else {
            setCurrentTab({
                ...currentTab,
                data: {
                    ...currentTab.data,
                    [id]: newValue.answered,
                },
            });
        }
    };

    const handleNextQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder + 1;
        if (continueQuestionOrder < questions.length) {
            setCurrentQuestionOrder(continueQuestionOrder);
        }
    };
    const handlePrevQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder - 1;
        if (continueQuestionOrder >= 0) {
            setCurrentQuestionOrder(continueQuestionOrder);
        }
    };

    const renderActionList = () => {
        return (
            <div className="btn-group">
                <Form.Item>
                    <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={params.id ? t("shared.back") : t("shared.cancel")}
                        onClick={(e) => handleGoBack(e)}
                    ></CustomButton>
                    {(!params.id || (params.id && user.id === values.ownerID && !values.isPublish)) && (
                        <CustomButton
                            htmlType="submit"
                            type="grey"
                            icon={<Icon name="icon-save" />}
                            title={t("shared.save_draft")}
                            onClick={(e) => handleSaveAsDraft(e)}
                        ></CustomButton>
                    )}
                    {params.id && user.id !== values.ownerID && type !== "modal" ? (
                        <>
                            {checkIfActionIsAllowed("exam", "clone") === true ? (
                                <CustomButton
                                    type="primary"
                                    icon={<Icon name="icon-rocket" />}
                                    title={t("shared.clone")}
                                    onClick={(e) => handleCloneExam(e)}
                                ></CustomButton>
                            ) : null}
                        </>
                    ) : (
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={
                                params.id && user.id === values.ownerID && values.isPublish
                                    ? t("shared.save")
                                    : t("shared.publish")
                            }
                            onClick={handleSubmit}
                        ></CustomButton>
                    )}
                </Form.Item>
            </div>
        );
    };

    useEffect(() => {
        dispatch(setCurrentPage("exam_bank"));
        setValues({ loading: true });
        dispatch(clearListAnswers());
        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setValues({ optionListCategory: res.data });
        });
        if (params.id && type !== "modal") {
            findExam(params.id, { organization_id: fParamsOrgMember.organization_id }).then(({ status, data }) => {
                if (status) {
                    setValues({
                        isPublish: data?.is_publish,
                        ownerID: data?.user?.id,
                        questions: data?.question_list,
                    });
                    if (user.id !== data?.user?.id) {
                        setCurrentTab({ ...currentTab, tabKey: "preview" });
                    }
                    const examFormData = {
                        name: data?.name,
                        category_id: data?.category_id,
                        max_score: data?.max_score,
                        is_public: data?.is_public,
                    };
                    // 1. Correct form values:
                    const examScope = getItemScopeSelected(data);
                    if (examScope.scopeId) {
                        examFormData.is_public = examScope.scopeId;
                        setExamScopeId(examScope.scopeId);
                    }
                    // 2. Update form states & values:
                    form.setFieldsValue(examFormData);
                }
                setValues({
                    loading: false,
                });
            });
        } else {
            form.setFieldsValue({
                is_public: false,
                max_score: 0,
                name: "",
                category_id: null,
            });
            setValues({
                loading: false,
            });
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            max_score: values.questions.reduce((acc, cur) => acc + cur?.score, 0),
        });
    }, [values.questions]);

    return (
        <div className="exam-detail-wrapper">
            <Header
                backTitle={t("header.exam_bank")}
                backFunc={handleGoBack}
                actions={
                    (user.id === values.ownerID && !values.loading) || !params.id
                        ? [
                              {
                                  title: t("header.modify"),
                                  icon: (
                                      <Icon
                                          name="icon-modify"
                                          fill={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"}
                                      />
                                  ),
                                  link: "",
                                  onClick: () => {
                                      setCurrentQuestionOrder(0);
                                      dispatch(clearListAnswers());
                                      setIsShowAnswers(false);
                                      setCurrentTab({ ...currentTab, tabKey: "modify" });
                                  },
                                  type: currentTab.tabKey === "modify" ? "primary" : "",
                              },
                              {
                                  title: t("header.preview"),
                                  icon: (
                                      <Icon
                                          name="icon-preview"
                                          fill={currentTab.tabKey === "preview" ? "#0077FF" : "#fff"}
                                      />
                                  ),
                                  link: "",
                                  onClick: () => {
                                      if (questions.length) {
                                          setCurrentTab({ ...currentTab, tabKey: "preview" });
                                      } else {
                                          notification.warning({
                                              message: t("message.warning_require_question"),
                                          });
                                      }
                                  },
                                  type: currentTab.tabKey === "preview" ? "primary" : "",
                              },
                          ]
                        : [
                              {
                                  title: t("header.preview"),
                                  icon: <Icon name="icon-preview" fill="#0077FF" />,
                                  link: "",
                                  type: "primary",
                              },
                          ]
                }
            />

            <Spin spinning={values.loading}>
                <Form form={form}>
                    <div
                        className="exam-detail"
                        style={{
                            ...(currentTab.tabKey !== "modify" ? { display: "none" } : {}),
                        }}
                    >
                        <div className="exam-detail-content">
                            <SettingTopBar
                                optionListCategory={values.optionListCategory}
                                onChangeCategoryList={(newCateList) => {
                                    setValues({ ...values, optionListCategory: newCateList });
                                }}
                            />

                            <div className="exam-question-list">
                                <div className="list-topbar">
                                    <span className="topbar-title">{t("exam_bank.list_question")}</span>
                                    <span className="topbar-btn-wrapper">
                                        <CustomButton
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            title={t("exam_bank.add_question")}
                                            onClick={handleAddQuestion}
                                        ></CustomButton>
                                    </span>
                                </div>
                                <div className="list-content question-list-table">
                                    <Table
                                        className="app-table"
                                        columns={columns}
                                        dataSource={values.questions || []}
                                        pagination={{
                                            pageSize: 10,
                                            position: ["bottomCenter"],
                                        }}
                                        scroll={{ x: "auto" }}
                                    />
                                </div>
                            </div>

                            <Row className="exam-formitems">
                                <Col>
                                    <Form.Item
                                        name="max_score"
                                        label={t("exam_bank.total_score")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <InputNumber className="app-input" readOnly />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <SettingPublishingStatus examScopeId={examScopeId} />
                        </div>

                        <div className="exam-detail-actions">{renderActionList()}</div>
                    </div>

                    {currentTab.tabKey === "preview" && (
                        <div
                            className="exam-detail-preview"
                            style={{
                                ...(currentTab.tabKey !== "preview" ? { display: "none" } : {}),
                            }}
                        >
                            <div className="exam-preview-content">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsShowAnswers(!isShowAnswers);
                                    }}
                                    className="question-show-answer"
                                >
                                    {isShowAnswers ? t("q_detail.hide_answer") : t("q_detail.show_answer")}
                                </button>
                                {!isShowAnswers && (
                                    <Form.Item name={currentQuestionOrder} className="exam-preview">
                                        <QuestionPanel
                                            mode="preview"
                                            goNextQuestion={handleNextQuestionOrder}
                                            goPrevQuestion={handlePrevQuestionOrder}
                                            questionInfo={qDataPreview}
                                            questionAmount={values.questions.length}
                                            questionOrder={qDataPreview.order}
                                            onChange={(newValue) => handleChangeAnswered(qDataPreview.id, newValue)}
                                        />
                                    </Form.Item>
                                )}
                                {isShowAnswers && (
                                    <div className="question-panel">
                                        <div className="question-label-wrapper">
                                            <div className="question-label">
                                                <span className="label-wrapper">
                                                    <span className="label-question">
                                                        {t("question_panel.question")}
                                                    </span>
                                                    <span className="label-number">
                                                        {currentQuestionOrder + 1
                                                            ? `${currentQuestionOrder + 1 < 10 ? "0" : ""}${
                                                                  currentQuestionOrder + 1
                                                              }`
                                                            : "00"}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="question-content">
                                            <QuestionContent
                                                showResults={true}
                                                questionInfo={qDataPreview}
                                                isShowScore={false}
                                                questionNumber={currentQuestionOrder}
                                            />
                                        </div>
                                        <div className="question-nav">
                                            <button
                                                type="button"
                                                className={`nav-btn-wrapper ${
                                                    currentQuestionOrder + 1 === 1 ? "disabled" : ""
                                                }`}
                                                disabled={currentQuestionOrder + 1 === 1}
                                                onClick={handlePrevQuestionOrder}
                                            >
                                                <span className="nav-btn prev">
                                                    <img src={iconChevronLeft} alt=""></img>
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className={`nav-btn-wrapper ${
                                                    currentQuestionOrder === values.questions.length - 1
                                                        ? "disabled"
                                                        : ""
                                                }`}
                                                disabled={currentQuestionOrder === values.questions.length - 1}
                                                onClick={handleNextQuestionOrder}
                                            >
                                                <span className="nav-btn next">
                                                    <img src={iconChevronRight} alt=""></img>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <SettingPublishingStatus examScopeId={examScopeId} />
                            </div>

                            <div className="exam-preview-actions">{renderActionList()}</div>
                        </div>
                    )}
                </Form>
            </Spin>

            <Modal
                className="add-exam-question-modal scroll_primary"
                centered
                title={t("exam_bank.add_question_to_exam")}
                footer={null}
                visible={isModalAddQuestions}
                onCancel={() => setIsModalAddQuestions(false)}
                destroyOnClose
                width="calc(100vw - 40px)"
                height="calc(100vh - 40px)"
                style={{
                    maxWidth: "1400px",
                    maxHeight: "1400px",
                    margin: "20px 0",
                }}
                bodyStyle={{
                    overflowY: "auto",
                    height: "calc(100vh - 100px)",
                    backgroundColor: "#F0F6FB",
                }}
                closeIcon={<Icon name="icon-cross-thin" />}
            >
                <AddExamQuestions
                    setIsModalAddQuestions={setIsModalAddQuestions}
                    value={[...values.questions]}
                    onChange={(value) => {
                        // Handle add question to exam
                        if (values.questions.length === 0) {
                            setCurrentQuestionOrder(0);
                        }
                        setValues({
                            questions: value,
                        });
                    }}
                />
            </Modal>

            <Modal
                visible={editingQuestion !== null}
                centered
                title={""}
                closable={false}
                footer={null}
                onCancel={() => {
                    setEditingQuestion(null);
                }}
                width={"80%"}
                bodyStyle={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 100px)",
                    backgroundColor: "#F0F6FB",
                }}
                destroyOnClose={true}
            >
                {editingQuestion && (
                    <>
                        {editingQuestion.type === "passage" ? (
                            <DetailPassageQuestion
                                editingQuestion={editingQuestion}
                                closeModal={() => setEditingQuestion(null)}
                                type={"exam_bank"}
                                onChange={handleUpdateQuestion}
                            />
                        ) : (
                            <QuestionDetail
                                type="exam_bank"
                                editingQuestion={editingQuestion}
                                typeOfQuestion={editingQuestion.type}
                                closeModal={() => setEditingQuestion(null)}
                                onChange={handleUpdateQuestion}
                            ></QuestionDetail>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default DetailExam;
