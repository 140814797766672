import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import useValues from "src/hooks/useValues";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";

const FilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchUserList = () => {},
    handleCloseFilterMenu = () => {},
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [values, setValues] = useValues({
        genderList: [
            { name: t(`profile.gender_male`), value: 1 },
            { name: t(`profile.gender_female`), value: 0 },
        ],
        statusList: [
            { name: t(`profile.active_false`), value: false },
            { name: t(`profile.active_true`), value: true },
        ],
    });
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            gender: formData.gender,
            active: formData.active,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.gender !== undefined &&
            newFilterParams.push({
                name: "gender",
                value: fetchParams.gender,
                labelName: t("profile.gender"),
                labelValue: fetchParams.gender === null ? t(`profile.gender_male`) : t(`profile.gender_female`),
            });
        fetchParams.active !== undefined &&
            newFilterParams.push({
                name: "active",
                value: fetchParams.active,
                labelName: t("profile.active"),
                labelValue: fetchParams.active ? t("profile.active_true") : t("profile.active_false"),
            });
        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleFetchUserList({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                gender: newFormData.gender,
                active: newFormData.active,
            };
            setCurrFilterParams(filterParams);
            handleFetchUserList({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid form-horizontal" layout="horizontal">
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Form.Item name="gender" label={t("profile.gender")}>
                        <Select
                            className="app-select"
                            placeholder={t("profile.select_gender")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                        >
                            {values.genderList.map((opt, i) => {
                                return (
                                    <Select.Option key={`gender-${i}`} value={opt.value}>
                                        {opt.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="active" label={t("profile.active")}>
                        <Select
                            className="app-select"
                            placeholder={t("profile.select_active")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                        >
                            {values.statusList.map((opt, i) => {
                                return (
                                    <Select.Option key={`active-${i}`} value={opt.value}>
                                        {opt.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
