import StudentFrame from "src/modules/components/StudentFrame";
import ClassImg from "src/assets/images/Rectangle5289.png";
import Button from "src/modules/components/Button";
import ReviewIcon from "src/assets/images/review-icon.svg";
import StudentHeader from "src/modules/components/StudentHeader";
import { Dropdown, Empty, Spin, Tag } from "antd";
import InputSearch from "src/modules/components/Input/InputSearch";
import FilterMenu from "./components/FilterMenu";
import { useEffect, useState, useMemo } from "react";
import { t } from "i18next";
import { fetchClassesForStudent } from "src/api/containers/class";
import { useFetch, useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import { useDispatch, useSelector } from "react-redux";
import ImageFallback from "src/modules/components/ImageFallback";

const ClassesStudent = () => {
    const { user } = useSelector((state) => state.auth);

    const [loadingClasses, dataClasses, paginationClasses, fetchClassList, refetchClassList] = useFetch(
        {
            page: 1,
            perPage: 11,
            relations: [
                "categories",
                "branch",
                user?.currentOrg?.scopeOptionKey === "organization" ? "organization" : "team",
            ],
        },
        fetchClassesForStudent
    );

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        inputSearchQuestion: "",
        filterParams: [],
    });

    const isLastPage = useMemo(() => {
        const { current, pageSize, total } = paginationClasses;
        return current * pageSize >= total;
    }, [paginationClasses]);

    const [values, setValues] = useValues({
        listPages: 0,
        listOfClasses: [],
    });
    const dispatch = useDispatch();

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleClickButton = (id) => {
        // navigate(
        //     generate("score", {
        //         id,
        //     })
        // );
    };

    const handleLoadMore = () => {
        fetchClassList(
            {
                page: paginationClasses.current + 1,
            },
            true
        );
    };

    const handleSearch = () => {
        if (filterSettings) {
            fetchClassList({ page: 1, slug: filterSettings.inputSearchQuestion });
        }
    };

    useEffect(() => {
        fetchClassList({}, true);
        dispatch(setCurrentPage("class"));
    }, []);

    useEffect(() => {
        if (dataClasses.length >= 0) {
            if (paginationClasses.current === 1) {
                setValues({
                    ...values,
                    listPages: paginationClasses.current,
                    listOfClasses: dataClasses,
                });
            } else if (paginationClasses.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: paginationClasses.current,
                    listOfClasses: [...values.listOfClasses, ...dataClasses],
                });
            }
        }
    }, [dataClasses, paginationClasses]);

    return (
        <div className="homeStudent_wrapper">
            <div className="homeStudent_header">
                <StudentHeader title={t("classes.your_class_list")}>
                    <div className="homeStudent_controlPanel">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                onChange={(e) =>
                                    setFilterSettings({
                                        ...filterSettings,
                                        inputSearchQuestion: e.target.value,
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="homeStudent_controlPanel-search">
                            <div className="homeStudent_controlPanel_title">{t("assignment.filter")}</div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetch={fetchClassList}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottom"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="homeStudent_filter">
                                    {filterSettings.filterParams?.length > 0 ? (
                                        filterSettings.filterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                >
                                                    {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                        fKey.labelValue || ""
                                                    }`}
                                                </Tag>
                                            );
                                        })
                                    ) : (
                                        <div>{t("assignment.filter_option")}</div>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </StudentHeader>
            </div>
            <StudentFrame title={t("classes.your_class_list")}>
                <Spin spinning={loadingClasses} tips="loading">
                    <div className="cardList">
                        {values.listOfClasses.map((classItem) => {
                            return (
                                <div key={classItem.id} className="cardItem">
                                    <div className="cardItem_inner">
                                        <div className="cardItem_thumbNail">
                                            <ImageFallback src={classItem?.file?.src || ClassImg} />
                                        </div>
                                        <div className="cardItem_info">
                                            <h3>{classItem?.name}</h3>
                                            <p>
                                                {t("classes.owner")}: {classItem?.owner?.name || t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.subject")}:{" "}
                                                {classItem?.categories?.length
                                                    ? classItem.categories.map((item) => item?.name || "_").join(", ")
                                                    : t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.class_code")}: {classItem?.code || t("shared.unknown")}
                                            </p>
                                            {/* <div style={{ marginTop: "15px" }}>
                                                <Button
                                                    onClick={() => handleClickButton(classItem?.id)}
                                                    title={t("classes.view_detail")}
                                                    icon={ReviewIcon}
                                                    type="primary"
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {Boolean(values?.listOfClasses.length && !isLastPage && !loadingClasses) && (
                            <div className="cardItem loadmore_btn" onClick={handleLoadMore}>
                                <div className="cardItem_inner">{t("assignment.load_more")}</div>
                            </div>
                        )}
                    </div>
                    {!values.listOfClasses.length && <Empty description={t("message.empty_data")} />}
                </Spin>
            </StudentFrame>
        </div>
    );
};

export default ClassesStudent;
