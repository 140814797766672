import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { uuid4 } from "@sentry/utils";
import { Alert } from "antd";
import QuestionContentDetail from "../components/ContentDetail";
import ImageLabelsContainer from "./components/ImageLabelsContainer";
import ImageLabelAnswerDraggable from "src/modules/containers/QuestionDetail/components/ImageLabelAnswerDraggable";
import "./ImageWithLabels.scss";

function ImageWithLabels({
    isReadonly = false,
    // Question & answers:
    labelType /** @param {string} labelType. Values: "text", "dropdown", "drag&drop". */,
    question = "" /** @param {string} question */,
    answer /** @param {string[]} answer - list of provided answers. */,
    defaultAnswer /** @param {string[]} defaultAnswered - list of selected answers */,
    coordinates /** @param {Object[]} coordinates. Values: { top: 0, left: 0 } */,
    src /** @param {string} src */,
    width /** @param {Number} width */,
    height /** @param {Number} height */,
    // Event handlers:
    onChange,
}) {
    const { t } = useTranslation();

    const [selectedAnswers, setSelectedAnswers] = useState(defaultAnswer || []);
    const [labelList, setLabelList] = useState([]); // Label & selected answer list.
    const [labelAnswerList, setLabelAnswerList] = useState([]); // Provided/possible answer list.

    const elemIdForLblAnswers = useRef(`sec-${uuid4()}`);
    const elemIdForImgLbls = useRef(`sec-${uuid4()}`);

    const handleChange = (_selectedAnswers) => {
        if (onChange instanceof Function) {
            onChange({ answered: _selectedAnswers });
        }
    };

    const specialClassname = useMemo(() => {
        return (labelType || "").replace("&", "-");
    }, [labelType]);

    const handleSetLblAnswerText = (_label, _labelAnswer) => {
        if (isNaN(_label)) {
            return;
        }
        const lblIndex = _label;
        if (labelList.length > lblIndex) {
            const lblList = [...labelList];
            lblList[lblIndex].content = _labelAnswer;
            // Update:
            setLabelList(lblList);
            // Trigger onChange prop:
            handleChange(lblList.map((item) => item.content));
        }
    };

    const handleSetLblAnswerDropdown = (_label, _labelAnswer) => {
        if (isNaN(_label)) {
            return;
        }
        const lblIndex = _label;
        if (labelList.length > lblIndex) {
            const lblList = [...labelList];
            lblList[lblIndex].content = _labelAnswer;
            // Update:
            setLabelList(lblList);
            // Trigger onChange prop:
            handleChange(lblList.map((item) => item.content));
        }
    };

    const handleSetLblAnswerDragDrop = (_label, _labelAnswer) => {
        if (isNaN(_label?._local_id) || isNaN(_labelAnswer)) {
            return;
        }
        const lblIndex = _label._local_id;
        const lblAnswerIndex = _labelAnswer;
        if (labelList.length > lblIndex && labelAnswerList.length > lblAnswerIndex) {
            const lblList = [...labelList];
            const lblAnswerList = [...labelAnswerList];
            // If answer for the label is already set, swap answers.
            // Otherwise, move the selected answer to the label.
            if (lblList[lblIndex].content) {
                // Swap answers:
                const temp = lblList[lblIndex].content;
                lblList[lblIndex].content = lblAnswerList[lblAnswerIndex];
                lblAnswerList[lblAnswerIndex] = temp;
            } else {
                // Set answer (change image label's content):
                lblList[lblIndex].content = lblAnswerList[lblAnswerIndex];
                // Remove the answer that is assigned to label:
                lblAnswerList.splice(lblAnswerIndex, 1);
            }
            // Update:
            setLabelList(lblList);
            setLabelAnswerList(lblAnswerList);
            // Trigger onChange prop:
            handleChange(lblList.map((item) => item.content));
        }
    };

    const handleRemoveAnswerFromLbl = (_label) => {
        if (isNaN(_label)) {
            return;
        }
        const lblIndex = _label;
        if (labelList.length > lblIndex) {
            const lblList = [...labelList];
            const lblAnswerList = [...labelAnswerList];
            // Remove the answer that is assigned to label and move it to provided answer list:
            const temp = lblList[lblIndex].content;
            lblList[lblIndex].content = "";
            lblAnswerList.push(temp);
            // Update:
            setLabelList(lblList);
            setLabelAnswerList(lblAnswerList);
            // Trigger onChange prop:
            handleChange(lblList.map((item) => item.content));
        }
    };

    const handleSwapLabelAnswer = (_label, _labelTarget) => {
        if (isNaN(_label) || isNaN(_labelTarget)) {
            return;
        }
        const lblIndex = parseInt(_label);
        const lblTargetIndex = parseInt(_labelTarget);
        if (lblIndex === lblTargetIndex) {
            return;
        }
        if (labelList.length > lblIndex && labelList.length > lblTargetIndex) {
            const lblList = [...labelList];
            // Swap answer:
            const temp = lblList[lblIndex].content;
            lblList[lblIndex].content = lblList[lblTargetIndex].content;
            lblList[lblTargetIndex].content = temp;
            // Update:
            setLabelList(lblList);
            // Trigger onChange prop:
            handleChange(lblList.map((item) => item.content));
        }
    };

    /**
     * Side effects:
     */
    useEffect(() => {
        // Preparation:
        const providedCoordinates = coordinates || [];
        // Data:
        const _labelList = [];
        const _labelAnswerList = (answer || []).filter((item) => !!item);
        for (let i = 0; i < providedCoordinates.length; i++) {
            _labelList.push({
                left: providedCoordinates[i].x,
                top: providedCoordinates[i].y,
                content: selectedAnswers?.[i] || "",
            });
        }
        // Update values:
        setLabelList(_labelList);
        if (labelType === "drag&drop") {
            const _labelListContentSet = new Set(_labelList.map((item) => item?.content));
            const _labelAnswerListFiltered = _labelAnswerList.filter((item) => !_labelListContentSet.has(item));
            setLabelAnswerList(_labelAnswerListFiltered);
        } else {
            setLabelAnswerList(_labelAnswerList);
        }
    }, []);

    /**
     * Rendering:
     */
    let handleUpdateLabelAnswer = undefined;
    switch (labelType) {
        case "text":
            handleUpdateLabelAnswer = handleSetLblAnswerText;
            break;
        case "dropdown":
            handleUpdateLabelAnswer = handleSetLblAnswerDropdown;
            break;
        case "drag&drop":
            handleUpdateLabelAnswer = handleSetLblAnswerDragDrop;
            break;
        default:
            break;
    }

    const renderProvidedAnswerList = () => {
        switch (labelType) {
            case "text": {
                return null;
            }
            case "dropdown": {
                if (!labelAnswerList.length) {
                    return null;
                }
                return labelAnswerList.map((_item, _itemIndex) => {
                    return (
                        <div key={`${_itemIndex}`} className="lblanswer-item-wrapper">
                            <span className="lblanswer-item">{_item}</span>
                        </div>
                    );
                });
            }
            case "drag&drop": {
                if (!labelAnswerList.length) {
                    return null;
                }
                return labelAnswerList.map((_item, _itemIndex) => {
                    return (
                        <div key={`${_itemIndex}`} className="lblanswer-item-wrapper">
                            <ImageLabelAnswerDraggable
                                className="view-lblanswer-drg"
                                position={{ x: 0, y: 0 }} // Fixed position!
                                dropzoneCssSelectors=".view-image-label"
                                onDragStop={(dropTarget) => handleUpdateLabelAnswer(dropTarget, _itemIndex)}
                                customDragElement={<span className="lblanswer-item">{_item}</span>}
                                isDisabled={isReadonly}
                            >
                                <span className="lblanswer-item">{_item}</span>
                            </ImageLabelAnswerDraggable>
                        </div>
                    );
                });
            }
            default:
                return <Alert type="error" message="Label type is not valid!" showIcon />;
        }
    };

    useEffect(() => {
        if (["dropdown", "drag&drop"].includes(labelType)) {
            const elemLblAnswers = document.getElementById(elemIdForLblAnswers.current);
            const elemLblAnswersView = document.querySelector(`#${elemIdForLblAnswers.current} .view-lblanswers`);
            const elemImgLbls = document.getElementById(elemIdForImgLbls.current);

            const handleScroll = () => {
                const rectLblAnswers = elemLblAnswers.getBoundingClientRect();
                const rectImgLbls = elemImgLbls.getBoundingClientRect();

                const hiddenFrom = 0;
                const isElemLblAnswersVisible = rectLblAnswers.top > hiddenFrom;
                const isElemImgLblsVisible = rectImgLbls.top + rectImgLbls.height - rectLblAnswers.height > hiddenFrom;

                if (!isElemLblAnswersVisible && isElemImgLblsVisible) {
                    // Wrapper:
                    elemLblAnswers.style.height = `${rectLblAnswers.height}px`;
                    // View:
                    elemLblAnswersView.classList.add("is-fixed");
                    elemLblAnswersView.style.setProperty("--top", `${hiddenFrom}px`);
                    elemLblAnswersView.style.setProperty("--left", `${rectLblAnswers.left}px`);
                    elemLblAnswersView.style.setProperty("--width", `${rectLblAnswers.width}px`);
                } else {
                    // Wrapper:
                    elemLblAnswers.style.height = "";
                    // View:
                    elemLblAnswersView.classList.remove("is-fixed");
                    elemLblAnswersView.style.removeProperty("--top");
                    elemLblAnswersView.style.removeProperty("--left");
                    elemLblAnswersView.style.removeProperty("--width");
                }
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    return (
        <div className={`q-imgwithlbls ${specialClassname}`}>
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            {["dropdown", "drag&drop"].includes(labelType) ? (
                <div className="q-content-section" id={elemIdForLblAnswers.current}>
                    <div className={`view-lblanswers ${specialClassname}`}>{renderProvidedAnswerList()}</div>
                </div>
            ) : null}

            <div className="q-content-section" id={elemIdForImgLbls.current}>
                <div className="view-image-with-labels">
                    <ImageLabelsContainer
                        src={src}
                        labelType={labelType}
                        labelList={labelList}
                        labelAnswerList={labelAnswerList}
                        containerSize={{ width, height }}
                        onUpdateLabelAnswer={handleUpdateLabelAnswer}
                        onRemoveLabelAnswer={handleRemoveAnswerFromLbl}
                        onSwapLabelAnswer={handleSwapLabelAnswer}
                        isReadonly={isReadonly}
                    />
                </div>
            </div>
        </div>
    );
}

export default ImageWithLabels;
