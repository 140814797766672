import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, notification, Space, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import FilterMenu from "../FilterMenu";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import ModalDetailEmployee from "../ModalDetailEmployee";
import InputSearch from "src/modules/components/Input/InputSearch";
import UploadBtn from "src/modules/containers/ManageStudent/components/UploadBtn";
import ModalAddUserToOrganization from "src/modules/components/ModalAddUserToOrganization";
import { fetchBranches } from "src/api/containers/branch";
import {
    fetchOrganizationUsers,
    removeUserEmployeeRole,
    removeUserFromOrganization,
    updateUserInOrganization,
} from "src/api/containers/organization";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
import "./EmployeeTable.scss";
import FilterIcon from "src/assets/images/filter-icon.svg";

function EmployeeTable() {
    const user = useSelector((state) => state.auth.user);

    const [loadingOrgUsers, dataSource, pagination, fetch, refetch] = useFetch(
        {
            role: "employee",
            organization_id: user.paramsForOrgOrTeam?.organization_id,
            is_employee_in_organization: 1,
        },
        fetchOrganizationUsers
    );

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // Values: "removing-user-roles", true||false.
    const [values, setValues] = useValues({
        organization_id: user.paramsForOrgOrTeam?.organization_id,
        isModalConfirmVisible: false, // Values: "confirm-delete", "confirm-remove-user-roles", true||false.
        isModalInviteUserVisible: false,
        isModalDetailUserVisible: false,
        selectedUser: null,
        listBranch: [],
        loadingFile: false,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchUser: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });

    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const checkRoles = useMemo(() => {
        return (
            checkPermission(pagesAllow.employee, rolesAllow.update, user) ||
            checkPermission(pagesAllow.organization, rolesAllow.removeUserOrganization, user)
        );
    }, [user]);

    const columns = [
        {
            title: t("organization.full_name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => {
                return (
                    record?.name ||
                    (record?.first_name && record.last_name ? `${record.first_name} ${record.last_name}` : "_")
                );
            },
        },
        {
            title: t("organization.gender"),
            dataIndex: "gender",
            key: "gender",
            width: "auto",
            align: "center",
            render: (text, record) => {
                switch (record?.gender) {
                    case 0:
                        return t(`profile.gender_female`);
                    case 1:
                        return t(`profile.gender_male`);
                    default:
                        return "_";
                }
            },
        },
        {
            title: t("organization.email"),
            dataIndex: "username",
            key: "username",
            width: "auto",
            align: "center",
            render: (text, record) => {
                return record?.username || "_";
            },
        },

        {
            title: t("organization.organization_role"),
            dataIndex: "roles",
            key: "roles",
            width: "auto",
            align: "center",
            render: (text, record) => {
                return rederRoles(record?.roles) || "_";
            },
        },

        // {
        //     title: t("organization.status"),
        //     dataIndex: "active",
        //     key: "active",
        //     width: "auto",
        //     render: (text, record) => {
        //         switch (record?.active) {
        //             case false:
        //                 return t(`profile.active_false`);
        //             case true:
        //                 return t(`profile.active_true`);
        //             default:
        //                 return "_";
        //         }
        //     },
        // },
    ];

    if (checkRoles) {
        columns.push({
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            align: "center",
            render: (text, record) => (
                <div className="organization-user-list-actions">
                    <Can I={rolesAllow.removeUserOrganization} page={pagesAllow.organization}>
                        <Tooltip placement="top" title={t("organization.remove_user_employee_role")}>
                            <span
                                onClick={() => handleShowModalConfirm(record, "confirm-remove-user-roles")}
                                className="action-btn"
                            >
                                <UserDeleteOutlined />
                            </span>
                        </Tooltip>
                    </Can>

                    <Can I={rolesAllow.update} page={pagesAllow.employee}>
                        <Tooltip placement="top" title={t("shared.edit")}>
                            <span onClick={() => handleShowModalDetailUser(record)} className="action-btn">
                                <EditOutlined />
                            </span>
                        </Tooltip>
                    </Can>

                    <Can I={rolesAllow.removeUserOrganization} page={pagesAllow.organization}>
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <span
                                onClick={() => handleShowModalConfirm(record, "confirm-delete")}
                                className="action-btn organization-user-delbtn"
                            >
                                <DeleteOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                </div>
            ),
        });
    }

    const rederRoles = (roles = []) => {
        if (roles?.length) {
            const result = roles.reduce((pre, curr) => {
                return [...pre, curr?.name];
            }, []);
            return result.join(", ");
        }
        return [];
    };

    const handleShowModalConfirm = (selectedUser, confirmWhat) => {
        if (selectedUser && ["confirm-delete", "confirm-remove-user-roles"].includes(confirmWhat)) {
            setValues({ isModalConfirmVisible: confirmWhat, selectedUser: selectedUser });
        }
    };

    const handleShowModalDetailUser = (selectedUser) => {
        setValues({ isModalDetailUserVisible: true, selectedUser: selectedUser });
    };

    const handleRemoveUserRoles = () => {
        if (!values.organization_id && loading !== "removing-user-roles") {
            return;
        }

        setLoading("removing-user-roles");

        // Preparation:
        const userInfo = {
            // role_ids: [],
            organization_id: user?.paramsForOrgOrTeam?.organization_id,
        };

        // Make api calls:
        removeUserEmployeeRole("employee", values.selectedUser?.id, userInfo).then((res) => {
            setLoading(false);
            if (res.status) {
                notification.success({
                    message: t("message.update_success"),
                });
                setValues({ isModalConfirmVisible: false, selectedUser: null });
                refetch();
            } else {
                notification.error({
                    message: res.message || t("message.update_error"),
                });
            }
        });
    };

    const handleDeleteSelectedUser = () => {
        removeUserFromOrganization(values.selectedUser?.id, values.organization_id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isModalConfirmVisible: false, selectedUser: null });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleSearchUser = () => {
        if (filterSettings) {
            fetch({
                page: 1,
                slug: filterSettings.inputSearchUser,
            });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        fetch({}, true);
        fetchBranches({ filter: "active:1", noPagination: true, ...user.paramsForOrgOrTeam }).then((res) => {
            setValues({ listBranch: res.data });
        });
    }, []);

    const renderPopConfirm = () => {
        let title = "";
        let type = "action";
        let callbackOnOk = undefined;
        switch (values.isModalConfirmVisible) {
            case "confirm-delete": {
                title = t("organization.confirm_remove_user");
                type = "delete";
                callbackOnOk = handleDeleteSelectedUser;
                break;
            }
            case "confirm-remove-user-roles": {
                title = t("organization.confirm_remove_user_employee_role");
                type = "update";
                callbackOnOk = handleRemoveUserRoles;
                break;
            }
            default:
                break;
        }
        return (
            <PopConfirm
                title={title}
                type={type}
                visible={values.isModalConfirmVisible !== false}
                onOk={callbackOnOk}
                onCancel={() => setValues({ isModalConfirmVisible: false })}
            />
        );
    };

    return (
        <div className="question-bank organization-user-list org-user-list">
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("organization.find_user")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchUser: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchUser();
                                    }
                                }}
                                onClickIconSearch={handleSearchUser}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetchUserList={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                        branches={values.listBranch}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions">
                        {/* <Can I={rolesAllow.inviteUser} page={pagesAllow.organization}>
                            <CustomButton
                                type="primary"
                                icon={<ShareAltOutlined />}
                                title={t("organization.invite_employee")}
                                onClick={() => setValues({ isModalInviteUserVisible: true })}
                            ></CustomButton>
                        </Can> */}
                    </div>
                </div>
                <div className="actions-button">
                    <div>
                        <UploadBtn
                            loading={values.loadingFile}
                            setLoading={setValues}
                            refetch={refetch}
                            fileName="employee"
                            role="employee"
                            organization={user.paramsForOrgOrTeam}
                        />
                    </div>
                    <div>
                        <Can I={rolesAllow.create} page={pagesAllow.employee}>
                            <CustomButton
                                type="primary"
                                icon={<PlusOutlined />}
                                title={t("organization.add_employee")}
                                onClick={() => handleShowModalDetailUser(undefined)}
                            ></CustomButton>
                        </Can>
                    </div>
                </div>
            </div>

            <div className="organization-user-list__table">
                <Table
                    className="app-table"
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loadingOrgUsers}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            <ModalAddUserToOrganization
                visible={values.isModalInviteUserVisible}
                organization={{
                    ...user.paramsForOrgOrTeam,
                }}
                onOk={() => setValues({ isModalInviteUserVisible: false })}
                onCancel={() => setValues({ isModalInviteUserVisible: false })}
            />

            <ModalDetailEmployee
                visible={values.isModalDetailUserVisible}
                organization_id={values.organization_id}
                selectedUser={values.selectedUser}
                onOk={() => {
                    refetch({}, true);
                    setValues({ isModalDetailUserVisible: false });
                }}
                onCancel={() => setValues({ isModalDetailUserVisible: false })}
            />

            {renderPopConfirm()}
        </div>
    );
}

export default EmployeeTable;
