import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import ResultAnswer from "../components/ResultAnswer";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import "./FillBlanksDragDrop.scss";

const FillBlanksDragDrop = (props) => {
    const {
        correct_answer /** @param{String[]} */,
        answered /** @param{String[]} */,
        question /** @param {String} question: Title of question */,
    } = props;

    return (
        <div className="q-result q-fill-blanks-drag-drop">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{ question, answer: correct_answer, answer_submit: answered }}
                />
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {(correct_answer || []).map((item, itemIndex) => {
                        return (
                            <div key={`ans-${itemIndex}`} className="correct-answer-item">
                                <ResultAnswer letter={itemIndex + 1}>
                                    <HTMLDisplayer htmlString={item} />
                                </ResultAnswer>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FillBlanksDragDrop;
