import React, { useRef, useState } from "react";
import { t } from "i18next";
import TextareaEditor from "src/modules/components/TextareaEditor";
import QuestionContentDetail from "../components/ContentDetail";
import "./Writing.scss";
import clsx from "clsx";

function Writing({
    isReadonly = false,
    question,
    defaultAnswer /** @param {string} defaultAnswer. Student's answer. */,
    wordLimit,
    exam_types,
    onChange = () => {},
}) {
    const timeoutIdChange = useRef(null);

    const [inputAnswer, setInputAnswer] = useState(defaultAnswer?.[0]);
    const [inputAnswerSetting, setInputAnswerSetting] = useState({
        inputWordCount: inputAnswer?.split(" ").length,
    });

    const handleChangeAnswered = (str) => {
        setInputAnswer(str);
        // Limit onChange calls:
        clearTimeout(timeoutIdChange.current);
        timeoutIdChange.current = setTimeout(() => {
            if (onChange instanceof Function) {
                onChange({
                    answered: [str],
                });
            }
        });
    };

    const handleCountWord = (count) => {
        setInputAnswerSetting({
            ...inputAnswerSetting,
            inputWordCount: count > wordLimit ? wordLimit : count,
        });
    };

    return (
        <div className={clsx("q-writing", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            <div className="q-content-title">
                <QuestionContentDetail isReadonly={isReadonly} value={{ question }} />
            </div>

            <div className="q-content-section">
                <div className="view-writing">
                    <TextareaEditor
                        className="app-input"
                        value={inputAnswer || ""}
                        placeholder={t("question_panel.write_here")}
                        disabled={isReadonly}
                        onChange={handleChangeAnswered}
                        wordCountMax={wordLimit}
                        onCountWord={handleCountWord}
                    />
                    <div className="content-setting-wrapper">
                        <span className="word-counter">
                            {inputAnswerSetting.inputWordCount || 0}/{wordLimit || "-"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Writing;
