import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import ImageLabelAnswerDraggable from "src/modules/containers/QuestionDetail/components/ImageLabelAnswerDraggable";
import "./ImageLabel.scss";

function ImageLabel({
    isReadonly,
    // HTML properties:
    className,
    style,
    // Label:
    localId,
    type /** @param {string} type. Values: "text", "dropdown", "drag&drop". */,
    content /** @param {string} content */,
    contentList /** @param {string[]} content */,
    isFocused /** @param {boolean} isFocused */,
    // Event handlers:
    onUpdateLabelAnswer,
    onRemoveLabelAnswer,
    onSwapLabelAnswer,
    onFocusContent,
    onBlurContent,
}) {
    const { t } = useTranslation();
    const timeoutChangeVal = useRef(false);
    const [lblContent, setLblContent] = useState("");

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (isFocused) {
            r += " is-focused";
        }
        if (lblContent) {
            r += " has-val";
        }
        return r;
    }, [className, isFocused, lblContent]);

    const specialClassname = useMemo(() => {
        return (type || "").replace("&", "-");
    }, [type]);

    const updateLabelAnswer = (_lblContent) => {
        if (onUpdateLabelAnswer instanceof Function) {
            onUpdateLabelAnswer(localId, _lblContent);
        }
    };

    const removeLabelAnswer = () => {
        if (onRemoveLabelAnswer instanceof Function) {
            onRemoveLabelAnswer(localId);
        }
    };

    const swapLabelAnswer = (localIdTarget) => {
        if (onSwapLabelAnswer instanceof Function) {
            onSwapLabelAnswer(localId, localIdTarget);
        }
    };

    const handleChangeLblContentText = (e) => {
        setLblContent(e.target.value);
        clearTimeout(timeoutChangeVal.current);
        timeoutChangeVal.current = setTimeout(() => {
            updateLabelAnswer(e.target.value);
        }, 200);
    };

    const handleChangeLblContentDropdown = (value) => {
        updateLabelAnswer(value || "");
    };

    const handleFocusLblContent = () => {
        if (onFocusContent instanceof Function) {
            onFocusContent(localId);
        }
    };

    const handleBlurLblContent = () => {
        if (onBlurContent instanceof Function) {
            onBlurContent();
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        // Focus the label:
        handleFocusLblContent();
    };

    useEffect(() => {
        setLblContent(content);
    }, [content]);

    const renderLabelContent = () => {
        switch (type) {
            case "text": {
                return (
                    <Input
                        className="app-input image-label-input"
                        readOnly={isReadonly}
                        placeholder={t("question.answer")}
                        value={lblContent}
                        onChange={handleChangeLblContentText}
                        // onFocus={handleFocusLblContent}
                        onBlur={handleBlurLblContent}
                    />
                );
            }
            case "dropdown": {
                return (
                    <Select
                        className="app-select show-arrow image-label-selector"
                        disabled={isReadonly}
                        showSearch
                        allowClear
                        showArrow={false}
                        placeholder={t("question.select_answer")}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleChangeLblContentDropdown}
                        value={lblContent || undefined}
                        dropdownClassName="app-select-dropdown type-wordwrap"
                        dropdownMatchSelectWidth={false}
                    >
                        {(contentList || [])?.map((lblAnswer, lblAnswerIndex) => {
                            return (
                                <Select.Option key={`lblanswer${lblAnswerIndex}`} value={lblAnswer}>
                                    {lblAnswer}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
            }
            case "drag&drop": {
                if (lblContent) {
                    return (
                        <ImageLabelAnswerDraggable
                            className="view-lblanswer-drg"
                            isDisabled={isReadonly}
                            position={{ x: 0, y: 0 }} // Fixed position!
                            dropzoneCssSelectors=".view-lblanswers, .view-image-label"
                            onDragStop={(dropTarget) => {
                                if (dropTarget) {
                                    const classes = (dropTarget.className || "").split(" ");
                                    if (classes.includes("view-image-label")) {
                                        swapLabelAnswer(dropTarget._local_id);
                                    } else {
                                        removeLabelAnswer();
                                    }
                                }
                            }}
                            customDragElement={<span className="lblanswer-item">{lblContent}</span>}
                        >
                            <span className="lblanswer-item">{lblContent}</span>
                        </ImageLabelAnswerDraggable>
                    );
                } else {
                    return null;
                }
            }
            default:
                return null;
        }
    };

    return (
        <span
            id={localId}
            className={`view-image-label${extraClassnames} ${specialClassname}`}
            style={style}
            onClick={handleClick}
        >
            <span className="view-image-label-content-wrapper">
                <span className="image-label-pin">{/* Empty! */}</span>
                <span className="image-label-content">{renderLabelContent()}</span>
            </span>
        </span>
    );
}

export default ImageLabel;
