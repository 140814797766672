import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row, notification } from "antd";
import { getMe } from "src/api/containers/auth";
import { login } from "src/reducers/auth";
import { checkAfterPurchasePlan } from "src/api/containers/plan";
import { clearSelectedUserScope, getUserScopeSelected } from "src/api/helpers/userScope";
import { default as CustomButton } from "src/modules/components/Button";
import { deleteCookie } from "src/utils/helpers";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import "./PaymentResult.scss";

const PaymentResult = ({ urlParams, showPaymentInfo = true, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // false, "result".
    const [data, setData] = useState({
        paymentResult: undefined,
        paymentStatus: undefined,
    });

    const updateMyProfile = () => {
        getMe(user?.paramsForOrgOrTeam?.organization_id).then((res) => {
            if (res?.status === true && res?.data?.user) {
                dispatch(login(res.data.user));
            } else {
                localStorage.removeItem("token");
                deleteCookie("token");
            }
        });
    };

    useEffect(() => {
        if (typeof urlParams === "string" || Object.keys(urlParams).length) {
            setLoading("result");
            checkAfterPurchasePlan(urlParams)
                .then((res) => {
                    // if (res.status && res.data) {
                    if (res.status) {
                        setData({
                            ...data,
                            paymentResult: res.data?.subscription_plan,
                            paymentStatus: "finished",
                        });
                        if (res?.message) {
                            notification.success({ message: res.message });
                        }
                    } else {
                        setData({
                            ...data,
                            paymentResult: null,
                            paymentStatus: "unfinished",
                        });
                        if (res?.message) {
                            notification.success({ message: res.message });
                        }
                    }

                    setLoading(false);
                    updateMyProfile();
                })
                .catch((err) => {
                    setData({
                        paymentStatus: "system_error",
                    });
                });
        }
    }, [urlParams]);

    useEffect(() => {
        const newScope = data.paymentResult?.plan?.type;
        const currScope = getUserScopeSelected(user)?.scopeKey;
        if (newScope && currScope && newScope !== currScope) {
            clearSelectedUserScope();
        }
    }, [data]);

    const renderSatus = () => {
        switch (data.paymentStatus) {
            case "finished":
                return (
                    <div className="status-wrapper">
                        <span className="status-icon">
                            <SvgTick fill="forestgreen" />
                        </span>
                        <span className="status-message">{t("payment.sucess_make_payment_transaction")}!</span>
                    </div>
                );
            case "unfinished":
                return (
                    <div className="status-wrapper">
                        <span className="status-icon">
                            <SvgCross fill="orangered" />
                        </span>
                        <span className="status-message">{t("payment.fail_make_payment_transaction")}!</span>
                    </div>
                );
            case "system_error":
                return (
                    <div className="status-wrapper">
                        <span className="status-icon">
                            <SvgCross fill="orangered" />
                        </span>
                        <span className="status-message">{t("message.system_error")}!</span>
                    </div>
                );
        }
        // if (data.paymentStatus === "finished") {
        //     return (
        //         <div className="status-wrapper">
        //             <span className="status-icon">
        //                 <SvgTick fill="forestgreen" />
        //             </span>
        //             <span className="status-message">{t("payment.sucess_make_payment_transaction")}!</span>
        //         </div>
        //     );
        // } else if (data.paymentStatus === "unfinished") {
        //     return (
        //         <div className="status-wrapper">
        //             <span className="status-icon">
        //                 <SvgCross fill="orangered" />
        //             </span>
        //             <span className="status-message">{t("payment.fail_make_payment_transaction")}!</span>
        //         </div>
        //     );
        // }
    };

    return (
        <div className="payment-result-panel" {...rest}>
            {loading === "result" ? (
                <>
                    <div className="result-header">
                        <div className="status-wrapper skeleton"></div>
                    </div>
                    <div className="result-body">
                        <div className="body-section skeleton"></div>
                        <div className="body-section skeleton"></div>
                    </div>
                </>
            ) : (
                <>
                    <div className="result-header">{renderSatus()}</div>
                    <div className="result-body">
                        {data.paymentResult ? (
                            <>
                                <div className="body-section order-items-info">
                                    {data.paymentResult?.plan ? (
                                        <Row>
                                            <Col xs={24} sm={24} md={8}>
                                                {t("plan.plan_name")}:
                                            </Col>
                                            <Col xs={24} sm={24} md={16}>
                                                {data.paymentResult.plan.name || "_"}
                                            </Col>
                                        </Row>
                                    ) : null}
                                </div>
                                {showPaymentInfo ? (
                                    <div className="body-section payment-info">
                                        {/* {data.paymentResult?.bill ? ( */}
                                        <Row>
                                            {/* <Col xs={24} sm={24} md={8}>
                                                {t("plan.price")}:
                                            </Col>
                                            <Col xs={24} sm={24} md={16} className="price">
                                                <span>
                                                    {(data.paymentResult?.plan_price?.price || 0).toLocaleString() ||
                                                        "_"}
                                                </span>
                                                <span>{t("price.vnd")}</span>
                                            </Col> */}

                                            {/* <Col xs={24} sm={24} md={8}>
                                                {t("price.total")}:
                                            </Col>
                                            <Col xs={24} sm={24} md={16} className="price">
                                                <span>{(data.paymentResult?.total || 0).toLocaleString() || "_"}</span>
                                                <span>{t("price.vnd")}</span>
                                            </Col> */}

                                            <Col xs={24} sm={24} md={8}>
                                                {t("payment.order_total")}:
                                            </Col>
                                            <Col xs={24} sm={24} md={16} className="price">
                                                <span>{(data.paymentResult?.amount || 0).toLocaleString() || "_"}</span>
                                                <span>{t("price.vnd")}</span>
                                            </Col>
                                        </Row>
                                        {/* ) : null} */}
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </>
            )}
            <div className="result-footer">
                <CustomButton
                    type="primary"
                    title={t("html_status_code.action_go_back_home")}
                    linkTo="/"
                    disabled={["result"].includes(loading)}
                    className="btn-gohome"
                ></CustomButton>
            </div>
        </div>
    );
};

export default PaymentResult;
