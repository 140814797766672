/**
 * Get the Editor which is focussed.
 * @returns string | boolean.
 */
export const getFocussedEditor = () => {
    return {
        editor: window.isEditorFocussed || false,
        toolbar: window.isEditorToolbarFocussed || false,
        dialogSettings: {
            mcFltrOptList: window.mcFltrOptList,
            mcFltrOptSelected: window.mcFltrOptSelected,
            isOneOperandOnly: window.isOneOperandOnly || false,
        },
    };
};

/**
 * Get the Editor Dialog which is opened.
 * @returns string | boolean.
 */
export const getOpenedEditorDialog = () => {
    return window.isEditorDialogOpened || false;
};

/**
 * Focus an editor.
 * @param {string} editorKey
 */
export const toggleFocussedEditor = (editorKey, editorToolbarKey, editorDialogSettings) => {
    if (editorKey) {
        window.isEditorFocussed = editorKey;
        window.isEditorToolbarFocussed = editorToolbarKey;
        if (editorDialogSettings) {
            // Dialog settings for math_characters plugin - Select & Options:
            if (editorDialogSettings.mcFltrOptList?.length) {
                window.mcFltrOptList = editorDialogSettings.mcFltrOptList;
            }
            if (editorDialogSettings.mcFltrOptSelected) {
                window.mcFltrOptSelected = editorDialogSettings.mcFltrOptSelected;
            }
            // Dialog settings for math_characters plugin - Input mode:
            window.isOneOperandOnly = editorDialogSettings.isOneOperandOnly;
        }
    } else {
        delete window.isEditorFocussed;
        delete window.isEditorToolbarFocussed;
        delete window.mcFltrOptList;
        delete window.mcFltrOptSelected;
        delete window.isOneOperandOnly;
    }
};

/**
 * Open an Editor's Dialog.
 * @param {string} editorKey
 */
export const toggleOpenedEditorDialog = (editorKey) => {
    if (editorKey) {
        window.isEditorDialogOpened = editorKey;
    } else {
        window.isEditorDialogOpened = false;
    }
};
