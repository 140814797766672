import { Dropdown, Space, Table, Tag, Tooltip, notification } from "antd";
import "./TeamAndOrg.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRef } from "react";
import { useFetch, useValues } from "src/hooks";
import { fetchTeamsAndOrgForUser } from "src/api/containers/organization";
import moment from "moment";
import Button from "src/modules/components/Button";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import InputSearch from "src/modules/components/Input/InputSearch";
import CustomButton from "src/modules/components/Button";
import FilterMenu from "./components/FilterMenu";
import FilterIcon from "src/assets/images/filter-icon.svg";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ModalDetailTeamAndOrg from "./components/DetailTeamAndOrg";

function TeamAndOrg({ isTabActive }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const paramsForOrgOrTeam = useSelector((state) => state.auth.user?.paramsForOrgOrTeam);

    const { organization_id: currentOrgId, isOwner } = paramsForOrgOrTeam;

    const [loadingOrgAndTeams, OrgAndTeams, paginationOrgAndTeams, fetchOrgAndTeams, refetchOrgAndTeams] = useFetch(
        {},
        fetchTeamsAndOrgForUser
    );

    const [values, setValues] = useValues({
        inputSearch: "",
        filterParams: [],
        slug: "",
        isFilterMenuVisible: false,
        visibleModal: "", // "modal-detail-org"
        selectedOrg: null,
    });

    const handleSearch = () => {
        fetchOrgAndTeams(
            {
                slug: values.inputSearch,
            },
            true
        );
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = values.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setValues({ filterParams: newFilterParams });
    };

    const handleGoToPayment = (selectedPlan, selectedPrice) => {
        if (selectedPlan && selectedPrice) {
            const data = {
                planId: selectedPlan.id,
                planType: selectedPlan.type,
                planPriceId: selectedPrice.id,
                is_extend: true,
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            navigate(generate("payment"));
        } else {
            notification.warning({ message: t("message.an_error_occurred") });
        }
    };

    useEffect(() => {
        fetchOrgAndTeams({}, true);
    }, []);

    const paymentColumns = [
        {
            title: t("exam_bank.name"),
            key: "name",
            dataIndex: "name",
        },
        {
            title: t("shared.type"),
            key: "is_team",
            dataIndex: "is_team",
            render: (is_team) => {
                return is_team === true ? t("shared.team") : is_team === false ? t("shared.organization") : "";
            },
        },
        {
            title: t("shared.status"),
            key: "status",
            dataIndex: "expired_date",
            render: (text) => {
                return moment(text).isBefore(moment()) ? (
                    <Tag color="red">{t("assignment.expired")}</Tag>
                ) : (
                    <Tag color="blue">{t("setting.alive")}</Tag>
                );
            },
        },
        {
            title: t("setting.expiration_date"),
            key: "expired_date",
            dataIndex: "expired_date",
            render: (text) => {
                return moment(text).format("DD/MM/YYYY");
            },
        },
        {
            title: t("table.actions"),
            key: "actions",
            dataIndex: "expired_date",
            className: "table_actions",

            render: (expired_date, record) => {
                const is_expried = moment(expired_date).isBefore(moment());
                return (
                    <Space style={{ justifyContent: "flex-start" }}>
                        {is_expried ? (
                            <CustomButton
                                type="second"
                                className="has-shadow"
                                hasResponsiveDesign
                                title={t("setting.extend")}
                                icon={<PlusCircleOutlined />}
                                onClick={() => {
                                    handleGoToPayment(
                                        record?.activeSubscription?.used_plan,
                                        record?.activeSubscription?.used_plan?.plan_price
                                    );
                                }}
                            />
                        ) : record?.id === currentOrgId && isOwner ? (
                            <Tooltip placement="top" title={t("shared.update")}>
                                <CustomButton
                                    type="primary"
                                    hasResponsiveDesign
                                    icon={<EditOutlined />}
                                    title={t("shared.update")}
                                    onClick={() => {
                                        setValues({ visibleModal: "modal-detail-org", selectedOrg: record });
                                    }}
                                />
                            </Tooltip>
                        ) : null}
                    </Space>
                );
            },
        },
    ];
    return (
        <div className="payment_history">
            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("shared.find")}
                            value={values.inputSearch}
                            onChange={(e) => setValues({ inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {values.filterParams?.length > 0
                                    ? values.filterParams.map((fKey, i) => {
                                          return (
                                              <Tag
                                                  className="app-tag"
                                                  key={`filter-key${i}`}
                                                  closable
                                                  onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                              >
                                                  {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                  {`${fKey.labelValue || ""}`}
                                              </Tag>
                                          );
                                      })
                                    : // <>{t("shared.option_filter")}</>
                                      null}
                            </Space>
                        </div>
                        <Dropdown
                            visible={values.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={values.filterParams}
                                    filterParamsInitial={values.filterParams}
                                    handleFetchData={fetchOrgAndTeams}
                                    handleCloseFilterMenu={(newFilterParams) => {
                                        setValues({
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        });
                                    }}
                                    willFormBeCleared={!values.isFilterMenuVisible}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => setValues({ isFilterMenuVisible: val })}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={FilterIcon}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <br />
            <Table
                className="app-table payment_history-table"
                columns={paymentColumns}
                dataSource={OrgAndTeams}
                scroll={{ x: "auto" }}
                pagination={{
                    showSizeChanger: false,
                    ...paginationOrgAndTeams,
                    position: ["bottomCenter"],
                    onChange: (page) => {
                        fetchOrgAndTeams({ page: page });
                    },
                }}
                loading={loadingOrgAndTeams}
            />

            <ModalDetailTeamAndOrg
                visible={values.visibleModal === "modal-detail-org"}
                onCancel={() => setValues({ visibleModal: "", selectedOrg: null })}
                data={values.selectedOrg}
                handleOk={() => {
                    refetchOrgAndTeams();
                    setValues({ visibleModal: "", selectedOrg: null });
                }}
            />
        </div>
    );
}

export default TeamAndOrg;
