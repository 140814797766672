/**
 * Convert a storage value in MB to an other storage unit.
 * @param {Number} _value Number in MB.
 * @param {Boolean} isExact Return the exact value or not.
 * @param {Number} roundDecCount (Used when isExact === true) Rounds the string to a specified number of decimals.
 * @returns String.
 */
export const getStorageValueWithUnitFromMB = (_value, isExact = false, roundDecCount = 2) => {
    const value = _value;
    if (value >= 1048576) {
        const rValue = value / 1024 / 1024;
        return isExact === false ? `${parseFloat(rValue.toFixed(roundDecCount))} TB` : `${rValue} TB`;
    } else if (value >= 1024) {
        const rValue = value / 1024;
        return isExact === false ? `${parseFloat(rValue.toFixed(roundDecCount))} GB` : `${rValue} GB`;
    } else {
        return isExact === false ? `${parseFloat(value.toFixed(roundDecCount))} MB` : `${value} MB`;
    }
};
