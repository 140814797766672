import api from "..";

// Reaction fields:
const examReactionFields = ["reaction_count", "clone_count", "is_reacted"];

export const fetchExams = (fetchParams) => {
    const filters = [];
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.is_ranking && filters.push(`is_ranking:${fetchParams.is_ranking}`);
    fetchParams.is_auto_scoring && filters.push(`is_auto_scoring:${fetchParams.is_auto_scoring}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _examquestion_fields: `${[
            "name",
            "is_publish",
            "is_public",
            "team_id",
            "organization_id",
            "type",
            ...examReactionFields,
        ]}`,
        _relations: `${["user", "category"]}`,
        _user_fields: `${["name"]}`,
        _category_fields: `${["name"]}`,
    };

    fetchParams.perPage && (params._perPage = fetchParams.perPage);
    return api
        .get("/exam_question", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                    lastPage: meta.pagination.lastPage,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const findExam = (id, fetchParams = {}) => {
    const filters = [];
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _examquestion_fields: `${[
            "name",
            "is_publish",
            "is_public",
            "question_list",
            "category_id",
            "max_score",
            "team_id",
            "organization_id",
            "type",
        ]}`,
        _relations: `${["user", "files"]}`,
        _file_fields: `${["src", "name"]}`,
    };
    return api
        .get(`/exam_question/${id}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateExam = (id, data) => {
    return api
        .put(`/exam_question/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createExam = (data) => {
    return api
        .post("/exam_question", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Delete an exam.
 * @param {string} id Exam id.
 * @param {string} scopeId Exam scope id.
 * @returns Object.
 */
export const deleteExam = (id, scopeId) => {
    if (!id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }
    const url = scopeId ? `/exam_question/${id}?organization_id=${scopeId}` : `/exam_question/${id}`;
    return api
        .delete(url)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const cloneExam = (id, data) => {
    return api
        .post(`/exam_question/${id}/clone`, data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};
