import { DualAxes } from "@ant-design/plots";

function ExamChart(props) {
    const {
        lineColor = "#000",
        propsColor = {},
        propsLabel = {},
        yLineTitle = "",
        examData = [],
        timeData = [],
        yLineMaxValue = -1,
    } = props;

    /**
     * Sample data:
     * (DON'T DELETE THE INSTRUCTION BELOW!)
     * NOTES:
     * - In an object of examData array, xLabel must be a string!
     */
    // const lineColor = "#0077FF";
    // const propsColor = {
    //     correct: "#75B136",
    //     correct_nearly: "#D8FFB0",
    //     incorrect: "#E56965",
    //     mark_by_hand: "#F8CC5A",
    //     skip: "#DDDDDD",
    // };
    // const propsLabel = {
    //     correct: t("assignment.correct"),
    //     correct_nearly: t("assignment.correct_nearly"),
    //     incorrect: t("assignment.incorrect"),
    //     mark_by_hand: t("assignment.mark_by_hand"),
    //     skip: t("assignment.skip"),
    // };
    // const examData = [
    //     { xLabel: "1.1", itemType: 'correct', yColValue: 0 }
    //     , { xLabel: "1.1", itemType: 'correct_nearly', yColValue: 1 }
    //     , { xLabel: "1.1", itemType: 'incorrect', yColValue: 1 }
    //     , { xLabel: "1.1", itemType: 'mark_by_hand', yColValue: 0 }
    //     , { xLabel: "1.1", itemType: 'skip', yColValue: 0 }
    //     , { xLabel: "1.2", itemType: 'correct', yColValue: 2 }
    //     , { xLabel: "1.2", itemType: 'correct_nearly', yColValue: 0 }
    //     , { xLabel: "1.2", itemType: 'incorrect', yColValue: 0 }
    //     , { xLabel: "1.2", itemType: 'mark_by_hand', yColValue: 0 }
    //     , { xLabel: "1.2", itemType: 'skip', yColValue: 0 }
    //     , { xLabel: "1.3", itemType: 'correct', yColValue: 0 }
    //     , { xLabel: "1.3", itemType: 'correct_nearly', yColValue: 0 }
    //     , { xLabel: "1.3", itemType: 'incorrect', yColValue: 0 }
    //     , { xLabel: "1.3", itemType: 'mark_by_hand', yColValue: 2 }
    //     , { xLabel: "1.3", itemType: 'skip', yColValue: 0 }
    //     , { xLabel: "2", itemType: 'correct', yColValue: 1 }
    //     , { xLabel: "2", itemType: 'correct_nearly', yColValue: 0 }
    //     , { xLabel: "2", itemType: 'incorrect', yColValue: 1 }
    //     , { xLabel: "2", itemType: 'mark_by_hand', yColValue: 0 }
    //     , { xLabel: "2", itemType: 'skip', yColValue: 0 }
    //     , { xLabel: "3", itemType: 'correct', yColValue: 2 }
    //     , { xLabel: "3", itemType: 'correct_nearly', yColValue: 0 }
    //     , { xLabel: "3", itemType: 'incorrect', yColValue: 0 }
    //     , { xLabel: "3", itemType: 'mark_by_hand', yColValue: 0 }
    //     , { xLabel: "3", itemType: 'skip', yColValue: 0 }
    //     , { xLabel: "4", itemType: 'correct', yColValue: 0 }
    //     , { xLabel: "4", itemType: 'correct_nearly', yColValue: 0 }
    //     , { xLabel: "4", itemType: 'incorrect', yColValue: 0 }
    //     , { xLabel: "4", itemType: 'mark_by_hand', yColValue: 2 }
    //     , { xLabel: "4", itemType: 'skip', yColValue: 0 }
    // ];
    // const timeData = [
    //     { xLabel: "1.1", yLineValue: 1 },
    //     { xLabel: "1.2", yLineValue: 3 },
    //     { xLabel: "1.3", yLineValue: 5 },
    //     { xLabel: "2", yLineValue: 3 },
    //     { xLabel: "3", yLineValue: 1 },
    //     { xLabel: "4", yLineValue: 8 },
    // ];

    /**
     * Chart config:
     */
    const configChartYAxis = {
        grid: {
            line: null,
        },
        line: {
            style: {
                stroke: "#000",
                strokeOpacity: 1,
            },
        },
        tickLine: {
            alignTick: true,
            style: {
                stroke: "#000",
                strokeOpacity: 1,
            },
        },
        label: {
            style: {
                fill: "#000",
                fontSize: 16,
                fontWeight: "400",
                lineHeight: 35,
            },
        },
    };

    const config = {
        // Plot Container:
        appendPadding: [0, 0, 30, 0],
        autoFit: true,

        // Data Mapping:
        data: [examData, timeData],
        xField: "xLabel",
        yField: ["yColValue", "yLineValue"],

        // Geometry Style:
        geometryOptions: [
            {
                geometry: "column",
                isStack: true,
                seriesField: "itemType",
                columnStyle: {
                    cursor: "pointer",
                    // radius: [5, 5, 0, 0],
                },
                color: (params) => {
                    if (params.itemType) {
                        return propsColor[params.itemType];
                    }
                    return "";
                },
                // label: {},
            },
            {
                geometry: "line",
                smooth: true,
                color: lineColor,
            },
        ],

        // Plot Components:
        xAxis: {
            top: true,
            grid: {
                line: null,
            },
            line: {
                style: {
                    stroke: "#000",
                    strokeOpacity: 1,
                },
            },
            label: {
                style: {
                    fill: "#000",
                    fontSize: 14,
                    fontWeight: "400",
                    lineHeight: 35,
                },
            },
        },
        yAxis: {
            yColValue: {
                ...configChartYAxis,
                min: 0,
                tickInterval: 1,
            },
            yLineValue: {
                ...configChartYAxis,
                min: 0,
                ...(yLineMaxValue > 0 ? { max: yLineMaxValue } : {}),
                // tickInterval: 1,
            },
        },
        legend: {
            layout: "horizontal",
            position: "bottom",
            marker: (params) => {
                return {
                    symbol: params === "yLineValue" ? "circle" : "square",
                    style: {
                        lineWidth: 1,
                        r: params === "yLineValue" ? 6 : 15,
                    },
                };
            },
            itemName: {
                formatter: (text, item, index) => {
                    if (text === "yLineValue") {
                        return yLineTitle.toUpperCase();
                    } else {
                        return (propsLabel[text] || "unknown").toUpperCase();
                    }
                },
                style: {
                    fill: "#000",
                    fontSize: 14,
                    fontWeight: "400",
                    lineHeight: 35,
                },
            },
        },
        tooltip: {
            formatter: (params) => {
                if (params.itemType) {
                    return { name: propsLabel[params.itemType], value: params.yColValue };
                }
                if (params.yLineValue >= 0) {
                    return { name: yLineTitle, value: params.yLineValue };
                }
                return { name: "", value: "" };
            },
        },

        // Plot Theme:
        theme: {
            maxColumnWidth: 50,
        },

        // Plot Interactions:
        interactions: [
            {
                type: "element-highlight",
            },
            {
                type: "active-region",
            },
        ],
    };

    return (
        <div className="exam-chart-wrapper stacked-column-line">
            <DualAxes {...config} />
        </div>
    );
}

export default ExamChart;
