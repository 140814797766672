import clsx from "clsx";

const Card = ({ title, src, active, ...rest }) => {
    return (
        <div className={clsx("exam-card", active && "active")} {...rest}>
            <div className="exam-card_image">
                <img
                    src={src || "https://phutungnhapkhauchinhhang.com/wp-content/uploads/2020/06/default-thumbnail.jpg"}
                />
            </div>
            <div className="exam-card_title">
                <span>{title}</span>
            </div>
            <div className="triangle-left"></div>
        </div>
    );
};

export default Card;
