import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResultAnswer from "../../../components/ResultAnswer";
import { compareStringsInDefaultFormat } from "src/utils/helpers/comparison";
import "./ImageLabel.scss";

function ImageLabel({
    // HTML properties:
    className,
    style,
    // Label:
    localId,
    localOrdinalNumber,
    type /** @param {string} type. Values: "text", "dropdown", "drag&drop". */,
    content /** @param {string} content */,
    contentCorrect /** @param {string} content */,
    isFocused /** @param {boolean} isFocused */,
    // Event handlers:
    onFocusContent,
    onBlurContent,
}) {
    const { t } = useTranslation();

    // Check if label content is correct:
    let lblContentResult;
    if (type === "text") {
        lblContentResult = compareStringsInDefaultFormat(content, contentCorrect);
    } else {
        lblContentResult = content === contentCorrect;
    }

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (isFocused) {
            r += " is-focused";
        }
        if (content) {
            r += " has-val";
        }
        return r;
    }, [className, isFocused, content]);

    const specialClassname = useMemo(() => {
        return (type || "").replace("&", "-");
    }, [type]);

    const handleFocusLblContent = () => {
        if (onFocusContent instanceof Function) {
            onFocusContent(localId);
        }
    };

    const handleBlurLblContent = () => {
        if (onBlurContent instanceof Function) {
            onBlurContent();
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        // Focus the label:
        handleFocusLblContent();
    };

    const renderLabelContent = () => {
        switch (type) {
            case "text":
            case "dropdown":
            case "drag&drop": {
                return content;
            }
            default:
                return null;
        }
    };

    const getResultStatus = () => {
        if (content) {
            return lblContentResult === true ? "correct" : "incorrect";
        } else {
            if (contentCorrect) {
                return "incorrect";
            } else {
                return undefined;
            }
        }
    };

    return (
        <span
            id={localId}
            className={`view-image-label view-result${extraClassnames} ${specialClassname}`}
            style={style}
            onClick={handleClick}
        >
            <span className="view-image-label-content-wrapper">
                <span className="image-label-pin">{/* Empty! */}</span>
                <ResultAnswer letter={localOrdinalNumber || "_"} resultStatus={getResultStatus()}>
                    <span className="image-label-content">{renderLabelContent()}</span>
                </ResultAnswer>
            </span>
        </span>
    );
}

export default ImageLabel;
