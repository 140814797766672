import { useState } from "react";
import { Tooltip, notification } from "antd";
import { t } from "i18next";
import { CopyOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import { reactQuestion, reactExamQuestion } from "src/api/containers/reaction";
import { useValues } from "src/hooks";
import "./Reactions.scss";

const reactTypes = {
    question: {
        target: "a question",
        targetKeyId: "question_id",
        doReaction: reactQuestion,
    },
    exam_question: {
        target: "an exam",
        targetKeyId: "exam_question_id",
        doReaction: reactExamQuestion,
    },
};

const Reactions = ({
    type = "question", // Values: "question" || "exam_question".
    targetId, // It can be question id, exam id.
    organizationId,
    reactionCount,
    cloneCount,
    isReacted,
    onReacted,
}) => {
    // This is a function to update reaction status:
    const _target = reactTypes[type].target;
    const _targetKeyId = reactTypes[type].targetKeyId;
    const _doReaction = reactTypes[type].doReaction;

    /**
     * These two used only in this component after calling `_doReaction` to update reaction status right away without rely on `onReacted`.
     * `currentReactionCount`: Is `reactionCount` after calling `_doReaction`.
     * `currentIsReacted`: Is `isReacted` after calling `_doReaction`.
     */
    const [currentReactionCount, setCurrentReactionCount] = useState(null);
    const [currentIsReacted, setCurrentIsReacted] = useState(null);

    /**
     * These two used to specify what to use:
     * - `reactionCount` or `currentReactionCount`.
     * - `isReacted` or `currentIsReacted`.
     */
    const actualReactionCount = currentReactionCount !== null ? currentReactionCount : reactionCount || 0;
    const actualIsReacted = currentIsReacted !== null ? currentIsReacted : isReacted || false;

    const [values, setValues] = useValues({
        loadingOn: "", // Values: "reaction" || "".
    });

    const handleReact = () => {
        if (values.loadingOn === "reaction") {
            return;
        }
        if (!targetId) {
            notification.error({ message: t(`Please select ${_target} to react!`) });
            return;
        }
        const data = {
            [_targetKeyId]: targetId,
            organization_id: organizationId,
        };
        setValues({ loadingOn: "reaction" });
        _doReaction(data).then((res) => {
            setValues({ loadingOn: "" });
            if (res.status) {
                const { reaction_count, is_reacted } = res.data || {};
                setCurrentReactionCount(reaction_count);
                setCurrentIsReacted(is_reacted);

                if (onReacted instanceof Function) {
                    onReacted(is_reacted, reaction_count);
                }
            }
        });
    };

    return (
        <div className="app-reactions">
            <div className="reaction-item">
                <Tooltip title={t("shared.reaction_count")}>
                    <div
                        className={`reaction-action act-love${actualIsReacted ? " active" : ""}${
                            values.loadingOn === "reaction" ? " loading" : ""
                        }`}
                    >
                        <CustomButton
                            type="simple"
                            icon={actualIsReacted ? <HeartFilled /> : <HeartOutlined />}
                            className="reaction-btn"
                            onClick={handleReact}
                        />
                    </div>
                </Tooltip>
                <div className="reaction-count">{actualReactionCount}</div>
            </div>
            <div className="reaction-item">
                <Tooltip title={t("shared.clone_count")}>
                    <div className="reaction-action act-clone">
                        <CustomButton type="simple" icon={<CopyOutlined />} className="reaction-btn" />
                    </div>
                </Tooltip>
                <div className="reaction-count">{cloneCount || 0}</div>
            </div>
        </div>
    );
};

export default Reactions;
