import api from "..";

/**
 * Send a message to ask for contact.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const sendContactRequest = (fetchParams) => {
    if (!fetchParams?.name || !fetchParams?.phone || !fetchParams?.message) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }
    const data = {
        name: fetchParams.name,
        phone: fetchParams.phone,
        message: fetchParams.message,
        plan_id: fetchParams.plan_id,
    };
    return api
        .post(`/contact`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
