import { BulbOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { t } from "i18next";
import Icon from "src/modules/components/Icon";
import LatexDangerously from "src/modules/components/LatexDangerously";
import "./Answer.scss";

const Answer = (props) => {
    const {
        letter = "a",
        // showResult = false,
        answer = { text: "", result: false, chosen: false },
        handleChooseAnswer = () => {},
    } = props;

    const generateClassName = () => {
        if (answer.chosen === true) {
            return answer.chosen === answer.result ? " chosen-correctly" : " chosen-incorrectly";
        } else {
            return answer.result === true ? " chosen-correctly" : "";
        }
    };

    const renderAnwerStatus = () => {
        if (answer.chosen === true) {
            return answer.chosen === answer.result ? (
                <span className="answer-status">
                    <Tooltip title={t("message.you_have_chosen_this_correct_answer")}>
                        <BulbOutlined style={{ marginRight: "15px", color: "#129D29" }} />
                    </Tooltip>
                    <Icon name="icon-tick" fill="#129D29"></Icon>
                </span>
            ) : (
                <span className="answer-status">
                    <Icon name="icon-cross-thick" fill="#FF0000"></Icon>
                </span>
            );
        } else {
            return answer.result === true ? (
                <span className="answer-status">
                    <Icon name="icon-tick" fill="#129D29"></Icon>
                </span>
            ) : null;
        }
    };

    return (
        <div className={`answer-for-multiple-choice${generateClassName()}`} onClick={() => handleChooseAnswer()}>
            <div className="answer-wrapper">
                <div className="answer-content">
                    <span className="answer-letter">{letter}</span>
                    <div className="answer-text">
                        <LatexDangerously innerHTML={answer.text} />
                    </div>
                </div>
                {renderAnwerStatus()}
            </div>
        </div>
    );
};

export default Answer;
