import { Input } from "antd";
import InputEditor from "src/modules/components/InputEditor";

const MyInput = (props) => {
    const { onChange, value } = props;

    const handleChange = (data) => {
        onChange({
            text: data,
            id: value.id,
        });
    };

    return <InputEditor value={value.text} onChange={handleChange} />;
};

export default MyInput;
