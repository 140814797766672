import "./StudentFeatureDropDown.scss";
import SettingIcon from "src/assets/images/setting-icon.svg";
import PowerIcon from "src/assets/images/power-icon.svg";
import { useRouting } from "src/utils/router";
import { useAuth } from "src/modules/auth";
import { Link } from "react-router-dom";

const StudentFeatureDropDown = () => {
    const { generate } = useRouting();
    const { logout } = useAuth();
    const dropDownList = [
        {
            id: 1,
            title: "Quản lý tài khoản",
            icon: SettingIcon,
            path: "profile",
        },
        {
            id: 2,
            title: "Đăng xuất",
            icon: PowerIcon,
            action: () => {
                logout(() => {});
            },
        },
    ];

    const handleClickLink = (e, action) => {
        if (action) {
            e.preventDefault();
            action();
        }
    };

    return (
        <div className="StudentFeatureDropDown_wrapper">
            <div className="StudentFeatureDropDown_dropDownList">
                {dropDownList.map((dropDownItem) => (
                    <Link
                        to={generate(dropDownItem.path)}
                        onClick={(e) => handleClickLink(e, dropDownItem.action)}
                        className="StudentFeatureDropDown_dropDownItem"
                        key={dropDownItem.id}
                    >
                        <img className="StudentFeatureDropDown_icon" src={dropDownItem.icon} />
                        {dropDownItem.title}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default StudentFeatureDropDown;
