import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formatDate: "DD/MM/YYYY",
    formatDateTime: "DD/MM/YYYY hh:mm:ss",
    collapsed: localStorage.getItem("collapsed") === "1",
    lang: localStorage.getItem("lang") || "vi",
    currentPage: "",
    isHelpModalVisible: false,
    isTooManyRequest: false,
    isFeedbackModalVisible: false,
    audioIdPlaying: null,
};

export const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        toggleCollapsed: (state, action) => {
            localStorage.setItem("collapsed", !state.collapsed ? "1" : "0");
            state.collapsed = !state.collapsed;
        },
        setCollapsed: (state, action) => {
            localStorage.setItem("collapsed", action.payload ? "1" : "0");
            state.collapsed = action.payload;
        },
        changeLang: (state, action) => {
            localStorage.setItem("lang", action.payload);
            state.lang = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        toggleHelpModalVisible: (state, action) => {
            state.isHelpModalVisible = action.payload || !state.isHelpModalVisible;
        },
        setIsTooManyRequest: (state, action) => {
            state.isTooManyRequest = action.payload;
        },
        toggleFeedbackModalVisible: (state, action) => {
            state.isFeedbackModalVisible = action.payload || !state.isFeedbackModalVisible;
        },
        setAudioIdPlaying: (state, action) => {
            state.audioIdPlaying = action.payload;
        },
        removeAudioIdPlaying: (state) => {
            state.audioIdPlaying = null;
        },
    },
});

export const {
    toggleCollapsed,
    setCollapsed,
    changeLang,
    setCurrentPage,
    toggleHelpModalVisible,
    setIsTooManyRequest,
    toggleFeedbackModalVisible,
    setAudioIdPlaying,
    removeAudioIdPlaying,
} = generalSlice.actions;

export default generalSlice.reducer;
