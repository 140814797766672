import { questionTypes } from "../containers/question";
import { shuffleArr } from "src/utils/helpers/array";
import { replaceBlobWithBase64 } from "src/utils/helpers";

const convertBlobToBase64 = async (ansData) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(ansData, "text/html");
    let images = doc.querySelectorAll("img");
    if (images.length > 0) {
        await Promise.all(Array.from(images).map(replaceBlobWithBase64));
        ansData = doc.body.innerHTML;
    }
    return ansData;
};

/**
 * Get question type by question key.
 * @param {string} questionKey A question key.
 * @returns (a question type) || false.
 */
export const getQuestionTypeByKey = (questionKey) => {
    let isValid = false;
    const typeList = Object.keys(questionTypes);
    for (let i = 0; i < typeList.length; i++) {
        if (questionTypes[typeList[i]].key === questionKey) {
            isValid = typeList[i];
            break;
        }
    }
    return isValid;
};

/**
 * Get question key by question type.
 * @param {string} questionType A question type.
 * @returns (a question key) || false.
 */
export const getQuestionKeyByType = (questionType) => {
    return questionTypes[questionType]?.key || false;
};

/**
 * ?
 * Get question key by question type.
 * @param {string} questionType A question type.
 * @returns isScoring.
 */
export const getQuestionIsScoringByType = (questionType) => {
    return questionTypes[questionType]?.isScoring || false;
};

/**
 * ?
 * Get types is scoring
 */
export const getTypesAreScoring = () => {
    const result = [];

    Object.keys(questionTypes).forEach((key) => {
        if (questionTypes[key].isScoring) {
            result.push(key);
        }
    });

    return result;
};

/**
 * Get list of question types but not include mentioned type.
 * @param {Array} excludes List of excluded types.
 * @returns Array.
 */
export const getQuestionTypesExclude = (excludes = []) => {
    const types = Object.keys(questionTypes || {});

    return types.filter((type) => !excludes?.includes(type));
};

/**
 * [TEACHER - USED WHEN PREVIEWING A QUESTION: view as student, view result]
 * Get questionData ('for-previewing' version) by questionData.
 * - Specify expected answers for the question: correct_answer.
 * - Specify possible answers for the question: answer.
 * - Shuffle question's possible answers for previewing question detail.
 * @param {Object} questionData Question.
 * @returns Object.
 */
export const getQuestionDataPreview = (questionData = {}) => {
    switch (questionData?.type) {
        case "fill_in_the_blank":
        case "fill_in_the_blank_drag_drop":
        case "fill_in_the_blank_image":
        case "fill_in_the_blank_drag_drop_image": {
            /**
             * 1. If the questionData is question-construction data, handle:
             * - Create a fake 'correct_answer' from 'answer'.
             * - As for 'answer':
             *   + shuffle 'answer1'.
             *   + use 'answer1' value instead.
             * - As for 'answer1':
             *   + will be removed.
             *
             * 2. If the questionData is question-for-previewing data, handle:
             * - Nothing!
             */
            const isQuestionConstructionData = questionData.answer && questionData.answer1;
            if (isQuestionConstructionData) {
                const result = { ...questionData };
                // Setup 'correct_answer':
                result.correct_answer = questionData.answer;
                // Setup 'answer', remove 'answer1':
                let answersPossible = questionData.answer1 || questionData.answer;
                if (answersPossible instanceof Array) {
                    result.answer = shuffleArr(answersPossible);
                    result.answer1 = null;
                }
                return result;
            }
            break;
        }
        case "draw_graph": {
            if (questionData.answer instanceof Array) {
                /**
                 * 1. If the questionData is question-construction data, handle:
                 * - Create a fake 'correct_answer' from 'answer'.
                 * - As for 'answer':
                 *   + Removing all of it's item ids.
                 *   + Empty all of it's item values.
                 *
                 * 2. If the questionData is question-for-previewing data, handle:
                 * - Nothing!
                 */
                const isQuestionConstructionData = !questionData.correct_answer;
                if (isQuestionConstructionData) {
                    let answersCorrect = questionData.answer;
                    const answersProvided = structuredClone(answersCorrect).map((item) => {
                        if (item.type === "point") {
                            delete item.id;
                            item.values = [];
                        } else {
                            delete item.id;
                            if (item.values instanceof Array) {
                                item.values.forEach((valItem) => {
                                    delete valItem.id;
                                    valItem.values = [];
                                });
                            }
                        }
                        return item;
                    });
                    return {
                        ...questionData,
                        answer: answersProvided,
                        correct_answer: answersCorrect,
                    };
                }
            }
            break;
        }
        case "number_line": {
            if (questionData.answer instanceof Array) {
                /**
                 * 1. If the questionData is question-construction data, handle:
                 * - Create a fake 'correct_answer' from 'answer'.
                 * - As for 'answer':
                 *   + Removing all of it's item ids, item types.
                 *   + Empty all of it's item values.
                 *
                 * 2. If the questionData is question-for-previewing data, handle:
                 * - Nothing!
                 */
                const isQuestionConstructionData = !questionData.correct_answer;
                if (isQuestionConstructionData) {
                    let answersCorrect = questionData.answer;
                    const answersProvided = structuredClone(answersCorrect).map((item) => {
                        delete item.id;
                        delete item.type;
                        item.value = undefined;
                        return item;
                    });
                    return {
                        ...questionData,
                        answer: answersProvided,
                        correct_answer: answersCorrect,
                    };
                }
            }
            break;
        }
        case "passage": {
            /**
             * 1. If the questionData is question-construction data, handle:
             * - Run this function for each question's paragraph.
             *
             * 2. If the questionData is question-for-previewing data.
             * This case never happens. Because we can only check its children.
             */
            if (questionData.paragraphs instanceof Array) {
                return {
                    ...questionData,
                    paragraphs: questionData.paragraphs.map((pItem) => {
                        return {
                            ...pItem,
                            question_details: pItem.question_details?.map((qItem) => {
                                return getQuestionDataPreview(qItem);
                            }),
                        };
                    }),
                };
            }
            break;
        }

        case "drag_drop": {
            if (questionData?.answer1?.length && questionData?.answer2?.length) {
                return { ...questionData, answer2: shuffleArr(questionData.answer2) };
            }
            if (questionData?.answer?.answer1?.length && questionData?.answer?.answer2?.length) {
                return {
                    ...questionData,
                    answer: {
                        ...questionData.answer,
                        answer2: shuffleArr(questionData.answer.answer2),
                    },
                };
            }
            break;
        }
        case "drag_drop_group_order_by":
        case "drag_drop_group": {
            if (questionData?.answer1?.length && questionData?.answer2?.length) {
                return { ...questionData, answer2: shuffleArr(questionData.answer2) };
            }
            break;
        }
        case "sort": {
            if (questionData?.answer?.length) {
                return { ...questionData, answer: shuffleArr(questionData.answer) };
            }
            break;
        }
    }
    return questionData;
};

/**
 * [TEACHER - USED WHEN PREVIEWING A QUESTION LIST: view as student, view result]
 * Get questionListData (preview version) by questionListData.
 * @param {Array} questionListData Array of question objects.
 * @returns Array.
 */
export const getQuestionListDataPreview = (questionListData = []) => {
    return questionListData.map((item) => {
        if (item?.detail) {
            return { ...item, detail: getQuestionDataPreview(item.detail) };
        } else {
            return getQuestionDataPreview(item);
        }
    });
};

/**
 * [TEACHER - USED WHEN SUBMITTING A QUESTION TO CREATE/UPDATE]
 * Get questionData ('for-submitting' version) by questionData.
 * - Remove unnecessary data.
 * @param {Object} questionData Question.
 * @returns Object.
 */
export const getQuestionDataSubmit = async (questionData = {}) => {
    switch (questionData?.type) {
        case "multiple_choice":
        case "multiple_choice_sentence_quiz": {
            if (questionData.answer instanceof Array) {
                /**
                 * 1. Convert all images that have tag with blob `src` to base64 `src`:
                 */
                let answersCorrect = questionData.answer;
                const answersCorrectNew = structuredClone(answersCorrect);
                const answersCorrectNewText = await Promise.all(
                    answersCorrectNew.map((item) => convertBlobToBase64(item.text))
                );
                answersCorrectNew.forEach((item, itemIndex) => {
                    item.text = answersCorrectNewText[itemIndex];
                });
                return { ...questionData, answer: answersCorrectNew };
            }
            break;
        }
        case "draw_graph": {
            if (questionData.answer instanceof Array) {
                /**
                 * 1. If the questionData is question-construction data, handle:
                 * - Removing all of it's answer-item ids.
                 */
                let answersCorrect = questionData.answer;
                const answersCorrectNew = structuredClone(answersCorrect).map((item) => {
                    if (item.type === "point") {
                        delete item.id;
                    } else {
                        delete item.id;
                        if (item.values instanceof Array) {
                            item.values.forEach((valItem) => {
                                delete valItem.id;
                            });
                        }
                    }
                    return item;
                });
                return { ...questionData, answer: answersCorrectNew };
            }
            break;
        }
        case "number_line": {
            if (questionData.answer instanceof Array) {
                /**
                 * 1. If the questionData is question-construction data, handle:
                 * - Removing all of it's answer-item ids, answer-item types.
                 */
                let answersCorrect = questionData.answer;
                const answersCorrectNew = structuredClone(answersCorrect).map((item) => {
                    delete item.id;
                    delete item.type;
                    return item;
                });
                return { ...questionData, answer: answersCorrectNew };
            }
            break;
        }
    }
    return questionData;
};
