import "./Card.scss";

const Card = ({ title, image, actions, children, ...rest }) => {
    return (
        <div className="learning-progress-report-nav-card">
            <div className="card-title">{title || ""}</div>
            <div className="card-action-list">{children}</div>
            <div className="bg-img-wrapper">{image}</div>
        </div>
    );
};

export default Card;
