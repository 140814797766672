import { Dropdown, Space, Table, Tag } from "antd";
import "./PaymentHistory.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { useFetch, useValues } from "src/hooks";
import { fetchSubscriptions } from "src/api/containers/subscription";
import moment from "moment";
import InputSearch from "src/modules/components/Input/InputSearch";
import CustomButton from "src/modules/components/Button";
import FilterMenu from "./components/FilterMenu";
import FilterIcon from "src/assets/images/filter-icon.svg";

function PaymentHistory({}) {
    const { t } = useTranslation();

    const [values, setValues] = useValues({
        inputSearch: "",
        filterParams: [],
        slug: "",
        isFilterMenuVisible: false,
    });

    const [loadingPayments, payments, paginationPayments, fetchPayments, refetchPayments] = useFetch(
        {},
        fetchSubscriptions
    );

    const paymentColumns = [
        {
            title: t("setting.code"),
            key: "code",
            dataIndex: "code",
            // render=>{

            // }
        },
        {
            title: t("setting.subscription"),
            key: "plan",
            dataIndex: "plan",
            render: (plan) => {
                return plan?.name;
            },
        },
        {
            title: t("setting.amount"),
            key: "amount",
            dataIndex: "amount",
            render: (amount) => (amount ? amount.toLocaleString() : amount),
        },
        {
            title: t("shared.created_at"),
            key: "created_at",
            dataIndex: "created_at",
            render: (text) => {
                return moment(text).format("DD/MM/YYYY HH:mm:ss");
            },
        },
        {
            title: t("class.begin_date"),
            key: "valid_from",
            dataIndex: "valid_from",
            render: (text) => {
                return moment(text).format("DD/MM/YYYY");
            },
        },
        {
            title: t("setting.expiration_date"),
            key: "valid_to",
            dataIndex: "valid_to",
            render: (text) => {
                return moment(text).format("DD/MM/YYYY");
            },
        },
        {
            title: t("setting.payment_method"),
            key: "payment_method",
            dataIndex: "payment_method",
            render: (text, record) => {
                return record?.bill?.payment_method;
            },
        },
        {
            title: t("setting.payment_status"),
            key: "status",
            dataIndex: "status",
            render: (text, record) => {
                if (["PAY_ERROR", "PAYED", "NOT_PAYED"].includes(record?.bill?.status)) {
                    return t(`payment.${record?.bill?.status}`);
                }
                return record?.bill?.status;
            },
        },
    ];

    const handleSearch = () => {
        fetchPayments(
            {
                slug: values.inputSearch,
            },
            true
        );
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = values.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setValues({ filterParams: newFilterParams });
    };

    useEffect(() => {
        //call api
        fetchPayments({}, true);
    }, []);

    return (
        <div className="payment_history">
            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("shared.find")}
                            value={values.inputSearch}
                            onChange={(e) => setValues({ inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {values.filterParams?.length > 0
                                    ? values.filterParams.map((fKey, i) => {
                                          return (
                                              <Tag
                                                  className="app-tag"
                                                  key={`filter-key${i}`}
                                                  closable
                                                  onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                              >
                                                  {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                  {`${fKey.labelValue || ""}`}
                                              </Tag>
                                          );
                                      })
                                    : // <>{t("shared.option_filter")}</>
                                      null}
                            </Space>
                        </div>
                        <Dropdown
                            visible={values.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={values.filterParams}
                                    filterParamsInitial={values.filterParams}
                                    handleFetchPaymentsHistory={fetchPayments}
                                    handleCloseFilterMenu={(newFilterParams) => {
                                        setValues({
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        });
                                    }}
                                    willFormBeCleared={!values.isFilterMenuVisible}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => setValues({ isFilterMenuVisible: val })}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={FilterIcon}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <br />
            <Table
                className="app-table payment_history-table"
                columns={paymentColumns}
                dataSource={payments}
                scroll={{ x: "auto" }}
                pagination={{
                    showSizeChanger: false,
                    ...paginationPayments,
                    position: ["bottomCenter"],
                    onChange: (page) => {
                        fetchPayments({ page: page });
                    },
                }}
                loading={loadingPayments}
            />
        </div>
    );
}

export default PaymentHistory;
