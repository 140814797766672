/**
 * Shuffle an array.
 * @param {Array} arr Array of numbers/strings/objects.
 * @param {boolean} isDifferent Check if the result is different from arr.
 * @returns A new array.
 */
export const shuffleArr = (arr, isDifferent = true) => {
    if (arr?.length) {
        // 1.Validation:
        // - Arrays with the same values can not be different from each other.
        if (isDifferent) {
            isDifferent = arr.some((item, index) => item !== arr[0]);
        }
        // 2. Handle shuffling:
        if (arr.length > 2 && isDifferent) {
            let rIsDifferent = false;
            let rArr = [...arr];
            while (rIsDifferent === false) {
                rArr = rArr.sort(() => Math.random() - 0.5);
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] !== rArr[i]) {
                        rIsDifferent = true;
                        break;
                    }
                }
            }
            return rArr;
        } else {
            return [...arr].sort(() => Math.random() - 0.5);
        }
    }
    return false;
};
