import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { Dropdown, Modal, Space, Tag } from "antd";
import InputSearch from "src/modules/components/Input/InputSearch";
import Table from "src/modules/components/Table";
import FilterMenu from "src/modules/containers/Class/components/FilterMenu";
import { default as CustomButton } from "src/modules/components/Button";
import { useFetch, useValues } from "src/hooks";
import { classFilters, fetchClasses } from "src/api/containers/class";
import { fetchBranches } from "src/api/containers/branch";
import FilterIcon from "src/assets/images/filter-icon.svg";
import actionClose from "src/assets/images/action-close.svg";
import "./ModalClassList.scss";

const ModalClassList = (props) => {
    const { teacher_id, modalClassName = "", onCancel = () => {}, ...rest } = props;

    const { t } = useTranslation();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const mainfetchParams = {
        page: 1,
        is_active: 1,
        user_id: teacher_id,
        // class_in_organization: 1,
        organization_id: user.paramsForOrgOrTeam?.organization_id,
        relations: ["branch", user?.currentOrg?.scopeOptionKey === "organization" ? "organization" : "team"],
    };
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { ...mainfetchParams, ...fParams },
        fetchClasses
    );

    // Others:
    const { generate } = useRouting();
    const [values, setValues] = useValues({
        listOfBranches: [],
        organization_id: user.organization?.organization_id,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchValue: "",
        isFilterMenuVisible: false,
        filterParams: [
            {
                name: "is_active",
                value: "1",
                labelName: t("shared.status"),
                labelValue: t(`class.${classFilters.is_active["1"]}`),
            },
        ],
    });

    const tableColumns = [
        {
            title: t("organization.code"),
            dataIndex: "code",
            key: "code",
            width: "auto",
            render: (text, record) => record?.code,
        },
        {
            title: t("organization.class_name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => record?.name,
        },
        {
            title: t("organization.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => record?.begin_date && moment(record.begin_date).format("DD/MM/YYYY"),
        },
        {
            title: t("organization.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => record?.end_date && moment(record.end_date).format("DD/MM/YYYY"),
        },
        {
            title: t("organization.branch"),
            dataIndex: "branch",
            key: "branch",
            width: "auto",
            render: (text, record) => record?.branch?.name || "_",
        },
        {
            dataIndex: "action",
            key: "action",
            width: "auto",
            render: (text, record) => (
                <Link to={generate("detail_class", { id: getLinkToClass(record?.code) })}>
                    {t("organization.detail")}
                </Link>
            ),
        },
    ];

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const getLinkToClass = (val) => {
        if (dataSource?.length > 0) {
            const result = dataSource.find((item) => item.code == val);
            return result.id;
        }
        return "";
    };

    const handleSearch = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchValue });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const customizeBranchList = (branches) => {
        const renderActive = (active) => {
            return active ? "" : ` (${t("branch.inactive")})`;
        };
        let result = [];
        if (branches?.length > 0) {
            result = branches.map((item) => {
                const newName = item.name + renderActive(item.active);
                return { ...item, name: newName };
            });
        }
        return result;
    };

    useEffect(() => {
        if (teacher_id) {
            fetch({ user_id: teacher_id }, true);
        }
    }, [teacher_id]);

    useEffect(() => {
        fetchBranches({ noPagination: true, organization_id: user?.paramsForOrgOrTeam?.organization_id }).then(
            (res) => {
                setValues({ listOfBranches: customizeBranchList(res.data) });
            }
        );
    }, []);

    return (
        <Modal
            centered
            title={t("organization.class_list")}
            closeIcon={<img src={actionClose}></img>}
            footer={null}
            width="auto"
            onCancel={handleCancel}
            {...rest}
            className={`app-modal type-basic${props.className ? " " + props.className : ""}`}
        >
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchValue: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => {
                                                          handleRemoveFilterParam(e, fKey.name);
                                                      }}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                forceRender
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                        excludeFields={["organization_id"]}
                                        branchDataList={values.listOfBranches}
                                        onFetchDataList={fetch}
                                        onCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        // type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    {/* <div className="filter-toolbar-actions">
                        <CustomButton
                            // type="primary"
                            icon={Pencil}
                            title={t("manage_employee.invite_teacher")}
                            onClick={() => setValues({ isAddUserModalVisible: true })}
                        ></CustomButton>
                    </div> */}
                </div>
            </div>
            <Table
                loading={loading}
                dataSource={dataSource || []}
                pagination={{
                    showSizeChanger: false,
                    ...pagination,
                    position: ["bottomCenter"],
                    onChange: (page) => {
                        fetch({ page: page });
                    },
                }}
                columns={tableColumns}
                className="app-table"
                scroll={{ x: "auto" }}
                rowKey="id"
            />
        </Modal>
    );
};

export default ModalClassList;
