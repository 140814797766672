import { generateConfig } from "./_default";

/**
 * Editor (textarea).
 * IMPORTANT NOTES:
 * - Textarea's value can include HTML elements with any type of 'display' property.
 * @param {Object} values Custom config values.
 * @returns Editor's config.
 */
export const generateConfigForTextarea = (values = {}) => {
    const {
        // Counting & limiting words/characters:
        wordCountMax,
        onCountWord,
    } = values;

    const { setup, ...rest } = generateConfig({
        customTypeName: "textarea",
        editingMode: "inline",
        isToolbarInsideEditorRoot: false,
        wordCountMax,
        onCountWord,
    });

    return {
        ...rest,

        // Plugins, toolbar, menubar:
        plugins:
            "paste-options" +
            " lists advlist" +
            " image imagetools media" +
            " insertdatetime link anchor table charmap math codesample hr" +
            " searchreplace wordcount" +
            " directionality visualblocks visualchars" +
            " preview" +
            " nonbreaking pagebreak" +
            " quickbars help",
        toolbar:
            "undo redo" +
            " | searchreplace" +
            " | bold italic underline strikethrough removeformat" +
            " | fontselect fontsizeselect formatselect lineheight" +
            " | alignleft aligncenter alignright alignjustify" +
            " | outdent indent" +
            " | bullist numlist" +
            " | subscript superscript" +
            " | forecolor backcolor" +
            " | paste-options" +
            " | image media" +
            " | link table charmap math",
        toolbar_mode: "sliding",
        menubar: "edit insert view format table tools help",
        contextmenu: "image imagetools link table",
        quickbars_selection_toolbar: "bold italic underline strikethrough | removeformat",
        quickbars_insert_toolbar: false,

        // Custom settings:
        setup: (editor) => {
            if (setup instanceof Function) {
                setup(editor);
            }
        },
    };
};
