import { Upload, Progress, Form, Spin, Space, notification } from "antd";
import axios from "axios";
import { useState } from "react";
import { default as CustomButton } from "src/modules/components/Button";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined, ExportOutlined } from "@ant-design/icons";
import "./UploadBtn.scss";
import api from "src/api";
import { createMultiUser, exportUsers } from "src/api/containers/user";
import { useSelector } from "react-redux";
import moment from "moment";
import { getCookie } from "src/utils/helpers";
import configs from "src/configs";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";

const UploadBtn = ({ setLoading, loading, refetch, fileName, role, organization }) => {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const uploadFile = async (options) => {
        const { onSuccess, onError, file } = options;

        let fmData = new FormData();

        fmData.append("files", file);
        setLoading({ loadingFile: true });

        if (organization?.organization_id) {
            fmData.append("organization_id", organization.organization_id);
        }

        createMultiUser(fmData, role).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("organization.import_user_success") });
                // refetch({}, true);
            } else {
                notification.error({ message: message || t("organization.add_user_fail") });
            }
            setLoading({ loadingFile: false });
        });
    };

    const handleDownload = () => {
        const token = getCookie("token") || localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };
        const params = {
            organization_id: organization?.organization_id,
        };
        axios
            .get(`${configs.API_ENDPOINT}/manage/${role}/export`, {
                responseType: "blob",
                params,
                headers,
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}_${moment().format("YYMMDDHHmm")}.xlsx`); //or any other extension
                link.click();
            });
    };

    const handleDownloadSampleFile = () => {
        const token = getCookie("token") || localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };
        let url = `${configs.API_ENDPOINT}/file/import.xlsx`;
        if (role == "employee") {
            url = `${configs.API_ENDPOINT}/file/import_employee.xlsx`;
        }
        axios
            .get(url, {
                responseType: "blob",
                headers,
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${"file-mau"}_${moment().format("YYMMDDHHmm")}.xlsx`); //or any other extension
                link.click();
            });
    };

    return (
        <div className="button-import-export">
            <Space>
                <Can I={rolesAllow.import} page={pagesAllow[role]}>
                    <div className="button-import">
                        <Upload
                            name="file"
                            accept=".xls,.xlsx,.csv"
                            // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            customRequest={uploadFile}
                            className="image-upload-grid"
                            multiple={false}
                            showUploadList={false}
                            maxCount={1}
                        >
                            <Spin spinning={loading}>
                                <CustomButton
                                    type="primary"
                                    title={t("organization.batch_import")}
                                    icon={<CloudUploadOutlined />}
                                ></CustomButton>
                            </Spin>
                        </Upload>
                        <div className="button-import_sub-btn" onClick={handleDownloadSampleFile}>
                            ({t("organization.download_file")})
                        </div>
                    </div>
                </Can>
                <Can I={rolesAllow.export} page={pagesAllow[role]}>
                    <Spin spinning={false}>
                        <CustomButton
                            type="primary"
                            title={t("organization.export")}
                            icon={<ExportOutlined />}
                            onClick={handleDownload}
                        ></CustomButton>
                    </Spin>
                </Can>
            </Space>
        </div>
    );
};

export default UploadBtn;
