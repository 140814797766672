import { Column } from "@ant-design/plots";
import "./PercentColumnPlot.scss";
import { getColorForPercentChart } from "src/utils/helpers";
import React, { useMemo } from "react";
import { Spin } from "antd";
import useMediaQuery from "src/hooks/useMediaQuery";

const PercentColumnPlot = (props) => {
    const { label, data, isLoading = false } = props;
    const currMedia = useMediaQuery(["(max-width: 576px)"]);

    const configChartYAxis = {
        grid: {
            line: null,
        },
        line: {
            style: {
                stroke: "#000",
                strokeOpacity: 1,
            },
        },
        tickLine: {
            alignTick: true,
            style: {
                stroke: "#000",
                strokeOpacity: 1,
            },
        },
        label: {
            style: {
                fill: "#fff",
                fontSize: 16,
                fontWeight: "400",
                lineHeight: 35,
            },
        },
    };

    const config = useMemo(() => {
        return {
            data,
            xField: "name",
            yField: "percent",
            seriesField: "percent",
            // isStack: true,
            legend: false,
            label: {
                position: "middle",
                content: (item) => {
                    return item.percent + "%";
                },
                style: {
                    fill: "#000",
                },
            },
            appendPadding: [60, 50, 60, 70],
            yAxis: {
                ...configChartYAxis,
                min: 0,
                tickInterval: 10,
                label: {
                    formatter: (value) => {
                        return value + "%";
                    },
                },
                max: 100,
            },
            xAxis: {
                grid: {
                    line: null,
                },
                line: {
                    style: {
                        stroke: "#000",
                        strokeOpacity: 1,
                    },
                },
                label:
                    currMedia.breakpointValue === 576
                        ? null
                        : {
                              autoRotate: true,
                              autoHide: false,
                              offset: 10,
                              style: {
                                  fill: "#000",
                                  fontSize: 14,
                                  fontWeight: "400",
                                  lineHeight: 35,
                              },
                          },
            },
            color: ({ percent }) => {
                return getColorForPercentChart(percent);
            },
            // tooltip: false,
            columnBackground: {
                style: {
                    fill: "#CCCCCC",
                },
            },

            theme: {
                maxColumnWidth: 50,
            },
        };
    }, [currMedia]);
    return (
        <div className="percentColumnPlot_wrapper">
            <div className="percentColumnPlot_inner">
                <Column {...config} />
                <div className="percentColumnPlot_label">{label}</div>
            </div>
        </div>
    );
};

export default React.memo(PercentColumnPlot);
