import { Button, Card, Checkbox, Grid, Image, Radio, Spin, notification } from "antd";
import styles from "./ImageSelector.module.scss";
import { useSelector } from "react-redux";
import { addFiles } from "src/api/containers/storage";
import { useValues } from "src/hooks";
import { base64ToBlob } from "src/utils/helpers";
import { createExam } from "src/api/containers/exam";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import CustomButton from "src/modules/components/Button";
import { PDF_CONST } from "../../../DetailExamPDF/constant";

function ImageSelector({ images = [], selectedImageIds = [], setSelectedImageIds = () => {} }) {
    const user = useSelector((state) => state.auth.user);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const navigate = useNavigate();
    const { generate } = useRouting();

    const [values, setValues] = useValues({
        loadingImage: false,
    });

    const handleSelectAll = (e) => {
        const checked = e.target.checked;

        if (checked) {
            const ids = images.map((item) => item?.id);
            setSelectedImageIds(ids);
        } else {
            setSelectedImageIds([]);
        }
    };

    const handleSelectImage = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;

        if (checked) {
            if (!selectedImageIds.includes(value)) {
                setSelectedImageIds([...selectedImageIds, value]);
            }
        } else {
            const newImgs = selectedImageIds.filter((item) => item !== value);
            setSelectedImageIds(newImgs);
        }
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const convertB64ToBlob = (b64, filename) => {
        const file = new File([new Blob([b64])], filename, { contentType: "image/png" });

        return file;
    };

    const b64ToFile = (b64, fileName) => {
        // const blob1 = base64ToBlob(b64, "image/png");
        var file = dataURLtoFile(b64, `${fileName || "file"}.png`);
        // const file = new File([blob1], `${fileName || "file"}.png`, {
        //     type: "image/png" || "application/octet-stream",
        // });
        return file;
    };

    const getSelectedImages = () => {
        return images.filter((item) => selectedImageIds.includes(item.id));
    };

    const generateDefaultPaths = (file_ids) => {
        return file_ids.map((item) => {
            return {
                file_id: item,
                path: PDF_CONST.DEFAULT_PATHS,
            };
        });
    };

    function createExamDraft(file_ids) {
        const examdata = {
            name: "",
            category_id: "",
            max_score: 0,
            is_publish: false,
            is_public: false,
            file_ids: file_ids,
            type: "pdf",
            paths: generateDefaultPaths(file_ids),
        };

        setValues({ loadingImage: true });
        createExam(examdata).then((res) => {
            if (res.status) {
                setValues({ loadingImage: false });
                navigate(generate("update_exam_pdf", { id: res.id }));
            } else {
                notification.error({ message: t("message.an_error_occurred") });
                setValues({ loadingImage: false });
            }
        });
    }

    function handleSaveImages() {
        const formData = new FormData();

        const selectedImgs = getSelectedImages();
        if (selectedImgs.length > 0) {
            [...selectedImgs].forEach((item, fileIndex) => {
                const file = b64ToFile(item.src, item.name);

                formData.append(`file${fileIndex}`, file, `${item.name}.png`);
            });
        }

        if (fParamsOrgMember.organization_id) {
            formData.append("organization_id", fParamsOrgMember.organization_id);
        }

        return new Promise((resolve, reject) => {
            setValues({ loadingImage: true });
            addFiles(formData).then((res) => {
                if (res.status) {
                    resolve(res.data);
                    // setValues({ loadingImage: false });
                    const image_ids = res.data?.map((item) => item?.id);
                    createExamDraft(image_ids);
                } else {
                    reject(res);
                    setValues({ loadingImage: false });
                    notification.error({ message: res.message });
                }
            });
        });
    }

    return (
        <Spin spinning={values.loadingImage}>
            <div className={`pdf-image-selector ${styles.container}`}>
                <div className={styles.wrapper}>
                    {/* <Radio.Group onChange={handleSelectAll} name="checkAll">
                    <Radio.Button value={true}>Chọn tất cả</Radio.Button>
                    <Radio.Button value={false}>Bỏ CHọn tất cả</Radio.Button>
                </Radio.Group> */}
                    <Checkbox onChange={handleSelectAll}>Chọn tất cả</Checkbox>
                    <div className={styles.listCard}>
                        {images.map((item) => {
                            return (
                                <Card
                                    key={item.id}
                                    bordered
                                    hoverable
                                    actions={null}
                                    bodyStyle={{ padding: 4 }}
                                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                                >
                                    <div className={styles.imageWrapper}>
                                        <span className={styles.checkbox}>
                                            <Checkbox
                                                style={{ width: 20, height: 20 }}
                                                className="flex flex-center"
                                                onChange={handleSelectImage}
                                                value={item.id}
                                                checked={selectedImageIds.includes(item.id)}
                                            ></Checkbox>
                                        </span>
                                        <div className={styles.image}>
                                            <Image alt="example" src={item.src} />
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>

                    <div className={styles.footer}>
                        <CustomButton title="Tiếp tục" onClick={handleSaveImages} />
                    </div>
                </div>
            </div>
        </Spin>
    );
}

export default ImageSelector;
