import api from "..";

const subscriptionFields = {
    info: ["code", "valid_from", "valid_to", "total", "amount"],
};

const subscriptionRelations = ["bill", "plan", "planPrice"];

//API lấy ra lịch sử thanh toán của các gói mà user đăng kí
export const fetchSubscriptions = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.valid_from && filters.push(`valid_from:${fetchParams.valid_from}`);
    fetchParams.valid_to && filters.push(`valid_to:${fetchParams.valid_to}`);
    fetchParams.bill_status && filters.push(`bill_status:${fetchParams.bill_status}`);
    fetchParams.plan_id && filters.push(`plan_id:${fetchParams.plan_id}`);

    // fetchParams.planType && filters.push(`type:${fetchParams.planType}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _subscription_fields: `${subscriptionFields.info}`,
        _relations: `${subscriptionRelations}`,
        _plan_price_fields: `${["months"]}`,
        _plan_fields: `${["name"]}`,
        _bill_fields: `${["status", "payment_method"]}`,
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/subscription", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                    lastPage: meta.pagination.lastPage,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};
