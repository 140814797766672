import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./NumberCard.module.scss";

function NumberCard({ image, titleKey, count, linkTo, isAllowLinkTo, color_avt }) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.avatar} style={{ backgroundColor: color_avt }}>
                    <img src={image || "#"} />
                </div>
                <div className={styles.content}>
                    <p className={styles.number}>{count?.toLocaleString("en-US") || t("unknown")}</p>
                    <div className={styles.text}>
                        <p>{t(`dashboard.${titleKey}`)}</p>
                    </div>
                </div>
                <div className={styles.actions} style={{ color: color_avt }}>
                    {isAllowLinkTo && (
                        <Link to={linkTo} style={{ color: "inherit" }}>
                            <button>
                                <span style={{ marginRight: 4 }}>{t("view")}</span>
                                <span style={{ fontSize: 10 }}>
                                    <ArrowRightOutlined />
                                </span>
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NumberCard;
