import InputEditor from "src/modules/components/InputEditor";
import { BinIcon } from "src/utils/drawer";

const BlankInput = (props) => {
    const { onChange, value, onDeleteCorrectAnswer } = props;

    const handleChange = (data) => {
        onChange({
            text: data,
            id: value.id,
        });
    };

    const handleDeleteCorrectAnswer = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        onDeleteCorrectAnswer(id);
    };

    return (
        <InputEditor
            value={value?.text}
            onChange={handleChange}
            isSuffix
            {...(value?.id < 0
                ? {
                      suffix: (
                          <BinIcon
                              className="fill-blank-drag-drop__correct_answer__delete"
                              onClick={(e) => handleDeleteCorrectAnswer(e, value?.id)}
                          />
                      ),
                  }
                : {})}
        />
    );
};

export default BlankInput;
