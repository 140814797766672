import api from "..";

export const fetchLanguages = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _language_fields: `${["name", "code"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/language/get_languages_speech", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
            };
        })
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const fetchSpeaker = (fetchParams) => {
    const params = {
        code: fetchParams?.code,
    };

    return api
        .get("/language/get_speakers_speech", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};
