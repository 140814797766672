import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import "./Highlight.scss";

const Highlight = (props) => {
    const {
        question,
        passage,
        // highlight_submit,
        passage_student,
        answered,
    } = props;

    const { passage_highlighted: highlight_submit = "" } = answered || {};

    return (
        <div className="qc_highlight">
            <div className="viewScoreStudent_highlightTitle">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                    }}
                />
            </div>
            <div className="passage_wrap">
                <div className="passage_item">
                    <div className="passage_title title-danger"> {t("question.your_answer")}</div>
                    <div
                        className="contentResultHighlight"
                        dangerouslySetInnerHTML={{ __html: highlight_submit || passage_student || "" }}
                    ></div>
                </div>
                <div className="passage_item">
                    <div className="passage_title title-success"> {t("question.correct_answer")}</div>
                    <div
                        className="contentResultHighlight"
                        dangerouslySetInnerHTML={{
                            __html:
                                passage
                                    ?.replace(/<highlight/g, "<span class='selected'")
                                    .replace(/<\/highlight>/g, "</span>") || "",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Highlight;
