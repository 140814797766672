import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, notification } from "antd";
import PopupFileManager from "../FileManager/popup";
import { fileManagerConfigs, validateFileBeforeUpload } from "../FileManager/utils/helpers";
import "./UploadFile.scss";
import { ReactComponent as IconCamera } from "src/assets/images/camera-icon.svg";

function UploadFile({ className, fileType, selectedFile, onChangeFile, readOnly, isLoading, ...rest }) {
    const { t } = useTranslation();
    const fmController = PopupFileManager.useController();
    const [currSelectedFile, setCurrSelectedFile] = useState(null);

    const handleChangeSelectedFile = (selectedFile) => {
        if (onChangeFile instanceof Function) {
            onChangeFile(selectedFile);
        }
    };

    useEffect(() => {
        if (selectedFile) {
            setCurrSelectedFile(selectedFile);
        }
    }, [selectedFile]);

    const renderUploader = () => {
        if ((fileType = "avatar")) {
            return (
                <>
                    <div className={`upload-file-wrapper type-avatar${className ? " " + className : ""}`}>
                        <div
                            className={`avatar-wrapper${isLoading ? " " + "loading" : ""}`}
                            onClick={() => {
                                if (readOnly === false) {
                                    fmController.call("open");
                                }
                            }}
                        >
                            {isLoading ? null : currSelectedFile?.src ? (
                                <Avatar className="app-avatar layout-full" src={currSelectedFile.src} />
                            ) : currSelectedFile?.icon ? (
                                <Avatar className="app-avatar layout-full" icon={currSelectedFile.icon} />
                            ) : currSelectedFile?.title ? (
                                <Avatar className="app-avatar layout-full">{currSelectedFile.title}</Avatar>
                            ) : null}
                            <div className="avatar-btn">
                                <IconCamera />
                            </div>
                        </div>
                    </div>

                    <PopupFileManager
                        controller={fmController}
                        isMultiple={false}
                        onSelectFile={(selectedFile) => {
                            if (selectedFile?.id && selectedFile?.src) {
                                if (validateFileBeforeUpload(selectedFile.src).type === "image") {
                                    handleChangeSelectedFile(selectedFile);
                                    fmController.call("close");
                                } else {
                                    const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                                    notification.error({
                                        message: (
                                            <>
                                                <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                                <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                            </>
                                        ),
                                    });
                                }
                            }
                        }}
                    />
                </>
            );
        }
        return null;
    };

    return <>{renderUploader()}</>;
}

export default UploadFile;
