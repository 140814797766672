import MathQuill from "mathquill-node";
import React, { useEffect, useRef } from "react";

const LatexMathField = ({ mathFieldRef, change, position }, ref) => {
    const latexRef = useRef(null);

    const handleFocusInner = (e) => {
        position.current = {
            clientX: e.clientX,
            clientY: e.clientY,
            target: e.target,
        };
    };

    useEffect(() => {
        const MQ = MathQuill.getInterface(2);

        MQ.registerEmbed("response", () => {
            return {
                htmlString: `
                    <span class="response-embed">
    
                    </span>
                `,
                text() {
                    return "custom_embed";
                },
                latex() {
                    return "\\embed{response}";
                },
            };
        });

        mathFieldRef.current = MQ.MathField(latexRef.current, {
            handlers: {
                edit: (mathField) => {
                    change(mathField.latex());
                },
            },
        });

        mathFieldRef.current.el().addEventListener("click", handleFocusInner);

        return () => {
            mathFieldRef.current.el().removeEventListener("click", handleFocusInner);
        };
    }, []);

    return <div ref={latexRef}></div>;
};

export default React.memo(React.forwardRef(LatexMathField));
