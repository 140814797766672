import { useEffect, useRef } from "react";
import { notification } from "antd";
import "./Notification.scss";

const Notification = ({ controller }) => {
    const notifRef = useRef(null);

    useEffect(() => {
        const doBeforeOpenNotif = () => {
            document.body.style.overflowX = "hidden";
        };
        const doAfterCloseNotif = () => {
            document.body.style.overflowX = "";
        };

        controller.set("notification.show", (message, duration = 3000, type) => {
            doBeforeOpenNotif();
            let notifType = type ? type : "open";
            if (["success", "info", "warning", "error"].includes(notifType) === false) {
                notifType = "open";
            }
            notification[notifType]({
                message: message,
                description: undefined,
                duration: duration / 1000,
                getContainer: () => {
                    return notifRef.current;
                },
                onClose: () => {
                    doAfterCloseNotif();
                },
            });
        });

        return () => {
            controller.remove("notification.show");
        };
    }, []);

    return <div ref={notifRef} className="fm-notif"></div>;
};

export default Notification;
