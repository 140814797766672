import {
    number0,
    number1,
    number2,
    number3,
    number4,
    number5,
    number6,
    number7,
    number8,
    number9,
} from "../../icons/tab1";
import { _2475, _2734, _2735, _2737, _2788 } from "../../icons/tab1"; // "+", "-", "*", "/", "+-".
import { decimal, equal } from "../../icons/tab1"; // ".", "=".
import { _gt, lt, _6199, _4065 } from "../../icons/tab1"; // ">", "<", ">=", "<=".
import { _944, _944_2 } from "../../icons/tab1"; // "()", "[]".
import { _993, _leftParenthesis, _leftSquareBracket, _rightParenthesis, _rightSquareBracket } from "../../icons/tab8"; // "(", ")", "[", "]".
import { fraction, _941 } from "../../icons/tab1"; // Fraction.
import { Path3367, Path3436, Path3437, sqrt, _1043, _942, _999 } from "../../icons/tab1"; // Radical symbol, Exponentiation, Pi, Infinity.
import { _2480 } from "../../icons/tab1"; // Euler's Number.
import { _2740 } from "../../icons/tab3"; // Fraction.
import { _1004, _1005, _1008, _1009, _1015 } from "../../icons/tab7"; // Matrices.
import { _log } from "../../icons/chars";
// Sin, cos, tan, cot,...:
import { _Path3437, _fraction, _sqrt, _2479, _2481 } from "../../icons/tab3";
import { _3484, _3485, _2482, _2483, _2251, _2247, _2249, _2245 } from "../../icons/tab3";
import { _2243, _2248, _8924, _8925, _8923, _8922, _8921, _8920 } from "../../icons/tab3";
import { _8939, _8940, _8941, _8942, _8943, _8944, _3171, _3163 } from "../../icons/tab3";
// Geometry:
import { _Path2744, _6101 } from "../../icons/tab3";
import { _3167, _3170, _3180, _3181, _equal, _Path3436 } from "../../icons/tab4";
import { _Path3420 } from "../../icons/tab6";
import { _2751, _3168, _3172 } from "../../icons/tab4";
import { _1053, _6102, _6103, _6104 } from "../../icons/tab8";
// Chemistry:
import { _1023 } from "../../icons/tab8";
import { Group8854, Group8879, Group8880, Group8881, Group8882, Group8883 } from "../../icons/tab5";
// Unit:
import {
    acre,
    bytes,
    gb,
    mb,
    g_ml,
    mol,
    g_mol,
    Group3378,
    Group8393,
    Group8394,
    Group8395,
    Group8396,
    Group8397,
    Group8398,
    Group8557,
    Group8558,
    Group8559,
    Group8860,
    Group8861,
    Group8862,
    Group8863,
    Group8864,
    Group8865,
    Group8866,
    Group8867,
    Group8868,
    Group8869,
    Group8870,
    Group8871,
    Group8872,
    Group8873,
    Group8874,
    Group8876,
    Group8877,
    Group8878,
    Path3512,
    Path3530,
    Path3531,
    Path3532,
    Path3534,
    Path3535,
    Path3536,
    Path3537,
    Path3538,
    Path3539,
    Path3540,
    Path3541,
    Path3548,
    Path3549,
} from "../../icons/tab2";

export const mathcharFilterOptions = [
    {
        key: "standard_keypads",
        options: [
            {
                key: "numbers_only",
                datas: [
                    [
                        { icon: number1, text: "1" },
                        { icon: number2, text: "2" },
                        { icon: number3, text: "3" },
                        { icon: number4, text: "4" },
                        { icon: number5, text: "5" },
                        { icon: number6, text: "6" },
                        { icon: number7, text: "7" },
                        { icon: number8, text: "8" },
                        { icon: number9, text: "9" },
                        { icon: number0, text: "0" },
                    ],
                ],
            },
            {
                key: "numbers_n_basic_symbols",
                datas: [
                    [
                        { icon: number1, text: "1" },
                        { icon: number2, text: "2" },
                        { icon: number3, text: "3" },
                        { icon: decimal, text: "." },
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: number4, text: "4" },
                        { icon: number5, text: "5" },
                        { icon: number6, text: "6" },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        { icon: _1043, text: "\\sqrt[3]{}" },
                        { icon: _999, text: "\\sqrt[]{}", keys: ["Left", "Left"] },
                        { icon: number7, text: "7" },
                        { icon: number8, text: "8" },
                        { icon: number9, text: "9" },
                        { icon: number0, text: "0" },
                    ],
                ],
            },
            {
                key: "basic",
                datas: [
                    [
                        { icon: number1, text: "1" },
                        { icon: number2, text: "2" },
                        { icon: number3, text: "3" },
                        { icon: _2735, text: "+" },
                        { icon: _gt, text: ">" },
                        { icon: number4, text: "4" },
                        { icon: number5, text: "5" },
                        { icon: number6, text: "6" },
                        { icon: _2737, text: "-" },
                        { icon: lt, text: "<" },
                        { icon: number7, text: "7" },
                        { icon: number8, text: "8" },
                        { icon: number9, text: "9" },
                        { icon: _2734, text: "*" },
                        { icon: _944_2, text: "\\left(\\right)", keys: ["Left"] },
                        { icon: number0, text: "0" },
                        { icon: decimal, text: "." },
                        { icon: equal, text: "=" },
                        { icon: _2788, text: "\\div" },
                        { icon: _944, text: "\\left[\\right]", keys: ["Left"] },
                    ],
                ],
            },
            {
                key: "basic_wo_numbers",
                datas: [
                    [
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: _941, text: "\\frac{}{}", keys: ["Left", "Left", "Left"] },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        { icon: _1043, text: "\\sqrt[3]{}" },
                        { icon: _999, text: "\\sqrt[]{}", keys: ["Left", "Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: Path3437, text: "\\pi" },
                    ],
                ],
            },
            {
                key: "intermediate",
                datas: [
                    [
                        { icon: number1, text: "1" },
                        { icon: number2, text: "2" },
                        { icon: number3, text: "3" },
                        { icon: _2734, text: "*" },
                        { icon: _6199, text: "\\ge" },
                        { icon: _leftParenthesis, text: "(" },
                        { icon: _rightParenthesis, text: ")" },
                        { icon: number4, text: "4" },
                        { icon: number5, text: "5" },
                        { icon: number6, text: "6" },
                        { icon: _2788, text: "\\div" },
                        { icon: _4065, text: "\\le" },
                        { icon: _leftSquareBracket, text: "[" },
                        { icon: _rightSquareBracket, text: "]" },
                        { icon: number7, text: "7" },
                        { icon: number8, text: "8" },
                        { icon: number9, text: "9" },
                        { icon: _2475, text: "\\pm" },
                        { icon: _gt, text: ">" },
                        { icon: _log, text: "\\log" },
                        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
                        { icon: number0, text: "0" },
                        { icon: decimal, text: "." },
                        { icon: equal, text: "=" },
                        { icon: Path3367, text: "\\infty" },
                        { icon: lt, text: "<" },
                        { icon: Path3437, text: "\\pi" },
                        { icon: Path3436, text: "\\theta" },
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: _941, text: "\\frac{}{}", keys: ["Left", "Left", "Left"] },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        { icon: _999, text: "\\sqrt[]{}", keys: ["Left", "Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: _2740, text: "\\degree", keys: ["Left"] },
                        { icon: _2480, text: "e^{}", keys: ["Left"] },
                    ],
                ],
            },
            {
                key: "intermediate_wo_numbers",
                datas: [
                    [
                        { icon: _2734, text: "*" },
                        { icon: _2475, text: "\\pm" },
                        { icon: _6199, text: "\\ge" },
                        { icon: _4065, text: "\\le" },
                        { icon: _gt, text: ">" },
                        { icon: lt, text: "<" },
                        { icon: _2788, text: "\\div" },
                        { icon: equal, text: "=" },
                        { icon: _leftParenthesis, text: "(" },
                        { icon: _rightParenthesis, text: ")" },
                        { icon: _leftSquareBracket, text: "[" },
                        { icon: _rightSquareBracket, text: "]" },
                        { icon: Path3437, text: "\\pi" },
                        { icon: Path3367, text: "\\infty" },
                        { icon: _log, text: "\\log" },
                        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: _2740, text: "\\degree", keys: ["Left"] },
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: _941, text: "\\frac{}{}", keys: ["Left", "Left", "Left"] },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        { icon: _999, text: "\\sqrt[]{}", keys: ["Left", "Left"] },
                        { icon: _944, text: "\\left[\\right]", keys: ["Left"] },
                        { icon: _944_2, text: "\\left(\\right)", keys: ["Left"] },
                    ],
                ],
            },
            {
                key: "matrices",
                datas: [
                    [
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        {
                            icon: _1015,
                            text: "\\begin{vmatrix}{}&{}\\\\{}&{}\\end{vmatrix}",
                            keys: ["Left", "Left", "Up"],
                        },
                        {
                            icon: _1004,
                            text: "\\begin{bmatrix}{}&{}\\\\{}&{}\\end{bmatrix}",
                            keys: ["Left", "Left", "Left", "Left"],
                        },
                        {
                            icon: _1005,
                            text: "\\begin{bmatrix}{}&{}&{}\\\\{}&{}&{}\\\\{}&{}&{}\\end{bmatrix}",
                            keys: ["Left", "Left", "Left", "Up", "Up"],
                        },
                    ],
                ],
            },
            {
                key: "matrices_basic",
                datas: [
                    [
                        { icon: number1, text: "1" },
                        { icon: number2, text: "2" },
                        { icon: number3, text: "3" },
                        { icon: _2735, text: "+" },
                        { icon: _gt, text: ">" },
                        { icon: fraction, text: "\\frac{}{}", keys: ["Left", "Left"] },
                        { icon: number4, text: "4" },
                        { icon: number5, text: "5" },
                        { icon: number6, text: "6" },
                        { icon: _2737, text: "-" },
                        { icon: lt, text: "<" },
                        { icon: _941, text: "\\frac{}{}", keys: ["Left", "Left", "Left"] },
                        { icon: number7, text: "7" },
                        { icon: number8, text: "8" },
                        { icon: number9, text: "9" },
                        { icon: _2734, text: "*" },
                        { icon: _944_2, text: "\\left(\\right)", keys: ["Left"] },
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: number0, text: "0" },
                        { icon: decimal, text: "." },
                        { icon: equal, text: "=" },
                        { icon: _2788, text: "\\div" },
                        { icon: _944, text: "\\left[\\right]", keys: ["Left"] },
                        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
                        { icon: sqrt, text: "\\sqrt{ }", keys: ["Left"] },
                        {
                            icon: _1015,
                            text: "\\begin{vmatrix}{}&{}\\\\{}&{}\\end{vmatrix}",
                            keys: ["Left", "Left", "Up"],
                        },
                        {
                            icon: _1004,
                            text: "\\begin{bmatrix}{}&{}\\\\{}&{}\\end{bmatrix}",
                            keys: ["Left", "Left", "Left", "Left"],
                        },
                        {
                            icon: _1005,
                            text: "\\begin{bmatrix}{}&{}&{}\\\\{}&{}&{}\\\\{}&{}&{}\\end{bmatrix}",
                            keys: ["Left", "Left", "Left", "Up", "Up"],
                        },
                    ],
                ],
            },
            {
                key: "trignometry",
                datas: [
                    [
                        { icon: _2740, text: "\\degree", keys: ["Left"] },
                        { icon: _Path3437, text: "\\pi" },
                        { icon: _942, text: "^{ }", keys: ["Left", "Left"] },
                        { icon: _fraction, text: "\\frac{ }{ }", keys: ["Left", "Up"] },
                        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
                        { icon: _sqrt, text: "\\sqrt{}", keys: ["Left"] },
                        { icon: _2479, text: "\\sin\\left(\\right)", keys: ["Left"] },
                        { icon: _2481, text: "\\cos\\left(\\right)", keys: ["Left"] },
                        { icon: _3484, text: "\\sec\\left(\\right)", keys: ["Left"] },
                        { icon: _3485, text: "\\csc\\left(\\right)", keys: ["Left"] },
                        { icon: _2482, text: "\\tan\\left(\\right)", keys: ["Left"] },
                        { icon: _2483, text: "\\cot\\left(\\right)", keys: ["Left"] },
                        { icon: _2251, text: "\\sin^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _2247, text: "\\csc^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _2249, text: "\\sec^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _2245, text: "\\csc^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _2243, text: "\\tan^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _2248, text: "\\cot^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8924, text: "\\sinh\\left(\\right)", keys: ["Left"] },
                        { icon: _8925, text: "\\cosh\\left(\\right)", keys: ["Left"] },
                        { icon: _8923, text: "\\sech^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8922, text: "\\csch^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8921, text: "\\tanh^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8920, text: "\\coth^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8939, text: "\\sinh^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8940, text: "\\cosh^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8941, text: "\\sech^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8942, text: "\\csch^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8943, text: "\\tanh^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _8944, text: "\\coth^{-1}\\left(\\right)", keys: ["Left"] },
                        { icon: _3171, text: "\\perp" },
                        { icon: _3163, text: "\\parallel" },
                        { icon: _Path2744, text: "\\angle" },
                        { icon: _6101, text: "\\text{m}\\angle" },
                    ],
                ],
            },
            {
                key: "geometry",
                datas: [
                    [
                        { icon: _3171, text: "\\perp" },
                        { icon: _3163, text: "\\parallel" },
                        { icon: _Path2744, text: "\\angle" },
                        { icon: _6101, text: "\\text{m}\\angle" },
                        { icon: _2740, text: "\\degree", keys: ["Left"] },
                        { icon: _Path3420, text: "\\Delta" },
                        { icon: _3170, text: "\\odot" },
                        { icon: _Path3436, text: "\\theta" },
                        { icon: _3181, text: "\\nparallel" },
                        { icon: _3167, text: "\\sim" },
                        { icon: _3172, text: "\\uxparallelogram" },
                        { icon: _2751, text: "\\equiv" },
                        { icon: _3168, text: "\\cong" },
                        { icon: _Path3437, text: "\\pi" },
                        { icon: _3180, text: "\\square" },
                        { icon: _1053, text: "\\overset{\\frown}{}", keys: ["Left"] },
                        { icon: _6104, text: "\\overline{ }", keys: ["Left"] },
                        { icon: _6102, text: "\\overrightarrow{ }", keys: ["Left"] },
                        { icon: _6103, text: "\\overleftrightarrow{ }", keys: ["Left"] },
                    ],
                ],
            },
            {
                key: "chemistry",
                datas: [
                    [
                        { icon: _942, text: "^{}", keys: ["Left"] },
                        { icon: _942, text: "_{}", keys: ["Left"] },
                        { icon: _2740, text: "\\degree", keys: ["Left"] },
                        { icon: _944, text: "\\left(\\right)", keys: ["Left"] },
                        { icon: _944_2, text: "\\left[\\right]", keys: ["Left"] },
                        { icon: _1023, text: "\\left\\{\\right\\}", keys: ["Left"] },
                        { icon: Group8879, text: "\\leftarrow" },
                        { icon: Group8883, text: "\\longleftrightarrow" },
                        { icon: Group8880, text: "\\rightarrow" },
                        { icon: Group8881, text: "\\uparrow" },
                        { icon: Group8882, text: "\\downarrow" },
                        { icon: Group8854, text: "\\rightleftharpoons" },
                        { icon: _Path3420, text: "\\Delta" },
                        { icon: mol, text: "\\text{mol}" },
                        { icon: g_ml, text: "\\text{g/mL}" },
                        { icon: g_mol, text: "\\text{g/mol}" },
                        { icon: _2737, text: "-" },
                        { icon: _equal, text: "=" },
                        { icon: _2751, text: "\\equiv" },
                    ],
                ],
            },
            {
                key: "units_SI",
                datas: [
                    [
                        //
                        { icon: Group8870, text: "\\text{µm}" },
                        { icon: Group8871, text: "\\text{mm}" },
                        { icon: Group8872, text: "\\text{cm}" },
                        { icon: Group8397, text: "\\text{m}" },
                        { icon: Group8398, text: "\\text{km}" },
                        //
                        { icon: Group8395, text: "\\text{mm}^2" },
                        { icon: Group8396, text: "\\text{cm}^2" },
                        { icon: Group8394, text: "\\text{m}^2" },
                        { icon: Group8393, text: "\\text{km}^2" },
                        //
                        { icon: Group8867, text: "\\text{mm}^3" },
                        { icon: Group8868, text: "\\text{cm}^3" },
                        { icon: Group8869, text: "\\text{m}^3" },
                        { icon: Group8878, text: "\\text{km}^3" },
                        //
                        { icon: Path3532, text: "\\text{mL}" },
                        { icon: Path3531, text: "\\text{L}" },
                        { icon: Group8860, text: "\\text{µg}" },
                        { icon: Group8861, text: "\\text{mg}" },
                        { icon: Group3378, text: "\\text{g}" },
                        { icon: Group8862, text: "\\text{kg}" },
                        { icon: Group8559, text: "\\text{g/}cm^3" },
                        { icon: Group8558, text: "\\text{kg/}m^3" },
                        //
                        { icon: Group8873, text: "\\text{cm/s}" },
                        { icon: Group8865, text: "\\text{m/s}" },
                        { icon: Group8874, text: "\\text{km/s}" },
                        { icon: Group8866, text: "\\text{m/}s^2" },
                        //
                        { icon: Group8863, text: "\\text{µs}" },
                        { icon: Group8864, text: "\\text{ms}" },
                        { icon: Path3530, text: "\\text{s}" },
                    ],
                ],
            },
            {
                key: "units_US",
                datas: [
                    [
                        { icon: Group8557, text: "\\text{in}^2" },
                        { icon: Group8876, text: "\\text{mi}^2" },
                        { icon: Group8877, text: "\\text{ft}^2" },
                        { iconText: "feet", text: "\\text{feet}" },
                        { icon: Path3534, text: "\\text{oz}" },
                        { icon: Path3535, text: "\\text{lb}" },
                        { icon: Path3536, text: "\\text{in}" },
                        { icon: Path3537, text: "\\text{ft}" },
                        { icon: Path3538, text: "\\text{mi}" },
                        { icon: Path3539, text: "\\text{fl oz}" },
                        { icon: Path3540, text: "\\text{pt}" },
                        { icon: Path3541, text: "\\text{gal}" },
                        { icon: Path3512, text: "\\text{second}" },
                        { icon: Path3548, text: "\\text{min}" },
                        { icon: Path3549, text: "\\text{hour}" },
                        // sq mi
                        // yard
                        { icon: acre, text: "\\text{acre}" },
                        { iconText: "cup", text: "\\cup" },
                        // qt
                        // tbsp
                        // ton
                        // tsp
                        // mph
                        // fps
                        // ft/sec2
                        // in3
                        // mi3
                        // ft3
                    ],
                ],
            },
            {
                key: "full",
            },
        ],
    },
];

export const getMathCharOptionData = (optionKey) => {
    let list = null;
    if (optionKey) {
        for (let i = 0; i < mathcharFilterOptions.length; i++) {
            const _groupOpt = mathcharFilterOptions[i].options;
            for (let j = 0; j < _groupOpt.length; j++) {
                if (_groupOpt[j].key === optionKey) {
                    list = _groupOpt[j].datas;
                    return { list };
                }
            }
        }
        return { list };
    }
    return { list };
};
