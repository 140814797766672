import { convertStringWithDefaultCharacters } from "../string";

/**
 * Compares strings after converting them to the ones with default characters.
 * Use case: Compare 'Hello, it's me' with 'Hello, itʼs me'.
 * @param {Array} strings Array of strings.
 * @returns true/false.
 */
export const compareStringsInDefaultFormat = (...strings) => {
    if (strings.length < 2) {
        return true;
    }
    const stringsConverted = strings.map((str) => convertStringWithDefaultCharacters(str));
    const stringFirst = stringsConverted[0];
    for (let i = 1; i < stringsConverted.length; i++) {
        const stringN = stringsConverted[i];
        if (stringFirst !== stringN) {
            return false;
        }
    }
    return true;
};

/**
 * Compare two objects to check if they have the same key-value pairs.
 * Use case: Compare { a: 1, b: 2 } with { a: 3, b: 4 }.
 * @param {Array} objs Array of objects.
 * @returns true/false.
 */
export const compareObjects = (...objs) => {
    let objKeysPattern;
    for (let i = 0; i < objs.length; i++) {
        if (i === 0) {
            objKeysPattern = Object.keys(objs[i]);
        } else {
            const objKeys = Object.keys(objs[i]);
            if (objKeys.length !== objKeysPattern.length) {
                return false;
            }
            for (let key of objKeysPattern) {
                if (objs[0][key] !== objs[i][key]) {
                    return false;
                }
            }
        }
    }
    return true;
};

/**
 * Compare two arrays to check if they have the same values.
 * Use case: Compare [1, 2] with [3, 4].
 * @param {Array} arrays Array of arrays.
 * @returns true/false.
 */
export const compareArrays = (...arrays) => {
    if (arrays.length < 2) {
        return true;
    }
    const arrFirst = arrays[0];
    for (let i = 1; i < arrays.length; i++) {
        const array = arrays[i];
        // Check the lengths:
        if (array.length !== arrFirst.length) {
            return false;
        }
        // Loop with an early exit:
        for (let j = 0; j < arrFirst.length; j++) {
            if (array[j] !== arrFirst[j]) {
                return false;
            }
        }
    }
    return true;
};

/**
 * Compare two objects (deeply) to check if they have the same key-value pairs.
 * Use case:
 * - Compare { a: 1, b: 2 } with { a: 3, b: 4 }.
 * - Compare [1, 2] with [3, 4].
 * - Compare { a: 1, b: { c: 2 } } with { a: 3, b: { c: 4 } }.
 * - Compare [[2, 1], [2, 5]] with [[2, 1], [2, 5]].
 * @param {Array} objs Array of objects.
 * @returns true/false.
 */
export const compareObjectsDeeply = (...objs) => {
    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) {
            return true;
        }
        if (!obj1 || !obj2 || typeof obj1 !== "object" || typeof obj2 !== "object") {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    };

    for (let i = 1; i < objs.length; i++) {
        if (!deepEqual(objs[0], objs[i])) {
            return false;
        }
    }

    return true;
};
