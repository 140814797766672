import { getUserScopeSelected, scopeKeys } from "src/api/helpers/userScope";

const rolesAllow = {
    list: "__list",
    find: "__find",
    create: "__create",
    update: "__update",
    delete: "__delete",
    import: "__import",
    export: "__export",
    inviteTeacher: "inviteTeacher",
    inviteUser: "inviteUser",
    addUsers: "addUsers",

    removeUserOrganization: "removeUserOrganization",
    removeUser: "removeUser",
    addRolesToUser: "addRolesToUser",

    setStorageUser: "setStorageUser",
    getPermissions: "getPermissions",
};

const pagesAllow = {
    class: "class",
    organization: "organization",
    team: "team",
    user: "user",
    role: "role",
    manage: "manage",
    branch: "branch",
    student: "student",
    teacher: "teacher",
    employee: "employee",
    competition: "competition",
};

const scopePermissions = {
    [scopeKeys.personal]: {
        adminPagesAllowed: [],
    },
    [scopeKeys.org]: {
        adminPagesAllowed: [
            pagesAllow.student,
            pagesAllow.teacher,
            pagesAllow.employee,
            pagesAllow.class,
            pagesAllow.branch,
            pagesAllow.role,
            pagesAllow.setStorageUser,
            pagesAllow.competition,
        ],
    },
    [scopeKeys.teams]: {
        adminPagesAllowed: [
            pagesAllow.student,
            pagesAllow.teacher,
            // pagesAllow.employee,
            pagesAllow.class,
            pagesAllow.branch,
            // pagesAllow.role,
            pagesAllow.setStorageUser,
            pagesAllow.competition,
        ],
    },
};

/**
 * Check if an admin page is allowed for the scope.
 * @param {string} adminPageKey Key in pagesAllow.
 * @param {string} scopeKey Key in scopeKeys.
 * @returns boolean.
 */
const checkIfAdminPageIsAllowed = (adminPageKey, scopeKey) => {
    return scopePermissions[scopeKey]?.adminPagesAllowed?.includes(adminPageKey) || false;
};

const checkArray = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
        return true;
    }
    return false;
};

const isObject = (obj) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
        return true;
    }
    return false;
};

const havePermisions = (page, organization, type) => {
    if (type == "organization") {
        const permissions = organization?.permissions;

        if (permissions && isObject(permissions) && permissions.hasOwnProperty(page) && checkArray(permissions[page])) {
            return permissions[page];
        }
    }
    if (type == "teams") {
        const permissions = organization?.permissions;
        if (permissions && isObject(permissions) && permissions.hasOwnProperty(page) && checkArray(permissions[page])) {
            return permissions[page];
        }
    }
};

// organization: {} || teams: []
// true: nếu có quyền, false: ko có quyền or ko thuộc tổ chức or teams
const checkPermission = (page, actions, user) => {
    if (!user) return false;

    //TH user login với tk free nhưng trong api me có organization và teams
    if ((user?.organization || checkArray(user?.teams)) && !user?.currentOrg?.permissions) {
        return false;
    }
    // if (!["organization", "teams"].includes(user?.currentOrg?.scopeOptionKey)) {
    //     return false;
    // }

    // Check permission by scope:
    if (!checkIfAdminPageIsAllowed(page, user?.currentOrg?.scopeOptionKey)) {
        return false;
    }

    // Check permission by user and user's scope info:
    if (user?.currentOrg?.scopeOptionKey === "organization") {
        if (user?.organization?.is_organization_owner) return true;

        const { is_in_organization, organization, role } = user;

        const p = havePermisions(page, organization, "organization");

        //nếu ko là gv tổ chức || ko thuộc tc || ko có permissions
        if ((actions && role !== "teacher") || !is_in_organization || !p) {
            return false;
        }

        //check xem user có role [actions] hay không
        if (actions && checkArray(actions)) {
            return actions.every((e) => p.includes(e));
        }

        return p.includes(actions);
    } else if (user?.currentOrg?.scopeOptionKey === "teams") {
        if (user?.currentOrg?.is_team_owner) return true;

        const { currentOrg, role } = user;

        const p = havePermisions(page, currentOrg, "teams");
        if ((actions && role !== "teacher") || !p) {
            return false;
        }

        if (actions && checkArray(actions)) {
            return actions.every((e) => p.includes(e));
        }

        return p.includes(actions);
    }
    return false;
};

const canViewInfoOrganization = (user) => {
    if (user) {
        if (user?.currentOrg?.is_organization_owner || user?.currentOrg?.is_team_owner) {
            return true;
        }
        if (
            user?.currentOrg?.permissions &&
            !(Array.isArray(user.currentOrg.permissions) && user.currentOrg.permissions.length == 0)
        ) {
            return true;
        }
    }
    return false;
};

const getTypeOrg = (user) => {
    return user?.currentOrg?.scopeOptionKey === "teams"
        ? "team"
        : user?.currentOrg?.scopeOptionKey === "organization"
        ? "organization"
        : "personal";
};

const routesAllow = (user) => {
    const routes = {
        manage_student: checkPermission(pagesAllow.student, rolesAllow.list, user),
        manage_teacher: checkPermission(pagesAllow.teacher, rolesAllow.list, user),
        manage_employee: checkPermission(pagesAllow.employee, rolesAllow.list, user),
        manage_class: checkPermission(pagesAllow.class, rolesAllow.list, user),
        manage_branch: checkPermission(pagesAllow.branch, rolesAllow.list, user),
        manage_role: checkPermission(pagesAllow.role, rolesAllow.list, user),
        manage_capacity: checkPermission(pagesAllow[getTypeOrg(user)], rolesAllow.setStorageUser, user),
        organization_detail: canViewInfoOrganization(user),
        ranking: checkPermission(pagesAllow.competition, rolesAllow.list, user),
        competitions: checkPermission(pagesAllow.competition, rolesAllow.list, user),
        manage_ranking: checkPermission(pagesAllow.competition, rolesAllow.list, user),
        manage_competitions: checkPermission(pagesAllow.competition, rolesAllow.list, user),
        // organization_detail: user?.organization || (Array.isArray(user?.teams) && user.teams.length > 0),
    };

    const result = [];

    for (const p in routes) {
        if (routes[p]) {
            result.push(p);
        }
    }

    return result;
};

const routesNotAllow = (user) => {
    const routes = {
        manage_student: checkPermission(pagesAllow.student, rolesAllow.list, user),
        manage_teacher: checkPermission(pagesAllow.teacher, rolesAllow.list, user),
        manage_employee: checkPermission(pagesAllow.employee, rolesAllow.list, user),
        manage_class: checkPermission(pagesAllow.class, rolesAllow.list, user),
        manage_branch: checkPermission(pagesAllow.branch, rolesAllow.list, user),
        manage_role: checkPermission(pagesAllow.role, rolesAllow.list, user),
        manage_capacity: checkPermission(pagesAllow[getTypeOrg(user)], rolesAllow.setStorageUser, user),
        organization_detail: canViewInfoOrganization(user),
        ranking:
            checkPermission(pagesAllow.competition, rolesAllow.list, user) ||
            getUserScopeSelected(user)?.scopeName == false,
        competitions:
            checkPermission(pagesAllow.competition, rolesAllow.list, user) ||
            getUserScopeSelected(user)?.scopeName == false ||
            user?.role === "student",
        manage_ranking:
            checkPermission(pagesAllow.competition, rolesAllow.list, user) ||
            getUserScopeSelected(user)?.scopeName == false,
        manage_competitions:
            checkPermission(pagesAllow.competition, rolesAllow.list, user) ||
            getUserScopeSelected(user)?.scopeName == false,
        // organization_detail: user?.organization || (Array.isArray(user?.teams) && user.teams.length > 0),
    };

    const result = [];

    for (const p in routes) {
        if (!routes[p]) {
            result.push(p);
        }
    }
    routesAllow(user);

    return result;
};

export const permissionUser = {
    checkPermission,
    routesNotAllow,
    pagesAllow,
    rolesAllow,
    routesAllow,
};
