import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { useFetch, useValues } from "src/hooks";
import { setCurrentPage } from "src/reducers/general";
import Header from "src/modules/components/Header";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import EntryTestLevel from "../../components/EntryTestLevel";
import { listEntryTestLevel } from "src/api/containers/entry_test";
import "./DetailEntryTest.scss";

const DetailEntryTest = () => {
    const dispatch = useDispatch();

    const { id: test_input_id } = useParams();
    const navigate = useNavigate();
    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    const { generate } = useRouting();
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [entryTestLoading, entryTestData, entryTestPagination, entryTestFetch, entryTestRefetch] = useFetch(
        { page: 1, test_input_id: test_input_id, ...fParams },
        listEntryTestLevel
    );

    // Others:
    const [values, setValues] = useValues({
        listPages: 0,
        competition: {},
    });

    useEffect(() => {
        if (test_input_id) {
            dispatch(setCurrentPage("entry_test_level"));
            entryTestFetch({}, true);
        }
    }, [test_input_id]);

    useEffect(() => {
        if (entryTestData.length) {
            if (entryTestPagination.current === 1) {
                setValues({
                    listPages: entryTestPagination.current,
                });
            } else if (entryTestPagination.current > values.listPages) {
                setValues({
                    listPages: entryTestPagination.current,
                });
            }
        } else {
            setValues({
                listPages: 0,
            });
        }
    }, [entryTestData, entryTestPagination]);

    return (
        <div className="class detail-competition">
            <Header backEnable={true} backFunc={() => navigate(generate("entry_test"))} />

            <Spin spinning={entryTestLoading}>
                <EntryTestLevel
                    entryTestLevel={values.competition}
                    pagination={entryTestPagination}
                    handleRefetchAsignment={() => {
                        entryTestRefetch({ page: 1 }, true);
                    }}
                    listAssignments={entryTestData}
                />
            </Spin>
        </div>
    );
};

export default DetailEntryTest;
