import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, notification, Space, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import FilterMenu from "../FilterMenu";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import InputSearch from "src/modules/components/Input/InputSearch";
import ModalClassList from "../ModalClassList";
import ModalDetailTeacher from "../ModalDetailTeacher";
import ModalAddUserToOrganization from "src/modules/components/ModalAddUserToOrganization";
import { fetchOrganizationUsers, removeUserFromOrganization } from "src/api/containers/organization";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
import "./TeacherTable.scss";
import FilterIcon from "src/assets/images/filter-icon.svg";
import { ReactComponent as ManageClass } from "src/assets/images/nav/QLLop.svg";
import UploadBtn from "src/modules/containers/ManageStudent/components/UploadBtn";
import "./TeacherTable.scss";

function TeacherTable() {
    const user = useSelector((state) => state.auth.user);
    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        {
            page: 1,
            slug: "",
            role: "teacher",
            organization_id: user.paramsForOrgOrTeam?.organization_id,
        },
        fetchOrganizationUsers
    );

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        organization_id: user.paramsForOrgOrTeam?.organization_id,
        isModalConfirmDeleteVisible: false,
        isModalInviteUserVisible: false,
        isModalDetailUserVisible: false,
        isModalClassListVisible: false,
        selectedUser: null,
        loadingFile: false,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchUser: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });

    const checkRoles = useMemo(() => {
        return (
            checkPermission(pagesAllow.teacher, rolesAllow.update, user) ||
            checkPermission(pagesAllow.organization, rolesAllow.removeUserOrganization, user) ||
            checkPermission(pagesAllow.class, rolesAllow.list, user)
        );
    }, [user]);

    const columns = [
        {
            title: t("organization.full_name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => {
                return (
                    record?.name ||
                    (record?.first_name && record.last_name ? `${record.first_name} ${record.last_name}` : "_")
                );
            },
            align: "center",
        },
        {
            title: t("organization.gender"),
            dataIndex: "gender",
            key: "gender",
            width: "auto",
            render: (text, record) => {
                switch (record?.gender) {
                    case 0:
                        return t(`profile.gender_female`);
                    case 1:
                        return t(`profile.gender_male`);
                    default:
                        return "_";
                }
            },
            align: "center",
        },
        {
            title: t("organization.email"),
            dataIndex: "username",
            key: "username",
            width: "auto",
            render: (text, record) => {
                return record?.username || "_";
            },
            align: "center",
        },

        {
            title: t("organization.total_class"),
            dataIndex: "count_class",
            key: "count_class",
            width: "auto",
            align: "center",
            render: (text, record) => (record?.count_class || record?.count_class == 0 ? record.count_class : "_"),
        },
        {
            title: t("organization.total_student"),
            dataIndex: "count_student",
            key: "count_student",
            width: "auto",
            align: "center",
            render: (text, record) =>
                record?.count_student || record?.count_student == 0 ? record.count_student : "_",
        },
        // {
        //     title: t("organization.status"),
        //     dataIndex: "active",
        //     key: "active",
        //     width: "auto",
        //     render: (text, record) => {
        //         switch (record?.active) {
        //             case false:
        //                 return t(`profile.active_false`);
        //             case true:
        //                 return t(`profile.active_true`);
        //             default:
        //                 return "_";
        //         }
        //     },
        // },
    ];

    if (checkRoles) {
        columns.push({
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            align: "center",
            render: (text, record) => (
                <div className="organization-user-list-actions">
                    <Can I={rolesAllow.list} page={pagesAllow.class}>
                        <Tooltip placement="top" title={t("organization.class_list")}>
                            <span onClick={() => handleShowModalClassList(getTeacher(record))} className="action-btn">
                                <ManageClass />
                            </span>
                        </Tooltip>
                    </Can>

                    <Can I={rolesAllow.update} page={pagesAllow.teacher}>
                        <Tooltip placement="top" title={t("shared.edit")}>
                            <span onClick={() => handleShowModalDetailTeacher(record)} className="action-btn">
                                <EditOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                    <Can I={rolesAllow.removeUserOrganization} page={pagesAllow.organization}>
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <span
                                onClick={() => handleShowModalConfirmDelete(record)}
                                className="action-btn organization-user-delbtn"
                            >
                                <DeleteOutlined />
                            </span>
                        </Tooltip>
                    </Can>
                </div>
            ),
        });
    }

    const getTeacher = (record) => {
        if (record?.username && dataSource) {
            return dataSource.find((item) => item.username === record.username);
        }
        return {};
    };

    const handleShowModalConfirmDelete = (selectedUser) => {
        setValues({ isModalConfirmDeleteVisible: true, selectedUser: selectedUser });
    };

    const handleShowModalDetailTeacher = (selectedUser) => {
        setValues({ isModalDetailUserVisible: true, selectedUser: selectedUser });
    };

    const handleShowModalClassList = (selectedUser) => {
        setValues({ isModalClassListVisible: true, teacher_id: selectedUser?.id });
    };

    const handleDeleteSelectedUser = () => {
        removeUserFromOrganization(values.selectedUser?.id, values.organization_id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isModalConfirmDeleteVisible: false, selectedUser: null });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleSearchUser = () => {
        if (filterSettings) {
            fetch({
                page: 1,
                slug: filterSettings.inputSearchUser,
            });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        fetch({}, true);
    }, []);

    return (
        <div className="question-bank organization-user-list org-teacher-list">
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("organization.find_user")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchUser: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchUser();
                                    }
                                }}
                                onClickIconSearch={handleSearchUser}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetchUserList={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {/* <div className="filter-toolbar-actions"></div> */}
                <div className="actions-button">
                    <div>
                        <UploadBtn
                            loading={values.loadingFile}
                            setLoading={setValues}
                            refetch={refetch}
                            fileName="teacher"
                            role="teacher"
                            organization={user.paramsForOrgOrTeam}
                        />
                    </div>

                    <div>
                        <Space>
                            <Can I={rolesAllow.inviteUser} page={pagesAllow.organization}>
                                <CustomButton
                                    type="primary"
                                    icon={<ShareAltOutlined />}
                                    title={t("organization.invite_teacher")}
                                    onClick={() => setValues({ isModalInviteUserVisible: true })}
                                ></CustomButton>
                            </Can>
                            <Can I={rolesAllow.create} page={pagesAllow.teacher}>
                                <CustomButton
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    title={t("class.add_teacher")}
                                    onClick={() => handleShowModalDetailTeacher(undefined)}
                                ></CustomButton>
                            </Can>
                        </Space>
                    </div>
                </div>
            </div>

            <div className="organization-user-list__table">
                <Table
                    className="app-table"
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            <ModalAddUserToOrganization
                visible={values.isModalInviteUserVisible}
                organization={{
                    ...user.paramsForOrgOrTeam,
                }}
                onOk={() => setValues({ isModalInviteUserVisible: false })}
                onCancel={() => setValues({ isModalInviteUserVisible: false })}
            />

            <ModalClassList
                visible={values.isModalClassListVisible}
                selectedUser={values.selectedUser}
                teacher_id={values.teacher_id}
                onCancel={() => setValues({ isModalClassListVisible: false })}
            />

            <ModalDetailTeacher
                visible={values.isModalDetailUserVisible}
                organization_id={values.organization_id}
                selectedUser={values.selectedUser}
                onOk={() => {
                    refetch({}, true);
                    setValues({ isModalDetailUserVisible: false });
                }}
                onCancel={() => setValues({ isModalDetailUserVisible: false })}
            />

            <PopConfirm
                title={t("organization.remove_user")}
                type="delete"
                visible={values.isModalConfirmDeleteVisible}
                onOk={handleDeleteSelectedUser}
                onCancel={() => setValues({ isModalConfirmDeleteVisible: false })}
            />
        </div>
    );
}

export default TeacherTable;
