import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, notification, Select, Spin, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import { fetchExams } from "src/api/containers/exam";
import { createAssignment, fetchAssignments, updateAssignment } from "src/api/containers/assignment";
import { useDispatch, useSelector } from "react-redux";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { default as CustomButton } from "src/modules/components/Button";
import moment from "moment";
import iconClose from "src/assets/images/action-close.svg";
import { animationId } from "src/reducers/animation";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import RankingRoundImg from "src/assets/images/ranking/ranking_round.png";
import ColorPoolImg from "src/assets/images/ranking/color_pool.png";

//giao bài tập trong 1 vòng thi, 1 vòng thi tương đương với 1 lần giao bài tập
const ModalDetailCompetition = (props) => {
    const {
        competition = {},
        competitionId = "",
        classId,
        editingAssignment = {},
        previousItem = {},
        nextItem = {},
        isLoading = false,
        setValuesExam,
        classScopeId /** @param {string} classScopeId - Organization id, team id or undefined. */,
        onOk = () => {},
        onCancel = () => {},
        ...rest
    } = props;

    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const [values, setValues] = useValues({
        loading: false,
        loadingExam1: false,
        optionListClass: [],
        optionListSubject: [],
        optionListExams: [],
        optionListAssignment: [],
        optionListExamsDefault: [],
        exam_question_id: "",
        ranking_type: editingAssignment?.ranking_type || "",
        isRoundEnded: false,
        isDisableBeginDate: false,
    });

    const timeoutIdGetExams = useRef(0);

    const dispatch = useDispatch();

    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [loadingAsignment, optionListAssignment, pagination, fetch, refetch] = useFetch(
        { slug: "", teacher_id: user.id, ...fParams, noPagination: 1 },
        fetchAssignments
    );

    const rankingOptions = [
        {
            value: "point",
            label: t("ranking.by_point"),
        },
        {
            value: "top",
            label: t("ranking.by_top"),
        },
    ];

    const handleSearchExams = (keyword) => {
        if (!values.loadingExam1) {
            setValues({
                loadingExam1: true,
            });
        }
        clearTimeout(timeoutIdGetExams.current);
        if (typeof keyword === "string") {
            setValues({
                loadingExam1: true,
            });

            timeoutIdGetExams.current = setTimeout(() => {
                fetchExams({
                    category_id: form.getFieldValue("category_id"),
                    user_id: user.id,
                    noPagination: true,
                    slug: keyword,
                    page: 1,
                    is_auto_scoring: true,
                    perPage: 2,
                }).then((res) => {
                    setValues({
                        loadingExam1: false,
                        optionListExams: res.data || [],
                    });
                });
            }, 800);
        }
    };

    const handleSearchAsignments = (keyword) => {
        clearTimeout(timeoutIdGetExams.current);
        if (typeof keyword === "string") {
            timeoutIdGetExams.current = setTimeout(() => {
                fetch({
                    category_id: form.getFieldValue("category_id"),
                    user_id: user.id,
                    noPagination: true,
                    class_id: form.getFieldValue("class_id"),
                    is_ranking: 1,
                    slug: keyword,
                    ignore_exam_id: form.getFieldValue("exam_question_id"),
                });
            }, 800);
        }
    };

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();
        if (classId && !formData.class_id) {
            formData.class_id = classId;
        }

        if (fParams.organization_id) {
            formData.organization_id = fParams.organization_id;
        }

        if (formData.begin_date && formData.end_date && formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }

        formData.begin_date = formData.begin_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;
        formData.end_date = formData.end_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;
        formData.is_test = true;
        formData.category_id = competition?.category_id;
        formData.class_id = competition?.class_id;
        formData.competition_id = competition?.id;

        setValues({ loading: true });
        if (!Object.keys(editingAssignment || {}).length) {
            createAssignment(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setValues({ loading: false, ranking_type: "" });
            });
        } else {
            updateAssignment(editingAssignment.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", update: editingAssignment.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false, ranking_type: "" });
            });
        }
    };

    const handleChangeExam = (value, option) => {
        const fValues = form.getFieldsValue();
        const data = {};
        if (classId && !fValues?.class_id) {
            form.setFieldsValue({ class_id: classId });
            data.class_id = classId;
        }

        // form.setFieldsValue(data);
        setValues({
            exam_question_id: value,
        });

        form.setFieldsValue({
            name: option?.children,
            class_id: classId,
            ranking_type: undefined,
            ranking_value: undefined,
            previous_id: undefined,
        });
    };

    useEffect(() => {
        //lấy đề theo môn
        fetchExams({
            category_id: competition?.category_id,
            user_id: user.id,
            // noPagination: true,
            is_auto_scoring: true,
            perPage: 2,
        }).then((res) => {
            if (res.status) {
                setValues({
                    loading: false,
                    optionListExams: res.data || [],
                    optionListExamsDefault: res.data || [],
                });
            }
        });
    }, []);

    useEffect(() => {
        if (isLoading !== values.loading) {
            setValues({ loading: isLoading });
        }
    }, [isLoading]);

    useEffect(() => {
        if (Object.keys(editingAssignment || {}).length) {
            // Get subject list:
            form.setFieldsValue({
                class_id: editingAssignment?.class?.id,
                category_id: editingAssignment?.category?.id || undefined,
                name: editingAssignment?.name,
                exam_question_id: editingAssignment?.exam?.id,
                begin_date: editingAssignment?.begin_date ? moment(editingAssignment?.begin_date) : null,
                end_date: editingAssignment?.end_date ? moment(editingAssignment?.end_date) : null,
                homework_time_minute: editingAssignment?.homework_time_minute,
                ranking_type: editingAssignment?.ranking_type,
                ranking_value: editingAssignment?.ranking_value,
                order: editingAssignment?.order,
            });
            if (moment(new Date()).isAfter(moment(editingAssignment?.end_date))) {
                setValues({
                    isRoundEnded: true,
                    isDisableBeginDate: moment(editingAssignment?.begin_date).isBefore(new Date()),
                });
            }
        } else {
            if (values.optionListClass.length) {
                form.setFieldsValue({
                    class_id: undefined,
                    category_id: undefined,
                    name: undefined,
                    exam_question_id: undefined,
                    begin_date: undefined,
                    end_date: undefined,
                    homework_time_minute: undefined,
                    ranking_type: undefined,
                    ranking_value: undefined,
                    order: undefined,
                });
            }
        }
    }, [editingAssignment]);

    useEffect(() => {
        if (props.visible === false) {
            setValues({
                optionListSubject: [],
                optionListExams: values.optionListExamsDefault,
                exam_question_id: "",
                ranking_type: "",
                isDisableBeginDate: false,
            });
        } else {
            // thêm vòng thi đang edit vào list vòng thi
            const listExams = values.optionListExams;
            const exam = listExams?.find((item) => item?.id === editingAssignment?.exam?.id);
            if (!exam && editingAssignment?.exam?.id) {
                listExams.push(editingAssignment?.exam);
                setValues({ optionListExams: listExams });
            }
        }
    }, [props.visible]);

    useEffect(() => {
        if (editingAssignment?.ranking_type && values.ranking_type !== editingAssignment?.ranking_type) {
            setValues({
                ranking_type: editingAssignment.ranking_type,
            });
        }
    }, [editingAssignment?.ranking_type]);

    /*
     * t/g bắt đầu < t/g kết thúc
     * thời bắt đầu của vòng sau phải muộn thời gian kết thúc của vòng trc,
     *
     */
    // type : begin || end
    const validateTime = (type = "", time) => {
        if (type === "begin") {
            const endTime = form.getFieldValue("end_date");
            const previousEndTime = previousItem?.end_date;

            // t/g bắt đầu phải nhỏ hơn t/g kết thúc
            if (endTime) {
                // kt nhỏ hơn bắt đầu => quăng lỗi
                if (moment(endTime).isBefore(moment(time))) {
                    notification.warning({ message: t("message.err_begin_date_is_greater") });
                    form.setFieldsValue({ begin_date: "" });
                    return;
                }
            }

            // t/g bắt đầu vòng sau sớm hơn t/g kết thúc của vòng trước => error
            if (previousEndTime && moment(time).isBefore(moment(previousEndTime))) {
                notification.warning({
                    message: t("ranking.err_rounds_time_end"),
                });
                form.setFieldsValue({ begin_date: "" });
                return;
            }

            // nằm ngoài t/g cuộc thi
            if (!moment(time).isBetween(moment(competition?.begin_date), moment(competition?.end_date))) {
                notification.warning({ message: t("ranking.err_rounds_time_between") });
                form.setFieldsValue({ begin_date: "" });
                return;
            }
        } else if (type === "end") {
            const beginTime = form.getFieldValue("begin_date");
            const nextBeginTime = nextItem?.begin_date;

            if (beginTime) {
                //bắt đầu < kết thúc => lỗi
                if (moment(beginTime).isAfter(moment(time))) {
                    notification.warning({ message: t("message.err_begin_date_is_greater") });
                    form.setFieldsValue({ end_date: "" });
                    return;
                }
            }

            // muộn hơn t/g bắt đầu của vòng sau
            if (nextBeginTime && moment(time).isAfter(moment(nextBeginTime))) {
                notification.warning({
                    message: t("ranking.err_rounds_next_begin_time"),
                });
                form.setFieldsValue({ end_date: "" });
                return;
            }
            // nằm ngoài t/g cuộc thi
            if (!moment(time).isBetween(moment(competition?.begin_date), moment(competition?.end_date))) {
                notification.warning({ message: t("ranking.err_rounds_time_between") });
                form.setFieldsValue({ end_date: "" });
                return;
            }
        }
    };

    return (
        <Modal
            {...rest}
            centered
            // title={t("class.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            destroyOnClose
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-detail-exam competition`}
            onCancel={() => {
                onCancel();
                form.resetFields();
                setValues({ isRoundEnded: false });
            }}
            onOk={() => {
                onOk();
                form.resetFields();
                setValues({ isRoundEnded: false });
            }}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {/* Left section */}
                        <div className="modal-detail-exam__left">
                            <div className="modal-detail-exam__left-header">
                                {editingAssignment?.id ? t("ranking.update_round") : t("ranking.create_round")}
                            </div>
                            <div className="detail-exam-form">
                                <Form form={form} onFinish={handleSubmitForm}>
                                    <Form.Item
                                        label={
                                            <>
                                                <span>{t("class.exam")}</span>
                                                <CustomTooltip
                                                    type="question"
                                                    placement="right"
                                                    title={t("ranking.assignment_note")}
                                                    style={{ display: "inline-flex", order: "4", marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="exam_question_id"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Select
                                            // disabled={values.isRoundEnded}
                                            className="app-select"
                                            placeholder={t("class.select_exam")}
                                            onChange={(value, option) => handleChangeExam(value, option)}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            onSearch={handleSearchExams}
                                            loading={values.loadingExam1}
                                            notFoundContent={
                                                values.loadingExam1 === true ? (
                                                    <span style={{ display: "flex" }}>
                                                        <Spin style={{ marginRight: "10px" }} />{" "}
                                                        {`${t("shared.loading")}...`}
                                                    </span>
                                                ) : undefined
                                            }
                                        >
                                            {values.optionListExams.map((exam) => (
                                                <Select.Option key={exam.id} value={exam.id}>
                                                    {exam.name || t("shared.unknown")}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={<span>{t("ranking.round_name")}</span>}
                                        name="name"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Input
                                            // disabled={values.isRoundEnded}
                                            className="app-input"
                                            placeholder={t("class.input_exam_name")}
                                            maxLength={100}
                                        />
                                    </Form.Item>

                                    {/* <Form.Item
                                        label={
                                            <>
                                                <span>{t("class.exam_name")}</span>
                                                <CustomTooltip
                                                    type="question"
                                                    placement="right"
                                                    title={t("class.exam_name_description")}
                                                    style={{ order: "4", marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="name"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={t("class.input_exam_name")}
                                            maxLength={100}
                                        />
                                    </Form.Item> */}
                                    {editingAssignment?.id && (
                                        <Form.Item
                                            label={t("shared.order")}
                                            name="order"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <InputNumber
                                                // disabled={values.isRoundEnded}
                                                className="app-input"
                                                min={1}
                                                placeholder={t("shared.input_order")}
                                            />
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        label={
                                            <>
                                                <span>{t("class.begin_date")}</span>
                                                <CustomTooltip
                                                    type="question"
                                                    placement="right"
                                                    title={t("ranking.err_rounds_time_begin")}
                                                    style={{ display: "inline-flex", order: "4", marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="begin_date"
                                        rules={[{ required: true, message: t("class.input_begin_date") }]}
                                    >
                                        <DatePicker
                                            // disabled={values.isRoundEnded || values.isDisableBeginDate}
                                            onChange={(value) => validateTime("begin", value)}
                                            format="DD-MM-YYYY HH:mm"
                                            showTime={{ format: "HH:mm" }}
                                            placeholder={t("class.input_begin_date")}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                <span>{t("class.end_date")}</span>
                                                <CustomTooltip
                                                    type="question"
                                                    placement="right"
                                                    title={t("ranking.err_rounds_time_end")}
                                                    style={{ display: "inline-flex", order: "4", marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="end_date"
                                        rules={[{ required: true, message: t("class.input_end_date") }]}
                                    >
                                        <DatePicker
                                            // disabled={values.isRoundEnded}
                                            onChange={(value) => validateTime("end", value)}
                                            format="DD-MM-YYYY HH:mm"
                                            showTime={{ format: "HH:mm" }}
                                            placeholder={t("class.input_end_date")}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("class.duration")}
                                        name="homework_time_minute"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <InputNumber
                                            // disabled={values.isRoundEnded}
                                            className="app-input"
                                            placeholder={t("class.input_time")}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("ranking.ranking_type")}
                                        name="ranking_type"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Select
                                            // disabled={values.isRoundEnded}
                                            className="app-select"
                                            options={rankingOptions}
                                            onChange={(v) => {
                                                setValues({ ranking_type: v });
                                                if (
                                                    !optionListAssignment?.length ||
                                                    !optionListAssignment?.length < 1
                                                ) {
                                                    fetch({
                                                        category_id: form.getFieldValue("category_id"),
                                                        user_id: user.id,
                                                        noPagination: true,
                                                        class_id: form.getFieldValue("class_id"),
                                                        is_ranking: 1,
                                                        ignore_exam_id: form.getFieldValue("exam_question_id"),
                                                    });
                                                }
                                            }}
                                            allowClear
                                            placeholder={t("ranking.choose_ranking_type")}
                                        ></Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={values.ranking_type === "top" ? t("ranking.top") : t("ranking.point")}
                                        name="ranking_value"
                                        hidden={values.ranking_type ? false : true}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // disabled={values.isRoundEnded}
                                            className="app-input"
                                            placeholder={
                                                values.ranking_type === "top"
                                                    ? t("ranking.enter_the_number_top")
                                                    : t("ranking.enter_the_number_point")
                                            }
                                        />
                                    </Form.Item>

                                    <div className="note">
                                        {/* <span
                                            dangerouslySetInnerHTML={{
                                                __html: t("assignment.exam_note"),
                                            }}
                                        ></span> */}
                                    </div>
                                    <div className="btn-group">
                                        <CustomButton
                                            // isDisabled={values.isRoundEnded}
                                            type="green"
                                            htmlType="submit"
                                            icon={<SendIcon />}
                                            title={t("shared.save")}
                                            style={{ width: "100%", maxWidth: "unset" }}
                                        ></CustomButton>
                                    </div>
                                </Form>
                            </div>
                        </div>

                        <div className="modal-detail-exam__right">
                            <div className="image-wrapper">
                                <div className="image-top">
                                    <img src={ColorPoolImg} />
                                </div>
                                <div className="image-bottom center">
                                    <img src={RankingRoundImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailCompetition;
