import { t } from "i18next";
import React, { useState } from "react";
import ForgetPasswordPNG from "src/assets/images/ForgetPassword.png";
import ResetPasswordPNG from "src/assets/images/resetPassword.png";
import { Form, Input, notification, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CheckCircleOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Button from "src/modules/components/Button";
import { resetPassword, resetPasswordNewUserOrganization } from "src/api/containers/auth";

const ResetPassword = ({ username }) => {
    const [form] = useForm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handelResetPassword = () => {
        const formData = form.getFieldValue();

        formData.username = username;
        setIsLoading(true);
        resetPasswordNewUserOrganization(formData).then(({ status, message }) => {
            if (status) {
                notification.success({
                    message: t("message.reset_success"),
                });
                setIsLoading(false);
                navigate("/login");
            } else {
                notification.error({
                    message: message || t("message.reset_error"),
                });
                setIsLoading(false);
            }
        });
    };

    return (
        <div className="sign-in forget-password reset-password">
            <div className="sign-in-wrapper">
                <Spin spinning={isLoading}>
                    <h1>{t("login.update_password")}</h1>
                    <h4>{t("login.update_your_password")}</h4>
                    <div className="forget-password-wrapper">
                        <div className="forget-password-img">
                            <img src={ResetPasswordPNG} alt="" />
                        </div>
                        <Form form={form} onFinish={handelResetPassword}>
                            <h2>{t("login.new_password")}</h2>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Input.Password
                                    className="app-input"
                                    placeholder="********************"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>
                            <h2>{t("login.retype_new_password")}</h2>
                            <Form.Item
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t("message.password_not_match")));
                                        },
                                    }),
                                ]}
                                dependencies={["password"]}
                            >
                                <Input.Password
                                    className="app-input"
                                    placeholder="*****************"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    title={t("login.save_password")}
                                    className="submit-btn"
                                    htmlType="submit"
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default ResetPassword;
