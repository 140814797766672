import { Checkbox, Col, Form, InputNumber, notification, Row, Select, Spin, Typography } from "antd";
import Editor from "src/modules/components/Editor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { useValues } from "src/hooks";

import "./DetailShading.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import ShadeGrid from "./components/ShadeGrid";
import configEditor from "src/utils/configEditor";

const shadeDefaultValues = {
    width: 2,
    height: 2,
    rowCount: 1,
    columnCount: 6,
    shaded: [], //ô tô sẵn- đề bài
    correctAnswer: [], // đáp án đúng
    answers: [], // đáp án của user làm bài
    lock_cell: false,
};

const DetailShading = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const { t } = useTranslation();

    const [values, setValues] = useValues({
        loading: false,
        optionListCategory: [],
        optionListTag: [],
        ownerID: "",
        isPublish: false,
        paths: "",
        answer: [],
        defaultCorrectAnswer: [],
        isEditing: questionId ? true : false,
    });

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });
    const [shadeData, setShadeData] = useValues({
        ...shadeDefaultValues,
    });

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const _questionData = {
                type: "highlight_square",
                lock_cell: _formData.lock_cell,
                by_location: _formData.by_location,
                method: _formData.by_location ? "by_location" : "by_count",
                answer: shadeData.correctAnswer,
            };

            if (shadeData) {
                _questionData.width = shadeData.width;
                _questionData.height = shadeData.height;
                _questionData.columnCount = shadeData.columnCount;
                _questionData.rowCount = shadeData.rowCount;
                _questionData.shaded = shadeData.shaded;
            }

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: "",
                // Special fields:
                type: _questionData.type,
                width: _questionData.width,
                height: _questionData.height,
                columnCount: shadeData.columnCount,
                rowCount: shadeData.rowCount,
                is_multiple: _formData.is_multiple,
                mode: "preview",
                correct_answer: shadeData.correctAnswer,
                shaded: shadeData.shaded,
                lock_cell: shadeData.lock_cell,
                method: _formData.by_location ? "by_location" : "by_count",
                by_location: _formData.by_location,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            if (shadeData.correctAnswer?.length <= 0) {
                                notification.warning({ message: t("hotspot.missing_correct_answer") });
                                reject();
                                return;
                            }
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const checkIsItemLocked = (i, j, shaded) => {
        const isLocked = Array.isArray(shaded) && shaded.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;
        return isLocked;
    };

    const checkIsItemExists = (i, j, answers) => {
        return Array.isArray(answers) && answers.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;
    };

    const checkIndexIfItemExists = (i, j, arr) => {
        return Array.isArray(arr) && arr.findIndex((shade) => shade[0] === i && shade[1] === j);
    };

    //thêm 1 ô vuông vô array
    const modifyArray = (i, j, arr) => {
        const newArr = structuredClone(arr);
        const indx = checkIndexIfItemExists(i, j, newArr);
        if (indx !== -1) {
            newArr.splice(indx, 1); // remove item
            return newArr;
        } else return [...newArr, [i, j]];
    };

    const removeDuplicateItems = (arr) => {
        let result = [];
        if (Array.isArray(arr)) {
            arr.forEach((item) => {
                if (checkIndexIfItemExists(item[0], item[1], result) === -1) {
                    result.push(item);
                }
            });
        }
        return result;
    };

    const handleClickShadeItem = (i, j, mode) => {
        //mode: doing/ editing/ setAnswer/
        const isNotLocked = !shadeData.lock_cell && !checkIsItemLocked(i, j, shadeData.shaded);

        if (mode == "doing") {
            //TH làm bài
            if (isNotLocked) {
                const isNotExists = !checkIsItemExists(i, j, shadeData.answers);
                if (isNotExists) {
                    setShadeData({
                        answers: modifyArray(i, j, shadeData.answers),
                    });
                }
            }
        } else if (mode === "editing") {
            //TH tạo đề
            // const isNotExists = !checkIsItemExists(i, j, shadeData.answers);
            // if (isNotExists) {
            const newShade = modifyArray(i, j, shadeData.shaded);

            setShadeData({
                // shaded: [...shadeData.shaded, [i, j]],
                shaded: newShade,
                correctAnswer: modifyArray(
                    i,
                    j,
                    removeDuplicateItems([
                        ...structuredClone(shadeData.shaded),
                        ...structuredClone(shadeData.correctAnswer),
                    ])
                ),
            });
        } else if (mode === "setAnswer") {
            setShadeData({
                correctAnswer: modifyArray(i, j, removeDuplicateItems([...shadeData.correctAnswer])),
            });
        }
    };

    const handleChangeInputOptions = (value, type) => {
        if (value && type) {
            setShadeData({
                [type]: value,
            });
        }
    };

    const handeCheckLockCell = (e) => {
        if (e.target.checked) {
            setShadeData({
                lock_cell: e.target.checked,
                // correctAnswer: structuredClone(shadeData.shaded)
                correctAnswer: removeDuplicateItems([
                    ...structuredClone(shadeData.shaded),
                    ...structuredClone(shadeData.correctAnswer),
                ]),
            });
        } else {
            setShadeData({
                lock_cell: e.target.checked,
                correctAnswer: [],
            });
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData?.width) {
            setShadeData({
                width: formStates.formQuestionData?.width,
                height: formStates.formQuestionData?.height,
                columnCount: formStates.formQuestionData?.columnCount,
                rowCount: formStates.formQuestionData?.rowCount,
                lock_cell: formStates.formQuestionData?.lock_cell,
            });
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Set form data:
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                category_id: qData?.category?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                question: qData?.detail?.question || "",
                                score: qData?.detail?.score,
                                is_multiple: qData?.detail?.is_multiple,
                                correct_answer: qData.detail?.answer,
                                file_id: qData?.detail?.file_id,
                                // Special fields: "file_id", "src", "coordinates", "width", "height", "answer", "answer1".
                                // _answers: [...localAnswersCorrect, ...localAnswersAll],
                            };

                            const sData = {
                                width: qData?.detail?.width || 1,
                                height: qData?.detail?.height || 1,
                                columnCount: qData?.detail?.columnCount || 1,
                                rowCount: qData?.detail?.rowCount || 1,
                                shaded: qData?.detail?.shaded || [],
                                correctAnswer: qData?.detail?.answer || [],
                                lock_cell: qData?.detail?.lock_cell,
                            };

                            form.setFieldsValue(sData);

                            setShadeData(sData);

                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });

                            const corrAnswer = qData.detail?.answer;
                            if (corrAnswer) {
                                //delete 'key-' of 'key-abcdxy' => 'abcdxy
                                const result = corrAnswer
                                    .map((item) => item.result && item.text.substring(4))
                                    .filter((item) => item);
                                setValues({
                                    defaultCorrectAnswer: result,
                                });
                            }
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res?.[1]?.data;
                            const questionFormData = {
                                question: qData.question || "",
                                score: qData.score || undefined,
                                correct_answer: qData.answer || [],
                                // Special fields: "file_id", "src", "coordinates", "width", "height", "answer", "answer1".
                            };

                            const sData = {
                                width: qData?.width || 1,
                                height: qData?.height || 1,
                                columnCount: qData?.columnCount || 1,
                                rowCount: qData?.rowCount || 1,
                                shaded: qData?.shaded || [],
                                correctAnswer: qData?.answer || [],
                                lock_cell: qData?.lock_cell || false,
                            };
                            setShadeData(sData);
                            form.setFieldsValue(sData);

                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData, { shadeData });
        }
    }, [isFormFilled, detailData]);

    return (
        <div className="question-detail-shading">
            <Spin spinning={values.loading}>
                <div className="question-detail writing shading">
                    <div className="question-detail-content">
                        {/* ---Question title---------- */}

                        <Row className="question-editor">
                            <Typography className="question-editor-title">{t("question.question")}</Typography>
                            <Form.Item
                                name="question"
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Editor config={editorConfigQuestion.current}></Editor>
                            </Form.Item>
                        </Row>

                        {/* -----Question options ------- */}
                        <br />
                        <div className="shade-options">
                            <Typography.Title level={4} className="shade-options-title">
                                {t("question.options")}
                            </Typography.Title>
                            <Row>
                                <Col>
                                    <Form.Item
                                        className="shade-options_items"
                                        name="rowCount"
                                        label={t("shade.row_count")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                        initialValue={shadeDefaultValues.rowCount}
                                    >
                                        <InputNumber
                                            className="app-input shade-options-input"
                                            min={1}
                                            onChange={(value) => handleChangeInputOptions(value, "rowCount")}
                                        ></InputNumber>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        className="shade-options_items"
                                        name="columnCount"
                                        label={t("shade.column_count")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                        initialValue={shadeDefaultValues.columnCount}
                                    >
                                        <InputNumber
                                            className="app-input shade-options-input"
                                            min={1}
                                            onChange={(value) => handleChangeInputOptions(value, "columnCount")}
                                        ></InputNumber>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Item
                                        className="shade-options_items"
                                        name="width"
                                        label={t("shade.cell_width")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                        initialValue={shadeDefaultValues.width}
                                    >
                                        <InputNumber
                                            className="app-input shade-options-input"
                                            min={1}
                                            onChange={(value) => handleChangeInputOptions(value, "width")}
                                        ></InputNumber>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        className="shade-options_items"
                                        name="height"
                                        label={t("shade.cell_height")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                        initialValue={2}
                                    >
                                        <InputNumber
                                            className="app-input shade-options-input"
                                            min={1}
                                            onChange={(value) => handleChangeInputOptions(value, "height")}
                                        ></InputNumber>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        {/* -------Shade cells------ */}
                        <br />
                        <div className="shade-cells">
                            <div className="shade-cells_container">
                                {/* <Typography.Title level={4} className="shade-cells-title">
                                    {t("shade.shade_cells")}
                                </Typography.Title> */}
                                <Row justify="center">
                                    <div className="shade-cells_grid">
                                        <ShadeGrid
                                            onCellClick={handleClickShadeItem}
                                            cellWidth={shadeData.width}
                                            cellHeight={shadeData.height}
                                            rowCount={shadeData.rowCount}
                                            colCount={shadeData.columnCount}
                                            mode="editing"
                                            lockedCells={[]}
                                            listActive={shadeData.shaded}
                                        />
                                    </div>
                                </Row>
                                <br />
                                <Form.Item name="lock_cell" valuePropName="checked">
                                    <Checkbox
                                        style={{ fontSize: 18 }}
                                        className="app-checkbox"
                                        onChange={handeCheckLockCell}
                                        defaultChecked={false}
                                    >
                                        {t("shade.lock_shade_cells")}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>

                        {/* ========Set correct answer ============*/}
                        <br />
                        <div className="shade-correct-answer">
                            <Typography.Title level={4} className="shade-cells-title">
                                {t("shared.set_correct_answer")}
                            </Typography.Title>
                            <Row justify="space-between">
                                <Col span={12}>
                                    <Form.Item
                                        name="by_location"
                                        label={t("question.method")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                        initialValue={true}
                                    >
                                        <Select className="app-select show-arrow has-rd shade-cells-select-method">
                                            <Select.Option value={true}>{t("shade.by_location")}</Select.Option>
                                            <Select.Option value={false}>{t("shade.by_count")}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="score"
                                        label={t("question.score")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className="app-input"
                                            min={0}
                                            onKeyDown={(e) => {
                                                if (e.code === "Enter") {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center">
                                {/* set correct */}
                                <ShadeGrid
                                    onCellClick={handleClickShadeItem}
                                    cellWidth={shadeData.width}
                                    cellHeight={shadeData.height}
                                    rowCount={shadeData.rowCount}
                                    colCount={shadeData.columnCount}
                                    mode="setAnswer"
                                    lockedCells={shadeData.lock_cell ? shadeData.shaded : []}
                                    correctAnswers={shadeData.correctAnswer}
                                />
                            </Row>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default DetailShading;
