import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Item from "./Item";
import { validateFileList } from "../utils/helpers";
import { ReactComponent as AddFolder } from "../assets/actions/add-folder.svg";
import { ReactComponent as AddFile } from "../assets/actions/add-file.svg";
import { ReactComponent as MoveFolder } from "../assets/actions/move-folder.svg";
import { ReactComponent as TrashBin } from "../assets/actions/trash-bin.svg";
import { ReactComponent as LeftArrow } from "../assets/left-arrow.svg";

const Toolbar = ({ controller, uploadFiles = () => {} }) => {
    const { t } = useTranslation();

    const [activeItems, setActiveItems] = useState(["new_folder", "add_files"]);

    useEffect(() => {
        controller.set("toolbar.add_action", (actions) => {
            if (typeof actions === "string") {
                actions = [actions];
            }
            if (actions instanceof Array && actions.length > 0) {
                const newActiveItems = [...activeItems];
                actions.forEach((action) => {
                    if (!newActiveItems.includes(action)) {
                        newActiveItems.push(action);
                    }
                });
                setActiveItems(newActiveItems);
            }
        });
        controller.set("toolbar.remove_action", (actions) => {
            if (typeof actions === "string") {
                actions = [actions];
            }
            if (actions instanceof Array && actions.length > 0) {
                const newActiveItems = [...activeItems].filter((action) => !actions.includes(action));
                setActiveItems(newActiveItems);
            }
        });

        return () => {
            controller.remove("toolbar.add_action");
            controller.remove("toolbar.remove_action");
        };
    }, [activeItems]);

    const handleClickDelete = () => {
        if (activeItems.includes("delete")) {
            if (controller.call("content.get_current_selected_files")?.length) {
                controller.call("confirm.show", "Bạn có chắc muốn xóa tập tin/thư mục không?", () => {
                    controller.call("content.click_delete");
                });
            } else {
                const folderToBeDeleted = controller.call("sidebar.get_active_folder");
                controller.call(
                    "confirm.show",
                    `Bạn có chắc muốn xóa thư mục "${folderToBeDeleted.name || ""}" không?`,
                    () => {
                        controller.call(`sidebar_item.${folderToBeDeleted.id || null}.click_delete`);
                    }
                );
            }
        }
    };

    const handleClickNewFolder = () => {
        if (activeItems.includes("new_folder")) {
            const parent = controller.call("content.get_current_parent");
            if (parent) {
                controller.call("new_folder.show", parent);
            }
        }
    };

    const handleClickBack = () => {
        controller.call("shared.pop_flow_folder");
        setTimeout(() => {
            const folders = controller.call("shared.get_flow_folders");
            if (folders instanceof Array) {
                const last = folders[folders.length - 1];
                if (last) {
                    controller.call("content.open_folder", last);
                } else {
                    controller.call("content.open_folder", { id: null, name: "Root" });
                }
            }
        });
    };

    const handleClickAddFilesBtn = (e) => {
        if (e.target?.files?.length > 0) {
            const chk = validateFileList(e.target.files);
            if (chk.status === false) {
                controller.call("notification.show", chk.message, 5000, "error");
                return;
            }
            const res = uploadFiles(e.target?.files, controller.call("content.get_current_parent"));
            if (res instanceof Promise) {
                res.then((newFiles) => {
                    controller.call("content.add_files", newFiles);
                }).catch(({ message }) => {
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        }
    };

    const handleClickMoveToFolder = () => {
        controller.call("movetomodal.show");
    };

    return (
        <div className="file-manager-toolbar">
            <Item
                disabled={!activeItems.includes("back")}
                className="fm-go-back"
                src={LeftArrow}
                onClick={handleClickBack}
            />
            <Item
                disabled={!activeItems.includes("new_folder")}
                className="fm-new-folder"
                src={AddFolder}
                onClick={handleClickNewFolder}
                title={t("file_manager.add_folder")}
                text={t("file_manager.add_folder")}
            />
            <Item
                inputId={"input-files"}
                inputType={"file"}
                disabled={!activeItems.includes("add_files")}
                src={AddFile}
                onClick={(e) => {
                    // This onClick event handler is very important!
                    // This will reset the input's value and trigger the onchange event even if the same path is selected.
                    e.target.value = null;
                }}
                onChange={handleClickAddFilesBtn}
                title={t("file_manager.add_files")}
                text={t("file_manager.add_files")}
            />
            <Item
                disabled={!activeItems.includes("moveto")}
                src={MoveFolder}
                onClick={handleClickMoveToFolder}
                title={t("file_manager.move_to")}
                text={t("file_manager.move_to")}
            />
            <Item
                disabled={!activeItems.includes("delete")}
                src={TrashBin}
                onClick={handleClickDelete}
                title={t("file_manager.delete")}
                text={t("file_manager.delete")}
            />
        </div>
    );
};

export default Toolbar;
