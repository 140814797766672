import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, notification, Select, Spin, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import { fetchExams } from "src/api/containers/exam";
import { useDispatch, useSelector } from "react-redux";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { default as CustomButton } from "src/modules/components/Button";
import moment from "moment";
import iconClose from "src/assets/images/action-close.svg";
import { animationId } from "src/reducers/animation";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import RankingRoundImg from "src/assets/images/ranking/ranking_round.png";
import ColorPoolImg from "src/assets/images/ranking/color_pool.png";
import { fetchClasses } from "src/api/containers/class";
import { useParams } from "react-router-dom";
import { createEntryTestLevel, updateEntryTestLevel } from "src/api/containers/entry_test";
import { QuestionCircleOutlined } from "@ant-design/icons";

//giao bài tập trong 1 vòng thi, 1 vòng thi tương đương với 1 lần giao bài tập
const ModalDetailEntryTestLevel = (props) => {
    const {
        editingAssignment = {},
        previousItem = {},
        nextItem = {},
        isLoading = false,
        setValuesExam,
        classScopeId /** @param {string} classScopeId - Organization id, team id or undefined. */,
        onOk = () => {},
        onCancel = () => {},
        ...rest
    } = props;

    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const { id: test_input_id } = useParams();
    const [values, setValues] = useValues({
        loading: false,
        loadingExam1: false,
        optionListClass: [],
        optionListExams: [],
        optionListExamsDefault: [],
        exam_question_id: "",
        ranking_type: editingAssignment?.ranking_type || "",
        isRoundEnded: false,
        listClass: [],
        loadingListClass: false,
    });

    const timeoutIdGetExams = useRef(0);

    const dispatch = useDispatch();

    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }

    const handleSearchExams = (keyword) => {
        if (!values.loadingExam1) {
            setValues({
                loadingExam1: true,
            });
        }
        clearTimeout(timeoutIdGetExams.current);
        if (typeof keyword === "string") {
            setValues({
                loadingExam1: true,
            });

            timeoutIdGetExams.current = setTimeout(() => {
                fetchClasses({
                    slug: keyword,
                    page: 1,
                    perPage: 20,
                    organization_id: fParamsOrgMember.organization_id,
                }).then((res) => {
                    setValues({
                        loadingExam1: false,
                        optionListExams: res.data || [],
                    });
                });
            }, 800);
        }
    };

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();

        if (fParams.organization_id) {
            formData.organization_id = fParams.organization_id;
        }
        if (test_input_id) {
            formData.test_input_id = test_input_id;
        }

        setValues({ loading: true });
        if (!Object.keys(editingAssignment || {}).length) {
            createEntryTestLevel(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "entry_test", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setValues({ loading: false, ranking_type: "" });
            });
        } else {
            updateEntryTestLevel(editingAssignment.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "entry_test", update: editingAssignment.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false, ranking_type: "" });
            });
        }
    };

    const handleChangeClass = (value, option) => {};

    useEffect(() => {
        if (Object.keys(editingAssignment || {}).length) {
            // Get subject list:
            form.setFieldsValue({
                test_input_id: editingAssignment?.test_input_id,
                class_id: editingAssignment?.class?.id,
                name: editingAssignment?.name,
                score: editingAssignment?.score,
            });
        } else {
            form.resetFields();
        }
    }, [editingAssignment]);

    useEffect(() => {
        if (props.visible && !values.listClass?.length) {
            fetchClasses({ perPage: 20, organization_id: fParamsOrgMember.organization_id }).then(
                ({ status, data }) => {
                    if (status) {
                        setValues({ listClass: data });
                    }
                }
            );
        }
    }, [props.visible]);

    return (
        <Modal
            {...rest}
            centered
            // title={t("class.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            destroyOnClose
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-detail-exam competition`}
            onCancel={() => {
                onCancel();
                form.resetFields();
                setValues({ isRoundEnded: false });
            }}
            onOk={() => {
                onOk();
                form.resetFields();
                setValues({ isRoundEnded: false });
            }}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {/* Left section */}
                        <div className="modal-detail-exam__left">
                            <div className="modal-detail-exam__left-header">
                                {`${editingAssignment?.id ? t("shared.update") : t("shared.create")} ${t(
                                    "entry_test.entry_test_level"
                                ).toLowerCase()}`}
                            </div>
                            <div className="detail-exam-form">
                                <Form form={form} onFinish={handleSubmitForm}>
                                    <Form.Item
                                        label={`${t("profile.name")} ${t("entry_test.level").toLowerCase()}`}
                                        name="name"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={`${t("shared.input")} ${t("profile.name").toLowerCase()} ${t(
                                                "entry_test.level"
                                            ).toLowerCase()}`}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <>
                                                <span>{t("entry_test.score")}</span>
                                                <CustomTooltip
                                                    type="question"
                                                    placement="right"
                                                    title={t("entry_test.score_tooltip")}
                                                    style={{ order: "4", marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="score"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <InputNumber
                                            // disabled={values.isRoundEnded}
                                            className="app-input"
                                            placeholder={`${t("shared.input")} ${t("shared.score").toLowerCase()} `}
                                            min={0}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={<span>{t("class.class")}</span>}
                                        name="class_id"
                                        // rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Select
                                            // disabled={values.isRoundEnded}
                                            className="app-select"
                                            placeholder={t("class.select_class")}
                                            onChange={(value, option) => handleChangeClass(value, option)}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            onSearch={handleSearchExams}
                                            loading={values.loadingExam1}
                                            notFoundContent={
                                                values.loadingExam1 === true ? (
                                                    <span style={{ display: "flex" }}>
                                                        <Spin style={{ marginRight: "10px" }} />{" "}
                                                        {`${t("shared.loading")}...`}
                                                    </span>
                                                ) : undefined
                                            }
                                        >
                                            {values.listClass.map((exam) => (
                                                <Select.Option key={exam.id} value={exam.id}>
                                                    {exam.name || t("shared.unknown")}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <div className="note">
                                        {/* <span
                                            dangerouslySetInnerHTML={{
                                                __html: t("assignment.exam_note"),
                                            }}
                                        ></span> */}
                                    </div>
                                    <div className="btn-group">
                                        <CustomButton
                                            // isDisabled={values.isRoundEnded}
                                            type="green"
                                            htmlType="submit"
                                            icon={<SendIcon />}
                                            title={t("shared.save")}
                                            style={{ width: "100%", maxWidth: "unset" }}
                                        ></CustomButton>
                                    </div>
                                </Form>
                            </div>
                        </div>

                        <div className="modal-detail-exam__right">
                            <div className="image-wrapper">
                                <div className="image-top">
                                    <img src={ColorPoolImg} />
                                </div>
                                <div className="image-bottom center">
                                    <img src={RankingRoundImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailEntryTestLevel;
