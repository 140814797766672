import api from "..";

export const branchFilters = [
    { name: "branch.all_branch", value: "all" },
    { name: "branch.active_branch", value: "1" },
    { name: "branch.inactive_branch", value: "0" },
];

export const fetchBranches = (fetchParams) => {
    const filters = [];
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.active && filters.push(`active:${fetchParams.active}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);

    const params = {
        ...(fetchParams._noPagination && { _noPagination: fetchParams._noPagination }),
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _branch_fields: `${["name", "id", "active", "creator"]}`,
        _relations: `${[fetchParams?.relations?.length > 0 ? fetchParams.relations.map((item) => item) : undefined]}`,
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/branch", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                ...(!fetchParams.noPagination && {
                    pagination: {
                        showSizeChanger: false,
                        current: meta.pagination.page,
                        pageSize: meta.pagination.perPage,
                        total: meta.pagination.total,
                        lastPage: meta.pagination.lastPage,
                    },
                }),
            };
        })
        .catch(({ message }) => ({ status: false, data: [], message }));
};

export const updateBranch = (id, data) => {
    return api
        .put(`/branch/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createBranch = (data) => {
    return api
        .post("/branch", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteBranch = (branchId, fetchParams) => {
    let url = `/branch/${branchId}`;

    if (fetchParams?.organization_id) {
        url += `?organization_id=${fetchParams.organization_id}`;
    }

    return api
        .delete(url)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};
