import { Col, Form, InputNumber, notification, Row, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import Editor from "src/modules/components/Editor";
import Answers from "../DetailDragDropGroup/components/Answers";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import configEditor from "src/utils/configEditor";
import parser from "src/utils/parser";
import MyDragDropGroup from "../DetailDragDropGroup/components/MyDragDropGroup";

const DetailDragDropGroup = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const convertMatch = (match, answers) => {
        const newMatch = { ...match };

        Object.entries(newMatch || {}).forEach(([key, aswrs]) => {
            aswrs.forEach((answer, answerIndex) => {
                if (typeof answer === "string") {
                    const indexOfAnswers = answers.findIndex((aswr) => {
                        return aswr === answer;
                    });

                    newMatch[key][answerIndex] = {
                        text: answer,
                        value: indexOfAnswers,
                    };
                }
            });
        });

        return newMatch;
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            const _answer1 = _formData.answers.map((answer) => parser.compactMathField(answer));

            const answeredArr = Object.values(_formData?.match || {}).flat();

            const currentListAnswered = _formData?.match;
            // Handle change answered sync with rest answers (mutate state)
            const newListAnswered = convertMatch(currentListAnswered, _answer1);

            Object.entries(newListAnswered || {}).forEach(([key, aswrs]) => {
                aswrs.forEach((answer) => {
                    const answerIndex = answeredArr.findIndex((aswr) => aswr.value === answer.value);

                    if (answerIndex !== -1) {
                        answer.text = _answer1[answer.value];
                    }
                });
            });
            const _match = {};

            Object.keys(newListAnswered || {}).forEach((key) => {
                const answers = newListAnswered[key];
                _match[key] = answers.map((aswr) => parser.compactMathField(aswr.text));
            });

            // Data:
            const _questionData = {
                type: currQType,
                answer1: _answer1 || [],
                answer2: _formData.groups,
                match: _match,
            };

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                match: _match,
                answer1: _answer1,
                answer2: _formData.groups,

                // Special fields:
                type: _questionData.type,
            };

            // Start action:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                const validateActions = ["go-preview", "save-child", "save"];
                if (validateActions.includes(formAction)) {
                    const correctAnswers = form.getFieldValue("answers") || [];
                    const groups = form.getFieldValue("groups") || [];

                    const correctAnswersWithoutDuplicate = new Set(correctAnswers);
                    const groupsWithoutDuplicate = new Set(groups);

                    if (correctAnswersWithoutDuplicate.size !== correctAnswers.length) {
                        return notification.warning({
                            message: t("message.warning_duplicate_answer"),
                        });
                    }

                    if (groupsWithoutDuplicate.size !== groups.length) {
                        return notification.warning({
                            message: t("message.warning_duplicate_group"),
                        });
                    }
                }

                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            const _match = convertMatch(qData?.detail?.match, qData?.detail?.answer1);

                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                category_id: qData?.category?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                question: qData?.detail?.question || "",
                                score: qData?.detail?.score,
                                // Special fields: "answer1", "answer2", "match".
                                answers: qData?.detail?.answer1 || [""],
                                groups: qData?.detail?.answer2 || [""],
                                match: _match,
                            };

                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    form.setFieldsValue({
                        ...(!form.getFieldValue("answers")?.length && {
                            answers: ["Item 1", "Item 2"],
                        }),
                        ...(!form.getFieldValue("groups")?.length && {
                            groups: [
                                { name: "Group 1", key: "key-0" },
                                { name: "Group 2", key: "key-1" },
                            ],
                        }),
                        ...(!Object.keys(form.getFieldValue("match") || {})?.length && {
                            match: undefined,
                        }),
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;

                            const questionFormData = {
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "answer1", "answer2", "match".
                                answers: qData?.answer1 || [""],
                                groups: qData?.answer2 || [""],
                                match: qData?.match,
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }

                        form.setFieldsValue({
                            ...(!form.getFieldValue("answers")?.length && {
                                answers: ["Item 1", "Item 2"],
                            }),
                            ...(!form.getFieldValue("groups")?.length && {
                                groups: [
                                    { name: "Group 1", key: "key-0" },
                                    { name: "Group 2", key: "key-1" },
                                ],
                            }),
                            ...(!Object.keys(form.getFieldValue("match") || {})?.length && {
                                match: undefined,
                            }),
                        });
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và category list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion.current}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-formitems">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">
                    {t("question.answer")}
                    <CustomTooltip
                        type="info"
                        placement="right"
                        title={t("question.message_shuffle")}
                        style={{ marginLeft: "8px" }}
                    />
                </Typography>
                <div className="question-answer-list">
                    <Form.Item dependencies={["match"]}>
                        {(form) => (
                            <Form.Item name="answers">
                                <Answers form={form} name="answers" match={form.getFieldValue("match")} />
                            </Form.Item>
                        )}
                    </Form.Item>
                </div>
            </Row>

            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">
                    {t("question.group")}
                    <CustomTooltip
                        type="info"
                        placement="right"
                        title={t("question.message_help_group")}
                        style={{ marginLeft: "8px" }}
                    />
                </Typography>
                <div className="question-answer-list">
                    <Form.Item dependencies={["match"]}>
                        {(form) => (
                            <Form.Item name="groups">
                                <Answers form={form} match={form.getFieldValue("match")} name="groups" />
                            </Form.Item>
                        )}
                    </Form.Item>
                </div>
            </Row>

            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">
                    {t("question.correct_answer")}
                    <CustomTooltip
                        type="info"
                        placement="right"
                        title={t("question.message_choose_the_correct_answer")}
                        style={{ marginLeft: "8px" }}
                    />
                </Typography>
                <div className="question-answer-list">
                    <Form.Item dependencies={["answers", "groups"]}>
                        {(form) => (
                            <Form.Item name="match">
                                <MyDragDropGroup
                                    // onChange={handleChangeMatch}
                                    groups={form.getFieldValue("groups")}
                                    answers={form.getFieldValue("answers")}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </div>
            </Row>
        </>
    );
};

export default DetailDragDropGroup;
