import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, notification, Space, Tag, Tooltip } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import Header from "src/modules/components/Header";
import FilterMenu from "./components/CompetitionFilterMenu";
import InputSearch from "src/modules/components/Input/InputSearch";
import Button from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import FilterIcon from "src/assets/images/filter-icon.svg";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { listCompetitions } from "src/api/containers/Competition";
import { fetchClasses } from "src/api/containers/class";
import "./Competitions.scss";

const Competitions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { generate } = useRouting();

    // User and scope:
    const { user, accountType } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }

    // Api
    const [classLoading, classData, classPagination, fetchClass, reFetchClass] = useFetch(
        { noPagination: 1, relations: ["categories"] },
        fetchClasses
    );
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, slug: "", ...fParams },
        listCompetitions
    );

    const currMedia = useMediaQuery(["(max-width: 768px)"]);

    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;

    const [values, setValues] = useValues({
        isOnMobile: false,
        isShowForm: false,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchAssignment: "",
        isFilterMenuVisible: false,
        filterParams: [
            ...(fParams.organization_id
                ? [
                      {
                          name: "organization_id",
                          value: fParams.organization_id,
                          labelValue: fParamsDetail.organization_name,
                      },
                  ]
                : []),
        ],
    });

    const columns = [
        {
            title: t("ranking.competition_name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => record?.name,
        },

        {
            title: t("class.name"),
            dataIndex: "class",
            key: "class",
            width: "auto",
            render: (text, record) => record?.class?.name,
        },
        {
            title: t("class.subject"),
            dataIndex: "subject",
            key: "subject",
            width: "auto",
            render: (text, record) => record?.category?.name,
        },

        {
            title: t("class.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.begin_date).format("DD:MM:YYYY HH:MM")
                    : t("class.time_not_limited");
            },
        },
        {
            title: t("class.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.end_date).format("DD:MM:YYYY HH:MM")
                    : t("class.time_not_limited");
            },
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="table-action question-bank-action" style={{ justifyContent: "center" }}>
                    <Tooltip placement="top" title={t("ranking.take_exam")}>
                        <span
                            className="table-action__icon"
                            onClick={() => {
                                const a = generate("rounds", { competition_id: record?.id });
                                navigate(`${a}?competition_id=${record?.id}`);
                            }}
                        >
                            <UnorderedListOutlined />
                        </span>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const getFilterParamValue = (fName, fValueNotFound = undefined) => {
        for (let i = 0; i < filterSettings.filterParams.length; i++) {
            if (filterSettings.filterParams[i].name === fName) {
                return filterSettings.filterParams[i].value;
            }
        }
        return fValueNotFound;
    };

    const handleSearchAssignment = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchAssignment });
        }
    };

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        if (currMedia.breakpointValue !== -1) {
            setValues({ isOnMobile: true });
        } else if (values.isOnMobile) {
            setValues({ isOnMobile: false });
        }
    }, [currMedia.breakpointValue]);

    useEffect(() => {
        dispatch(setCurrentPage("assignment"));
        fetch({}, true);
        fetchClass({}, true);
    }, []);

    useEffect(() => {
        //animation when clone a new question
        if (animate?.name == "assignment") {
            const rowAnimation = (id, action, timeout = 3000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };

            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    return (
        <>
            <div className="question-bank competitions">
                <Header backEnable={false} backTitle={t("ranking.list_of_competitions")} actions={[]} />

                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                onChange={(e) =>
                                    setFilterSettings({ ...filterSettings, inputSearchAssignment: e.target.value })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearchAssignment();
                                    }
                                }}
                                onClickIconSearch={handleSearchAssignment}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                          fKey.labelValue || ""
                                                      }`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                forceRender
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        classData={classData}
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetchAssignmentList={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) => {
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val });
                                }}
                            >
                                <div className="filter-button">
                                    <Button type="primary" icon={FilterIcon} title={t("shared.option_filter")}></Button>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="table competitions-table">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={loading}
                        pagination={{
                            showSizeChanger: false,
                            ...pagination,
                            position: ["bottomCenter"],
                            onChange: (page) => {
                                fetch({ page: page });
                            },
                        }}
                        scroll={{ x: 1500 }}
                        rowKey="id"
                    />
                </div>
            </div>
        </>
    );
};

export default Competitions;
