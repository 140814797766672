import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import Answer from "./components/Answer";
import QuestionContentDetail from "../components/ContentDetail";
import "./MultipleChoice.scss";
import clsx from "clsx";

const MultipleChoice = (props) => {
    const {
        isReadonly = false,
        question = "",
        id,
        type,
        /**
         * Description type for answer
         *
         * @param {@Interface string[] | {text: string; result: boolean}[]  | {text: string; chosen: boolean}[]}
         */
        answer = [],

        defaultAnswer = [],
        onChange = () => {},
        qIndex,
        exam_types = "",
    } = props;

    const [inputAnswers, setInputAnswers] = useState(() => {
        if (answer[0]?.text) {
            if (answer[0].chosen !== undefined) {
                return answer;
            }

            if (answer[0].result !== undefined) {
                const result = answer.map((aswr) => {
                    if (typeof aswr.text === "string") {
                        return {
                            text: aswr.text,
                            chosen: defaultAnswer?.includes(aswr.text) || false,
                        };
                    } else {
                        //eslint-disable-next-line
                        console.error("Answer.text is not string, please contact dev");
                    }
                });

                return result || [];
            }
        } else {
            const result = answer?.map((aswr) => {
                if (typeof aswr === "string") {
                    return {
                        text: aswr,
                        chosen: defaultAnswer?.includes(aswr) || false,
                    };
                } else {
                    //eslint-disable-next-line
                    console.error("Answer is not string, please contact dev");
                }
            });

            return result;
        }
    });

    const handleChooseAnswer = (index) => () => {
        const newAnswer = structuredClone(inputAnswers);

        newAnswer.forEach((answer) => (answer.chosen = false));
        newAnswer[index].chosen = !newAnswer[index].chosen;

        const newAnswered = [];

        newAnswer.forEach((aswr) => {
            aswr.chosen && newAnswered.push(aswr.text);
        });

        const newQuestionInfo = {
            answered: newAnswered,
        };

        setInputAnswers(newAnswer);
        onChange(newQuestionInfo);
    };

    return (
        <div className={clsx("q-multiple-choice", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? (
                <div className="q-title">
                    {t("q.question")} {qIndex ? ` ${qIndex}` : null}{" "}
                </div>
            ) : null}

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question || "",
                    }}
                />
            </div>

            <div className="view-multiple-choice">
                {inputAnswers.map((item, index) => (
                    <Answer
                        key={index}
                        answer={item}
                        disabled={isReadonly}
                        handleChooseAnswer={!isReadonly ? handleChooseAnswer(index) : () => {}}
                        letter={String.fromCharCode(97 + index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MultipleChoice;
