import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Form, Input, Modal, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import Icon from "src/modules/components/Icon";
import { createBranch, updateBranch } from "src/api/containers/branch";

const ModalDetailBranch = (props) => {
    const { details = null, visible = true, onOk = () => {}, onCancel = () => {}, organization, ...rest } = props;

    const user = useSelector((state) => state.auth.user);

    const [values, setValues] = useValues({
        loading: false,
        loadingTeachers: false,
        optionListCategory: [
            { value: false, name: "inactive" },
            { value: true, name: "active" },
        ],
        optionListTeacher: [],
    });
    const { t } = useTranslation();
    const [form] = useForm();

    const handleCancelForm = (willFormBeCleared = false) => {
        if (!details && willFormBeCleared === true) {
            form.resetFields();
        }
        onCancel();
    };

    const handleAddClass = (newBranchData) => {
        if (newBranchData) {
            setValues({ ...values, loading: true });
            newBranchData = {
                ...newBranchData,
                creator_id: user.id,
            };
            createBranch(newBranchData).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleUpdateBranch = (id, data) => {
        if (id && data) {
            setValues({ ...values, loading: true });
            // data.active = active;
            updateBranch(id, data).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        } else {
            notification.error({
                message: t("branch.input_name"),
            });
        }
    };

    const handleSubmitForm = (id) => {
        let formData = {
            ...form.getFieldsValue(),
            organization_id: organization?.organization_id,
        };
        if (!formData.name) {
            return;
        }
        if (id) {
            handleUpdateBranch(id, formData);
        } else {
            handleAddClass(formData);
        }
    };

    useEffect(() => {
        if (visible && form) form.setFieldsValue({ name: details.name, active: details.active });
    }, [details, visible]);

    return (
        <Modal
            visible={visible}
            onOk={() => handleSubmitForm(details.branchId)}
            onCancel={handleCancelForm}
            centered
            title={details ? t("branch.update_branch") : t("branch.add_branch")}
            closeIcon={<IconClose />}
            footer={null}
            width="auto"
            {...rest}
            className={`app-modal type-basic flexible-height maxw-520${props.className ? " " + props.className : ""}`}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form}>
                        <Form.Item
                            label={t("branch.name")}
                            name="name"
                            rules={[{ required: true, message: t("branch.input_branch_name") }]}
                        >
                            <Input placeholder={t("branch.input_name")} maxLength={100} />
                        </Form.Item>

                        <Form.Item label={t("branch.status")} name="active">
                            <Select className="app-select">
                                <Select.Option value={true}>{t("branch.active")}</Select.Option>
                                <Select.Option value={false}>{t("branch.inactive")}</Select.Option>
                            </Select>
                        </Form.Item>

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-delete" />}
                                title={t("shared.cancel")}
                                onClick={handleCancelForm}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save")}
                                onClick={() => handleSubmitForm(details.branchId)}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailBranch;
