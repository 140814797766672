import { Button, Layout } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useMemo, useRef } from "react";
import useValues from "src/hooks/useValues";
import QuestionResultPDF from "./QuestionResultPDF";
import Thumbnails from "../TestPDF/Thumbnails";
import StudentPDFPreviewer from "../TestPDF/StudentPDFPreviewer";
import ExamResult from "src/modules/components/ProgressBar/ExamResult";
import "./PDFTestResult.scss";
import { LogoutOutlined } from "@ant-design/icons";

function PDFTestResult({
    role,
    record,
    isMarking = false,
    markedQuestions,
    markingQuestion,
    typeViewScore = "review",
    markedScore,
    headerActions,
}) {
    const previewRef = useRef();
    const [values, setValues] = useValues({
        currentPageData: {},
        showThumbnails: false,
    });

    function toggleThumbnails() {
        setValues({ showThumbnails: !values.showThumbnails });
    }

    useEffect(() => {
        if (record?.data?.history?.length) {
            setValues({ currentPageData: record?.data?.history?.[0] });
        }
    }, [record]);

    return (
        <div>
            <div className="testLayout_wrapper pdf">
                <Layout style={{ flexDirection: "column", padding: 10 }}>
                    <div className="viewScoreStudent_header pdf">
                        <h3 className="title">{record?.data?.assignment?.name}</h3>
                        <ExamResult
                            isMobile={false}
                            width={140}
                            size="small"
                            totalScore={record?.data?.assignment?.exam?.max_score}
                            score={markedScore}
                            countQuestions={record?.data?.count_sentences}
                            countCorrectAnswers={record?.data?.count_correct_sentences}
                            className="pdf"
                        />
                        <div className="actions">
                            {/* <Button icon={<LogoutOutlined title="Thoát" />}>Thoát</Button> */}
                            {headerActions ? headerActions : null}
                        </div>
                    </div>
                    <Layout style={{ flexDirection: "row" }}>
                        <Thumbnails
                            testData={record?.data?.history}
                            currentThumbnail={values.currentPageData}
                            setCurrentThumbnails={(data) => {
                                setValues({ currentPageData: data });
                            }}
                            isShowThumbnails={values.showThumbnails}
                        />
                        <Content className="testLayout_content">
                            <StudentPDFPreviewer
                                width={previewRef?.current?.offsetWidth || 600}
                                height={previewRef?.current?.offsetHeight || 800}
                                src={values.currentPageData?.src}
                                currentThumbnails={values.currentPageData}
                                toggleThumbnail={toggleThumbnails}
                            />
                        </Content>
                        <Sider theme="light" width="35%">
                            {/* <QuestionList questions={data?.questions} /> */}

                            <QuestionResultPDF
                                role={role}
                                showResults={true}
                                record={record?.data}
                                order={record?.order}
                                isMarking={isMarking}
                                markedQuestions={markedQuestions}
                                markingQuestion={markingQuestion}
                                typeViewScore={typeViewScore}
                                questions={values.currentPageData?.questions}
                            />
                        </Sider>
                    </Layout>
                </Layout>
            </div>
        </div>
    );
}

export default PDFTestResult;
