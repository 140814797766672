import React from "react";
import styles from "./NewTestCard.module.scss";
import { isFunction } from "src/utils/helpers";
import { Badge } from "antd";

function NewTestCard({
    icon,
    title = "",
    description = "",
    renderActions = () => {},
    footer = () => {},
    header = () => {},
    ribbonText = "",
}) {
    const content = (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <header>{header ? header() : null}</header>
                <div className={styles.body}>
                    {icon && <span className={styles.cardIcon}>{icon}</span>}
                    {title ? <div className={styles.title}>{title}</div> : null}
                    {description ? <div className={styles.description}>{description}</div> : null}
                    {isFunction(renderActions) && renderActions()}
                </div>
                <footer>{footer ? footer() : null}</footer>
            </div>
        </div>
    );
    return ribbonText ? (
        <Badge.Ribbon text={ribbonText} color="red">
            {content}
        </Badge.Ribbon>
    ) : (
        content
    );
}

NewTestCard.propTypes = {};

export default NewTestCard;
