import React from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import clsx from "clsx";
import { questionTypes } from "src/api/containers/question";
import ImageWithLabels from "./components/ImageWithLabels";
import MultipleChoice from "./components/MultipleChoice";
import FillBlanks from "./components/FillBlanks";
import FillBlanksInput from "./components/FillBlanksInput";
import MultipleChoiceBoolean from "./components/MultipleChoiceBoolean";
import Writing from "./components/Writing";
import MultipleChoiceTable from "./components/MultipleChoiceTable";
import Passage from "./components/Passage";
import DragDrop from "./components/DragDrop";
import DragDropGroup from "./components/DragDropGroup";
import Sort from "./components/Sort";
import Highlight from "./components/Highlight";
import FillBlanksDragDrop from "./components/FillBlanksDragDrop";
import HighlightImage from "./components/HighlightImage";
import DragDropGroupOrderBy from "./components/DragDropGroupOrderBy";
import ShadingPreview from "./components/Shading";
import Hotspot from "./components/Hotspot";
import SpeechToText from "./components/SpeechToText";
import MathTextDropdown from "./components/MathTextDropdown";
import Matrix from "./components/Matrix";
import Graph from "./components/Graph";
import GraphNumberLine from "./components/GraphNumberLine";
import "./QuestionContent.scss";

export const QuestionContent = ({
    questionInfo,
    handleMarkWritingInPassage,
    formData,
    questionNumber,
    showQuestionNumber,
    styleShowQuestion,
    isMarking,
    isShowScore = true,
    displayType = "default", // "default" | "question-panel".
    exam_types = "",
}) => {
    const renderQuestionContent = () => {
        const generalProps = {
            type: questionInfo?.question_type?.type || questionInfo?.type,
            question: questionInfo.question,
            score: questionInfo.score,
            score_submit: questionInfo.score_submit,
            order: questionInfo.order,

            correct_answer: questionInfo.correct_answer || questionInfo.answer,

            answered: questionInfo.answered || questionInfo.answer_submit,
            id: questionInfo.id || questionInfo._id,
            exam_types: exam_types,
        };

        switch (generalProps?.type) {
            case "writing_short":
            case "writing":
                if (questionInfo?.answer_submit) {
                    questionInfo.answer = questionInfo?.answer_submit || questionInfo.answer;
                }
                return <Writing {...generalProps} />;
            case "fill_in_the_blank_text_image": {
                const { coordinates, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="text" {...generalProps} />;
            }
            case "fill_in_the_blank_image": {
                const { coordinates, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="dropdown" {...generalProps} />;
            }
            case "fill_in_the_blank_drag_drop_image": {
                const { coordinates, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="drag&drop" {...generalProps} />;
            }
            case "fill_in_the_blank":
                return <FillBlanks {...generalProps} />;
            case "fill_in_the_blank_text":
                generalProps.is_require_uppercase = questionInfo.is_require_uppercase || false;
                return <FillBlanksInput {...generalProps} />;
            case "fill_in_the_blank_drag_drop":
                return <FillBlanksDragDrop {...generalProps} />;
            case "multiple_choice":
            case "multiple_choice_sentence_quiz":
                return <MultipleChoice showResults {...generalProps} />;
            case "multiple_choice_boolean":
                return <MultipleChoiceBoolean showResults {...generalProps} />;
            case "multiple_choice_table":
                generalProps.correct_answer = questionInfo.correct_answer || questionInfo.match;
                generalProps.answer1 = questionInfo.answer1;
                generalProps.answer2 = questionInfo.answer2;

                return <MultipleChoiceTable showResults {...generalProps} />;
            case "passage":
                return (
                    <Passage
                        questionInfo={questionInfo}
                        handleMarkWritingInPassage={handleMarkWritingInPassage}
                        formData={formData}
                        isMarking={isMarking}
                        isShowScore={isShowScore}
                    />
                );
            case "drag_drop":
                if (!questionInfo.correct_answer) {
                    generalProps.correct_answer = {
                        answer1: questionInfo?.correct_answer1 || questionInfo?.answer1,
                        answer2: questionInfo?.correct_answer2 || questionInfo?.answer2,
                    };
                }

                return <DragDrop {...generalProps} />;
            case "drag_drop_group": {
                generalProps.answer1 = questionInfo.answer1;
                generalProps.answer2 = questionInfo.answer2;
                generalProps.correct_answer = questionInfo.match;

                return <DragDropGroup {...generalProps} />;
            }
            case "drag_drop_group_order_by": {
                generalProps.answer1 = questionInfo.answer1;
                generalProps.answer2 = questionInfo.answer2;
                generalProps.correct_answer = questionInfo.match;

                return <DragDropGroupOrderBy {...generalProps} />;
            }
            case "sort":
                return <Sort {...generalProps} />;
            case "highlight":
                generalProps.passage_student = questionInfo.passage_student;
                generalProps.passage = questionInfo.passage;

                // Hiện tại answered của câu highlight là passage_highlight. Sau này khi sửa api sẽ đổi lại logic
                if (questionInfo.highlight_submit)
                    generalProps.answered = { passage_highlighted: questionInfo.highlight_submit };
                return <Highlight {...generalProps} />;
            case "highlight_image":
                return (
                    <HighlightImage
                        {...generalProps}
                        {...{
                            width: questionInfo.width,
                            height: questionInfo.height,
                            src: questionInfo.src || questionInfo?.file?.src,
                        }}
                    />
                );
            case "highlight_square":
                return <ShadingPreview {...generalProps} {...{ qInfo: questionInfo }} />;

            case "hot_spot":
                return (
                    <Hotspot
                        {...generalProps}
                        {...{
                            width: questionInfo.width,
                            height: questionInfo.height,
                            src: questionInfo.src || questionInfo?.file?.src,
                            paths: questionInfo.paths || questionInfo?.path,
                        }}
                    />
                );
            case "speech_to_text":
                const isCorrectSubmited =
                    questionInfo?.score_submit !== undefined && questionInfo?.score
                        ? questionInfo?.score_submit === questionInfo?.score
                            ? true
                            : false
                        : undefined;
                generalProps.is_sentence = questionInfo.is_sentence;
                generalProps.answer = generalProps.correct_answer;
                generalProps.lang = questionInfo?.language?.code;
                generalProps.isCorrectSubmited = isCorrectSubmited;
                return <SpeechToText {...generalProps} />;
            case "convert_measurement":
                return <MathTextDropdown {...generalProps} />;

            case "fill_in_the_blank_latex":
            case "matrix":
                return <Matrix {...generalProps} template_latex={questionInfo.template_latex} />;
            case "draw_graph": {
                return <Graph {...generalProps} />;
            }
            case "number_line": {
                const { max_value, min_value, jump_value } = questionInfo;
                generalProps.max_value = max_value;
                generalProps.min_value = min_value;
                generalProps.jump_value = jump_value;
                return <GraphNumberLine {...generalProps} />;
            }
            default:
                return null;
        }
    };

    const element = (
        <div className="qc_panel">
            {showQuestionNumber && styleShowQuestion == "default" && (
                <div className="question-label-wrapper">
                    <div className="question-label">
                        <span className="label-wrapper">
                            <span className="label-question">{t("question_panel.question")}</span>
                            <span className="label-number">
                                {questionNumber ? `${questionNumber < 10 ? "0" : ""}${questionNumber}` : "00"}
                            </span>
                        </span>
                    </div>
                </div>
            )}
            {showQuestionNumber && styleShowQuestion == "circle" && (
                <div className="label-wrapper-circle">
                    {questionNumber ? `${questionNumber < 10 ? "0" : ""}${questionNumber}` : "00"}
                </div>
            )}

            <div
                className={clsx(
                    "qc_body",
                    showQuestionNumber && styleShowQuestion == "default" ? "content-wrapper" : ""
                )}
            >
                <React.Fragment key={questionInfo.id}>{renderQuestionContent()}</React.Fragment>
            </div>
        </div>
    );

    if (displayType === "question-panel") {
        return (
            <div className="qc_wrapper question-panel">
                <div className="question-content">{element}</div>
            </div>
        );
    }

    return element;
};

QuestionContent.defaultProps = {
    type: "preview",
    styleShowQuestion: "defualt",
    questionNumber: 0,
    showQuestionNumber: false,
    questionInfo: {},
    handleMarkWritingInPassage: () => {},
    formData: null,
};

QuestionContent.propTypes = {
    questionInfo: PropTypes.shape({
        answered: PropTypes.array,
        question: PropTypes.string,
        type: PropTypes.oneOf(Object.keys(questionTypes)).isRequired,
    }).isRequired,
    showResults: PropTypes.bool,
};

export default QuestionContent;
