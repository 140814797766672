import { t } from "i18next";
import React from "react";
import InputEditor from "src/modules/components/InputEditor";
import { BinIcon } from "src/utils/drawer";
import "./Answer.scss";

const Answer = (props) => {
    const { value, index, onChange, onDeleteAnswer } = props;

    return (
        <div className="sort_item">
            <InputEditor
                isSuffix
                suffix={<BinIcon onClick={() => onDeleteAnswer(index)} />}
                value={value}
                onChange={onChange}
                placeholder={t("q.write_answer_here")}
            />
        </div>
    );
};

export default React.memo(Answer);
