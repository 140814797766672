import clsx from "clsx";
import ResultAnswer from "../../../components/ResultAnswer";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import "./Answer.scss";

const Answer = (props) => {
    const { isShowIcon, answer = {}, handleChooseAnswer = () => {} } = props;

    const generateClassName = () => {
        return answer.isCorrect ? " chosen-correctly" : " chosen-incorrectly";
    };

    return (
        <div className={clsx("answer-for-drag-drop-group", generateClassName())} onClick={handleChooseAnswer}>
            {isShowIcon ? (
                <ResultAnswer resultStatus={answer.isCorrect ? "correct" : "incorrect"}>
                    <HTMLDisplayer rootType="inline" htmlString={answer?.text} />
                </ResultAnswer>
            ) : (
                <ResultAnswer>
                    <HTMLDisplayer rootType="inline" htmlString={answer?.text} />
                </ResultAnswer>
            )}
        </div>
    );
};

export default Answer;
