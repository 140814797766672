import { Typography } from "antd";
import { t } from "i18next";
import UserSpeechRecognition from "src/modules/components/UserSpeechRecognition";
import QuestionContentDetail from "../ContentDetail";
import "./SpeechToText.scss";

const SpeechToText = (props) => {
    const { question, answered, speaker, answer, is_sentence, onChange, lang, isCorrectSubmited } = props;

    return (
        <div className="qc_writing qc_speech-to-text">
            <div className="viewScoreStudent_writingTitle">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question: question,
                    }}
                />
            </div>
            <div className="speech-to-text-frame">
                <br />
                <div className="sample-sentence">
                    {/* <Typography.Title level={4} style={{ textAlign: "center" }}>
                        {answer?.[0] || answer || ""}
                    </Typography.Title> */}
                </div>
                {/* <Typography.Title level={4}>{t("question.your_answer")}</Typography.Title> */}
                <UserSpeechRecognition
                    is_sentence={is_sentence}
                    answer={answer}
                    checkHadSpeech={true}
                    speaker={Array.isArray(speaker) ? speaker?.[0] : speaker}
                    lang={lang}
                    readOnly={true}
                    answered={answered}
                    onChange={onChange}
                    isCorrectSubmited={isCorrectSubmited}
                />
            </div>
        </div>
    );
};

export default SpeechToText;
