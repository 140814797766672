import React, { useEffect, useState } from "react";
import { Avatar, Divider, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    fetchNotifications,
    markAllNotificationsAsRead,
    markOneNotificationAsRead,
} from "src/api/containers/notification";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useRealtimeListener from "src/hooks/useRealtimeListener";
import { default as CustomButton } from "../Button";
import "./NotificationPanel.scss";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";

const NotificationPanel = ({
    scrollableTarget,
    limitItems = 100,
    countNewItems = 0,
    onChangeCountNewItems,
    ...rest
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        pageCurr: 0,
        listCountItems: 0,
        listOfNotifs: [],
    });
    const newNotifsInRealtime = useRealtimeListener();

    const handleMarkOneAsRead = (notifId) => {
        if (notifId) {
            markOneNotificationAsRead(notifId).then((res) => {
                if (res.status) {
                    const newList = [...values.listOfNotifs];
                    for (let i = 0; i < newList.length; i++) {
                        if (newList[i].id === notifId) {
                            newList[i].read_at = true;
                            break;
                        }
                    }
                    setValues({
                        ...values,
                        listOfNotifs: newList,
                    });
                    if (onChangeCountNewItems instanceof Function) {
                        onChangeCountNewItems(countNewItems - 1);
                    }
                }
            });
        }
    };

    const handleMarkAllAsRead = () => {
        markAllNotificationsAsRead().then((res) => {
            if (res.status) {
                const newList = [...values.listOfNotifs];
                for (let i = 0; i < newList.length; i++) {
                    newList[i].read_at = true;
                }
                setValues({
                    ...values,
                    listOfNotifs: newList,
                });
                if (onChangeCountNewItems instanceof Function) {
                    onChangeCountNewItems(0);
                }
            }
        });
    };

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        fetchNotifications({ page: values.pageCurr + 1 })
            .then((resData) => {
                const listOfNewNotifs = resData.data?.length ? resData.data : [];
                setValues({
                    ...values,
                    pageCurr: resData.pagination?.current,
                    listCountItems: resData.pagination?.total
                        ? resData.pagination?.total <= limitItems
                            ? resData.pagination?.total
                            : limitItems
                        : 0,
                    listOfNotifs: [...values.listOfNotifs, ...listOfNewNotifs],
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadMoreData();
    }, []);

    return (
        <div id="notif-panel">
            <div className="notif-menu">
                <CustomButton
                    className="notif-menu-action"
                    type="simple"
                    icon={<SvgTick />}
                    title={t("notification.mark_all_as_read")}
                    onClick={handleMarkAllAsRead}
                    disabled={!countNewItems}
                />
            </div>
            {countNewItems ? (
                <div className="notif-msg">
                    {countNewItems} {t("notification.unread_messages").toLowerCase()}
                </div>
            ) : null}

            <InfiniteScroll
                scrollableTarget={scrollableTarget || "notif-panel"}
                dataLength={values.listOfNotifs.length}
                next={loadMoreData}
                hasMore={
                    values.listOfNotifs.length <
                    (values.listCountItems <= limitItems ? values.listCountItems : limitItems)
                }
                loader={
                    <Skeleton
                        avatar
                        paragraph={{
                            rows: 1,
                        }}
                        active
                    />
                }
                endMessage={
                    <Divider className="list-end-message" plain>
                        {t("notification.list_end")}
                    </Divider>
                }
            >
                <List
                    dataSource={values.listOfNotifs}
                    renderItem={(item, index) => {
                        const liItemTitle = (
                            <div className="notif-item-title">
                                <div>{item.content || ""}</div>
                                {item.created_at ? <div>{moment(item.created_at).fromNow()}</div> : null}
                            </div>
                        );
                        return (
                            <List.Item key={`${index}`} className={"notif-item" + `${item.read_at ? "" : " unread"}`}>
                                <List.Item.Meta avatar={<Avatar src={null} />} title={liItemTitle} description={""} />
                                {item.read_at ? (
                                    <span className="notif-item-action hidden">{t("notification.read")}</span>
                                ) : (
                                    <span
                                        className="notif-item-action"
                                        onClick={() => {
                                            handleMarkOneAsRead(item.id);
                                        }}
                                    >
                                        {t("notification.unread")}
                                    </span>
                                )}
                            </List.Item>
                        );
                    }}
                />
            </InfiniteScroll>
        </div>
    );
};

export default NotificationPanel;
