import { Radio } from "antd";
import clsx from "clsx";
import LatexDangerously from "src/modules/components/LatexDangerously";
import { CorrectIcon, InCorrectIcon } from "src/utils/drawer";
import parser from "src/utils/parser";
import "./MyTable.scss";

const MyTable = (props) => {
    const {
        answer1, // string[]
        answer2, //
        correctMatch,
        answered,
        onChange,
    } = props;

    return (
        <div className="resultpanel__multiplechoicetable__table">
            <div className="multiplechoicetable__table-skin">
                <table className="multiplechoicetable__table__skeleton">
                    <tbody>
                        <tr className="sticky-row">
                            <td className="multiplechoicetable_item sticky-col first-col"></td>
                            {answer2.map((casr2, i) => (
                                <td className="multiplechoicetable_item" key={i}>
                                    <LatexDangerously innerHTML={casr2} />
                                </td>
                            ))}
                        </tr>
                        {answer1.map((casr1, i) => (
                            <tr key={i} className={clsx(i === 0 && "sticky-bottom-content")}>
                                <td className="sticky-col first-col multiplechoicetable_item">
                                    <div>
                                        <LatexDangerously innerHTML={casr1} />
                                    </div>
                                </td>

                                {/* Td value */}
                                {answer2.map((casr2, iCol) => {
                                    const isCorrect = correctMatch?.[i] === answered?.[i];
                                    return (
                                        <td className="text-center multiplechoicetable_item" key={iCol}>
                                            <Radio checked={answered?.[i] === iCol}></Radio>
                                            {answered?.[i] === iCol && (
                                                <>{isCorrect ? <CorrectIcon /> : <InCorrectIcon />}</>
                                            )}

                                            {(answered?.[iCol] === undefined || !isCorrect) &&
                                                correctMatch?.[i] === iCol && <CorrectIcon />}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MyTable;
