import { Col, Form, Modal, Row, Select, Spin, notification } from "antd";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { fetchClasses, mergeClass } from "src/api/containers/class";
import { useValues } from "src/hooks";
import CustomButton from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import clsx from "clsx";
import "./ModalMergeClass.scss";

const ModalMergeClass = (props) => {
    const { visible = false, onOk = () => {}, onCancel = () => {}, page, ...rest } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const isCalledApi = useRef({
        listOfficialClass: false,
        listIntendedClass: false,
    });

    const [values, setValues] = useValues({
        listOfficialClass: [],
        selectedOfficialClass: [],
        listIntendedClass: [],
        selectedIntendedClass: [],
        loadingForm: false,
    });

    const handleSubmitForm = (data) => {
        setValues({ loadingForm: true });
        mergeClass(data?.class_id, data?.class_ids)
            .then(({ message, status }) => {
                if (!status) {
                    notification.error({ message });
                    return;
                } else {
                    notification.success({ message: t("message.update_success") });
                    setValues({ selectedIntendedClass: [], selectedOfficialClass: [] });
                    form.resetFields();
                    onCancel();
                }
            })
            .catch(({ message }) => {
                notification.error({ message });
                return;
            })
            .finally(() => {
                setValues({ loadingForm: false });
            });
    };

    const handleCancelForm = () => {
        setValues({
            selectedIntendedClass: [],
            selectedOfficialClass: [],
        });
        form.resetFields();
        onCancel();
    };

    const renderClassItems = (items, className) => {
        return (
            <div className={`list-class-items ${className || ""}`}>
                {items?.map((item, idx) => {
                    if (!item) return;
                    return (
                        <Form.Item
                            key={idx}
                            label={t("shared.confirm")}
                            className={clsx({
                                "hide-label info-confirmation-wrapper": true,
                                hightlight: idx % 2 === 0,
                            })}
                        >
                            <Row className="info-confirmation-box">
                                <Col xs={24} sm={8} xl={8} className="label">
                                    {t("class.name")}:
                                </Col>
                                <Col xs={24} sm={16} xl={16}>
                                    {item?.name}
                                </Col>
                                <Col xs={24} sm={8} xl={8} className="label">
                                    {t("class.begin_date")}:
                                </Col>
                                <Col xs={24} sm={16} xl={16}>
                                    {item?.begin_date ? moment(item.begin_date).format("DD/MM/YYYY") : "_"}
                                </Col>
                                <Col xs={24} sm={8} xl={8} className="label">
                                    {t("class.end_date")}:
                                </Col>
                                <Col xs={24} sm={16} xl={16}>
                                    {item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : "_"}
                                </Col>
                                <Col xs={24} sm={8} xl={8} className="label">
                                    {t("class.teacher")}:
                                </Col>
                                <Col xs={24} sm={16} xl={16}>
                                    <div className="teacher-list">
                                        {item.teachers?.map((item, itemIndex) => {
                                            return (
                                                <span key={`class-teacher-${itemIndex}`}>
                                                    {itemIndex > 0 ? ", " : ""}
                                                    <span>{item.name}</span>
                                                </span>
                                            );
                                        })}
                                        {/* {typeof item.owner === "string" ? (
                                            <span key={`class-teacher-owner`}>
                                                <span>{item.owner || "_"}</span>
                                            </span>
                                        ) : item.owner?.name ? (
                                            <span key={`class-teacher-owner`}>
                                                <span>{item.owner.name || "_"}</span>
                                            </span>
                                        ) : null} */}
                                    </div>
                                </Col>
                            </Row>
                        </Form.Item>
                    );
                })}
            </div>
        );
    };

    useEffect(() => {
        if (visible) {
            if (!isCalledApi.current.listIntendedClass) {
                fetchClasses({ noPagination: 1, is_official: 0 }).then(({ status, data, message }) => {
                    if (status) {
                        isCalledApi.current.listIntendedClass = true;
                        setValues({
                            listIntendedClass: data,
                        });
                    }
                });
            }
            if (!isCalledApi.current.listOfficialClass) {
                fetchClasses({ noPagination: 1, is_official: 1 }).then(({ status, data, message }) => {
                    if (status) {
                        isCalledApi.current.listOfficialClass = true;
                        setValues({
                            listOfficialClass: data,
                        });
                    }
                });
            }
        }
    }, [visible]);

    const handleChangeOfficalClass = (value, fullValue) => {
        setValues({
            selectedOfficialClass: fullValue?.data,
        });
    };

    const handleChangeClass = (data, fullData) => {
        setValues({ selectedIntendedClass: fullData?.map((item) => item?.data) });
    };

    return (
        <Modal
            {...rest}
            visible={visible}
            onOk={() => {
                onOk();
            }}
            onCancel={() => {
                onCancel();
                setValues({ selectedIntendedClass: [], selectedOfficialClass: [] });
                form.resetFields();
            }}
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-merge-class`}
            footer={null}
            centered
            title={t("class.merge_class")}
            destroyOnClose
        >
            <Spin spinning={values.loadingForm}>
                <Form form={form} onFinish={handleSubmitForm}>
                    <Form.Item
                        name="class_id"
                        label={t("class.official_class")}
                        rules={[{ required: true, message: t("message.required") }]}
                    >
                        <Select
                            className="app-select show-arrow"
                            placeholder={t("class.find_and_select_class")}
                            showArrow={false}
                            allowClear
                            showSearch
                            // value={data.classSelected?.map((item) => item.id)}
                            onChange={handleChangeOfficalClass}
                            optionFilterProp="children"
                        >
                            {values.listOfficialClass?.map((item, index) => {
                                return (
                                    <Select.Option key={`class${index}`} data={item} value={item.id}>
                                        {`${t(item.name)} (${t("class.code")}: ${t(item.code)})`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    {form.getFieldValue("class_id") && renderClassItems([values.selectedOfficialClass])}
                    {/* --------------------- */}
                    <Form.Item
                        name="class_ids"
                        label={t("class.intended_class")}
                        rules={[{ required: true, message: t("message.required") }]}
                    >
                        <Select
                            mode="multiple"
                            className="app-select show-arrow"
                            placeholder={t("class.find_and_select_class")}
                            showArrow={false}
                            allowClear
                            showSearch
                            // value={data.classSelected?.map((item) => item.id)}
                            onChange={handleChangeClass}
                            optionFilterProp="children"
                        >
                            {values.listIntendedClass?.map((item, index) => {
                                return (
                                    <Select.Option key={`class${index}`} value={item.id} data={item}>
                                        {`${t(item.name)} (${t("class.code")}: ${t(item.code)})`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    {form.getFieldValue("class_id") && renderClassItems(values.selectedIntendedClass, "intend-class")}

                    <div className="btn-group">
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={t("shared.cancel")}
                            onClick={handleCancelForm}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-save" />}
                            title={t("shared.save")}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalMergeClass;
