import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import "./QuestionNumberList.scss";

function QuestionNumberList(props) {
    const {
        type = "default",
        itemType = "button",
        itemConfig = { itemHtmlElementId: "" },
        numberOfQuestions = 0,
        order = -1,
        onChangeSelectedQuestion = () => {},
    } = props;

    const { t } = useTranslation();

    const handleSelectQuestion = (questionIndex) => {
        onChangeSelectedQuestion(questionIndex);
    };

    const IconJumpPrev = (
        <span role="img" aria-label="double-left" className="anticon anticon-double-left ant-pagination-item-link-icon">
            <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="double-left"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
            >
                <path d="M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z"></path>
            </svg>
        </span>
    );

    const IconJumpNext = (
        <span
            role="img"
            aria-label="double-right"
            className="anticon anticon-double-right ant-pagination-item-link-icon"
        >
            <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="double-right"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
            >
                <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z"></path>
            </svg>
        </span>
    );

    return (
        <>
            {type === "default" && (
                <div className="question-number-list">
                    <div className="number-list-title">{t("question.question_number_list")}</div>
                    <div className="number-list-wrapper scroll_grey">
                        {Array(numberOfQuestions)
                            .fill(0)
                            .map((item, index) => {
                                return (
                                    <div className="number-list-itemWrapper" key={index}>
                                        <div
                                            className="number-list-item"
                                            onClick={() => handleSelectQuestion(index + 1)}
                                        >
                                            <span className={`number-btn${index + 1 === order ? " " + "active" : ""}`}>
                                                {index + 1}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}

            {type === "horizontal" && (
                <Pagination
                    className="question-number-list"
                    showSizeChanger={false}
                    defaultCurrent={order}
                    pageSize={1}
                    total={numberOfQuestions}
                    onChange={(val) => {
                        handleSelectQuestion(val);
                    }}
                    itemRender={
                        itemType === "link"
                            ? (page, type, element) => {
                                  if (type === "page") {
                                      return <a href={`#${itemConfig.itemHtmlElementId}${page}`}>{page}</a>;
                                  } else if (type === "jump-prev" || type === "jump-next") {
                                      return (
                                          <a
                                              className="ant-pagination-item-link"
                                              onClick={() =>
                                                  (location.href = `#${itemConfig.itemHtmlElementId}${page}`)
                                              }
                                          >
                                              <div className="ant-pagination-item-container">
                                                  {type === "jump-prev" && IconJumpPrev}
                                                  {type === "jump-next" && IconJumpNext}
                                                  <span className="ant-pagination-item-ellipsis">•••</span>
                                              </div>
                                          </a>
                                      );
                                  } else if (type === "prev" || type === "next") {
                                      return (
                                          <a
                                              onClick={() =>
                                                  (location.href = `#${itemConfig.itemHtmlElementId}${page}`)
                                              }
                                          >
                                              {element}
                                          </a>
                                      );
                                  }
                              }
                            : (page, type, element) => {
                                  return element;
                              }
                    }
                />
            )}
        </>
    );
}

export default QuestionNumberList;
