import api from "..";

// User fields:
const userFields = ["role", "name", "phone", "gender", "active", "username", "avatar"];

/**
 * (VERIFIED) Get user list of a class or more.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchUsers = (fetchParams) => {
    // Filter:
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    [0, 1, null].includes(fetchParams.gender) && filters.push(`gender:${fetchParams.gender}`);
    [true, false].includes(fetchParams.active) && filters.push(`active:${fetchParams.active}`);
    [0, 1].includes(fetchParams.class_user_status)
        ? filters.push(`class_user_status:${fetchParams.class_user_status}`)
        : filters.push(`class_user_status:1`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${[
            "name",
            "phone",
            "username",
            "gender",
            "active",
            "role",
            "used_storage",
            "storage_organization_empty",
            "class_user_status",
        ]}`,
    };
    // Fetch:
    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

// Teachers:
export const fetchTeachers = (fetchParams = {}) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.gender !== undefined && filters.push(`gender:${fetchParams.gender}`);
    fetchParams.active !== undefined && filters.push(`active:${fetchParams.active}`);
    fetchParams.organization_id !== undefined && filters.push(`organization_id:${fetchParams.organization_id}`);
    fetchParams.user_organization !== undefined && filters.push(`user_organization:${fetchParams.user_organization}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${[...userFields, "used_storage", "storage_organization_empty"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };

    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

/**
 * (VERIFIED) Get teacher's student list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const getListStudentOfTeacher = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    // Fetch params:
    const params = {
        // _filter: filters.join(";") || undefined,
        class_id: fetchParams.class_id,
        organization_id: fetchParams.organization_id,
    };
    // Fetch:
    return api
        .get(`/student`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

//Students
export const fetchStudents = (fetchParams) => {
    const filters = ["role:student"];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.class_id && filters.push(`class_id:${fetchParams.class_id}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${[
            "name",
            "first_name",
            "last_name",
            "address",
            "phone",
            "username",
            "gender",
            "active",
            "class_user_status",
        ]}`,
        // Pagination:
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };

    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchTeacherOfStudent = (fetchParams = {}) => {
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);

    return api
        .get("/my_teacher")
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const fetchAllStudents = () => {
    const params = {
        _filter: "role:student",
        _user_fields: `${["name"]}`,
        _pagination: 0,
    };
    return api
        .get("/user", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const findUser = (id) => {
    const params = {
        _user_fields: `${["username", "name", "active"]}`,
    };
    return api
        .get(`/user/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateUser = (id, data) => {
    return api
        .put(`/user/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createUser = (data) => {
    return api
        .post("/user", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const createMultiUser = (data, role) => {
    return api
        .post(`/manage/${role}/import`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const exportUsers = (role) => {
    return api
        .get(`/manage/${role}/export`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteUser = (id) => {
    return api
        .delete(`/user/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const changePasswordUser = (id, data) => {
    return api
        .post("/user/change_password", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const skipHelpModal = () => {
    return api
        .put("/skip", { _user_fields: "is_new_user" })
        .then(({ status, data }) => {
            return { status, is_new_user: data?.is_new_user };
        })
        .catch(({ message }) => ({ status: false, message }));
};
