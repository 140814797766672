import { Divider, Input, InputNumber } from "antd";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContent from "src/modules/components/QuestionContent";
import "./QuestionResultPDF.scss";
import { useValues } from "src/hooks";

/**
 *
 * @questions {[]}
 * @type {string} : "preview" | "review"
 * @returns
 */
function QuestionResultPDF({ questions = [], typeViewScore, isMarking, markedQuestions, markingQuestion, mode }) {
    return (
        <div className="question-result-panel question-result-pdf">
            {questions?.map((item) => (
                <ResultItem
                    key={item.id}
                    typeViewScore={typeViewScore}
                    isMarking={isMarking}
                    markedQuestions={markedQuestions}
                    markingQuestion={markingQuestion}
                    currentQuestion={item}
                />
            ))}
        </div>
    );
}

function ResultItem({
    mode = "",
    isMarking,
    markedQuestions,
    markingQuestion,
    currentQuestion = {},
    order,
    typeViewScore, // "preview" or "review"
}) {
    const { t } = useTranslation();
    const [note, setNote] = useState("");

    const questionInfo = useMemo(() => {
        const currQuestion = currentQuestion || {};

        const generalProps = {
            ...currQuestion,
        };

        if (isMarking) {
            generalProps.score_teacher_submit = currQuestion?.score_teacher_submit;
            generalProps.note = currQuestion?.note;
            generalProps.score_teacher_marking =
                markedQuestions[currQuestion.id]?.score ?? currQuestion?.score_teacher_submit;

            return generalProps;
        }

        return generalProps;
    }, [order, markedQuestions, currentQuestion]);

    useEffect(() => {
        if (isMarking) {
            setNote(markedQuestions[questionInfo.id]?.note || questionInfo.note);
        } else {
            setNote(questionInfo.note);
        }
    }, [order]);

    const handleWriteComment = (e) => {
        const note = e.target.value;

        if (isMarking) {
            markingQuestion(questionInfo.id, {
                note,
                oldScore: questionInfo?.score_teacher_submit ?? questionInfo?.score_submit,
            });
            setNote(note);
        }
    };

    const handleMarkWriting = (score) => {
        if (!score) score = 0;

        if (isMarking) {
            markingQuestion(questionInfo.id, {
                score,
                oldScore: questionInfo?.score_teacher_submit ?? questionInfo?.score_submit,
            });
        }
    };

    return (
        <div className="question-result-item" id={questionInfo?.id}>
            <div className="question-number-wrapper">
                <div className="question-number">
                    {questionInfo?.order ? `${questionInfo?.order < 10 ? "0" : ""}${questionInfo?.order}` : "00"}
                </div>
            </div>
            <QuestionContent
                questionInfo={questionInfo}
                handleMarkWritingInPassage={() => {}}
                showQuestionNumber
                questionNumber={questionInfo?.order}
                styleShowQuestion="circle"
                isMarking={isMarking}
                type={typeViewScore}
            />
            {mode !== "statistical" && (
                <>
                    {isMarking ? (
                        <div className="teacher-mark">
                            <strong>{t("shared.score")}:</strong>
                            <InputNumber
                                className="app-input"
                                value={
                                    questionInfo.score_teacher_marking ??
                                    questionInfo.score_teacher_submit ??
                                    questionInfo.score_submit
                                }
                                onChange={handleMarkWriting}
                                min={0}
                                max={questionInfo.score}
                            />
                            <span>
                                /{questionInfo?.score}
                                {questionInfo?.score_teacher_submit !== questionInfo?.score_submit &&
                                    ` - ${t("scored_manually")}`}
                            </span>
                        </div>
                    ) : (
                        <>
                            {!isMarking && (
                                <div
                                    className={clsx(
                                        "student-view-mark",
                                        (questionInfo.score_teacher_submit ?? questionInfo.score_submit) && "correct"
                                    )}
                                >
                                    <strong>{t("shared.score")}:</strong>
                                    <span>
                                        {questionInfo.score_teacher_submit ?? questionInfo?.score_submit ?? 0}/
                                        {questionInfo?.score || 0}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    <div className="question-comment">
                        <div className="question-comment-title">{t("exam_result.teacher_comment")}</div>
                        <div className={clsx("question-comment-content", isMarking && "role-teacher")}>
                            <Input.TextArea
                                value={note}
                                onChange={(e) => handleWriteComment(e)}
                                rows={4}
                                placeholder={
                                    !isMarking ? t("exam_result.no_comment") : `${t("exam_result.teacher_comment")}...`
                                }
                                maxLength={500}
                                style={{ resize: "none" }}
                                readOnly={!isMarking}
                            />
                        </div>
                    </div>
                </>
            )}
            <Divider />
        </div>
    );
}
export default QuestionResultPDF;
