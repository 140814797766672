import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { skipHelpModal } from "src/api/containers/user";
import { t } from "i18next";
import EmptyImg from "src/assets/images/Empty_1.png";
import { useSelector } from "react-redux";
import { fetchGuides } from "src/api/containers/guide";
import HelpMenuLayout from "../HelpMenuLayout";
import { useValues } from "src/hooks";
import "./HelpModal.scss";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";

const HelpModal = ({ visible = true, className, page, onChangeVisible, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    const lang = useSelector((state) => state.general.lang);

    const [loading, setLoading] = useState(false);
    const [modalValues, setModalValues] = useValues({
        helpData: [],
        current_page: "",
        isReOpenModal: false,
    });

    const handleSubmit = () => {
        onChangeVisible(false);
    };

    const handleCancel = () => {
        onChangeVisible(false);
        if (user?.is_new_user !== false) {
            skipHelpModal().then(({ is_new_user }) => {
                // if (is_new_user) {
                //     consoloe.log(is_new_user);
                // }
            });
        }
    };

    useEffect(() => {
        setModalValues({
            helpData: [],
            current_page: "",
            isReOpenModal: false,
        });
    }, [page]);

    useEffect(() => {
        if (visible && modalValues?.current_page == page && !modalValues.isReOpenModal) {
            setModalValues({ isReOpenModal: true });
        }

        if (visible && modalValues?.current_page != page) {
            setLoading(true);
            fetchGuides({ type: page, lang: lang }).then((res) => {
                const { data, status } = res;
                if (status) {
                    setModalValues({ helpData: data, current_page: page });
                }
                setLoading(false);
            });
        }
    }, [visible]);

    return (
        <Modal
            centered
            visible={visible}
            title={t("help.help")}
            closeIcon={<SvgClose />}
            footer={null}
            // className="help-modal"
            className={"app-modal type-primary modal-helper" + (className ? ` ${className}` : "")}
            wrapClassName="modal-helper-wrap"
            onOk={handleSubmit}
            onCancel={handleCancel}
            {...rest}
        >
            <Spin spinning={loading}>
                <div className="modal-wraper">
                    {modalValues?.helpData?.length > 0 ? (
                        <div className="data-wrapper">
                            <HelpMenuLayout
                                data={modalValues.helpData}
                                isReOpenModal={modalValues.isReOpenModal}
                                visible={visible}
                            />
                        </div>
                    ) : (
                        <div className="no-data-wrapper">
                            <div className="no-data">
                                <img src={EmptyImg} />
                                <div>{t("help.nodata")}</div>
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
        </Modal>
    );
};

export default HelpModal;
