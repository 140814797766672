import React, { useContext, useEffect } from "react";
import ThumbnailsItem from "../ThumbnailsItem";

import clsx from "clsx";
import "./Thumbnails.scss";
import { DoubleLeftOutlined, DoubleRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useValues } from "src/hooks";

function Thumbnails({
    testData = [],
    toggleThumbnails,
    isShowThumbnails = false,
    currentThumbnail = {},
    setCurrentThumbnails = () => {},
    toggleRef,
}) {
    const [values, setValues] = useValues({
        isShow: true,
    });

    // function toggleThumbnails() {
    //     setValues({ isShow: !values.isShow });
    // }
    // useEffect(() => {
    //     if (toggleRef?.current) {
    //         toggleRef.current = toggleThumbnails;
    //     }
    // }, [toggleRef?.current]);
    return (
        <div className={clsx("pdf-thumbnails test", { show: isShowThumbnails })}>
            <div className="thumbnails-list">
                {testData?.map((item, idx) => (
                    <ThumbnailsItem
                        key={item.file_id}
                        data={item}
                        page={idx + 1}
                        currentThumbnail={currentThumbnail}
                        onClick={() => setCurrentThumbnails?.(item)}
                    />
                ))}
            </div>
            {/* <span className="toggle-btn" onClick={toggleThumbnails}>
                {values.isShow ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </span> */}
        </div>
    );
}

export default Thumbnails;
