import { createSlice } from "@reduxjs/toolkit";
import { cancelUsingDemoAccount } from "src/api/helpers/demoAccount";
import { clearSelectedUserScope, getCurrentTeamsOrOganization } from "src/api/helpers/userScope";
import { deleteCookie } from "src/utils/helpers";

const initialState = {
    user: null,
    accountType: localStorage.getItem("currentAccount") || null, // null || "demo".
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            // 1. User data:
            if (action.payload) {
                const t_org = getCurrentTeamsOrOganization(action.payload);
                if (t_org) {
                    state.user = { ...action.payload, ...t_org };
                } else {
                    state.user = action.payload;
                }
            }
            localStorage.setItem("isLogged", "1");
            // 2. Account type:
            state.accountType = localStorage.getItem("currentAccount") || null;
        },
        logout: (state, action) => {
            // 1. Reset user data:
            state.user = null;
            state.accountType = null;
            localStorage.removeItem("token");
            localStorage.setItem("isLogged", "0");
            deleteCookie("token");
            // 2. Clear things:
            clearSelectedUserScope();
            // 3. Check action:
            if (action.payload?.isChangingAccType) {
                // If the user is changing account type,
                // it means that he/she is logging out his/her account to change to main/demo account.
                switch (action.payload.isChangingAccType) {
                    case "demo": {
                        const { user, token } = action.payload;
                        // Store the current account before logging with demo account:
                        localStorage.setItem("previousAccount", JSON.stringify({ user, token }));
                        // Set up an action to be handled after the user is logged out:
                        localStorage.setItem("nextAction", JSON.stringify({ action: "login-demo-account" }));
                        break;
                    }
                    case "main":
                    default: {
                        // Set up an action to be handled after the user is logged out:
                        localStorage.setItem("nextAction", JSON.stringify({ action: "login-main-account" }));
                        break;
                    }
                }
            } else {
                // If current account is "demo", reset to "main":
                cancelUsingDemoAccount();
            }
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
