import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/components/Header";
import OrganizationUserList from "./components/OrganizationUserList";
import { useTranslation } from "react-i18next";
import { setCurrentPage } from "src/reducers/general";
import "./Organization.scss";

function Organization(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("organization"));
    }, []);

    return (
        <div className="organization-wrapper">
            <Header backEnable={false} backTitle={t("sidebar.manage_capacity")} actions={[]} />
            <OrganizationUserList />
        </div>
    );
}

export default Organization;
