import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, notification } from "antd";
import Icon from "src/modules/components/Icon";
import actionClose from "src/assets/images/action-close.svg";
import { default as CustomButton } from "src/modules/components/Button";
import { fetchTeachers } from "src/api/containers/user";
import { inviteUserToOrganization } from "src/api/containers/organization";
import { useSelector } from "react-redux";

const ModalAddUser = (props) => {
    const { organization = {}, modalClassName = "", onOk = () => {}, onCancel = () => {}, ...rest } = props;

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleOk = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const findUserByEmail = async (userEmail) => {
        const r = await new Promise((resolve, reject) => {
            fetchTeachers({ slug: userEmail }).then((res) => {
                if (res.status === true && res.data?.length === 1) {
                    resolve(res.data[0]);
                } else {
                    reject(null);
                }
            });
        });
        return r;
    };

    const handleCreateInvitation = async () => {
        const formData = form.getFieldsValue();
        if (!formData.user_email) {
            return;
        }
        // Generate submit values:
        // const teacherData = await findUserByEmail(formData.user_email);
        const submitParams = {
            email: formData.user_email,
            organization_id: organization?.organization_id,
        };
        // Make api calls:
        inviteUserToOrganization(submitParams).then((res) => {
            if (res.status) {
                notification.success({
                    message: t("organization.send_invitation_success"),
                });
                form.resetFields();
                handleOk();
            } else {
                notification.error({
                    message: res.message || t("organization.send_invitation_error"),
                });
            }
        });
    };

    return (
        <Modal
            centered
            footer={null}
            title={t("organization.add_user")}
            closeIcon={<img src={actionClose}></img>}
            onOk={handleOk}
            onCancel={handleCancel}
            className={`app-modal type-basic flexible-height maxw-520${modalClassName ? " " + modalClassName : ""}`}
            {...rest}
        >
            <Form form={form} onFinish={handleCreateInvitation}>
                <Form.Item
                    name="user_email"
                    label={t("organization.user_email")}
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                        {
                            type: "email",
                            message: t("message.invalid_email"),
                        },
                    ]}
                >
                    <Input className="app-input" placeholder={t("organization.user_email")} />
                </Form.Item>

                <div className="btn-group">
                    <CustomButton
                        type="grey"
                        icon={<Icon name="icon-cross-thick" />}
                        title={t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<Icon name="icon-tick" />}
                        title={t("organization.send_invitation")}
                    ></CustomButton>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalAddUser;
