import api from "..";

// Question fields:
const questionFields = [
    "is_publish",
    "is_public",
    "user_id",
    "detail_id",
    "detail",
    "team_id",
    "organization_id",
    "hidden_file_id",
];
// Question detail fields - general:
const questionDetailFields = ["type", "category_id", "tag_id", "question", "score"];
// Question detail fields - types:
// const qWriting = ["word_limit"];
// const qWritingShort = ["word_limit"];
// const qMultipleChoice = ["answer", "is_multiple"];
// const qMultipleChoiceBool = ["answer"];
// const qMultipleChoiceTable = ["answer1", "answer2", "match"];
// const qSort = ["answer"];
// const qImgLblsDragDrop = ["file_id", "src", "coordinates", "width", "height", "answer", "answer1"];
// const qImgLblsDropdown = ["file_id", "src", "coordinates", "width", "height", "answer", "answer1"];
// const qImgLblsText = ["file_id", "src", "coordinates", "width", "height", "answer", "is_require_uppercase"];
// const qHighlight = ["passage", "passage_student", "answer"];
// const qHighlightImage = ["file_id", "src", "coordinates", "width", "height", "answer"];
// const qFillBlanksInput = ["answer", "is_require_uppercase"];
// const qFillBlanksSelect = ["answer", "answer1"];
// const qFillBlanksDragdrop = ["answer1"];
// const qDragDrop = ["answer1", "answer2"];
// const qDragDropGroup = ["answer1", "answer2", "match"];
// const qDragDropGroupOrder = ["answer1", "answer2", "match"];
// const qHotspot = ["file_id", "src", "coordinates", "width", "height", "answer", "answer1", "is_multiple"];
// Reaction fields:
const questionReactionFields = ["reaction_count", "clone_count", "is_reacted"];

/**
 * (VERIFIED) List of question types.
 * Notes:
 * - Question types and keys are used to specify a question type! But:
 *   + types are used for fetch params.
 *   + keys are used for url params.
 *   + isScoring are used for render show answer button in detail
 *   + group are used for group question in modalSelectQuestionType
 *         includes: writing, multiplechoice, fill in the blanks, highlight
 * - Each question has its own fetch params.
 * - Others:
 *   + isScoring.
 */

export const questionTypes = {
    writing: {
        key: "writing",
        isScoring: true,
    },
    writing_short: {
        key: "writing-short",
        isScoring: true,
    },

    multiple_choice: {
        key: "multiple-choice",
    },

    multiple_choice_sentence_quiz: {
        key: "multiple-choice-sentence-quiz",
    },
    multiple_choice_boolean: {
        key: "multiple-choice-boolean",
    },

    multiple_choice_table: {
        key: "multiple-choice-table",
    },

    sort: {
        key: "sort",
    },

    drag_drop: {
        key: "drag-drop",
    },
    drag_drop_group: {
        key: "drag-drop-group",
    },
    drag_drop_group_order_by: {
        key: "drag-drop-group-order-by",
    },

    fill_in_the_blank: {
        key: "fill-in-the-blank",
    },
    fill_in_the_blank_drag_drop: {
        key: "fill-in-the-blank-drag-drop",
    },
    fill_in_the_blank_text: {
        key: "fill-in-the-blank-text",
    },

    highlight: {
        key: "highlight",
    },

    // (Image with labels) Điền vào chỗ trống trong ảnh:
    fill_in_the_blank_text_image: {
        key: "imgwithlabels-text",
    },
    // (Image with labels) Dropdown list trong ảnh:
    fill_in_the_blank_image: {
        key: "imgwithlabels-dropdown",
    },
    // (Image with labels) Kéo thả vào hình ảnh:
    fill_in_the_blank_drag_drop_image: {
        key: "imgwithlabels-dragdrop",
    },

    // (Graph) Đồ thị:
    draw_graph: {
        key: "graph",
    },
    number_line: {
        key: "number-line",
    },

    // Đánh dấu trong ảnh:
    highlight_image: {
        key: "highlight_image",
        isScoring: true,
    },
    // Hotspot
    hot_spot: {
        key: "hotspot",
        // isScoring: true,
    },

    matrix: {
        key: "math-matrix",
    },

    fill_in_the_blank_latex: {
        key: "fill-in-the-blank-latex",
    },

    // math_fill_expression: {
    //     key: "fill-expression",
    // },

    // complete_the_equation: {
    //     key: "complete-the-equation",
    // },

    // Math - Text and dropdown:
    convert_measurement: {
        key: "math-text-dropdown",
    },

    // Đoạn văn:
    passage: {
        key: "passage",
        // isScoring: true,
    },

    // Tô màu ô vuông:
    highlight_square: {
        key: "highlight_square",
    },

    //speech to text:
    speech_to_text: {
        key: "speech-to-text",
        isScoring: true,
    },
};

export const questionTypeGroups = {
    writing: ["writing", "writing_short"],
    multiple_choice: [
        "multiple_choice",
        "multiple_choice_sentence_quiz",
        "multiple_choice_boolean",
        "multiple_choice_table",
    ],
    passage: ["passage"],
    drag_drop: ["sort", "drag_drop", "drag_drop_group", "drag_drop_group_order_by"],
    fill_in_the_blank: [
        "fill_in_the_blank",
        "fill_in_the_blank_text",
        "fill_in_the_blank_drag_drop",
        "fill_in_the_blank_drag_drop_image",
        "fill_in_the_blank_image",
        "fill_in_the_blank_text_image",
    ],
    highlight: ["highlight", "highlight_image", "hot_spot", "highlight_square"],
};

/**
 * (VERIFIED) Get fields by question type.
 * @param {string} _questionType
 * @returns Array of strings.
 */
const getQuestionDetailFieldsByType = (_questionType) => {
    return questionTypes[_questionType]?.fetchParams || [];
};

/**
 * (VERIFIED) Get question detail.
 * @param {string} id Question id.
 * @param {Array} relations Question more info.
 * @returns Object.
 */
export const findQuestion = (id, qType, fetchParams = {}, relations = ["tags", "category"]) => {
    // Filter:
    const filters = [];
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    // Fetch params:
    const params = {
        _filter: filters.join(";") || undefined,
        _question_fields: `${questionFields} `,
        _question_detail_fields: relations.includes("detail")
            ? `${[...questionDetailFields, ...getQuestionDetailFieldsByType(qType)]}`
            : "",
        // Other fields:
        _relations: relations.join(","),
        _tag_fields: relations.includes("tags") ? `${["name", "owner_id"]}` : "",
        _category_fields: relations.includes("category") ? `${["name"]}` : "",
    };
    // Fetch:
    return api
        .get(`/question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Get question detail of passage question's child.
 * @param {string} id Question id.
 * @param {Array} relations Question more info.
 * @returns Object.
 */
export const findChildQuestion = (id, relations = []) => {
    const params = {
        _relations: relations.join(","),
        _question_detail_fields: `${[
            "type",
            "question",
            "score",
            "answer",
            "answer1",
            "answer2",
            "match",
            "word_limit",
            "is_multiple",
            "is_require_uppercase",
            "file",
            "coordinates",
            "width",
            "height",
            "text",
            "passage",
            "passage_student",
            "question_parent_id",
            "file_id",
            "path",
            "shaded",
            "columnCount",
            "rowCount",
            "by_location",
            "lock_cell",
            "speaker",
            "spelling",
            "language",
            "template_latex",
        ]}`,
    };
    return api
        .get(`/question_detail/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Get question list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchQuestionList = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.owner_id && filters.push(`user_id:${fetchParams.owner_id}`);
    fetchParams.category_id && filters.push(`category_id:${fetchParams.category_id}`);
    fetchParams.tag_id && filters.push(`tag_id:${fetchParams.tag_id}`);
    fetchParams.tag_name && filters.push(`tag_name:${fetchParams.tag_name}`);
    fetchParams.type && filters.push(`type:${fetchParams.type}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _question_fields: `${[...questionFields, ...questionReactionFields]}`,
        // Other fields:
        _relations: `${["tags", "category", "user"]}`,
        _tag_fields: `${["name", "color"]} `,
        _category_fields: `${["name"]} `,
        _user_fields: `${["name"]} `,
        // Pagination:
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/question", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const createQuestion = (data) => {
    return api
        .post("/question", data)
        .then(({ status, id, message }) => ({ status, id, message }))
        .catch((err) => ({ status: false }));
};

export const updateQuestion = (id, data) => {
    return api
        .put(`/question/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * (VERIFIED) Delete a question.
 * @param {string} id Question id.
 * @param {string} scopeId Question scope id.
 * @returns Object.
 */
export const deleteQuestion = (id, scopeId) => {
    if (!id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }
    const url = scopeId ? `/question/${id}?organization_id=${scopeId}` : `/question/${id}`;
    return api
        .delete(url)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const cloneQuestion = (id, data) => {
    return api
        .post(`/question/clone_question/${id}`, data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * ****************************************************************************************************
 */

/**
 * (VERIFIED) Get question detail of passage question - parent.
 * @param {string} id Question id.
 * @returns Object.
 */
export const findPassageQuestion = (id, fetchParams = {}) => {
    const filters = [];
    fetchParams.organization_id && filters.push(`organization_id:${fetchParams.organization_id}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _relations: "tags,category",
        _question_fields: `${questionFields} `,
        _tag_fields: `${["name", "owner_id"]}`,
        _category_fields: `${["name"]}`,
    };
    return api
        .get(`/question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

export const addChildPassageForPassageQuestion = (data) => {
    return api
        .post("/question_paragraph", data)
        .then(({ status, id }) => ({ status, id }))
        .catch(({ message }) => ({ status: false, message }));
};

export const addChildQuestionForPassageQuestion = (data) => {
    return api
        .post("/question_detail", data)
        .then(({ status, id }) => ({ status, id }))
        .catch(({ message }) => ({ status: false, message }));
};

export const findChildPassage = (id, relations = []) => {
    const params = {
        _relations: relations.join(","),
        _question_passage_paragraph_fields: `${["heading", "title", "content"]} `,
    };
    return api
        .get(`/question_paragraph/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateChildPassage = (id, data) => {
    return api
        .put(`/question_paragraph/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const updateChildQuestion = (id, data) => {
    return api
        .put(`/question_detail/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteChildPassage = (id) => {
    return api
        .delete(`/question_paragraph/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const deleteChildQuestion = (id) => {
    return api
        .delete(`/question_detail/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const quickAddOrDeleteQuestionToPassage = (id, data, type) => {
    return api
        .put(`/question_paragraph/${id}/sync_questions`, { type, question_detail_ids: data })
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export { getQuestionDetailFieldsByType };
