import React, { useEffect, useMemo, useRef } from "react";
import { Col, Form, Input, InputNumber, notification, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Editor from "src/modules/components/Editor";
import configEditor from "src/utils/configEditor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import "./SpeechToText.scss";
import { fetchSpeaker, fetchLanguages } from "src/api/containers/language";
import { useValues } from "src/hooks";
const { TextArea } = Input;
import chineseToPinyin from "chinese-to-pinyin";
import { toRomaji } from "wanakana";
import { convert as hangulRomanization } from "hangul-romanization";

const listSpecialLangs = ["CN", "TH", "KR", "JP"];

const DetailSpeechToText = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const editorConfigQuestion = useRef(configEditor.question());

    const { t } = useTranslation();
    const [detailData, setDetailData] = useValues({
        questionData: undefined,
        questionFormData: undefined,
        listSpeaker: [],
        listLang: [],
        speaker: "",
        lang: "",
    });

    const hasSpeakers = useMemo(() => {
        return detailData.listSpeaker?.length;
    }, [detailData.listSpeaker]);

    const converToLatin = (text, lang) => {
        switch (lang) {
            case "CN":
                return chineseToPinyin(text);
            case "JP":
                return toRomaji(text);
            case "KR":
                return hangulRomanization(text);
        }
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const answData = Array.isArray(_formData.answer) ? _formData.answer : [_formData.answer];
            const _questionData = {
                type: "speech_to_text",
                speaker: detailData.listSpeaker?.length > 1 ? _formData.speaker : detailData.listSpeaker?.[0],
                is_sentence: _formData.is_sentence,
                answer: Array.isArray(_formData.answer) ? _formData.answer : [_formData.answer],
                language_code: _formData.language_code,
                spelling: listSpecialLangs.includes(detailData.lang)
                    ? converToLatin(answData?.[0], detailData.lang)
                    : "",
            };
            const _questionDataPreview = {
                // Basic fields:
                is_sentence: _formData.is_sentence,
                question: _formData.question,
                answer: Array.isArray(_formData.answer) ? _formData.answer : [_formData.answer],
                // speaker: [_formData.speaker],
                speaker: detailData.listSpeaker?.length > 1 ? [_formData.speaker] : detailData.listSpeaker,
                language: detailData?.questionData?.detail?.language,
                language_code: detailData.language_code,
                type: _questionData.type,
                spelling: _questionData.spelling,
                mode: true,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                const validateActions = ["go-preview", "save-child", "save"];
                if (validateActions.includes(formAction)) {
                    // Custom validate answer is never duplicate
                    const correctAnswer = form.getFieldValue("answer");
                    const lang = form.getFieldValue("language_code");

                    if (!correctAnswer) {
                        notification.warning({
                            message: t("q.please_input_answer"),
                        });

                        return;
                    }

                    if (!lang) {
                        notification.warning({
                            message: t("q.please_select_language"),
                        });

                        return;
                    }
                }
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const handleChangeAnswer = (value) => {
        const _formData = form.getFieldsValue();
        if (_formData.is_sentence === false) {
            const ans = _formData.answer;
            _formData.answer = [ans?.split(" ")?.[0]];
            form.setFieldsValue(_formData);
        }
    };

    const getSpeakerOptions = (speaker) => {
        if (Array.isArray(speaker) && speaker.length > 1) {
            return speaker.map((item) => ({ label: item?.[1], value: item?.[0] }));
        }
        return speaker;
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const detail = res?.data?.detail;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                category_id: qData?.category?.id || undefined,

                                question: detail?.question || "",
                                score: detail?.score || 0,
                                // Special fields: "speaker","is_sentence","language_code"
                                speaker: detail?.language?.speaker,
                                language_code: detail?.language?.code,
                                is_sentence: detail?.is_sentence,
                                answer: detail?.answer?.[0] || "",
                            };

                            fetchSpeaker({ code: detail?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });

                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const questionFormData = {
                                question: res[1].data?.question || "",
                                score: res[1].data?.score || 0,
                                // Special fields: "speaker".""
                                speaker: res[1].data?.language?.speaker || "",
                                language_code: res[1].data?.language?.code || "",
                                is_sentence: res[1].data?.is_sentence,
                                answer: res[1]?.data?.answer?.[0] || "",
                            };

                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });

                            fetchSpeaker({ code: res[1]?.data?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    useEffect(() => {
        if (detailData.lang) {
            fetchSpeaker({ code: detailData.lang }).then(({ status, data }) => {
                if (data?.length > 1) {
                    const newdata = data.map((item) => {
                        return { label: item?.[1], value: item?.[0] };
                    });
                    setDetailData({
                        listSpeaker: newdata,
                    });
                } else {
                    setDetailData({
                        listSpeaker: data,
                    });
                }
            });
        }
    }, [detailData.lang]);

    useEffect(() => {
        fetchLanguages({}).then((res) => {
            const { status, data } = res;
            if (status) {
                const newData = data.map((item) => ({ label: item.name, value: item.code }));
                setDetailData({
                    listLang: newData,
                });
            }
        });
    }, []);

    return (
        <div className="detail_speech_to_text">
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion.current}></Editor>
                </Form.Item>
            </Row>

            <Row gutter={[16, 16]} className="question-formitems set-input-numbers top-bar-setting">
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                        labelCol={{ xs: 3, sm: 5, lg: 4 }}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="language_code"
                        label={t("speech_to_text.language")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                        // className="speech_to_text_select "
                        className="app-select set-tags scroll_thin_grey"
                        labelCol={{ xs: 3, sm: 5, lg: 5, xl: 5, xxl: 3 }}
                    >
                        <Select
                            options={detailData.listLang}
                            className="app-select show-arrow has-rd"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? "").includes(input)}
                            onChange={(v) => {
                                setDetailData({
                                    lang: v,
                                    speaker: [],
                                });
                                form.setFieldsValue({
                                    speaker: "",
                                });
                            }}
                        ></Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="question-formitems set-input-numbers top-bar-setting">
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="is_sentence"
                        label={t("shared.type")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                        initialValue={true}
                        className="speech_to_text_select"
                        labelCol={{ xs: 3, sm: 5, lg: 4 }}
                    >
                        <Select
                            options={[
                                { label: t("speech_to_text.sentence"), value: true },
                                { label: t("speech_to_text.word"), value: false },
                            ]}
                            // style={{ minWidth: 200 }}
                            className="app-select show-arrow has-rd speech-to-text-select"
                            onChange={() => form.setFieldsValue({ answer: "" })}
                        ></Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    {detailData.listSpeaker && detailData.listSpeaker?.length > 1 && (
                        <Form.Item
                            name="speaker"
                            label={t("speech_to_text.speaker")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                            // className="speech_to_text_select"
                            className="app-select set-tags scroll_thin_grey speech-to-text-select"
                            labelCol={{ xs: 3, sm: 5, lg: 5, xl: 5, xxl: 3 }}
                        >
                            <Select
                                options={detailData.listSpeaker}
                                className="app-select show-arrow has-rd"
                                showSearch
                                allowClear
                            ></Select>
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Typography.Title level={5}>{t("question.answer")}</Typography.Title>
                    <Form.Item name={"answer"}>
                        <TextArea
                            className="app-input"
                            onChange={(e) => handleChangeAnswer(e.target.value)}
                            placeholder={t("question_panel.write_here")}
                            autoSize={{ minRows: 4, maxRows: 10 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default DetailSpeechToText;
