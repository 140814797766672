import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/components/Header";
import RoleList from "./components/RoleList";
import { useTranslation } from "react-i18next";
import { setCurrentPage } from "src/reducers/general";
import "./ManageRole.scss";

function ManageRole(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("manage_role"));
    }, []);

    // if (user && user.organization?.is_organization_owner === true) {
    return (
        <div className="organization-wrapper">
            <Header backEnable={false} backTitle={t("header.manage_role")} actions={[]} />
            <RoleList />
        </div>
    );
    // } else {
    //     return null;
    // }
}

export default ManageRole;
