import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, notification, Select, Spin } from "antd";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import { fetchRoles } from "src/api/containers/role";
import { fetchBranches } from "src/api/containers/branch";
import { addUserToOrganization, updateUserInOrganization } from "src/api/containers/organization";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";
// import "./ModalDetailEmployee.scss";
import actionClose from "src/assets/images/action-close.svg";

const ModalDetailEmployee = ({
    modalClassName = "",
    organization_id,
    selectedUser,
    onOk = () => {},
    onCancel = () => {},
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false); // Values: "submitting", true||false.
    const [data, setData] = useState({
        roleList: [],
        branchList: [],
    });

    const { checkPermission, pagesAllow, rolesAllow } = permissionUser;

    const handleOk = () => {
        form.resetFields();
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        form.resetFields();
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmitForm = (formData) => {
        if (!organization_id && loading !== "submitting") {
            return;
        }

        setLoading("submitting");

        // Preparation:
        const { roles, ...rest } = formData;
        const userInfo = {
            ...(selectedUser?.id ? { id: selectedUser.id } : {}),
            role_ids: roles,
            ...rest,
            organization_id: organization_id,
        };

        // Make api calls:
        if (selectedUser?.id) {
            updateUserInOrganization("employee", selectedUser?.id, userInfo).then((res) => {
                setLoading(false);
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        } else {
            addUserToOrganization("employee", userInfo).then((res) => {
                setLoading(false);
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    handleOk();
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const customBranchs = (branches) => {
        const renderActive = (active) => {
            return active ? "" : ` (${t("branch.inactive")})`;
        };
        let result = [];
        if (branches?.length > 0) {
            result = branches.map((item) => {
                const newName = item.name + renderActive(item.active);
                return { ...item, name: newName };
            });
        }
        return result;
    };

    // useEffect(() => {
    //     setLoading(true);
    //     fetchRoles({ noPagination: true }).then((res) => {
    //         setLoading(false);
    //         if (res.status) {
    //             setData({
    //                 ...data,
    //                 roleList: res.data,
    //             });
    //         }
    //     });
    // }, []);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchBranches({ active: 1, noPagination: true, organization_id: organization_id }),
            fetchRoles({ noPagination: true, organization_id: organization_id }),
        ]).then((res) => {
            setLoading(false);
            setData({
                ...data,
                branchList: res[0].data?.length ? res[0].data : [],
                roleList: res[1].data?.length ? res[1].data : [],
            });
        });
    }, []);

    useEffect(() => {
        if (rest.visible && selectedUser && Object.keys(selectedUser)?.length > 0) {
            let newBranchs = [];
            if (selectedUser?.branches?.length > 0) {
                newBranchs = selectedUser.branches.map((item) => {
                    return item.id;
                });
            }

            form.setFieldsValue({
                first_name: selectedUser.first_name,
                last_name: selectedUser.last_name,
                username: selectedUser.username,
                phone: selectedUser.phone,
                address: selectedUser.address,
                gender: selectedUser.gender,
                branch_ids: newBranchs,
                roles: selectedUser.roles?.length ? selectedUser.roles.map((item) => item.id) : undefined,
            });

            fetchBranches({ _noPagination: true }).then((res) => {
                if (res.status) {
                    if (data.branchList?.length <= 0) {
                        setData({
                            ...data,
                            branchList: customBranchs(res.data),
                        });
                    }
                }
            });
        }
    }, [rest.visible, selectedUser]);

    return (
        <Modal
            centered
            footer={null}
            title={selectedUser ? t("organization.update_user") : t("organization.add_user")}
            closeIcon={<img src={actionClose}></img>}
            onOk={handleOk}
            onCancel={handleCancel}
            className={`app-modal type-basic flexible-height${modalClassName ? " " + modalClassName : ""}`}
            {...rest}
        >
            <Spin wrapperClassName="app-spin-nested-loading layout-full" spinning={["submitting"].includes(loading)}>
                <Form form={form} onFinish={handleSubmitForm}>
                    <Form.Item
                        name="first_name"
                        label={t("organization.first_name")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.first_name")} />
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label={t("organization.last_name")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.last_name")} />
                    </Form.Item>

                    <Form.Item
                        name="username"
                        label={t("organization.email")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                            {
                                type: "email",
                                message: t("message.invalid_email"),
                            },
                        ]}
                    >
                        <Input className="app-input" placeholder={t("organization.email")} />
                    </Form.Item>

                    <Form.Item name="phone" label={t("organization.phone")}>
                        <Input className="app-input" placeholder={t("organization.phone")} />
                    </Form.Item>

                    <Form.Item name="address" label={t("organization.address")}>
                        <Input className="app-input" placeholder={t("organization.address")} />
                    </Form.Item>

                    <Form.Item name="gender" label={t("shared.gender")}>
                        <Select
                            className="app-select show-arrow"
                            placeholder={t("profile.select_gender")}
                            showArrow={false}
                        >
                            <Select.Option value={1}>{t("shared.male")}</Select.Option>
                            <Select.Option value={0}>{t("shared.female")}</Select.Option>
                        </Select>
                    </Form.Item>

                    <hr className="hr-between-formitems" />

                    <Can I={rolesAllow.addRolesToUser} page={pagesAllow.role}>
                        <Form.Item
                            name="roles"
                            label={t("manage_role.role")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                        >
                            <Select
                                className="app-select show-arrow"
                                mode="multiple"
                                placeholder={t("manage_role.select_roles")}
                                optionFilterProp="children"
                                showArrow={false}
                                showSearch
                                allowClear
                            >
                                {data.roleList.map((roleItem, roleIndex) => {
                                    return (
                                        <Select.Option key={`role${roleIndex}`} value={roleItem.id}>
                                            {roleItem.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Can>

                    <Form.Item name="branch_ids" label={t("organization.branch")}>
                        <Select
                            className="app-select show-arrow"
                            mode="multiple"
                            placeholder={t("organization.select_branch")}
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            showArrow={false}
                            showSearch
                            allowClear
                        >
                            {data.branchList.length &&
                                data.branchList.map((item) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </Form.Item>

                    <div className="btn-group">
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-cross-thick" />}
                            title={t("shared.cancel")}
                            onClick={handleCancel}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-tick" />}
                            title={t("shared.save")}
                            disabled={loading === "submitting"}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalDetailEmployee;
