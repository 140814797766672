import api from "..";

export const fetchProfile = ({ id }) => {
    return api
        .get(`/profile/${id}`)
        .then(({ status, data, message }) => ({
            status,
            data,
            message,
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

export const updateProfile = ({ id, data }) => {
    return api
        .put(`/profile/${id}`, data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};
