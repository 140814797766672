import { useMemo } from "react";
import { useSelector } from "react-redux";
import { permissionUser } from "src/utils/permission";

// ex: Can I Create class in page Class => <Can I="__create" page="class">{children}</Can>
// extendLogic : is a callback function return boolean value
function Can({ children, I, I_OR, page, andLogic, orLogic, memoDependencies = [] }) {
    const user = useSelector((state) => state.auth.user);
    let dependencies = [user];

    if (Array.isArray(memoDependencies) && memoDependencies.length > 0) {
        dependencies = dependencies.concat(memoDependencies);
    }

    const { pagesAllow, rolesAllow, checkPermission } = permissionUser;

    const reslt = useMemo(() => {
        let can = checkPermission(page, I, user);

        const andLg = typeof andLogic == "function" ? andLogic() : "none";
        const orLg = typeof orLogic == "function" ? orLogic() : "none";

        if (Array.isArray(I_OR) && I_OR.length > 0) {
            can = I_OR.map((item) => checkPermission(page, item, user)).includes(true);
        }

        let result = can;

        if (andLg !== "none") result = can && andLg;

        if (orLg !== "none") result = can || orLg;
        return result;
    }, dependencies);

    return reslt ? children : null;
}

export default Can;
