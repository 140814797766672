import Button from "../Button";
import { _1008, _1009, _1004, _1005, _1015, _1017, _1016, _1020, _1021, _1022 } from "../../icons/tab7";
import { _1006, _1007 } from "../../icons/tab7";

const Tab7 = ({ onClick }) => {
    let datas = [
        { icon: _1008, text: "\\begin{bmatrix}{}\\\\{}\\end{bmatrix}", keys: ["Left", "Left"] },
        { icon: _1009, text: "\\begin{bmatrix}{}&{}\\end{bmatrix}", keys: ["Left", "Left"] },
        {
            icon: _1004,
            text: "\\begin{bmatrix}{}&{}\\\\{}&{}\\end{bmatrix}",
            keys: ["Left", "Left", "Left", "Left"],
        },
        {
            icon: _1005,
            text: "\\begin{bmatrix}{}&{}&{}\\\\{}&{}&{}\\\\{}&{}&{}\\end{bmatrix}",
            keys: ["Left", "Left", "Left", "Up", "Up"],
        },

        { icon: _1015, text: "\\begin{vmatrix}{}&{}\\\\{}&{}\\end{vmatrix}", keys: ["Left", "Left", "Up"] },

        { icon: _1017, text: "\\begin{armatrix}{}\\\\{}\\end{armatrix}", keys: ["Left", "Left"] },
        { icon: _1016, text: "\\begin{almatrix}{}\\\\{}\\end{almatrix}", keys: ["Left", "Left"] },
        // { icon: _1016, text: "\\left\\{\\begin{matrix}1\\\\4\\\\\\end{matrix}\\right.", keys: ["Left", "Left"] },
        { icon: _1020, text: "\\begin{almatrix}{}&{}\\\\{}&{}\\end{almatrix}", keys: ["Left", "Left", "Left", "Left"] },

        { icon: _1021, keys: ["Shift-Spacebar", "Left"] },
        { icon: _1022, keys: ["Shift-Enter", "Left"] },

        // [
        //     { icon: _1006, text: "\\begin{bmatrix}1\\\\4\\\\7\\end{bmatrix}" }, //not edulatic
        //     { icon: _1007, text: "\\text{km}" }, //not edulatic
        // ],
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab7;
