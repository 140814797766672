import { useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Avatar from "../Avatar";
import { scopeKeys, selectUserScope } from "src/api/helpers/userScope";
import { getTargetURLQueryString } from "src/modules/auth/helpers";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import "./UserScopePicker.scss";

const UserScopePicker = ({ className, onChangeUserScope, ...rest }) => {
    const { t } = useTranslation();

    const lang = useSelector((state) => state.general.lang);
    const { user } = useSelector((state) => state.auth);

    const selectedUserScope = JSON.parse(localStorage.getItem("selectedUserScope") || null);
    const [selectedScopeId, setSelectedScopeId] = useState(
        selectedUserScope
            ? selectedUserScope.scopeOptionKey === scopeKeys.personal
                ? scopeKeys.personal
                : selectedUserScope.organization_id
            : undefined
    ); // Values: organization_id || team_id || "personal".

    const handleSelectUserScope = (optionKey, _itemKey, _item) => {
        // 1. Update info:
        selectUserScope(optionKey, _item, user.id);
        setSelectedScopeId(_itemKey);
        if (onChangeUserScope instanceof Function) {
            onChangeUserScope(optionKey, _item, user.id);
        }

        // 2. Reload trang nếu là 1 trong 3 case sau:
        // - Chọn tổ chức (hoặc team) khác với tổ chức (hoặc team) hiện tại.
        // - Chuyển đổi sang team khác team hiện tại.
        // - Chuyển sang personal nếu hiện tại không phải personal.
        if (selectedUserScope?.scopeOptionKey) {
            const { scopeOptionKey, organization_id } = selectedUserScope;
            const condition_1 = scopeOptionKey !== optionKey;
            const condition_2 = scopeOptionKey === optionKey && organization_id && organization_id !== _itemKey;
            const condition_3 = scopeOptionKey === "personal" && optionKey !== "personal";
            if (condition_1 || condition_2 || condition_3) {
                window.location.href = `/${lang}${getTargetURLQueryString()}`;
                return;
            }
        } else {
            window.location.href = `/${lang}${getTargetURLQueryString()}`;
            return;
        }
    };

    const generateMenuItemFor = (optionKey, _itemKey, _item) => {
        let itemKey = "";
        let itemTitle = "";
        let itemLabelName = "";
        let itemImg = "";
        let itemOnClick = () => {};

        if (optionKey === scopeKeys.org) {
            itemKey = _itemKey;
            itemTitle = _item.organization_name;
            itemLabelName = _item.organization_name || "_";
            itemImg = _item.avatar;
            itemOnClick = () => {
                handleSelectUserScope(scopeKeys.org, itemKey, _item);
            };
        } else if (optionKey === scopeKeys.teams) {
            itemKey = _itemKey;
            itemTitle = _item.team_name;
            itemLabelName = _item.team_name || "_";
            itemImg = _item.avatar;
            itemOnClick = () => {
                handleSelectUserScope(scopeKeys.teams, itemKey, _item);
            };
        } else if (optionKey === scopeKeys.personal) {
            itemKey = _itemKey || scopeKeys.personal;
            itemTitle = user.name || t("organization.personal");
            itemLabelName = user.name || t("organization.personal");
            itemImg = user.avatar;
            itemOnClick = () => {
                handleSelectUserScope(scopeKeys.personal, itemKey);
            };
        } else {
            return null;
        }

        return {
            key: itemKey,
            title: itemTitle,
            // className: "",
            label: (
                <div className="option-item-wrapper">
                    <span className="option-item">
                        <span className="option-icon">
                            {itemImg ? (
                                <Avatar className="layout-full" src={itemImg} />
                            ) : (
                                <Avatar className="layout-full">
                                    {itemLabelName.length ? itemLabelName[0].toUpperCase() : <UserOutlined />}
                                </Avatar>
                            )}
                        </span>
                        <span className="option-item-section">
                            <span className="option-name">{itemLabelName}</span>
                            {itemKey === selectedScopeId ? (
                                <span className="option-status">
                                    <SvgTick fill="#129D29" />
                                </span>
                            ) : null}
                        </span>
                    </span>
                </div>
            ),
            onClick: itemOnClick,
        };
    };

    const renderMenuOptionsBy = (optionKey) => {
        switch (optionKey) {
            case scopeKeys.org:
                return [generateMenuItemFor(scopeKeys.org, user.organization?.organization_id, user.organization)];
            case scopeKeys.teams:
                if (user.teams?.length) {
                    return user.teams.map((tItem, tIndex) => {
                        return generateMenuItemFor(scopeKeys.teams, tItem.team_id, tItem);
                    });
                }
                return [];
            case scopeKeys.personal:
                return [generateMenuItemFor(scopeKeys.personal)];
            default:
                return [];
        }
    };

    let extraClassnames = "";
    if (className) {
        className += ` ${className}`;
    }

    return (
        <div className={`user-scope-picker${extraClassnames}`} {...rest}>
            {user.organization?.organization_id ? (
                <div className="options-section">
                    <div className="section-title">{t("organization.your_organization")}</div>
                    <Menu
                        className="section-menu"
                        items={[...renderMenuOptionsBy(scopeKeys.org)]}
                        selectedKeys={[selectedScopeId]}
                    />
                </div>
            ) : null}

            {user.teams?.length ? (
                <div className="options-section">
                    <div className="section-title">{t("organization.your_group")}</div>
                    <Menu
                        className="section-menu"
                        items={[...renderMenuOptionsBy(scopeKeys.teams)]}
                        selectedKeys={[selectedScopeId]}
                    />
                </div>
            ) : null}

            {user.organization?.organization_id || user.teams?.length ? (
                <div className="options-section">
                    <div className="section-title">{t("organization.personal")}</div>
                    <Menu
                        className="section-menu"
                        items={[...renderMenuOptionsBy(scopeKeys.personal)]}
                        selectedKeys={[selectedScopeId]}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UserScopePicker;
