import React, { useEffect } from "react";
import { Button, Form, Input, Modal, notification, Space, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import QrCode from "src/modules/components/QrCode";
import { CopyOutlined } from "@ant-design/icons";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import "./ModalInviteLink.scss";

function ModalInviteLink(props) {
    const { targetId = "", loading = false, visible = false, onOk = () => {}, onCancel = () => {}, ...rest } = props;

    const { t } = useTranslation();

    const [form] = useForm();
    const [values, setValues] = useValues({
        isLoading: false,
        isVisible: false,
    });

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            setValues({
                ...values,
                isVisible: false,
            });
        }
    };

    const handleOk = () => {
        if (onOk) {
            // const currLink = form.getFieldsValue().invite_link;
            if (targetId) {
                navigator.clipboard.writeText(targetId);
                notification.success({
                    message: t("shared.copy_invite_link_success"),
                });
            }
            onOk();
        } else {
            setValues({
                ...values,
                isVisible: false,
            });
        }
    };

    useEffect(() => {
        setValues({
            ...values,
            isLoading: loading,
            isVisible: visible,
        });
        if (visible === true) {
            // form.setFieldsValue({
            //     invite_link: targetId,
            // });
        }
    }, [props]);

    return (
        <Modal
            visible={values.isVisible}
            centered
            footer={null}
            title={t("assignment.invite_link")}
            closeIcon={<SvgCross />}
            onOk={handleOk}
            onCancel={handleCancel}
            {...rest}
            className={`app-modal type-basic flexible-height maxw-360 modal-invlink-asgmt${
                props.className ? " " + props.className : ""
            }`}
        >
            <Spin spinning={values.isLoading}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form}>
                        <QrCode value={targetId} />

                        <div className="invlink-title-wrapper">
                            <span>{t("shared.or")}</span>
                            <span>{t("shared.copy_the_link_below")}</span>
                        </div>

                        <Form.Item name="invite_link">
                            <Space direction="horizontal" style={{ width: "100%" }}>
                                <Input
                                    readOnly
                                    className="app-input has-rd"
                                    placeholder={t("assignment.invite_link")}
                                    value={targetId}
                                ></Input>
                                <Tooltip title={t("shared.copy_invite_link")}>
                                    <CustomButton
                                        htmlType="submit"
                                        type="primary"
                                        icon={<CopyOutlined />}
                                        title={t("shared.copy")}
                                        className="invlink-btn"
                                        onClick={handleOk}
                                    ></CustomButton>
                                </Tooltip>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
}

export default ModalInviteLink;
