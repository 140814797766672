import {
    BarsOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Tooltip } from "antd";
import { data } from "jquery";
import React, { useEffect, useState } from "react";
const { Header, Content, Footer, Sider } = Layout;
import HelpCarousel from "../HelpCarousel";
import HelpIframeEmbedLink from "../HelpIframeEmbedLink";
import "./HelpMenuLayout.scss";
import { t } from "i18next";

const HelpMenuLayout = ({ data = [], isReOpenModal, visible }) => {
    const [slides, setSlides] = useState([]);
    const [slideEmbedLink, setSlideEmbedLink] = useState("");
    const [collapsed, setCollapsed] = useState(false);
    const [title, setTitle] = useState("");

    const handleClickMenuItem = (e) => {
        if (e?.key) {
            const item = data.find((item) => item.id === e.key);
            const childGuide = item?.childGuides;
            const embed_link = item?.embed_link;
            setSlides(childGuide);
            setSlideEmbedLink(embed_link);
            setTitle(item.title);
        }
    };

    useEffect(() => {
        if (data?.length > 0) {
            const childGuide = data[0].childGuides;
            const embed_link = data[0].embed_link;
            setSlides(childGuide);
            setSlideEmbedLink(embed_link);
            setTitle(data[0].title);
        }
    }, []);

    return (
        <Layout className="help-menu-layout">
            <Sider collapsedWidth="46" collapsible collapsed={collapsed}>
                {collapsed && (
                    <div className="icon_list">
                        <Tooltip title={t("help.category")} placement="right" color="#07f">
                            <span onClick={() => setCollapsed(false)}>
                                <BarsOutlined />
                            </span>
                        </Tooltip>
                    </div>
                )}
                <div className="help-menu-title">
                    <span>{t("help.category")}</span>
                </div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={data ? data[0]?.id : "1"}
                    items={data.map((item) => ({
                        key: item.id,
                        label: (
                            <Tooltip title={item.title} placement="right" color="#07f">
                                <div className="sider-menu-title">{item.title}</div>
                            </Tooltip>
                        ),
                    }))}
                    onClick={handleClickMenuItem}
                    expandIcon={<DoubleRightOutlined />}
                />
                <div className="help-menu-bottom" onClick={() => setCollapsed((pre) => !pre)}>
                    {collapsed ? (
                        <DoubleRightOutlined />
                    ) : (
                        <>
                            <DoubleLeftOutlined />
                            <span className="minimize">{t("help.collapse_sidebar")}</span>
                        </>
                    )}
                </div>
            </Sider>
            <Layout className="help-content-wrapper">
                <Layout>
                    <Header>{title}</Header>
                    {slideEmbedLink ? (
                        <Content>
                            <HelpIframeEmbedLink slideEmbedLink={slideEmbedLink} />
                        </Content>
                    ) : (
                        <Content>
                            <HelpCarousel data={slides} isReOpenModal={isReOpenModal} visible={visible} />
                        </Content>
                    )}
                </Layout>
            </Layout>
        </Layout>
    );
};

export default HelpMenuLayout;
