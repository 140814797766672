import React from 'react';

function IconModify(props) {
    const {
        fill = '#000'
    } = props;

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5918 5.44623L13.2243 3.72572L16.2088 6.71055L14.5889 8.40095L11.5918 5.44623ZM10.4444 6.65549L4.77453 12.6312C4.62588 12.7879 4.53194 12.9885 4.50677 13.203L4.15597 16.1919L7.0334 15.9304C7.27367 15.9085 7.49795 15.8005 7.66487 15.6264L13.4357 9.60441L10.4444 6.65549ZM14.1004 2.24477C13.6031 1.74748 12.7937 1.75809 12.3097 2.26823L3.2411 11.826C3.05529 12.0218 2.93787 12.2725 2.9064 12.5406L2.44081 16.5077C2.34826 17.2962 3.00475 17.9701 3.79545 17.8982L7.71215 17.5422C8.01249 17.5149 8.29283 17.3799 8.50149 17.1622L17.6944 7.56922C18.1648 7.07829 18.1566 6.30135 17.6758 5.82052L14.1004 2.24477Z" />
        </svg>
    );
}

export default IconModify;