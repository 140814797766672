import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import QuestionContentDetail from "../ContentDetail";
import ImageLabelsContainer from "./components/ImageLabelsContainer";
import ResultAnswer from "../components/ResultAnswer";

function ImageWithLabels({
    // Question & answers:
    labelType /** @param {string} labelType. Values: "text", "dropdown", "drag&drop". */,
    question = "" /** @param {string} question */,
    answer /** @params {string[]} answer */,
    correct_answer /** @param {string[]} correct_answer */,
    answered /** @param {string[]} answered */,
    coordinates /** @param {Object[]} coordinates. Values: { top: 0, left: 0 } */,
    src /** @param {string} src */,
    width /** @param {Number} width */,
    height /** @param {Number} height */,
}) {
    const { t } = useTranslation();
    const [labelList, setLabelList] = useState([]); // Label & selected answer list.
    const [labelAnswerList, setLabelAnswerList] = useState([]); // Provided answer list.

    const specialClassname = useMemo(() => {
        return (labelType || "").replace("&", "-");
    }, [labelType]);

    /**
     * Side effects:
     */
    useEffect(() => {
        // Preparation:
        const providedCoordinates = coordinates || [];
        // Data:
        const _labelList = [];
        const _labelAnswerList = (answer || []).filter((item) => !!item);
        const _labelAnswerListCorrect = correct_answer || [];
        const _labelAnswerListSelected = answered || [];
        for (let i = 0; i < providedCoordinates.length; i++) {
            _labelList.push({
                left: providedCoordinates[i].x,
                top: providedCoordinates[i].y,
                content: _labelAnswerListSelected[i],
                contentCorrect: _labelAnswerListCorrect[i],
            });
        }
        // Update values:
        setLabelList(_labelList);
        setLabelAnswerList(_labelAnswerList);
    }, [answer, coordinates]);

    const renderProvidedAnswerList = () => {
        switch (labelType) {
            case "text": {
                return null;
            }
            case "dropdown":
            case "drag&drop": {
                if (!labelAnswerList.length) {
                    return null;
                }
                return labelAnswerList.map((_item, _itemIndex) => {
                    return (
                        <div key={`${_itemIndex}`} className="lblanswer-item-wrapper">
                            <span className="lblanswer-item">{_item}</span>
                        </div>
                    );
                });
            }
            default:
                return <Alert type="error" message="Label type is not valid!" showIcon />;
        }
    };

    return (
        <div className={`q-imgwithlbls ${specialClassname}`}>
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            {/* {["dropdown", "drag&drop"].includes(labelType) ? (
                <div className="q-content-section">
                    <div className={`view-lblanswers ${specialClassname}`}>{renderProvidedAnswerList()}</div>
                </div>
            ) : null} */}

            <div className="q-content-section">
                <div className="view-image-with-labels">
                    <ImageLabelsContainer
                        isReadonly={true}
                        src={src}
                        labelType={labelType}
                        labelList={labelList}
                        containerSize={{ width, height }}
                    />
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {labelList.map((item, itemIndex) => {
                        return (
                            <div key={`lbl-${itemIndex}`} className="correct-answer-item">
                                <ResultAnswer letter={itemIndex + 1}>
                                    <span>{item.contentCorrect}</span>
                                </ResultAnswer>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default ImageWithLabels;
