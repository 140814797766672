import { forwardRef, useEffect, useImperativeHandle, useRef, useState, React, useContext } from "react";

//lazy load
import loadable from "@loadable/component";
import ExamPDFContext from "src/modules/contexts/ExamPDFContext";
import { Button } from "antd";
import { LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { useResizeObserver, useValues } from "src/hooks";
const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler/index.js"));

function safeZwibblerLoad(zwibbler, data) {
    // hàm load json data của zwibbler
    try {
        zwibbler.load(data);
    } catch (e) {
        try {
            // eslint-disable-next-line no-control-regex
            zwibbler.load(data.replace(/[^\x00-\x80]/g, ""));
        } catch (e2) {
            /* eslint-disable */
            console.log({ e2 });
        }
    }
}

const StudentPDFPreviewer = ({
    toggleThumbnail,
    isShowThumbnails,
    width,
    currentThumbnails,
    src,
    defaultAnswer,
    isReadonly = false,
}) => {
    const [zwibbler, setZwibbler] = useState();
    const zwibblerEl = useRef(null);
    const [values, setValues] = useValues({ scale: 1, isShowThumbnails: false });
    const { targetRef, dimensions } = useResizeObserver();

    const zwibblerConfig = {
        // Needed to indicate to Zwibbler that this is the main element.
        zwibbler: "",
        "z-controller": "mycontroller",
        background: "transparent",
        // confine: "view",
    };

    useEffect(() => {
        function initZwibbler() {
            if (window.Zwibbler && zwibblerEl.current && !zwibbler) {
                // initialize Zwibbler
                const newZwibbler = window.Zwibbler.create(zwibblerEl.current, {
                    autoPickTool: false,
                    showToolbar: false,
                    showColourPanel: false,
                    showHints: false,
                    scrollbars: false,
                    readOnly: true,
                    setFocus: false, // Zwibbler will be unable to intercept any keyboard commands
                    allowZoom: false,
                    allowTextInShape: false, // Zwibbler to write text inside a closed shapes
                });

                newZwibbler.on("node-clicked", function (node, x, y) {
                    var id = newZwibbler.getNodeProperty(node, "_qId");
                    console.log(newZwibbler.getNodeObject(node));
                    if (id) {
                        function scrollToElement(id) {
                            // Get the target element by its ID
                            var targetElement = document.getElementById(id);
                            console.log(targetElement);

                            // Check if the element exists
                            if (targetElement) {
                                // Scroll to the target element
                                targetElement.scrollIntoView({
                                    behavior: "smooth", // Use 'auto' or 'instant' for different scrolling behavior
                                    block: "start", // Use 'start', 'center', or 'end' to align the element within the viewport
                                });
                            }
                        }
                        scrollToElement(id);
                    }
                });

                //setting
                newZwibbler.setConfig("confine", "view");
                setZwibbler(newZwibbler);
            }
        }

        //chạy interval để check zwibbler đã load xong chưa => mếu xong thì chạy hàm khởi tạo....
        let interval = setInterval(() => {
            if ("Zwibbler" in window) {
                initZwibbler();
                clearInterval(interval);
                // console.count("interval");
            }
        }, 100);
        return () => {
            setZwibbler(null);
        };
    }, []);

    useEffect(() => {
        if (zwibbler && currentThumbnails?.path) {
            safeZwibblerLoad(zwibbler, currentThumbnails.path);
        }
    }, [zwibbler, currentThumbnails]);

    useEffect(() => {
        // handle dimension change such as zoom in/out or window resize
        if (zwibbler) {
            setTimeout(() => {
                zwibbler.resize();
                console.count("123-123");
            }, 500);
        }
    }, [dimensions.width, dimensions.height, zwibbler]);

    function getWidthHeight(width) {
        // const w = width || 600;
        const w = window.document.body.offsetWidth - 760;
        const h = Math.round(width * 1.414 || 600 * 1.414);

        return { width: w, height: h, minWidth: 600, minHeight: 600 * 1.414 };
    }

    const width_height = getWidthHeight(width);

    function handleScale(type) {
        const s = 0.05;
        let scale = values.scale;
        if (type === "incre" && scale < 2) {
            scale = scale + s;
        } else if (scale > 0.5) {
            scale = scale - s;
        }
        setValues({ scale: scale });
    }

    return (
        <div className="pdf-drawer-container">
            <div className="pdf-drawer-wrapper" style={{ maxHeight: "unset" }}>
                <div className={`zwibbler-draw-container pdf`}>
                    {!isReadonly && <div className="toolbar-wrapper"></div>}
                    <div
                        className="zwibbler-container"
                        id="scrollable"
                        style={{ ...width_height, minWidth: "80%", transform: `scale(${values.scale})` }}
                    >
                        <div className="custom-zwibbler" style={{ height: width_height.height }} ref={targetRef}>
                            <div className="zwibbler-image-background">{src && <img src={src} />}</div>

                            <div ref={zwibblerEl} {...zwibblerConfig} id="zwibbler-canvas" className="zwibbler">
                                <div z-canvas="" className="stretch" confine="view" />
                            </div>
                        </div>
                    </div>

                    {/* //layzyload module */}
                    <ZwibblerModule></ZwibblerModule>
                </div>
            </div>
            <div className="page-adjust-tool">
                <Button
                    onClick={() => toggleThumbnail?.()}
                    icon={isShowThumbnails ? <LeftOutlined /> : <RightOutlined />}
                ></Button>
                <Button onClick={() => handleScale("incre")} icon={<PlusOutlined />}></Button>
                <Button icon={<MinusOutlined />} onClick={() => handleScale("decre")}></Button>
            </div>
        </div>
    );
};

export default StudentPDFPreviewer;
