import React, { useEffect, useState } from "react";
import { Dropdown, notification, Spin, Tooltip } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { t } from "i18next";
import moment from "moment";
import { default as AppHeader } from "src/modules/components/Header";
import PopupFileManager from "src/modules/components/FileManager/popup";
import { getItemScopeSelected } from "src/api/helpers/userScope";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import { updateClass } from "src/api/containers/class";
import { useMediaQuery } from "src/hooks";
import { ReactComponent as TVStatistic } from "src/assets/images/tv-statistic.svg";
import GraduateHat from "src/assets/images/graduate-hat.svg";
import Calender from "src/assets/images/calender.svg";
import Book from "src/assets/images/book-icon.svg";
import QRCode from "src/assets/images/qr_code.svg";
import User from "src/assets/images/user.svg";
import detailClassHeader from "src/assets/images/header_class.png";
import "./Header.scss";
import Can from "src/modules/components/Can";
import { permissionUser } from "src/utils/permission";

const Header = (props) => {
    const { classData } = props;

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // Date format:
    const { formatDate } = useSelector((state) => state.general);

    //permission
    const { pagesAllow, rolesAllow } = permissionUser;

    // Others:
    const fmController = PopupFileManager.useController();
    const [loading, setLoading] = useState(false);
    const [currImgSrc, setCurrImgSrc] = useState("");
    const currMedia = useMediaQuery(["(min-width: 768px)"]);

    const handleChangeClassImage = (fileData) => {
        setLoading("image");
        updateClass(classData.id, {
            file_id: fileData.id,
            organization_id: getItemScopeSelected(classData).scopeId,
        })
            .then(({ status, message }) => {
                setLoading(false);
                if (status) {
                    setCurrImgSrc(fileData.src);
                    notification.success({
                        message: message || t("message.update_success"),
                    });
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
            })
            .catch(({ message }) => {
                notification.error({ message });
            });
    };

    const TeacherList = () => (
        <div className="class-header-dropdown-menu">
            {classData.teachers &&
                classData.teachers.map((teacher, index) => {
                    return <strong key={`teacher-${index}`}>{teacher.name}</strong>;
                })}
        </div>
    );

    const CategoryList = () => (
        <div className="class-header-dropdown-menu">
            {classData?.categories?.length &&
                classData.categories.map((item, itemIndex) => {
                    return <strong key={`cate-${itemIndex}`}>{item?.name || "_"}</strong>;
                })}
        </div>
    );

    if (!classData || !Object.keys(classData).length) {
        return (
            <AppHeader
                className="class-header"
                backEnable={false}
                backTitle={
                    <div className="class-header-title">
                        <span className="title-icon">{<TVStatistic />}</span>
                        <span className="title-content">{t("class.list_class")}</span>
                    </div>
                }
                actions={[]}
            />
        );
    }

    return (
        <div className="detail-class-header">
            <div className="detail-class-header-wrapper">
                <Spin spinning={loading === "image"} wrapperClassName="class-img-spinner">
                    <div className={"class-img-wrapper" + `${loading === "image" ? " loading" : ""}`}>
                        <img src={currImgSrc || classData?.file?.src || detailClassHeader} alt="220x135" />
                        <Can I={rolesAllow.update} page={pagesAllow.class}>
                            {/* {classData.owner._id === user.id ? ( */}
                            <div className="class-img-actions-wrapper">
                                <div className="class-img-actions">
                                    <Tooltip title={t("class.change_class_image")}>
                                        <span
                                            className="action-btn"
                                            onClick={() => {
                                                fmController.call("open");
                                            }}
                                        >
                                            <SwapOutlined />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            {/* // ) : null} */}
                        </Can>
                    </div>
                </Spin>

                <ul>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={QRCode} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.code")}
                                <span>:</span>
                            </span>
                            <strong>{classData.code ? classData.code : ""}</strong>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={Book} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.subject")}
                                <span>:</span>
                            </span>
                            {classData?.categories?.length ? (
                                classData.categories.length <= 2 ? (
                                    classData.categories.map((item, itemIndex) => {
                                        return <strong key={`cate-${itemIndex}`}>{item?.name || "_"}</strong>;
                                    })
                                ) : (
                                    <Dropdown overlay={<CategoryList />} placement="bottom">
                                        <strong className="show-more">...</strong>
                                    </Dropdown>
                                )
                            ) : (
                                <strong>_</strong>
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={Calender} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.begin_date")}
                                <span>:</span>
                            </span>
                            <strong>
                                {classData.begin_date ? moment(classData.begin_date).format(formatDate) : ""}
                            </strong>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={Calender} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.end_date")} <span>:</span>
                            </span>
                            <strong>{classData.end_date ? moment(classData.end_date).format(formatDate) : ""}</strong>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={User} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.teacher")}
                                <span>:</span>
                            </span>
                            {classData?.teachers.length !== 0 ? (
                                classData?.teachers.length <= 2 && currMedia.breakpointValue === 768 ? (
                                    classData.teachers.map((t, i) => {
                                        return <strong key={`teacher-${i}`}>{t.name || ""}</strong>;
                                    })
                                ) : (
                                    <Dropdown overlay={<TeacherList />} placement="bottom">
                                        <strong className="show-more">...</strong>
                                    </Dropdown>
                                )
                            ) : (
                                <strong>{t("class.no_teacher")}</strong>
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="detail-class-icon">
                                <img src={GraduateHat} alt="icon" />
                            </span>
                            <span className="detail-class-item-title">
                                {t("class.student_amount")} <span>:</span>
                            </span>
                            <strong>{classData.total_student}</strong>
                        </p>
                    </li>
                </ul>
            </div>

            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        if (validateFileBeforeUpload(selectedFile.src).type === "image") {
                            handleChangeClassImage(selectedFile);
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>{`
                                                    ${t("message.err_file_extension_not_valid")}.
                                                    ${t("message.please_try_another_file_extension")}!
                                                `}</div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </div>
    );
};

export default Header;
