import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/components/Header";
import TeacherTable from "./components/TeacherTable";
import { useTranslation } from "react-i18next";
import "./ManageTeacher.scss";
import { useEffect } from "react";
import { setCurrentPage } from "src/reducers/general";

function ManageTeacher(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentPage("organization"));
    }, []);

    // if (user && user.organization?.is_organization_owner === true) {
    return (
        <div className="organization-wrapper">
            <Header backEnable={false} backTitle={t("header.manage_teacher")} actions={[]} />
            <TeacherTable />
        </div>
    );
    // } else {
    //     return null;
    // }
}

export default ManageTeacher;
