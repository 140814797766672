import { useSwiper } from "swiper/react";

const SwiperButton = ({ children, swiperRef, type = "next", ...rest }) => {
    const swiper = useSwiper();
    swiperRef.current = swiper;
    return (
        <button
            className="swiper-button"
            onClick={() => (type === "next" ? swiper.slideNext() : swiper.slidePrev())}
            {...rest}
        >
            {children}
        </button>
    );
};

export default SwiperButton;
