import Button from "../Button";
import { _1022, _6104, _6102, _6103, _1053, _1055, _1052, _leftParenthesis } from "../../icons/tab8";
import {
    _rightParenthesis,
    _leftSquareBracket,
    _rightSquareBracket,
    _944,
    _944_2,
    _1023,
    _993,
    _1025,
} from "../../icons/tab8";
import { _1045 } from "../../icons/tab8";

const Tab8 = ({ onClick }) => {
    const datas = [
        { icon: _1022, text: "\\xrightarrow{}{}", keys: ["Left", "Left"] },
        { icon: _6104, text: "\\overline{ }", keys: ["Left"] },
        { icon: _6102, text: "\\overrightarrow{ }", keys: ["Left"] },
        { icon: _6103, text: "\\overleftrightarrow{ }", keys: ["Left"] },
        { icon: _1053, text: "\\overset{\\frown}{}", keys: ["Left"] },
        { icon: _1055, text: "\\hat{}", keys: ["Left"] },
        { icon: _leftParenthesis, text: "(" },
        { icon: _rightParenthesis, text: ")" },

        { icon: _leftSquareBracket, text: "[" },
        { icon: _rightSquareBracket, text: "]" },
        { icon: _944, text: "\\left(\\right)", keys: ["Left"] },
        { icon: _944_2, text: "\\left[\\right]", keys: ["Left"] },
        { icon: _1023, text: "\\left\\{\\right\\}", keys: ["Left"] },
        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
        { icon: _1025, text: "\\left[\\right)", keys: ["Left"] },
        { icon: _1045, text: "\\left(\\right]", keys: ["Left"] },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab8;
