// File is verified!
import api from "..";

const planFields = {
    planInfo: [
        "type",
        "name",
        "content",
        "exam_question_per_account",
        "storage_per_account",
        "number_of_account",
        "slug",
    ],
};
const planPriceFields = ["months", "price", "discount"];
const planRelations = ["prices"];

export const planTypes = {
    free: "free",
    premium: "team",
    organization: "organization",
};

// Get subscription plan list:
export const fetchSubscriptionPlans = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.planType && filters.push(`type:${fetchParams.planType}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _plan_fields: `${planFields.planInfo}`,
        _relations: `${planRelations}`,
        _plan_price_fields: `${planPriceFields}`,
    };
    return api
        .get("/plan", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

// Find plan by id:
export const findSubscriptionPlan = (id) => {
    const params = {
        _plan_fields: `${planFields.planInfo}`,
        _relations: `${planRelations}`,
        _plan_price_fields: `${planPriceFields}`,
    };
    return api
        .get(`/plan/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

// Get bank list:
export const fetchBankList = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
    };
    return api
        .get("/pay/get_banks", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

// Purchase plan:
export const purchasePlan = (data) => {
    const { plan_price_id, payment_method, promotion_codes } = data;

    if (!plan_price_id || !payment_method) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .post("/plan/purchase", data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Check after purchasing plan:
export const checkAfterPurchasePlan = (data) => {
    let urlParams;

    if (typeof data === "string") {
        urlParams = data;
    } else if (Object.keys(data).length) {
        urlParams = [];
        for (const key of Object.keys(data)) {
            urlParams.push(`${key}=${data[key]}`);
        }
        urlParams = urlParams.join("&");
    } else {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .get(`/plan/after_purchase?${urlParams}`)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Promotional codes:
export const applyPromoCode = (data) => {
    const { plan_price_id, promotion_codes } = data;

    if (!plan_price_id || !promotion_codes) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .post("/plan/apply_promotion", data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};
