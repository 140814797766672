import React, { useCallback, useEffect, useMemo, useRef } from "react";
import StaticMath from "src/modules/components/StaticMath";

const StaticMathField = (props) => {
    const { children } = props;
    const staticRef = useRef(null);
    const StaticMathRef = React.createRef(null);

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.latex(children);
        }
    }, [children]);

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.innerFields.forEach((field, index) => {
                field.latex(value?.[index] || "");
                field.__controller.editable = false;
            });
        }
    }, [children]);

    return <StaticMath ref={StaticMathRef} staticMathRef={staticRef} />;
};

export default React.memo(StaticMathField);
