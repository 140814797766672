/**
 * Scope keys.
 */
export const scopeKeys = {
    org: "organization",
    teams: "teams",
    personal: "personal",
};

/**
 * Get selected scope:
 * @param {Object} userData User data.
 * @returns Object.
 */
export const getCurrentTeamsOrOganization = (userData) => {
    const selectedUserScope = localStorage.getItem("selectedUserScope");

    if (selectedUserScope) {
        const { scopeOptionKey, organization_id } = JSON.parse(selectedUserScope);

        if (organization_id) {
            if (scopeOptionKey === "organization") {
                return {
                    paramsForOrgOrTeam: {
                        organization_id: userData?.organization?.organization_id,
                        isOwner: userData?.organization?.is_organization_owner,
                    },
                    currentOrg: { ...userData?.organization, scopeOptionKey: scopeOptionKey },
                };
            } else if (scopeOptionKey === "teams") {
                const teams = (Array.isArray(userData?.teams) && userData.teams) || [];
                const teamData = teams.find((item) => item?.team_id == organization_id);
                return {
                    paramsForOrgOrTeam: {
                        organization_id: teamData?.team_id,
                        isOwner: teamData?.is_team_owner,
                    },
                    currentOrg: { ...teamData, scopeOptionKey: scopeOptionKey },
                };
            }
        }
    }

    return {
        paramsForOrgOrTeam: {},
    };
};

/**
 * USER'S SCOPE:
 * Check if user has any scope or not.
 * @param {Object} _userData User data.
 * @returns {Object} {scopeKey, scopeName}.
 */
export const checkIfUserHasAnyScope = (_userData) => {
    if (_userData?.organization || _userData?.teams?.length) {
        return true;
    }
    return false;
};

/**
 * USER'S SCOPE:
 * Check if user has a valid scope or not.
 * @param {Object} _userData User data.
 * @param {Object} selectedUserScope { scopeOptionKey, organization_id, user_id }.
 * @returns
 * - Return false if there is no selected scope.
 * - Return "personal" if scope is scopeKeys.personal.
 * - Return "member" if scope is not scopeKeys.personal.
 */
export const checkIfUserHasValidScope = (_userData, selectedUserScope) => {
    if (selectedUserScope) {
        const { scopeOptionKey, organization_id, user_id } = selectedUserScope;

        if (scopeOptionKey && user_id === _userData.id) {
            if (scopeOptionKey === scopeKeys.personal) {
                return "personal";
            } else {
                if (organization_id) {
                    return "member";
                }
            }
        }
    }
    return false;
};

/**
 * USER'S SCOPE:
 * Select a scope.
 * @param {string} scopeKey Key in scopeKeys.
 * @param {Object} scopeData Scope information.
 * @param {string} userId The user who select the scope (to check the scope is used for who).
 */
export const selectUserScope = (scopeKey, scopeData, userId) => {
    switch (scopeKey) {
        case scopeKeys.org:
            localStorage.setItem(
                "selectedUserScope",
                JSON.stringify({
                    scopeOptionKey: scopeKey,
                    organization_id: scopeData.organization_id,
                    user_id: userId,
                })
            );
            return;
        case scopeKeys.teams:
            localStorage.setItem(
                "selectedUserScope",
                JSON.stringify({
                    scopeOptionKey: scopeKey,
                    organization_id: scopeData.team_id,
                    user_id: userId,
                })
            );
            return;
        case scopeKeys.personal:
            localStorage.setItem(
                "selectedUserScope",
                JSON.stringify({
                    scopeOptionKey: scopeKey,
                    user_id: userId,
                })
            );
            return;
        default:
            return;
    }
};

/**
 * USER'S SCOPE:
 * Clear selected user scope:
 */
export const clearSelectedUserScope = () => {
    localStorage.removeItem("selectedUserScope");
};

/**
 * USER'S SCOPE:
 * Get user's selected scope info (scope key, scope name).
 * @param {Object} _userData User data.
 * @returns {Object} {scopeKey, scopeName}.
 */
export const getUserScopeSelected = (_userData) => {
    let scopeKey = false;
    let scopeName = false;
    switch (_userData.currentOrg?.scopeOptionKey) {
        case "teams":
            scopeKey = "team";
            scopeName = _userData.currentOrg?.team_name;
            break;
        case "organization":
            scopeKey = "organization";
            scopeName = _userData.currentOrg?.organization_name;
            break;
        default:
            break;
    }
    return { scopeKey, scopeName };
};

/**
 * USER'S SCOPE:
 * Get user's selected scope info (scope key, scope name) by scope id.
 * @param {Object} _userData User data.
 * @param {string} scopeId Scope id.
 * @returns {Object} {scopeKey, scopeName}.
 */
export const getUserScopeByScopeId = (_userData, scopeId) => {
    let scopeKey = false;
    let scopeName = false;
    // Check organization scope:
    const orgInfo = _userData?.organization;
    if (scopeId === orgInfo?.organization_id) {
        scopeKey = "organization";
        scopeName = orgInfo.organization_name;
        return { scopeKey, scopeName };
    }
    // Check team scope:
    const teamsInfo = _userData?.teams || [];
    for (let i = 0; i < teamsInfo.length; i++) {
        if (scopeId === teamsInfo[i].team_id) {
            scopeKey = "team";
            scopeName = teamsInfo[i].team_name;
            return { scopeKey, scopeName };
        }
    }
    // Others:
    return { scopeKey, scopeName };
};

/**
 * ITEM'S SCOPE:
 * - Get item's scope id (organization_id, team_id).
 * @param {Object} _itemHasScope Question info, exam info,... .
 * @returns {Object} {scopeId, scopeKey, scopeName}.
 */
export const getItemScopeSelected = (_itemHasScope) => {
    let scopeId = false;
    let scopeKey = false;
    let scopeName = false;

    // Check organization scope:
    const scopeOrg = _itemHasScope?.organization_id || _itemHasScope?.organization?.id || false;
    if (scopeOrg) {
        scopeId = scopeOrg;
        scopeKey = "organization";
        scopeName = _itemHasScope?.organization?.name;
    }

    // Check team scope:
    const scopeTeam = _itemHasScope?.team_id || _itemHasScope?.team?.id || false;
    if (scopeTeam) {
        scopeId = scopeTeam;
        scopeKey = "team";
        scopeName = _itemHasScope?.team?.name;
    }

    return { scopeId, scopeKey, scopeName };
};
