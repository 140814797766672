import { useEffect, useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import router, { useRouting } from "src/utils/router";
import { useDispatch, useSelector } from "react-redux";
import AuthedAfter from "./AuthedAfter";
import HelpModal from "src/modules/components/Help/HelpModal";
import { logout } from "src/reducers/auth";
import { toggleHelpModalVisible, toggleFeedbackModalVisible } from "src/reducers/general";
import { permissionUser } from "src/utils/permission";
import FeedbackDrawer from "src/modules/containers/Feedback/FeedbackDrawer";

const Authed = () => {
    const { generate } = useRouting();
    const dispatch = useDispatch();

    // User:
    const { user } = useSelector((state) => state.auth);
    // Page info:
    const { currentPage, isHelpModalVisible, isFeedbackModalVisible } = useSelector((state) => state.general);

    // Others:
    const { routesNotAllow } = permissionUser;
    const notAllowRoutes = useMemo(() => routesNotAllow(user), [user]);

    if (user?.role === "guest") {
        let lang = localStorage.getItem("lang");
        if (lang && lang === "en") {
            window.location.href = "/en/choose-role";
        } else {
            window.location.href = "/vi/choose-role";
        }
    }

    const handleShowHelpModal = (val) => {
        dispatch(toggleHelpModalVisible(val));
    };

    const handleCancelModalFeedback = () => {
        dispatch(toggleFeedbackModalVisible(false));
    };

    useEffect(() => {
        if (user.is_new_user === true) {
            dispatch(toggleHelpModalVisible(true));
        }

        const handleStorageChange = (storage) => {
            if (storage.storageArea.isLogged == 0) {
                dispatch(logout());
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <AuthedAfter>
            <Routes>
                {router.collection.items.map((item, idx) => {
                    let Layout = item.layout;
                    let Element = item.element;
                    if (item.role.includes(user?.role) && !notAllowRoutes.includes(item.name)) {
                        return (
                            <Route
                                key={idx}
                                path={item.path}
                                element={
                                    Layout ? (
                                        <Layout>
                                            <Element />
                                        </Layout>
                                    ) : (
                                        <Element />
                                    )
                                }
                            />
                        );
                    }
                })}
                <Route path="*" element={<Navigate to={generate("not-found")} />} />
            </Routes>

            <HelpModal visible={isHelpModalVisible} page={currentPage} onChangeVisible={handleShowHelpModal} />
            <FeedbackDrawer
                visible={isFeedbackModalVisible}
                onOk={handleCancelModalFeedback}
                onCancel={handleCancelModalFeedback}
            ></FeedbackDrawer>
        </AuthedAfter>
    );
};

export default Authed;
