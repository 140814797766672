import { useEffect, useRef, useState } from "react";

const useResizeObserver = () => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const targetRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                setDimensions({ width, height });
            }
        });

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []); // Empty dependency array to run the effect once on mount

    return { targetRef, dimensions };
};

export default useResizeObserver;
