import { Alert, Dropdown, Space, Spin, Tag } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchStudentAssignments } from "src/api/containers/assignment";
import Pencil from "src/assets/images/pencil.svg";
import ClassImg from "src/assets/images/Rectangle5289.png";
import ReviewIcon from "src/assets/images/review-icon.svg";
import { useFetch, useValues } from "src/hooks";
import Button from "src/modules/components/Button";
import InputSearch from "src/modules/components/Input/InputSearch";
import StudentFrame from "src/modules/components/StudentFrame";
import StudentHeader from "src/modules/components/StudentHeader";
import { setCurrentPage } from "src/reducers/general";
import { compareTwoTime } from "src/utils/helpers";
import { useRouting } from "src/utils/router";
import FilterMenu from "./components/FilterMenu";
import "./HomeStudent.scss";

const HomeStudent = () => {
    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [loadingAssignment, dataAssignment, paginationAssignment, fetchAssignment, refetchAssignment] = useFetch(
        { page: 1, slug: "", perPage: 11, ...fParams },
        fetchStudentAssignments
    );

    // Others:
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();

    //redux
    const listAnswers = useSelector((item) => item?.answer?.listAnswers);

    const isLastPage = useMemo(() => {
        const { current, pageSize, total } = paginationAssignment;
        return current * pageSize >= total;
    }, [paginationAssignment]);

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        inputSearchQuestion: "",
        filterParams: [
            ...(fParams.organization_id
                ? [
                      {
                          name: "organization_id",
                          value: fParams.organization_id,
                          labelValue: fParamsDetail.organization_name,
                      },
                  ]
                : []),
        ],
    });

    const [values, setValues] = useValues({
        listPages: 0,
        listOfAssignments: [],
    });

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        dispatch(setCurrentPage("assignment"));
        fetchAssignment({}, true);
    }, []);

    const renderRecordStatus = ({ status }) => {
        switch (status) {
            case "donot":
                return <div className="cardItem_status cardItem_status-danger">{t("assignment.donot")}</div>;
            case "submited":
                return <div className="cardItem_status cardItem_status-success">{t("assignment.submited")}</div>;
            case "marked":
                return <div className="cardItem_status cardItem_status-info">{t("assignment.marked")}</div>;
            case "doing":
                return <div className="cardItem_status cardItem_status-warn">{t("assignment.doing")}</div>;
            case "expired":
                return <div className="cardItem_status cardItem_status-danger">{t("assignment.expired")}</div>;
            case "not_start":
                return <div className="cardItem_status cardItem_status-disable">{t("assignment.not_start")}</div>;
            case "submited_lately":
                return <div className="cardItem_status cardItem_status-warn2">{t("assignment.submited_lately")}</div>;
            case "marked_and_submited_lately":
                return (
                    <div className="cardItem_status cardItem_status-warn2">
                        {t("assignment.marked_and_submited_lately")}
                    </div>
                );
            default:
                return <div className="cardItem_status cardItem_status-disable">{t("shared.unknown")}</div>;
        }
    };

    const handleDoHomeWork = (assignmentId, is_rework) => {
        is_rework
            ? navigate(`${generate("test", { id: assignmentId })}?is_rework=true`)
            : navigate(generate("test", { id: assignmentId }));
    };

    const handleClickReview = (recordId) => {
        navigate(generate("score", { id: recordId }));
    };

    const handleLoadMore = () => {
        fetchAssignment(
            {
                page: paginationAssignment.current + 1,
            },
            true
        );
    };

    const handleSearch = () => {
        if (filterSettings) {
            fetchAssignment({ page: 1, slug: filterSettings.inputSearchQuestion });
        }
    };

    const renderButtonDoHomeWork = (arg1, assignmentId, isAllowActions) => {
        const { status, id: recordId } = arg1;

        switch (status) {
            case "doing":
                return (
                    <Button
                        onClick={() => handleDoHomeWork(assignmentId)}
                        title={t("assignment.continue")}
                        icon={Pencil}
                        type="primary"
                    />
                );
            case "donot":
                return (
                    <Button
                        onClick={() => handleDoHomeWork(assignmentId)}
                        title={t("assignment.do_homework")}
                        icon={Pencil}
                        type="primary"
                    />
                );
            case "submited":
            case "marked":
            case "submited_lately":
            case "marked_and_submited_lately":
                return (
                    <Button
                        onClick={() => handleClickReview(recordId)}
                        title={t("assignment.review")}
                        icon={ReviewIcon}
                        type="primary"
                        isDisabled={!isAllowActions}
                    />
                );
            case "expired":
                if (listAnswers?.[recordId]) {
                    // nếu có data của hs làm bài thì mới hiện nút nộp
                    return (
                        <Button
                            onClick={() => handleDoHomeWork(assignmentId)}
                            title={t("assignment.submit")}
                            // icon={Pencil}
                            type="primary"
                        />
                    );
                }
        }
    };

    const renderButtonRe_DoHomeWork = ({ status }, is_test, assignmentId) => {
        if (is_test === null || is_test === undefined) return;

        if (is_test === false) {
            switch (status) {
                case "submited":
                case "marked":
                    return (
                        <Button
                            onClick={() => handleDoHomeWork(assignmentId, true)}
                            title={t("assignment.redo_homework")}
                            icon={Pencil}
                            type="primary"
                        />
                    );
            }
        }
        return;
    };

    useEffect(() => {
        if (dataAssignment) {
            if (paginationAssignment.current === 1) {
                setValues({
                    ...values,
                    listPages: paginationAssignment.current,
                    listOfAssignments: dataAssignment,
                });
            } else if (paginationAssignment.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: paginationAssignment.current,
                    listOfAssignments: [...values.listOfAssignments, ...dataAssignment],
                });
            }
        }
    }, [dataAssignment, paginationAssignment]);

    return (
        <div className="homeStudent_wrapper">
            <div className="homeStudent_header">
                <StudentHeader title={t("assignment.listAssignment")}>
                    <div className="homeStudent_controlPanel">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                onChange={(e) =>
                                    setFilterSettings({
                                        ...filterSettings,
                                        inputSearchQuestion: e.target.value,
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="homeStudent_controlPanel-search">
                            <div className="homeStudent_controlPanel_title">{t("assignment.filter")}</div>
                            <Dropdown
                                forceRender
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        filterParamsInitial={filterSettings.filterParams}
                                        handleFetch={fetchAssignment}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottom"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="homeStudent_filter">
                                    {filterSettings.filterParams?.length > 0 ? (
                                        filterSettings.filterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                                >
                                                    {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                        fKey.labelValue || ""
                                                    }`}
                                                </Tag>
                                            );
                                        })
                                    ) : (
                                        <div>{t("assignment.filter_option")}</div>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </StudentHeader>
            </div>
            <StudentFrame title={t("assignment.assignments")}>
                <Spin tips="loading" spinning={loadingAssignment}>
                    <div className="cardList">
                        {values?.listOfAssignments.map((assignment) => {
                            const isAllowActions = assignment?.ranking_type
                                ? compareTwoTime(new Date(), assignment?.end_date)
                                : true;
                            return (
                                <div key={assignment.id} className="cardItem">
                                    <div className="cardItem_inner">
                                        <div className="cardItem_thumbNail">
                                            <div className="cardItem_image">
                                                <img src={ClassImg} />
                                            </div>
                                        </div>
                                        <div className="cardItem_info">
                                            <h3>{assignment?.name || t("shared.unknown")}</h3>
                                            <p>
                                                {t("shared.type")}:{" "}
                                                {assignment?.is_test === true || assignment?.is_test === null ? (
                                                    <Tag color="red" style={{ borderRadius: 10 }}>
                                                        {t("assignment.exam")}
                                                    </Tag>
                                                ) : assignment?.is_test === false ? (
                                                    <Tag color="cyan" style={{ borderRadius: 10 }}>
                                                        {t("assignment.assignment")}
                                                    </Tag>
                                                ) : (
                                                    t("shared.unknown")
                                                )}
                                            </p>
                                            <p>
                                                {t("assignment.subject")}:{" "}
                                                {assignment?.category?.name || t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.class")}:{" "}
                                                {assignment?.class?.name || t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.time_spent")}:{" "}
                                                {`${assignment?.homework_time_minute} (${t("class.input_time")})` ||
                                                    t("shared.unknown")}
                                            </p>
                                            <p>
                                                {t("assignment.class_code")}:{" "}
                                                {assignment?.class?.code || t("shared.unknown")}
                                            </p>
                                            {assignment?.begin_date && (
                                                <p>
                                                    {t("assignment.start_from")}:{" "}
                                                    {moment(assignment?.begin_date).format("DD/MM/YYYY - H:mm")}{" "}
                                                </p>
                                            )}
                                            <p>
                                                {t("assignment.deadline")}:{" "}
                                                {assignment?.end_date
                                                    ? moment(assignment?.end_date).format("DD/MM/YYYY - H:mm")
                                                    : t("class.time_not_limited")}
                                            </p>
                                            {assignment.is_test === false && (
                                                <p>
                                                    {t("assignment.count_submit")}:
                                                    {assignment?.record?.count_submit_exercise
                                                        ? ` ${assignment.record.count_submit_exercise}`
                                                        : ` ${0}`}
                                                </p>
                                            )}
                                            <div>
                                                {t("assignment.status")}
                                                {renderRecordStatus(assignment?.record)}
                                            </div>
                                            <div style={{ marginTop: "15px" }}>
                                                <Space wrap="wrap">
                                                    {renderButtonDoHomeWork(
                                                        assignment?.record,
                                                        assignment?.id,
                                                        isAllowActions
                                                    )}
                                                    {renderButtonRe_DoHomeWork(
                                                        assignment?.record,
                                                        assignment.is_test,
                                                        assignment?.id
                                                    )}
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {Boolean(values?.listOfAssignments.length && !isLastPage && !loadingAssignment) && (
                            <div className="cardItem loadmore_btn" onClick={handleLoadMore}>
                                <div className="cardItem_inner">{t("assignment.load_more")}</div>
                            </div>
                        )}
                    </div>
                    {values?.listOfAssignments?.length == 0 && (
                        <Alert description={t("assignment.no_assignment")} type="warning" style={{ marginTop: 20 }} />
                    )}
                </Spin>
            </StudentFrame>
        </div>
    );
};

export default HomeStudent;
