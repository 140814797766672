import React, { useEffect, useMemo, useState } from "react";
import "./Dashboard.scss";
import Header from "src/modules/components/Header";
import { useTranslation } from "react-i18next";
import NumberCard from "src/modules/components/NumberCard";
import AssignmentImg from "src/assets/images/dashboard/assignments.png";
import CompetitionsImg from "src/assets/images/dashboard/competitions.png";
import StudentsImg from "src/assets/images/dashboard/students.png";
import TestsImg from "src/assets/images/dashboard/test.png";
import QuestionsImg from "src/assets/images/dashboard/questions.png";
import ClassImg from "src/assets/images/dashboard/class.png";
import { useRouting } from "src/utils/router";
import { getDashboadData } from "src/api/containers/statistic";
import { Dropdown, Select, Space, Spin, Tag, notification } from "antd";
import { useFetch, useValues } from "src/hooks";
import { useDispatch, useSelector } from "react-redux";
import { permissionUser } from "src/utils/permission";
import { useSearchParams } from "react-router-dom";
import { getFilterValuesByURLSearchParams, getURLSearchParamsByFilterValues } from "src/utils/helpers/urlQueryString";
import FilterMenu from "src/modules/components/FilterMenu";
import { compareObjects } from "src/utils/helpers/comparison";
import FilterIcon from "src/assets/images/filter-icon.svg";
import { default as CustomButton } from "src/modules/components/Button";
import { setCurrentPage } from "src/reducers/general";

const LIST_ITEMS = [
    {
        key: "total_student",
        avatar: StudentsImg,
        color_avt: "#ffa940",
        linkTo: ["manage_student"],
    },
    {
        key: "total_class",
        avatar: ClassImg,
        color_avt: "#c49c6b",
        linkTo: ["class", "manage_class"],
    },
    {
        key: "total_created_class",
        avatar: ClassImg,
        color_avt: "#40a9fffc",
        linkTo: ["class", "manage_class"],
    },
    {
        key: "total_created_question",
        avatar: QuestionsImg,
        color_avt: "#e791bc",
        linkTo: ["question_bank"],
    },
    {
        key: "total_created_exam",
        avatar: AssignmentImg,
        color_avt: "#26bf94",
        linkTo: ["exam_bank"],
    },

    {
        key: "total_created_assignment",
        avatar: TestsImg,
        color_avt: "#845adf",
        linkTo: ["assignment"],
    },

    {
        key: "total_created_competition",
        avatar: CompetitionsImg,
        color_avt: "#ff4d4f",
        linkTo: ["competitions"],
    },
];

function Dashboard({ isWithUrlQueryString = true }) {
    const { t } = useTranslation();
    const { generate } = useRouting();
    const user = useSelector((state) => state.auth.user);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};
    const dispatch = useDispatch();

    const [values, setValues] = useValues({
        dashboardData: {},
        loading: false,

        isFirstTimeFetchDone: false,
        // Filter menu:
        isVisible: false,
    });

    const [loading, dataSource, pagination, fetch, refetch] = useFetch({}, getDashboadData);

    const { routesNotAllow, routesAllow } = permissionUser;
    const allowRoutes = useMemo(() => routesAllow(user), [user]);

    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const filterValuesInitial = {};
        const filterValuesConfig = {};

        if (fParamsUserScope?.organization_id) {
            filterValuesInitial.organization_id = fParamsUserScope.organization_id;
            filterValuesConfig.organization_id = {
                label: t("shared.scope"),
                queryKey: "scope",
                values: [fParamsUserScope.organization_id],
                valuesInfo: {
                    [fParamsUserScope.organization_id]: {
                        label: user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown", // {t(`shared.local_of_${scopeKey}`)} - {scopeName}
                        queryValue: fParamsUserScope.organization_id,
                    },
                },
            };
        }
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [values]);

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    const isRouteAllow = (routes) => {
        if (!user || !routes) return false;
        if (user?.currentOrg?.is_organization_owner || user?.currentOrg?.is_team_owner) {
            return true;
        } else {
            if (Object.keys(fParamsUserScope).length === 0) {
                //case login with account free
                const freeRoutes = ["class", "question_bank", "exam_bank", "assignment", "competitions"];
                const result = freeRoutes?.some((item) => routes?.includes(item));

                return result;
            } else {
                const defaultRoutes = ["class", "question_bank", "exam_bank", "assignment"];
                const result =
                    defaultRoutes.some((item) => routes?.includes(item)) ||
                    allowRoutes?.some((item) => routes?.includes(item));
                return result;
            }
        }
    };

    useEffect(() => {
        // if (user?.id) {
        //     setValues({ loading: true });
        //     const params = {};
        //     if (user?.paramsForOrgOrTeam?.organization_id) {
        //         params.organization_id = user?.paramsForOrgOrTeam?.organization_id;
        //     }
        //     getDashboadData(params)
        //         .then(({ status, data }) => {
        //             if (status) {
        //                 setValues({ dashboardData: data, loading: false });
        //             }
        //         })
        //         .catch((err) => {
        //             notification.error({ message: t("shared.something_went_wrong") });
        //             setValues({ loading: false });
        //         });
        // }
    }, [user]);

    const formFields = useMemo(() => {
        const _formFields = {};
        if (fParamsUserScope?.organization_id && filterValuesConfig.organization_id) {
            _formFields.organization_id = {
                label: t("shared.scope"),
                element: (
                    <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                        {filterValuesConfig.organization_id.values.map((item, itemIndex) => {
                            return (
                                <Select.Option key={`scope${itemIndex}`} value={item}>
                                    {t(`${filterValuesConfig.organization_id.valuesInfo[item].label}`)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                ),
                colProps: {
                    span: 24,
                },
            };
        }
        return _formFields;
    }, [values]);

    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: ["organization_id"],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    const handleUpdateQueryString = (urlQueries = {}) => {
        const sParams = {};
        urlQueries?.scope && (sParams.scope = urlQueries.scope);
        setUrlSearchParams(sParams);
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            [fieldName]: undefined,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleFetchDataList = (fetchParams = {}, isReload = true, extraFetchParams) => {
        const fParams = {
            organization_id: fetchParams.organization_id,
            ...(Object.keys(extraFetchParams || {})?.length ? extraFetchParams : {}),
        };
        fetch({ ...fParams }, isReload);
    };

    useEffect(() => {
        dispatch(setCurrentPage("dashboard"));
    }, []);

    useEffect(() => {
        if (isWithUrlQueryString) {
            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());

            const isUrlQSEmpty = Object.keys(currUrlSearchParams).length === 0;
            const isFirstTimeFetch = values.isFirstTimeFetchDone === false;
            if (isUrlQSEmpty && isFirstTimeFetch) {
                const newUrlSearchParams = getURLSearchParamsByFilterValues(currFilterValues, filterValuesConfig);

                if (Object.keys(newUrlSearchParams).length === 0) {
                    handleFetchDataList(currFilterValues, true);
                } else {
                    handleUpdateQueryString(newUrlSearchParams);
                }
            } else {
                if (!isUrlQSEmpty || (isUrlQSEmpty && !isFirstTimeFetch)) {
                    const newFilterValues = getFilterValuesByURLSearchParams(currUrlSearchParams, filterValuesConfig);

                    if (compareObjects(currFilterValues, newFilterValues) === false || isFirstTimeFetch) {
                        setCurrFilterValues(newFilterValues);
                        setValues({ isFirstTimeFetchDone: true });
                        // Update data list whenever the URL query string is changed:
                        handleFetchDataList(newFilterValues, true);
                    }
                }
            }
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [urlSearchParams, currFilterValues]);

    return (
        <div className="dashboard page-padding">
            <div className="dashboard-header">
                <Header backEnable={false} backTitle={t("header.dashboard")} actions={[]} />
            </div>

            {Object.entries(fParamsUserScope).length !== 0 && (
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        {/* ------search input------ */}
                        <div className="filter-toolbar-item filterinput-wrapper">
                            {/* <InputSearch
                            displayType="style-dream"
                            placeholder={t("class.find_class")}
                            value={filterInfo.inputSearch}
                            onChange={(e) => setFilterInfo({ ...filterInfo, inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        /> */}
                        </div>

                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {(!isWithUrlQueryString || values.isFirstTimeFetchDone) &&
                                        Object.keys(currFilterValues).map((fKey, i) => {
                                            const fValue = currFilterValues[fKey];
                                            const fValueLabel =
                                                filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                                (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                    ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                    : undefined);
                                            if (fValue && fValueLabel) {
                                                return (
                                                    <Tag
                                                        className="app-tag"
                                                        key={`filter-key${i}`}
                                                        closable
                                                        onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                    >
                                                        {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                    </Tag>
                                                );
                                            }
                                            return null;
                                        })}
                                </Space>
                            </div>

                            <Dropdown
                                forceRender
                                visible={values.isVisible}
                                overlay={
                                    <FilterMenu
                                        formLayout={"horizontal"}
                                        formFields={formFields}
                                        formFieldGroups={formFieldGroups}
                                        formData={currFilterValues}
                                        willResetForm={values.isVisible === false}
                                        onCancel={() => {
                                            setValues({
                                                isVisible: false,
                                            });
                                        }}
                                        onSubmit={(newFilterValues) => {
                                            if (isWithUrlQueryString) {
                                                const currUrlSearchParams = Object.fromEntries(
                                                    urlSearchParams.entries()
                                                );
                                                const newUrlSearchParams = getURLSearchParamsByFilterValues(
                                                    newFilterValues,
                                                    filterValuesConfig
                                                );
                                                if (compareObjects(currUrlSearchParams, newUrlSearchParams) === false) {
                                                    handleUpdateQueryString(newUrlSearchParams);
                                                }
                                            } else {
                                                setCurrFilterValues(newFilterValues);
                                            }
                                        }}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) => {
                                    setValues({ isVisible: val });
                                }}
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions"></div>
                </div>
            )}

            <Spin spinning={loading} tip={t("shared.loading")}>
                <div className="dashboard-container">
                    <div className="dashboard-inner">
                        <div className="dashboard-cards">
                            <div className="list-card">
                                {LIST_ITEMS.map((item, key) => (
                                    <NumberCard
                                        key={item.key}
                                        image={item.avatar}
                                        count={dataSource?.[item?.key]}
                                        color_avt={item.color_avt}
                                        linkTo={(() => {
                                            if (Object.entries(fParamsUserScope).length) {
                                                let itemForOrg = item.linkTo.find((x) => x?.startsWith("manage"));
                                                if (!isRouteAllow([itemForOrg])) {
                                                    itemForOrg = null;
                                                }
                                                return generate(itemForOrg || item.linkTo?.[0]);
                                            } else {
                                                const itemNotOrg = item.linkTo.find((x) => !x?.startsWith("manage"));

                                                return generate(itemNotOrg || item.linkTo?.[0]);
                                            }
                                        })()}
                                        isAllowLinkTo={isRouteAllow(item.linkTo)}
                                        titleKey={item.key}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
}

export default Dashboard;
