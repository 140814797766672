import { t } from "i18next";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragDropIcon from "src/assets/images/drag-icon.svg";
import LatexDangerously from "src/modules/components/LatexDangerously";
import QuestionContentDetail from "../components/ContentDetail";
import "./Sort.scss";
import clsx from "clsx";

const Sort = (props) => {
    const {
        isReadonly = false, // boolean
        question, // string
        answer, // string[]
        defaultAnswer, // string[]
        onChange, // () => void
        exam_types,
    } = props;

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const [placeholderProps, setPlaceholderProps] = useState({});
    const [data, setData] = useState(() => {
        const answered = defaultAnswer || answer;
        return answered?.map((aswr, i) => {
            return {
                text: aswr,
                value: answer.findIndex((aswrCorrect) => aswrCorrect === aswr) + 1, // Get position in pure answer for default answer
            };
        });
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDragEnd = (result) => {
        setPlaceholderProps({});

        if (!result.destination) {
            return;
        }

        const items = reorder(data, result.source.index, result.destination.index);

        const newAnswered = items.map((item) => item.text);

        if (onChange && onChange instanceof Function) {
            onChange({
                answered: newAnswered,
            });
        }

        setData(items);
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery).parentNode;

        return draggedDOM;
    };

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);
        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY: clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;
        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];

        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
            position: "absolute",
            backgroundColor: "white",
            borderRadius: "3px",
            border: "dashed 1px #ddd",
        });
    };

    useEffect(() => {
        // Sort always have the answer
        if (onChange && onChange instanceof Function) {
            onChange({
                answered: defaultAnswer || answer,
            });
        }
    }, []);

    // useEffect(() => {
    //     if (answer) {
    //         setData(() => {
    //             const answered = defaultAnswer || answer;
    //             return answered?.map((aswr, i) => {
    //                 return {
    //                     text: aswr,
    //                     value: answer.findIndex((aswrCorrect) => aswrCorrect === aswr) + 1, // Get position in pure answer for default answer
    //                 };
    //             });
    //         });
    //     }
    // }, [answer]);

    return (
        <div className={clsx("q-sort", { [exam_types]: !!exam_types })}>
            {exam_types !== "pdf" ? <div className="q-title">{t("q.question")}</div> : null}

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="sort_container">
                <DragDropContext
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    onDragUpdate={handleDragUpdate}
                >
                    <Droppable droppableId="list1">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="dragDrop_list">
                                {data?.map((item, index) => (
                                    <Draggable
                                        key={`item-${item.value}`}
                                        draggableId={`item-${item.value}`}
                                        index={index}
                                        isDragDisabled={isReadonly}
                                        disableInteractiveElementBlocking
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className={`${
                                                    snapshot?.isDragging ? "dragDrop_item dragging" : "dragDrop_item"
                                                } ${isReadonly ? "disabled" : ""}`}
                                            >
                                                <img
                                                    {...provided.dragHandleProps}
                                                    src={dragDropIcon}
                                                    alt="dragDropIcon"
                                                />
                                                <LatexDangerously innerHTML={item.text} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {snapshot.isDraggingOver && (
                                    <div
                                        className="placeholder"
                                        style={{
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            height: placeholderProps.clientHeight,
                                            width: placeholderProps.clientWidth,
                                            position: "absolute",
                                            backgroundColor: "white",
                                            borderRadius: "3px",
                                            border: "dashed 1px #ddd",
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default Sort;
