import { useEffect, useRef, useState } from "react";

const NewFolderModal = ({ controller, okText = "OK", cancelText = "Cancel", makeFolder = () => {} }) => {
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState("");
    const [parent, setParent] = useState(null);
    const inputRef = useRef();

    const handleCancel = () => {
        controller.call("new_folder.hide");
    };

    const handleConfirm = () => {
        const folderName = name;
        const parentInfo = parent;
        if (folderName && parentInfo) {
            const siblings = controller.call("content.get_current_files");
            const handleDoIf = (stage, res, run) => {
                if (stage == "pending") {
                    //
                } else if (stage === "fulfilled") {
                    setVisible(false);
                    setParent(null);
                    setName("");
                } else if (stage === "rejected") {
                    res.message && controller.call("notification.show", res.message, 5000, "error");
                } else if (stage === "confirm") {
                    setVisible(false);
                    controller.call("confirm.show", res, run);
                }
            };
            controller.call("crud_folder.create_with_validate", folderName, parentInfo, handleDoIf, siblings);
        }
    };

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current && inputRef.current.focus();
            }, 200);
        }
    }, [visible]);

    useEffect(() => {
        controller.set("new_folder.show", (parent) => {
            setVisible(true);
            setParent(parent);
            setName("");
        });
        controller.set("new_folder.hide", () => {
            setVisible(false);
            setParent(null);
            setTimeout(() => {
                setName("");
            }, 300);
        });

        return () => {
            controller.remove("new_folder.show");
            controller.remove("new_folder.hide");
        };
    }, []);

    const className = visible ? "file-manager-modal file-manager-modal-active" : "file-manager-modal";

    return (
        <div className={className}>
            <div className="file-manager-modal-fog" onClick={handleCancel}></div>
            <div className="file-manager-modal-content">
                <div className="file-manager-modal-body">
                    <input
                        className="fm-input-center"
                        placeholder="Tên thư mục"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength={50}
                        ref={inputRef}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                return handleConfirm();
                            }
                        }}
                    />
                </div>
                <div className="file-manager-modal-actions">
                    <button onClick={handleConfirm} disabled={!(name?.trim() || "")}>
                        {okText}
                    </button>
                    <button onClick={handleCancel}>{cancelText}</button>
                </div>
            </div>
        </div>
    );
};

export default NewFolderModal;
