import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRouting } from "src/utils/router";
import { Modal } from "antd";
import { checkIfUserHasAnyScope, checkIfUserHasValidScope, clearSelectedUserScope } from "src/api/helpers/userScope";
import UserScopePicker from "./components/UserScopePicker";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import { useEffect } from "react";

const AuthedAfter = ({ children }) => {
    const { t } = useTranslation();
    const { generate } = useRouting();

    const { user } = useSelector((state) => state.auth);

    const selectedUserScope = JSON.parse(localStorage.getItem("selectedUserScope") || null);
    const ifUserHasNoScope = checkIfUserHasAnyScope(user) === false;
    const ifUserHasValidScope = checkIfUserHasValidScope(user, selectedUserScope) !== false;

    useEffect(() => {
        if (!(ifUserHasNoScope || ifUserHasValidScope)) {
            const handleStorageChange = (storage) => {
                if (storage.storageArea.selectedUserScope) {
                    window.location.href = "/";
                }
            };

            window.addEventListener("storage", handleStorageChange);

            return () => {
                window.removeEventListener("storage", handleStorageChange);
            };
        }
    }, []);

    // If the user doesn't have any scope:
    if (ifUserHasNoScope) {
        clearSelectedUserScope();
        return children;
    }

    // Check if the user has selected any scope and the scope is valid:
    if (ifUserHasValidScope) {
        return children;
    } else {
        clearSelectedUserScope();
    }

    // If current page is payment result page, it means that the user has finished a payment,
    // so we have to display this page without interruption.
    if (window.location.pathname.includes(generate("payment_result"))) {
        return children;
    }

    return (
        <>
            <Modal
                visible={true}
                centered
                closable={false}
                title={t("organization.pick_login_option")}
                closeIcon={<IconClose />}
                footer={null}
                width="auto"
                className="app-modal type-basic flexible-height"
            >
                <UserScopePicker />
            </Modal>
        </>
    );
};

export default AuthedAfter;
