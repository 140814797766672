// File is verified!
import api from "..";

const notificationFields = ["_id", "content", "read_at", "created_at", "updated_at"];

// Get notification list:
export const fetchNotifications = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _notification_fields: `${notificationFields}`,
    };
    // Fetch:
    return api
        .get("/notification", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

// Get number of unread notifications:
export const countUnreadNotifications = () => {
    return api
        .get("/notification/count_not_read")
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: -1 }));
};

// Mark all notifications as 'read':
export const markAllNotificationsAsRead = () => {
    return api
        .post("/notification/read_all")
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};

// Mark a notification as 'read':
export const markOneNotificationAsRead = (notifId) => {
    return api
        .post(`/notification/read_one/${notifId}`)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};
