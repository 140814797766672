import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, notification, Select, Spin, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import { fetchExams } from "src/api/containers/exam";
import { createAssignment, fetchAssignmentsBank, updateAssignment } from "src/api/containers/assignment";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses } from "src/api/containers/class";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { default as CustomButton } from "src/modules/components/Button";
import moment from "moment";
import iconClose from "src/assets/images/action-close.svg";
import { animationId } from "src/reducers/animation";
import ExamCard from "./components/ExamCard";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import BaiKiemtraGif from "src/assets/images/form-icon/baitap.gif";
import BaiTapGif from "src/assets/images/form-icon/thuchanh.gif";
import "./ModalDetailExam.scss";

const ModalDetailExam = (props) => {
    const {
        classId,
        categoryList = [], //list môn học -classDetail
        editingAssignment = {},
        isLoading = false,
        isOnMobile,
        setValuesExam,
        examType,
        isShowFormSelect,

        classScopeId /** @param {string} classScopeId - Organization id, team id or undefined. */,
        ...rest
    } = props;

    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const [values, setValues] = useValues({
        loading: false,
        loadingExam1: false,
        optionListClass: [],
        optionListSubject: [],
        optionListExams: [],
        optionListAssignment: [],
        optionListExamsDefault: [],
        exam_question_id: "",
    });

    const timeoutIdGetExams = useRef(0);

    const dispatch = useDispatch();

    const currMedia = useMediaQuery(["(max-width: 768px)"]);

    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [loadingAsignment, optionListAssignment, pagination, fetch, refetch] = useFetch(
        { slug: "", teacher_id: user.id, ...fParams },
        fetchAssignmentsBank
    );

    const isTypeExam = examType === "exam"; // is bai kiem tra

    const handleSearchExams = (keyword) => {
        if (!values.loadingExam1) {
            setValues({
                loadingExam1: true,
            });
        }
        clearTimeout(timeoutIdGetExams.current);
        if (typeof keyword === "string") {
            setValues({
                loadingExam1: true,
            });

            timeoutIdGetExams.current = setTimeout(() => {
                fetchExams({
                    category_id: form.getFieldValue("category_id"),
                    user_id: user.id,
                    noPagination: true,
                    slug: keyword,
                    page: 1,
                }).then((res) => {
                    setValues({
                        ...values,
                        loadingExam1: false,
                        optionListExams: res.data || [],
                    });
                });
            }, 800);
        }
    };

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();

        // if (isTypeExam && classScopeId) {
        if (classScopeId) {
            formData.organization_id = classScopeId;
        }

        if (classId && !formData.class_id) {
            formData.class_id = classId;
        }
        // if (!formData.class_id || !formData.exam_question_id || !formData.name) {
        //     return;
        // }

        if (formData.begin_date && formData.end_date && formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }

        formData.category_id = formData.category_id || null;
        formData.begin_date = formData.begin_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;
        formData.end_date = formData.end_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;
        examType === "exam" ? (formData.is_test = true) : (formData.is_test = false);

        setValues({ loading: true });
        if (!Object.keys(editingAssignment || {}).length) {
            createAssignment(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setValues({ loading: false });
            });
        } else {
            updateAssignment(editingAssignment.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", update: editingAssignment.id }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                setValues({ loading: false });
            });
        }
    };

    const handleChangeClass = (classId) => {
        if (classId) {
            const formFields = form.getFieldsValue();

            const newFields = {
                ...formFields,
                category_id: undefined,
                exam_question_id: undefined,
                name: undefined,
                class_id: classId,
            };
            //trường hợp tạo bài kt trong classDetail
            if (formFields.class_id !== classId) {
                newFields.class_id = classId;
            }
            form.setFieldsValue(newFields);

            for (let i = 0; i < values.optionListClass.length; i++) {
                if (classId === values.optionListClass[i].id) {
                    setValues({
                        ...values,
                        optionListSubject: values.optionListClass[i].categories || [],
                        optionListExams: values.optionListExamsDefault,
                    });
                    break;
                }
            }
        } else {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                category_id: undefined,
                exam_question_id: undefined,
                name: undefined,
                class_id: undefined,
            });
            setValues({
                ...values,
                optionListExams: values.optionListExamsDefault,
            });
        }
    };

    const handleChangeCate = (cateId) => {
        if (cateId) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                exam_question_id: undefined,
                name: undefined,
            });
            setValues({ ...values, loading: true });
            fetchExams({ category_id: cateId, user_id: user.id, noPagination: true }).then((res) => {
                setValues({
                    ...values,
                    loading: false,
                    optionListExams: res.data || [],
                });
            });
        } else {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                exam_question_id: undefined,
                name: undefined,
            });
            setValues({
                ...values,
                optionListExams: values.optionListExamsDefault,
            });
        }
    };

    const handleChangeExam = (value) => {
        const fValues = form.getFieldsValue();

        const data = {
            name: values.optionListExams.find((exam) => exam.id === value)?.name,
        };

        if (classId && !fValues?.class_id) {
            form.setFieldsValue({ class_id: classId });
            data.class_id = classId;
        }

        // form.setFieldsValue(data);
        setValues({
            exam_question_id: value,
        });

        form.setFieldsValue({
            ...data,
            previous_id: undefined,
        });

        // checkIsRanking(value);
    };

    useEffect(() => {
        /**
         * Get classes and all categories of each class.
         * Get all exams created by the user.
         */
        Promise.all([
            fetchClasses({ organization_id: classScopeId, relations: ["categories"], noPagination: true }),
            fetchExams({ user_id: user.id, noPagination: true }),
        ]).then(([resClasses, resExams]) => {
            if (resClasses.status && resExams.status) {
                setValues({
                    optionListClass: resClasses.data || [],
                    optionListExams: resExams.data || [],
                    optionListExamsDefault: resExams.data || [],
                });
            }
        });
    }, [classScopeId]);

    useEffect(() => {
        if (isLoading !== values.loading) {
            setValues({ ...values, loading: isLoading });
        }
    }, [isLoading]);

    useEffect(() => {
        if (Object.keys(editingAssignment || {}).length) {
            const classId = editingAssignment?.class?.id;
            const cateId = editingAssignment?.category?.id;
            // Get subject list:
            const newSubjectList = [];
            if (classId) {
                for (let i = 0; i < values.optionListClass.length; i++) {
                    if (classId === values.optionListClass[i].id) {
                        newSubjectList.push(...(values.optionListClass[i].categories || []));
                        break;
                    }
                }
            }
            // Get exam list for the selected subject:
            setValues({ ...values, loading: true });
            fetchExams({
                user_id: user.id,
                noPagination: true,
                ...(cateId ? { category_id: cateId } : {}),
            }).then((res) => {
                // xử lý case đề bài ko thuộc list exams,
                // vì chỉ lấy 10 bài nên nếu ko có bài đó trong list 10 bài thì sẽ hiện id mà ko hiện tên
                const newResData = res.data;
                if (editingAssignment?.exam) {
                    const exam = res?.data?.find((item) => item?.id === editingAssignment?.exam?.id);
                    if (!exam) {
                        newResData?.push(editingAssignment?.exam);
                    }
                }
                setValues({
                    loading: false,
                    optionListSubject: newSubjectList,
                    optionListExams: newResData || [],
                });
                form.setFieldsValue({
                    class_id: editingAssignment?.class?.id,
                    category_id: (editingAssignment?.class_category_ids || [])?.includes(
                        editingAssignment?.category?.id
                    )
                        ? editingAssignment?.category?.id
                        : undefined,
                    name: editingAssignment?.name,
                    exam_question_id: editingAssignment?.exam?.id,
                    begin_date: editingAssignment?.begin_date ? moment(editingAssignment?.begin_date) : null,
                    end_date: editingAssignment?.end_date ? moment(editingAssignment?.end_date) : null,
                    homework_time_minute: editingAssignment?.homework_time_minute,
                    previous_id: editingAssignment?.previous_id,
                });
            });
        } else {
            if (values.optionListClass.length) {
                form.setFieldsValue({
                    class_id: undefined,
                    category_id: undefined,
                    name: undefined,
                    exam_question_id: undefined,
                    begin_date: undefined,
                    end_date: undefined,
                    homework_time_minute: undefined,
                    previous_id: undefined,
                });
            }
        }
    }, [editingAssignment]);

    useEffect(() => {
        if (props.visible === false) {
            setValues({
                ...values,
                optionListSubject: [],
                optionListExams: values.optionListExamsDefault,
                exam_question_id: "",
            });
        }
    }, [props.visible]);

    useEffect(() => {
        if (currMedia.breakpointValue !== -1 && !isOnMobile) {
            setValuesExam({ isOnMobile: true });
        }
    }, [currMedia, isOnMobile]);

    return (
        <Modal
            centered
            // title={t("class.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            {...rest}
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-detail-exam`}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {(!isOnMobile || !isShowFormSelect) && (
                            <div className="modal-detail-exam__left">
                                <div className="modal-detail-exam__left-header">
                                    {t("assignment.select_type_assignment")}
                                </div>
                                <div className="modal-detail-exam_list">
                                    <ExamCard
                                        active={isTypeExam}
                                        title={t("assignment.exam")}
                                        src={BaiKiemtraGif}
                                        onClick={() => {
                                            // Khi edit bài giao, ko cho nhấn qua dạng khác.
                                            // VD: đang là dang bài tập thì ko cho chuyển thành bài ktra
                                            if (editingAssignment?.is_test === false) return;
                                            setValuesExam({ examType: "exam", isShowForm: true });
                                            !editingAssignment?.id && form.resetFields();
                                        }}
                                    />
                                    <ExamCard
                                        active={examType === "assignment"}
                                        title={t("assignment.assignment")}
                                        src={BaiTapGif}
                                        onClick={() => {
                                            if (editingAssignment?.is_test === true) return;
                                            setValuesExam({ examType: "assignment", isShowForm: true });
                                            !editingAssignment?.id && form.resetFields();
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Right section */}
                        {(!isOnMobile || isShowFormSelect) && (
                            <div className="modal-detail-exam__right">
                                <div className="detail-exam-form">
                                    <Form form={form} onFinish={handleSubmitForm}>
                                        <Form.Item
                                            label={t("class.name")}
                                            name="class_id"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                            hidden={classId ? true : false}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("class.select_class")}
                                                onChange={(value) => handleChangeClass(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {values.optionListClass.map((item, index) => (
                                                    <Select.Option key={`class-${index}`} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.subject")}
                                            name="category_id"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("class.select_subjects")}
                                                onChange={(value) => handleChangeCate(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {classId
                                                    ? categoryList.map((item, index) => (
                                                          <Select.Option key={`subject-${index}`} value={item.id}>
                                                              {item.name}
                                                          </Select.Option>
                                                      ))
                                                    : values.optionListSubject.map((item, index) => (
                                                          <Select.Option key={`subject-${index}`} value={item.id}>
                                                              {item.name}
                                                          </Select.Option>
                                                      ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={isTypeExam ? t("class.select_exam") : t("class.select_asgmt")}
                                            name="exam_question_id"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={
                                                    isTypeExam ? t("class.select_exam") : t("class.select_asgmt")
                                                }
                                                onChange={(value) => handleChangeExam(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                                onSearch={handleSearchExams}
                                                loading={values.loadingExam1}
                                                notFoundContent={
                                                    values.loadingExam1 === true ? (
                                                        <span style={{ display: "flex" }}>
                                                            <Spin style={{ marginRight: "10px" }} />{" "}
                                                            {`${t("shared.loading")}...`}
                                                        </span>
                                                    ) : undefined
                                                }
                                            >
                                                {values.optionListExams.map((exam) => (
                                                    <Select.Option key={exam.id} value={exam.id}>
                                                        {exam.name || t("shared.unknown")}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <>
                                                    <span>
                                                        {isTypeExam ? t("class.exam_name") : t("class.asgmt_name")}
                                                    </span>
                                                    <CustomTooltip
                                                        type="question"
                                                        placement="right"
                                                        title={t("class.exam_name_description")}
                                                        style={{ order: "4", marginLeft: "4px" }}
                                                    />
                                                </>
                                            }
                                            name="name"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Input
                                                className="app-input"
                                                placeholder={
                                                    isTypeExam
                                                        ? t("class.input_exam_name")
                                                        : t("class.input_asgmt_name")
                                                }
                                                maxLength={100}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.begin_date")}
                                            name="begin_date"
                                            rules={[{ required: isTypeExam, message: t("class.input_begin_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("class.input_begin_date")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.end_date")}
                                            name="end_date"
                                            rules={[{ required: isTypeExam, message: t("class.input_end_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("class.input_end_date")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("class.duration")}
                                            name="homework_time_minute"
                                            rules={[{ required: isTypeExam, message: t("message.required") }]}
                                        >
                                            <InputNumber className="app-input" placeholder={t("class.input_time")} />
                                        </Form.Item>
                                        {/* {isTypeExam && (values.isShowRanking || editingAssignment?.ranking_type) && (
                                            <>
                                                <Form.Item label={t("ranking.ranking_type")} name="ranking_type">
                                                    <Select
                                                        className="app-select"
                                                        options={rankingOptions}
                                                        onChange={(v) => {
                                                            setValues({ ranking_type: v });
                                                            if (
                                                                !optionListAssignment?.length ||
                                                                !optionListAssignment?.length < 1
                                                            ) {
                                                                fetch({
                                                                    category_id: form.getFieldValue("category_id"),
                                                                    user_id: user.id,
                                                                    noPagination: true,
                                                                    class_id: form.getFieldValue("class_id"),
                                                                    is_ranking: 1,
                                                                    ignore_exam_id: form.getFieldValue(
                                                                        "exam_question_id"
                                                                    ),
                                                                });
                                                            }
                                                        }}
                                                        allowClear
                                                        placeholder={t("ranking.choose_ranking_type")}
                                                    ></Select>
                                                </Form.Item>

                                                {values.ranking_type && (
                                                    <>
                                                        <Form.Item
                                                            label={
                                                                values.ranking_type === "top"
                                                                    ? t("ranking.top")
                                                                    : t("ranking.point")
                                                            }
                                                            name="ranking_value"
                                                            rules={[
                                                                {
                                                                    required: isTypeExam,
                                                                    message: t("message.required"),
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                className="app-input"
                                                                placeholder={
                                                                    values.ranking_type === "top"
                                                                        ? t("ranking.enter_the_number_top")
                                                                        : t("ranking.enter_the_number_point")
                                                                }
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="previous_id"
                                                            label={
                                                                <>
                                                                    <span>{t("ranking.previous")}</span>
                                                                    <CustomTooltip
                                                                        type="question"
                                                                        placement="right"
                                                                        title={t("ranking.previous_condition")}
                                                                        style={{ order: "4", marginLeft: "4px" }}
                                                                    />
                                                                </>
                                                            }
                                                        >
                                                            <Select
                                                                className="app-select"
                                                                placeholder={
                                                                    isTypeExam
                                                                        ? t("class.select_exam")
                                                                        : t("class.select_asgmt")
                                                                }
                                                                optionFilterProp="children"
                                                                showSearch
                                                                allowClear
                                                                onSearch={handleSearchAsignments}
                                                                loading={loadingAsignment}
                                                                notFoundContent={
                                                                    loadingAsignment === true ? (
                                                                        <span style={{ display: "flex" }}>
                                                                            <Spin style={{ marginRight: "10px" }} />{" "}
                                                                            {`${t("shared.loading")}...`}
                                                                        </span>
                                                                    ) : undefined
                                                                }
                                                            >
                                                                {optionListAssignment?.map((exam) => (
                                                                    <Select.Option key={exam.id} value={exam.id}>
                                                                        {exam.name || t("shared.unknown")}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </>
                                        )} */}
                                        <div className="note">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        examType === "exam"
                                                            ? t("assignment.exam_note")
                                                            : t("assignment.assignment_note"),
                                                }}
                                            ></span>
                                        </div>
                                        <div className="btn-group">
                                            <CustomButton
                                                type="second"
                                                htmlType="submit"
                                                icon={<SendIcon />}
                                                title={t("shared.save")}
                                                style={{ width: "100%", maxWidth: "unset" }}
                                            ></CustomButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailExam;
