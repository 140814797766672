import React, { useEffect } from "react";
import { Checkbox, DatePicker, Form, Input, Modal, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import { useDispatch, useSelector } from "react-redux";
import { default as CustomButton } from "src/modules/components/Button";
import iconClose from "src/assets/images/action-close.svg";
import { animationId } from "src/reducers/animation";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import { createCompetition, updateCompetition } from "src/api/containers/Competition";
import RankingCupImg from "src/assets/images/ranking/ranking_and_cup.png";
import ColorPoolImg from "src/assets/images/ranking/color_pool.png";
import moment from "moment";
import "./ModalDetailCompetition.scss";

const ModalDetailCompetition = (props) => {
    const {
        classList = [],
        classId,
        editingCompetition = {},
        onCancel = () => {},
        onOk = () => {},
        isLoading = false,
        classScopeId,
        ...rest
    } = props;

    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const [values, setValues] = useValues({
        loading: isLoading,
        // optionListClass: [],
        optionListSubject: [],
        isClassOfficial: true,
        isDisableBeginDate: false,
    });

    const dispatch = useDispatch();

    const fParamsOrgMember = user.paramsForOrgOrTeam || {};
    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();
        // return;
        formData.category_id === undefined && (formData.category_id = null);
        fParams.organization_id && (formData.organization_id = fParams.organization_id);

        if (formData.begin_date && formData.end_date && formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }

        formData.begin_date = formData.begin_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;
        formData.end_date = formData.end_date?.format("YYYY-MM-DD HH:mm:ss") || undefined;

        setValues({ loading: true });
        if (!Object.keys(editingCompetition || {}).length) {
            createCompetition(formData)
                .then(({ status, message, id }) => {
                    if (status) {
                        dispatch(animationId({ name: "assignment", add: id }));
                        notification.success({
                            message: t("message.add_success"),
                        });
                        form.resetFields();
                        props.onOk();
                    } else {
                        notification.error({
                            message: message || t("message.add_error"),
                        });
                    }
                })
                .finally(() => {
                    setValues({ loading: false });
                });
        } else {
            formData.is_official = editingCompetition?.is_official;
            updateCompetition(editingCompetition.id, formData)
                .then(({ status, message }) => {
                    if (status) {
                        dispatch(animationId({ name: "assignment", update: editingCompetition.id }));
                        notification.success({
                            message: t("message.update_success"),
                        });
                        form.resetFields();
                        props.onOk();
                    } else {
                        notification.error({
                            message: message || t("message.update_error"),
                        });
                    }
                })
                .finally(() => {
                    setValues({ loading: false });
                });
        }
    };

    const getListSubject = (classList, classId) => {
        for (let i = 0; i < classList.length; i++) {
            if (classId === classList[i].id) {
                setValues({
                    optionListSubject: classList[i].categories || [],
                });
                break;
            }
        }
    };

    const handleChangeClass = (classId, data) => {
        if (classId) {
            // check to show competition type
            // const classItem = data?.data;
            // if (classItem) {
            //     if (classItem?.is_official === false) {
            //         setValues({
            //             isClassOfficial: false,
            //         });
            //     } else if (classItem?.is_official === true && values.isClassOfficial === false) {
            //         //lớp chính thức
            //         setValues({
            //             isClassOfficial: true,
            //         });
            //     }
            // }

            const formFields = form.getFieldsValue();
            const newFields = {
                category_id: undefined,
                class_id: classId,
            };
            //trường hợp tạo bài kt trong classDetail
            if (formFields.class_id !== classId) {
                newFields.class_id = classId;
            }
            form.setFieldsValue(newFields);
            getListSubject(classList, classId);
        } else {
            form.setFieldsValue({
                category_id: undefined,
                // name: undefined,
                class_id: undefined,
            });
        }
    };

    useEffect(() => {
        if (Object.keys(editingCompetition || {}).length) {
            const classId = editingCompetition?.class?.id || classId;
            if (classId) {
                getListSubject(classList, classId);
                form.setFieldsValue({
                    class_id: classId,
                    category_id: editingCompetition?.category_id,
                    name: editingCompetition?.name,
                    begin_date: editingCompetition?.begin_date ? moment(editingCompetition?.begin_date) : null,
                    end_date: editingCompetition?.end_date ? moment(editingCompetition?.end_date) : null,
                    // is_official: editingCompetition?.is_official,
                });
                if (moment(editingCompetition?.begin_date).isBefore(new Date())) {
                    setValues({ isDisableBeginDate: true });
                }
            }
            // setValues({ isClassOfficial: editingCompetition?.class?.is_official });
        } else if (classId) {
            if (classList?.length > 0 && !(values.optionListSubject?.length > 0)) {
                getListSubject(classList, classId);
                form.setFieldsValue({
                    class_id: classId,
                });
            }
        }
    }, [editingCompetition?.id, classId, classList]);

    useEffect(() => {
        if (props.visible === false) {
            setValues({
                optionListSubject: [],
                isDisableBeginDate: false,
            });
        }
    }, [props.visible]);

    return (
        <Modal
            centered
            // title={t("class.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-detail-exam competition`}
            destroyOnClose={true}
            onCancel={() => {
                onCancel?.();
                form.resetFields();
            }}
            onOk={() => {
                onOk?.();
                form.resetFields();
            }}
            {...rest}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {/* Left section */}
                        <div className="modal-detail-exam__left">
                            <div className="modal-detail-exam__left-header">
                                {editingCompetition?.id
                                    ? t("ranking.update_competition")
                                    : t("ranking.create_competition")}
                            </div>
                            <div className="detail-exam-form">
                                <Form form={form} onFinish={handleSubmitForm}>
                                    <Form.Item
                                        label={t("ranking.competition_name")}
                                        name="name"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Input
                                            placeholder={t("ranking.enter_competition_name")}
                                            className="app-input"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("class.name")}
                                        name="class_id"
                                        rules={[{ required: true, message: t("message.required") }]}
                                        hidden={classId}
                                    >
                                        <Select
                                            className="app-select"
                                            placeholder={t("class.select_class")}
                                            onChange={(value, data) => handleChangeClass(value, data)}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                        >
                                            {classList?.map((item, index) => (
                                                <Select.Option key={`class-${index}`} value={item.id} data={item}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={t("class.subject")}
                                        name="category_id"
                                        // rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Select
                                            className="app-select"
                                            placeholder={t("class.select_subjects")}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                        >
                                            {values.optionListSubject.map((item, index) => (
                                                <Select.Option key={`subject-${index}`} value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={t("class.begin_date")}
                                        name="begin_date"
                                        rules={[{ required: true, message: t("class.input_begin_date") }]}
                                    >
                                        <DatePicker
                                            disabled={values.isDisableBeginDate}
                                            format={"DD-MM-YYYY HH:mm"}
                                            showTime={{ format: "HH:mm" }}
                                            placeholder={t("class.input_begin_date")}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("class.end_date")}
                                        name="end_date"
                                        rules={[{ required: true, message: t("class.input_end_date") }]}
                                    >
                                        <DatePicker
                                            format={"DD-MM-YYYY HH:mm"}
                                            showTime={{ format: "HH:mm" }}
                                            placeholder={t("class.input_end_date")}
                                        />
                                    </Form.Item>
                                    {/* {values.isClassOfficial === false && (
                                        <Form.Item
                                            // rules={[{ required: true, message: t("message.required") }]}
                                            name="is_official"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                <span style={{ fontSize: 18 }}>
                                                    {t("ranking.official_competition")}
                                                </span>
                                            </Checkbox>
                                        </Form.Item>
                                    )} */}

                                    {/* <div className="note">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    examType === "exam"
                                                        ? t("assignment.exam_note")
                                                        : t("assignment.assignment_note"),
                                            }}
                                        ></span>
                                    </div> */}
                                    <div className="btn-group">
                                        <CustomButton
                                            type="green"
                                            htmlType="submit"
                                            icon={<SendIcon />}
                                            title={t("shared.save")}
                                            style={{ width: "100%", maxWidth: "unset" }}
                                        ></CustomButton>
                                    </div>
                                </Form>
                            </div>
                        </div>

                        <div className="modal-detail-exam__right">
                            <div className="image-wrapper">
                                <div className="image-top">
                                    <img src={ColorPoolImg} />
                                </div>
                                <div className="image-bottom">
                                    <img src={RankingCupImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailCompetition;
