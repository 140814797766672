import { useCallback } from "react";
import InputEditor from "src/modules/components/InputEditor";
import { BinIcon } from "src/utils/drawer";
import "./CorrectAnswer.scss";

const CorrectAnswer = (props) => {
    const { onChange, value, onDeleteCorrectAnswer } = props;

    const handleChange = (data) => {
        onChange({
            text: data,
            id: value?.id,
        });
    };

    const handleDeleteCorrectAnswer = useCallback((e, id) => {
        e.preventDefault();
        e.stopPropagation();

        onDeleteCorrectAnswer(id);
    }, []);

    return (
        <div className="fill-blank-drag-drop__correct_answer">
            <InputEditor
                isSuffix
                className="fill-blank-drag-drop__correct_answer__input"
                value={value?.text}
                onChange={handleChange}
                {...(value?.id < 0
                    ? {
                          suffix: (
                              <BinIcon
                                  className="fill-blank-drag-drop__correct_answer__delete"
                                  onClick={(e) => handleDeleteCorrectAnswer(e, value?.id)}
                              />
                          ),
                      }
                    : {})}
            />
        </div>
    );
};

export default CorrectAnswer;
