import React from "react";
import "./HelpIframeEmbedLink.scss";

const HelpIframeEmbedLink = ({ slideEmbedLink }) => {
    return (
        <div className="help-iframe-embedlink">
            <div className="embedlink-viewer">
                <iframe src={slideEmbedLink}></iframe>
            </div>
        </div>
    );
};

export default HelpIframeEmbedLink;
