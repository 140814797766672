import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchCategories } from "src/api/containers/category";
import useValues from "src/hooks/useValues";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";
import { fetchSubscriptionPlans } from "src/api/containers/plan";
import moment from "moment";

const FilterMenu = ({
    filterParams = [],
    filterParamsInitial = undefined,
    willFormBeCleared = false,
    handleFetchPaymentsHistory = () => {},
    handleCloseFilterMenu = () => {},
}) => {
    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        categories: [],
        isFetchingCates: false,
        listPlans: [],
    });
    const [currFilterParams, setCurrFilterParams] = useState(filterParamsInitial?.length ? filterParamsInitial : []); // Current applied filter values.
    const timeoutIdGetCates = useRef(null);

    const handleSubmit = () => {
        const fetchParams = form.getFieldsValue();

        // Save new applied filter values:
        const newFilterParams = [];

        fetchParams.bill_status &&
            newFilterParams.push({
                name: "bill_status",
                value: fetchParams.bill_status,
                labelName: t("shared.status"),
                labelValue: fetchParams.bill_status ? t(`payment.${fetchParams.bill_status}`) : "",
            });

        fetchParams.plan_id &&
            newFilterParams.push({
                name: "plan_id",
                value: fetchParams.plan_id,
                labelName: t("payment.subscription"),
                labelValue:
                    values.listPlans.filter((plan) => {
                        return plan.id === fetchParams.plan_id;
                    })[0].name || "",
            });
        fetchParams.valid_from &&
            newFilterParams.push({
                name: "valid_from",
                value: fetchParams.valid_from,
                labelValue: moment(fetchParams.valid_from).format("DD-MM-YYYY"),
            });
        fetchParams.valid_to &&
            newFilterParams.push({
                name: "valid_to",
                value: fetchParams.valid_to,
                labelValue: moment(fetchParams.valid_to).format("DD-MM-YYYY"),
            });

        fetchParams.valid_from && (fetchParams.valid_from = moment(fetchParams.valid_from).format("YYYY-MM-DD"));
        fetchParams.valid_to && (fetchParams.valid_to = moment(fetchParams.valid_to).format("YYYY-MM-DD"));
        setCurrFilterParams(newFilterParams);
        // Fetch assignment list by the corresponding filter values:
        handleFetchPaymentsHistory({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    useEffect(() => {
        fetchCategories({ slug: "", noPagination: true }).then((res) => {
            setValues({
                categories: res.data,
            });
        });

        fetchSubscriptionPlans({}).then(({ status, message, data }) => {
            if (status) {
                setValues({
                    listPlans: data,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                bill_status: newFormData.bill_status,
                plan_id: newFormData.plan_id,
                valid_from: newFormData.valid_from ? moment(newFormData.valid_from).format("YYYY-MM-DD") : "",
                valid_to: newFormData.valid_to ? moment(newFormData.valid_to).format("YYYY-MM-DD") : "",
            };

            setCurrFilterParams(filterParams);
            handleFetchPaymentsHistory({ page: 1, ...fetchParams }, true);
        }
    }, [filterParams]);

    useEffect(() => {
        if (filterParamsInitial?.length) {
            const newFormData = {};
            for (let i = 0; i < filterParamsInitial.length; i++) {
                newFormData[filterParamsInitial[i].name] = filterParamsInitial[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
        }
    }, [filterParamsInitial]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid form-horizontal" layout="horizontal">
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Form.Item name="bill_status" label={t("shared.status")}>
                        <Select className="app-select" placeholder={t("branch.select_status")}>
                            <Select.Option value={"PAYED"}>{t(`payment.PAYED`)}</Select.Option>
                            <Select.Option value={"NOT_PAYED"}>{t(`payment.NOT_PAYED`)}</Select.Option>
                            <Select.Option value={"PAY_ERROR"}>{t(`payment.PAY_ERROR`)}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="plan_id" label={t("setting.subscription")}>
                        <Select className="app-select" placeholder={t("setting.select_plan")}>
                            {values.listPlans?.map((item) => {
                                return (
                                    <Select.Option key={item?.id} value={item?.id}>
                                        {item?.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="valid_from" label={t("class.begin_date")}>
                        <DatePicker
                            format="DD-MM-YYYY"
                            className="app-filter-date"
                            placeholder={t("report.please_select_date")}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="valid_to" label={t("setting.expiration_date")}>
                        <DatePicker
                            format="DD-MM-YYYY"
                            className="app-filter-date"
                            placeholder={t("report.please_select_date")}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default FilterMenu;
