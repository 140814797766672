import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Dropdown, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import { fetchQuestionList } from "src/api/containers/question";
import { renderQuestionType } from "src/utils/helpers";
import FilterMenu from "../FilterMenu";
import InputSearch from "src/modules/components/Input/InputSearch";
import Button from "src/modules/components/Button";
import Table from "src/modules/components/Table";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import Pencil from "src/assets/images/pencil.svg";
import Rocket from "src/assets/images/rocket.svg";
import Bin from "src/assets/images/bin.svg";
import FilterIcon from "src/assets/images/filter-icon.svg";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import "./AddExamQuestions.scss";

const AddExamQuestions = (props) => {
    const { value = [], onChange = () => {}, setIsModalAddQuestions = () => {} } = props;
    const { t } = useTranslation();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // API CALL:
    const fParams = {};
    const fParamsDetail = {};
    if (fParamsOrgMember.organization_id) {
        fParams.organization_id = fParamsOrgMember.organization_id;
        fParamsDetail.organization_name = user.currentOrg?.organization_name || user.currentOrg?.team_name || "Unknown";
    }
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, slug: "", is_publish: true, owner_id: user.id, ...fParams },
        fetchQuestionList
    );

    // Others:
    const [newValue, setNewValue] = useState([...value]);
    const [isAddQuestionModal, setIsAddQuestionModal] = useState(false);
    const [filterSettings, setFilterSettings] = useState({
        inputSearchQuestion: "",
        isFilterMenuVisible: false,
        filterParams: [
            {
                name: "is_publish",
                value: true,
            },
            {
                name: "owner_id",
                value: user.id,
                labelName: t("question.owner"),
                labelValue: t(`question.owner_me`),
            },
            ...(fParams.organization_id
                ? [
                      {
                          name: "is_public",
                          value: fParams.organization_id,
                          labelValue: fParamsDetail.organization_name,
                      },
                  ]
                : []),
        ],
    });

    const columns = [
        {
            title: t("shared.category"),
            dataIndex: "category",
            key: "category",
            width: "auto",
            render: (text, record) => record?.category?.name,
        },
        {
            title: t("shared.tags"),
            dataIndex: "tags",
            key: "tags",
            width: "auto",
            render: (text, record) => {
                return (
                    <div className="tags-wrapper">
                        {record?.tags.map((tag) => (
                            <Tag className="app-tag-label" key={tag.id} color={tag.color}>
                                {tag.name.toUpperCase()}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: t("shared.owner"),
            dataIndex: "owner",
            key: "owner",
            width: "auto",
            render: (text, record) => record?.user?.name,
        },
        {
            title: t("q.question"),
            dataIndex: "question",
            key: "question",
            width: "auto",
            render: (text, record) => {
                let htmlStr = record?.detail?.question;
                htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");
                return (
                    <div className="table-content-format">
                        <div className="text-limit-lines">
                            <HTMLDisplayer htmlString={htmlStr} />
                        </div>
                    </div>
                );
            },
        },
        {
            title: t("shared.type"),
            dataIndex: "type",
            key: "type",
            width: "auto",
            render: (text, record) => record?.detail?.question_type?.name,
        },
    ];

    const handleCloseModalSelectQuestionType = useCallback(() => {
        setIsAddQuestionModal(false);
    }, []);

    const handleAddQuestion = () => {
        onChange(newValue);
        setIsModalAddQuestions(false);
    };

    const handleSelectRow = (record) => {
        const index = newValue.findIndex((item) => item.id === record.id);

        if (index >= 0) {
            // Reorder the list of selected questions:
            let num = newValue[index].order || 0;
            const arr = [...newValue.filter((item) => item.id !== record.id)];
            for (let i = index; i < arr.length; i++) {
                arr[i].order = num;
                num++;
            }
            setNewValue(arr);
        } else {
            // Specify ordinal number:
            let qNum = 1;
            for (let i = 0; i < newValue.length; i++) {
                if (qNum <= newValue[i].order) {
                    qNum = newValue[i].order + 1;
                }
            }
            // Update the list of selected questions:
            const addQuestion = {
                ...record,
                score: record?.detail?.score,
                type: record?.detail?.type,
                question: record?.detail?.question,
                order: qNum,
            };
            setNewValue([...newValue, addQuestion]);
        }
    };

    // Nào rãnh quay lại sửa logic của handleChangeSelectedRowKeys này xog xoá sẽ xoá comment
    const handleChangeSelectedRowKeys = (ids) => {
        let temp = [...newValue];
        // If a question is already selected, remove it:
        if (ids.length === 0 || ids.length < temp.length) {
            let numFrom = -1;
            let index = -1;
            temp = temp.filter((item, i) => {
                if (dataSource.find((item2) => item2.id === item.id)) {
                    if (numFrom === -1) {
                        numFrom = item.order;
                        index = i;
                    }
                    return false;
                }
                return true;
            });
            if (index > -1) {
                for (let i = index; i < temp.length; i++) {
                    temp[i].order = numFrom;
                    numFrom++;
                }
            }
        }

        // Specify ordinal number for new questions:
        let qNum = 1;
        for (let i = 0; i < temp.length; i++) {
            if (qNum <= temp[i].order) {
                qNum = temp[i].order + 1;
            }
        }

        // Add new questions to the list:
        ids.forEach((id) => {
            if (temp.findIndex((item) => item.id === id) < 0) {
                let addQuestion = dataSource.find((item) => item.id === id);
                addQuestion = {
                    ...addQuestion,
                    score: addQuestion?.detail?.score,
                    type: addQuestion?.detail?.type,
                    question: addQuestion?.detail?.question,
                    order: qNum,
                };
                temp.push(addQuestion);
                qNum++;
            }
        });

        // Update list of selected questions:
        setNewValue(temp);
    };

    const handleSearchQuestion = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchQuestion });
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        fetch({}, true);
    }, []);

    return (
        <div className="question-bank add-exam-bank scroll_primary">
            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("shared.find_question")}
                            onChange={(e) =>
                                setFilterSettings({ ...filterSettings, inputSearchQuestion: e.target.value })
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearchQuestion();
                                }
                            }}
                            onClickIconSearch={handleSearchQuestion}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {filterSettings.filterParams?.length > 0
                                    ? filterSettings.filterParams.map((fKey, i) => {
                                          if (!fKey.labelName && !fKey.labelValue) {
                                              return null;
                                          }
                                          return (
                                              <Tag
                                                  className="app-tag"
                                                  key={`filter-key${i}`}
                                                  closable
                                                  onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                              >
                                                  {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                  {`${fKey.labelValue || ""}`}
                                              </Tag>
                                          );
                                      })
                                    : // <>{t("shared.option_filter")}</>
                                      null}
                            </Space>
                        </div>
                        <Dropdown
                            forceRender
                            overlayStyle={{ zIndex: 1010 }}
                            visible={filterSettings.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={filterSettings.filterParams}
                                    filterParamsInitial={filterSettings.filterParams}
                                    handleFetchQuestionList={fetch}
                                    handleCloseFilterMenu={(newFilterParams) =>
                                        setFilterSettings({
                                            ...filterSettings,
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        })
                                    }
                                    willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    excludeFields={["is_publish"]}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => {
                                setFilterSettings({ ...filterSettings, isFilterMenuVisible: val });
                            }}
                        >
                            <div className="filter-button">
                                <Button type="primary" icon={FilterIcon} title={t("shared.option_filter")}></Button>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions">
                    <Button
                        type="primary"
                        icon={Pencil}
                        title={t("exam_bank.create_question")}
                        onClick={() => setIsAddQuestionModal(true)}
                    ></Button>
                </div>
            </div>

            <div className="table add-exam-table">
                <Table
                    rowSelection={{
                        selectedRowKeys: newValue.map((item) => item.id),
                        onChange: handleChangeSelectedRowKeys,
                        renderCell: (checked) => {
                            return <Checkbox checked={checked} />;
                        },
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    onRow={(record) => ({ onClick: () => handleSelectRow(record) })}
                    rowKey="id"
                />
            </div>

            <div className="btn-group">
                <Button
                    type="grey"
                    icon={Bin}
                    title={t("shared.cancel")}
                    onClick={() => setIsModalAddQuestions(false)}
                ></Button>
                <Button type="primary" icon={Rocket} title={t("shared.confirm")} onClick={handleAddQuestion}></Button>
            </div>

            <ModalSelectQuestionType
                type="exam_bank"
                visible={isAddQuestionModal}
                onCancel={handleCloseModalSelectQuestionType}
                refetch={refetch}
                // onClickItem={() => {
                //     console.log("Hello");
                // }}
            ></ModalSelectQuestionType>
        </div>
    );
};

export default AddExamQuestions;
