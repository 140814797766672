import { Col, Form, Row, Select, DatePicker, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef } from "react";
import useValues from "src/hooks/useValues";
import Button from "src/modules/components/Button";
import { fetchClasses } from "src/api/containers/class";
import { fetchCategories } from "src/api/containers/category";
import { fetchAssignments } from "src/api/containers/assignment";
import moment from "moment";
import Icon from "src/modules/components/Icon";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { useSelector } from "react-redux";
import { getUserScopeSelected } from "src/api/helpers/userScope";
import "./FilterMenu.scss";

const filterMenu = (props) => {
    const {
        isModal = false,
        filterParams = [],
        handleFetch = () => {},
        handleCloseFilterMenu = () => {},
        isTeacher = false,
    } = props;
    const [form] = useForm();

    const _filterParams = useMemo(() => {
        const _r = {};
        filterParams.map((item) => {
            _r[item.name] = {
                value: item.value,
                labelName: item.labelName,
                labelValue: item.labelValue,
            };
        });
        return _r;
    }, [filterParams]);

    // User and scope:
    const user = useSelector((state) => state.auth.user);
    const fParamsUserScope = user.paramsForOrgOrTeam || {};
    const { scopeKey, scopeName } = useMemo(() => {
        return getUserScopeSelected(user);
    }, [user]);

    const [values, setValues] = useValues({
        loading: false,
        optionListClass: [],
        optionListSubject: [],
        optionListAssignment: [],
        isFetchingClasses: false, // Values: "searching", true, false.
        isFetchingSubjects: false, // Values: "searching", true, false.
        isFetchingAssignments: false, // Values: "searching", true, false.
        isLoadingMoreClasses: false, // Values: "disabled" (Disable loading-more), true, false (Allow loading-more).
        isLoadingMoreAssignments: false, // Values: "disabled" (Disable loading-more), true, false (Allow loading-more).
    });
    const timeoutIdGetClasses = useRef(null);
    const timeoutIdGetAssignments = useRef(null);
    const fetchClassesParams = useRef({
        page: 0,
        slug: "",
    });
    const fetchAssignmentsParams = useRef({
        page: 0,
        slug: "",
    });

    const handleCloseModal = () => {
        handleCloseFilterMenu(false);
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            class_id: formData.class_id,
            subject_id: formData.subject_id,
            assignment_id: formData.assignment_id,
            organization_id: formData.organization_id,
        };

        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.dateFrom &&
            newFilterParams.push({
                name: "dateFrom",
                value: fetchParams.dateFrom,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.dateFrom).format("YYYY-MM-DD"),
            });
        fetchParams.dateTo &&
            newFilterParams.push({
                name: "dateTo",
                value: fetchParams.dateTo,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.dateTo).format("YYYY-MM-DD"),
            });
        fetchParams.class_id &&
            newFilterParams.push({
                name: "class_id",
                value: fetchParams.class_id,
                labelName: t("report.class"),
                labelValue: values.optionListClass.filter((classItem) => {
                    return classItem.id === fetchParams.class_id;
                })[0]?.name,
            });
        fetchParams.subject_id &&
            newFilterParams.push({
                name: "subject_id",
                value: fetchParams.subject_id,
                labelName: t("report.subject"),
                labelValue:
                    values.optionListSubject.filter((subject) => {
                        return subject.id === fetchParams.subject_id;
                    })[0]?.name || "_",
            });
        fetchParams.assignment_id &&
            newFilterParams.push({
                name: "assignment_id",
                value: fetchParams.assignment_id,
                labelName: t("report.assignment"),
                labelValue: values.optionListAssignment.filter((assignment) => {
                    return assignment.id === fetchParams.assignment_id;
                })[0]?.name,
            });
        fetchParams.organization_id !== undefined &&
            newFilterParams.push({
                name: "organization_id",
                value: fetchParams.organization_id,
                labelValue: scopeName,
            });

        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams);
    };

    // 1. Scope:
    const handleChangeScope = (scopeId) => {
        // Reset class, subject, assignment data when the scope is changed:
        fetchClassesParams.current = {
            page: 0,
            slug: "",
            organization_id: scopeId,
        };
        fetchAssignmentsParams.current = {
            page: 0,
            slug: "",
            organization_id: scopeId,
        };
        // Reset form fields:
        form.setFieldsValue({
            class_id: undefined,
            subject_id: undefined,
            assignment_id: undefined,
        });
        // Fetch:
        handleFetchClass({
            organization_id: scopeId,
        });
    };

    // 2. Class:
    const handleFetchClass = (fetchParams = {}, classId) => {
        if (classId) {
            const selectedScopeId = "";
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                ...fetchParams,
                page: 1,
            };
            const fetchParamsCates = { class_ids: [classId], noPagination: true };
            fetchAssignmentsParams.current = {
                ...fetchAssignmentsParams.current,
                page: 1,
                organization_id: selectedScopeId,
                class_id: classId,
            };

            setValues({
                ...values,
                isFetchingClasses: true,
                isFetchingSubjects: true,
                isFetchingAssignments: true,
            });
            const _fetchClasses = fetchClasses(fetchClassesParams.current);
            const _fetchCates = fetchCategories(fetchParamsCates);
            const _fetchAsgmts = fetchAssignments(fetchAssignmentsParams.current);
            Promise.all([_fetchClasses, _fetchCates, _fetchAsgmts]).then((res) => {
                const resClasses = res[0];
                const resCates = res[1];
                const resAsgmts = res[2];

                const _newValues = {};
                const _classes = resClasses.data || [];
                const _cates = resCates.data || [];
                const _asgmts = resAsgmts.data || [];

                if (resClasses.status) {
                    _newValues.isFetchingClasses = false;
                    _newValues.optionListClass = _classes;
                    _newValues.isLoadingMoreClasses =
                        resClasses.data?.length === 0 ||
                        resClasses.pagination.lastPage === fetchClassesParams.current.page
                            ? "disabled"
                            : false;
                    fetchClassesParams.current.page = resClasses.pagination?.page || 1;
                } else {
                    _newValues.isFetchingClasses = false;
                    _newValues.optionListClass = [];
                    _newValues.isLoadingMoreClasses = false;
                    fetchClassesParams.current.page = 0;
                }

                if (resCates.status) {
                    _newValues.isFetchingSubjects = false;
                    _newValues.optionListSubject = _cates;
                } else {
                    _newValues.isFetchingSubjects = false;
                    _newValues.optionListSubject = [];
                }

                if (resAsgmts.status) {
                    _newValues.isFetchingAssignments = false;
                    _newValues.optionListAssignment = _asgmts;
                    fetchAssignmentsParams.current.page = resAsgmts.pagination?.page || 1;
                } else {
                    _newValues.isFetchingAssignments = false;
                    _newValues.optionListAssignment = [];
                    fetchAssignmentsParams.current.page = 0;
                }

                setValues(_newValues);
            });
        } else {
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                ...fetchParams,
                page: 1,
            };

            setValues({ ...values, isFetchingClasses: true });
            fetchClasses(fetchClassesParams.current).then((res) => {
                if (res.status === true) {
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                        optionListClass: res.data || [],
                        optionListSubject: [],
                        optionListAssignment: [],
                        isLoadingMoreClasses:
                            res.data?.length === 0 || res.pagination.lastPage === fetchClassesParams.current.page
                                ? "disabled"
                                : false,
                        isLoadingMoreAssignments: false,
                    });
                    fetchClassesParams.current.page = res.pagination?.page || 1;
                } else {
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                        optionListClass: [],
                        optionListSubject: [],
                        optionListAssignment: [],
                        isLoadingMoreClasses: false,
                        isLoadingMoreAssignments: false,
                    });
                    fetchClassesParams.current.page = 0;
                }
            });
        }
    };

    // 2.1. Class - change:
    const handleChangeClass = (classId) => {
        // Reset slug when the dropdown is closed (when the search-input is cleared):
        fetchClassesParams.current.slug = "";

        if (!classId) {
            // Reset states:
            setValues({
                ...values,
                optionListSubject: [],
                optionListAssignment: [],
            });
            // Reset form fields:
            form.setFieldsValue({
                subject_id: undefined,
                assignment_id: undefined,
            });
            return;
        }

        const selectedScopeId = form.getFieldValue("organization_id");
        const fetchParamsCates = { class_ids: [classId] };
        fetchAssignmentsParams.current = {
            ...fetchAssignmentsParams.current,
            page: 1,
            organization_id: selectedScopeId,
            class_id: classId,
        };

        setValues({ ...values, isFetchingSubjects: true, isFetchingAssignments: true });
        Promise.all([fetchCategories(fetchParamsCates), fetchAssignments(fetchAssignmentsParams.current)]).then(
            (res) => {
                const resCates = res[0];
                const resAsgmts = res[1];
                if (resCates.status || resAsgmts.status) {
                    setValues({
                        ...values,
                        isFetchingSubjects: false,
                        isFetchingAssignments: false,
                        optionListSubject: resCates.data || [],
                        optionListAssignment: resAsgmts.data || [],
                    });
                    fetchAssignmentsParams.current.page = resAsgmts.pagination?.page || 1;
                } else {
                    setValues({
                        ...values,
                        isFetchingSubjects: false,
                        isFetchingAssignments: false,
                        optionListSubject: [],
                        optionListAssignment: [],
                    });
                    fetchAssignmentsParams.current.page = 0;
                }
            }
        );
        // Reset form fields:
        form.setFieldsValue({
            subject_id: undefined,
            assignment_id: undefined,
        });
    };

    // 2.2. Class - keydown:
    const handleKeyDownClass = (e) => {
        if (values.isFetchingClasses === true || values.isLoadingMoreClasses === true) {
            e.preventDefault();
        }
    };

    // 2.3. Class - search:
    const handleSearchClasses = (keyword) => {
        clearTimeout(timeoutIdGetClasses.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                isFetchingClasses: "searching",
            });
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                page: 1,
                slug: keyword,
            };
            timeoutIdGetClasses.current = setTimeout(() => {
                fetchClasses(fetchClassesParams.current).then((res) => {
                    if (res.status) {
                        setValues({
                            ...values,
                            optionListClass: res.data,
                            isFetchingClasses: false,
                            isLoadingMoreClasses: false,
                        });
                    } else {
                        setValues({
                            ...values,
                            isFetchingClasses: false,
                        });
                    }
                });
            }, 500);
        }
    };

    // 2.4. Class - scroll:
    const handleScrollClasses = (e) => {
        if (
            ["searching", true].includes(values.isFetchingClasses) ||
            ["disabled", true].includes(values.isLoadingMoreClasses)
        ) {
            return;
        }
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = fetchClassesParams.current.page + 1;
            fetchClassesParams.current = {
                ...fetchClassesParams.current,
                page: nextPageToLoad,
            };
            setValues({ ...values, isLoadingMoreClasses: true });
            fetchClasses(fetchClassesParams.current).then((res) => {
                if (res.status) {
                    setValues({
                        ...values,
                        isLoadingMoreClasses:
                            res.data?.length === 0 || res.pagination.lastPage === nextPageToLoad ? "disabled" : false,
                        optionListClass: [...values.optionListClass, ...res.data],
                    });
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreClasses: false,
                    });
                }
            });
        }
    };

    // 2.5. Class - element:
    const classFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("shared.search_and_select_classes")}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={values.isFetchingSubjects || values.isFetchingAssignments}
            onChange={handleChangeClass}
            onInputKeyDown={handleKeyDownClass}
            onSearch={(keyword) => handleSearchClasses(keyword)}
            onPopupScroll={(e) => handleScrollClasses(e)}
            notFoundContent={
                values.isFetchingClasses === true ? (
                    <i className="notfound-when-loading">
                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                    </i>
                ) : values.isFetchingClasses === "searching" ? (
                    <React.Fragment></React.Fragment>
                ) : undefined
            }
            dropdownClassName="app-select-dropdown"
            dropdownRender={(menu) => (
                <>
                    {values.isFetchingClasses === "searching" ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                        </div>
                    ) : null}
                    {menu}
                    {values.isLoadingMoreClasses === true ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        </div>
                    ) : null}
                </>
            )}
        >
            {_filterParams.class_id?.value && (
                <Select.Option key={`class-extra`} value={_filterParams.class_id?.value} style={{ display: "none" }}>
                    {_filterParams.class_id?.labelValue}
                </Select.Option>
            )}
            {values.optionListClass.map((item, itemIndex) => (
                <Select.Option key={`class-${item.id}`} value={item.id}>
                    ({item.code}) {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    // 3. Subject:
    // 3.1. Subject - change:
    const handleChangeSubject = (subjectId) => {
        const selectedScopeId = form.getFieldValue("organization_id");
        const selectedClassId = form.getFieldValue("class_id");
        fetchAssignmentsParams.current = {
            ...fetchAssignmentsParams.current,
            page: 1,
            organization_id: selectedScopeId,
            class_id: selectedClassId,
            category_id: subjectId,
        };

        setValues({ ...values, isFetchingAssignments: true });
        fetchAssignments(fetchAssignmentsParams.current).then((res) => {
            if (res.status) {
                setValues({
                    ...values,
                    isFetchingAssignments: false,
                    optionListAssignment: res.data || [],
                });
                fetchAssignmentsParams.current.page = res.pagination?.page || 1;
            } else {
                setValues({
                    ...values,
                    isFetchingAssignments: false,
                    optionListAssignment: [],
                });
                fetchAssignmentsParams.current.page = 0;
            }
        });
        // Reset form fields:
        form.setFieldsValue({
            assignment_id: undefined,
        });
    };

    // 3.1. Subject - element:
    const subjectFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("report.please_select_subject")}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={values.isFetchingClasses || values.isFetchingAssignments}
            onChange={handleChangeSubject}
        >
            {values.optionListSubject.map((item, itemIndex) => (
                <Select.Option key={`cate-${item.id}`} value={item.id}>
                    {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    // 4. Assignment:
    // 4.1. Assignment - change:
    const handleChangeAssignment = (asgmtId) => {
        // Reset slug when the dropdown is closed (when the search-input is cleared):
        fetchAssignmentsParams.current.slug = "";
    };

    // 4.2. Assignment - keydown:
    const handleKeyDownAssignment = (e) => {
        if (values.isFetchingAssignments === true || values.isLoadingMoreAssignments === true) {
            e.preventDefault();
        }
    };

    // 4.3. Assignment - search:
    const handleSearchAssignments = (keyword) => {
        clearTimeout(timeoutIdGetAssignments.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                isFetchingAssignments: "searching",
            });
            fetchAssignmentsParams.current = {
                ...fetchAssignmentsParams.current,
                page: 1,
                slug: keyword,
            };
            timeoutIdGetAssignments.current = setTimeout(() => {
                fetchAssignments(fetchAssignmentsParams.current).then((res) => {
                    if (res.status) {
                        setValues({
                            ...values,
                            optionListAssignment: res.data,
                            isFetchingAssignments: false,
                            isLoadingMoreAssignments: false,
                        });
                    } else {
                        setValues({
                            ...values,
                            isFetchingAssignments: false,
                        });
                    }
                });
            }, 500);
        }
    };

    // 4.4. Assignment - scroll:
    const handleScrollAssignments = (e) => {
        if (
            ["searching", true].includes(values.isFetchingAssignments) ||
            ["disabled", true].includes(values.isLoadingMoreAssignments)
        ) {
            return;
        }
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = fetchAssignmentsParams.current.page + 1;
            fetchAssignmentsParams.current = {
                ...fetchAssignmentsParams.current,
                page: nextPageToLoad,
            };
            setValues({ ...values, isLoadingMoreAssignments: true });
            fetchAssignments(fetchAssignmentsParams.current).then((res) => {
                if (res.status) {
                    setValues({
                        ...values,
                        isLoadingMoreAssignments:
                            res.data?.length === 0 || res.pagination.lastPage === nextPageToLoad ? "disabled" : false,
                        optionListAssignment: [...values.optionListAssignment, ...res.data],
                    });
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreAssignments: false,
                    });
                }
            });
        }
    };

    // 4.5. Assignment - element:
    const assignmentFilterMenu = (
        <Select
            className="app-select filter-person_selector"
            placeholder={t("report.please_select_test_name")}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={values.isFetchingClasses || values.isFetchingSubjects}
            onChange={handleChangeAssignment}
            onInputKeyDown={handleKeyDownAssignment}
            onSearch={(keyword) => handleSearchAssignments(keyword)}
            onPopupScroll={(e) => handleScrollAssignments(e)}
            notFoundContent={
                values.isFetchingAssignments === true ? (
                    <i className="notfound-when-loading">
                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                    </i>
                ) : values.isFetchingAssignments === "searching" ? (
                    <React.Fragment></React.Fragment>
                ) : undefined
            }
            dropdownClassName="app-select-dropdown"
            dropdownRender={(menu) => (
                <>
                    {values.isFetchingAssignments === "searching" ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                        </div>
                    ) : null}
                    {menu}
                    {values.isLoadingMoreAssignments === true ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        </div>
                    ) : null}
                </>
            )}
        >
            {_filterParams.assignment_id?.value && (
                <Select.Option
                    key={`class-extra`}
                    value={_filterParams.assignment_id?.value}
                    style={{ display: "none" }}
                >
                    {_filterParams.assignment_id?.labelValue}
                </Select.Option>
            )}
            {values.optionListAssignment.map((item, itemIndex) => (
                <Select.Option key={`asgmt-${item.id}`} value={item.id}>
                    {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    useEffect(() => {
        if (!_filterParams.class_id?.value) {
            handleFetchClass();
        } else {
            handleFetchClass({}, _filterParams.class_id?.value);
        }
    }, []);

    useEffect(() => {
        const newFormData = {};
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i]?.name] = filterParams[i]?.value;
        }

        // Update form:
        form.resetFields();
        form.setFieldsValue(newFormData);

        // Refetch data:
        if (!isModal) {
            const fetchParams = {
                dateFrom: newFormData.dateFrom,
                dateTo: newFormData.dateTo,
                class_id: newFormData.class_id,
                subject_id: newFormData.subject_id,
                assignment_id: newFormData.assignment_id,
                organization_id: newFormData.organization_id,
            };
            handleFetch({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    return (
        <div className="filter-personal-learning-progress_wrapper">
            <Spin
                spinning={
                    values.isFetchingClasses === true ||
                    values.isFetchingAssignments === true ||
                    values.isFetchingSubjects === true
                }
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    className="form form-full-label filter-personal-learning-progress_inner scroll_primary"
                >
                    <Row>
                        {fParamsUserScope?.organization_id ? (
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item name="organization_id" label={t("shared.scope")}>
                                    <Select
                                        className="filter-person_selector"
                                        placeholder={t("shared.select_scope")}
                                        allowClear
                                        onChange={handleChangeScope}
                                    >
                                        <Select.Option value={fParamsUserScope.organization_id}>
                                            {scopeName || "Unknown"}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="dateFrom"
                                label={
                                    <>
                                        <span>{t("report.dateFrom")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_datefrom")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    // suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="dateTo"
                                label={
                                    <>
                                        <span>{t("report.dateTo")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_dateto")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    // suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="class_id"
                                label={t("report.class")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                {classFilterMenu}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item name="subject_id" label={t("report.subject")}>
                                {subjectFilterMenu}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item name="assignment_id" label={t("report.test_name")}>
                                {assignmentFilterMenu}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="filter_btn">
                        <Button
                            type="grey"
                            onClick={handleCloseModal}
                            icon={<Icon name="icon-delete" />}
                            title={t("report.cancel")}
                        ></Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("report.apply")}
                        ></Button>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default filterMenu;
