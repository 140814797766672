import { t } from "i18next";
import { useMemo } from "react";
import { checkIsObject } from "src/utils/helpers";
import QuestionContentDetail from "../ContentDetail";
import MyTable from "./components/MyTable";

function MultipleChoiceTable(props) {
    const {
        /**
         * Interface of questionInfo
         * @param {Boolean[]} correct_answer: correct answer
         * @param {Boolean[]} answered?: student's answers
         * @param {String[]} answer1: question of table
         * @param {{key: number, value: string}[] | string[]} answer2: title of list select
         * @param {String} question: Question
         */
        correct_answer,
        answered,
        answer1,
        answer2,
        question,
    } = props;

    // Convert type {key: number, value: string}[] to string[]
    const newAnswer2 = useMemo(() => {
        if (checkIsObject(answer2?.[0])) {
            return answer2?.map((aswr) => aswr.value);
        }

        return answer2;
    }, [answer2]);

    return (
        <div className="qc_multiple-choice-table">
            {question && (
                <div className="q-content-title">
                    <QuestionContentDetail
                        isReadonly={true}
                        value={{
                            question,
                        }}
                    />
                </div>
            )}
            <div className="content-multiple-table">
                <MyTable answer1={answer1} answer2={newAnswer2} answered={answered} correctMatch={correct_answer} />
            </div>
        </div>
    );
}

export default MultipleChoiceTable;
