import Button from "../Button";
import { Path3367, Path2256, Path2253, Path2263, Path2261, Path2266, Path2262, Path2743 } from "../../icons/tab9";
import { Path3479, Path3478, Path2252, Path2254, Path3476, Path3475, Path2792, Path3358 } from "../../icons/tab9";
import { Path3362, Path3357, Path3359, Path3504, Path3505, Path3521, _1023 } from "../../icons/tab9";

const Tab9 = ({ onClick }) => {
    let datas = [
        { icon: Path3367, text: "\\infty" },
        { icon: Path2256, text: "\\subset" },
        { icon: Path2253, text: "\\supset" },
        { icon: Path2263, text: "\\subseteq" },
        { icon: Path2261, text: "\\supseteq" },
        { icon: Path2266, text: "\\notin" },
        { icon: Path2262, text: "\\ni" },
        { icon: Path2743, text: "\\notni" },

        { icon: Path3479, text: "\\notsubseteq" },
        { icon: Path3478, text: "\\notsubset" },
        { icon: Path2252, text: "\\cup" },
        { icon: Path2254, text: "\\cap" },
        { icon: Path3476, text: "\\varnothing" },
        { icon: Path3475, text: "\\exists" },
        { icon: Path2792, text: "\\forall" },
        { icon: Path3358, text: "\\R" },

        { icon: Path3362, text: "\\C" },
        { icon: Path3357, text: "\\Q" },
        { icon: Path3359, text: "\\Z" },
        { icon: Path3504, text: "\\N" },
        { icon: Path3505, text: "\\U" },
        { icon: Path3521, text: "\\backslash" },
        { icon: _1023, text: "\\left\\{\\right\\}", keys: ["Left"] },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab9;
