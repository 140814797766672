import React, { useState } from "react";
// Import Swiper React components:
import { Swiper, SwiperSlide } from "swiper/react";
// Import required modules:
import { EffectCoverflow, Pagination } from "swiper";
// Import Swiper styles:
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// Import custom styles:
import "./Swiper.scss";

const AppSwiper = ({
    children,
    className,
    coverflowEffect = {},
    styleConfig = {
        disableShadow: true,
    },
    onClickSlide,
    ...rest
}) => {
    const [swiper, setSwiper] = useState(null);

    // Generate ClassNames:
    const currClassNames = [];
    if (styleConfig.disableShadow) {
        currClassNames.push("disable-shadow");
    }

    const handleClickSlide = (e, slideIndex) => {
        if (onClickSlide instanceof Function) {
            onClickSlide(e);
        }
        if (swiper?.slideTo instanceof Function) {
            swiper.slideTo(slideIndex);
        }
    };

    if (!children?.length) {
        return null;
    }

    return (
        <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
                ...coverflowEffect,
            }}
            initialSlide={0}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className={
                "app-swiper type-effect-coverflow" +
                (className || "") +
                (currClassNames.length ? " " + currClassNames.join(" ") : "")
            }
            onSwiper={setSwiper}
            {...rest}
        >
            {children.map((child, childIndex) => {
                if (React.isValidElement(child)) {
                    return (
                        <SwiperSlide
                            key={`slide-${childIndex}`}
                            onClick={(e) => {
                                handleClickSlide(e, childIndex);
                            }}
                        >
                            {child}
                        </SwiperSlide>
                    );
                }
                return null;
            })}
        </Swiper>
    );
};

export default AppSwiper;
