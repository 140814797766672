import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PictureOutlined } from "@ant-design/icons";
import "./TextScroll.scss";

const TextScroll = ({ text = "", icon, defaultIcon }) => {
    const collapsed = useSelector((state) => state.general.collapsed);
    const [duration, setDuration] = useState(8);

    useEffect(() => {
        const len = text?.length;
        if (len < 12) {
            setDuration(8);
        } else if (len < 21) {
            setDuration(11);
        } else if (len < 45) {
            setDuration(14);
        } else if (len < 65) {
            setDuration(18);
        }
    }, []);

    return (
        <div className={`sidebar_text-scroll ${collapsed ? "collapsed" : ""}`}>
            <span className="sidebar_text-scroll-logo">
                {(() => {
                    const _img = icon || defaultIcon;
                    if (typeof _img === "string") {
                        return <img src={_img} />;
                    }
                    if (React.isValidElement(_img)) {
                        return _img;
                    }
                    return <PictureOutlined />;
                })()}
            </span>
            <span className="sidebar_text-scroll-content">
                <p style={{ animationDuration: `${duration}s`, WebkitAnimationDuration: duration }}>{text}</p>
            </span>
        </div>
    );
};

export default TextScroll;
